import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IWorkingIntervals } from '../../interfaces/i-working-intervals';
import { WorkingHour } from '../../models/working-hour';
import { AuthService } from '../auth.service';
import { CachedDataService } from '../cached-data.service';

import { ShiftsService } from './shifts.service';

const WORKING_HOURS_STORAGE_KEY = 'working-hours';

@Injectable({
  providedIn: 'root',
})
export class WorkingHoursService {
  private readonly path = '/working-hours';
  private workingInterval: IWorkingIntervals | null;

  constructor(
    protected http: HttpClient,
    private shiftsService: ShiftsService,
    private cachedDataService: CachedDataService,
    private authService: AuthService,
  ) {}

  isStarted(): boolean {
    return Boolean(this.workingInterval && this.workingInterval.id !== 0);
  }

  startWorkingHours(
    checkLocalStorage: boolean = false,
  ): Observable<IWorkingIntervals | null> {
    const currentShift = this.shiftsService.getCurrentShift();

    if (currentShift) {
      const wh = new WorkingHour();

      wh.shopId = currentShift.shopId;
      wh.userId = this.authService.getUserId();
      wh.shiftId = currentShift.id;

      if (checkLocalStorage) {
        const workingHoursLocal = localStorage.getItem(
          WORKING_HOURS_STORAGE_KEY,
        );

        const workingHours = workingHoursLocal
          ? (JSON.parse(workingHoursLocal) as WorkingHour)
          : null!;

        if (workingHours) {
          wh.shopId = workingHours.shopId;
          wh.userId = workingHours.userId;
          wh.shiftId = workingHours.shiftId;
          wh.startedAt = workingHours.startedAt;
        }
      }

      return this.http.post<IWorkingIntervals>(`${this.path}/start`, wh).pipe(
        tap((x) => {
          this.workingInterval = x;

          this.cachedDataService.setWorkingIntervals(this.workingInterval);

          if (checkLocalStorage) {
            localStorage.removeItem(WORKING_HOURS_STORAGE_KEY);
          }
        }),
      );
    }

    return of(null);
  }

  saveWorkingHoursLocal(): void {
    const currentShift = this.cachedDataService.getShift();

    if (currentShift) {
      const wh = new WorkingHour();

      wh.shopId = currentShift.shopId;
      wh.userId = currentShift.userId;
      wh.shiftId = currentShift.id;
      wh.startedAt = new Date();

      localStorage.setItem(WORKING_HOURS_STORAGE_KEY, JSON.stringify(wh));
    }
  }

  haveUnsyncedWorkingHours(): boolean {
    const workingHoursLocal = localStorage.getItem(WORKING_HOURS_STORAGE_KEY);
    const workingHours = workingHoursLocal
      ? (JSON.parse(workingHoursLocal) as WorkingHour)
      : null!;

    return Boolean(workingHours);
  }

  getCurrentWorkingHours(): Observable<IWorkingIntervals | null> {
    const currentShift = this.shiftsService.getCurrentShift();

    if (currentShift) {
      let params = new HttpParams();

      params = params.append('shopId', currentShift.shopId.toString());
      params = params.append('userId', this.authService.getUserId().toString());
      params = params.append('date', new Date().toISOString());

      return this.http
        .get<IWorkingIntervals>(`${this.path}/current`, { params })
        .pipe(
          tap((x) => {
            this.workingInterval = x;

            this.cachedDataService.setWorkingIntervals(this.workingInterval);
          }),
        );
    }

    return of(null);
  }

  getCurrentWorkingHoursLocal(): Observable<IWorkingIntervals | null> {
    this.workingInterval = this.cachedDataService.getWorkingIntervals();

    if (!(this.workingInterval && this.workingInterval.id)) {
      this.saveWorkingHoursLocal();

      this.workingInterval = { id: -1, time: 0 };
    }

    return new Observable((observer) => {
      observer.next(this.workingInterval);
      observer.complete();
    });
  }

  stopWorkingHours(userId: number): Observable<void | null> {
    if (this.workingInterval) {
      const wh = new WorkingHour();

      wh.id = this.workingInterval.id;
      wh.userId = userId;

      return this.http.post<void>(`${this.path}/stop`, wh).pipe(
        tap(() => {
          this.workingInterval = null;

          this.cachedDataService.setWorkingIntervals(this.workingInterval);
        }),
      );
    }

    return of(null);
  }
}
