import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
} from '@angular/router';
import { AlertController } from '@ionic/angular';

import {
  SHIFT_CLOSED,
  SHIFT_NOT_CONNECTED,
} from '../constants/error-messages.const';
import { ShiftsService } from '../services/resources/shifts.service';
import { ToastService } from '../services/toast.service';
import { UtilsService } from '../services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ShiftGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private alertCtrl: AlertController,
    private shiftsService: ShiftsService,
    private toastService: ToastService,
    private utilsService: UtilsService,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> {
    return this.isOnlineAndShiftOpened();
  }

  canActivateChild(): Promise<boolean> {
    return this.isOnlineAndShiftOpened();
  }

  canLoad(): Promise<boolean> {
    return this.isOnlineAndShiftOpened();
  }

  async isOnlineAndShiftOpened(): Promise<boolean> {
    if (!(await this.utilsService.isOnline())) {
      this.toastService.presentNoInternet();

      return false;
    }

    const isShiftOpened = this.shiftsService.isOpened();
    const isShiftUser = this.shiftsService.isShiftUser();

    if (!(isShiftOpened && isShiftUser)) {
      await this.alertCtrl
        .create({
          header: 'Підказка',
          message: !isShiftUser ? SHIFT_NOT_CONNECTED : SHIFT_CLOSED,
          buttons: [{ text: 'Закрити', role: 'close' }],
        })
        .then((alert) => alert.present());

      this.router.navigate(['/shop'], { replaceUrl: true });

      return false;
    }

    return true;
  }
}
