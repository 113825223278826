import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class HostListenerService {
  private readonly eventType: BehaviorSubject<string>;

  constructor() {
    this.eventType = new BehaviorSubject<string>('sale');
  }

  setEventType(type: string): void {
    this.eventType.next(type);
  }

  getEventType(): Observable<string> {
    return this.eventType;
  }
}
