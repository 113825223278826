import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';

import { CachedResource } from '../../core/abstract/cached-resource';
import { ICategoryWithSubcategory } from '../../core/interfaces/i-category-with-subcategory';
import { ObjectLiteral } from '../../core/interfaces/object-literal';
import { CachedDataService } from '../../core/services/cached-data.service';

import { Category } from './category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService extends CachedResource<Category> {
  constructor(
    protected http: HttpClient,
    protected platform: Platform,
    protected network: Network,
    private cachedDataService: CachedDataService,
  ) {
    super(http, platform, network, {
      path: '/categories',
      cache: true,
      preferCache: true,
    });
  }

  findForApp(
    options: Partial<Category> = {},
    customOptions?: { forceRefresh?: boolean },
  ): Observable<Category[]> {
    options = { ...options, shopId: this.cachedDataService.getShopId() };

    return super.findForApp(options, customOptions);
  }

  findWithSubcategories(
    shopId: number,
  ): Observable<ICategoryWithSubcategory[]> {
    const params: ObjectLiteral = { shopId, withSubcategory: true };

    return this.http.get<ICategoryWithSubcategory[]>(this.buildUrl(), {
      params,
    });
  }
}
