<div class="check" *ngIf="waste">
  <bk-view-line [line]="numberCaption + ' ' + waste.day + '.' + waste.month + '.' + waste.year"></bk-view-line>
  <bk-view-line [start]="selfNameCaption + ': ' + waste.fop" [end]="waste.rnokpp"></bk-view-line>
  <bk-view-line [line]="providerNameCaption + ': ' + waste.provider"></bk-view-line>
  <bk-view-line [line]="userNameCaption + ': ' + waste.employee"></bk-view-line>
  <bk-view-line [line]="poaCaption + ' ' + waste.year"></bk-view-line>

  <table class="products">
    <tr class="header">
      <th class="index">№</th>
      <th class="name">{{ headerNameCaption }}</th>
      <th class="quantity">{{ headerQuantityCaption }}</th>
      <th class="price">{{ headerPriceCaption }}{{ waste.salePriceMode ? '*' : '' }}<br>{{ headerNoVatCaption }}</th>
      <th class="sum">{{ headerSumCaption }}{{ waste.salePriceMode ? '*' : '' }}<br>{{ headerNoVatCaption }}</th>
    </tr>

    <tr class="product" *ngFor="let product of waste.products; let i = index">
      <td class="index">{{ i + 1 }}</td>
      <td class="name">{{ product.name }}, {{ product.type }}</td>
      <td class="quantity">{{ product.quantity }}</td>
  
      <td class="price" *ngIf="waste.salePriceMode; else defaultPrice">
        {{ product.costPrice }}<br>{{ product.salePrice }}
      </td>

      <ng-template #defaultPrice>
        <td class="price">{{ product.price }}</td>
      </ng-template>

      <td class="sum" *ngIf="waste.salePriceMode; else defaultSum">
        {{ product.costSum }}<br>{{ product.saleSum }}
      </td>

      <ng-template #defaultSum>
        <td class="sum">{{ product.sum }}</td>
      </ng-template>
    </tr>
  </table>

  <section *ngIf="!waste.salePriceMode; else salePriceModeTotal">
    <bk-view-line [start]="totalSumCaption" [end]="currency(waste.sum)"></bk-view-line>
    <bk-view-line [line]="totalVatCaption"></bk-view-line>
    <bk-view-line [start]="totalSumWithVatCaption" [end]="currency('______.___')"></bk-view-line>
    <bk-view-line [line]="waste.positionCountRow"></bk-view-line>
    <bk-view-line [line]="waste.summarySumRow"></bk-view-line>
  </section>

  <ng-template #salePriceModeTotal>
    <section>
      <bk-view-line [line]="salePriceModeNote"></bk-view-line>
      <bk-view-line [line]="waste.positionCountRow"></bk-view-line>
      <bk-view-line [start]="totalCostSumCaption" [end]="currency(waste.costSum)"></bk-view-line>
      <bk-view-line [start]="totalSaleSumCaption" [end]="currency(waste.saleSum)"></bk-view-line>
      <bk-view-line [start]="totalVatCaption" [end]="currency('______.___')"></bk-view-line>
      <bk-view-line [start]="totalSumWithVatCaption" [end]="currency('______.___')"></bk-view-line>
    </section>
  </ng-template>

  <bk-view-line [start]="directorSignCaption + ' ____________'" [end]="accountantSignCaption + ' ____________'"></bk-view-line>
  <bk-view-line [start]="shopSignCaption + ' ___________'" [end]="providerSignCaption + ' _____________'"></bk-view-line>

  <div class="page-break"></div>
</div>
