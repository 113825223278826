import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import chunk from 'lodash-es/chunk';

import { Category } from '../categories/category.model';

import { Subcategory } from './subcategory.model';

@Component({
  selector: 'bk-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.scss'],
})
export class SubcategoriesComponent implements OnInit {
  @Input() menuCategory: Category;
  @Input() menuSubcategories: Subcategory[];
  @Input() isLandscape = false;
  @Output() goToProducts: EventEmitter<Subcategory> =
    new EventEmitter<Subcategory>();
  @Output() goBackToMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  subcategories: Subcategory[];
  category: Category;

  subcategoryGroups: Subcategory[][];

  columnsCount = 1;

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.isLandscape) {
      this.category = this.menuCategory;
      this.subcategories = this.menuSubcategories;
      this.columnsCount = 4;
      this.subcategoryGroups = chunk(this.subcategories, this.columnsCount);
    } else {
      const navigation = this.router.getCurrentNavigation();

      if (navigation && navigation.extras && navigation.extras.state) {
        const routeState = navigation.extras.state;

        if (routeState) {
          this.category = JSON.parse(routeState.category) as Category;
          this.subcategories = JSON.parse(
            routeState.subcategories,
          ) as Subcategory[];
        }
      }
    }
  }

  go(subcategory: Subcategory): void {
    if (this.isLandscape) {
      this.goToProducts.emit(subcategory);

      return;
    }

    this.router.navigateByUrl('/products', {
      state: {
        category: JSON.stringify(this.category),
        subcategory: JSON.stringify(subcategory),
      },
    });
  }
}
