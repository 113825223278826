export class PrintPRroZReportTax {
  /**
   * Обіг <Літера>
   */
  volume: string;

  /**
   * <Назва> <Літера> <%>
   */
  name: string;

  /**
   * Вихідна сума для розрахування податку/збору
   */
  sourceSum: string;

  /**
   * Сума податку/збору
   */
  sum: string;
}
