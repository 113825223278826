import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiUrlInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!(req.url.startsWith('https://') || req.url.startsWith('http://'))) {
      req = req.clone({
        url: environment.apiUrl + req.url,
        headers: new HttpHeaders(),
      });
    }

    return next.handle(req);
  }
}
