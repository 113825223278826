<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="settings"></ion-back-button>
    </ion-buttons>

    <ion-title>Друк чеків</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form #printerForm="ngForm">
    <ion-list [inset]="true" lines="full" class="settings">
      <bk-printer [settings]="settings"
                  [connectionMethods]="connectionMethods"
                  (updated)="updateSettings()"></bk-printer>

      <ion-item *ngIf="isPaperWidthAvailable()">
        <ion-icon slot="start" name="code-working-outline" color="dark"></ion-icon>
        <ion-label>Ширина паперу</ion-label>
        <ion-select id="paperWidth"
                    name="paperWidth"
                    okText="Вибрати" 
                    cancelText="Скасувати"
                    [(ngModel)]="settings.paperWidth"
                    (ngModelChange)="setSettings()">
          <ion-select-option *ngFor="let paperWidthOption of paperWidths" [value]="paperWidthOption">
            {{ paperWidthOption }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item *ngIf="isRowLengthAvailable()">
        <ion-icon slot="start" name="text-outline" color="dark"></ion-icon>
        <ion-label>Кількість символів у рядку</ion-label>
        <ion-select id="rowLength"
                    name="rowLength"
                    okText="Вибрати" 
                    cancelText="Скасувати"
                    [(ngModel)]="settings.rowLength"
                    (ngModelChange)="setSettings()">
          <ion-select-option *ngFor="let rowLengthOption of rowLengths" [value]="rowLengthOption">
            {{ rowLengthOption }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item *ngIf="isRowLengthAvailable()">
        <ion-icon slot="start" name="cut-outline" color="dark"></ion-icon>
        <ion-label>Відстань до обрізу</ion-label>
        <ion-select id="linesToCut"
                    name="linesToCut"
                    okText="Вибрати" 
                    cancelText="Скасувати"
                    [(ngModel)]="settings.linesToCut"
                    (ngModelChange)="setSettings()">
          <ion-select-option *ngFor="let linesToCutOption of linesToCutList" [value]="linesToCutOption">
            {{ linesToCutOption }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item *ngIf="isPrintShopLogoInReceiptAvailable()">
        <ion-icon slot="start" name="image-outline" color="dark"></ion-icon>
        <ion-label>Друк лого магазину у чеку</ion-label>
        <ion-toggle slot="end" color="dark" name="printShopLogoInReceipt"
                    [(ngModel)]="settings.printShopLogoInReceipt"
                    (ngModelChange)="setSettings()"></ion-toggle>
      </ion-item>

      <ion-item>
        <ion-icon slot="start" name="ticket-outline" color="dark"></ion-icon>
        <ion-label>Автоматичний друк після продажу</ion-label>
        <ion-toggle slot="end" color="dark" name="autoPrintAfterSale"
                    [(ngModel)]="settings.autoPrintAfterSale"
                    (ngModelChange)="updateSettings()"></ion-toggle>
      </ion-item>

      <ion-item *ngIf="isUseCashDrawerAvailable()">
        <ion-icon slot="start" name="file-tray-full-outline" color="dark"></ion-icon>
        <ion-text>
          <ion-label>Грошова скринька</ion-label>
          <ion-note>Для автоматичного відкривання, скринька має бути підключена до принтера</ion-note>
        </ion-text>
        <ion-toggle slot="end" color="dark" name="useCashDrawer"
                    [(ngModel)]="settings.useCashDrawer"
                    (ngModelChange)="updateSettings()"></ion-toggle>
      </ion-item>

      <ion-item *ngIf="isAndroidApp">
        <ion-icon slot="start" name="options-outline" color="dark"></ion-icon>
        <ion-label>Додаткові налаштування</ion-label>
        <ion-toggle slot="end" color="dark" name="additionOptions" [(ngModel)]="additionOptions"></ion-toggle>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true" lines="full" class="settings" *ngIf="additionOptions && isAndroidApp">
      <ion-list-header>
        <ion-label>Додаткові налаштування</ion-label>
      </ion-list-header>

      <ion-item>
        <ion-icon slot="start" name="print-outline" color="dark"></ion-icon>
        <ion-label>Модель POS-термінала</ion-label>
        <ion-select id="model"
                    name="model"
                    okText="Вибрати"
                    cancelText="Скасувати"
                    [interfaceOptions]="{ cssClass: 'printer-select-option' }"
                    [(ngModel)]="androidPosTerminal"
                    (ngModelChange)="setModel()">
          <ion-select-option *ngFor="let androidPosTerminalOption of androidPosTerminals" [value]="androidPosTerminalOption">
            {{ androidPosTerminalOption.title }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-icon slot="start" name="document-text-outline" color="dark"></ion-icon>
        <ion-label  position="stacked">Назва кодування</ion-label>
        <ion-input  name="charsetName"
                    placeholder="windows-1251 | CP866"
                    [(ngModel)]="settings.charsetName"
                    (ngModelChange)="setSettings()"
                    required></ion-input>
      </ion-item>

      <ion-item>
        <ion-icon slot="start" name="document-outline" color="dark"></ion-icon>
        <ion-label  position="stacked">Код кодування</ion-label>
        <ion-input  type="tel"
                    name="charsetId"
                    placeholder="17 | 46 | 73"
                    [(ngModel)]="settings.charsetId"
                    (ngModelChange)="setSettings()"
                    required></ion-input>
      </ion-item>

      <ion-item>
        <ion-icon slot="start" name="language-outline" color="dark"></ion-icon>
        <ion-label>Вимкнути китайське кодування</ion-label>
        <ion-toggle slot="end" color="dark" name="turnOffChinese"
                    [(ngModel)]="turnOffChinese"
                    (ngModelChange)="setSettings()"></ion-toggle>
      </ion-item>
    </ion-list>

    <bk-qz-tray *ngIf="settings.connectionMethod === printerConnectionMethod.QZTray"></bk-qz-tray>
  </form>
</ion-content>
