export class Printer {
  name: string;
  title: string;
  id: string;
  class: number;
  address: string;

  // USB
  productName?: string;
  manufacturerName?: string;
  deviceId?: number;
  serialNumber?: string;
  vendorId?: number;

  constructor(name: string = '', title?: string) {
    this.name = name;
    this.title = title ?? name;
  }

  static usbName(manufacturerName?: string, productName?: string): string {
    return Printer.baseName(manufacturerName, productName, ' ', '_');
  }

  static usbTitle(manufacturerName?: string, productName?: string): string {
    return Printer.baseName(manufacturerName, productName, '_', ' ');
  }

  private static baseName(
    manufacturerName: string | undefined,
    productName: string | undefined,
    searchValue: string,
    replaceValue: string,
  ): string {
    return `${(manufacturerName ?? '').trim()} ${(
      productName ?? ''
    ).trim()}`.replace(new RegExp(searchValue, 'g'), replaceValue);
  }
}
