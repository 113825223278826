import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AUTH_URL } from '../constants/auth-url.const';
import { CachedDataService } from '../services/cached-data.service';
import { UtilsService } from '../services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private utilsService: UtilsService,
    private cachedDataService: CachedDataService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    handler: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.url.endsWith(AUTH_URL)) {
      return handler.handle(request);
    }

    if (
      request.url.includes('/settings/network-state') ||
      request.url.includes('/wastes') ||
      request.url.includes('/moves') ||
      request.url.includes('/ingredients') ||
      request.url.includes('/last-doc-number') ||
      request.url.includes('/log')
    ) {
      return handler.handle(request);
    }

    return handler.handle(request).pipe(
      tap(
        () => {},
        (responseError: HttpErrorResponse) => {
          this.showError(responseError);
        },
      ),
    );
  }

  private showError(responseError: HttpErrorResponse): void {
    const httpError = this.utilsService.getParsedError(responseError);
    const appSettings = this.cachedDataService.getAppSettings();

    if (httpError.message.includes('/cover/')) {
      return;
    }

    if (httpError.message.includes('/sales') && httpError.statusCode === 0) {
      return;
    }

    if (
      httpError.message.includes(appSettings.dpsPRroUrl) &&
      (httpError.statusCode === 404 ||
        httpError.statusCode === 0 ||
        httpError.name === 'TimeoutError') &&
      !httpError.errorMessage?.startsWith('Код помилки')
    ) {
      this.utilsService.showErrorToast(httpError, {
        note: `Сервер ДПС недоступний${
          httpError.statusCode ? '' : ' (немає мережі Інтернет)'
        }`,
        isWarning: true,
      });

      return;
    }

    if (
      httpError.message.includes('/api/') &&
      httpError.statusCode === 0 &&
      httpError.name === 'HttpErrorResponse'
    ) {
      this.utilsService.showErrorToast(httpError, {
        note: 'Сервер cashbox недоступний',
      });

      return;
    }

    this.utilsService.showErrorToast(httpError);
  }
}
