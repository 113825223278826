import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import chunk from 'lodash-es/chunk';

import { SYNC_PRODUCTS_SUCCESS } from '../../core/constants/events.const';
import { CachedDataService } from '../../core/services/cached-data.service';
import { Events } from '../../core/services/events.service';
import { SubcategoriesService } from '../subcategories/subcategories.service';

import { CategoriesService } from './categories.service';
import { Category } from './category.model';

@Component({
  selector: 'bk-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() isLandscape: boolean;
  @Output() setCategory: EventEmitter<Category> = new EventEmitter<Category>();

  categoryGroups: Category[][];

  columnsCount = 2;

  constructor(
    private router: Router,
    private categoriesService: CategoriesService,
    private subcategoriesService: SubcategoriesService,
    private cachedDataService: CachedDataService,
    private events: Events,
  ) {}

  ngOnInit(): void {
    if (this.isLandscape) {
      this.columnsCount = 4;
    }

    this.loadCategories();

    this.events.subscribe(SYNC_PRODUCTS_SUCCESS, () => {
      this.loadCategories();
    });
  }

  go(category: Category): void {
    if (this.isLandscape) {
      this.setCategory.emit(category);

      return;
    }

    this.subcategoriesService
      .findForApp({ categoryId: category.id })
      .subscribe((subcategories) => {
        if (subcategories.length === 1) {
          return this.router.navigateByUrl('/products', {
            state: {
              category: JSON.stringify(category),
              subcategory: JSON.stringify(subcategories[0]),
              emptyCategory: true,
            },
          });
        }

        return this.router.navigateByUrl('/subcategories', {
          state: {
            category: JSON.stringify(category),
            subcategories: JSON.stringify(subcategories),
          },
        });
      });
  }

  private loadCategories(): void {
    if (this.cachedDataService.getShopId() == null) {
      return;
    }

    this.categoriesService.findForApp().subscribe((categories) => {
      this.categoryGroups = chunk(categories, this.columnsCount);
    });
  }
}
