import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ClientScreenRoutingModule } from './client-screen-routing.module';
import { ClientScreenComponent } from './client-screen.component';

@NgModule({
  imports: [SharedModule, ClientScreenRoutingModule],
  declarations: [ClientScreenComponent],
})
export class ClientScreenModule {}
