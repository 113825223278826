import { PayFormCode } from '../../p-rro/fsco/enums/pay-form-code.enum';

export class SalePayment {
  id?: number;
  saleId: number;
  method: PayFormCode;
  amount: number;

  /**
   * Сума внесених коштів
   */
  providedCash: number;

  /**
   * Найменування еквайра торговця (текст)
   */
  acquirerName?: string;

  /**
   * Ідентифікатор транзакції, що надається еквайром та ідентифікує операцію в платіжній системі (128 символів)
   */
  acquirerTransactionId?: string;

  /**
   * Код авторизації (64 символи)
   */
  authCode?: string;

  /**
   * Ідентифікатор платіжного пристрою (128 символів)
   */
  deviceId?: string;

  /**
   * Реквізити електронного платіжного засобу (128 символів)
   */
  epzDetails?: string;

  /**
   * Найменування платіжної системи (текст)
   */
  paymentSystem?: string;

  /**
   * POS-термінал. Дата та час транзакції (ддммррррггххсс)
   */
  posTransactionDate?: Date;

  /**
   * POS-термінал. Номер чека транзакції (128 символів)
   */
  posTransactionNumber?: string;

  /**
   * Ідентифікатор транзакції у додатку Термінал від ПриватБанку
   */
  transactionId?: string;

  /**
   * Тип поточної транзакції згідно BPOS:
   * 0 – undefined,
   *
   * 1 – Purchase,
   *
   * 2 – Refund,
   *
   * 3 – Void
   */
  operationType?: number;

  /**
   * Перевірка підпису (CVM)
   */
  signVerification?: number;
}
