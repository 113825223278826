export class PrintShiftTotals {
  cashRemain: string;

  salesSummary: string;
  salesCashSummary: string;
  salesCardSummary: string;
  salesDiscountSummary: string;

  salesCount: string;
  salesCashAmount: string;
  salesCardAmount: string;
  salesTotalAmount: string;
  salesDiscount: string;

  saleReturnsCount: string;
  saleReturnsCashAmount: string;
  saleReturnsCardAmount: string;
  saleReturnsTotalAmount: string;
  saleReturnsDiscount: string;

  cashDeposits: string;
  cashIncasations: string;
  cardIncasations: string;
  cashFinanceExpenses: string;

  refillsCount: string;
  refillsAmount: string;
  refillsPaidCashAmount: string;

  wastesCount: string;
  wastesAmount: string;

  wasteReturnsCount: string;
  wasteReturnsAmount: string;
  wasteReturnsReceivedCashAmount: string;

  movesOutCount: string;
  movesInCount: string;
  movesInFromStockCount: string;
}
