<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="settings"></ion-back-button>
    </ion-buttons>

    <ion-title>Платіжний термінал</ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="openMenu()">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list [inset]="true" lines="full" class="settings">
    <ion-item>
      <ion-label  position="floating">Еквайр (Банк)</ion-label>
      <ion-input  type="text"
                  name="acquirerName"
                  [(ngModel)]="data.acquirerName"
                  (ngModelChange)="savePaymentTerminalData()"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label  position="floating">Термінал (ID)</ion-label>
      <ion-input  type="text"
                  name="deviceId"
                  [(ngModel)]="data.deviceId"
                  (ngModelChange)="savePaymentTerminalData()"></ion-input>
    </ion-item>

    <ion-item *ngIf="isDesktop || isAndroidApp">
      <ion-label>Суміщення</ion-label>
      <ion-select name="terminal" 
                  okText="Вибрати" 
                  cancelText="Скасувати"
                  class="select-fit"
                  [disabled]="connected"
                  [interfaceOptions]="{ cssClass: 'connection-protocol-select-option' }"
                  [(ngModel)]="terminal" 
                  (ngModelChange)="savePaymentTerminalData()">
        <ion-select-option *ngFor="let availableTerminal of availableTerminals" [value]="availableTerminal">
          {{ availableTerminal.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item *ngIf="terminal.agentIpSettings">
      <ion-label position="floating">IP-адреса посередника</ion-label>
      <ion-input  #agentIpModel="ngModel"
                  type="tel"
                  name="agentIp"
                  mask="IP"
                  placeholder="127.0.0.1"
                  maxlength="15"
                  pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                  [disabled]="connected"
                  [(ngModel)]="data.agentIp"
                  (ngModelChange)="savePaymentTerminalData()"
                  required></ion-input>

      <ion-note class="error-form-note" *ngIf="agentIpModel.errors?.required">
        IP-адреса обов'язкова для заповнення
      </ion-note>

      <ion-note class="error-form-note" *ngIf="agentIpModel.errors?.pattern">
        Неправильний формат IP-адреси (127.0.0.1)
      </ion-note>
    </ion-item>

    <ion-item button detail="false" (click)="downloadFiles()" *ngIf="terminal.hasDownloadFiles">
      <ion-icon slot="start" name="cloud-download-outline" color="secondary"></ion-icon>
      <ion-text>
        <ion-label>Завантажити файли для суміщення</ion-label>
        <ion-note>Вміст архіву потрібно розархівувати у папку C:\cashbox\{{ terminal.downloadPath }}</ion-note>
      </ion-text>
    </ion-item>
  </ion-list>

  <form [formGroup]="terminalForm" *ngIf="(isDesktop || isAndroidApp) && data.connectionProtocol">
    <ion-list [inset]="true" lines="full" class="settings">
      <ion-list-header>
        <ion-label>Налаштування суміщення</ion-label>
      </ion-list-header>

      <section *ngIf="terminal.connectionMethodSettings">
        <ion-item>
          <ion-label>Спосіб підключення</ion-label>
          <ion-select [formControlName]="KEY_CONNECTION_METHOD" okText="Вибрати" cancelText="Скасувати">
            <ion-select-option *ngFor="let method of connectionMethods" [value]="method.value">
              {{ method.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item *ngIf="terminalForm.get(KEY_CONNECTION_METHOD)?.value === terminalConnectionMethod.Ethernet">
          <ion-label position="floating">IP-адреса терміналу</ion-label>
          <ion-input [formControlName]="KEY_IP" type="tel" placeholder="0.0.0.0"></ion-input>
        </ion-item>

        <ng-container *ngFor="let validation of validationMessages.ip">
          <div class="error-message" *ngIf="terminalForm.get(KEY_IP).hasError(validation.type) && (terminalForm.get(KEY_IP).dirty || terminalForm.get(KEY_IP).touched)">
            <ion-icon slot="start" name="alert-circle-outline"></ion-icon>
            <ion-label>{{ validation.message }}</ion-label>
          </div>
        </ng-container>

        <ion-item *ngIf="terminalForm.get(KEY_CONNECTION_METHOD)?.value === terminalConnectionMethod.USB">
          <ion-label position="floating">Номер порту USB</ion-label>
          <ion-input [formControlName]="KEY_USB_PORT" type="tel"></ion-input>
        </ion-item>

        <ng-container *ngFor="let validation of validationMessages.usbPort">
          <div class="error-message" *ngIf="terminalForm.get(KEY_USB_PORT).hasError(validation.type) && (terminalForm.get(KEY_USB_PORT).dirty || terminalForm.get(KEY_USB_PORT).touched)">
            <ion-icon slot="start" name="alert-circle-outline"></ion-icon>
            <ion-label>{{ validation.message }}</ion-label>
          </div>
        </ng-container>
      </section>

      <ion-item *ngIf="terminal.serviceRefundSettings">
        <ion-label>Сервіс повернення</ion-label>

        <ion-button slot="end" color="medium" fill="clear" shape="round" size="small" (click)="showServiceRefundHelp()">
          <ion-icon slot="icon-only" name="information-circle-outline"></ion-icon>
        </ion-button>

        <ion-toggle slot="end" color="secondary" [formControlName]="KEY_SERVICE_REFUND" [checked]="terminalForm.get(KEY_SERVICE_REFUND)?.value"></ion-toggle>
      </ion-item>

      <ion-item *ngIf="terminal.autoDebugSettings && isDesktop">
        <ion-label>Автоматичне зняття логів (debug)</ion-label>
        <ion-toggle slot="end" color="secondary" [formControlName]="KEY_AUTO_DEBUG" [checked]="terminalForm.get(KEY_AUTO_DEBUG)?.value"></ion-toggle>
      </ion-item>

      <section *ngIf="terminal.deviceIdSettings">
        <ion-item>
          <div class="device-id">
            <ion-label>ID пристрою</ion-label>
            <ion-label class="ion-text-end" [color]="settings?.tapXphoneDeviceId ? 'dark' : 'danger'">
              {{ settings?.tapXphoneDeviceId ? settings?.tapXphoneDeviceId : 'Невідомий' }}
            </ion-label>
          </div>
        </ion-item>

        <div class="error-message ion-padding-start" *ngFor="let warning of tapXphoneWarnings">
          <ion-icon slot="start" name="alert-circle-outline"></ion-icon>
          <ion-label>{{ warning }}</ion-label>
        </div>

        <ion-item button detail="false" (click)="tapXphoneStatus()">
          <ion-label>Статус пристрою</ion-label>
        </ion-item>
      </section>

      <section *ngIf="terminal.merchantIdSettings">
        <ion-item>
          <ion-label position="floating">Номер мерчанту</ion-label>
          <ion-input [formControlName]="KEY_MERCHANT_ID" type="tel"></ion-input>
        </ion-item>

        <ng-container *ngFor="let validation of validationMessages.merchantId">
          <div class="error-message" *ngIf="terminalForm.get(KEY_MERCHANT_ID).hasError(validation.type) && (terminalForm.get(KEY_MERCHANT_ID).dirty || terminalForm.get(KEY_MERCHANT_ID).touched)">
            <ion-icon slot="start" name="alert-circle-outline"></ion-icon>
            <ion-label>{{ validation.message }}</ion-label>
          </div>
        </ng-container>
      </section>

      <div class="no-data" *ngIf="terminal.emptySettingsLabel">
        <span>{{ terminal.emptySettingsLabel }}</span>
      </div>

      <ion-list-header class="ion-padding-top">
        <ion-label>Операції</ion-label>
      </ion-list-header>

      <ion-item button detail="false" (click)="pingDevice()" *ngIf="terminal.pingOperation">
        <ion-label>Протокол</ion-label>
      </ion-item>

      <ion-item button detail="false" (click)="identify()" *ngIf="terminal.identifyOperation">
        <ion-label>Термінал</ion-label>
      </ion-item>

      <ion-item button detail="false" (click)="getMerchantList()" *ngIf="terminal.merchantListOperation">
        <ion-label>Мерчанти</ion-label>
      </ion-item>

      <ion-item button detail="false" (click)="audit()" *ngIf="terminal.auditOperation">
        <ion-label>{{ terminal.auditLabel }}</ion-label>
      </ion-item>

      <ion-item button detail="false" (click)="verify()" *ngIf="terminal.verifyOperation">
        <ion-label>{{ terminal.verifyLabel }}</ion-label>
      </ion-item>

      <ion-item button detail="false" (click)="debugMode()" *ngIf="terminal.debugMode && isDesktop">
        <ion-label>Debug</ion-label>
      </ion-item>

      <ion-item button detail="false" (click)="openApp()" *ngIf="terminal.openAppLabel">
        <ion-label>{{ terminal.openAppLabel }}</ion-label>
      </ion-item>

      <div class="no-data" *ngIf="terminal.emptyOperationsLabel">
        <span>{{ terminal.emptyOperationsLabel }}</span>
      </div>
    </ion-list>
  </form>
</ion-content>

<ion-footer *ngIf="terminal.activateButton">
  <ion-toolbar>
    <ion-button *ngIf="!connected" class="one-button" color="primary" (click)="connect()" [disabled]="terminalForm.invalid">
      Активувати
    </ion-button>

    <ion-button *ngIf="connected" class="one-button" color="primary" (click)="disconnect()">
      Відключити
    </ion-button>
  </ion-toolbar>
</ion-footer>
