import { TransactionType } from '../transaction-type.enum';

import { Transaction } from './transaction.model';

export class Incasation extends Transaction {
  // Unique fields
  money: number;
  deposit: number;
  depositComment: string;
  refillId: number;
  wasteId: number;

  constructor(
    data: { amount: number; comment?: string } = { amount: 0 },
    type: TransactionType = TransactionType.Incasation,
  ) {
    super(type, data.amount);

    this.money = type === TransactionType.Incasation ? data.amount : 0;
    this.deposit = type === TransactionType.Deposit ? data.amount : 0;

    if (data.comment != null) {
      if (type === TransactionType.Incasation) {
        this.comment = data.comment;
      } else if (type === TransactionType.Deposit) {
        this.depositComment = data.comment;
      }
    }
  }
}
