import { Component, Input, OnChanges } from '@angular/core';

import { ObjectLiteral } from '../../../core/interfaces/object-literal';

@Component({
  selector: 'bk-icon',
  templateUrl: './icon-component.html',
})
export class IconComponent implements OnChanges {
  @Input() name: string;
  @Input() color: string;
  @Input()
  customSize: {
    width: string;
    height: string;
  } = {
    width: '24px',
    height: '24px',
  };

  // Warning
  // Don't forget update 'src/theme/variables.scss'
  appColors: ObjectLiteral = {
    primary: '#eb1d39',
    secondary: '#00d127',
    tertiary: '#005891',
    success: '#33dc77',
    danger: '#f53d3d',
    dark: '#232323',
    light: '#fff',
  };

  customStyle: {};

  ngOnChanges(): void {
    this.customStyle = {
      width: this.customSize.width,
      height: this.customSize.height,
    };

    if (this.color) {
      if (this.color in this.appColors) {
        this.color = this.appColors[this.color];
      }

      this.customStyle = { ...this.customStyle, color: this.color };
    }
  }
}
