import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ClientScreenComponent } from './client-screen.component';

const routes: Routes = [
  {
    path: '',
    component: ClientScreenComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientScreenRoutingModule {}
