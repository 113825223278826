<div class="check" *ngIf="sale">
  <section>
    <div class="shop-logo" *ngIf="printShopLogoInReceipt && shop.checkLogoPath">
      <img [src]="shop.checkLogoPath">
    </div>

    <bk-view-line [center]="sale.companyName"></bk-view-line>
    <bk-view-line [center]="sale.shopName" [bold]="true"></bk-view-line>
    <bk-view-line [center]="sale.shopAddress"></bk-view-line>
    <bk-view-line [center]="sale.IPNRow"></bk-view-line>
    <bk-view-line [center]="sale.EDRPOURow" *ngIf="!sale.IPNRow"></bk-view-line>
    <hr>
  </section>

  <section class="products">
    <div class="product" *ngFor="let product of sale.products">
      <bk-view-line [line]="product.quantityAndPrice"></bk-view-line>
      <bk-view-line [line]="product.uktzedRow"></bk-view-line>
      <bk-view-line [line]="product.exciseLabelRow"></bk-view-line>
      <bk-view-line [start]="product.name" [end]="product.sum"></bk-view-line>
    </div>

    <hr>
  </section>

  <section class="border-top">
    <bk-view-line [start]="sale.acquirerName" [end]="sale.deviceId"></bk-view-line>
    <bk-view-line [line]="sale.posOperation"></bk-view-line>
    <bk-view-line [start]="epzCaption" [end]="sale.epzDetails"></bk-view-line>
    <bk-view-line [start]="paymentSystemCaption" [end]="sale.paymentSystem"></bk-view-line>
    <bk-view-line [start]="authCodeCaption" [end]="sale.authCode"></bk-view-line>
    <bk-view-line [start]="rrnCaption" [end]="sale.acquirerTransactionId"></bk-view-line>
    <bk-view-line [start]="checkNumberCaption" [end]="sale.posTransactionNumber" *ngIf="sale.posTransactionNumber && !sale.acquirerTransactionId && !sale.authCode"></bk-view-line>
  </section>

  <section class="border-top border-bottom">
    <bk-view-line [start]="debtSumCaption" [end]="sale.debtSumRow"></bk-view-line>
    <bk-view-line [start]="cardSumCaption" [end]="sale.cardSumRow"></bk-view-line>
    <bk-view-line [start]="cashSumCaption" [end]="sale.cashSumRow"></bk-view-line>
  </section>

  <section *ngIf="sale.discountSum">
    <bk-view-line [start]="costSumCaption" [end]="sale.costSum"></bk-view-line>
    <bk-view-line [start]="discountSumCaption" [end]="sale.discountSum"></bk-view-line>
    <hr>
  </section>
  
  <section>
    <bk-view-line [start]="totalSumCaption" [end]="sale.totalSum" [bold]="true"></bk-view-line>
    <hr>
  </section>

  <section>
    <div *ngFor="let tax of sale.taxes">
      <bk-view-line [start]="tax.name" [end]="tax.amount"></bk-view-line>
    </div>

    <hr>
  </section>

  <section *ngIf="sale.roundSum">
    <bk-view-line [start]="roundSumCaption" [end]="sale.roundSum"></bk-view-line>
    <bk-view-line [start]="paymentSumCaption" [end]="sale.paymentSum" [bold]="true"></bk-view-line>
    <hr>
  </section>

  <section *ngIf="sale.remainsSum">
    <bk-view-line [start]="providedSumCaption" [end]="sale.providedSum"></bk-view-line>
    <bk-view-line [start]="remainsSumCaption" [end]="sale.remainsSum" [bold]="true"></bk-view-line>
    <hr>
  </section>

  <section class="border-top" *ngIf="sale.comment">
    <bk-view-line [line]="sale.comment"></bk-view-line>
    <hr>
  </section>

  <section class="border-top">
    <div class="note break-spaces" *ngIf="sale.shopComment">
      {{ sale.shopComment }}
    </div>

    <bk-view-line [center]="thanks"></bk-view-line>
    <hr>
  </section>

  <section *ngIf="sale.isFiscal; else notFiscal">
    <bk-view-line [start]="sale.checkFiscalNumberRow" [end]="sale.date"></bk-view-line>

    <div class="note">
      <qrcode [qrdata]="sale.qrData"
              [margin]="1"
              [width]="96"
              [errorCorrectionLevel]="'L'"
              [elementType]="'svg'"></qrcode>
    </div>

    <bk-view-line [line]="sale.prroOnlineStatusRow"></bk-view-line>
    <bk-view-line [line]="sale.prroFiscalNumberRow"></bk-view-line>
    <hr>

    <div class="note">
      <a [href]="sale.qrData" target="_blank">{{ sale.checkType }}</a>
    </div>

    <bk-view-line [center]="sale.returnedCheckFiscalNumberRow"></bk-view-line>
  </section>

  <ng-template #notFiscal>
    <section>
      <bk-view-line [start]="'#' + sale.id" [end]="sale.date" *ngIf="sale.id; else withoutId"></bk-view-line>

      <ng-template #withoutId>
        <bk-view-line [center]="sale.date"></bk-view-line>
      </ng-template>

      <bk-view-line [center]="sale.returnSaleIdRow"></bk-view-line>
    </section>
  </ng-template>

  <bk-view-line [center]="prroName"></bk-view-line>

  <div class="page-break"></div>
</div>
