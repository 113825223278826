import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { iif, of, throwError, BehaviorSubject, Observable } from 'rxjs';
import { concatMap, delay, retryWhen, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { StorageTable } from '../../core/models/storage-table.model';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { LogsService } from '../logs/logs.service';

import { EleksService } from './eleks/eleks.service';
import { EleksRequest } from './eleks/request.interface';
import { EleksResponse } from './eleks/response.interface';
import { IntegrationSettings } from './integration-settings.interface';
import { Integration } from './integration.enum';
import {
  DEFAULT_ELEKS_AUTO_SALE,
  DEFAULT_ELEKS_INTERVAL,
  DEFAULT_ELEKS_PORT,
  DEFAULT_ELEKS_SELECT_FIRST_BY_PRICE,
  TOAST_TITLE,
} from './integrations.const';

const KEY_NAME = 'name';
const KEY_ELEKS_PORT = 'eleksPort';
const KEY_ELEKS_INTERVAL = 'eleksInterval';
const KEY_ELEKS_SELECT_FIRST_BY_PRICE = 'eleksSelectFirstByPrice';
const KEY_ELEKS_AUTO_SALE = 'eleksAutoSale';
const KEY_ELEKS_DEFAULT_PRODUCT_ID = 'eleksDefaultProductId';

// Deprecated
const KEY_PORT = 'port';
const KEY_INTERVAL = 'interval';
const KEY_SELECT_FIRST_BY_PRICE = 'selectFirstByPrice';
const KEY_AUTO_SALE = 'autoSale';
const KEY_DEFAULT_PRODUCT_ID = 'defaultProductId';

const RECONNECT_INTERVAL = 5000; // 5 seconds
const MAX_RECONNECT_ATTEMPTS = 10;

const LOG_KEY = 'integration';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  private integration: StorageTable;
  private webSocket: WebSocketSubject<EleksRequest>;
  private isConnectedSubject: BehaviorSubject<boolean>;

  private isDesktop = false;

  private retryAttempt = 0;

  constructor(
    private logsService: LogsService,
    private utilsService: UtilsService,
    private toastService: ToastService,
    private eleksService: EleksService,
  ) {
    this.integration = new StorageTable('integration');
    this.isConnectedSubject = new BehaviorSubject<boolean>(false);

    this.isDesktop = this.utilsService.isDesktop();
  }

  async clearData(): Promise<void> {
    await this.integration.clear();
  }

  private async getValue<T>(key: string, defaultValue: T): Promise<T> {
    const value = await this.integration.get<T>(key);

    if (value == null) {
      // TODO: Delete after updater up 5.9.92
      const deprecatedValue = await this.getDeprecatedValue<T>(key);

      if (deprecatedValue != null) {
        return deprecatedValue;
      }

      await this.integration.set<T>(key, defaultValue);

      return defaultValue;
    }

    return value;
  }

  private async getDeprecatedValue<T>(key: string): Promise<T | null> {
    let deprecatedKey = key;

    switch (key) {
      case KEY_ELEKS_PORT:
        deprecatedKey = KEY_PORT;
        break;

      case KEY_ELEKS_INTERVAL:
        deprecatedKey = KEY_INTERVAL;
        break;

      case KEY_ELEKS_SELECT_FIRST_BY_PRICE:
        deprecatedKey = KEY_SELECT_FIRST_BY_PRICE;
        break;

      case KEY_ELEKS_AUTO_SALE:
        deprecatedKey = KEY_AUTO_SALE;
        break;

      case KEY_ELEKS_DEFAULT_PRODUCT_ID:
        deprecatedKey = KEY_DEFAULT_PRODUCT_ID;
        break;
    }

    const deprecatedValue = await this.integration.get<T>(deprecatedKey);

    if (deprecatedValue != null) {
      await this.integration.set<T>(key, deprecatedValue);
      await this.integration.remove(deprecatedKey);

      return deprecatedValue;
    }

    return null;
  }

  async getSettings(): Promise<IntegrationSettings> {
    const name = await this.getValue(KEY_NAME, Integration.None);

    const eleksPort = await this.getValue(KEY_ELEKS_PORT, DEFAULT_ELEKS_PORT);
    const eleksInterval = await this.getValue(
      KEY_ELEKS_INTERVAL,
      DEFAULT_ELEKS_INTERVAL,
    );

    const eleksSelectFirstByPrice = await this.getValue(
      KEY_ELEKS_SELECT_FIRST_BY_PRICE,
      DEFAULT_ELEKS_SELECT_FIRST_BY_PRICE,
    );

    const eleksAutoSale = await this.getValue(
      KEY_ELEKS_AUTO_SALE,
      DEFAULT_ELEKS_AUTO_SALE,
    );

    const eleksDefaultProductId = await this.getValue(
      KEY_ELEKS_DEFAULT_PRODUCT_ID,
      null,
    );

    return {
      name,
      eleksPort,
      eleksInterval,
      eleksSelectFirstByPrice,
      eleksAutoSale,
      eleksDefaultProductId,
    };
  }

  async setSettings(data: IntegrationSettings): Promise<void> {
    await this.integration.set(KEY_NAME, data.name);
    await this.integration.set(KEY_ELEKS_PORT, data.eleksPort);
    await this.integration.set(KEY_ELEKS_INTERVAL, data.eleksInterval);
    await this.integration.set(
      KEY_ELEKS_SELECT_FIRST_BY_PRICE,
      data.eleksSelectFirstByPrice,
    );

    await this.integration.set(KEY_ELEKS_AUTO_SALE, data.eleksAutoSale);
    await this.integration.set(
      KEY_ELEKS_DEFAULT_PRODUCT_ID,
      data.eleksDefaultProductId,
    );
  }

  init(): void {
    if (!this.isDesktop) {
      return;
    }

    this.getSettings().then((integration) => {
      if (integration.name === Integration.None) {
        return;
      }

      if (integration.name === Integration.Eleks) {
        this.eleksService.loadProducts();
      }

      this.connect(integration);
    });
  }

  isConnected(): Observable<boolean> {
    return this.isConnectedSubject;
  }

  connect(integration: IntegrationSettings): void {
    this.disconnect();
    this.createWebSocketConnection(integration);

    this.retryAttempt = 0;

    this.webSocket
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            concatMap((error) =>
              iif(
                () => this.retryAttempt >= MAX_RECONNECT_ATTEMPTS,
                throwError(
                  'Перевищено ліміт спроб повторного підключення інтеграції',
                ),
                of(error).pipe(
                  tap(async () => {
                    await this.reconnectToWebSocketServer(this.retryAttempt);

                    this.retryAttempt += 1;
                  }),
                  delay(RECONNECT_INTERVAL),
                ),
              ),
            ),
          ),
        ),
      )
      .subscribe(
        async (data: EleksResponse) => {
          this.log(`Response [#timestamp]: ${JSON.stringify(data)}`);

          if (integration.name === Integration.Eleks) {
            if (data.error) {
              const message = `Помилка: ${data.errorDescription ?? ''}`;

              this.toastService.presentError(TOAST_TITLE, message);
              this.log(`> [#timestamp] ${TOAST_TITLE}: ${message}`);
            } else if (data.docs?.length > 0) {
              const settings = await this.getSettings();

              await this.eleksService.handleResponse(
                data,
                this.webSocket,
                settings,
              );
            }
          }
        },
        (error) => {
          const errorMessage = `Помилка обробки відповіді: ${this.parseConnectionError(
            error,
          )}`;

          this.log(`> Error [#timestamp] ${TOAST_TITLE}: ${errorMessage}`);
          this.toastService.presentError(TOAST_TITLE, errorMessage);
        },
        () => {
          const message = `З'єднання інтеграції завершене`;

          this.log(`> [#timestamp] ${message}`);
        },
      );
  }

  private createWebSocketConnection(integration: IntegrationSettings): void {
    const url =
      integration.name === Integration.Eleks
        ? `ws://127.0.0.1:${integration.eleksPort}/eleks`
        : `ws://127.0.0.1:${integration.eleksPort}/cashbox`;

    this.webSocket = webSocket({
      url,
      openObserver: {
        next: () => {
          this.successfullyConnected();
        },
      },
      closeObserver: {
        next: () => {
          this.successfullyDisconnected();
        },
      },
    });
  }

  private async reconnectToWebSocketServer(
    retryAttempt: number,
  ): Promise<void> {
    if (retryAttempt === 0) {
      this.startAgent();
    }

    this.isConnectedSubject.next(false);

    if (retryAttempt > 0) {
      const message = `Відновлення з'єднання (спроба ${retryAttempt}/${MAX_RECONNECT_ATTEMPTS})...`;

      this.toastService.presentWarning(TOAST_TITLE, message);
      this.log(`> [#timestamp] ${TOAST_TITLE}: ${message}`);
    }
  }

  checkNewDocs(): void {
    this.eleksService.checkNewDocs(this.webSocket);
  }

  private successfullyConnected(): void {
    this.isConnectedSubject.next(true);

    this.retryAttempt = 0;

    const message = 'Суміщення з інтеграцією активоване';

    this.log(`> [#timestamp] ${message}`);
    this.toastService.present(message);
  }

  private successfullyDisconnected(): void {
    this.isConnectedSubject.next(false);

    const message = 'Суміщення з інтеграцією завершене';

    this.log(`> [#timestamp] ${message}`);
    this.toastService.present(message);
  }

  private parseConnectionError(error: any): string {
    return typeof error === 'string' ? error : JSON.stringify(error);
  }

  private log(message: string): void {
    const value = message.replace(
      '[#timestamp]',
      `[${formatDate(new Date(), 'HH:mm:ss.sss', 'uk-UA')}]`,
    );

    // tslint:disable-next-line:no-console
    console.log(`Integration ${value}`);

    this.logsService.add(LOG_KEY, value).then().catch();
  }

  disconnect(): void {
    if (this.webSocket != null && !this.webSocket.closed) {
      this.webSocket.complete();
    }
  }

  startAgent(): void {
    this.toastService.present('Запуск агента інтеграції');
    window.location.assign('cashbox.eleks:launch');
  }
}
