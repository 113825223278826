<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="settings"></ion-back-button>
    </ion-buttons>

    <ion-title>Ваги</ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="openMenu()">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list [inset]="true" lines="full" class="settings" *ngIf="isDesktop || isAndroidApp">
    <ion-item>
      <ion-icon slot="start" name="git-branch-outline" [color]="isConnected ? 'success' : 'dark'"></ion-icon>
      <ion-label>Спосіб підключення</ion-label>
      <ion-select name="connectionMethod" 
                  okText="Вибрати" 
                  cancelText="Скасувати"
                  class="select-fit"
                  [disabled]="settings.connectionMethod !== scalesConnectionMethod.None && isConnected"
                  [interfaceOptions]="{ cssClass: 'scales-select-option' }"
                  [(ngModel)]="settings.connectionMethod"
                  (ngModelChange)="setConnectionMethod()">
        <ion-select-option *ngFor="let method of connectionMethods" [value]="method">
          {{ connectionMethodName(method) }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item button detail="false" (click)="downloadAgent()" *ngIf="settings.connectionMethod === scalesConnectionMethod.Cashbox">
      <ion-icon slot="start" name="cloud-download-outline" color="secondary"></ion-icon>
      <ion-text>
        <ion-label>Завантажити файли для суміщення</ion-label>
        <ion-note>Вміст архіву потрібно розархівувати у папку C:\cashbox\Scales</ion-note>
      </ion-text>
    </ion-item>
  </ion-list>

  <form #scalesForm="ngForm">
    <ion-list [inset]="true" lines="full" class="settings" *ngIf="settings.connectionMethod !== scalesConnectionMethod.None">
      <ion-list-header>
        <ion-label>Налаштування підключення</ion-label>
      </ion-list-header>

      <ion-item *ngIf="isScalesAvailable()">
        <ion-icon slot="start" name="scale-outline" [color]="isConnected ? 'success' : 'dark'"></ion-icon>
        <ion-label>Модель</ion-label>
        <ion-select id="scales"
                    name="scales"
                    okText="Вибрати"
                    cancelText="Скасувати"
                    [ngClass]="{ 'required' : settings.scales.name === '' }"
                    [interfaceOptions]="{ cssClass: 'scales-select-option' }"
                    [disabled]="settings.connectionMethod !== scalesConnectionMethod.None && isConnected"
                    [(ngModel)]="settings.scales"
                    (ngModelChange)="setScales()">
          <ion-select-option *ngFor="let scales of availableScales" [value]="scales">
            {{ scales.title }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <section *ngIf="settings.connectionMethod === scalesConnectionMethod.Cashbox">
        <ion-item>
          <ion-icon slot="start" name="code-download-outline" [color]="isConnected ? 'success' : 'dark'"></ion-icon>
          <ion-label position="stacked">COM-порт</ion-label>
          <ion-input  #comPortModel="ngModel"
                      type="tel"
                      name="comPort"
                      maxlength="2"
                      [placeholder]="DEFAULT_COM_PORT"
                      [disabled]="isConnected"
                      [(ngModel)]="settings.comPort"
                      (ngModelChange)="setScales()"
                      required></ion-input>
  
          <ion-note class="error-form-note" *ngIf="comPortModel.errors?.required">
            COM-порт обов'язковий для заповнення
          </ion-note>
        </ion-item>
      </section>

      <ion-item *ngIf="settings.connectionMethod !== scalesConnectionMethod.None">
        <ion-icon slot="start" name="options-outline" color="dark"></ion-icon>
        <ion-label>Додаткові налаштування</ion-label>
        <ion-toggle slot="end" color="dark" name="additionOptions" [(ngModel)]="additionOptions"></ion-toggle>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true" lines="full" class="settings" *ngIf="additionOptions && settings.connectionMethod !== scalesConnectionMethod.None">
      <ion-list-header>
        <ion-label>Додаткові налаштування</ion-label>
      </ion-list-header>

      <ion-item>
        <ion-icon slot="start" name="stopwatch-outline" [color]="isConnected ? 'success' : 'dark'"></ion-icon>
        <ion-label  position="stacked">Час очікування даних, мс</ion-label>
        <ion-input  #websocketPortModel="ngModel"
                    type="tel"
                    name="delay"
                    maxlength="4"
                    [min]="MIN_DELAY"
                    [bkMin]="MIN_DELAY"
                    [placeholder]="DEFAULT_DELAY"
                    [disabled]="isConnected"
                    [(ngModel)]="settings.delay"
                    (ngModelChange)="setScales()"
                    required></ion-input>

        <ion-note class="error-form-note" *ngIf="websocketPortModel.errors?.min">
          Мінімальний час очікування - 100 мс
        </ion-note>

        <ion-note class="error-form-note" *ngIf="websocketPortModel.errors?.required">
          Час очікування даних обов'язковий для заповнення
        </ion-note>
      </ion-item>

      <section *ngIf="settings.connectionMethod === scalesConnectionMethod.Cashbox">
        <ion-item>
          <ion-icon slot="start" name="link-outline" [color]="isConnected ? 'success' : 'dark'"></ion-icon>
          <ion-label  position="stacked">Websocket-порт</ion-label>
          <ion-input  #websocketPortModel="ngModel"
                      type="tel"
                      name="websocketPort"
                      maxlength="5"
                      [placeholder]="DEFAULT_WEBSOCKET_PORT"
                      [disabled]="isConnected"
                      [(ngModel)]="settings.websocketPort"
                      (ngModelChange)="setScales()"
                      required></ion-input>

          <ion-note class="error-form-note" *ngIf="websocketPortModel.errors?.required">
            Порт websocket-сервера обов'язковий для заповнення
          </ion-note>
        </ion-item>
      </section>
    </ion-list>
  </form>
</ion-content>

<ion-footer *ngIf="settings.connectionMethod !== scalesConnectionMethod.None">
  <ion-toolbar>
    <ion-button *ngIf="!isConnected" class="one-button" color="primary" (click)="connect()" [disabled]="scalesForm.invalid">
      Активувати
    </ion-button>

    <ion-button *ngIf="isConnected" class="one-button" color="primary" (click)="disconnect()">
      Відключити
    </ion-button>
  </ion-toolbar>
</ion-footer>
