import {
  REFILL_HEADER_NAME_CAPTION,
  REFILL_HEADER_QUANTITY_CAPTION,
  REFILL_HEADER_SUM_CAPTION,
  REFILL_PAID_SUM_CAPTION,
  REFILL_PROVIDER_NAME_CAPTION,
  REFILL_RECIPIENT_SIGN_CAPTION,
  REFILL_SENDER_SIGN_CAPTION,
  REFILL_SHOP_NAME_CAPTION,
  REFILL_TOTAL_SUM_CAPTION,
} from '../receipt.const';

import { PrintProductItem } from './print-product-item.model';
import { PrintReceipt } from './print-receipt.model';

export class PrintRefill extends PrintReceipt {
  date: string;
  name: string;
  providerName: string;
  shopName: string;
  userName: string;

  amount: string;
  amountByWords: string;

  paidAmount: string;
  paidAmountByWords: string;

  products: PrintProductItem[];

  receipt(): string[] {
    const receipt: string[] = [];

    receipt.push(...this.alignToLeft(`${this.name} ${this.date}`));
    receipt.push(this.rowDevider());

    receipt.push(
      ...this.alignToLeft(
        `${REFILL_PROVIDER_NAME_CAPTION}: ${this.providerName}`,
      ),
    );

    receipt.push(
      ...this.alignToLeft(`${REFILL_SHOP_NAME_CAPTION}: ${this.shopName}`),
    );

    const indexLength = 3;
    const quantityLength = 6;
    const sumLength = 8;

    const numbersLength = quantityLength + sumLength;
    const nameLength = this.rowLength - numbersLength - indexLength;

    const headerIndexRow1 = '#'.padEnd(indexLength, ' ');
    const headerNameRow1 = REFILL_HEADER_NAME_CAPTION.padEnd(nameLength, ' ');

    const headerQuantityRow1 = REFILL_HEADER_QUANTITY_CAPTION.padStart(
      quantityLength,
      ' ',
    );

    const headerSumRow1 = REFILL_HEADER_SUM_CAPTION.padStart(sumLength, ' ');

    receipt.push(this.rowDevider());

    receipt.push(
      `${this.prefix()}${headerIndexRow1}${headerNameRow1}${headerQuantityRow1}${headerSumRow1}`,
    );

    receipt.push(this.rowDevider());

    this.products.forEach((product, index) => {
      const indexValue = `${(index + 1).toString().padEnd(indexLength, ' ')}`;
      const nameValue = `${product.name}, ${product.type}`;
      const numbersValue = `${product.quantity.padStart(
        quantityLength,
        ' ',
      )}${product.sum.padStart(sumLength, ' ')}`;

      this.formatReceiptTableRow(
        receipt,
        indexValue,
        indexLength,
        nameValue,
        nameLength,
        numbersValue,
        numbersLength,
      );
    });

    receipt.push(this.rowDevider());

    this.addTwoColumnItemToReceipt(
      `${REFILL_TOTAL_SUM_CAPTION}`,
      this.amount,
      receipt,
    );

    receipt.push(...this.alignToLeft(`${this.amountByWords}`));

    if (this.paidAmount > '') {
      this.addTwoColumnItemToReceipt(
        `${REFILL_PAID_SUM_CAPTION}`,
        this.paidAmount,
        receipt,
      );

      receipt.push(...this.alignToLeft(`${this.paidAmountByWords}`));
    }

    receipt.push(`${this.prefix()}`);

    this.addTwoColumnItemToReceipt(
      `${REFILL_SENDER_SIGN_CAPTION} ${''.padEnd(
        (this.rowLength - 2) / 2 - REFILL_SENDER_SIGN_CAPTION.length - 1,
        '_',
      )}`,
      `${REFILL_RECIPIENT_SIGN_CAPTION} ${''.padEnd(
        (this.rowLength - 2) / 2 - REFILL_RECIPIENT_SIGN_CAPTION.length - 1,
        '_',
      )}`,
      receipt,
    );

    return receipt;
  }
}
