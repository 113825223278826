import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AlertController,
  IonDatetime,
  IonItemSliding,
  ModalController,
} from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import remove from 'lodash-es/remove';
import round from 'lodash-es/round';
import { DateTime } from 'luxon';

import { UA_MONTHS_GENITIVE } from '../core/constants/date.const';
import { SALE_CALCULATION_DIALOG_ID } from '../core/constants/modal-controller.const';
import { PRESALE_GROUP_ROWS } from '../core/constants/visible-rows.const';
import { Shift } from '../core/models/shift.model';
import { User } from '../core/models/user.model';
import { LoadingService } from '../core/services/loading.service';
import { ShiftsService } from '../core/services/resources/shifts.service';
import { UserService } from '../core/services/resources/user.service';
import { UtilsService } from '../core/services/utils.service';
import { SaleProduct } from '../sales/sale/sale-product.model';
import { ReceiptPrinterService } from '../settings/printer/receipt/receipt-printer.service';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../settings/settings.const';
import { SaleCalculationDialog } from '../shop/sale-calculation/sale-calculation.dialog';

import { PresaleStatus } from './presale/presale-status.enum';
import { Presale } from './presale/presale.model';
import { PresalesGroup } from './presales-group.model';
import { PresalesService } from './presales.service';

@Component({
  selector: 'bk-presales',
  templateUrl: './presales.component.html',
  styleUrls: ['./presales.component.scss'],
})
export class PresalesComponent implements OnInit {
  @ViewChild(IonDatetime) dateTime: IonDatetime;
  @ViewChild(IonItemSliding) itemSliding: IonItemSliding;

  presaleGroups: PresalesGroup[] = [];
  presales: Presale[];
  shift: Shift;
  user: User;
  updatedPresale?: Presale;

  TAB_CREATED = 'created';
  TAB_COMPLETED = 'completed';

  UA_MONTHS = UA_MONTHS_GENITIVE.map((month) => month.toLowerCase());

  activeTabName: string;
  activeTab: PresaleStatus;
  status = PresaleStatus;

  minDate: string;
  maxDate: string;
  presaleDate: string;

  requestInProgress = false;
  isShiftRefreshing = false;

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private presalesService: PresalesService,
    private utilsService: UtilsService,
    private receiptPrinterService: ReceiptPrinterService,
    private loadingService: LoadingService,
    private shiftsService: ShiftsService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.activeTabName = this.TAB_CREATED;
    this.activeTab = PresaleStatus.Created;

    this.setDateTimeRange();

    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });
  }

  ionViewWillEnter(): void {
    this.refreshShiftData();

    this.presaleGroups = [];

    this.presalesService
      .find({
        shiftId: this.shiftsService.getCurrentShiftId(),
        isMobile: true,
      })
      .subscribe((sales) => {
        this.presales = sales;

        this.presales.forEach((presale) => {
          this.checkAndCreateGroup(presale);
        });

        this.regroupPresales(this.presales);
      });
  }

  private setDateTimeRange(): void {
    const minute = DateTime.now().minute;

    this.minDate = DateTime.now()
      .plus({
        minutes: minute > 55 ? 60 - minute : 0,
      })
      .toISO();

    this.maxDate = DateTime.now().plus({ days: 30 }).toISO();
  }

  private checkAndCreateGroup(
    presale: Presale,
    collapsed: boolean = true,
  ): void {
    if (
      this.presaleGroups.find(
        (group) => group.date === presale.completeBy.toLocaleDateString(),
      ) == null
    ) {
      const newGroup = new PresalesGroup(
        presale.completeBy.toLocaleDateString(),
      );

      newGroup.collapsed = collapsed;

      this.presaleGroups.push(newGroup);
      this.presaleGroups.sort(
        (a, b) =>
          new Date(b.date.split('.').reverse().join('-')).getTime() -
          new Date(a.date.split('.').reverse().join('-')).getTime(),
      );
    }
  }

  private refreshShiftData(): void {
    this.isShiftRefreshing = true;

    this.shiftsService.getShift().subscribe((shift) => {
      if (shift) {
        this.shift = shift;
      }

      this.isShiftRefreshing = false;
    });
  }

  toggleGroupPresale(group: PresalesGroup): void {
    group.collapsed = !group.collapsed;

    if (group.collapsed) {
      group.visibleRows = PRESALE_GROUP_ROWS;
    }
  }

  getPresales(group: PresalesGroup): Presale[] {
    if (this.activeTab === PresaleStatus.Created) {
      return group.createdPresales;
    }

    return group.completedPresales;
  }

  showMorePresales(group: PresalesGroup): void {
    group.visibleRows = Math.min(
      this.activeTab === PresaleStatus.Created
        ? group.createdPresales.length
        : group.completedPresales.length,
      group.visibleRows + PRESALE_GROUP_ROWS,
    );
  }

  changedTab(event: any): void {
    switch (event.detail.value) {
      case this.TAB_CREATED:
        this.activeTabName = this.TAB_CREATED;
        this.activeTab = PresaleStatus.Created;
        break;

      case this.TAB_COMPLETED:
        this.activeTabName = this.TAB_COMPLETED;
        this.activeTab = PresaleStatus.Completed;
        break;
    }

    this.presaleGroups.forEach((group) => {
      if (!group.collapsed) {
        this.setVisibleRowsCount(group);
      }
    });
  }

  async sale(presale: Presale, group: PresalesGroup): Promise<void> {
    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    const saleProducts: SaleProduct[] = [];

    presale.presaleProducts.forEach((presaleProduct) => {
      const saleProduct = this.utilsService.getSaleProduct(presaleProduct);

      saleProducts.push(saleProduct);
    });

    const modal = await this.modalCtrl.create({
      component: SaleCalculationDialog,
      componentProps: {
        presale,
        saleProducts,
      },
      backdropDismiss: false,
      cssClass: 'payment-dialog',
      id: SALE_CALCULATION_DIALOG_ID,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.isSuccess) {
      presale.status = PresaleStatus.Completed;

      remove(
        group.createdPresales,
        (createdPresale) => createdPresale.id === presale.id,
      );

      group.completedPresales.splice(0, 0, ...[presale]);
      group.completedPresales.sort(
        (a, b) => b.completeBy.getTime() - a.completeBy.getTime(),
      );
    }

    this.requestInProgress = false;
  }

  async print(presale: Presale): Promise<void> {
    this.itemSliding.close();

    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    await this.receiptPrinterService.printPresale(presale, { viewMode: true });

    this.requestInProgress = false;
  }

  async updateTime(presale: Presale): Promise<void> {
    this.itemSliding.close();

    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    const alert = await this.alertCtrl.create({
      header: `Час виконання`,
      message: `<strong>Змінити</strong> час виконання замовлення (відкладеного чека)?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
          handler: () => {
            this.requestInProgress = false;
          },
        },
        {
          text: 'Змінити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            this.updatedPresale = cloneDeep(presale);

            this.setDateTimeRange();

            await this.dateTime.open();
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  async delete(presale: Presale, group: PresalesGroup): Promise<void> {
    this.itemSliding.close();

    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    const alert = await this.alertCtrl.create({
      header: `Відкладений чек`,
      message: `<strong>Видалити</strong> відкладений чек?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
          handler: () => {
            this.requestInProgress = false;
          },
        },
        {
          text: 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            const spinnerName = 'presales.component:cancelPresale';

            await this.loadingService.presentCustomPreloader(spinnerName);

            presale.status = PresaleStatus.Canceled;

            const isConnected = await this.utilsService.isOnline();

            this.presalesService
              .updatePresale(presale, isConnected)
              .then(async (returnedPresale) => {
                remove(
                  group.createdPresales,
                  (createdPresale) => createdPresale.id === returnedPresale.id,
                );

                this.refreshShiftData();

                await this.loadingService.dismiss(spinnerName);
              })
              .finally(() => {
                this.requestInProgress = false;
              });
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  async dateTimeChange(): Promise<void> {
    if (!this.updatedPresale) {
      this.requestInProgress = false;

      return;
    }

    const spinnerName = 'presales.component:dateTimeChange';

    await this.loadingService.presentCustomPreloader(spinnerName);

    const isConnected = await this.utilsService.isOnline();
    const currentCompleteBy = this.updatedPresale.completeBy;

    this.updatedPresale.completeBy = new Date(this.presaleDate);

    this.presalesService
      .updatePresale(this.updatedPresale, isConnected)
      .then((returnedPresale) => {
        const currentGroup = this.presaleGroups.find((group) =>
          group.createdPresales.find(
            (presale) => presale.id === returnedPresale.id,
          ),
        );

        if (currentGroup) {
          const currentPresale = currentGroup.createdPresales.find(
            (presale) => presale.id === returnedPresale.id,
          );

          if (currentPresale) {
            currentPresale.completeBy = new Date(returnedPresale.completeBy);

            this.refreshGroupsOfCreated(
              currentGroup,
              currentPresale,
              currentCompleteBy,
            );
          }
        }
      })
      .finally(() => {
        this.refreshShiftData();
        this.loadingService.dismiss(spinnerName);

        this.presaleDate = new Date().toISOString();
        this.updatedPresale = undefined;
        this.requestInProgress = false;
      });
  }

  private refreshGroupsOfCreated(
    currentGroup: PresalesGroup,
    currentPresale: Presale,
    currentCompleteBy: Date,
  ): void {
    if (
      currentCompleteBy.toLocaleDateString() !==
      currentPresale.completeBy.toLocaleDateString()
    ) {
      this.checkAndCreateGroup(currentPresale, false);

      const newGroup = this.presaleGroups.find(
        (group) =>
          group.date === currentPresale.completeBy.toLocaleDateString(),
      );

      if (newGroup) {
        newGroup.createdPresales.splice(0, 0, ...[currentPresale]);
        newGroup.visibleRows += 1;

        remove(
          currentGroup.createdPresales,
          (createdPresale) => createdPresale.id === currentPresale.id,
        );

        newGroup.createdPresales.sort(
          (a, b) => b.completeBy.getTime() - a.completeBy.getTime(),
        );
      }
    } else {
      currentGroup.createdPresales.sort(
        (a, b) => b.completeBy.getTime() - a.completeBy.getTime(),
      );
    }
  }

  dateTimeCancel(): void {
    //
  }

  private setVisibleRowsCount(group: PresalesGroup): void {
    group.visibleRows = Math.min(
      this.activeTab === PresaleStatus.Created
        ? group.createdPresales.length
        : group.completedPresales.length,
      PRESALE_GROUP_ROWS,
    );
  }

  private regroupPresales(presales: Presale[]): void {
    this.presaleGroups.forEach((group, index) => {
      if (index === 0) {
        this.presaleGroups[0].collapsed = false;
      }

      presales.forEach((presale) => {
        if (presale.completeBy.toLocaleDateString() === group.date) {
          this.calcPresale(presale, group);
        }
      });

      this.setVisibleRowsCount(group);
    });
  }

  private calcPresale(presale: Presale, group: PresalesGroup): void {
    presale.costSum = 0;
    presale.discountSum = 0;
    presale.roundSum = 0;
    presale.paymentSum = 0;
    presale.changeSum = 0;
    presale.totalDiscount = 0;
    presale.totalFreeCups = 0;
    presale.totalBonus = 0;

    let productExist = false;

    presale.presaleProducts.forEach((presaleProduct) => {
      presaleProduct.cost = round(
        presaleProduct.quantity * presaleProduct.price,
        2,
      );

      presaleProduct.discount = round(
        (presaleProduct.personalDiscount ?? 0) +
          (presaleProduct.freeCup ?? 0) +
          (presaleProduct.bonusPayment ?? 0),
        2,
      );

      presaleProduct.fullCost = round(
        presaleProduct.cost + presaleProduct.discount,
        2,
      );

      presaleProduct.fullPrice = round(
        presaleProduct.fullCost / presaleProduct.quantity,
        2,
      );

      presale.costSum += presaleProduct.fullCost;
      presale.discountSum += presaleProduct.discount;

      presale.totalDiscount += presaleProduct.personalDiscount ?? 0;
      presale.totalFreeCups += presaleProduct.freeCup ?? 0;
      presale.totalBonus += presaleProduct.bonusPayment ?? 0;

      if (presaleProduct.quantity) {
        productExist = true;
      }
    });

    const totalPaymentWithoutRound = presale.costSum - presale.discountSum;

    presale.roundSum = !presale.cashless
      ? this.utilsService.getRoundSum(
          totalPaymentWithoutRound - presale.cardSum,
        )
      : 0;

    presale.paymentSum = totalPaymentWithoutRound + presale.roundSum;
    presale.changeSum = Math.max(presale.providedCash - presale.cashSum, 0);

    presale.costSum = round(presale.costSum, 2);
    presale.discountSum = round(presale.discountSum, 2);
    presale.roundSum = round(presale.roundSum, 2);
    presale.paymentSum = round(presale.paymentSum, 2);
    presale.cashSum = round(presale.cashSum, 2);
    presale.cardSum = round(presale.cardSum, 2);
    presale.providedCash = round(presale.providedCash, 2);
    presale.changeSum = round(presale.changeSum, 2);

    if (productExist) {
      switch (presale.status) {
        case PresaleStatus.Created:
          group.createdPresales.push(presale);
          break;

        case PresaleStatus.Completed:
          group.completedPresales.push(presale);
          break;
      }
    }
  }
}
