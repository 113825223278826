import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  selector: '[bkMin]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinValidator, multi: true },
  ],
})
export class MinValidator implements Validator {
  @Input() bkMin: number;

  validate(control: AbstractControl): ValidationErrors | null {
    if ((control.value || control.value === 0) && control.value < this.bkMin) {
      return { min: true };
    }

    return null;
  }
}
