<ion-header>
  <ion-toolbar>
    <ion-title>Адреса доставки</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label position="floating">Телефон</ion-label>
          <ion-input type="tel" name="phone" [(ngModel)]="phone" autocomplete="off"></ion-input>
        </ion-item>
      </ion-col>  
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label position="floating">Місто/село</ion-label>
          <ion-input type="text" name="locality" [(ngModel)]="locality"></ion-input>
        </ion-item>
      </ion-col>  
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label position="floating">Вулиця</ion-label>
          <ion-input type="text" name="street" [(ngModel)]="street"></ion-input>
        </ion-item>
      </ion-col>  
    </ion-row>

    <ion-row>
      <ion-col size="3">
        <ion-item>
          <ion-label position="floating">Будинок</ion-label>
          <ion-input type="text" name="house" [(ngModel)]="house"></ion-input>
        </ion-item>
      </ion-col>  

      <ion-col size="3">
        <ion-item>
          <ion-label position="floating">Квартира</ion-label>
          <ion-input type="text" name="apartment" [(ngModel)]="apartment"></ion-input>
        </ion-item>
      </ion-col>  

      <ion-col size="3">
        <ion-item>
          <ion-label position="floating">Поверх</ion-label>
          <ion-input type="tel" name="floor" [(ngModel)]="floor"></ion-input>
        </ion-item>
      </ion-col>  

      <ion-col size="3">
        <ion-item>
          <ion-label position="floating">Під'їзд</ion-label>
          <ion-input type="tel" name="entrance" [(ngModel)]="entrance"></ion-input>
        </ion-item>
      </ion-col>  
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item>
          <ion-segment color="dark" (ionChange)="changedLock($event)" [value]="lock">
            <ion-segment-button value="">
              <ion-label>Не вказано</ion-label>
            </ion-segment-button>
            
            <ion-segment-button value="Домофон">
              <ion-label>Домофон</ion-label>
            </ion-segment-button>
    
            <ion-segment-button value="Кодовий замок">
              <ion-label>Кодовий замок</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-item>
      </ion-col>  
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label position="floating">Коментар</ion-label>
    
          <ion-input type="text"
                     name="comment"
                     [(ngModel)]="comment"
                     (keyup.enter)="save()"></ion-input>
        </ion-item>
      </ion-col>  
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="two-buttons" slot="start" color="tertiary" (click)="cancel()">Скасувати</ion-button>
    <ion-button class="two-buttons" slot="end" color="primary" (click)="save()">Підтвердити</ion-button>
  </ion-toolbar>
</ion-footer>