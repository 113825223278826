import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { environment } from '../../../../environments/environment';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../../settings.const';

import { QzTrayService } from './qz-tray.service';

@Component({
  selector: 'bk-qz-tray',
  templateUrl: './qz-tray.component.html',
})
export class QzTrayComponent {
  constructor(
    private alertCtrl: AlertController,
    private qzTrayService: QzTrayService,
  ) {}

  downloadQzTray(): void {
    window.open('https://qz.io/download/', '_blank');
  }

  downloadQzCert(): void {
    window.open(`${environment.apiUrl}/settings/qz-print-cert`, '_blank');
  }

  launchQzTray(): void {
    window.location.assign('qz:launch');
  }

  async clearQzTrayData(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Сервіс друку QZ Tray',
      message: `
        Після видалення перестане працювати прямий друк чеків (без додаткових діалогових вікон).
        Частина даних буде завантажена повторно після авторизації.
        Щоб відновити поточний режим друку, наобхідно буде вибрати принтер після авторизації.<br><br>
        <strong>Видалити</strong> дані?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            await this.qzTrayService.clearData();
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }
}
