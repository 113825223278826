import { CheckDocumentSubType } from '../enums/check-document-sub-type.enum';
import { CheckDocumentType } from '../enums/check-document-type.enum';

import { PRroCheck } from './p-rro-check.model';

export class PRroServiceCheck extends PRroCheck {
  /**
   * Розширений тип документа (числовий):
   *
   * 0-Касовий чек (реалізація),
   *
   * 1-Видатковий чек (повернення),
   *
   * 2-Чек операції "службове внесення"/"отримання авансу",
   *
   * 3-Чек операції "отримання підкріплення",
   *
   * 4–Чек операції "службова видача"/"інкасація"
   */
  checkSubType: CheckDocumentSubType;

  /**
   * Загальна сума (15.2 цифри)
   */
  totalSum: number;

  constructor(
    type: CheckDocumentType,
    subType: CheckDocumentSubType,
    date?: Date,
  ) {
    super(type, date);

    this.checkSubType = subType;
    this.totalSum = 0;
  }
}
