import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

import { environment } from '../../../environments/environment';
import { IStorageUserSettings } from '../../core/interfaces/storage-user-setting.interface';
import { CachedDataService } from '../../core/services/cached-data.service';
import { UtilsService } from '../../core/services/utils.service';
import { ProductsService } from '../../shop/products/products.service';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../settings.const';

import { EleksSelectDialog } from './eleks/select-dialog/eleks-select.dialog';
import { Integration } from './integration.enum';
import { IntegrationService } from './integration.service';
import {
  DEFAULT_ELEKS_INTERVAL,
  DEFAULT_ELEKS_NO_DEFAULT,
  DEFAULT_SETTINGS,
} from './integrations.const';

@Component({
  selector: 'bk-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit {
  readonly integration = Integration;

  userSettings: IStorageUserSettings;

  isDesktop: boolean;
  isConnected: boolean;

  integrations: Integration[] = [Integration.None, Integration.Eleks];
  intervals = [2, 3, 4, DEFAULT_ELEKS_INTERVAL, 10];

  settings = DEFAULT_SETTINGS;
  defaultProductName = DEFAULT_ELEKS_NO_DEFAULT;

  requestInProgress = false;

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private cachedDataService: CachedDataService,
    private utilsService: UtilsService,
    private integrationService: IntegrationService,
    private productsService: ProductsService,
  ) {
    this.userSettings = this.cachedDataService.getUserSettings();
    this.isDesktop = this.utilsService.isDesktop();
  }

  async ngOnInit(): Promise<void> {
    this.integrationService.isConnected().subscribe((active) => {
      this.isConnected = active;
      this.requestInProgress = false;
    });
  }

  async ionViewWillEnter(): Promise<void> {
    this.settings = await this.integrationService.getSettings();

    if (this.settings.name === Integration.Eleks) {
      this.productsService
        .findForApp({}, { useIndexedDb: true })
        .subscribe((products) => {
          const product = products.find(
            (p) => p.id === this.settings.eleksDefaultProductId,
          );

          if (product != null) {
            this.defaultProductName = product.name;
          }
        });
    }
  }

  integrationName(integration: Integration): string {
    if (integration === Integration.Eleks) {
      return 'Doctor Eleks';
    }

    return 'Немає';
  }

  async setIntegration(): Promise<void> {
    await this.integrationService.setSettings(this.settings);

    if (this.settings.name === Integration.None) {
      this.integrationService.disconnect();
    }
  }

  async selectProduct(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: EleksSelectDialog,
      componentProps: {},
      backdropDismiss: false,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.item != null) {
      this.defaultProductName = data.item.name;
      this.settings.eleksDefaultProductId = data.item.id;

      await this.integrationService.setSettings(this.settings);
    }
  }

  async deleteProduct(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Найменування за замовчуванням',
      message: `<strong>Видалити</strong> найменування за замовчуванням?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            this.defaultProductName = DEFAULT_ELEKS_NO_DEFAULT;
            this.settings.eleksDefaultProductId = null;

            await this.integrationService.setSettings(this.settings);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  downloadAgent(): void {
    window.open(`${environment.apiUrl}/settings/eleks`, '_blank');
  }

  startAgent(): void {
    this.integrationService.startAgent();
  }

  connect(): void {
    this.requestInProgress = true;

    this.integrationService.connect(this.settings);
  }

  disconnect(): void {
    this.requestInProgress = true;

    this.integrationService.disconnect();
  }
}
