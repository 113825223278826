import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { CONFIRM_DIALOG_ALERT_STYLE } from '../settings.const';

import { LogsService } from './logs.service';

@Component({
  selector: 'bk-logs',
  templateUrl: './logs.page.html',
  styleUrls: ['./logs.page.scss'],
})
export class LogsPageComponent implements OnInit {
  keys: string[] = [];

  constructor(
    private alertCtrl: AlertController,
    private logsService: LogsService,
  ) {}

  ngOnInit(): void {
    this.logsService.keys().then((keys) => {
      this.keys = keys;
    });
  }

  async remove(key: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Логи',
      message: `
        <strong>Видалити</strong> лог "${key}"?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: () => {
            this.logsService
              .remove(key)
              .then(() => {
                this.ngOnInit();
              })
              .catch();
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  async clear(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Логи',
      message: `
        <strong>Видалити</strong> всі логи?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: () => {
            this.logsService
              .clear()
              .then(() => {
                this.keys = [];
              })
              .catch();
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }
}
