import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
} from '@angular/router';

import { CachedDataService } from '../services/cached-data.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticsGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private router: Router,
    private cachedDataService: CachedDataService,
  ) {}

  canActivate(): boolean {
    return this.hasAccess();
  }

  canActivateChild(): boolean {
    return this.hasAccess();
  }

  canLoad(): boolean {
    return this.hasAccess();
  }

  hasAccess(): boolean {
    const user = this.cachedDataService.getUser();

    if (!user.statistics) {
      this.router.navigateByUrl('/shop');
    }

    return user.statistics;
  }
}
