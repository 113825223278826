import { Component, Input, OnInit } from '@angular/core';

import { CachedImageService } from '../../../core/services/cached-image.service';

@Component({
  selector: 'bk-cached-image',
  templateUrl: './cached-image.component.html',
  styleUrls: ['./cached-image.component.scss'],
})
export class CachedImageComponent implements OnInit {
  @Input()
  set src(imageUrl: string) {
    this.imageSrc = this.cachedImageService.readCoverAsFilePath(imageUrl);
  }

  imageSrc = '';

  constructor(private cachedImageService: CachedImageService) {}

  ngOnInit(): void {}
}
