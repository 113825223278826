export const APP_VERSION = 'cashbox-app';
export const APP_VERSION_WEB = 'cashbox-web';
export const APP_VERSION_WEB_PREFIX = 'Web';
export const APP_VERSION_ANDROID = 'cashbox-android';
export const APP_VERSION_IOS = 'cashbox-ios';
export const CURRENT_BARCODE_SCANNER = 'currentBarcodeScanner';
export const CONNECTION_ERROR = 'connection error';
export const SALES_STORAGE_KEY = 'sales';
export const PRESALES_STORAGE_KEY = 'presales';
export const INCASATIONS_STORAGE_KEY = 'incasations';
export const FINANCE_EXPENSES_STORAGE_KEY = 'financeExpenses';
export const CRYPT_DATA_STORAGE_KEY = 'сryptData';
export const USERS_SETTINGS_STORAGE_KEY = 'usersSettings';
export const APP_SETTINGS_STORAGE_KEY = 'appSettings';
export const PRRO_NAME = 'cashbox.in.ua';
export const KEYBOARD_MODE_DEFAULT_VALUE = true;
export const DARK_THEME_MODE_DEFAULT_VALUE = false;
export const QUICK_SALE_BUTTONS_MODE_DEFAULT_VALUE = true;
export const FISCAL_MODE_BY_DEFAULT_DEFAULT_VALUE = true;
export const PRODUCTS_AS_LIST_MODE_DEFAULT_VALUE = false;
export const ROUND_INVOICE_TOTAL_SUM_DEFAULT_VALUE = false;
export const SHOW_COMMENT_IN_PAYMENT_DEFAULT_VALUE = false;
export const TAB_CONTROL_DEFAULT_VALUE = false;
export const USE_TSP_DEFAULT_VALUE = true;
export const CONFIRM_DIALOG_STYLE = 'confirm-dialog';
export const CONFIRM_DIALOG_ALERT_STYLE = 'confirm-dialog-alert';
export const INDEXEDDB_TABLE_DATA = 'data';

export const SUPPORT_EMAIL_NAME = 'support-email';
export const CASHBOX_URL_NAME = 'cashbox-url';
export const CORS_PROXY_URL_NAME = 'cors-proxy-url';
export const DPS_PRRO_URL_NAME = 'dps-prro-url';
export const DPS_PRRO_API_HTTPS_URL_NAME = `dps-prro-api-https-url`;
export const DPS_PRRO_API_HTTP_URL_NAME = `dps-prro-api-http-url`;
export const DPS_PRRO_CHECK_URL_NAME = `dps-prro-check-url`;
export const MAX_SALE_AMOUNT_NAME = 'max-sale-amount';

export const DEFAULT_SUPPORT_EMAIL = 'cashbox.in.ua@gmail.com';
export const DEFAULT_CASHBOX_URL = `https://cashbox.in.ua`;
export const DEFAULT_CORS_PROXY_URL = 'https://cors.cashbox.in.ua';
export const DEFAULT_DPS_PRRO_URL = 'fs.tax.gov.ua';
export const DEFAULT_DPS_PRRO_API_HTTPS_URL = `https://${DEFAULT_DPS_PRRO_URL}:8643/fs`;
export const DEFAULT_DPS_PRRO_API_HTTP_URL = `http://${DEFAULT_DPS_PRRO_URL}:8609/fs`;
export const DEFAULT_DPS_PRRO_CHECK_URL = `https://cabinet.tax.gov.ua/cashregs/check`;
export const DEFAULT_MAX_SALE_AMOUNT = 50000;

export const DEFAULT_TOAST_DELAY = 2500;
export const WARNING_TOAST_DELAY = 5000;
export const ERROR_TOAST_DELAY = 5000;
export const TERMINAL_DEFAULT_MERCHANT_ID = 1;
export const TERMINAL_DEFAULT_SERVICE_REFUND = true;
export const TERMINAL_DEFAULT_AUTO_DEBUG = false;
