export class ShopClient {
  id: number;
  shopId: number;
  mobilePhone: string;
  name: string;
  bonus: number;
  personalDiscount: number;
  wholesaleBuyer: boolean;
  balance: number;
}
