<form #incasationForm="ngForm">
  <ion-list *ngIf="shift && (type === transaction.Incasation)">
    <ion-item>
      <ion-label  position="floating">Сума, ₴</ion-label>
      <ion-input  type="number"
                  name="incasationMoney"
                  step="0.01"
                  [(ngModel)]="incasation.money"
                  [min]="MIN_CURRENCY_SUM_VALUE"
                  [bkMin]="MIN_CURRENCY_SUM_VALUE"
                  [max]="MAX_CURRENCY_SUM_VALUE"
                  [bkMax]="MAX_CURRENCY_SUM_VALUE"
                  (ionFocus)="selectAll()"
                  [required]="true"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label>Безготівкова</ion-label>
      <ion-toggle name="incasationCashless"
                  color="success"
                  [(ngModel)]="incasation.cashless"
                  (ngModelChange)="toggleIncasationType()"></ion-toggle>
    </ion-item>

    <ion-item>
      <ion-label position="stacked">Коментар</ion-label>
      <ion-textarea type="text"
                    name="incasationComment"
                    placeholder="Коментар"
                    [autoGrow]="true"
                    [minlength]="MIN_STRING_LENGTH"
                    [bkMin]="MIN_STRING_LENGTH"
                    [maxlength]="MAX_STRING_LENGTH"
                    [bkMax]="MAX_STRING_LENGTH"
                    [(ngModel)]="incasation.comment"></ion-textarea>
    </ion-item>
  </ion-list>

  <ion-list *ngIf="shift && (type === transaction.Deposit)">
    <ion-item>
      <ion-label  position="floating">Сума, ₴</ion-label>
      <ion-input  type="number"
                  name="depositDeposit"
                  step="0.01"
                  [(ngModel)]="deposit.deposit"
                  [min]="MIN_CURRENCY_SUM_VALUE"
                  [bkMin]="MIN_CURRENCY_SUM_VALUE"
                  [max]="MAX_CURRENCY_SUM_VALUE"
                  [bkMax]="MAX_CURRENCY_SUM_VALUE"
                  (ionFocus)="selectAll()"
                  [required]="true"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="stacked">Коментар</ion-label>
      <ion-textarea type="text"
                    name="depositComment"
                    placeholder="Коментар"
                    [autoGrow]="true"
                    [minlength]="MIN_STRING_LENGTH"
                    [maxlength]="MAX_STRING_LENGTH"
                    [bkMin]="MIN_STRING_LENGTH"
                    [bkMax]="MAX_STRING_LENGTH"
                    [(ngModel)]="deposit.depositComment"></ion-textarea>
    </ion-item>
  </ion-list>

  <ion-list *ngIf="shift && (type === transaction.FinanceExpense)">
    <ion-item>
      <ion-label>Вид витрати:</ion-label>
      <ion-select name="financeSubcategory"
                  okText="Вибрати"
                  cancelText="Скасувати"
                  [(ngModel)]="financeSubcategory"
                  [interfaceOptions]="{ cssClass: 'finance-expense-select-option' }"
                  (ngModelChange)="financeExpenseSubcategoryChange()"
                  [required]="true">
        <ng-container *ngFor="let financeCategory of financeCategories">
          <ion-select-option [disabled]="true">
            {{ financeCategory.name }}
          </ion-select-option>

          <ion-select-option *ngFor="let financeSubcategory of financeCategory.financeSubcategories" [value]="financeSubcategory">
            {{ financeSubcategory.name }}
          </ion-select-option>
        </ng-container>
      </ion-select>
    </ion-item>

    <section *ngIf="isSalary">
      <ion-item>
        <ion-label>Працівник:</ion-label>
        <ion-select name="financeExpenseEmployee"
                    okText="Вибрати"
                    cancelText="Скасувати"
                    [(ngModel)]="financeExpenseEmployee"
                    [interfaceOptions]="{ cssClass: 'finance-expense-select-option' }"
                    (ngModelChange)="setFinanceExpenseSalaryComment()"
                    [required]="true">
          <ion-select-option *ngFor="let employee of employees" [value]="employee">
            {{ employee.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Нарахування за:</ion-label>
        <ion-datetime name="financeExpenseSalaryDate"
                      displayFormat="MMMM YYYY"
                      doneText="Вибрати"
                      cancelText="Скасувати"
                      placeholder="Оберіть період нарахування"
                      [monthNames]="MONTHS"
                      [min]="financeExpenseSalaryPreviousMonth"
                      [max]="financeExpenseSalaryMaxDate"
                      [(ngModel)]="financeExpenseSalaryDate"
                      (ngModelChange)="setFinanceExpenseSalaryComment()"
                      [required]="true"></ion-datetime>
      </ion-item>
    </section>

    <ion-item>
      <ion-label  position="floating">Сума, ₴</ion-label>
      <ion-input  type="number"
                  name="financeExpensePrice"
                  step="0.01"
                  [(ngModel)]="financeExpense.price"
                  [min]="MIN_CURRENCY_SUM_VALUE"
                  [bkMin]="MIN_CURRENCY_SUM_VALUE"
                  [max]="MAX_CURRENCY_SUM_VALUE"
                  [bkMax]="MAX_CURRENCY_SUM_VALUE"
                  (ionFocus)="selectAll()"
                  [required]="true"></ion-input>
    </ion-item>

    <ion-item class="comment">
      <ion-label position="stacked">Коментар</ion-label>
      <ion-textarea type="text"
                    name="financeExpenseComment"
                    placeholder="Коментар"
                    [autoGrow]="true"
                    [disabled]="isSalary"
                    [(ngModel)]="financeExpense.comment"
                    [minlength]="MIN_STRING_LENGTH"
                    [bkMin]="MIN_STRING_LENGTH"
                    [maxlength]="MAX_STRING_LENGTH"
                    [bkMax]="MAX_STRING_LENGTH"></ion-textarea>
    </ion-item>
  </ion-list>
</form>

<ion-toolbar>
  <ion-text slot="start" color="light" *ngIf="isPRroActive">
    <section *ngIf="zReport?.opened; else noFiscalShift">
      <div class="fiscal text-no-wrap" *ngIf="user?.showCash">
        <div class="fiscal__caption">Фіскальний залишок:&nbsp;</div>
        <div class="fiscal__sum">{{ zReport.cashRemain | number: '1.2-2' }}</div>
        <div class="fiscal__currency">&nbsp;₴</div>
      </div>
    </section>

    <ng-template #noFiscalShift>
      <div class="fiscal text-no-wrap">
        Фіскальна зміна не відкрита
      </div>
    </ng-template>
  </ion-text>

  <ion-button slot="end" color="primary" (click)="save()" [disabled]="!incasationForm.valid">
    Зберегти
  </ion-button> 
</ion-toolbar>
