import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController, ToastButton, ToastController } from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import round from 'lodash-es/round';
import { Observable } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';

import { Resource } from '../core/abstract/resource';
import { SYNC_TOAST_MESSAGE } from '../core/constants/error-messages.const';
import {
  LABEL_PRINTER_REFRESH,
  RECEIPT_PRINTER_REFRESH,
  SHOP_REFRESH,
} from '../core/constants/events.const';
import { ObjectLiteral } from '../core/interfaces/object-literal';
import { IToastOptions } from '../core/interfaces/toast-options.interface';
import { SyncResult } from '../core/models/sync-result.model';
import { CachedDataService } from '../core/services/cached-data.service';
import { Events } from '../core/services/events.service';
import { ToastService } from '../core/services/toast.service';
import { UtilsService } from '../core/services/utils.service';
import { CheckDocumentSubType } from '../p-rro/fsco/enums/check-document-sub-type.enum';
import { PayFormCode } from '../p-rro/fsco/enums/pay-form-code.enum';
import { PRroReturnCheck } from '../p-rro/fsco/models/p-rro-return-check.model';
import { PRroSaleCheck } from '../p-rro/fsco/models/p-rro-sale-check.model';
import { PRroSaleItem } from '../p-rro/fsco/models/p-rro-sale-item.model';
import { PRroSaleTax } from '../p-rro/fsco/models/p-rro-sale-tax.model';
import { PRro } from '../p-rro/p-rro.model';
import { PRroService } from '../p-rro/p-rro.service';
import { LabelPrinterService } from '../settings/printer/label/label-printer.service';
import { ReceiptPrinterService } from '../settings/printer/receipt/receipt-printer.service';
import { SALES_STORAGE_KEY } from '../settings/settings.const';
import { TerminalConnectionProtocol } from '../settings/terminals/enums/terminal-connection-protocol.enum';
import { TerminalsService } from '../settings/terminals/terminals.service';
import { CashlessPaymentDialog } from '../shop/cashless-payment/cashless-payment.dialog';
import { Product } from '../shop/products/product.model';

import { SalePayment } from './sale/sale-payment.model';
import { SaleProduct } from './sale/sale-product.model';
import { Sale } from './sale/sale.model';

@Injectable({
  providedIn: 'root',
})
export class SalesService extends Resource<Sale> {
  private isDesktop = false;
  private isAndroidApp = false;
  private isPrintReceiptMode = false;
  private isPrintReceiptAutoPrintMode = false;
  private isPrintLabelMode = false;
  private isPrintLabelAutoPrintMode = false;

  constructor(
    protected http: HttpClient,
    private events: Events,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private toastService: ToastService,
    private utilsService: UtilsService,
    private cachedDataService: CachedDataService,
    private prroService: PRroService,
    private receiptPrinterService: ReceiptPrinterService,
    private labelPrinterService: LabelPrinterService,
    private terminalsService: TerminalsService,
  ) {
    super(http, {
      path: '/sales',
    });

    this.isDesktop = this.utilsService.isDesktop();
    this.isAndroidApp = this.utilsService.isAndroidApp();

    this.events.subscribe(SHOP_REFRESH, () => {
      this.refreshReceiptPrintStatus();
      this.refreshLabelPrintStatus();
    });

    this.events.subscribe(
      RECEIPT_PRINTER_REFRESH,
      (status: { isAvailible: boolean; isAutoPrintMode: boolean }) => {
        this.isPrintReceiptMode = status.isAvailible;
        this.isPrintReceiptAutoPrintMode = status.isAutoPrintMode;
      },
    );

    this.events.subscribe(
      LABEL_PRINTER_REFRESH,
      (status: { isAvailible: boolean; isAutoPrintMode: boolean }) => {
        this.isPrintLabelMode = status.isAvailible;
        this.isPrintReceiptAutoPrintMode = status.isAutoPrintMode;
      },
    );

    this.refreshReceiptPrintStatus();
    this.refreshLabelPrintStatus();
  }

  find(options: ObjectLiteral = {}): Observable<Sale[]> {
    options = { shopId: this.cachedDataService.getShopId(), ...options };

    return super.find(options).pipe(
      map((sales) => sales.map((sale) => this.transformFromBackend(sale))),
      map((sales) => orderBy(sales, 'createdAt', 'desc')),
    );
  }

  async findForReturn(
    condition: { fn?: string; id?: string; day?: string } = {},
  ): Promise<Sale[]> {
    let params = new HttpParams();

    params = params.append(
      'shopId',
      this.cachedDataService.getShopId().toString(),
    );

    if (condition.fn != null) {
      params = params.append('fn', condition.fn);
    }

    if (condition.id != null) {
      params = params.append('id', condition.id);
    }

    if (condition.day != null) {
      params = params.append('day', condition.day);
    }

    return this.http
      .get<Sale[]>(`${this.config.path}/return`, { params })
      .pipe(
        map((sales) => sales.map((sale) => this.transformFromBackend(sale))),
      )
      .toPromise()
      .then((sales) => sales)
      .catch((reason) => []);
  }

  get(id: number | string): Observable<Sale> {
    return super.get(id).pipe(map((sale) => this.transformFromBackend(sale)));
  }

  async findDebtRepayments(id: number | string): Promise<Sale[]> {
    return this.http
      .get<Sale[]>(`${this.config.path}/debt-repayments`, {
        params: { id: id.toString() },
      })
      .pipe(
        map((sales) => sales.map((sale) => this.transformFromBackend(sale))),
      )
      .toPromise()
      .then((sales) => sales)
      .catch((reason) => []);
  }

  async createSale(
    currentSale: Sale,
    saleType: CheckDocumentSubType,
    options: { needFiscalization: boolean } = { needFiscalization: true },
  ): Promise<Sale> {
    const isConnected = await this.utilsService.isOnline();
    const shop = this.cachedDataService.getShop();

    currentSale.createdAt = new Date();
    currentSale.shopId = shop.id;
    currentSale.shiftId = this.cachedDataService.getShift().id;
    currentSale.userId = this.cachedDataService.getUser().id;
    currentSale.userSessionId = this.cachedDataService.getSessionId();
    currentSale.isOffline = !isConnected;

    let isPRroActive = false;

    if (this.cachedDataService.isPRroActive()) {
      isPRroActive = true;

      if (options.needFiscalization) {
        await this.createFiscalization(currentSale, saleType);
      }
    }

    const saleToSave = this.transformToBackend(currentSale, {
      isReturn: saleType === CheckDocumentSubType.CheckReturn,
    });

    if (!isConnected) {
      saleToSave.isOffline = true;

      return this.saveOfflineSale(saleToSave, currentSale);
    }

    return super
      .create(saleToSave, { isCalculated: 'true' })
      .pipe(
        tap((newSale) => {
          currentSale.id = newSale.id;

          if (isPRroActive && shop.prroManualControl) {
            currentSale.salePayments = newSale.salePayments;
          }

          this.presentToast(currentSale, {
            isCheckTaxStatus: isPRroActive && shop.prroManualControl,
          });
        }),
        catchError((error) =>
          this.saveOfflineSale(saleToSave, currentSale, error),
        ),
      )
      .toPromise()
      .finally(async () => {
        await this.print(currentSale, {
          isReturn: saleType === CheckDocumentSubType.CheckReturn,
        });
      });
  }

  private async createFiscalization(
    currentSale: Sale,
    saleType: CheckDocumentSubType,
  ): Promise<void> {
    this.addPRroData(currentSale);

    const taxSale =
      saleType === CheckDocumentSubType.CheckReturn
        ? this.getTaxReturn(currentSale)
        : this.getTaxSale(currentSale);

    const prroResult = await this.prroService.fiscalize(taxSale);

    if (prroResult != null) {
      currentSale.prroId = prroResult.prroId;
      currentSale.prroShiftId = prroResult.prroShiftId;
      currentSale.prroLocalNumber = prroResult.prroLocalNumber;
      currentSale.prroTaxNumber = prroResult.prroTaxNumber;
    } else {
      Reflect.deleteProperty(currentSale, 'prroLocalNumber');
      Reflect.deleteProperty(currentSale, 'prroTaxNumber');
    }
  }

  private addPRroData(sale: Sale): void {
    const prro = this.cachedDataService.getPRRO();

    sale.prro = new PRro();
    sale.prro.fiscalNumber = prro.fiscalNumber;
    sale.prro.localNumber = prro.localNumber;
    sale.prro.testMode = prro.testMode;
  }

  async updateSale(currentSale: Sale, isConnected: boolean): Promise<Sale> {
    const saleToSave = this.transformToBackend(currentSale, { isUpdate: true });

    saleToSave.shopId = this.cachedDataService.getShopId();
    saleToSave.shiftId = this.cachedDataService.getShift().id;
    saleToSave.userId = this.cachedDataService.getUser().id;
    saleToSave.userSessionId = this.cachedDataService.getSessionId();

    if (!isConnected) {
      saleToSave.isOffline = true;

      return this.saveOfflineSale(saleToSave, currentSale);
    }

    return super
      .update(currentSale.id, saleToSave)
      .pipe(
        tap((newSale) => {
          currentSale.id = newSale.id;

          this.presentToast(currentSale, { isUpdate: true });
        }),
        catchError((error) =>
          this.saveOfflineSale(saleToSave, currentSale, error),
        ),
      )
      .toPromise()
      .finally(async () => {
        await this.print(currentSale, { isUpdate: true });
      });
  }

  private async print(
    sale: Sale,
    operation: {
      isUpdate?: boolean;
      isReturn?: boolean;
    },
  ): Promise<void> {
    if (this.isPrintReceiptMode) {
      if (Math.abs(sale.cashSum ?? 0) > 0) {
        await this.receiptPrinterService.openCashDrawer();
      }

      if (this.isPrintReceiptAutoPrintMode) {
        await this.receiptPrinterService.printSale(sale);
      }
    }

    if (
      this.isPrintLabelMode &&
      this.isPrintLabelAutoPrintMode &&
      !operation.isReturn &&
      !operation.isUpdate
    ) {
      await this.labelPrinterService.printSale(sale);
    }
  }

  getTaxSale(
    sale: Sale,
    oprions: { isNewDate: boolean } = { isNewDate: false },
  ): PRroSaleCheck {
    const taxSale = new PRroSaleCheck(
      oprions.isNewDate ? new Date() : sale.createdAt,
    );

    this.setTaxObject(taxSale, sale);

    return taxSale;
  }

  getTaxReturn(sale: Sale): PRroReturnCheck {
    const taxReturn = new PRroReturnCheck();

    taxReturn.returnTaxNumber =
      sale.returnSale?.prroTaxNumber ?? sale.prroTaxNumber;

    this.setTaxObject(taxReturn, sale);

    return taxReturn;
  }

  private setTaxObject(
    taxObject: PRroSaleCheck | PRroReturnCheck,
    sale: Sale,
  ): void {
    taxObject.uId = sale.uuid;

    taxObject.totalSum = round(Math.abs(sale.costSum), 2);
    taxObject.discountSum = round(Math.abs(sale.discountSum), 2);
    taxObject.roundSum = round(Math.sign(sale.costSum) * sale.roundSum, 2);
    taxObject.paymentSum = round(Math.abs(sale.paymentSum), 2);
    taxObject.providedSum = round(Math.abs(sale.clientCash), 2);
    taxObject.remainsSum = round(Math.abs(sale.changeSum), 2);

    sale.salePayments.forEach((salePayment) => {
      const item = this.salePaymentToSave(salePayment);

      item.amount = round(Math.abs(salePayment.amount), 2);

      taxObject.payments.push(item);
    });

    sale.saleProducts.forEach((saleProduct) => {
      const item = new PRroSaleItem(saleProduct);

      taxObject.items.push(item);
    });

    sale.saleTaxes.forEach((saleTax) => {
      const item = new PRroSaleTax(saleTax);

      taxObject.taxes.push(item);
    });
  }

  async sync(): Promise<SyncResult> {
    const sales = this.getLocallySavedSales();

    if (sales == null || sales.length === 0) {
      const emptyResult = new SyncResult();

      emptyResult.success = true;
      emptyResult.message = 'Немає даних для синхронізації продажів';

      return emptyResult;
    }

    const syncResult = new SyncResult('чеків');
    const unSyncedItems: Sale[] = [];
    const errors: string[] = [];

    syncResult.totalItems = sales.length;

    for (const sale of sales) {
      if (syncResult.timeoutErrors < 5) {
        await super
          .create(sale, { isCalculated: 'true' })
          .pipe(timeout(5000))
          .toPromise()
          .then((response) => {
            syncResult.successSync();
          })
          .catch((problem) => {
            syncResult.warningSync();
            unSyncedItems.push(sale);

            this.utilsService.addSyncError(problem, errors);
          });
      } else {
        unSyncedItems.push(sale);
      }
    }

    this.utilsService.setResultMessage(syncResult, errors);
    this.saveSalesLocally(unSyncedItems);

    return syncResult;
  }

  haveUnsyncedSales(): boolean {
    return this.getLocallySavedSales().length > 0;
  }

  async presentToast(sale: Sale, data: IToastOptions = {}): Promise<void> {
    if (sale.returnSaleId != null && sale.returnSaleId > 0) {
      data = { ...data, isReturn: true };
    }

    const buttons: ToastButton[] = [];

    if (this.isDesktop || this.isAndroidApp) {
      buttons.push({
        icon: 'print',
        handler: async () => {
          await this.printSale(sale, data);
        },
      });
    }

    if (
      this.isDesktop &&
      this.isPrintLabelMode &&
      !(data.isReturn || data.isUpdate)
    ) {
      buttons.push({
        icon: 'ticket',
        handler: async () => {
          await this.labelPrinterService.printSale(sale, { silentPrint: true });
        },
      });

      buttons.push({
        icon: 'git-network',
        handler: async () => {
          await this.labelPrinterService.printSale(sale, { silentPrint: true });
          await this.printSale(sale, data);
        },
      });
    }

    let note = '';

    if ((sale.prroTaxNumber ?? '') > '') {
      note = ' з використанням ПРРО';

      buttons.push({
        icon: 'share-social-outline',
        handler: async () => {
          await this.prroService.showQR(sale);
        },
      });
    }

    buttons.push({
      icon: 'close',
      role: 'cancel',
    });

    const toast = await this.toastCtrl.create({
      buttons,
      header: this.getToastHeader(data, note),
      message: this.getToastMessage(
        data,
        sale.changeSum > 0 ? `Решта: ${sale.changeSum.toFixed(2)}` : '',
      ),
      cssClass:
        data.isOffline || data.isError ? 'warning-toast' : 'default-toast',
      duration: 7500,
    });

    toast.present();
  }

  private async printSale(sale: Sale, data: IToastOptions): Promise<void> {
    if (data.isCheckTaxStatus && (sale.prroTaxNumber ?? '') === '') {
      await this.addFiscalization(sale, { printReceipt: true });
    } else {
      await this.receiptPrinterService.printSale(sale, { silentPrint: true });
    }
  }

  private getToastHeader(data: IToastOptions = {}, note: string): string {
    return data.isOffline
      ? `Оффлайн ${data.isUpdate ? 'оновлення чеку' : 'продаж'}${note}`
      : data.isError
      ? `Помилка ${
          data.isUpdate ? 'оновлення' : 'збереження'
        } даних на сервері${note}`
      : `${
          data.isUpdate
            ? 'Чек оновлено'
            : data.isReturn
            ? 'Повернено'
            : 'Продано'
        }${note}`;
  }

  private getToastMessage(
    data: IToastOptions = {},
    note: string = '',
  ): string | undefined {
    const rows: string[] = [note];

    rows.push(
      data.isOffline
        ? 'Необхідна синхронізація'
        : data.isError
        ? `Чек збережено на пристрої! ${SYNC_TOAST_MESSAGE}`
        : '',
    );

    const result = [...new Set(rows)];

    if (result.length === 0 || (result.length === 1 && result[0] === '')) {
      return undefined;
    }

    return rows.join('\n');
  }

  private async saveOfflineSale(
    preparedSale: Sale,
    originalSale: Sale,
    error?: any,
  ): Promise<Sale> {
    const parsedError = error ? this.utilsService.getParsedError(error) : null;

    if (!preparedSale.createdAt) {
      preparedSale.createdAt = new Date();
    }

    this.saveLocally(preparedSale);
    this.presentToast(originalSale, {
      isOffline: (parsedError?.statusCode ?? 0) === 0,
      isError: (parsedError?.statusCode ?? 0) >= 400,
    });

    return preparedSale;
  }

  private saveLocally(sale: Sale): void {
    const sales = this.getLocallySavedSales() || [];

    sales.push(sale);

    this.saveSalesLocally(sales);
  }

  private getLocallySavedSales(): Sale[] {
    const sales = localStorage.getItem(SALES_STORAGE_KEY);

    if (!sales) {
      return [];
    }

    return JSON.parse(sales);
  }

  private saveSalesLocally(sales: Sale[]): void {
    localStorage.setItem(SALES_STORAGE_KEY, JSON.stringify(sales));
  }

  transformFromBackend(originalSale: Sale): Sale {
    const sale = new Sale();

    sale.id = originalSale.id;
    sale.createdAt = new Date(originalSale.createdAt);
    sale.cashless = originalSale.cashless;
    sale.comment = originalSale.comment;
    sale.uuid = originalSale.uuid;
    sale.returnSaleId = originalSale.returnSaleId;
    sale.presaleId = originalSale.presaleId;
    sale.saleInDebtId = originalSale.saleInDebtId;
    sale.prroId = originalSale.prroId;
    sale.prroLocalNumber = originalSale.prroLocalNumber;
    sale.prroTaxNumber = originalSale.prroTaxNumber;

    sale.client = cloneDeep(originalSale.client);
    sale.user = cloneDeep(originalSale.user);
    sale.prro = cloneDeep(originalSale.prro);
    sale.returnSale = cloneDeep(originalSale.returnSale);

    sale.saleProducts = [];
    sale.salePayments = [];
    sale.saleTaxes = [];

    originalSale.saleProducts.forEach((sp) => {
      const product = new Product();

      product.id = sp.productId;
      product.name = this.utilsService.productName(sp.product);
      product.amount = this.utilsService.productMeasurement(sp.product);
      product.weightProduct = sp.product.weightProduct;

      const saleProduct = new SaleProduct();

      saleProduct.id = sp.id;
      saleProduct.productId = sp.productId;
      saleProduct.product = product;
      saleProduct.quantity = sp.quantity;
      saleProduct.price = sp.price;
      saleProduct.bonusPayment = sp.bonusPayment;
      saleProduct.bonusAdd = sp.bonusAdd;
      saleProduct.personalDiscount = sp.personalDiscount;
      saleProduct.freeCup = sp.freeCup;
      saleProduct.excisePercent = sp.excisePercent;
      saleProduct.exciseAmount = sp.exciseAmount;
      saleProduct.exciseLabel = sp.exciseLabel;
      saleProduct.exciseLetter = sp.exciseLetter;
      saleProduct.UKTZED = sp.UKTZED;
      saleProduct.vatPercent = sp.vatPercent;
      saleProduct.vatAmount = sp.vatAmount;
      saleProduct.vatLetter = sp.vatLetter;
      saleProduct.productName = sp.productName;

      saleProduct.refreshExciseLabelView();

      saleProduct.cost = this.utilsService.cost(
        saleProduct.quantity,
        saleProduct.price,
      );

      saleProduct.discount = round(
        (saleProduct.personalDiscount ?? 0) +
          (saleProduct.freeCup ?? 0) +
          (saleProduct.bonusPayment ?? 0),
        2,
      );

      saleProduct.fullCost = round(saleProduct.cost + saleProduct.discount, 2);
      saleProduct.fullPrice = round(
        saleProduct.quantity
          ? saleProduct.fullCost / saleProduct.quantity
          : saleProduct.price,
        2,
      );

      sale.costSum += saleProduct.fullCost;
      sale.discountSum += saleProduct.discount;
      sale.totalDiscount += saleProduct.personalDiscount ?? 0;
      sale.totalFreeCups += saleProduct.freeCup ?? 0;
      sale.totalBonus += saleProduct.bonusPayment ?? 0;

      sale.saleProducts.push(saleProduct);
    });

    originalSale.salePayments.forEach((sp) => {
      const salePayment = new SalePayment();

      Object.assign(salePayment, sp);

      if (
        salePayment.method === PayFormCode.Cash &&
        this.utilsService.getRoundSum(salePayment.amount) !== 0
      ) {
        salePayment.amount += this.utilsService.getRoundSum(salePayment.amount);
      }

      sale.salePayments.push(salePayment);
    });

    const totalPaymentWithoutRound = sale.costSum - sale.discountSum;

    this.setPaymentsData(sale);

    sale.roundSum = sale.cashSum
      ? this.utilsService.getRoundSum(totalPaymentWithoutRound - sale.cardSum)
      : 0;

    sale.paymentSum = totalPaymentWithoutRound + sale.roundSum;
    sale.changeSum = Math.max(sale.clientCash - Math.abs(sale.cashSum), 0);

    sale.costSum = round(sale.costSum, 2);
    sale.discountSum = round(sale.discountSum, 2);
    sale.roundSum = round(sale.roundSum, 2);
    sale.paymentSum = round(sale.paymentSum, 2);
    sale.clientCash = round(sale.clientCash, 2);
    sale.changeSum = round(sale.changeSum, 2);

    sale.calcTotalTaxes();

    this.setPaymentIcon(sale);

    return sale;
  }

  private setPaymentsData(sale: Sale): void {
    const cashPayment = sale.salePayments.find(
      (sp) => sp.method === PayFormCode.Cash,
    );

    const cardPayment = sale.salePayments.find(
      (sp) => sp.method === PayFormCode.Card,
    );

    const debtPayment = sale.salePayments.find(
      (sp) => sp.method === PayFormCode.InDebt,
    );

    if (cashPayment != null) {
      sale.cashSum = round(cashPayment.amount, 2);

      if (cashPayment.providedCash != null) {
        sale.clientCash = cashPayment.providedCash;
      }
    }

    if (cardPayment != null) {
      sale.cardSum = round(cardPayment.amount, 2);
    }

    if (debtPayment != null) {
      sale.debtSum = round(debtPayment.amount, 2);
    }
  }

  private transformToBackend(
    sale: Sale,
    operation: {
      isUpdate?: boolean;
      isReturn?: boolean;
    },
  ): Sale {
    const saleToSave = this.saleToSave(sale, operation);
    const sign = operation.isReturn ? -1 : 1;

    sale.salePayments.forEach((salePayment) => {
      const salePaymentToSave = this.salePaymentToSave(salePayment);

      salePaymentToSave.amount = sign * salePayment.amount;

      saleToSave.salePayments.push(salePaymentToSave);
    });

    sale.saleProducts.forEach((saleProduct) => {
      const saleProductToSave = this.saleProductToSave(saleProduct, sign);

      saleToSave.saleProducts.push(saleProductToSave);
    });

    if (saleToSave.saleInDebtId != null) {
      saleToSave.saleProducts = [];
    }

    if (operation.isReturn) {
      sale.roundSum = -sale.roundSum;
      sale.paymentSum = -sale.paymentSum;
      sale.cashSum = -sale.cashSum;
      sale.cardSum = -sale.cardSum;
      sale.clientCash = 0;
      sale.changeSum = 0;
      sale.costSum = -sale.costSum;
      sale.discountSum = -sale.discountSum;

      sale.totalDiscount = -sale.totalDiscount;
      sale.totalFreeCups = -sale.totalFreeCups;
      sale.totalBonus = -sale.totalBonus;
    }

    return saleToSave;
  }

  private saleToSave(
    sale: Sale,
    operation: {
      isUpdate?: boolean;
      isReturn?: boolean;
    },
  ): Sale {
    const saleToSave = new Sale({ skipConstructor: true });

    saleToSave.cashless =
      sale.cashless === null ? null : Boolean(sale.cashless);

    saleToSave.shopId = sale.shopId;
    saleToSave.shiftId = sale.shiftId;
    saleToSave.userId = sale.userId;
    saleToSave.createdAt = sale.createdAt;
    saleToSave.isOffline = sale.isOffline;
    saleToSave.userSessionId = sale.userSessionId;
    saleToSave.uuid = sale.uuid;

    if (operation.isUpdate) {
      saleToSave.id = sale.id;
    }

    if (sale.clientId != null) {
      saleToSave.clientId = sale.clientId;
    }

    if (sale.comment != null && sale.comment > '') {
      saleToSave.comment = sale.comment;
    }

    if (sale.returnSaleId != null) {
      saleToSave.returnSaleId = sale.returnSaleId;
    }

    if (sale.presaleId != null) {
      saleToSave.presaleId = sale.presaleId;
    }

    if (sale.saleInDebtId != null) {
      saleToSave.saleInDebtId = sale.saleInDebtId;
    }

    if (sale.prroLocalNumber != null && (sale.prroTaxNumber ?? '') > '') {
      saleToSave.prroId = sale.prroId;
      saleToSave.prroShiftId = sale.prroShiftId;
      saleToSave.prroLocalNumber = sale.prroLocalNumber;
      saleToSave.prroTaxNumber = sale.prroTaxNumber;
    }

    saleToSave.salePayments = [];
    saleToSave.saleProducts = [];

    return saleToSave;
  }

  private salePaymentToSave(salePayment: SalePayment): SalePayment {
    const item = new SalePayment();

    item.method = salePayment.method;
    item.amount = salePayment.amount;

    if (salePayment.id != null) {
      item.id = salePayment.id;
    }

    if (salePayment.providedCash != null) {
      item.providedCash = salePayment.providedCash;
    }

    if (
      salePayment.deviceId != null &&
      salePayment.paymentSystem != null &&
      salePayment.epzDetails != null
    ) {
      item.acquirerName = salePayment.acquirerName ?? '';
      item.deviceId = salePayment.deviceId;
      item.paymentSystem = salePayment.paymentSystem;
      item.epzDetails = salePayment.epzDetails;
      item.operationType = salePayment.operationType;

      this.salePaymentToSaveOptional(salePayment, item);
    }

    if (salePayment.transactionId != null) {
      item.transactionId = salePayment.transactionId;
    }

    return item;
  }

  private salePaymentToSaveOptional(
    salePayment: SalePayment,
    item: SalePayment,
  ): void {
    if (salePayment.acquirerTransactionId != null) {
      item.acquirerTransactionId = salePayment.acquirerTransactionId;
    }

    if (salePayment.authCode != null) {
      item.authCode = salePayment.authCode;
    }

    if (salePayment.posTransactionDate != null) {
      item.posTransactionDate = salePayment.posTransactionDate;
    }

    if (salePayment.posTransactionNumber != null) {
      item.posTransactionNumber = salePayment.posTransactionNumber;
    }

    if (salePayment.signVerification != null) {
      item.signVerification = salePayment.signVerification;
    }
  }

  private saleProductToSave(
    saleProduct: SaleProduct,
    sign: number = 1,
  ): SaleProduct {
    const saleProductToSave = new SaleProduct();

    saleProductToSave.productId = saleProduct.productId;
    saleProductToSave.quantity = sign * saleProduct.quantity;
    saleProductToSave.price = saleProduct.price;

    if (saleProduct.personalDiscount != null) {
      saleProductToSave.personalDiscount = sign * saleProduct.personalDiscount;
    }

    if (saleProduct.freeCup != null) {
      saleProductToSave.freeCup = sign * saleProduct.freeCup;
    }
    if (saleProduct.bonusPayment != null) {
      saleProductToSave.bonusPayment = sign * saleProduct.bonusPayment;
    }

    if (saleProduct.bonusAdd != null) {
      saleProductToSave.bonusAdd = sign * saleProduct.bonusAdd;
    }

    if (saleProduct.UKTZED != null && saleProduct.UKTZED > '') {
      saleProductToSave.UKTZED = saleProduct.UKTZED;
    }

    if (saleProduct.excisePercent != null && saleProduct.exciseAmount != null) {
      saleProductToSave.exciseLabel = saleProduct.exciseLabel;
      saleProductToSave.excisePercent = saleProduct.excisePercent;
      saleProductToSave.exciseAmount = sign * saleProduct.exciseAmount;
      saleProductToSave.exciseLetter = saleProduct.exciseLetter;
    }

    if (saleProduct.vatPercent != null && saleProduct.vatAmount != null) {
      saleProductToSave.vatPercent = saleProduct.vatPercent;
      saleProductToSave.vatAmount = sign * saleProduct.vatAmount;
      saleProductToSave.vatLetter = saleProduct.vatLetter;
    }

    if (saleProduct.productName != null && saleProduct.productName > '') {
      saleProductToSave.productName = saleProduct.productName;
    }

    return saleProductToSave;
  }

  setPaymentIcon(sale: Sale): void {
    if (sale.cashSum && sale.cardSum) {
      sale.paymentIcon = 'shapes';
      sale.paymentTooltip = 'Готівка + Картка';
    } else if (sale.debtSum) {
      sale.paymentIcon = 'trending-down';
      sale.paymentTooltip = 'В борг';
    } else if (sale.cardSum) {
      sale.paymentIcon = 'card';
      sale.paymentTooltip = 'Банківська картка';
    } else if (sale.cashSum) {
      sale.paymentIcon = 'cash';
      sale.paymentTooltip = 'Готівка';
    } else {
      sale.paymentIcon = 'help';
      sale.paymentTooltip = 'Невідома форма оплати';
    }
  }

  async cashlessPaymentDialog(
    amount: number,
    acquirerTransactionId: string = '',
  ): Promise<SalePayment | undefined> {
    const terminalData = await this.terminalsService.getTerminalData();

    if (
      terminalData.connectionProtocol === TerminalConnectionProtocol.None &&
      acquirerTransactionId > ''
    ) {
      this.toastService.presentError(
        'Операцію скасовано',
        'Зміна типу оплати для чеків, які оплачені карткою зі збереженням реквізитів транзакції, не передбачена. ' +
          'За необхідності - виконайте повернення й сформуйте чек ще раз',
        10000,
      );

      return undefined;
    }

    const modal = await this.modalCtrl.create({
      component: CashlessPaymentDialog,
      componentProps: {
        amount,
        refundTransactionId: acquirerTransactionId,
      },
      backdropDismiss: false,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.success) {
      return data.payment;
    }

    return undefined;
  }

  async addFiscalization(
    sale: Sale,
    options: { printReceipt: boolean } = { printReceipt: false },
  ): Promise<boolean> {
    if (Math.abs(sale.debtSum) > 0) {
      return false;
    }

    this.addPRroData(sale);

    const taxSale =
      sale.returnSaleId != null && sale.returnSaleId > 0
        ? this.getTaxReturn(sale)
        : this.getTaxSale(sale, { isNewDate: true });

    const prroResult = await this.prroService.fiscalize(taxSale);

    if (prroResult != null) {
      sale.prroId = prroResult.prroId;
      sale.prroShiftId = prroResult.prroShiftId;
      sale.prroLocalNumber = prroResult.prroLocalNumber;
      sale.prroTaxNumber = prroResult.prroTaxNumber;

      const isConnected = await this.utilsService.isOnline();

      await this.updateSale(sale, isConnected);

      if (options.printReceipt) {
        this.receiptPrinterService.printSale(sale, { silentPrint: true });
      }

      return true;
    }

    return false;
  }

  private refreshReceiptPrintStatus(): void {
    this.receiptPrinterService.status().then((status) => {
      this.isPrintReceiptMode = status.isPrinterAvailable;
      this.isPrintReceiptAutoPrintMode = status.isAutoPrintAfterSale;
    });
  }

  private refreshLabelPrintStatus(): void {
    this.labelPrinterService.status().then((status) => {
      this.isPrintLabelMode = status.isPrinterAvailable;
      this.isPrintLabelAutoPrintMode = status.isAutoPrintAfterSale;
    });
  }
}
