import { Component, Input, OnInit } from '@angular/core';

import {
  PRESALE_CLIENT_CAPTION,
  PRESALE_COMMENT_CAPTION,
  PRESALE_COMPLETE_BY_CAPTION,
  PRESALE_CREATED_AT_CAPTION,
  PRESALE_HEADER_NAME_CAPTION,
  PRESALE_HEADER_QUANTITY_CAPTION,
} from '../../receipt/receipt.const';
import { PrintPresale } from '../../receipt/types/print-presale.model';

@Component({
  selector: 'bk-view-presale',
  templateUrl: './view-presale.component.html',
  styleUrls: ['./view-presale.component.scss'],
})
export class ViewPresaleComponent implements OnInit {
  @Input() presale: PrintPresale;

  createdAtCaption = PRESALE_CREATED_AT_CAPTION;
  completeByCaption = PRESALE_COMPLETE_BY_CAPTION;
  nameCaption = PRESALE_HEADER_NAME_CAPTION;
  quantityCaption = PRESALE_HEADER_QUANTITY_CAPTION;
  commentCaption = PRESALE_COMMENT_CAPTION;
  clientCaption = PRESALE_CLIENT_CAPTION;

  constructor() {}

  ngOnInit(): void {
    //
  }
}
