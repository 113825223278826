import { ErrorHandler, Injectable } from '@angular/core';

import { LogsService } from '../settings/logs/logs.service';

import { UtilsService } from './services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    private utilsService: UtilsService,
    private logsService: LogsService,
  ) {
    super();
  }

  handleError(error: any): void {
    super.handleError(error);

    // Unroll errors from promises
    if (error.rejection != null) {
      error = error.rejection;
    }

    const parsedError = this.utilsService.getParsedError(error);

    if (
      parsedError.message.includes('cordova_not_available') ||
      parsedError.message.includes('plugin_not_installed') ||
      parsedError.message.includes('overlay does not exist')
    ) {
      this.logsService.send(
        `GlobalErrorHandler`,
        `${JSON.stringify(parsedError, null, 2)}`,
        { auto: true },
      );

      return;
    }

    if (
      parsedError.message.includes('/cover/') &&
      parsedError.statusCode === 404
    ) {
      return;
    }

    this.utilsService.showErrorAlert(parsedError);
  }
}
