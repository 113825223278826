import { LabelPrinterSettings } from '../label-printer-settings.interface';
import { PAPER_40_X_25, PAPER_60_X_40 } from '../label-printer.const';

import { IPrintLabelPaper } from './print-label-paper.interface';

export class PrintLabelPdfSettings {
  width = 0;
  height = 0;

  margins: {
    top: number;
    bottom: number;
    left: number;
    right: number;
    element: number;
  } = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    element: 0,
  };

  text: {
    fontSize: number;
    height: number;
    gap: number;
  } = {
    fontSize: 0,
    height: 0,
    gap: 0,
  };

  barcode: {
    format: string;
    fontSize: number;
    width: number;
    height: number;
    margin: number;
  } = {
    format: '',
    width: 0,
    height: 0,
    fontSize: 0,
    margin: 0,
  };

  constructor(settings: LabelPrinterSettings) {
    this.width = settings.paperFormat.width;
    this.height = settings.paperFormat.height;

    if (this.isFormat(PAPER_40_X_25)) {
      this.margins = { top: 1, bottom: 1, left: 1, right: 1, element: 1 };
      this.text = { height: 2, gap: 1.2, fontSize: 8 };
      this.barcode = {
        format: settings.barcodeFormat.name,
        fontSize: 12,
        width: 1,
        height: 25,
        margin: 1,
      };
    } else if (this.isFormat(PAPER_60_X_40)) {
      this.margins = { top: 1, bottom: 1, left: 1, right: 1, element: 1 };
      this.text = { height: 2.4, gap: 1.6, fontSize: 10 };
      this.barcode = {
        format: settings.barcodeFormat.name,
        fontSize: 14,
        width: 1.2,
        height: 32,
        margin: 1,
      };
    }
  }

  get printAreaWidth(): number {
    return this.width - this.margins.left - this.margins.right;
  }

  get printAreaHeight(): number {
    return this.height - this.margins.top - this.margins.bottom;
  }

  isFormat(format: IPrintLabelPaper): boolean {
    return this.width === format.width && this.height === format.height;
  }

  textHeight(rowsCount: number): number {
    return rowsCount * this.text.height + (rowsCount - 1) * this.text.gap;
  }
}
