import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonItemSliding } from '@ionic/angular';

import { DATETIME_FORMAT } from '../core/constants/date.const';
import {
  TRANSACTIONS_REFRESH,
  TRANSACTIONS_SHIFT,
  TRANSACTIONS_Z_REPORT,
} from '../core/constants/events.const';
import { Shift } from '../core/models/shift.model';
import { User } from '../core/models/user.model';
import { CachedDataService } from '../core/services/cached-data.service';
import { Events } from '../core/services/events.service';
import { ShiftsService } from '../core/services/resources/shifts.service';
import { UserService } from '../core/services/resources/user.service';
import { ToastService } from '../core/services/toast.service';
import { PRroZReport } from '../p-rro/fsco/models/p-rro-z-report.model';
import { PRroService } from '../p-rro/p-rro.service';
import { ReceiptPrinterService } from '../settings/printer/receipt/receipt-printer.service';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../settings/settings.const';

import { FinanceExpensesService } from './finance-expanses.service';
import { IncasationsService } from './incasations.service';
import { Deposit } from './transaction/models/deposit.model';
import { Incasation } from './transaction/models/incasation.model';
import { Transaction } from './transaction/models/transaction.model';
import { TransactionType } from './transaction/transaction-type.enum';
import { TransactionsGroup } from './transactions-group.model';
import { TransactionsService } from './transactions.service';

const WARNING_TITLE = 'Операцію скасовано';

@Component({
  selector: 'bk-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit, OnDestroy {
  @ViewChild(IonItemSliding) itemSliding: IonItemSliding;

  user: User;
  shift: Shift;

  private prroZReport: PRroZReport;
  private isPRroActive = false;

  shiftTransactions: Transaction[] = [];
  groupTransactions: TransactionsGroup[] = [];

  type = TransactionType.Incasation;
  transactions = TransactionType;

  private requestInProgress = false;

  get transactionTypeName(): string {
    switch (this.type) {
      case TransactionType.Incasation:
        return 'incasation';

      case TransactionType.Deposit:
        return 'deposit';

      case TransactionType.FinanceExpense:
        return 'expense';

      default:
        return '';
    }
  }
  constructor(
    private events: Events,
    private alertCtrl: AlertController,
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
    private userService: UserService,
    private shiftsService: ShiftsService,
    private incasationsService: IncasationsService,
    private financeExpensesService: FinanceExpensesService,
    private prroService: PRroService,
    private receiptPrinterService: ReceiptPrinterService,
    private transactionsService: TransactionsService,
  ) {
    this.isPRroActive = this.cachedDataService.isPRroActive();
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });

    this.events.subscribe(TRANSACTIONS_REFRESH, () => {
      this.refresh();
    });
  }

  ngOnDestroy(): void {
    this.events.destroy(TRANSACTIONS_REFRESH);
  }

  async ionViewWillEnter(): Promise<void> {
    this.refresh();
  }

  changeTab(event: CustomEvent): void {
    this.requestInProgress = false;

    switch (Number(event.detail.value)) {
      case TransactionType.Incasation:
        this.type = TransactionType.Incasation;
        break;

      case TransactionType.Deposit:
        this.type = TransactionType.Deposit;
        break;

      case TransactionType.FinanceExpense:
        this.type = TransactionType.FinanceExpense;
        break;
    }

    this.loadTransactions();
  }

  //#region Refresh
  refresh(): void {
    this.groupTransactions = [];

    this.loadShiftData();
    this.loadPRroShiftData();
    this.loadTransactions();
  }

  private loadShiftData(): void {
    this.shiftsService.getShift().subscribe(
      (shift) => {
        if (shift) {
          this.shift = shift;

          this.events.publish(TRANSACTIONS_SHIFT, shift);
        }
      },
      (error) => {
        //
      },
    );
  }

  private loadPRroShiftData(): void {
    if (!this.isPRroActive) {
      return;
    }

    this.prroService
      .getZReport()
      .then((zReport) => {
        this.prroZReport = zReport;

        this.events.publish(TRANSACTIONS_Z_REPORT, zReport);
      })
      .catch((reason) => {
        //
      });
  }

  private loadTransactions(): void {
    if (this.type === TransactionType.FinanceExpense) {
      this.financeExpensesService
        .find({
          shiftId: this.shiftsService.getCurrentShiftId(),
          userId: this.cachedDataService.getUser().id,
          type: this.transactionTypeName,
        })
        .subscribe(
          (financeExpenses) => {
            const transactions: Transaction[] = [];

            financeExpenses.forEach((financeExpense) => {
              const transaction =
                this.financeExpensesService.convertToTransaction(
                  financeExpense,
                );

              transactions.push(transaction);
            });

            this.regroupTransactions(transactions);
          },
          (error) => {
            //
          },
        );
    } else {
      this.incasationsService
        .find({
          shiftId: this.shiftsService.getCurrentShiftId(),
          userId: this.cachedDataService.getUser().id,
          type: this.transactionTypeName,
        })
        .subscribe(
          (incasations) => {
            const transactions: Transaction[] = [];

            incasations.forEach((incasation) => {
              const transaction =
                incasation.deposit > 0
                  ? new Transaction(TransactionType.Deposit, incasation.deposit)
                  : new Transaction(
                      TransactionType.Incasation,
                      incasation.money,
                    );

              transaction.id = incasation.id;
              transaction.createdAt = incasation.createdAt;
              transaction.cashless = incasation.cashless;
              transaction.shopId = incasation.shopId;
              transaction.prroId = incasation.prroId;
              transaction.prro = incasation.prro;
              transaction.prroShiftId = incasation.prroShiftId;
              transaction.prroLocalNumber = incasation.prroLocalNumber;
              transaction.prroTaxNumber = incasation.prroTaxNumber;
              transaction.returnId = incasation.returnId;
              transaction.comment =
                incasation.deposit > 0
                  ? incasation.depositComment
                  : incasation.comment;

              transactions.push(transaction);
            });

            this.regroupTransactions(transactions);
          },
          (error) => {
            //
          },
        );
    }
  }

  private regroupTransactions(transactions: Transaction[]): void {
    this.shiftTransactions = transactions;
    this.groupTransactions = [];

    transactions.forEach((transaction) => {
      if (
        this.groupTransactions.find(
          (group) => group.date === transaction.createdAt.toLocaleDateString(),
        ) == null
      ) {
        this.groupTransactions.push(
          new TransactionsGroup(transaction.createdAt.toLocaleDateString()),
        );
      }
    });

    this.groupTransactions.forEach((group) => {
      transactions.forEach((transaction) => {
        if (transaction.createdAt.toLocaleDateString() === group.date) {
          group.transactions.push(transaction);

          group.sum += transaction.amount;
        }
      });
    });
  }

  //#region Fiscalization
  isFiscalDoc(transaction: Transaction): boolean {
    return (
      transaction.prroLocalNumber != null &&
      (transaction.prroTaxNumber ?? '') > ''
    );
  }

  showQRButton(transaction: Incasation): boolean {
    return (
      this.isFiscalDoc(transaction) ||
      (this.isPRroActive && this.prroZReport?.opened && !transaction.cashless)
    );
  }

  async qr(transaction: Incasation): Promise<void> {
    this.itemSliding.close();

    if (this.type === TransactionType.Incasation && transaction.cashless) {
      return;
    }

    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    if (this.isFiscalDoc(transaction)) {
      await this.showQR(transaction);
    } else {
      await this.createQR(transaction);
    }

    this.requestInProgress = false;
  }

  private async showQR(transaction: Transaction): Promise<void> {
    await this.prroService.showQR(transaction);
  }

  private async createQR(transaction: Incasation): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Синхронізація даних з ДПС',
      message: `<strong>Відправити</strong> транзакцію у ДПС?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Відправити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            const prroResult = await this.transactionsService.fiscalize(
              transaction,
            );

            if (prroResult != null) {
              this.refresh();
            }
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  //#region Print
  async print(transaction: Transaction): Promise<void> {
    this.itemSliding.close();

    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    await this.receiptPrinterService.printTaxServiceDoc(transaction, {
      viewMode: true,
    });

    this.requestInProgress = false;
  }

  //#region Delete
  async delete(transaction: Transaction): Promise<void> {
    this.itemSliding.close();

    if (this.requestInProgress) {
      return;
    }

    if (!transaction.cashless && !this.canReverse(transaction)) {
      return;
    }

    this.requestInProgress = true;

    const alert = await this.alertCtrl.create({
      header: this.deleteAlertHeader(),
      message: this.isFiscalDoc(transaction)
        ? `<strong>Анулювати</strong> (створити ${
            this.type === TransactionType.Deposit ? 'інкасацію' : 'внесення'
          }) обрану транзакцію?`
        : `<strong>Видалити</strong> обрану транзакцію?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
          handler: () => {
            this.requestInProgress = false;
          },
        },
        {
          text: this.isFiscalDoc(transaction) ? 'Анулювати' : 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            if (this.isFiscalDoc(transaction)) {
              const reverseTransaction =
                this.getReverseTransaction(transaction);

              const doc = await this.transactionsService.create(
                reverseTransaction,
                { needFiscalization: this.isFiscalDoc(transaction) },
              );

              if (doc != null) {
                transaction.returnId = doc.id;
              }
            } else {
              await this.transactionsService.delete(transaction);
            }

            this.refresh();
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  private getReverseTransaction(transaction: Transaction): Transaction {
    const date = `від ${formatDate(
      transaction.createdAt,
      DATETIME_FORMAT,
      'uk_UA',
    )}`;

    const comment =
      (transaction.comment ?? '') > '' ? ` (${transaction.comment})` : '';

    const reverseTransaction =
      this.type === TransactionType.Deposit
        ? new Incasation({
            amount: transaction.amount,
            comment: `Анулювання внесення коштів ${date}${comment}`,
          })
        : new Deposit({
            amount: transaction.amount,
            comment: `Анулювання ${
              this.type === TransactionType.FinanceExpense
                ? 'витрати коштів'
                : 'інкасації'
            } ${date}${comment}`,
          });

    if (this.type === TransactionType.FinanceExpense) {
      reverseTransaction.financeExpenseReturnId = transaction.id;
    } else {
      reverseTransaction.returnId = transaction.id;
    }

    return reverseTransaction;
  }

  private deleteAlertHeader(): string {
    return this.type === TransactionType.Incasation
      ? 'Інкасація'
      : this.type === TransactionType.Deposit
      ? 'Внесення коштів'
      : this.type === TransactionType.FinanceExpense
      ? 'Витрата коштів'
      : '<Невідомий документ>';
  }
  private canReverse(transaction: Transaction): boolean {
    if (!this.isFiscalDoc(transaction)) {
      return true;
    }

    if (!this.isPRroActive || !this.prroZReport.opened) {
      this.toastService.presentWarning(
        WARNING_TITLE,
        !this.prroZReport.opened
          ? 'Фіскальна зміна не відкрита'
          : 'Перевірте налаштування УЕП і торгової точки',
      );

      return false;
    }

    const prroShift = this.cachedDataService.getPRROShift();

    if (transaction.prroShiftId !== prroShift.ShiftId) {
      this.toastService.presentWarning(
        WARNING_TITLE,
        'Транзакція не належить поточній фіскальній зміні',
      );

      return false;
    }

    return true;
  }
}
