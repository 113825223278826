import { Clipboard } from '@angular/cdk/clipboard';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

import { IStorageUserSettings } from '../core/interfaces/storage-user-setting.interface';
import { Shop } from '../core/models/shop.model';
import { CachedDataService } from '../core/services/cached-data.service';
import { LoadingService } from '../core/services/loading.service';
import { ToastService } from '../core/services/toast.service';
import { ReceiptPrinterService } from '../settings/printer/receipt/receipt-printer.service';
import { TAX_DATE_FORMAT } from '../settings/printer/receipt/receipt.const';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../settings/settings.const';

import { CryptSettingsDialog } from './components/crypt-settings/crypt-settings.dialog';
import { ShiftState } from './fsco/enums/shift-state.enum';
import {
  FISCAL_Z_REPORT_CAPTION,
  TEST_FISCAL_Z_REPORT_CAPTION,
} from './fsco/fsco.const';
import { CertSubject } from './fsco/interfaces/cert-subject.interface';
import { ResponsePRROState } from './fsco/interfaces/responses/response-prro-state.interface';
import { PRroDoc } from './fsco/models/p-rro-doc.model';
import { PRroZReport } from './fsco/models/p-rro-z-report.model';
import { PRro } from './p-rro.model';
import { PRroService } from './p-rro.service';
import { ShopCryptData } from './types/shop-crypt-data.model';

@Component({
  selector: 'bk-p-rro',
  templateUrl: './p-rro.component.html',
  styleUrls: ['./p-rro.component.scss'],
})
export class PRroComponent implements OnInit {
  TAB_OPERATIONS = 'operations';
  TAB_Z_REPORTS = 'zReports';
  TAB_SETTINGS = 'settings';

  activeTab = '';
  advancedOptions = false;

  shop: Shop;
  userSettings: IStorageUserSettings;

  subject: CertSubject | null = null;
  cryptData: ShopCryptData | null = null;

  prro: PRro;
  prroState: ResponsePRROState | null = null;

  zReports: PRroDoc[] = [];

  lastDocNumber: number;

  private requestInProgress = false;

  constructor(
    private clipboard: Clipboard,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private cachedDataService: CachedDataService,
    private receiptPrinterService: ReceiptPrinterService,
    private prroService: PRroService,
  ) {
    this.shop = this.cachedDataService.getShop();
    this.userSettings = this.cachedDataService.getUserSettings();
  }

  async ngOnInit(): Promise<void> {
    this.activeTab = this.TAB_OPERATIONS;

    this.cryptData = this.cachedDataService.getCryptData();
    this.prro = this.cachedDataService.getPRRO();

    this.lastDocNumber = this.prro?.lastDocNumber ?? 0;

    // if isQZTrayActive is higher then getPRRO, prro doesn't work
    await this.checkCryptData();
  }

  changedTab(event: any): void {
    this.activeTab = event.detail.value;

    this.getLastDocNumber();

    if (this.activeTab === this.TAB_Z_REPORTS) {
      this.prroService.loadZReports().subscribe((docs) => {
        this.zReports = docs;
      });
    }
  }

  async selectPRro(): Promise<void> {
    await this.prroService.selectPRro();
    await this.getRROState();

    this.prro = this.cachedDataService.getPRRO();

    this.lastDocNumber = this.prro.lastDocNumber;
  }

  copyToClipboard(): void {
    if (!this.subject?.subjectKeyId) {
      return;
    }

    this.clipboard.copy(this.subject?.subjectKeyId);
    this.toastService.present('Ідентифікатор ключа скопійовано у буфер обміну');
  }

  async openShift(): Promise<void> {
    if (!this.canWorkWithPRro()) {
      return;
    }

    this.requestInProgress = true;

    const { prroReport, prroState } = await this.prroService.openShift();

    if (prroState != null) {
      this.prroState = prroState;
    }

    this.requestInProgress = false;

    this.showResult(prroReport);
  }

  async createZReportAndCloseFiscalShift(): Promise<void> {
    if (!this.canWorkWithPRro()) {
      return;
    }

    if (this.invalidPRroState()) {
      return;
    }

    this.requestInProgress = true;

    const { operationReport, zReport, prroState } =
      await this.prroService.createZReportAndCloseShift();

    this.requestInProgress = false;

    if (prroState != null) {
      this.prroState = prroState;
    }

    if (zReport != null) {
      await this.receiptPrinterService.printTaxReport(zReport, {
        viewMode: true,
      });
    }

    await this.showResult(operationReport);
  }

  async setCryptData(): Promise<void> {
    if (this.cryptData == null) {
      this.cryptData = new ShopCryptData(
        this.cachedDataService.getShopId(),
        this.cachedDataService.getUser().id,
      );
    }

    const modal = await this.modalCtrl.create({
      component: CryptSettingsDialog,
      componentProps: {
        cryptData: this.cryptData,
      },
      backdropDismiss: false,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.isSuccess && this.cryptData) {
      this.cachedDataService.setCryptData(this.cryptData);

      await this.checkCryptData({ refresh: true });
    }
  }

  async deleteCryptData(): Promise<void> {
    const title = 'Налаштування УЕП';

    const alert = await this.alertCtrl.create({
      header: title,
      message: `<strong>Видалити</strong> інформацію про УЕП?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            if (this.cachedDataService.deleteCryptData()) {
              this.cryptData = null;
              this.subject = null;

              this.toastService.present(title, `Інформація про УЕП видалена`);
            } else {
              this.toastService.presentError(
                title,
                `Не вдалося видалити інформацію про УЕП`,
              );
            }

            await this.checkCryptData({ refresh: true });
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  private getLastDocNumber(): void {
    this.lastDocNumber = this.cachedDataService.getLastDocNumber();
  }

  async setLastDocNumber(): Promise<void> {
    const inputFieldId = 'inputField';

    const alert = await this.alertCtrl.create({
      header: 'Поточний номер документа',
      message: 'Введіть номер останнього відправленого у ДПС документа',
      inputs: [
        {
          id: inputFieldId,
          name: 'сurrentDocNumber',
          placeholder: 'Номер документа',
          type: 'number',
          value: this.lastDocNumber,
        },
      ],
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
          handler: () => {
            this.getLastDocNumber();
          },
        },
        {
          text: 'Підтвердити',
          role: 'confirm',
          cssClass: 'primary',
          handler: (data: { currentDocNumber: string }) => {
            this.updateLastDocNumber(data.currentDocNumber);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    alert.addEventListener('ionAlertDidPresent', (e) => {
      const inputField = document.getElementById(inputFieldId);

      if (inputField == null) {
        return;
      }

      setTimeout(() => {
        inputField.focus();

        if (inputField instanceof HTMLInputElement) {
          inputField.select();
        }
      }, 100);

      inputField.onkeyup = async (event) => {
        if (event.key !== 'Enter') {
          return;
        }

        if (inputField instanceof HTMLInputElement) {
          this.updateLastDocNumber(inputField.value);

          await alert.dismiss();
        }
      };
    });

    await alert.present();
  }

  toggleUseTSP(): void {
    this.userSettings.useTSP = !this.userSettings.useTSP;

    this.cachedDataService.setUserSettings(this.userSettings);
    this.prroService.updateTSPStatus(this.userSettings.useTSP);
  }

  private updateLastDocNumber(value: string): void {
    try {
      this.lastDocNumber = Number(value);

      this.cachedDataService.updateLastDocNumber(this.lastDocNumber);
    } catch (e) {
      this.getLastDocNumber();
      this.toastService.present(
        'Помилка введення',
        `Введіть число! \n${e}`,
        5000,
      );
    }
  }

  async getServerState(): Promise<void> {
    await this.loadingService.presentCustomPreloader('getServerState');

    const { state, message } = await this.prroService.getServerState();

    this.showResult(
      state
        ? `Є зв'язок з ФСКО<br><br>  
         Час сервера: <br> 
         ${message}`
        : "Немає зв'язку",
    );

    await this.loadingService.dismiss('getServerState');
  }

  async getRROState(): Promise<void> {
    await this.loadingService.presentCustomPreloader('getRROState');

    this.prroState = await this.prroService.getPRROState();

    await this.loadingService.dismiss('getRROState');
  }

  async getLastShiftTotals(): Promise<void> {
    if (this.invalidPRroState()) {
      return;
    }

    await this.loadingService.presentCustomPreloader('getLastShiftTotals');

    const zRepData = await this.prroService.getZReport();

    await this.loadingService.dismiss('getLastShiftTotals');

    await this.receiptPrinterService.printTaxReport(zRepData, {
      viewMode: true,
    });
  }

  private canWorkWithPRro(): boolean {
    if (!this.cachedDataService.getShift()) {
      this.alertCtrl
        .create({
          header: 'Робоча зміна не відкрита',
          message: `Для початку роботи з ПРРО необхідно відкрити робочу зміну`,
          buttons: [{ text: 'Закрити', role: 'close' }],
          backdropDismiss: false,
        })
        .then((alert) => alert.present());

      return false;
    }

    if (this.requestInProgress) {
      return false;
    }

    return true;
  }

  private invalidPRroState(): boolean {
    if (this.prroState == null) {
      this.toastService.presentWarning(
        'Немає інформації про стан ПРРО',
        'Натисніть кнопку "Стан ПРРО" у розділі "Програмний РРО"',
      );

      return true;
    }

    if (this.prroState.ShiftState === ShiftState.Closed) {
      this.toastService.presentWarning(
        'Фіскальну зміну ще не відкрито',
        'Натисніть кнопку "Стан ПРРО" у розділі "Програмний РРО" щоб оновити інформацію або відкрийте нову зміну',
      );

      return true;
    }

    return false;
  }

  private async showResult(response: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Результат запиту',
      message: response,
      buttons: [
        {
          text: 'Закрити',
          role: 'close',
        },
      ],
      backdropDismiss: true,
    });

    alert.present();
  }

  private async checkCryptData(
    options: { refresh: boolean } = { refresh: false },
  ): Promise<void> {
    const cryptData = this.cachedDataService.getCryptData();

    if (cryptData != null && cryptData.isCorrect()) {
      this.subject = await this.prroService.initCrypt(cryptData, options);

      if (this.subject != null) {
        this.getRROState();
      }
    } else {
      this.subject = null;
    }
  }

  async compareDocs(): Promise<void> {
    if (this.prroState == null) {
      this.toastService.presentWarning(
        'Немає інформації про стан ПРРО',
        'Натисніть кнопку "Стан ПРРО" у розділі "Програмний РРО"',
      );

      return;
    }

    const alert = await this.alertCtrl.create({
      header: 'Синхронізація даних з ДПС',
      message: `
        Порівняння документів, які збережені у сервісі cashbox,
        ${
          this.prroState.ShiftState === ShiftState.Opened
            ? 'поточної'
            : 'попередньої'
        } фіскальної зміни
        з даними ДПС та завантажити дані, яких немає, або оновити поточні<br><br>
        <strong>Порівняти</strong>?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Порівняти',
          role: 'confirm',
          cssClass: 'primary',
          handler: () => {
            this.prroService.loadDocsAndCompare().then().catch();
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  zReportCaption(zReportDoc: PRroDoc): string {
    return `${formatDate(
      zReportDoc.docDateTime,
      TAX_DATE_FORMAT,
      'uk_UA',
    )} ФН ${zReportDoc.taxNumber} ${this.optionalReportNumber(zReportDoc)}`;
  }

  private optionalReportNumber(zReportDoc: PRroDoc): string {
    return zReportDoc.jsonData?.reportNumber != null
      ? `№ ${zReportDoc.jsonData?.reportNumber}`
      : '';
  }

  preview(zReportDoc: PRroDoc): void {
    const zReport = new PRroZReport();

    zReport.companyName =
      zReportDoc.jsonData?.companyName ??
      zReportDoc.prro?.prroObject?.companyEntity.name ??
      '';
    zReport.EDRPOU =
      zReportDoc.jsonData?.EDRPOU ??
      zReportDoc.prro?.prroObject?.companyEntity.EDRPOU ??
      '';
    zReport.IPN =
      zReportDoc.jsonData?.IPN ??
      zReportDoc.prro?.prroObject?.companyEntity.IPN ??
      '';
    zReport.shopName =
      zReportDoc.jsonData?.shopName ?? zReportDoc.prro?.prroObject?.name ?? '';
    zReport.shopAddress =
      zReportDoc.jsonData?.shopAddress ??
      zReportDoc.prro?.prroObject?.address ??
      '';
    zReport.prroFiscalNumber =
      zReportDoc.jsonData?.prroFiscalNumber ?? zReportDoc.prro?.fiscalNumber;
    zReport.fiscalType =
      zReportDoc.jsonData?.testing ?? zReportDoc.prro?.testMode
        ? TEST_FISCAL_Z_REPORT_CAPTION
        : FISCAL_Z_REPORT_CAPTION;

    if (zReportDoc.jsonData == null) {
      this.toastService.presentWarning(
        'Друк Z-звіту',
        'Немає збережених даних\nЗа потреби зверніться для доопрацювання',
      );
      return;
    }

    zReport.date = zReportDoc.docDateTime;
    zReport.localNumber = zReportDoc.localNumber;
    zReport.taxNumber = zReportDoc.taxNumber;
    zReport.testing = zReportDoc.jsonData?.testing ?? zReportDoc.prro?.testMode;

    zReport.startedAt = zReportDoc.jsonData.startedAt;
    zReport.finishedAt = zReportDoc.jsonData.startedAt;

    zReport.reportNumber = zReportDoc.jsonData.reportNumber;
    zReport.lastDocLocalNumber = zReportDoc.jsonData.lastDocLocalNumber;
    zReport.lastDocFiscalNumber = zReportDoc.jsonData.lastDocFiscalNumber;

    zReport.discount = zReportDoc.jsonData.discount;

    zReport.realizCount = zReportDoc.jsonData.realizCount;
    zReport.realizCashSum = zReportDoc.jsonData.realizCashSum;
    zReport.realizCardSum = zReportDoc.jsonData.realizCardSum;
    zReport.realizOtherSum = zReportDoc.jsonData.realizOtherSum;
    zReport.realizNoRoundSum = zReportDoc.jsonData.realizNoRoundSum;
    zReport.realizRoundSum = zReportDoc.jsonData.realizRoundSum;

    zReport.returnCount = zReportDoc.jsonData.returnCount;
    zReport.returnCashSum = zReportDoc.jsonData.returnCashSum;
    zReport.returnCardSum = zReportDoc.jsonData.returnCardSum;
    zReport.returnOtherSum = zReportDoc.jsonData.returnOtherSum;
    zReport.returnNoRoundSum = zReportDoc.jsonData.returnNoRoundSum;
    zReport.returnRoundSum = zReportDoc.jsonData.returnRoundSum;

    zReport.serviceInput = zReportDoc.jsonData.serviceInput;
    zReport.serviceOutput = zReportDoc.jsonData.serviceOutput;

    zReport.realizPayments = zReportDoc.jsonData.realizPayments;
    zReport.returnPayments = zReportDoc.jsonData.returnPayments;

    zReport.realizTaxes = zReportDoc.jsonData.realizTaxes;
    zReport.returnTaxes = zReportDoc.jsonData.returnTaxes;

    this.receiptPrinterService.printTaxReport(zReport, {
      viewMode: true,
    });
  }
}
