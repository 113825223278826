import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { IonInput, ModalController } from '@ionic/angular';
import round from 'lodash-es/round';

import { INVOICE_ADD_WEIGHT_PRODUCT } from '../../core/constants/events.const';
import {
  MAX_WEIGHT_VALUE,
  MIN_WEIGHT_VALUE,
} from '../../core/constants/form-validations.const';
import { WEIGHT_AMOUNT } from '../../core/constants/product.const';
import { Measurement } from '../../core/enum/measurement.enum';
import { WeightProductMeasurement } from '../../core/enum/weight-product-measurement.enum';
import { Events } from '../../core/services/events.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { SaleProduct } from '../../sales/sale/sale-product.model';
import { ScalesResponse } from '../../settings/scales/response.interface';
import { ScalesService } from '../../settings/scales/scales.service';
import { Product } from '../products/product.model';

@Component({
  selector: 'bk-weight-product',
  templateUrl: './weight-product.html',
  styleUrls: ['./weight-product.scss'],
})
export class WeightProductComponent implements OnInit {
  @ViewChild(IonInput) input: IonInput;

  @Input() isLandscape = false;
  @Input() isModal = false;
  @Input() isEdit = false;
  @Input() menuProduct: Product;
  @Input() editProduct: SaleProduct;

  @Output() goBackToMenu: EventEmitter<any> = new EventEmitter<any>();
  @Output() goBackToSubcategories: EventEmitter<any> = new EventEmitter<any>();
  @Output() goBackToProducts: EventEmitter<any> = new EventEmitter<any>();

  scalesData: ScalesResponse;
  saleProduct: SaleProduct;
  productWeight: number | null;
  cost: number;

  amountName = '';
  amountType = '';

  isScalesAvailable = false;

  MIN_WEIGHT_VALUE = MIN_WEIGHT_VALUE;
  MAX_WEIGHT_VALUE = MAX_WEIGHT_VALUE;

  constructor(
    private router: Router,
    private events: Events,
    private modalCtrl: ModalController,
    private toastService: ToastService,
    private utilsService: UtilsService,
    private scalesService: ScalesService,
  ) {}

  ngOnInit(): void {
    this.init();

    if (this.scalesService.isScalesAvailable(this.saleProduct.product.amount)) {
      this.isScalesAvailable = true;

      this.scalesData = this.scalesService.emptyData();

      this.scalesService.liveData().subscribe((data) => {
        this.scalesData = data;

        if (data.weight > 0) {
          this.productWeight = data.weight;
        }
      });

      this.scalesService.getWeight();
    }

    if (!this.isModal) {
      this.setFocusOnInput();
    }
  }

  ionViewDidEnter(): void {
    if (this.isModal) {
      this.setFocusOnInput();
    }
  }

  async back(): Promise<void> {
    await this.finishWeighing();
  }

  recalculation(): void {
    if (this.productWeight != null && !Number.isNaN(this.productWeight)) {
      this.productWeight = Math.round(this.productWeight);

      this.cost = (this.productWeight * this.saleProduct.price) / WEIGHT_AMOUNT;
    } else {
      this.cost = 0;
    }

    this.cost = round(this.cost, 2);
  }

  async saveAndReturn(): Promise<void> {
    if (
      this.productWeight == null ||
      Number.isNaN(this.productWeight) ||
      this.productWeight <= 0
    ) {
      this.toastService.presentError('Неправильне значення поля "Маса"');
      return;
    }

    this.utilsService.calcWeightProductData(
      this.saleProduct,
      this.productWeight,
    );

    if (!this.isEdit) {
      this.events.publish(INVOICE_ADD_WEIGHT_PRODUCT, this.saleProduct);
    }

    await this.finishWeighing();

    if (this.isLandscape) {
      this.goBackToProducts.emit();
    } else if (this.isModal) {
      await this.modalCtrl.dismiss({ success: true });
    } else {
      this.router.navigate(['/shop'], { replaceUrl: true });
    }
  }

  isValid(): boolean {
    return this.cost > 0;
  }

  selectAll(): void {
    this.input.getInputElement().then((data) => {
      data.select();
    });
  }

  async cancel(): Promise<void> {
    await this.finishWeighing();
    await this.modalCtrl.dismiss();
  }

  private init(): void {
    if (this.isLandscape) {
      this.saleProduct = this.utilsService.getStartSaleProduct(
        this.menuProduct,
      );
    } else if (this.isModal) {
      this.saleProduct = this.editProduct;
    } else {
      this.initFromRouter();
    }

    this.amountType = this.saleProduct.product.amount
      .split(`${WEIGHT_AMOUNT}`)[1]
      ?.trim();

    switch (this.amountType) {
      case Measurement.Kilogram:
      case WeightProductMeasurement.Gram:
        this.amountName = 'Маса';
        break;

      case Measurement.Litre:
      case WeightProductMeasurement.Millilitre:
        this.amountName = "Об'єм";
        break;

      case Measurement.Metre:
      case WeightProductMeasurement.Millimetre:
        this.amountName = 'Довжина';
        break;

      default:
        break;
    }

    this.productWeight = +(WEIGHT_AMOUNT * this.saleProduct.quantity).toFixed(
      3,
    );

    this.cost = +(
      (this.productWeight * this.saleProduct.product.price) /
      WEIGHT_AMOUNT
    ).toFixed(2);
  }

  private initFromRouter(): void {
    const navigation = this.router.getCurrentNavigation();

    if (navigation?.extras?.state != null) {
      const routeState = navigation.extras.state;

      if (routeState.product != null) {
        const product: Product = JSON.parse(routeState.product) as Product;

        this.saleProduct = this.utilsService.getStartSaleProduct(product);
      } else {
        this.saleProduct = JSON.parse(routeState.saleProduct) as SaleProduct;
      }
    }
  }

  private setFocusOnInput(): void {
    setTimeout(() => {
      this.input.setFocus();
    }, 100);
  }

  private async finishWeighing(): Promise<void> {
    if (this.isScalesAvailable) {
      await this.scalesService.finish();
    }
  }
}
