import { PrinterConnectionMethod } from '../printer-connection-method.enum';
import { DEFAULT_PRINTER } from '../printer.const';

import { ReceiptPrinterSettings } from './receipt-printer-settings.interface';
import { AndroidPosTerminal } from './types/android-pos-terminal.interface';

export const PRINTER_URL = 'receipt-printer';
export const PRINTER_CLASS = 'принтер чеків';

export const PAPER_WIDTH_58 = 58;
export const PAPER_WIDTH_80 = 80;

export const ROW_LENGTH_32 = 32;
export const ROW_LENGTH_34 = 34;
export const ROW_LENGTH_35 = 35;
export const ROW_LENGTH_36 = 36;
export const ROW_LENGTH_42 = 42;
export const ROW_LENGTH_48 = 48;

export const LINES_TO_CUT_2 = 2;
export const LINES_TO_CUT_3 = 3;
export const LINES_TO_CUT_4 = 4;
export const LINES_TO_CUT_5 = 5;

export const DEFAULT_PAPER_WIDTH = PAPER_WIDTH_80;
export const DEFAULT_ROW_LENGTH = ROW_LENGTH_48;
export const DEFAULT_LINES_TO_CUT = LINES_TO_CUT_3;
export const DEFAULT_PRINT_SHOP_LOGO_IN_RECEIPT = false;
export const DEFAULT_AUTO_PRINT_AFTER_SALE = false;
export const DEFAULT_USE_CASH_DRAWER = false;

export const PRINTER_MODEL_SUNMI_V2 = 'sunmi_v2';
export const PRINTER_MODEL_IMIN_M2 = 'imin_m2';
export const PRINTER_MODEL_IMIN_SWIFT = 'imin_swift';
export const PRINTER_MODEL_IMIN_OTHER = 'imin_other';
export const PRINTER_MODEL_OTHER = 'other';

export const CHARSET_NAME_1251 = 'windows-1251';
export const CHARSET_NAME_866 = 'CP866';

export const CHARSET_ID_16 = 16;
export const CHARSET_ID_17 = 17;
export const CHARSET_ID_46 = 46;
export const CHARSET_ID_73 = 73;

export const DEFAULT_MODEL = PRINTER_MODEL_OTHER;
export const DEFAULT_CHARSET_NAME = CHARSET_NAME_1251;
export const DEFAULT_CHARSET_ID = CHARSET_ID_46;
export const DEFAULT_TURN_OFF_CHINESE = false;

export const DEFAULT_SETTINGS: ReceiptPrinterSettings = {
  connectionMethod: PrinterConnectionMethod.Browser,
  printer: DEFAULT_PRINTER,
  ip: '',
  mac: '',
  paperWidth: DEFAULT_PAPER_WIDTH,
  rowLength: DEFAULT_ROW_LENGTH,
  linesToCut: DEFAULT_LINES_TO_CUT,
  printShopLogoInReceipt: DEFAULT_PRINT_SHOP_LOGO_IN_RECEIPT,
  autoPrintAfterSale: DEFAULT_AUTO_PRINT_AFTER_SALE,
  model: DEFAULT_MODEL,
  charsetName: DEFAULT_CHARSET_NAME,
  charsetId: DEFAULT_CHARSET_ID,
  turnOffChinese: DEFAULT_TURN_OFF_CHINESE,
  useCashDrawer: DEFAULT_USE_CASH_DRAWER,
};

export const ANDROID_POS_TERMINALS: AndroidPosTerminal[] = [
  {
    title: 'Стандарт',
    name: PRINTER_MODEL_OTHER,
    charsetName: DEFAULT_CHARSET_NAME,
    charsetId: DEFAULT_CHARSET_ID,
    turnOffChinese: DEFAULT_TURN_OFF_CHINESE,
  },
  {
    title: 'Sunmi V2',
    name: PRINTER_MODEL_SUNMI_V2,
    charsetName: CHARSET_NAME_866,
    charsetId: CHARSET_ID_17,
    turnOffChinese: true,
  },
  {
    title: 'iMin M2 series',
    name: PRINTER_MODEL_IMIN_M2,
    charsetName: CHARSET_NAME_1251,
    charsetId: CHARSET_ID_16,
    turnOffChinese: true,
  },
  {
    title: 'iMin Swift',
    name: PRINTER_MODEL_IMIN_SWIFT,
    charsetName: CHARSET_NAME_1251,
    charsetId: CHARSET_ID_46,
    turnOffChinese: true,
  },
  {
    title: 'iMin Other',
    name: PRINTER_MODEL_IMIN_OTHER,
    charsetName: CHARSET_NAME_1251,
    charsetId: CHARSET_ID_73,
    turnOffChinese: true,
  },
];
