import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { LogPageComponent } from './logs/log/log.page';
import { LogsPageComponent } from './logs/logs.page';
import { LabelPrinterComponent } from './printer/label/label-printer.component';
import { ReceiptPrinterComponent } from './printer/receipt/receipt-printer.component';
import { ScalesComponent } from './scales/scales.component';
import { SecondScreenComponent } from './second-screen/second-screen.component';
import { SettingsComponent } from './settings.component';
import { TerminalsComponent } from './terminals/terminals.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
  },
  {
    path: 'barcode-scanner',
    component: BarcodeScannerComponent,
  },
  {
    path: 'receipt-printer',
    component: ReceiptPrinterComponent,
  },
  {
    path: 'label-printer',
    component: LabelPrinterComponent,
  },
  {
    path: 'terminals',
    component: TerminalsComponent,
  },
  {
    path: 'scales',
    component: ScalesComponent,
  },
  {
    path: 'integrations',
    component: IntegrationsComponent,
  },
  {
    path: 'second-screen',
    component: SecondScreenComponent,
  },
  {
    path: 'logs',
    component: LogsPageComponent,
  },
  {
    path: 'logs/:key',
    component: LogPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
