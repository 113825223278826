import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LogsService } from '../logs.service';

@Component({
  selector: 'bk-log',
  templateUrl: './log.page.html',
  styleUrls: ['./log.page.scss'],
})
export class LogPageComponent implements OnInit {
  key: string;
  value: string;

  constructor(
    private route: ActivatedRoute,
    private logsService: LogsService,
  ) {}

  ngOnInit(): void {
    this.key = this.route.snapshot.params['key'];
  }

  async ionViewWillEnter(): Promise<void> {
    const data = await this.logsService.data(this.key);

    this.value = data.join('\n');
  }
}
