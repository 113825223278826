import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';

import { Product } from '../../../../shop/products/product.model';
import { ProductsService } from '../../../../shop/products/products.service';
import { EleksDocItem } from '../doc-item.interface';
import { EleksDoc } from '../doc.interface';

@Component({
  selector: 'bk-eleks-select-dialog',
  templateUrl: './eleks-select.dialog.html',
  styleUrls: ['./eleks-select.dialog.scss'],
})
export class EleksSelectDialog implements OnInit {
  @ViewChild(IonInput) input: IonInput;

  @Input() docs?: EleksDoc[];
  @Input() docItem?: EleksDocItem;

  title = '';
  caption = '';
  search = '';

  products: Product[] = [];
  filteredProducts: Product[] = [];

  items: EleksDoc[] | Product[];

  PRODUCTS_ROWS = 50;

  constructor(
    private modalCtrl: ModalController,
    private productsService: ProductsService,
  ) {}

  ngOnInit(): void {
    if (this.docs != null) {
      this.title = 'Вибір чеку';
      this.caption = 'Оберіть чек для завершення операції';
      this.items = this.docs;
    } else if (this.docItem != null) {
      this.title = 'Вибір найменування';
      this.caption = `Для отриманого запису "${this.docItem.name}" (${this.docItem.quantity} x ${this.docItem.price}) знайдено більше одного найменування. Оберіть правильне`;
    } else {
      this.title = 'Найменування за замовчуванням';
    }

    if (this.docs != null && this.docItem == null) {
      return;
    }

    this.productsService
      .findForApp({}, { useIndexedDb: true })
      .subscribe((products) => {
        this.products = products;
        this.filteredProducts = products;

        this.items = products.filter((p) =>
          p.name.startsWith((this.docItem?.name ?? '').trim()),
        );
      });
  }

  ionViewDidEnter(): void {
    this.setFocusOnInput();
  }

  filterList(): void {
    const searchedWords = this.search.trim().toLowerCase().split(' ');

    this.filteredProducts = this.products.filter((product) => {
      const name = product.name.trim().toLowerCase();

      return (
        this.search === '' || searchedWords.every((word) => name.includes(word))
      );
    });
  }

  selectAll(): void {
    this.input.getInputElement().then((data) => {
      data.select();
    });
  }

  async confirm(item: EleksDoc | Product): Promise<void> {
    await this.modalCtrl.dismiss({ item });
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ item: null });
  }

  private setFocusOnInput(): void {
    setTimeout(() => {
      if (this.input) {
        this.input.setFocus();
      }
    }, 100);
  }
}
