import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';

import { CachedResource } from '../../core/abstract/cached-resource';
import { CachedDataService } from '../../core/services/cached-data.service';

import { Product } from './product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends CachedResource<Product> {
  constructor(
    protected http: HttpClient,
    protected platform: Platform,
    protected network: Network,
    private cachedDataService: CachedDataService,
  ) {
    super(http, platform, network, {
      path: '/products',
      cache: true,
      preferCache: true,
    });
  }

  findForApp(
    options: Partial<Product> = {},
    customOptions?: { forceRefresh?: boolean; useIndexedDb?: boolean },
  ): Observable<Product[]> {
    if (options.subcategoryId == null) {
      options = {
        ...options,
        shopId: this.cachedDataService.getShopId(),
      };
    }

    return super.findForApp(options, customOptions);
  }

  updatePrice(id: number, body: Product): Observable<Product> {
    return this.http.patch<Product>(`${this.path}/price/${id}`, body);
  }

  updateBarcode(id: number, body: Product): Observable<Product> {
    return this.http.patch<Product>(`${this.path}/barcode/${id}`, body);
  }

  async localProducts(): Promise<Product[]> {
    const key = `${this.buildUrl()}/app?shopId=${this.cachedDataService.getShopId()}`;

    const productsData = await this.getData(key);

    if (productsData != null) {
      return JSON.parse(productsData) as Product[];
    }
    return [];
  }
}
