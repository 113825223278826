<div class="check" *ngIf="serviceDoc">
  <section>
    <bk-view-line [center]="serviceDoc.companyName"></bk-view-line>
    <bk-view-line [center]="serviceDoc.shopName" [bold]="true"></bk-view-line>
    <bk-view-line [center]="serviceDoc.shopAddress"></bk-view-line>
    <bk-view-line [center]="serviceDoc.IPN"></bk-view-line>
    <bk-view-line [center]="serviceDoc.EDRPOU" *ngIf="!serviceDoc.IPN"></bk-view-line>
    <hr>
    <bk-view-line [center]="serviceDoc.name" [bold]="true"></bk-view-line>
    <hr>
  </section>

  <section class="list border-bottom">
    <bk-view-line [start]="serviceDocAmountCaption" [end]="serviceDoc.amount"></bk-view-line>
    <hr>
  </section>

  <section *ngIf="serviceDoc.isFiscal; else notFiscal">
    <bk-view-line [start]="serviceDoc.checkFiscalNumber" [end]="serviceDoc.date"></bk-view-line>
    <bk-view-line [line]="serviceDoc.prroFiscalNumber"></bk-view-line>
    <bk-view-line [center]="serviceDoc.checkType"></bk-view-line>
  </section>

  <ng-template #notFiscal>
    <section>
      <bk-view-line [center]="serviceDoc.date"></bk-view-line>
    </section>
  </ng-template>

  <bk-view-line [center]="prroName"></bk-view-line>

  <div class="page-break"></div>
</div>
