<ion-header>
  <ion-toolbar>
    <ion-title>Зміна ціни</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="product-price">
    <h2 class="product-price__title">{{ saleProduct.product.name }}</h2>
    <h3 class="product-price__price">{{ saleProduct.product.price }} грн x {{ saleProduct.quantity }} {{ saleProduct.product.amount }} = {{ (saleProduct.product.price * saleProduct.quantity) | currency: 'UAH':'symbol-narrow' }}</h3>

    <div class="product-price__data">
      <form #productForm="ngForm">
        <ion-list lines="inset">
          <ion-item>
            <ion-segment color="dark" (ionChange)="changedTab($event)" [value]="activeTab">
              <ion-segment-button *ngFor="let tab of tabs; let index = index" [value]="tab.name">
                <ion-label>{{ tab.caption }}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>

          <ion-item>
            <ion-input  type="number"
                        inputmode="numeric"
                        name="input"
                        [placeholder]="placeholder()"
                        debounce="250"
                        [(ngModel)]="value"
                        [autofocus]="true"
                        [required]="true"
                        [min]="MIN_CURRENCY_VALUE_NOT_ZERO.toString()"
                        [max]="maxValue().toString()"
                        [bkMin]="MIN_CURRENCY_VALUE_NOT_ZERO"
                        [bkMax]="maxValue()"
                        (ionFocus)="selectAll()"
                        (ngModelChange)="calcData()"
                        (keyup.enter)="confirm()"></ion-input>
          </ion-item>

          <section *ngIf="activeTab === productPriceTab.Price; else discountMode">
            <ion-item class="note">
              <ion-label slot="start">Різниця</ion-label>
              <ion-label slot="end">{{ (price - saleProduct.product.price) | currency:'UAH':'symbol-narrow'}}</ion-label>
            </ion-item>

            <ion-item class="note" *ngIf="saleProduct.quantity !== 1">
              <ion-label slot="start">Загальна різниця</ion-label>
              <ion-label slot="end">{{ ((price - saleProduct.product.price) * saleProduct.quantity) | currency:'UAH':'symbol-narrow' }}</ion-label>
            </ion-item>
          </section>

          <ng-template #discountMode>
            <ion-item class="note">
              <ion-label slot="start">Нова ціна</ion-label>
              <ion-label slot="end">{{ price | currency:'UAH':'symbol-narrow'}}</ion-label>
            </ion-item>

            <ion-item class="note" *ngIf="activeTab === productPriceTab.Percent">
              <ion-label slot="start">Знижка</ion-label>
              <ion-label slot="end">{{ discount | currency:'UAH':'symbol-narrow'}}</ion-label>
            </ion-item>

            <ion-item class="note" *ngIf="saleProduct.quantity !== 1">
              <ion-label slot="start">Сума знижки</ion-label>
              <ion-label slot="end">{{ (discount * saleProduct.quantity) | currency:'UAH':'symbol-narrow' }}</ion-label>
            </ion-item>
          </ng-template>

          <ion-item class="note">
            <ion-label slot="start">Сума</ion-label>
            <ion-label slot="end">{{ (price * saleProduct.quantity) | currency:'UAH':'symbol-narrow' }}</ion-label>
          </ion-item>
        </ion-list>
      </form>
    </div>
  </section>
</ion-content>
  
<ion-footer>
  <ion-toolbar>
    <ion-button slot="start" class="two-buttons" color="tertiary" (click)="cancel()">
      <ion-label>Скасувати</ion-label>
    </ion-button>

    <ion-button slot="end" class="two-buttons" color="primary" (click)="confirm()" [disabled]="productForm.invalid">
      <ion-label>Підтвердити</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>
