import { ROW_LENGTH_32 } from '../receipt-printer.const';
import {
  ESC_POS_NEW_LINE,
  PRESALE_CLIENT_CAPTION,
  PRESALE_COMMENT_CAPTION,
  PRESALE_COMPLETE_BY_CAPTION,
  PRESALE_CREATED_AT_CAPTION,
  PRESALE_HEADER_NAME_CAPTION,
  PRESALE_HEADER_QUANTITY_CAPTION,
} from '../receipt.const';

import { PrintProductItem } from './print-product-item.model';
import { PrintReceipt } from './print-receipt.model';

export class PrintPresale extends PrintReceipt {
  employee: string;
  createdAt: string;
  completeBy: string;
  clientName: string;
  clientPhone: string;
  comment: string;
  products: PrintProductItem[];

  receipt(): string[] {
    const receipt: string[] = [];

    receipt.push(
      ...this.alignToLeft(`${PRESALE_CREATED_AT_CAPTION} ${this.createdAt}`),
    );

    if (this.rowLength === ROW_LENGTH_32) {
      receipt.push(...this.alignToLeft(`${PRESALE_COMPLETE_BY_CAPTION}:`));
      receipt.push(...this.alignToLeft(`> ${this.completeBy}`));
    } else {
      this.addTwoColumnItemToReceipt(
        `${PRESALE_COMPLETE_BY_CAPTION}`,
        `${this.completeBy}`,
        receipt,
      );
    }

    const indexLength = 3;
    const quantityLength = 6;
    const nameLength = this.rowLength - quantityLength - indexLength;

    const headerIndexRow = '#'.padEnd(indexLength, ' ');
    const headerNameRow = PRESALE_HEADER_NAME_CAPTION.padEnd(nameLength, ' ');
    const headerQuantityRow = PRESALE_HEADER_QUANTITY_CAPTION.padStart(
      quantityLength,
      ' ',
    );

    receipt.push(this.rowDevider());

    receipt.push(
      `${this.prefix()}${headerIndexRow}${headerNameRow}${headerQuantityRow}`,
    );

    receipt.push(this.rowDevider());

    this.products.forEach((product, index) => {
      const indexValue = `${(index + 1).toString().padEnd(indexLength, ' ')}`;
      const indexEmptyValue = `${''.padEnd(indexLength, ' ')}`;
      const nameValue = `${product.name}, ${product.type}`;
      const quantityValue = `${product.quantity.padStart(quantityLength, ' ')}`;

      if (nameValue.length > nameLength) {
        const nameValueByRows = this.getTextByRows(nameValue, nameLength);
        const nameValueRows = nameValueByRows.split(ESC_POS_NEW_LINE);

        nameValueRows.forEach((nameValueRow, rowNumber) => {
          if (rowNumber === 0) {
            receipt.push(
              `${this.prefix()}${indexValue}${nameValueRows[rowNumber].padEnd(
                nameLength,
                ' ',
              )}${quantityValue}`,
            );
          } else {
            receipt.push(
              `${this.prefix()}${indexEmptyValue}${nameValueRows[
                rowNumber
              ].padEnd(this.rowLength - indexLength, ' ')}`,
            );
          }
        });
      } else {
        receipt.push(
          `${this.prefix()}${indexValue}${nameValue.padEnd(
            nameLength,
            ' ',
          )}${quantityValue}`,
        );
      }

      if (product.exciseLabel > '') {
        receipt.push(
          ...this.alignToLeft(
            `${''.toString().padEnd(indexLength, ' ')}${
              product.exciseLabelRow
            }`,
          ),
        );
      }
    });

    receipt.push(this.rowDevider());

    if (this.comment > '') {
      receipt.push(
        ...this.alignToLeft(`${PRESALE_COMMENT_CAPTION}:`, { isBold: true }),
      );

      receipt.push(...this.alignToLeft(`${this.comment}`));
      receipt.push(this.rowDevider());
    }

    if (this.clientName > '' || this.clientPhone > '') {
      receipt.push(
        ...this.alignToLeft(`${PRESALE_CLIENT_CAPTION}:`, { isBold: true }),
      );

      this.addTwoColumnItemToReceipt(
        `${this.clientName}`,
        `${this.clientPhone}`,
        receipt,
      );
    }

    return receipt;
  }
}
