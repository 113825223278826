<ion-header>
  <ion-toolbar>
    <ion-title>
      <div class="title">
        <ion-label>{{ salePayment.amount > 0 ? 'Безготівкова оплата' : 'Повернення коштів' }}</ion-label>
        <ion-label>{{ salePayment.amount | currency: 'UAH' : 'symbol-narrow' }}</ion-label>
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="payment">
    <ion-segment color="dark" (ionChange)="changedTab($event)" [value]="activeTab" *ngIf="true || terminalData?.connectionProtocol">
      <ion-segment-button *ngFor="let tab of tabs; let index = index" [value]="tab.name">
        <ion-label>{{ tab.caption }}</ion-label>
      </ion-segment-button>
    </ion-segment>

    <div class="payment__data" *ngIf="activeTab === cashlessPaymentTab.Data; else logTab">
      <ion-grid>
        <ion-row>
          <ion-col size="6">
            <ion-item>
              <ion-label  position="floating">Еквайр (Банк)</ion-label>
              <ion-input  type="text"
                          id="acquirerName"
                          name="acquirerName"
                          [(ngModel)]="salePayment.acquirerName"
                          (keyup.enter)="setFocus('deviceId')"></ion-input>
            </ion-item>
          </ion-col>

          <ion-col size="6">
            <ion-item>
              <ion-label  position="floating">Термінал (ID)</ion-label>
              <ion-input  #deviceId
                          type="text"
                          id="deviceId"
                          name="deviceId"
                          [(ngModel)]="salePayment.deviceId"
                          (keyup.enter)="setFocus('epzDetails')"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6">
            <ion-item>
              <ion-label  position="floating">ЕПЗ ({{ epzDetailsPrefix + epzDetailsMask }})</ion-label>
              <ion-input  #epzDetails
                          type="tel"
                          id="epzDetails" 
                          name="epzDetails"
                          [specialCharacters]="[ 'X', ' ' ]"
                          [prefix]="epzDetailsPrefix"
                          [mask]="epzDetailsMask"
                          pattern="(\d{4}|[X]{4})\s(\d{2}|[X]{2})[X]{2}\s[X]{4}\s\d{4}"
                          minlength=16
                          maxlength=19
                          [(ngModel)]="salePayment.epzDetails"
                          (ionChange)="parsePaymentSystemName()"
                          (keyup.enter)="setFocus('paymentSystem')"></ion-input>
            </ion-item>
          </ion-col>

          <ion-col size="6">
            <ion-item>
              <ion-label  position="floating">Платіжна система</ion-label>
              <ion-input  #paymentSystem
                          type="text" 
                          id="paymentSystem"
                          name="paymentSystem" 
                          [(ngModel)]="salePayment.paymentSystem"
                          (ionChange)="updadeEPZMask()"
                          (keyup.enter)="setFocus('acquirerTransactionId')"></ion-input>

              <ion-button icon-only slot="end" fill="clear" size="small" color="medium" class="select" (click)="selectpaymentSystem()">
                <ion-icon name="ellipsis-vertical"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6">
            <ion-item>
              <ion-label  position="floating">RRN транзакції</ion-label>
              <ion-input  #acquirerTransactionId
                          type="tel"
                          id="acquirerTransactionId"
                          name="acquirerTransactionId"
                          [(ngModel)]="salePayment.acquirerTransactionId"
                          (keyup.enter)="setFocus('authCode')"></ion-input>
            </ion-item>
          </ion-col>

          <ion-col size="6">
            <ion-item>
              <ion-label  position="floating">Код авторизації</ion-label>
              <ion-input  #authCode
                          type="tel"
                          id="authCode"
                          name="authCode"
                          [(ngModel)]="salePayment.authCode"
                          (keyup.enter)="confirm('Confirmed manually')"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="(this.isAndroidApp || this.isDesktop) && terminalData?.connectionProtocol">
          <ion-col size="12">
            <ion-item>
              <ion-spinner slot="start" *ngIf="terminalSpinner"></ion-spinner>
              <ion-label class="status">{{ terminalStatus }}</ion-label>

              <ion-buttons slot="end" *ngIf="isPaymentApp; else defaultTerminalButtons">
                <ion-button fill="solid" color="tertiary" (click)="status()">
                  <ion-icon name="information-outline"></ion-icon>
                </ion-button>

                <ion-button fill="solid" color="secondary" (click)="sendMessage()" *ngIf="terminalRetry">
                  <ion-icon name="repeat-outline"></ion-icon>
                </ion-button>

                <ion-button fill="solid" color="primary" (click)="interrupt('Interrupt manually')" *ngIf="terminalData?.connectionProtocol === terminalConnectionProtocol.TapToMono">
                  <ion-icon name="close-outline"></ion-icon>
                </ion-button>
              </ion-buttons>

              <ng-template #defaultTerminalButtons>
                <ion-buttons slot="end">
                  <ion-button fill="solid" color="primary" (click)="sendMessage()" *ngIf="terminalRetry; else cancelButton">Повторити</ion-button>

                  <ng-template #cancelButton>
                    <ion-button fill="solid" color="primary" (click)="interrupt('Interrupt manually')">Скасувати</ion-button>
                  </ng-template>
                </ion-buttons>
              </ng-template>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <ng-template #logTab>
      <div class="payment__data">
        <div class="payment__data__log">
          <bk-log-view name="Terminal session" [data]="logData"></bk-log-view>
        </div>
      </div>
    </ng-template>
  </section>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button slot="start" class="two-buttons" color="tertiary" (click)="cancel('Closed manually')">
      <ion-label>Скасувати</ion-label>
    </ion-button>

    <ion-button slot="end" class="two-buttons" color="primary" (click)="confirm('Confirmed manually')">
      <ion-label>{{ salePayment.amount > 0 ? 'Оплачено' : 'Повернено' }}</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>
