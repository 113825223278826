<ion-header>
  <ion-toolbar>
    <ion-title>Штрихкоди товару</ion-title>

    <ion-buttons slot="primary">
      <bk-bluetooth-barcode-scanner *ngIf="userSettings.keyboardMode && isBluetoothEnabled"
                                    [defaultEmitter]="true"
                                    (checkCode)="handleScannerCode($event)"></bk-bluetooth-barcode-scanner>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="barcode">
    <h2 class="barcode__title">{{ product.name }}</h2>

    <div class="barcode__data">
      <ion-list lines="none" class="table-data">
        <div class="even-row" *ngFor="let barcode of barcodes; let i = index">
          <ion-item>
            <ion-input  #barcodeInput
                        inputmode="numeric"
                        type="text"
                        id="barcode_{{i}}"
                        name="barcode_{{i}}"
                        placeholder="Штрихкод"
                        [value]="barcode"
                        (ionFocus)="selectAll(barcodeInput)"
                        (ionBlur)="update($event, i)"></ion-input>

            <ion-button slot="end" (click)="delete(i)">
              <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
            </ion-button>
          </ion-item>
        </div>

        <ion-item [button]="true" (click)="add()">
          <ion-icon name="add"></ion-icon>
        </ion-item>
      </ion-list>
    </div>
  </section>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button slot="start" class="two-buttons" color="tertiary" (click)="cancel()">
      <ion-label>Скасувати</ion-label>
    </ion-button>

    <ion-button slot="end" class="two-buttons" color="primary" (click)="save()">
      <ion-label>Підтвердити</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>
