import { PRESALE_GROUP_ROWS } from '../core/constants/visible-rows.const';

import { Presale } from './presale/presale.model';

export class PresalesGroup {
  date: string;
  createdPresales: Presale[];
  completedPresales: Presale[];
  collapsed: boolean;
  visibleRows: number;

  constructor(date: string) {
    this.date = date;
    this.createdPresales = [];
    this.completedPresales = [];
    this.collapsed = true;
    this.visibleRows = PRESALE_GROUP_ROWS;
  }
}
