import { Component, OnInit } from '@angular/core';
import {
  ActionSheetButton,
  ActionSheetController,
  AlertController,
  NavController,
} from '@ionic/angular';

import { environment } from '../../../environments/environment';
import { UtilsService } from '../../core/services/utils.service';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../settings.const';

import { ScalesConnectionMethod } from './scales-connection-method.enum';
import { ScalesModel } from './scales-model.enum';
import {
  DEFAULT_COM_PORT,
  DEFAULT_DELAY,
  DEFAULT_SCALES,
  DEFAULT_SETTINGS,
  DEFAULT_WEBSOCKET_PORT,
  MIN_DELAY,
} from './scales.const';
import { Scales } from './scales.model';
import { ScalesService } from './scales.service';

@Component({
  selector: 'bk-scales',
  templateUrl: './scales.component.html',
  styleUrls: ['./scales.component.scss'],
})
export class ScalesComponent implements OnInit {
  readonly scalesConnectionMethod = ScalesConnectionMethod;

  connectionMethods: ScalesConnectionMethod[] = [ScalesConnectionMethod.None];

  readonly supportedScales: Scales[] = [
    DEFAULT_SCALES,
    new Scales(ScalesModel.CAS_DLL, 'CAS DLL', {
      isDesktopSupport: true,
    }),
    new Scales(ScalesModel.CAS_ER_JR, 'CAS ER JR', {
      isDesktopSupport: true,
      isAndroidSupport: true,
    }),
    new Scales(ScalesModel.CAS_PR_II, 'CAS PR II', {
      isDesktopSupport: true,
      isAndroidSupport: true,
    }),
    new Scales(ScalesModel.CAS_SW_II, 'CAS SW II', {
      isDesktopSupport: true,
      isAndroidSupport: true,
    }),
    new Scales(ScalesModel.CAS_PB, 'CAS PB', {
      isDesktopSupport: true,
      isAndroidSupport: true,
    }),
    new Scales(ScalesModel.ICS_NT_DLL, 'ІКС-МАРКЕТ DLL', {
      isDesktopSupport: true,
    }),
    new Scales(ScalesModel.PROM_PRYLAD_BTA_60_7, 'ПРОМПРИЛАД ВТА-60/...-7', {
      isDesktopSupport: true,
      isAndroidSupport: true,
    }),
  ];

  isConnected: boolean;
  isAndroidApp: boolean;
  isDesktop: boolean;

  requestInProgress = false;

  settings = DEFAULT_SETTINGS;

  availableScales: Scales[] = [];

  additionOptions = false;

  DEFAULT_DELAY = DEFAULT_DELAY;
  DEFAULT_COM_PORT = DEFAULT_COM_PORT;
  DEFAULT_WEBSOCKET_PORT = DEFAULT_WEBSOCKET_PORT;

  MIN_DELAY = MIN_DELAY;

  constructor(
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private utilsService: UtilsService,
    private scalesService: ScalesService,
  ) {
    this.isDesktop = this.utilsService.isDesktop();
    this.isAndroidApp = this.utilsService.isAndroidApp();

    if (this.isDesktop) {
      this.connectionMethods.push(...[ScalesConnectionMethod.Cashbox]);

      this.availableScales = this.supportedScales.filter(
        (s) => s.isDesktopSupport,
      );
    } else if (this.isAndroidApp) {
      this.connectionMethods.push(...[ScalesConnectionMethod.USB]);

      this.availableScales = this.supportedScales.filter(
        (s) => s.isAndroidSupport,
      );
    }
  }

  async ngOnInit(): Promise<void> {
    this.scalesService.isConnected().subscribe((active) => {
      this.isConnected = active;
      this.requestInProgress = false;
    });
  }

  async ionViewWillEnter(): Promise<void> {
    this.settings = await this.scalesService.getSettings();

    if (this.settings.connectionMethod === ScalesConnectionMethod.USB) {
      //
    }

    this.settings.scales =
      this.availableScales.find((s) => s.name === this.settings.scales.name) ??
      DEFAULT_SCALES;
  }

  async openMenu(): Promise<void> {
    const actionSheetButtons: ActionSheetButton[] = [
      {
        text: 'Скасувати',
        icon: 'close',
        role: 'cancel',
      },
    ];

    actionSheetButtons.push({
      text: 'Очистити дані',
      icon: 'trash-outline',
      cssClass: 'clear',
      handler: () => {
        this.clearData();
      },
    });

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Додаткові операції',
      cssClass: 'terminals-action-sheet-controller',
      buttons: actionSheetButtons,
    });

    await actionSheet.present();
  }

  private async clearData(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: `Ваги`,
      message: `<strong>Видалити</strong> всі налаштування та інформацію про ваги?`,
      buttons: [
        { text: 'Скасувати', role: 'cancel', cssClass: 'tertiary' },
        {
          text: 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            await this.scalesService.clearData();

            this.navCtrl.back();
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  connectionMethodName(method: ScalesConnectionMethod): string {
    switch (method) {
      case ScalesConnectionMethod.Cashbox:
        return 'cashbox | Scales';

      case ScalesConnectionMethod.USB:
        return 'Кабель USB';

      default:
        return 'Немає';
    }
  }

  async setConnectionMethod(): Promise<void> {
    this.setScales();

    if (this.settings.connectionMethod === ScalesConnectionMethod.USB) {
      return;
    }
  }

  isScalesAvailable(): boolean {
    return (
      this.settings.connectionMethod === ScalesConnectionMethod.Cashbox ||
      this.settings.connectionMethod === ScalesConnectionMethod.USB
    );
  }

  async setScales(): Promise<void> {
    await this.scalesService.setSettings(this.settings);

    if (this.settings.connectionMethod === ScalesConnectionMethod.None) {
      this.scalesService.disconnect();
    }
  }

  downloadAgent(): void {
    window.open(`${environment.apiUrl}/settings/scales`, '_blank');
  }

  startAgent(): void {
    this.scalesService.startAgent();
  }

  connect(): void {
    this.requestInProgress = true;

    this.scalesService.connect(this.settings);
  }

  disconnect(): void {
    this.requestInProgress = true;

    this.scalesService.disconnect();
  }
}
