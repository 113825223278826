import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgxPrinterService } from 'ngx-printer';
import { PrinterConnectionMethod } from 'src/app/settings/printer/printer-connection-method.enum';

import { IStorageUserSettings } from '../../../core/interfaces/storage-user-setting.interface';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { ToastService } from '../../../core/services/toast.service';
import { LabelPrinterData } from '../label/label-printer-data.model';
import { LabelPrinterPrintService } from '../label/services/label-printer-print.service';
import { VIEW_COMPONENT_ID } from '../printer.const';
import { ReceiptPrinterData } from '../receipt/receipt-printer-data.model';
import { ReceiptPrinterPrintService } from '../receipt/services/receipt-printer-print.service';
import { PrintMove } from '../receipt/types/print-move.model';
import { PrintPRroServiceDoc } from '../receipt/types/print-p-rro-service-doc.model';
import { PrintPRroZReport } from '../receipt/types/print-p-rro-z-report.model';
import { PrintPresale } from '../receipt/types/print-presale.model';
import { PrintRefillSalePrices } from '../receipt/types/print-refill-sale-prices.model';
import { PrintRefill } from '../receipt/types/print-refill.model';
import { PrintSale } from '../receipt/types/print-sale.model';
import { PrintShift } from '../receipt/types/print-shift.model';
import { PrintWaste } from '../receipt/types/print-waste.model';

@Component({
  selector: 'bk-view-dialog',
  templateUrl: './view.dialog.html',
  styleUrls: ['./view.dialog.scss'],
})
export class ViewDialog implements OnInit {
  @Input() data: ReceiptPrinterData | LabelPrinterData;
  @Input() isPrinterAvailable = false;

  userSettings: IStorageUserSettings;

  isSale = false;
  isPresale = false;
  isWaste = false;
  isReport = false;
  isServiceDoc = false;
  isShift = false;
  isMove = false;
  isRefill = false;
  isRefillSalePrices = false;
  isLabels = false;

  @HostListener('window:afterprint', ['$event'])
  async afterprintEvent(event: any): Promise<void> {
    if (this.data.silentPrint) {
      await this.close();
    }
  }

  constructor(
    private modalCtrl: ModalController,
    private cachedDataService: CachedDataService,
    private toastService: ToastService,
    private ngxPrinterService: NgxPrinterService,
    private receiptPrinterPrintService: ReceiptPrinterPrintService,
    private labelPrinterPrintService: LabelPrinterPrintService,
  ) {
    this.userSettings = this.cachedDataService.getUserSettings();
  }

  ngOnInit(): void {
    if (this.data == null) {
      return;
    }

    if (this.data instanceof ReceiptPrinterData) {
      this.isSale = this.data.doc instanceof PrintSale;
      this.isPresale = this.data.doc instanceof PrintPresale;
      this.isWaste = this.data.doc instanceof PrintWaste;
      this.isReport = this.data.doc instanceof PrintPRroZReport;
      this.isServiceDoc = this.data.doc instanceof PrintPRroServiceDoc;
      this.isShift = this.data.doc instanceof PrintShift;
      this.isMove = this.data.doc instanceof PrintMove;
      this.isRefill = this.data.doc instanceof PrintRefill;
      this.isRefillSalePrices = this.data.doc instanceof PrintRefillSalePrices;
    } else if (this.data instanceof LabelPrinterData) {
      this.isLabels = true;
    }
  }

  async ionViewWillEnter(): Promise<void> {
    if (this.data.silentPrint) {
      await this.print();
    }
  }

  async print(): Promise<void> {
    if (!this.isPrinterAvailable) {
      this.toastService.presentWarning(
        `Друк ${this.isLabels ? 'етикеток' : 'чеків'}`,
        'Не вказаний спосіб підключення принтера або друк на поточній платформі не підтримується',
      );

      return;
    }

    if (
      this.data.settings.connectionMethod === PrinterConnectionMethod.Browser
    ) {
      if (this.isLabels) {
        this.toastService.presentWarning(
          'Друк етикеток',
          'Друк за допомогою браузера тимчасово працює не правильно\nВикористовуйте сервіс QZ Tray',
        );
      }

      this.ngxPrinterService.printOpenWindow = false;

      this.ngxPrinterService.printDiv(this.isLabels ? 'labels' : 'check');

      this.ngxPrinterService.printOpenWindow = true;
    } else if (this.data instanceof ReceiptPrinterData) {
      await this.receiptPrinterPrintService.print(this.data);
      await this.close();
    } else if (this.data instanceof LabelPrinterData) {
      await this.labelPrinterPrintService.print(this.data);
      await this.close();
    }
  }

  async close(): Promise<void> {
    const popover = await this.modalCtrl.getTop();

    if (popover != null) {
      await this.modalCtrl.dismiss(
        null,
        '',
        `${VIEW_COMPONENT_ID}_${this.data.type.toString()}`,
      );
    }
  }
}
