<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="settings"></ion-back-button>
    </ion-buttons>

    <ion-title>Інтеграції</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form #printerForm="ngForm">
    <ion-list [inset]="true" lines="full" class="settings">
      <ion-item>
        <ion-icon slot="start" name="git-compare-outline" [color]="isConnected ? 'success' : 'dark'"></ion-icon>
        <ion-label>Зовнішня інтеграція</ion-label>
        <ion-select id="integration" 
                    name="integration" 
                    okText="Вибрати" 
                    cancelText="Скасувати"
                    [disabled]="settings.name === integration.Eleks && isConnected"
                    [(ngModel)]="settings.name"
                    (ngModelChange)="setIntegration()">
          <ion-select-option *ngFor="let integrationItem of integrations" [value]="integrationItem">
            {{ integrationName(integrationItem) }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <section *ngIf="settings.name === integration.Eleks">
        <ion-item>
          <ion-icon slot="start" name="link-outline" [color]="isConnected ? 'success' : 'dark'"></ion-icon>
          <ion-label  position="stacked">Порт</ion-label>
          <ion-input  #portModel="ngModel"
                      type="tel"
                      name="port"
                      placeholder="8095"
                      maxlength="5"
                      [disabled]="settings.name === integration.Eleks && isConnected"
                      [(ngModel)]="settings.eleksPort"
                      (ngModelChange)="setIntegration()"
                      required></ion-input>
  
          <ion-note class="error-form-note" *ngIf="portModel.errors?.required">
            Порт websocket-сервера обов'язковий для заповнення
          </ion-note>
        </ion-item>
  
        <ion-item>
          <ion-icon slot="start" name="timer-outline" color="dark"></ion-icon>
          <ion-label>Інтервал очікування, с</ion-label>
          <ion-select id="interval" 
                      name="interval" 
                      okText="Вибрати" 
                      cancelText="Скасувати"
                      [(ngModel)]="settings.eleksInterval"
                      (ngModelChange)="setIntegration()">
            <ion-select-option *ngFor="let intervalOption of intervals" [value]="intervalOption">
              {{ intervalOption }}
            </ion-select-option>
          </ion-select>
        </ion-item>
  
        <ion-item >
          <ion-icon slot="start" name="eye-off-outline" color="dark"></ion-icon>
          <ion-label>Вибір першого збігу по ціні</ion-label>
          <ion-toggle slot="end" color="dark" name="selectFirstByPrice" [(ngModel)]="settings.eleksSelectFirstByPrice" (ngModelChange)="setIntegration()"></ion-toggle>
        </ion-item>
  
        <ion-item>
          <ion-icon slot="start" name="color-wand-outline" color="dark"></ion-icon>
          <ion-label>Автоматичне закриття чека</ion-label>
          <ion-toggle slot="end" color="dark" name="autoSale" [(ngModel)]="settings.eleksAutoSale" (ngModelChange)="setIntegration()"></ion-toggle>
        </ion-item>
  
        <ion-item>
          <ion-icon slot="start" name="bandage-outline"></ion-icon>
          <ion-label  position="stacked">Найменування за замовчуванням</ion-label>
          <ion-input  type="text"
                      name="defaultProduct"
                      [readonly]="true"
                      [(ngModel)]="defaultProductName"></ion-input>
  
          <ion-button class="no-margin width-80" slot="end" color="tertiary" (click)="selectProduct()">Змінити</ion-button>
          <ion-button class="no-margin width-38" slot="end" color="primary" (click)="deleteProduct()">
            <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
          </ion-button>
        </ion-item>
      </section>
    </ion-list>

    <ion-list [inset]="true" lines="full" *ngIf="settings.name === integration.Eleks">
      <ion-item button detail="false" (click)="downloadAgent()">
        <ion-icon slot="start" name="cloud-download-outline" color="secondary"></ion-icon>
        <ion-text>
          <ion-label>Завантажити файли для інтеграції</ion-label>
          <ion-note>Вміст архіву потрібно розархівувати у папку C:\cashbox\Eleks</ion-note>
        </ion-text>
      </ion-item>

      <ion-item button detail="false" (click)="startAgent()">
        <ion-icon slot="start" name="play-circle-outline" color="secondary"></ion-icon>
        <ion-label>Запустити програму</ion-label>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer *ngIf="settings?.name === integration.Eleks">
  <ion-toolbar>
    <ion-button class="one-button" 
                color="primary" 
                [disabled]="requestInProgress || printerForm.invalid"
                (click)="connect()"
                *ngIf="!isConnected">
      <ion-label>Підключити</ion-label>
    </ion-button>

    <ion-button class="one-button"
                color="primary"
                (click)="disconnect()"
                [disabled]="requestInProgress"
                *ngIf="isConnected">
      <ion-label>Відключити</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>