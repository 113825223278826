import { PRro } from '../../p-rro.model';
import { CheckDocumentSubType } from '../enums/check-document-sub-type.enum';
import { CheckDocumentType } from '../enums/check-document-type.enum';
import { DocumentClass } from '../enums/document-class.enum';

import { PRroZReport } from './p-rro-z-report.model';

export class PRroDoc {
  id: number;
  shopId: number;
  userId: number;
  shiftId: number;
  prroId: number;
  prro?: PRro;
  prroShiftId: number;
  docDateTime: Date;
  docData: string;
  docDataExist: boolean;
  docClass: DocumentClass;
  checkDocType: CheckDocumentType;
  checkDocSubType: CheckDocumentSubType;
  localNumber: number;
  taxNumber: string;
  testMode: boolean;
  revoked: boolean;
  storned: boolean;
  cashSum: number;
  cardSum: number;
  saleId: number;
  incasationId: number;
  expenseId: number;
  jsonData: PRroZReport;
}
