import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { of, Observable } from 'rxjs';
import { switchMap, switchMapTo, tap } from 'rxjs/operators';

import {
  AUTH_ERROR,
  NEED_PAYMENT,
  NEED_UPDATE,
  NO_SHOPS,
  ROLE_ERROR,
  SHOP_NOT_SELECTED,
  WRONG_CREDENTIALS,
} from '../../core/constants/error-messages.const';
import { SHOP_REFRESH } from '../../core/constants/events.const';
import { Shop } from '../../core/models/shop.model';
import { AuthService } from '../../core/services/auth.service';
import { CachedDataService } from '../../core/services/cached-data.service';
import { Events } from '../../core/services/events.service';
import { LoadingService } from '../../core/services/loading.service';
import { PreloaderService } from '../../core/services/preloader.service';
import { ShiftsService } from '../../core/services/resources/shifts.service';
import { ShopsService } from '../../core/services/resources/shops.service';
import { TariffService } from '../../core/services/resources/tariff.service';
import { UserService } from '../../core/services/resources/user.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { IntegrationService } from '../../settings/integrations/integration.service';
import { LogsService } from '../../settings/logs/logs.service';
import { LabelPrinterService } from '../../settings/printer/label/label-printer.service';
import { QzTrayService } from '../../settings/printer/qz-tray/qz-tray.service';
import { ReceiptPrinterService } from '../../settings/printer/receipt/receipt-printer.service';
import { SettingService } from '../../settings/settings.service';
import { TerminalsService } from '../../settings/terminals/terminals.service';
import { SelectShopDialog } from '../select-shop/select-shop.dialog';

@Component({
  selector: 'bk-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  username: string;
  password: string;
  pass: boolean;

  private loadingLoginName = 'login.component:login';

  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private events: Events,
    private authService: AuthService,
    private preloaderService: PreloaderService,
    private utilsService: UtilsService,
    private loadingService: LoadingService,
    private shopsService: ShopsService,
    private shiftsService: ShiftsService,
    private cachedDataService: CachedDataService,
    private tariffService: TariffService,
    private userService: UserService,
    private toastService: ToastService,
    private settingService: SettingService,
    private receiptPrinterService: ReceiptPrinterService,
    private labelPrinterService: LabelPrinterService,
    private terminalsService: TerminalsService,
    private integrationService: IntegrationService,
    private qzTrayService: QzTrayService,
    private logsService: LogsService,
  ) {
    this.pass = true;
  }

  togglePassword(event: MouseEvent): void {
    event.stopPropagation();

    this.pass = !this.pass;
  }

  async login(): Promise<void> {
    if (!(await this.utilsService.isOnline())) {
      this.toastService.presentNoInternet();

      return;
    }

    await this.loadingService.presentCustomPreloader(this.loadingLoginName);

    this.authService
      .authenticate(this.username, this.password)
      .pipe(
        switchMap(() => this.checkVersion()),
        switchMap(() => this.checkRole()),
        switchMap(() => this.checkUser()),
        switchMapTo(this.shopsService.find()),
        switchMap((shops) => this.chooseShop(shops)),
        tap((shop) => {
          this.loadingService.presentCustomPreloader('login.component:loading');
          this.cachedDataService.setShop(shop);
          this.events.publish(SHOP_REFRESH, shop);
          this.shopsService.setShop(shop);
          this.saveSession(shop);
        }),
        switchMap(() => this.loadQZSignData()),
        switchMap(() => this.preloaderService.preload()),
      )
      .subscribe(
        () => {
          this.loadingService.dismiss('login.component:loading');

          this.logsService.clearOutdated().then().catch();

          this.tariffService.canActivatePage().then((tariffPaid: boolean) => {
            if (tariffPaid) {
              this.shiftsService.getShift().subscribe((shift) => {
                this.events.publish('initialize-work-timer', true);
              });

              this.receiptPrinterService.init();
              this.labelPrinterService.init();
              this.integrationService.init();
              this.terminalsService.init();

              this.router.navigate(['/shop'], { replaceUrl: true });
            } else {
              this.alertCtrl
                .create({
                  header: 'Адміністративна помилка',
                  message: NEED_PAYMENT,
                  buttons: [{ text: 'Закрити', role: 'close' }],
                })
                .then((alert) => alert.present());

              this.router.navigate(['/login'], { replaceUrl: true });
            }
          });
        },
        (error) => {
          this.loadingService.dismiss(this.loadingLoginName);

          const errorMessage = this.getErrorMessage(error);

          if (
            errorMessage?.includes(NEED_UPDATE) &&
            errorMessage?.includes('~')
          ) {
            this.toastService.presentForUpdate(errorMessage.split('~')[1]);
          } else {
            this.toastService.presentError(AUTH_ERROR, errorMessage);
          }

          setTimeout(() => {
            this.authService.removeToken();
          }, 3500);
        },
      );
  }

  private saveSession(shop: Shop): void {
    const userSession = this.settingService.getSessionInfo(shop.id);

    this.userService.saveSession(userSession).subscribe((sessionId) => {
      this.cachedDataService.setSessionId(sessionId);
    });
  }

  private async selectShop(
    shops: Shop[],
    userId: number,
    isUserHaveShift: boolean,
  ): Promise<Shop> {
    const modal = await this.modalCtrl.create({
      component: SelectShopDialog,
      componentProps: {
        shops,
        userId,
        isUserHaveShift,
      },
      backdropDismiss: false,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    return data;
  }

  private checkVersion(): Observable<any> {
    return new Observable((observer) => {
      this.settingService
        .getVersion()
        .then((version) => {
          if (version.isCorrect) {
            observer.next();
          } else {
            observer.error(new Error(`${NEED_UPDATE}~${version.packageName}`));
          }
        })
        .catch((reason) => observer.error(new Error(reason)))
        .finally(() => observer.complete());
    });
  }

  private checkRole(): Observable<any> {
    return new Observable((observer) => {
      const role = this.authService.getUserRole().toUpperCase();
      const appSettings = this.cachedDataService.getAppSettings();

      if (role !== 'EMPLOYEE' && role !== 'SUPER_ADMIN') {
        observer.error(new Error(`${ROLE_ERROR} ${appSettings.url}`));
      } else {
        observer.next(role);
      }

      observer.complete();
    });
  }

  private checkUser(): Observable<any> {
    return new Observable((observer) => {
      this.userService.init();

      observer.next();
      observer.complete();
    });
  }

  private loadQZSignData(): Observable<void> {
    return new Observable((observer) => {
      this.qzTrayService
        .loadData()
        .then(() => {
          observer.next();
        })
        .catch((reason) => observer.error(new Error(reason)))
        .finally(() => observer.complete());
    });
  }

  private chooseShop(shops: Shop[]): Observable<Shop> {
    this.loadingService.dismiss(this.loadingLoginName);

    const userId = this.authService.getUserId();
    const isUserHaveShift = Boolean(
      shops.find(
        (shop) =>
          shop.shift != null &&
          (shop.shift.userId === userId ||
            shop.shift.users.find((shiftUser) => shiftUser.userId === userId)),
      ),
    );

    const user = this.cachedDataService.getUser();

    const shopIds = user.shopIds
      ? user.shopIds.split(',').map((id) => Number(id))
      : [];

    shops = shops.filter((shop) => shopIds.includes(shop.id));

    if (shops.length === 0) {
      throw new Error(NO_SHOPS);
    }

    if (shops.length === 1) {
      return of(shops[0]);
    }

    return new Observable((observer) => {
      this.selectShop(shops, userId, isUserHaveShift).then((shop) => {
        if (shop == null) {
          observer.error(new Error(SHOP_NOT_SELECTED));
        } else {
          observer.next(shop);
        }

        observer.complete();
      });
    });
  }

  private getErrorMessage(err: Error): string {
    if (!(err instanceof HttpErrorResponse)) {
      return err.message;
    }

    if (err.status === 401) {
      return WRONG_CREDENTIALS;
    }

    return err.error.message;
  }
}
