import { Shop } from '../../core/models/shop.model';

export class Category {
  id: number;
  name: string;
  position: number;
  coverPath: string;
  coverColor: string;
  coverLabel: string;

  // app field
  shopId: number;
  shop?: Shop;
}
