export const TRANSACTIONS_REFRESH = 'transactions:refresh';
export const TRANSACTIONS_SHIFT = 'transactions:shift';
export const TRANSACTIONS_Z_REPORT = 'transactions:z-report';

export const TERMINAL = 'terminal';
export const TERMINAL_SERVICE_MESSAGE = 'terminal:ServiceMessage';
export const TERMINAL_LOG = 'terminal:Log';

export const SYNC_PRODUCTS_SUCCESS = 'sync:products-success';

export const BARCODE_CHECK = 'barcode:check';

export const INVOICE_ADD_PRODUCT = 'invoice:add-product';
export const INVOICE_ADD_WEIGHT_PRODUCT = 'invoice:add-weight-product';
export const INVOICE_AUTO_SALE = 'invoice:auto-sale';

export const SETTINGS_ONLINE_CHECK = 'settings:online-check';
export const SETTINGS_BLUETOOTH_BARCODE_SCANNER =
  'settings:bluetooth-barcode-scanner';

export const SHOP_REFRESH = 'shop:refresh';
export const PRRO_STATE = 'prro:state';

export const RECEIPT_PRINTER_REFRESH = 'receipt-printer:refresh';
export const LABEL_PRINTER_REFRESH = 'label-printer:refresh';

export const BROADCAST_PING = 'ping';
export const BROADCAST_PONG = 'pong';
export const BROADCAST_SALE_INVOICE = 'invoice';
export const BROADCAST_SALE_SOLD = 'sold';
export const BROADCAST_SALE_CALCULATION = 'calculation';
export const BROADCAST_TAB_CREATED = 'tab-created';
export const BROADCAST_TAB_EXIST = 'tab-exist';
export const BROADCAST_TAB_HIDDEN = 'tab-hidden';
export const BROADCAST_TAB_ACTIVATED = 'tab-activated';
export const BROADCAST_TAB_CLOSED = 'tab-closed';
