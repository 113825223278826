import { ShiftVerifiedStatus } from '../enum/shift-verified-status.enum';

import { ShiftTotals } from './shift-totals.model';
import { ShiftUser } from './shift-user.model';

export class Shift {
  id: number;
  shopId: number;
  userId: number;
  createdAt: Date;
  closedAt?: Date;
  closed: boolean;
  verified: ShiftVerifiedStatus;

  totals: ShiftTotals;
  shiftUsers: ShiftUser[];

  lastShiftCashAmount?: number;
  isNeedSync?: boolean;
  isConnected?: boolean;
}
