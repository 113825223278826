import { Shop } from '../../../core/models/shop.model';
import { Product } from '../../../shop/products/product.model';

import { DEFAULT_SETTINGS } from './label-printer.const';
import { Label } from './label.enum';

export class LabelPrinterData {
  type = Label.Product;
  settings = DEFAULT_SETTINGS;
  silentPrint = false;

  logo = '';
  shop: Shop;

  products: Product[] = [];

  constructor(type: Label, products: Product[]) {
    this.type = type;
    this.products = products;
  }
}
