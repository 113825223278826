import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastButton, ToastController } from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { Resource } from '../core/abstract/resource';
import { SYNC_TOAST_MESSAGE } from '../core/constants/error-messages.const';
import {
  RECEIPT_PRINTER_REFRESH,
  SHOP_REFRESH,
} from '../core/constants/events.const';
import { ObjectLiteral } from '../core/interfaces/object-literal';
import { SyncResult } from '../core/models/sync-result.model';
import { CachedDataService } from '../core/services/cached-data.service';
import { Events } from '../core/services/events.service';
import { UtilsService } from '../core/services/utils.service';
import { ReceiptPrinterService } from '../settings/printer/receipt/receipt-printer.service';
import { PRESALES_STORAGE_KEY } from '../settings/settings.const';

import { PresaleProduct } from './presale/presale-product.model';
import { Presale } from './presale/presale.model';

interface IToastOptions {
  isOffline?: boolean;
  isError?: boolean;
  isUpdate?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PresalesService extends Resource<Presale> {
  private isPrintReceiptMode = false;
  private isPrintReceiptAutoPrintMode = false;

  constructor(
    protected http: HttpClient,
    private events: Events,
    private cachedDataService: CachedDataService,
    private receiptPrinterService: ReceiptPrinterService,
    private utilsService: UtilsService,
    private toastCtrl: ToastController,
  ) {
    super(http, {
      path: '/presales',
    });

    this.events.subscribe(SHOP_REFRESH, () => {
      this.refreshReceiptPrintStatus();
    });

    this.events.subscribe(
      RECEIPT_PRINTER_REFRESH,
      (status: { isAvailible: boolean; isAutoPrintMode: boolean }) => {
        this.isPrintReceiptMode = status.isAvailible;
        this.isPrintReceiptAutoPrintMode = status.isAutoPrintMode;
      },
    );

    this.refreshReceiptPrintStatus();
  }

  find(options: ObjectLiteral = {}): Observable<Presale[]> {
    options = { ...options, shopId: this.cachedDataService.getShopId() };

    return super.find(options).pipe(
      map((presales) =>
        presales.map((presale) => this.transformFromBackend(presale)),
      ),
      map((presales) => orderBy(presales, 'completeBy', 'desc')),
    );
  }

  get(id: number | string): Observable<Presale> {
    return super
      .get(id)
      .pipe(map((presale) => this.transformFromBackend(presale)));
  }

  async createPresale(currentPresale: Presale): Promise<Presale> {
    const isConnected = await this.utilsService.isOnline();

    currentPresale.shopId = this.cachedDataService.getShopId();
    currentPresale.uuid = uuid();

    const presaleToSave = this.transformToBackend(currentPresale, {});

    if (!isConnected) {
      return this.saveOfflinePresale(presaleToSave, currentPresale);
    }

    return super
      .create(presaleToSave)
      .pipe(
        tap(() => this.presentToast(currentPresale)),
        catchError((error) =>
          this.saveOfflinePresale(presaleToSave, currentPresale, error),
        ),
      )
      .toPromise()
      .finally(async () => {
        await this.print(currentPresale);
      });
  }

  async updatePresale(
    currentPresale: Presale,
    isConnected: boolean,
  ): Promise<Presale> {
    const presaleToSave = this.transformToBackend(currentPresale, {
      isUpdate: true,
    });

    presaleToSave.shopId = this.cachedDataService.getShopId();

    if (!isConnected) {
      return this.saveOfflinePresale(presaleToSave, currentPresale);
    }

    return super
      .update(currentPresale.id, presaleToSave)
      .pipe(
        tap(() => this.presentToast(currentPresale, { isUpdate: true })),
        catchError((error) =>
          this.saveOfflinePresale(presaleToSave, currentPresale, error),
        ),
      )
      .toPromise()
      .finally(async () => {
        await this.print(currentPresale);
      });
  }

  private async print(presale: Presale): Promise<void> {
    if (this.isPrintReceiptMode && this.isPrintReceiptAutoPrintMode) {
      await this.receiptPrinterService.printPresale(presale);
    }
  }

  async sync(): Promise<SyncResult> {
    const presales = this.getLocallySavedPresales();

    if (presales == null || presales.length === 0) {
      const emptyResult = new SyncResult();

      emptyResult.success = true;
      emptyResult.message = 'Немає даних для синхронізації замовлень';

      return emptyResult;
    }

    const syncResult = new SyncResult('замовлень');
    const unSyncedItems: Presale[] = [];
    const errors: string[] = [];

    syncResult.totalItems = presales.length;

    for (const presale of presales) {
      if (syncResult.timeoutErrors < 5) {
        await super
          .create(presale)
          .pipe(timeout(5000))
          .toPromise()
          .then((response) => {
            syncResult.successSync();
          })
          .catch((problem) => {
            syncResult.warningSync();
            unSyncedItems.push(presale);

            this.utilsService.addSyncError(problem, errors);
          });
      } else {
        unSyncedItems.push(presale);
      }
    }

    this.utilsService.setResultMessage(syncResult, errors);
    this.savePresalesLocally(unSyncedItems);

    return syncResult;
  }

  haveUnsyncedSales(): boolean {
    return this.getLocallySavedPresales().length > 0;
  }

  async presentToast(
    presale: Presale,
    data: IToastOptions = {},
  ): Promise<void> {
    const buttons: ToastButton[] = [];

    buttons.push({
      icon: 'print',
      handler: () => {
        this.receiptPrinterService.printPresale(presale);
      },
    });

    buttons.push({
      icon: 'close',
      role: 'cancel',
    });

    const toast = await this.toastCtrl.create({
      buttons,
      header: this.getToastHeader(data, ''),
      message: this.getToastMessage(data),
      cssClass:
        data.isOffline || data.isError ? 'warning-toast' : 'default-toast',
      duration: 7500,
    });

    toast.present();
  }

  private getToastHeader(
    data: IToastOptions = {},
    note: string,
  ): string | undefined {
    return data.isOffline
      ? `Оффлайн ${
          data.isUpdate ? 'оновлення ' : 'створення '
        }відкладеного чека${note}`
      : data.isError
      ? `Помилка ${
          data.isUpdate ? 'оновлення' : 'збереження'
        } даних на сервері${note}`
      : `${
          data.isUpdate ? 'Оновлено відкладений чек' : 'Чек відкладено'
        }${note}`;
  }

  private getToastMessage(data: IToastOptions = {}): string | undefined {
    return data.isOffline
      ? 'Необхідна синхронізація'
      : data.isError
      ? `Відкладений чек збережений на пристрої${SYNC_TOAST_MESSAGE}`
      : undefined;
  }

  private async saveOfflinePresale(
    preparedPresale: Presale,
    originalPresale: Presale,
    error?: any,
  ): Promise<Presale> {
    const parsedError = error ? this.utilsService.getParsedError(error) : null;

    this.saveLocally(preparedPresale);
    this.presentToast(originalPresale, {
      isOffline: (parsedError?.statusCode ?? 0) === 0,
      isError: (parsedError?.statusCode ?? 0) >= 400,
    });

    return preparedPresale;
  }

  private saveLocally(presale: Presale): void {
    const presales = this.getLocallySavedPresales() || [];

    presales.push(presale);

    this.savePresalesLocally(presales);
  }

  private getLocallySavedPresales(): Presale[] {
    const presales = localStorage.getItem(PRESALES_STORAGE_KEY);

    if (!presales) {
      return [];
    }

    return JSON.parse(presales);
  }

  private savePresalesLocally(presales: Presale[]): void {
    localStorage.setItem(PRESALES_STORAGE_KEY, JSON.stringify(presales));
  }

  private transformFromBackend(presale: Presale): Presale {
    const presaleClone: Presale = cloneDeep(presale);

    presaleClone.completeBy = new Date(presaleClone.completeBy);
    presaleClone.productNames = presaleClone.presaleProducts
      .map((presaleProduct) => presaleProduct.product.name)
      .join(', ');

    presaleClone.presaleProducts.forEach((presaleProduct) => {
      presaleProduct.product.name = this.utilsService.productName(
        presaleProduct.product,
      );

      presaleProduct.product.amount = this.utilsService.productMeasurement(
        presaleProduct.product,
      );
    });

    return presaleClone;
  }

  private transformToBackend(
    presale: Presale,
    operation: {
      isUpdate?: boolean;
    },
  ): Presale {
    const presaleToSave = this.getPresaleToSave(presale, operation);

    presale.presaleProducts.forEach((presaleProduct) => {
      const presaleProductToSave = this.getPresaleProductToSave(presaleProduct);

      presaleToSave.presaleProducts.push(presaleProductToSave);
    });

    return presaleToSave;
  }

  private getPresaleToSave(
    presale: Presale,
    operation: {
      isUpdate?: boolean;
    },
  ): Presale {
    const presaleToSave = new Presale(true);

    presaleToSave.cashless =
      presale.cashless === null ? null : Boolean(presale.cashless);

    presaleToSave.shopId = presale.shopId;
    presaleToSave.status = presale.status;
    presaleToSave.completeBy = presale.completeBy;
    presaleToSave.uuid = presale.uuid;

    if (operation.isUpdate) {
      presaleToSave.id = presale.id;
    }

    if (presale.clientId != null) {
      presaleToSave.clientId = presale.clientId;
    }

    if (presale.comment != null) {
      presaleToSave.comment = presale.comment;
    }

    if (presale.cashSum != null) {
      presaleToSave.cashSum = presale.cashSum;
    }

    if (presale.cardSum != null) {
      presaleToSave.cardSum = presale.cardSum;
    }

    if (presale.providedCash != null) {
      presaleToSave.providedCash = presale.providedCash;
    }

    presaleToSave.presaleProducts = [];

    return presaleToSave;
  }

  private getPresaleProductToSave(
    presaleProduct: PresaleProduct,
  ): PresaleProduct {
    const presaleProductToSave = new PresaleProduct();

    presaleProductToSave.productId = presaleProduct.productId;
    presaleProductToSave.quantity = presaleProduct.quantity;
    presaleProductToSave.price = presaleProduct.price;

    if (presaleProduct.personalDiscount != null) {
      presaleProductToSave.personalDiscount = presaleProduct.personalDiscount;
    }

    if (presaleProduct.freeCup != null) {
      presaleProductToSave.freeCup = presaleProduct.freeCup;
    }
    if (presaleProduct.bonusPayment != null) {
      presaleProductToSave.bonusPayment = presaleProduct.bonusPayment;
    }

    if (presaleProduct.bonusAdd != null) {
      presaleProductToSave.bonusAdd = presaleProduct.bonusAdd;
    }

    if (presaleProduct.UKTZED != null && presaleProduct.UKTZED > '') {
      presaleProductToSave.UKTZED = presaleProduct.UKTZED;
    }

    if (
      presaleProduct.excisePercent != null &&
      presaleProduct.exciseAmount != null
    ) {
      presaleProductToSave.exciseLabel = presaleProduct.exciseLabel;
      presaleProductToSave.excisePercent = presaleProduct.excisePercent;
      presaleProductToSave.exciseAmount = presaleProduct.exciseAmount;
      presaleProductToSave.exciseLetter = presaleProduct.exciseLetter;
    }

    if (presaleProduct.vatPercent != null && presaleProduct.vatAmount != null) {
      presaleProductToSave.vatPercent = presaleProduct.vatPercent;
      presaleProductToSave.vatAmount = presaleProduct.vatAmount;
      presaleProductToSave.vatLetter = presaleProduct.vatLetter;
    }

    if (presaleProduct.productName != null && presaleProduct.productName > '') {
      presaleProductToSave.productName = presaleProduct.productName;
    }

    return presaleProductToSave;
  }

  private refreshReceiptPrintStatus(): void {
    this.receiptPrinterService.status().then((status) => {
      this.isPrintReceiptMode = status.isPrinterAvailable;
      this.isPrintReceiptAutoPrintMode = status.isAutoPrintAfterSale;
    });
  }
}
