import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { IConfirmDialogResult } from './confirm-dialog-result.interface';

@Component({
  selector: 'bk-confirm-dialog',
  templateUrl: './confirm.dialog.html',
  styleUrls: ['./confirm.dialog.scss'],
})
export class ConfirmDialog implements OnInit {
  @Input() title: string;
  @Input() btnConfirm: string;
  @Input() printDialog = false;
  @Input() sendDialog = false;
  @Input() subtitle = '';
  @Input() message = '';

  needPrint: boolean;
  needSendEmail: boolean;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.needPrint = this.printDialog;
    this.needSendEmail = false;
  }

  async confirmModal(): Promise<void> {
    const result: IConfirmDialogResult = {
      confirmed: true,
      print: this.needPrint,
      sendEmail: this.needSendEmail,
    };

    await this.modalCtrl.dismiss(result);
  }

  async cancelModal(): Promise<void> {
    await this.modalCtrl.dismiss({ confirmed: false });
  }
}
