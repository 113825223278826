<ion-header>
  <ion-toolbar *ngIf="!isLandscape">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/shop"></ion-back-button>
    </ion-buttons>

    <ion-title>
      {{ category?.name }}
      <span *ngIf="!emptyCategory"> / {{ subcategory?.name }}</span>
    </ion-title>

    <ion-buttons slot="primary">
      <ion-button *ngIf="selectedProducts.length > 0"
                  color="primary"
                  (click)="addProductsAndGoBack()">
        <ion-label>Вибрати</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar class="landscape-toolbar" *ngIf="isLandscape">
    <ion-buttons slot="start">
      <ion-button color="medium" fill="outline" (click)="goBackToMenu.emit()">
        <ion-label>Меню</ion-label>
      </ion-button>

      <ion-button color="medium" fill="outline" (click)="goBackToSubcategories.emit()">
        <ion-label>{{ menuCategory.name }}</ion-label>
      </ion-button>

      <ion-button color="medium" fill="outline">
        <ion-label>{{ menuSubcategory.name }}</ion-label>
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button color="medium" fill="outline" (click)="goBackToSubcategories.emit()">
        <ion-icon name="arrow-back-outline"></ion-icon>
        <ion-label>Назад</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar class="search-toolbar" *ngIf="!isLandscape || userSettings.productsAsListMode" >
    <ion-searchbar  placeholder="Пошук" 
                    [ngClass]="{ 'landscape-searchbar': isLandscape && productsAsList, 
                                 'portrait-searchbar': !isLandscape }"
                    (ionInput)="search($event)"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{ 'dark-background': isLandscape,
                          'products-as-list': userSettings.productsAsListMode }">
  <section class="products-grid" *ngIf="isLandscape && !userSettings.productsAsListMode; else portraitMode">
    <ng-controler *ngIf="productGroups?.length; else loadingLandscape">
      <ion-grid>
        <div *ngFor="let productGroup of productGroups; let index = index">
          <ion-row *ngIf="index < visibleLandscapeRows">
            <ion-col *ngFor="let product of productGroup" [size]="(12 / columnsCount).toString()">
              <bk-cover color="medium"
                        [item]="product"
                        (useProduct)="addProductAndGoBack($event)"
                        (updateProduct)="updateProduct($event)"></bk-cover>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>

      <ion-infinite-scroll threshold="100px" (ionInfinite)="loadLandscapeData($event)">
        <ion-infinite-scroll-content loadingSpinner="circular" loadingText="Завантаження даних..."></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ng-controler>

    <ng-template #loadingLandscape>
      <div class="no-data">
        <span>
          <ion-spinner color="light" *ngIf="!productGroups; else noDataLandscape"></ion-spinner>

          <ng-template #noDataLandscape>
            <ion-text color="light">
              Немає найменувань. Зверніться до менеджера чи адміністратора
            </ion-text>
          </ng-template>
        </span>
      </div>
    </ng-template>
  </section>

  <ng-template #portraitMode>
    <section *ngIf="products?.length; else loadingPortrait">
      <ion-list lines="none" class="table-data">
        <div class="even-row" *ngFor="let product of filteredProducts; let index = index">
          <ion-item-sliding #itemSliding *ngIf="index < visiblePortraitRows">
            <ion-item detail="false"
                      [button]="true" 
                      (click)="addProductAndGoBack(product, $event)"
                      (press)="selectProduct(product)">  
              <div class="table-data-row">
                <ion-checkbox *ngIf="selectedProducts.length > 0"
                              [checked]="isProductChecked(product)"></ion-checkbox>

                <span class="column-name">{{ product.name }}</span>

                <span class="column-icons">
                  <bk-icon name="barcode" color="primary" *ngIf="product.barcode != ';;'"></bk-icon>
                  <bk-icon name="coupon" color="tertiary" *ngIf="product.freeCupActive"></bk-icon>
                </span>

                <span class="column-amount">{{ product.amount }}</span>
                <span class="column-price">{{ product.price | currency:'UAH':'symbol-narrow' }}</span>
              </div>
            </ion-item>

            <ion-item-options side="end" (click)="itemSliding.close()">
              <ion-item-option color="secondary" (click)="updatePrice(product)" class="border-right" *ngIf="user.changeProductPrice">
                <ion-icon name="pricetag-outline" size="large"></ion-icon>
              </ion-item-option>

              <ion-item-option color="secondary" (click)="updateBarcode(product)">
                <ion-icon name="barcode-outline" size="large"></ion-icon>
              </ion-item-option>

              <ion-item-option color="tertiary"(click)="printLabelDialog(product)" *ngIf="isPrintLabelMode">
                <ion-icon name="ticket-outline" size="large"></ion-icon>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </div>
      </ion-list>

      <ion-infinite-scroll threshold="100px" (ionInfinite)="loadPortraitData($event)">
        <ion-infinite-scroll-content loadingSpinner="circular" loadingText="Завантаження даних..."></ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </section>

    <ng-template #loadingPortrait>
      <div class="no-data">
        <span>
          <ion-spinner [color]="isLandscape && productsAsList ? 'light' : 'dark'" *ngIf="!products; else noDataPortrait"></ion-spinner>

          <ng-template #noDataPortrait>
            <ion-text [color]="isLandscape && productsAsList ? 'light' : 'dark'">
              Немає найменувань. Зверніться до менеджера чи адміністратора
            </ion-text>
          </ng-template>
        </span>
      </div>
    </ng-template>
  </ng-template>
</ion-content>
