import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonInput, ModalController } from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import round from 'lodash-es/round';

import { MIN_QUANTITY_VALUE } from '../../core/constants/form-validations.const';
import { SALE_RETURN_DIALOG_ID } from '../../core/constants/modal-controller.const';
import { LoadingService } from '../../core/services/loading.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { PayFormCode } from '../../p-rro/fsco/enums/pay-form-code.enum';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../../settings/settings.const';
import { ReturnService } from '../return.service';
import { SaleProduct } from '../sale/sale-product.model';
import { Sale } from '../sale/sale.model';
import { SalesService } from '../sales.service';

const LOADING_ID = 'sale-return.dialog';

@Component({
  selector: 'bk-sale-return-dialog',
  templateUrl: './sale-return.dialog.html',
  styleUrls: ['./sale-return.dialog.scss'],
})
export class SaleReturnDialog implements OnInit {
  @ViewChild(IonInput) input: IonInput;

  @Input() sale: Sale;

  returnProducts: SaleProduct[] = [];

  totalDebt = 0;
  totalCash = 0;
  totalCard = 0;

  isInDebt = false;

  selectAllChecked = false;
  selectAllIndeterminate = false;

  requestInProgress = false;

  MIN_QUANTITY_VALUE = MIN_QUANTITY_VALUE;

  get totalSum(): number {
    let sum;

    try {
      sum = this.returnProducts
        .filter((rp) => rp.selected)
        .reduce(
          (result, returnProduct) =>
            result +
            round(
              round(returnProduct.price, 2) * round(returnProduct.quantity, 3),
              2,
            ),
          0,
        );
    } catch (e) {
      sum = 0;
    }

    return sum;
  }

  get totalCashMax(): number {
    return (
      round(
        Math.max(
          this.totalSum,
          this.totalSum + this.utilsService.getRoundSum(this.totalSum),
        ),
        2,
      ) + Number.EPSILON
    );
  }

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private salesService: SalesService,
    private returnService: ReturnService,
    private utilsService: UtilsService,
    private toastService: ToastService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.isInDebt = Boolean(
      this.sale.salePayments.find(
        (salePayment) => salePayment.method === PayFormCode.InDebt,
      ),
    );

    this.sale.saleProducts.forEach((saleProduct) => {
      const returnProduct = cloneDeep(saleProduct);

      if (returnProduct.product.weightProduct) {
        const weightCoefficient = Math.pow(
          10,
          Number(returnProduct.product.weightProduct),
        );

        returnProduct.quantity = Math.abs(
          round(saleProduct.quantity / weightCoefficient, 3),
        );

        returnProduct.price = Math.abs(
          round(saleProduct.price * weightCoefficient, 2),
        );

        returnProduct.fullPrice = Math.abs(
          round(saleProduct.fullPrice * weightCoefficient, 2),
        );
      }

      returnProduct.soldQuantity = returnProduct.quantity;
      returnProduct.exciseLabels = returnProduct.exciseLabel?.split(';') ?? [];

      this.returnProducts.push(returnProduct);
    });
  }

  selectProduct(returnProduct: SaleProduct, index: number): void {
    this.updateSelectAllStatus();
    this.updatePaymentsTotals();

    if (!returnProduct.selected) {
      returnProduct.quantity =
        returnProduct.soldQuantity ?? returnProduct.quantity;

      returnProduct.exciseLabels = returnProduct.exciseLabel?.split(';') ?? [];

      return;
    }

    if (!returnProduct.product.weightProduct) {
      return;
    }

    setTimeout(() => {
      this.selectAllInputTextByIndex(index);
    }, 100);
  }

  selectAllInputText(input: IonInput | HTMLInputElement): void {
    if (input instanceof HTMLInputElement) {
      input.select();
    } else {
      input.getInputElement().then((data) => {
        data.select();
      });
    }
  }

  selectAllChanged(): void {
    setTimeout(() => {
      let firstWeightProductIndex = -1;

      this.returnProducts.forEach((returnProduct, index) => {
        returnProduct.selected = this.selectAllChecked;

        if (
          returnProduct.product.weightProduct &&
          firstWeightProductIndex < 0
        ) {
          firstWeightProductIndex = index;
        }
      });

      setTimeout(() => {
        if (firstWeightProductIndex >= 0) {
          this.selectAllInputTextByIndex(firstWeightProductIndex);
        }
      }, 200);
    }, 100);
  }

  incrementQuantity(returnProduct: SaleProduct): void {
    if (returnProduct.soldQuantity == null) {
      return;
    }

    if (returnProduct.quantity < returnProduct.soldQuantity) {
      returnProduct.quantity += 1;
    }

    this.calcSaleProductCost(returnProduct);
  }

  decrementQuantity(returnProduct: SaleProduct): void {
    if (returnProduct.soldQuantity == null) {
      return;
    }

    if (returnProduct.quantity === 1) {
      returnProduct.quantity = returnProduct.soldQuantity;
      returnProduct.selected = false;

      this.updateSelectAllStatus();
    } else {
      returnProduct.quantity -= 1;
    }

    this.calcSaleProductCost(returnProduct);
  }

  async inputQuantity(returnProduct: SaleProduct): Promise<void> {
    if (returnProduct.soldQuantity == null) {
      return;
    }

    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    const inputFieldId = 'inputField';

    const alert = await this.alertCtrl.create({
      header: 'Кількість',
      message: `Введіть ціле число`,
      inputs: [
        {
          id: inputFieldId,
          name: 'saleQuantity',
          placeholder: `Кількість`,
          type: 'number',
          value: returnProduct.quantity,
          min: 1,
          max: returnProduct.soldQuantity,
        },
      ],
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Підтвердити',
          role: 'confirm',
          cssClass: 'primary',
          handler: (data: { saleQuantity: string }) => {
            this.updateSaleProductQuantity(returnProduct, data.saleQuantity);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    alert.addEventListener('ionAlertDidPresent', (e) => {
      const inputField = document.getElementById(inputFieldId);

      if (!inputField) {
        return;
      }

      setTimeout(() => {
        inputField.focus();

        if (inputField instanceof HTMLInputElement) {
          inputField.select();
        }
      }, 100);

      inputField.onkeyup = async (event) => {
        if (event.key !== 'Enter') {
          return;
        }

        if (inputField instanceof HTMLInputElement) {
          this.updateSaleProductQuantity(returnProduct, inputField.value);

          await alert.dismiss();
        }
      };
    });

    await alert.present();
  }

  calcSaleProductCost(returnProduct: SaleProduct): void {
    returnProduct.cost = this.utilsService.cost(
      returnProduct.quantity,
      returnProduct.price,
    );

    returnProduct.fullCost = this.utilsService.cost(
      returnProduct.quantity,
      returnProduct.fullPrice,
    );

    this.updatePaymentsTotals();
  }

  deleteExciseLabel(returnProduct: SaleProduct, exciseLabel: string): void {
    returnProduct.exciseLabels = returnProduct.exciseLabels?.filter(
      (el) => el !== exciseLabel,
    );
  }

  cashPaymentFocus(input: IonInput): void {
    if (this.totalCash === 0 && this.totalCard > 0) {
      this.totalCash = round(
        this.totalSum + this.utilsService.getRoundSum(this.totalSum),
        2,
      );

      this.totalCard = 0;
    }

    setTimeout(() => {
      this.selectAllInputText(input);
    }, 100);
  }

  cardPaymentFocus(input: IonInput): void {
    if (this.totalCard === 0 && this.totalCash > 0) {
      this.totalCash = 0;
      this.totalCard = round(this.totalSum, 2);
    }

    setTimeout(() => {
      this.selectAllInputText(input);
    }, 100);
  }

  cashPaymentChanged(value: string): void {
    this.totalCash = Math.min(this.totalSum, Number(this.convertDot(value)));

    this.totalCard = round(this.totalSum - this.totalCash, 2);

    const totalRound = this.utilsService.getRoundSum(this.totalCash);

    this.totalCash = round(this.totalCash + totalRound, 2);
  }

  cardPaymentChanged(value: string): void {
    this.totalCard = Math.min(this.totalSum, Number(this.convertDot(value)));

    this.totalCash = round(this.totalSum - this.totalCard, 2);
    this.totalCash = round(
      this.totalCash + this.utilsService.getRoundSum(this.totalCash),
      2,
    );
  }

  async return(): Promise<void> {
    let wrongData = false;

    this.returnProducts
      .filter((returnProduct) => returnProduct.selected)
      .forEach((returnProduct) => {
        if (
          returnProduct.exciseLetter != null &&
          returnProduct.quantity < (returnProduct.exciseLabels?.length ?? 0)
        ) {
          this.toastService.presentError(
            `Акцизний товар`,
            `Кількість "${returnProduct.product.name}" менша за кількість акцизних марок\nВидаліть акцизні марки найменувань, які не повертають`,
          );

          wrongData = true;
        }
      });

    if (this.requestInProgress || wrongData) {
      return;
    }

    this.requestInProgress = true;

    const cardPayment = this.sale.salePayments.find(
      (sp) => sp.method === PayFormCode.Card,
    );

    const terminalData =
      this.totalCard > 0
        ? await this.salesService.cashlessPaymentDialog(
            -1 * this.totalCard,
            cardPayment?.transactionId ?? cardPayment?.acquirerTransactionId,
          )
        : undefined;

    if (this.totalCard > 0 && terminalData == null) {
      this.requestInProgress = false;

      return;
    }

    await this.loadingService.presentCustomPreloader(LOADING_ID);

    this.returnService
      .create(
        this.sale,
        terminalData,
        { cash: this.totalCash, card: this.totalCard, debt: this.totalDebt },
        this.returnProducts.filter((returnProduct) => returnProduct.selected),
      )
      .then(async (returnSale) => {
        if (returnSale == null) {
          return;
        }

        await this.modalCtrl.dismiss({ returnSale }, '', SALE_RETURN_DIALOG_ID);
      })
      .finally(async () => {
        await this.loadingService.dismiss(LOADING_ID);

        this.requestInProgress = false;
      });
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ returnSale: null });
  }

  private selectAllInputTextByIndex(index: number): void {
    const quantityInput = document.getElementById(`quantity_${index}`);
    if (quantityInput) {
      this.selectAllInputText(quantityInput as HTMLInputElement);
    }
  }

  private convertDot(value: string): number {
    return Number(Number(value.split(',').join('.')).toFixed(2));
  }

  private updateSelectAllStatus(): void {
    const selectedCount = this.returnProducts.filter(
      (rp) => rp.selected,
    ).length;

    if (selectedCount > 0 && selectedCount < this.returnProducts.length) {
      this.selectAllIndeterminate = true;
      this.selectAllChecked = false;
    } else if (selectedCount === this.returnProducts.length) {
      this.selectAllIndeterminate = false;
      this.selectAllChecked = true;
    } else {
      this.selectAllIndeterminate = false;
      this.selectAllChecked = false;
    }
  }

  private updatePaymentsTotals(): void {
    if (this.isInDebt) {
      this.totalDebt = this.totalSum;
      this.totalCash = 0;
      this.totalCard = 0;
    } else {
      this.totalDebt = 0;

      if (this.sale.cashless) {
        this.totalCash = 0;
        this.totalCard = this.totalSum;
      } else {
        this.totalCash = round(
          this.totalSum + this.utilsService.getRoundSum(this.totalSum),
          2,
        );

        this.totalCard = 0;
      }
    }
  }

  private updateSaleProductQuantity(
    returnProduct: SaleProduct,
    value: string,
  ): void {
    if (!value) {
      return;
    }

    const quantity = Math.min(
      Math.round(Math.abs(Number(value))),
      returnProduct.soldQuantity ?? returnProduct.quantity,
    );

    returnProduct.quantity = Math.max(1, quantity);

    this.calcSaleProductCost(returnProduct);
  }
}
