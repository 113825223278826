import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { Platform, ToastButton, ToastController } from '@ionic/angular';

import {
  APP_VERSION_WEB_PREFIX,
  DEFAULT_TOAST_DELAY,
  ERROR_TOAST_DELAY,
  WARNING_TOAST_DELAY,
} from '../../settings/settings.const';
import {
  NEED_PWA_UPDATE,
  NEED_UPDATE,
  NEED_UPDATE_TITLE,
  NO_INTERNET_CONNECTION,
  NO_INTERNET_TITLE,
} from '../constants/error-messages.const';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private router: Router,
    private market: Market,
    private platform: Platform,
    private toastCtrl: ToastController,
  ) {}

  async present(
    title: string,
    text?: string,
    delay?: number,
    style: string = 'default-toast',
    navigateByUrl?: string,
  ): Promise<void> {
    const buttons: ToastButton[] = [];

    if (navigateByUrl) {
      buttons.push({
        icon: navigateByUrl.includes('settings')
          ? 'settings-outline'
          : 'arrow-forward-outline',
        handler: async () => {
          this.router.navigateByUrl(`/${navigateByUrl}`);
        },
      });
    }

    buttons.push({
      icon: 'close',
      role: 'cancel',
    });

    const toast = await this.toastCtrl.create({
      buttons,
      header: title,
      message: text ? text : '',
      duration: delay ? delay : DEFAULT_TOAST_DELAY,
      cssClass: style === 'top-toast' ? 'default-toast' : style,
      position:
        style === 'error-toast' || style === 'top-toast' ? 'top' : 'bottom',
    });

    toast.present();
  }

  async presentWarning(
    title: string,
    text?: string,
    delay?: number,
    navigateByUrl?: string,
  ): Promise<void> {
    this.present(
      `Попередження: ${title}`,
      text,
      delay ?? WARNING_TOAST_DELAY,
      'warning-toast',
      navigateByUrl,
    );
  }

  async presentError(
    title: string,
    text?: string,
    delay?: number,
  ): Promise<void> {
    this.present(
      `Помилка: ${title}`,
      text,
      delay ?? ERROR_TOAST_DELAY,
      'error-toast',
    );
  }

  async presentForUpdate(packageName: string): Promise<void> {
    const isWeb = packageName.includes(`${APP_VERSION_WEB_PREFIX}`);

    const toast = await this.toastCtrl.create({
      header: NEED_UPDATE_TITLE,
      message: isWeb ? NEED_PWA_UPDATE : NEED_UPDATE,
      duration: 3500,
      buttons: [
        {
          icon: isWeb
            ? 'refresh'
            : this.platform.is('ios')
            ? 'logo-apple-appstore'
            : 'logo-google-playstore',
          text: isWeb ? 'Оновити' : 'Перевірити',
          handler: () => {
            isWeb ? window.location.reload() : this.market.open(packageName);
          },
        },
      ],
      cssClass: 'update-toast',
      position: 'top',
    });

    toast.present();
  }

  presentNoInternet(): void {
    this.presentWarning(NO_INTERNET_TITLE, NO_INTERNET_CONNECTION);
  }

  presentNoBarcode(barcode: string): void {
    this.presentWarning(
      'Невідомий штрихкод',
      `Товару зі штрихкодом "${barcode}" немає у базі даних`,
    );
  }
}
