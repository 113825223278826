import { Injectable } from '@angular/core';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Platform } from '@ionic/angular';

const COVERS_DIRECTORY = 'covers';

@Injectable({
  providedIn: 'root',
})
export class CachedImageService {
  constructor(private file: File, private platform: Platform) {}

  createCoverDirectory(): void {
    if (!this.platform.is('cordova')) {
      return;
    }

    this.file
      .checkDir(this.file.dataDirectory, COVERS_DIRECTORY)
      .then(() => {
        this.file
          .resolveDirectoryUrl(`${this.file.dataDirectory}${COVERS_DIRECTORY}`)
          .then();
      })
      .catch(() => {
        this.file
          .createDir(this.file.dataDirectory, COVERS_DIRECTORY, true)
          .then();
      });
  }

  async loadCover(imageUrl: string): Promise<void> {
    if (!this.platform.is('cordova')) {
      return;
    }

    const imagePath = `${this.file.dataDirectory}${COVERS_DIRECTORY}/`;
    const imageName = imageUrl.split('/').pop() ?? 'cover.png';

    await this.storeImage(imageUrl, imagePath, imageName);
  }

  readCoverAsFilePath(imageUrl: string): string {
    if (this.platform.is('cordova')) {
      const imagePath = `${this.file.dataDirectory}${COVERS_DIRECTORY}/`;
      const imageName = imageUrl.split('/').pop() ?? 'cover.png';

      this.file
        .checkFile(imagePath, imageName)
        .then(async (fileExist) => {
          if (fileExist) {
            return this.getImagePath(imageUrl, imagePath, imageName, false);
          }

          return this.getImagePath(imageUrl, imagePath, imageName);
        })
        .catch(async (error) => {
          return this.getImagePath(imageUrl, imagePath, imageName);
        });
    }

    return imageUrl;
  }

  readCoverAsDataURL(imageUrl: string): string {
    if (this.platform.is('cordova')) {
      const imagePath = `${this.file.dataDirectory}${COVERS_DIRECTORY}/`;
      const imageName = imageUrl.split('/').pop() ?? 'cover.png';

      this.file
        .readAsDataURL(imagePath, imageName)
        .then((readFile) => {
          return readFile;
        })
        .catch(async () => {
          await this.storeImage(imageUrl, imagePath, imageName);

          this.file.readAsDataURL(imagePath, imageName).then((readFile) => {
            if (readFile) {
              return readFile;
            }

            return imageUrl;
          });
        });
    }

    return imageUrl;
  }

  private async storeImage(
    imageUrl: string,
    imagePath: string,
    imageName: string,
  ): Promise<any> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return await this.file.writeFile(imagePath, imageName, blob, {
      replace: true,
      append: false,
    });
  }

  private async getImagePath(
    imageUrl: string,
    imagePath: string,
    imageName: string,
    needCreate: boolean = true,
  ): Promise<string> {
    if (needCreate) {
      await this.storeImage(imageUrl, imagePath, imageName);
    }

    return `${imagePath}${imageName}`.replace(
      'file:///',
      'http://localhost/_app_file_/',
    );
  }
}
