export enum ScalesModel {
  None = '',
  CAS_DLL = 'CAS_DLL',
  CAS_ER_JR = 'CAS_ER_JR',
  CAS_PR_II = 'CAS_PR_II',
  CAS_SW_II = 'CAS_SW_II',
  CAS_PB = 'CAS_PB',
  ICS_NT_DLL = 'ICS_NT_DLL',
  PROM_PRYLAD_BTA_60_7 = 'PROM_PRYLAD_BTA_60_7',
}
