import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Platform } from '@ionic/angular';

import { Resource } from '../core/abstract/resource';
import { Config } from '../core/constants/version.const';
import { IAppVersions } from '../core/interfaces/app-versions.interface';
import { Setting } from '../core/models/setting.model';
import { UserSession } from '../core/models/user-session.model';
import { AuthService } from '../core/services/auth.service';
import { CachedDataService } from '../core/services/cached-data.service';
import { ToastService } from '../core/services/toast.service';

import {
  APP_VERSION,
  APP_VERSION_ANDROID,
  APP_VERSION_IOS,
  APP_VERSION_WEB,
  APP_VERSION_WEB_PREFIX,
  CASHBOX_URL_NAME,
  CORS_PROXY_URL_NAME,
  DEFAULT_CASHBOX_URL,
  DEFAULT_CORS_PROXY_URL,
  DEFAULT_DPS_PRRO_API_HTTP_URL,
  DEFAULT_DPS_PRRO_API_HTTPS_URL,
  DEFAULT_DPS_PRRO_CHECK_URL,
  DEFAULT_DPS_PRRO_URL,
  DEFAULT_MAX_SALE_AMOUNT,
  DEFAULT_SUPPORT_EMAIL,
  DPS_PRRO_API_HTTP_URL_NAME,
  DPS_PRRO_API_HTTPS_URL_NAME,
  DPS_PRRO_CHECK_URL_NAME,
  DPS_PRRO_URL_NAME,
  MAX_SALE_AMOUNT_NAME,
  SUPPORT_EMAIL_NAME,
} from './settings.const';

@Injectable({
  providedIn: 'root',
})
export class SettingService extends Resource<Setting> {
  private appVersionNumber: string;
  private appPackageName: string;

  constructor(
    protected http: HttpClient,
    private toastService: ToastService,
    private platform: Platform,
    private device: Device,
    private appVersion: AppVersion,
    private authService: AuthService,
    private cachedDataService: CachedDataService,
  ) {
    super(http, {
      path: '/settings',
    });

    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        this.appVersion.getVersionNumber().then((versionNumber) => {
          this.appVersionNumber = versionNumber;
        });

        this.appVersion.getPackageName().then((packageName) => {
          this.appPackageName = packageName;
        });
      });
    } else {
      this.appVersionNumber = Config.VERSION;
      this.appPackageName = `${APP_VERSION_WEB_PREFIX}:${Config.VERSION}`;
    }
  }

  getPackageName(): string {
    return this.appPackageName;
  }

  getSessionInfo(shopId: number): UserSession {
    let osInfo = 'WebOS';
    let deviceInfo = 'WebDevice';

    const platformInfo = `${this.platform.width()}x${this.platform.height()} (${this.platform.platforms()})`;

    if (this.platform.is('cordova')) {
      osInfo = `${this.device.platform} ${this.device.version}`;
      deviceInfo = `${this.device.manufacturer} ${this.device.model}, ${platformInfo}`;
    } else {
      deviceInfo = `${deviceInfo}, ${platformInfo}`;
    }

    const userSession = new UserSession();

    userSession.userId = this.authService.getUserId();
    userSession.shopId = shopId;
    userSession.osInfo = osInfo;
    userSession.deviceInfo = deviceInfo;
    userSession.appVersion = this.getVersionInfo();

    return userSession;
  }

  getVersionInfo(): string {
    return this.platform.is('cordova')
      ? this.appVersionNumber
      : `${APP_VERSION_WEB_PREFIX}:${Config.VERSION}`;
  }

  async getVersion(): Promise<{
    isCorrect: boolean;
    recommendUpdate: boolean;
    packageName: string;
  }> {
    const versions = await this.loadSettingsAndVersions();
    const checkVersion = this.compareVersions(this.appVersionNumber, versions);

    this.cachedDataService.setMainDpsPRroApiUrl();

    return {
      isCorrect: !checkVersion.needUpdate,
      recommendUpdate: checkVersion.recommendUpdate,
      packageName: this.appPackageName,
    };
  }

  async checkApiVersion(): Promise<void> {
    const version = await this.getVersion();

    if (!version.isCorrect || version.recommendUpdate) {
      this.toastService.presentForUpdate(this.appPackageName);
    }

    if (!version.isCorrect) {
      setTimeout(() => {
        this.authService.removeToken();
      }, 3500);
    }
  }

  private async loadSettingsAndVersions(): Promise<IAppVersions> {
    const settings = await super.find().toPromise();

    this.cachedDataService.setAppSettings({
      supportEmail: this.setSetting(
        settings,
        SUPPORT_EMAIL_NAME,
        DEFAULT_SUPPORT_EMAIL,
      ),
      url: this.setSetting(settings, CASHBOX_URL_NAME, DEFAULT_CASHBOX_URL),
      corsProxyUrl: this.setSetting(
        settings,
        CORS_PROXY_URL_NAME,
        DEFAULT_CORS_PROXY_URL,
      ),
      dpsPRroUrl: this.setSetting(
        settings,
        DPS_PRRO_URL_NAME,
        DEFAULT_DPS_PRRO_URL,
      ),
      dpsPRroApiHttpsUrl: this.setSetting(
        settings,
        DPS_PRRO_API_HTTPS_URL_NAME,
        DEFAULT_DPS_PRRO_API_HTTPS_URL,
      ),
      dpsPRroApiHttpUrl: this.setSetting(
        settings,
        DPS_PRRO_API_HTTP_URL_NAME,
        DEFAULT_DPS_PRRO_API_HTTP_URL,
      ),
      dpsPRroCheckUrl: this.setSetting(
        settings,
        DPS_PRRO_CHECK_URL_NAME,
        DEFAULT_DPS_PRRO_CHECK_URL,
      ),
      maxSaleAmount: this.setSetting(
        settings,
        MAX_SALE_AMOUNT_NAME,
        DEFAULT_MAX_SALE_AMOUNT.toString(),
      ),
    });

    const versionParamName = this.getVersionParamName();
    const minVersion = this.setSetting(settings, APP_VERSION, '0.0.0');
    const recommendedVersion = this.setSetting(
      settings,
      versionParamName,
      minVersion,
    );

    return { minVersion, recommendedVersion };
  }

  private setSetting(
    settings: Setting[],
    name: string,
    defaultValue: string,
  ): string {
    return (
      settings.find((setting) => setting.type === name)?.version ?? defaultValue
    );
  }

  private getVersionParamName(): string {
    return this.platform.is('cordova') && this.platform.is('android')
      ? APP_VERSION_ANDROID
      : this.platform.is('cordova') && this.platform.is('ios')
      ? APP_VERSION_IOS
      : APP_VERSION_WEB;
  }

  private compareVersions(
    appVersion: string,
    dbVersion: IAppVersions,
  ): { needUpdate: boolean; recommendUpdate: boolean } {
    const appVersionArray: number[] = this.convertVersion(appVersion);

    const dbMinVersionArray: number[] = this.convertVersion(
      dbVersion.minVersion,
    );

    const dbRecommendedVersionArray: number[] = this.convertVersion(
      dbVersion.recommendedVersion,
    );

    let needUpdate: boolean | undefined;

    appVersionArray.forEach((appVersionItem, index) => {
      if (needUpdate === undefined) {
        if (appVersionItem > dbMinVersionArray[index]) {
          needUpdate = false;
        } else if (appVersionItem < dbMinVersionArray[index]) {
          needUpdate = true;
        }
      }
    });

    let recommendUpdate: boolean | undefined;

    appVersionArray.forEach((appVersionItem, index) => {
      if (recommendUpdate === undefined) {
        if (appVersionItem > dbRecommendedVersionArray[index]) {
          recommendUpdate = false;
        } else if (appVersionItem < dbRecommendedVersionArray[index]) {
          recommendUpdate = true;
        }
      }
    });

    return {
      needUpdate: needUpdate ?? false,
      recommendUpdate: recommendUpdate ?? false,
    };
  }

  private convertVersion(version: string): number[] {
    if (!version) {
      return [];
    }

    const temp = version.split('.');
    const first = temp[0];
    const second = temp[1];
    const third = temp[2];

    return [+first, +second, +third];
  }
}
