import { PRro } from '../../p-rro/p-rro.model';
import { ShopPRroMode } from '../enum/shop-p-rro-mode.enum';

import { ShopShift } from './shop-shift.model';

export class Shop {
  id: number;
  companyId: number;
  name: string;
  fop: string;
  streetAddress: string;
  ipn: string;
  logoUrl: string;
  checkLogoPath: string;
  checkComment: string;
  printCheck: boolean;
  printerIP: string;
  scalePort: string;
  tapxphoneDeviceId: string;
  clientScreen: boolean;
  invoiceCount: number;
  backInSalePrice: boolean;
  dailyRevision: boolean;
  presales: boolean;
  wholesalePrice: boolean;
  bonusPercent: number;
  freeCupNumber: number;
  freeCupPercent: number;
  prroCompanyName: string;
  prroEDRPOU: string;
  prroIPN: string;
  prroShopName: string;
  prroShopAddress: string;
  prroManualControl: boolean;
  prroMode: ShopPRroMode;

  shift: ShopShift | null;
  prros: PRro[];
}
