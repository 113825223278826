<ion-header>
  <ion-toolbar>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="default-product" *ngIf="docs == null && docItem == null; else mainSection">
    <ion-toolbar class="search-toolbar">
      <ion-searchbar  id="search"
                      name="search"
                      placeholder="Пошук"
                      [(ngModel)]="search"
                      (ionChange)="filterList()"></ion-searchbar>
    </ion-toolbar>

    <ion-content>
      <ion-list lines="none" class="table-data" *ngIf="filteredProducts.length > 0 && filteredProducts.length < PRODUCTS_ROWS; else noData">
        <div class="even-row" *ngFor="let product of filteredProducts; let index = index">
          <ion-item detail="false"
                    [button]="true" 
                    (click)="confirm(product, $event)">  
            <div class="table-data-row">
              {{ product.name }}, {{ product.amount }} - {{ product.price | currency:'UAH':'symbol-narrow' }}
            </div>
          </ion-item>
        </div>
      </ion-list>

      <ng-template #noData>
        <div class="no-data">
          <span>
            {{ filteredProducts.length }} найменувань<br>
            {{ filteredProducts.length > PRODUCTS_ROWS ? 'Скористайтесь пошуком' : '' }}
          </span>
        </div>
      </ng-template>
    </ion-content>
  </section>

  <ng-template #mainSection>
    <section class="data">
      <h2 class="data__caption">{{ caption }}</h2> 

      <div class="data__list">
        <ion-list lines="none" class="table-data">
          <div class="even-row" *ngFor="let item of items">
            <ion-item (click)="confirm(item)">
              <div class="item" *ngIf="docs">
                <span class="item__name">{{ item.comment }}, {{ item.cashier }}</span>
                <span class="item__amount">{{ item.amount | currency:'UAH':'symbol-narrow' }}</span>
              </div>

              <div class="item" *ngIf="docItem">
                <span class="item__name">{{ item.name }}</span>
                <span class="item__amount">{{ item.price | currency:'UAH':'symbol-narrow' }}</span>
              </div>
            </ion-item>
          </div>
        </ion-list>
      </div>
    </section>
  </ng-template>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="one-button" color="tertiary" (click)="cancel()">Скасувати</ion-button>
  </ion-toolbar>
</ion-footer>
