export enum PrivatbankJsonServiceMessageTypeResponse {
  deviceBusy = 'deviceBusy',
  debugOn = 'debugOn',
  debugOff = 'debugOff',
  identify = 'identify',
  interruptTransmitted = 'interruptTransmitted',
  methodNotImplemented = 'methodNotImplemented',
  getMerchantList = 'getMerchantList',
  getLastStatMsgCode = 'getLastStatMsgCode',
  getLastStatMsgDescription = 'getLastStatMsgDescription',
}
