import { Injectable } from '@angular/core';

import { StorageTable } from '../../../../core/models/storage-table.model';
import { PrinterConnectionMethod } from '../../printer-connection-method.enum';
import { Printer } from '../../printer.model';
import { ReceiptPrinterSettings } from '../receipt-printer-settings.interface';
import {
  DEFAULT_AUTO_PRINT_AFTER_SALE,
  DEFAULT_CHARSET_ID,
  DEFAULT_CHARSET_NAME,
  DEFAULT_LINES_TO_CUT,
  DEFAULT_MODEL,
  DEFAULT_PAPER_WIDTH,
  DEFAULT_PRINT_SHOP_LOGO_IN_RECEIPT,
  DEFAULT_ROW_LENGTH,
  DEFAULT_TURN_OFF_CHINESE,
  DEFAULT_USE_CASH_DRAWER,
} from '../receipt-printer.const';

const KEY_CONNECTION_METHOD = 'connectionMethod';
const KEY_NAME = 'name';
const KEY_IP = 'ip';
const KEY_MAC = 'mac';
const KEY_ADDRESS = 'address';
const KEY_PAPER_WIDTH = 'paperWidth';
const KEY_ROW_LENGTH = 'rowLength';
const KEY_LINES_TO_CUT = 'linesToCut';
const KEY_PRINT_SHOP_LOGO_IN_RECEIPT = 'printShopLogoInReceipt';
const KEY_MODEL = 'model';
const KEY_CHARSET_NAME = 'charsetName';
const KEY_CHARSET_ID = 'charsetId';
const KEY_TURN_OFF_CHINESE = 'turnOffChinese';
const KEY_AUTO_PRINT_AFTER_SALE = 'autoPrintAfterSale';
const KEY_USE_CASH_DRAWER = 'useCashDrawer';

@Injectable({
  providedIn: 'root',
})
export class ReceiptPrinterStorageService {
  private printer: StorageTable;
  private deprecatedPrinter: StorageTable;

  constructor() {
    this.printer = new StorageTable('receipt-printer');
    this.deprecatedPrinter = new StorageTable('printer');
  }

  async clearData(): Promise<void> {
    await this.printer.clear();
  }

  private async getValue<T>(key: string, defaultValue: T): Promise<T> {
    const value = await this.printer.get<T>(key);

    if (value == null) {
      // TODO: Delete after updater up 5.9.95
      const deprecatedValue = await this.getDeprecatedValue<T>(key);

      if (deprecatedValue != null) {
        return deprecatedValue;
      }

      await this.printer.set<T>(key, defaultValue);

      return defaultValue;
    }

    return value;
  }

  private async getDeprecatedValue<T>(key: string): Promise<T | null> {
    if (key === KEY_IP || key === KEY_MAC) {
      const connectionMethod =
        await this.deprecatedPrinter.get<PrinterConnectionMethod>(
          KEY_CONNECTION_METHOD,
        );

      if (
        (connectionMethod === PrinterConnectionMethod.WiFi && key === KEY_IP) ||
        (connectionMethod === PrinterConnectionMethod.Bluetooth &&
          key === KEY_MAC)
      ) {
        const specialDeprecatedValue = await this.deprecatedPrinter.get<T>(
          KEY_ADDRESS,
        );

        if (specialDeprecatedValue != null) {
          await this.printer.set<T>(key, specialDeprecatedValue);

          return specialDeprecatedValue;
        }
      }
    }

    const deprecatedValue = await this.deprecatedPrinter.get<T>(key);

    if (deprecatedValue != null) {
      await this.printer.set<T>(key, deprecatedValue);

      return deprecatedValue;
    }

    return null;
  }

  async getConnectionMethod(): Promise<PrinterConnectionMethod> {
    return this.getValue(
      KEY_CONNECTION_METHOD,
      PrinterConnectionMethod.Browser,
    );
  }

  async getSettings(): Promise<ReceiptPrinterSettings> {
    const connectionMethod = await this.getConnectionMethod();
    const printerName = await this.getValue(KEY_NAME, '');
    const ip = await this.getValue(KEY_IP, '');
    const mac = await this.getValue(KEY_MAC, '');

    const paperWidth = await this.getValue(
      KEY_PAPER_WIDTH,
      DEFAULT_PAPER_WIDTH,
    );

    const rowLength = await this.getValue(KEY_ROW_LENGTH, DEFAULT_ROW_LENGTH);
    const linesToCut = await this.getValue(
      KEY_LINES_TO_CUT,
      DEFAULT_LINES_TO_CUT,
    );

    const printShopLogoInReceipt = await this.getValue(
      KEY_PRINT_SHOP_LOGO_IN_RECEIPT,
      DEFAULT_PRINT_SHOP_LOGO_IN_RECEIPT,
    );

    const autoPrintAfterSale = await this.getValue(
      KEY_AUTO_PRINT_AFTER_SALE,
      DEFAULT_AUTO_PRINT_AFTER_SALE,
    );

    const useCashDrawer = await this.getValue(
      KEY_USE_CASH_DRAWER,
      DEFAULT_USE_CASH_DRAWER,
    );

    const model = await this.getValue(KEY_MODEL, DEFAULT_MODEL);
    const charsetName = await this.getValue(
      KEY_CHARSET_NAME,
      DEFAULT_CHARSET_NAME,
    );

    const charsetId = await this.getValue(KEY_CHARSET_ID, DEFAULT_CHARSET_ID);
    const turnOffChinese = await this.getValue(
      KEY_TURN_OFF_CHINESE,
      DEFAULT_TURN_OFF_CHINESE,
    );

    const printer = new Printer(printerName, '');

    return {
      connectionMethod,
      printer,
      ip,
      mac,
      paperWidth,
      rowLength,
      linesToCut,
      printShopLogoInReceipt,
      autoPrintAfterSale,
      useCashDrawer,
      model,
      charsetName,
      charsetId,
      turnOffChinese,
    };
  }

  async setSettings(data: ReceiptPrinterSettings): Promise<void> {
    await this.printer.set(KEY_CONNECTION_METHOD, data.connectionMethod);
    await this.printer.set(KEY_NAME, data.printer.name);
    await this.printer.set(KEY_IP, data.ip);
    await this.printer.set(KEY_MAC, data.mac);
    await this.printer.set(KEY_PAPER_WIDTH, data.paperWidth);
    await this.printer.set(KEY_ROW_LENGTH, data.rowLength);
    await this.printer.set(KEY_LINES_TO_CUT, data.linesToCut);
    await this.printer.set(
      KEY_PRINT_SHOP_LOGO_IN_RECEIPT,
      data.printShopLogoInReceipt,
    );

    await this.printer.set(KEY_AUTO_PRINT_AFTER_SALE, data.autoPrintAfterSale);
    await this.printer.set(KEY_USE_CASH_DRAWER, data.useCashDrawer);
    await this.printer.set(KEY_MODEL, data.model);
    await this.printer.set(KEY_CHARSET_NAME, data.charsetName);
    await this.printer.set(KEY_CHARSET_ID, data.charsetId);
    await this.printer.set(KEY_TURN_OFF_CHINESE, data.turnOffChinese);
  }
}
