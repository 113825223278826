import { Injectable } from '@angular/core';

import { PrinterConnectionMethod } from '../../printer-connection-method.enum';
import { QzTrayService } from '../../qz-tray/qz-tray.service';
import { ReceiptPrinterData } from '../receipt-printer-data.model';
import { PRINTER_CLASS, PRINTER_URL } from '../receipt-printer.const';

import { ReceiptPrinterThermalPrinterService } from './receipt-printer-thermal-printer.service';

@Injectable({
  providedIn: 'root',
})
export class ReceiptPrinterPrintService {
  constructor(
    private qzTrayService: QzTrayService,
    private receiptPrinterThermalPrinterService: ReceiptPrinterThermalPrinterService,
  ) {}

  async print(data: ReceiptPrinterData): Promise<void> {
    switch (data.settings.connectionMethod) {
      case PrinterConnectionMethod.QZTray:
        const printerExist = await this.qzTrayService.printerExist(
          data.settings.printer.name,
          { url: PRINTER_URL, printerClass: PRINTER_CLASS },
        );

        if (!printerExist) {
          return;
        }

        await this.qzTrayService.printReceipt(data);
        break;

      case PrinterConnectionMethod.Bluetooth:
      case PrinterConnectionMethod.USB:
      case PrinterConnectionMethod.WiFi:
        this.receiptPrinterThermalPrinterService.print(data);
        break;
    }
  }

  async openCashDrawer(data: ReceiptPrinterData): Promise<void> {
    switch (data.settings.connectionMethod) {
      case PrinterConnectionMethod.QZTray:
        const printerExist = await this.qzTrayService.printerExist(
          data.settings.printer.name,
          { url: PRINTER_URL, printerClass: PRINTER_CLASS },
        );

        if (!printerExist) {
          return;
        }

        await this.qzTrayService.openCashDrawer(data);
        break;

      case PrinterConnectionMethod.Bluetooth:
      case PrinterConnectionMethod.USB:
      case PrinterConnectionMethod.WiFi:
        this.receiptPrinterThermalPrinterService.openCashDrawer(data);
        break;
    }
  }
}
