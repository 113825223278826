import { formatDate, formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import JsBarcode from 'jsbarcode';
import jsPDF from 'jspdf';
import round from 'lodash-es/round';
import 'svg2pdf.js';

import { DATE_FORMAT } from '../../../../core/constants/date.const';
import { LoadingService } from '../../../../core/services/loading.service';
import { LabelPrinterData } from '../label-printer-data.model';
import {
  CHECK_LOGO_HEIGHT,
  CHECK_LOGO_WIDTH,
  EAN_13_LENGTH,
  PAPER_40_X_25,
  PAPER_60_X_40,
} from '../label-printer.const';
import { Label } from '../label.enum';
import { PrintLabelPdfSettings } from '../types/print-label-pdf-settings.model';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(private loadingService: LoadingService) {}

  async generateLabels(data: LabelPrinterData): Promise<jsPDF> {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [
        data.settings.paperFormat.width,
        data.settings.paperFormat.height,
      ],
    });

    doc.addFont(
      '../../../../assets/fonts/Roboto-Regular.ttf',
      'Roboto',
      'normal',
    );
    doc.addFont('../../../../assets/fonts/Roboto-Bold.ttf', 'Roboto', 'bold');
    doc.addFont(
      '../../../../assets/fonts/RobotoMono-Bold.ttf',
      'RobotoMono',
      'bold',
    );

    doc.addFont(
      '../../../../assets/fonts/RobotoMono-Regular.ttf',
      'RobotoMono',
      'normal',
    );

    doc.setFont('RobotoMono', 'normal');
    doc.setLanguage('uk');

    const operationId = 'generatePdf';
    const operationName = 'Створення PDF-файла';

    await this.loadingService.presentCustomPreloader(
      operationId,
      operationName,
    );

    for (let i = 0; i < data.products.length; i += 1) {
      if (data.type === Label.Sale) {
        await this.addSaleLabel(doc, data, i);
      } else if (data.type === Label.Product) {
        await this.addProductLabel(doc, data, i);
      }
    }

    await this.loadingService.dismiss(operationId);

    return doc;
  }

  private async addSaleLabel(
    doc: jsPDF,
    data: LabelPrinterData,
    i: number,
  ): Promise<void> {
    const labelConfig = new PrintLabelPdfSettings(data.settings);

    doc.setFontSize(labelConfig.text.fontSize);

    let currentTop = labelConfig.margins.top;

    /**
     * Calc name element default max height
     */
    let nameRowsMaxCount = 1;

    if (labelConfig.isFormat(PAPER_40_X_25)) {
      nameRowsMaxCount = 3;
    } else if (labelConfig.isFormat(PAPER_60_X_40)) {
      nameRowsMaxCount = 5;
    }

    let nameMaxHeight = labelConfig.textHeight(nameRowsMaxCount);

    if (data.logo > '') {
      const objectMaxHeight =
        labelConfig.printAreaHeight -
        nameMaxHeight -
        labelConfig.text.height - // date row
        labelConfig.margins.element * 2; // logo, name and date intervals

      const { objectLeft, objectWidth, objectHeight } = this.getObjectRect(
        labelConfig.width,
        CHECK_LOGO_WIDTH,
        CHECK_LOGO_HEIGHT,
        objectMaxHeight,
      );

      doc.addImage(
        data.logo,
        'PNG',
        objectLeft,
        currentTop,
        objectWidth,
        objectHeight,
      );

      currentTop += objectHeight + labelConfig.margins.element;
    } else {
      /**
       * Update name element default max height if logo is empty
       */
      if (labelConfig.isFormat(PAPER_40_X_25)) {
        nameRowsMaxCount += 3;
      } else if (labelConfig.isFormat(PAPER_60_X_40)) {
        nameRowsMaxCount += 2;
      }

      nameMaxHeight = labelConfig.textHeight(nameRowsMaxCount);
    }

    const nameRows = doc.splitTextToSize(
      data.products[i].name,
      labelConfig.printAreaWidth,
    ) as string[];

    const nameRowsCount = Math.min(nameRowsMaxCount, nameRows.length);
    const nameText = nameRows.slice(0, nameRowsCount).join(' ');

    const nameTop =
      currentTop + (nameMaxHeight - labelConfig.textHeight(nameRowsCount)) / 2;

    if (labelConfig.isFormat(PAPER_40_X_25)) {
      doc.text(nameText, labelConfig.width / 2, nameTop, {
        maxWidth: labelConfig.printAreaWidth,
        align: 'center',
        baseline: 'middle',
      });

      doc.text(
        this.date(),
        labelConfig.width / 2,
        labelConfig.height - labelConfig.margins.bottom,
        {
          maxWidth: labelConfig.printAreaWidth,
          align: 'center',
          baseline: 'alphabetic',
        },
      );
    } else if (labelConfig.isFormat(PAPER_60_X_40)) {
      doc.setFont('RobotoMono', 'normal');
      doc.setFontSize(10);

      doc.text(nameText, labelConfig.width / 2, nameTop, {
        maxWidth: labelConfig.printAreaWidth,
        align: 'center',
        baseline: 'middle',
      });

      doc.text(
        this.date(),
        labelConfig.width / 2,
        labelConfig.height - labelConfig.margins.bottom,
        {
          maxWidth: labelConfig.printAreaWidth,
          align: 'center',
          baseline: 'alphabetic',
        },
      );
    }

    if (i < data.products.length - 1) {
      doc.addPage();
    }
  }

  private async addProductLabel(
    doc: jsPDF,
    data: LabelPrinterData,
    i: number,
  ): Promise<void> {
    const labelConfig = new PrintLabelPdfSettings(data.settings);

    doc.setFontSize(labelConfig.text.fontSize);

    /**
     * Calc name element default max height
     */
    let nameRowsMaxCount = 1;

    if (labelConfig.isFormat(PAPER_40_X_25)) {
      nameRowsMaxCount = 3;
    } else if (labelConfig.isFormat(PAPER_60_X_40)) {
      nameRowsMaxCount = 6;
    }

    let nameMaxHeight = labelConfig.textHeight(nameRowsMaxCount);

    const barcode = data.products[i].barcode
      .split(';')
      .filter((b) => b.length >= EAN_13_LENGTH)[0];

    if (barcode > '') {
      const objectMaxHeight =
        labelConfig.printAreaHeight -
        nameMaxHeight -
        labelConfig.text.height - // date row
        labelConfig.margins.element * 2; // logo, name and date intervals

      const { svg, svgSize } = this.generateBarcodeSvg(barcode, labelConfig);

      const { objectLeft, objectWidth, objectHeight } = this.getObjectRect(
        labelConfig.width,
        svgSize.width,
        svgSize.height,
        objectMaxHeight,
      );

      await doc.svg(svg, {
        x: objectLeft,
        y:
          labelConfig.margins.top + nameMaxHeight + labelConfig.margins.element,
        width: objectWidth,
        height: objectHeight,
      });

      document.body.removeChild(svg);
    } else {
      /**
       * Update name element default max height if logo is empty
       */
      if (labelConfig.isFormat(PAPER_40_X_25)) {
        nameRowsMaxCount += 3;
      } else if (labelConfig.isFormat(PAPER_60_X_40)) {
        nameRowsMaxCount += 2;
      }

      nameMaxHeight = labelConfig.textHeight(nameRowsMaxCount);
    }

    const nameRows = doc.splitTextToSize(
      data.products[i].name,
      labelConfig.printAreaWidth,
    ) as string[];

    const nameRowsCount = Math.min(nameRowsMaxCount, nameRows.length);
    const nameText = nameRows.slice(0, nameRowsCount).join(' ');

    const nameTop =
      labelConfig.margins.top +
      (nameMaxHeight - labelConfig.textHeight(nameRowsCount)) / 2;

    const price = `${formatNumber(
      data.products[i].price ?? 0,
      'uk-UA',
      '1.2-2',
    )} грн`;

    if (labelConfig.isFormat(PAPER_40_X_25)) {
      doc.text(nameText, labelConfig.width / 2, nameTop, {
        maxWidth: labelConfig.printAreaWidth,
        align: 'center',
        baseline: 'hanging',
      });

      doc.text(
        price,
        labelConfig.width / 2,
        labelConfig.height - labelConfig.margins.bottom,
        {
          maxWidth: labelConfig.printAreaWidth,
          align: 'center',
          baseline: 'alphabetic',
        },
      );
    } else if (labelConfig.isFormat(PAPER_60_X_40)) {
      doc.setFont('RobotoMono', 'bold');
      doc.setFontSize(8);

      doc.text(data.shop.name, labelConfig.width / 2, labelConfig.margins.top, {
        maxWidth: labelConfig.printAreaWidth,
        align: 'center',
        baseline: 'top',
      });

      doc.setFont('RobotoMono', 'normal');
      doc.setFontSize(10);

      doc.text(
        nameText,
        labelConfig.width / 2,
        nameTop + labelConfig.text.height + labelConfig.margins.element,
        {
          maxWidth: labelConfig.printAreaWidth,
          align: 'center',
          baseline: 'middle',
        },
      );

      doc.setFont('RobotoMono', 'bold');

      doc.text(
        price,
        labelConfig.width / 2,
        labelConfig.height - labelConfig.margins.bottom,
        {
          maxWidth: labelConfig.printAreaWidth,
          align: 'center',
          baseline: 'alphabetic',
        },
      );

      doc.setFont('RobotoMono', 'normal');
    }

    if (i < data.products.length - 1) {
      doc.addPage();
    }
  }

  private date(): string {
    return `${formatDate(new Date(), DATE_FORMAT, 'uk-UA')}`;
  }

  private generateBarcodeSvg(
    barcode: string,
    labelConfig: PrintLabelPdfSettings,
  ): {
    svg: HTMLElement;
    svgSize: { width: number; height: number };
  } {
    const svg = document.createElement('svg');

    svg.style.display = 'none';

    JsBarcode(svg, barcode, {
      format: labelConfig.barcode.format,
      fontSize: labelConfig.barcode.fontSize,
      fontOptions: 'bold',
      width: labelConfig.barcode.width,
      height: labelConfig.barcode.height,
      margin: labelConfig.barcode.margin,
    });

    document.body.appendChild(svg);

    const viewbox = (svg.getAttribute('viewbox') ?? '0 0 0 0').split(' ');
    const svgSize = {
      width: Number(viewbox[2]),
      height: Number(viewbox[3]),
    };

    return { svg, svgSize };
  }

  private getObjectRect(
    pageWidth: number,
    objectWidth: number,
    objectHeight: number,
    objectMaxHeight: number,
  ): {
    objectLeft: number;
    objectWidth: number;
    objectHeight: number;
  } {
    const scale = objectWidth / objectHeight;
    const height = objectMaxHeight;
    const width = round(height * scale, 2);
    const left = round((pageWidth - width) / 2, 2);

    return {
      objectLeft: left,
      objectWidth: width,
      objectHeight: height,
    };
  }
}
