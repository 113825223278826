<div class="check" *ngIf="report">
  <section>
    <bk-view-line [center]="report.companyName"></bk-view-line>
    <bk-view-line [center]="report.shopName" [bold]="true"></bk-view-line>
    <bk-view-line [center]="report.shopAddress"></bk-view-line>
    <bk-view-line [center]="report.IPN"></bk-view-line>
    <bk-view-line [center]="report.EDRPOU" *ngIf="!report.IPN"></bk-view-line>
    <hr>
    <bk-view-line [center]="report.reportName" [bold]="true"></bk-view-line>
    <hr>
  </section>

  <section class="list border-bottom">
    <bk-view-line [start]="shiftStartCaption" [end]="report.startedAt"></bk-view-line>
    <bk-view-line [start]="shiftFinishCaption" [end]="report.finishedAt"></bk-view-line>
  </section>

  <section class="list border-bottom">
    <bk-view-line [start]="realizCountCaption" [end]="report.realizCount"></bk-view-line>
    <bk-view-line [start]="realizSumCaption" [end]="report.realizSum" [bold]="true"></bk-view-line>
    <bk-view-line [start]="roundSumCaption" [end]="report.realizRoundSum"></bk-view-line>

    <div *ngFor="let payment of report.realizPayments" class="item">
      <bk-view-line [start]="payment.name" [end]="payment.sum"></bk-view-line>
    </div>

    <div *ngFor="let tax of report.realizTaxes" class="item">
      <bk-view-line [start]="tax.volume" [end]="tax.sourceSum"></bk-view-line>
      <bk-view-line [start]="tax.name" [end]="tax.sum"></bk-view-line>
    </div>

    <hr>
  </section>

  <section class="list border-bottom">
    <bk-view-line [start]="returnCountCaption" [end]="report.returnCount"></bk-view-line>
    <bk-view-line [start]="returnSumCaption" [end]="report.returnSum" [bold]="true"></bk-view-line>
    <bk-view-line [start]="roundSumCaption" [end]="report.returnRoundSum"></bk-view-line>

    <div *ngFor="let payment of report.returnPayments" class="item">
      <bk-view-line [start]="payment.name" [end]="payment.sum"></bk-view-line>
    </div>

    <div *ngFor="let tax of report.returnTaxes" class="item">
      <bk-view-line [start]="tax.volume" [end]="tax.sourceSum"></bk-view-line>
      <bk-view-line [start]="tax.name" [end]="tax.sum"></bk-view-line>
    </div>

    <hr>
  </section>

  <section class="list border-bottom">
    <bk-view-line [start]="serviceInputCaption" [end]="report.serviceInput"></bk-view-line>
    <bk-view-line [start]="serviceOutputCaption" [end]="report.serviceOutput"></bk-view-line>
  </section>

  <section class="list border-bottom">
    <bk-view-line [start]="cashRemainCaption" [end]="report.cashRemain"></bk-view-line>
  </section>

  <section class="list border-bottom" *ngIf="isFiscal">
    <bk-view-line [start]="discountSumCaption" [end]="report.discount"></bk-view-line>
    <bk-view-line [start]="lastDocLocalNumberCaption" [end]="report.lastDocLocalNumber"></bk-view-line>
    <bk-view-line [start]="lastDocFisclNumberCaption" [end]="report.lastDocFiscalNumber"></bk-view-line>
  </section>

  <section *ngIf="isFiscal; else notFiscal">
    <bk-view-line [start]="report.checkFiscalNumber" [end]="report.date"></bk-view-line>
    <bk-view-line [line]="report.prroFiscalNumber"></bk-view-line>
    <bk-view-line [center]="report.fiscalType"></bk-view-line>
  </section>

  <ng-template #notFiscal>
    <section>
      <bk-view-line [line]="report.prroFiscalNumber"></bk-view-line>
      <bk-view-line [center]="report.date"></bk-view-line>
    </section>
  </ng-template>

  <bk-view-line [center]="prroName"></bk-view-line>

  <div class="page-break"></div>
</div>
