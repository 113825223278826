export const PrivatbankJsonLastStatMsgCode: Record<string, string> = {
  0: 'Код стану недоступний',
  1: 'Картка прочитана',
  2: 'Використана чіп-картка',
  3: 'Триває авторизація',
  4: 'Очікування дії касира...',
  5: 'Друк квитанції...',
  6: 'Перевірка необхідності ввести PIN-код...',
  7: 'Картку вилучено',
  8: "EMV multi aid's",
  9: 'Очікування картки...',
  10: 'Виконання операції...',
  11: 'Правильна транзакція',
  12: 'Клавіша очікування введення PIN-коду',
  13: 'Натиснута клавіша видалення введеного символу',
  14: 'Натиснута клавіша введення',
};
