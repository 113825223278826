import { Injectable } from '@angular/core';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { AlertController } from '@ionic/angular';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IPairedBluetoothDevice } from '../../core/interfaces/paired-bluetooth-device.interface';
import { CONNECTION_ERROR, CURRENT_BARCODE_SCANNER } from '../settings.const';

@Injectable({
  providedIn: 'root',
})
export class BarcodeScannerService {
  private isBluetoothDeviceConnected = false;

  private currentBluetoothDevice: IPairedBluetoothDevice;
  private readonly bluetoothDevice: BehaviorSubject<BluetoothSerial | null>;

  constructor(
    private alertCtrl: AlertController,
    private bluetoothSerial: BluetoothSerial,
  ) {
    this.bluetoothDevice = new BehaviorSubject(null);
  }

  getDevice(): IPairedBluetoothDevice | null {
    const device = localStorage.getItem(CURRENT_BARCODE_SCANNER);

    return device != null
      ? (JSON.parse(device) as IPairedBluetoothDevice)
      : null;
  }

  setDevice(device: IPairedBluetoothDevice | null): void {
    localStorage.setItem(CURRENT_BARCODE_SCANNER, JSON.stringify(device));
  }

  removeDevice(): void {
    localStorage.removeItem(CURRENT_BARCODE_SCANNER);
  }

  initBluetoothDevice(): Observable<any> {
    if (this.isBluetoothDeviceConnected) {
      return of({});
    }

    const device = this.getDevice();

    if (device != null) {
      return this.connectToBluetoothDevice(device);
    }

    return of(null);
  }

  reconnectToBluetoothDevice(device: IPairedBluetoothDevice): Observable<any> {
    if (this.isBluetoothDeviceConnected) {
      this.bluetoothSerial.disconnect();
      this.bluetoothDevice.next(null);

      return this.connectToBluetoothDevice(device);
    }

    return this.connectToBluetoothDevice(device);
  }

  disconnectBluetoothDevice(): Promise<any> {
    this.bluetoothDevice.next(null);

    this.isBluetoothDeviceConnected = false;

    return this.bluetoothSerial.disconnect();
  }

  getBluetoothDevice(): Observable<BluetoothSerial | null> {
    return this.bluetoothDevice;
  }

  setBluetoothDevice(device: BluetoothSerial | null): void {
    this.bluetoothDevice.next(device);
  }

  isBluetoothDeviceActive(): boolean {
    return this.isBluetoothDeviceConnected;
  }

  private connectToBluetoothDevice(
    device: IPairedBluetoothDevice,
  ): Observable<any> {
    this.currentBluetoothDevice = device;

    return this.bluetoothSerial.connect(device.address).pipe(
      tap(() => {
        this.isBluetoothDeviceConnected = true;

        this.setDevice(this.currentBluetoothDevice);
        this.bluetoothDevice.next(this.bluetoothSerial);
        this.showAlert(true);
      }),
      catchError((error) => {
        this.isBluetoothDeviceConnected = false;

        this.bluetoothDevice.next(null);
        this.showAlert(false);

        return of(CONNECTION_ERROR);
      }),
    );
  }

  private showAlert(success: boolean): void {
    this.alertCtrl
      .create({
        header: success ? 'Пристрій підключено' : 'Помилка підключення',
        message: success
          ? 'Сканер штрихкодів підключено'
          : 'Не вдається підключитися до сканера штрихкодів, перевірте чи пристрій увімкнений',
        buttons: [{ text: 'Закрити', role: 'close' }],
        cssClass: 'bt-device__alert',
      })
      .then((alert) => alert.present());
  }
}
