import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { INVOICE_ADD_PRODUCT } from '../../../core/constants/events.const';
import { Ingredient } from '../../../core/models/ingredient.model';
import { Events } from '../../../core/services/events.service';
import { Product } from '../../../shop/products/product.model';

@Component({
  selector: 'bk-multibarcode-dialog',
  templateUrl: './multibarcode.dialog.html',
  styleUrls: ['./multibarcode.dialog.scss'],
})
export class MultibarcodeDialog implements OnInit {
  @Input() items: Product[] | Ingredient[];
  @Input() barcode: string;
  @Input() weight?: number;
  @Input() isProduct = false;

  constructor(private modalCtrl: ModalController, private events: Events) {}

  ngOnInit(): void {
    //
  }

  async select(item: Product | Ingredient, event?: any): Promise<void> {
    if (event) {
      event.currentTarget.blur();
    }

    if (this.isProduct) {
      this.events.publish(INVOICE_ADD_PRODUCT, {
        product: item,
        weight: this.weight,
      });

      await this.modalCtrl.dismiss({ success: true });
    } else {
      await this.modalCtrl.dismiss({ success: true, ingredient: item });
    }
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ success: false });
  }
}
