<div>
  <div class="expanded-presale" [ngClass]="{ 'expanded-presale__active': collapsed }">
    <div class="expanded-presale__time">
      <span class="expanded-presale__time__completed">{{ presale.completeBy | date: 'HH:mm' }}</span>
      <span class="expanded-presale__time__created">{{ presale.createdAt | date: 'HH:mm' }}</span>
    </div>

    <div class="expanded-presale__client">
      <span class="expanded-presale__client__phone">{{ presale.client?.mobilePhone ? presale.client?.mobilePhone : '-' }}</span>
      <span class="expanded-presale__client__name">{{ presale.client?.name ? presale.client?.name : '-' }}</span>
    </div>

    <span class="expanded-presale__products">
      <span class="ion-hide-sm-down">{{ presale.productNames }}</span>
    </span>

    <span class="expanded-presale__sum">
      {{ (presale.paymentSum) | number: '1.2-2' }}
      <ion-icon [name]="presale.cashless == null ? 'shapes' : presale.cashless ? 'card' : 'cash'"></ion-icon>
    </span>

    <span class="expanded-presale__details">
      <a (click)="togglePresale($event)">Деталі</a>

      <span class="expanded-presale__details__comment">
        <ion-label *ngIf="!presale.comment">&nbsp;</ion-label>
        <ion-icon *ngIf="presale.comment" name="chatbubble-ellipses-outline"></ion-icon>
      </span>
    </span>
  </div>

  <div class="collapsed" *ngIf="collapsed">
    <div class="collapsed-presale">
      <div class="collapsed-presale__header">
        <span class="collapsed-presale__header__name">Найменування</span>
        <span class="collapsed-presale__header__quantity">Кількість</span>
        <span class="collapsed-presale__header__sum">Сума, ₴</span>
      </div>

      <div class="odd-row" *ngFor="let presaleProduct of presale.presaleProducts">
        <div class="collapsed-presale__data">
          <span class="collapsed-presale__data__name">{{ name(presaleProduct.product) }}</span>

          <span class="collapsed-presale__data__quantity">
            <bk-icon name="coupon" [customSize]="{ width: '18px', height: '18px' }" *ngIf="presaleProduct.freeCup"></bk-icon>
            {{ presaleProduct.quantity }}
          </span>

          <span class="collapsed-presale__data__sum">
            {{ presaleProduct.cost | number: '1.2-2' }}
          </span>
        </div>
      </div>

      <div class="collapsed-presale__comment" *ngIf="presale.comment">
        <span>{{ presale.comment }}</span>
      </div>
    </div>
  </div>
</div>
