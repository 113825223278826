<div class="cover" (click)="go(item)" (press)="popup(item)">
  <div class="cover__container">
    <img-loader *ngIf="coverPath; else noCover"
                [src]="coverPath ? coverPath : 'assets/images/no-cover.png'" 
                [spinner]="false"
                fallbackUrl="assets/images/error-cover.png">
      <img src="assets/images/no-cover.png" 
           alt="Немає обкладинки" 
           [ngClass]="{ 'disabled-image': disabled }"/>
    </img-loader>

    <ng-template #noCover>
      <div class="cover__container__placeholder" [style.background]="item.coverColor">
        <img src="assets/images/no-cover.png" 
             alt="Немає обкладинки" 
             [ngClass]="{ 'disabled-image': disabled }"/>
  
        <span class="cover__container__placeholder__label">{{ item.coverLabel }}</span>
      </div>
    </ng-template>
  </div>

  <div class="cover__caption">
    <span>{{ item.name }}</span>

    <div class="cover__caption__info" *ngIf="needUnits">
      {{ item.amount }}
      <span class="cover__caption__info__value">{{ item.price | currency:'UAH':'symbol-narrow' }}</span>
    </div>
  </div>
</div>
