<ion-header>
  <ion-toolbar>
    <ion-menu-toggle slot="start">
      <ion-button fill="clear">
        <ion-icon name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>

    <div class="logo-container">
      <img class="header-logo" src="assets/images/logo.png" alt="Логотип" (click)="refreshMenu()">
    </div>

    <ion-searchbar *ngIf="isShiftOpened && isWorkingTimeStarted && searchBarcodeMode"
                    #barcodeSearchbar
                    id="barcodeSearchbar"
                    name="barcodeSearchbar"
                    type="number"
                    class="create-sale-searchbar"
                    placeholder="Введіть штрихкод і натисніть Enter"
                    [(ngModel)]="searchBarcode"
                    (ionBlur)="clearBarcodeSearchbar()"
                    (ionCancel)="clearBarcodeSearchbar()"
                    (keyup.enter)="checkBarcode()"></ion-searchbar>

    <ion-searchbar *ngIf="isShiftOpened && isWorkingTimeStarted && searchProductMode"
                    #textSearchbar
                    id="textSearchbar"
                    name="textSearchbar"
                    class="create-sale-searchbar"
                    placeholder="Введіть одне або декілька слів для пошуку товару"
                    [(ngModel)]="searchProduct"
                    (ionBlur)="clearTextSearchbar()"
                    (ionCancel)="clearTextSearchbar()"
                    (ionChange)="filterList()"
                    (ionFocus)="searchFocus()"></ion-searchbar>

    <ion-buttons slot="end">
      <ion-button [disabled]="!(isShiftOpened && isWorkingTimeStarted)" (click)="openClientScreen($event)" *ngIf="isDesktop && shop.clientScreen">
        <ion-icon name="desktop-outline"></ion-icon>
      </ion-button>

      <ion-button [disabled]="!(isShiftOpened && isWorkingTimeStarted)" (click)="showBarcodeSearchbar()">
        <ion-icon name="keypad"></ion-icon>
      </ion-button>

      <bk-bluetooth-barcode-scanner *ngIf="!userSettings.keyboardMode"></bk-bluetooth-barcode-scanner>

      <ion-button [disabled]="!(isShiftOpened && isWorkingTimeStarted)" (click)="showTextSearchbar()">
        <ion-icon name="search"></ion-icon>
      </ion-button>

      <ion-button (click)="checkOnline()">
        <ion-icon name="wifi-outline" [color]="isOnline ? 'day' : 'danger'"></ion-icon>
      </ion-button>

      <ion-button (click)="goToPRROPage()" *ngIf="isFiscalMode">
        <ion-icon [name]="prroState && prroState.ShiftState ? 'shield-checkmark-outline' : 'warning-outline'" 
                  [color]="prroState && prroState.ShiftState ? prroState.ZRepPresent ? 'warning' : 'success' : 'danger'"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="search-select-list">
    <ion-list-header *ngIf="filteredProducts.length > SEARCH_ROWS; else productsList">
      <ion-label class="name">
        <span>{{ SEARCH_COMMENT }}</span>
        <ion-note>{{ SEARCH_RECORDS }}: {{ filteredProducts.length }}</ion-note>
      </ion-label>
    </ion-list-header>

    <ng-template #productsList>
      <ion-item *ngFor="let product of filteredProducts" (click)="addProduct(product)" (press)="addProduct(product)">
        <ion-label class="name">
          <span>{{ product.name + ', ' + product.amount }}</span>
          <ion-note>{{ product.categoryName + ' > ' + product.subcategoryName }}</ion-note>
        </ion-label>

        <ion-label class="price" slot="end">
          {{ product.price | currency:'UAH':'symbol-narrow' }}
        </ion-label>
      </ion-item>
    </ng-template>
  </ion-list>

  <div [ngClass]="{ 'landscape-mode': isLandscapeMode,
                    'portrait-mode': !isLandscapeMode }">
    <section class="categories-grid">
      <bk-categories *ngIf="!isLandscapeMode; else landscapeMenu"
                      [disabled]="!isShiftOpened || !isWorkingTimeStarted"></bk-categories>

      <ng-template #landscapeMenu>
        <bk-landscape-menu [isShiftOpen]="isShiftOpened && isWorkingTimeStarted"></bk-landscape-menu>
      </ng-template>
    </section>

    <section class="order">
      <ion-toolbar class="order__toolbar">
        <ion-buttons slot="start" *ngIf="isDesktop">
          <ion-button (click)="slides.slidePrev()" [disabled]="requestInProgress">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </ion-button>

          <ion-button (click)="slides.slideNext()" [disabled]="requestInProgress">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>
          <ion-label>Чек #{{ getSlideIndex() + 1 }}</ion-label>
        </ion-title>

        <ion-buttons slot="end">
          <ion-button (click)="openProductsMenu()" [disabled]="(totalCashSum === 0) || requestInProgress">
            <ion-icon name="ellipsis-vertical"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-slides class="order__slider"
                  [ngClass]="{ 'quick-buttons': !userSettings.quickSaleButtonsMode }"
                  (ionSlideDidChange)="slideDidChange()">
        <ion-slide *ngFor="let invoice of invoices">
          <ion-content #productList *ngIf="invoice.length; else noOrder">
            <ion-list class="order__slider__list" lines="none">
              <ion-item *ngFor="let saleProduct of invoice"> 
                <div class="order__slider__list__item">
                  <div class="order__slider__list__item__product">
                    <ion-text [color]="saleProduct.quantity > 0 ? 'dark' : 'danger'">
                      {{ saleProduct.product.name }}, {{ saleProduct.product.amount }}
                    </ion-text>
                  </div>

                  <div class="order__slider__list__item__addition">
                    <span>
                      {{ saleProduct.product.categoryName }} > 
                      {{ saleProduct.product.subcategoryName }}
                    </span>

                    <span *ngIf="saleProduct.product.excise?.needLabel" (click)="exciseProductDialog(saleProduct)">
                      <span [ngClass]="{ 'border-bottom': !saleProduct.exciseLabelView }">
                        Акцизні марки: 
                      </span>

                      <span [ngClass]="{ 'border-bottom': saleProduct.exciseLabelView }">
                        {{ saleProduct.exciseLabelView }}
                      </span>
                    </span>
                  </div>

                  <div class="order__slider__list__item__price">
                    <ion-text color="danger" class="old-price" *ngIf="isDiffPrice(saleProduct)">
                      {{ saleProduct.product.price | currency:'UAH':'symbol-narrow' }}
                    </ion-text>

                    <ion-text [ngClass]="{ 'order__slider__list__item__quantity': user?.changeSaleSum }"
                              (click)="updatePrice(saleProduct)"
                              *ngIf="user?.changeSaleSum; else readonlyPrice">
                      {{ saleProduct.price | currency:'UAH':'symbol-narrow' }}
                    </ion-text>

                    <ng-template #readonlyPrice>
                      {{ saleProduct.price | currency:'UAH':'symbol-narrow' }}
                    </ng-template>
                  </div>

                  <div class="order__slider__list__item__sum">
                    {{ saleProduct.cost | currency:'UAH':'symbol-narrow' }}
                  </div>

                  <div class="order__slider__list__item__buttons">
                    <ng-container *ngIf="!saleProduct.product.weightProduct; else weightProduct">
                      <ion-button color="primary" slot="icon-only" (click)="decrementQuantity(saleProduct)">
                        <ion-icon name="remove"></ion-icon>
                      </ion-button>

                      <ion-text class="order__slider__list__item__quantity" color="primary" (click)="inputQuantity(saleProduct)">
                        {{ saleProduct.quantity }}
                      </ion-text>

                      <ion-button color="primary" slot="icon-only" (click)="incrementQuantity(saleProduct)">
                        <ion-icon name="add"></ion-icon>
                      </ion-button>
                    </ng-container>

                    <ng-template #weightProduct>
                      <ng-container>
                        <ion-button color="primary" slot="icon-only" (click)="weightProductDialog(saleProduct, true)">
                          <ion-icon name="calculator"></ion-icon>
                        </ion-button>

                        <ion-text color="primary">
                          {{ getWeightProductQuantity(saleProduct) }}
                        </ion-text>

                        <ion-button color="primary" slot="icon-only" (click)="decrementQuantity(saleProduct)">
                          <ion-icon name="close"></ion-icon>
                        </ion-button>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>
              </ion-item>
            </ion-list>
          </ion-content>

          <ng-template #noOrder>
            <div class="ion-padding">
              <ng-container *ngIf="isShiftOpened; else shiftNotOpened">
                {{ isShiftUser ? 
                   (isWorkingTimeStarted ? 'Додайте товар або послугу' : 'Активуйте таймер робочого часу в боковому меню') :
                  'Приєднайтесь до робочої зміни' }}
              </ng-container>

              <ng-template #shiftNotOpened>
                Відкрийте робочу зміну
              </ng-template>
            </div>
          </ng-template>
        </ion-slide>
      </ion-slides>

      <ion-toolbar class="order__quick-buttons" *ngIf="userSettings.quickSaleButtonsMode">
        <ion-buttons>
          <ion-button fill="solid"
                      color="tertiary"
                      class="order__quick-buttons__cash"
                      (click)="cashPayment()"[disabled]="(totalCashSum === 0) || (totalCashSum > MAX_SALE_AMOUNT) || requestInProgress">
            <ion-icon slot="start" name="cash" size="large"></ion-icon>
            <ion-label>Готівка</ion-label>

            <ion-label slot="end">
              <ion-spinner *ngIf="requestCashInProgress; else cashSum"></ion-spinner>

              <ng-template #cashSum>
                <span *ngIf="isRounded">:&nbsp;{{ totalCashSum | number: '1.2-2' }}</span>
              </ng-template>
            </ion-label>
          </ion-button>

          <ion-button fill="solid"
                      color="secondary"
                      class="order__quick-buttons__card"
                      (click)="cardPayment()"
                      [disabled]="(totalCashSum === 0) || (totalCashSum > MAX_SALE_AMOUNT) || requestInProgress">
            <ion-icon slot="start" name="card" size="large"></ion-icon>
            <ion-label>Картка</ion-label>

            <ion-label slot="end">
              <ion-spinner *ngIf="requestCardInProgress; else cardSum"></ion-spinner>

              <ng-template #cardSum>
                <span *ngIf="isRounded">:&nbsp;{{ totalCardSum | number: '1.2-2' }}</span>
              </ng-template>
            </ion-label>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-toolbar class="order__footer">
        <div class="order__footer__paginator">
          <div class="order__footer__paginator__bullets">
            <span *ngFor="let pg of invoices; let i = index"
                  [class.order__footer__paginator__bullets__active]="i === getSlideIndex()"></span>
          </div>

          <div class="order__footer__paginator__numbers">
            <span>{{ getPageIndex() }}</span>
          </div>
        </div>

        <ion-buttons>
          <div class="order__footer__total">
            <ion-text color="primary">
              <span>{{ totalCashSum | currency:'UAH':'symbol-narrow' }}</span>
            </ion-text>
          </div>

          <ion-button fill="solid" color="primary" (click)="openCalculation()" [disabled]="(totalCashSum === 0) || (totalCashSum > MAX_SALE_AMOUNT) || requestInProgress">
            <ion-icon slot="start" name="calculator" size="large"></ion-icon>
            <ion-label>Розрахувати</ion-label>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </section>
  </div>
</ion-content>
