<ion-header>
  <ion-toolbar>
    <ion-title>Налаштування УЕП</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="warning">
    <div class="warning-icon">
      <ion-icon name="warning"></ion-icon>
    </div>

    <div class="warning-text">
      <h3>Звертаємо увагу</h3>

      <span>
        Під час роботи сервісу ключі та сертифікати користувача, <br>
        обробляються у додатку/браузері та не передаються на сервер 
      </span>
    </div>
  </div>
  
  <ion-segment color="dark" (ionChange)="changedACSK($event)" [value]="acsk">
    <ion-segment-button [value]="KEY_JKS">
      <ion-label>КНЕДП "ПриватБанк"</ion-label>
    </ion-segment-button>

    <ion-segment-button [value]="KEY_DAT">
      <ion-label>Інший КНЕДП</ion-label>
    </ion-segment-button>
  </ion-segment>

  <ion-list>
    <ion-item>
      <div>
        <ion-label>Ключ ({{ isJks ? 'pb_*.jks' : 'Key-6*.dat, *.ZS2' }}):</ion-label>
        <ion-input type="file" id="keyFile" name="keyFile" [accept]="acceptedKeysFiles" (change)="loadKey($event)"></ion-input>
      </div>
    </ion-item>

    <ion-item *ngIf="!isJks">
      <div>
        <ion-label class="long-label">Сертифікат підпису (підписання) (*.cer, *.crt):</ion-label>
        <ion-input type="file" id="certFile" name="certFile" [accept]="acceptedCertsFiles" (change)="loadCert($event)"></ion-input>
        <ion-note>Файл сертифіката (з призначенням "ЕЦП", "Підписання" і т.д.) можна завантажити на сайті КНЕДП у розділі "Пошук сертифікатів"</ion-note>
      </div>
    </ion-item>

    <div class="link" *ngIf="isJks">
      <a class="link-style" (click)="isJksRole = !isJksRole">Встановити роль {{ isJksRole ? 'автоматично' : 'вручну' }}</a>
    </div>

    <ion-item *ngIf="isJks && isJksRole">
      <ion-label>Роль:</ion-label>
      <ion-select id="selectProvider" 
                  okText="Вибрати" 
                  cancelText="Скасувати" 
                  [(ngModel)]="role">
        <ion-select-option *ngFor="let role of roles" [value]="role.value">
          {{ role.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Пароль</ion-label>

      <ion-input [type]="hidePassword ? 'password' : 'text'"
                name="password"
                autocomplete="off"
                [required]="true"
                [(ngModel)]="password"
                (keyup.enter)="save()"></ion-input>

      <ion-icon slot="end" 
                class="toggle-password"
                [name]="hidePassword ? 'eye-off-outline' : 'eye-outline'" 
                (click)="togglePassword($event)"></ion-icon>
    </ion-item>
  </ion-list>

  <div class="link">
    <span>Сайт</span>
    <a class="link-style" target="_blank" href="https://acsk.privatbank.ua/" *ngIf="isJks">КНЕДП АЦСК АТ КБ "ПРИВАТБАНК"</a>
    <a class="link-style" target="_blank" href="https://ca.tax.gov.ua/" *ngIf="!isJks">КНЕДП ДПС</a>
    <a class="link-style" target="_blank" href="https://uakey.com.ua/" *ngIf="!isJks">КНЕДП ТОВ "ЦСК "УКРАЇНА"</a>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="two-buttons" slot="start" color="tertiary" (click)="cancel()">Скасувати</ion-button>
    <ion-button class="two-buttons" slot="end" color="primary" (click)="save()">Зберегти</ion-button>
  </ion-toolbar>
</ion-footer>