<div class="check" *ngIf="shift">
  <section>
    <bk-view-line [center]="shift.shopName" [bold]="true"></bk-view-line>
    <hr>
    <bk-view-line [center]="reportName"></bk-view-line>
    <hr>
  </section>

  <section class="list border-bottom">
    <bk-view-line [start]="shiftStartCaption" [end]="shift.createdAt"></bk-view-line>
    <bk-view-line [start]="shiftFinishCaption" [end]="shift.closedAt"></bk-view-line>
  </section>

  <section class="list border-bottom" *ngFor="let shiftUser of shift.shiftUsers">
    <bk-view-line [start]="shiftUser.name" [end]="shiftUser.createdAt" [bold]="true"></bk-view-line>

    <section *ngIf="shiftUser.totals">
      <bk-view-line [start]="realizSumCaption + ' (' + shiftUser.totals.salesCount + ')'" [end]="shiftUser.totals.salesTotalAmount"></bk-view-line>
      <bk-view-line [start]="'> ' + cardPayment" [end]="shiftUser.totals.salesCardAmount"></bk-view-line>
      <bk-view-line [start]="'> ' + cashPayment" [end]="shiftUser.totals.salesCashAmount"></bk-view-line>
      <hr>
  
      <bk-view-line [start]="returnSumCaption + ' (' + shiftUser.totals.saleReturnsCount + ')'" [end]="shiftUser.totals.saleReturnsTotalAmount"></bk-view-line>
      <bk-view-line [start]="'> ' + cardPayment" [end]="shiftUser.totals.saleReturnsCardAmount"></bk-view-line>
      <bk-view-line [start]="'> ' + cashPayment" [end]="shiftUser.totals.saleReturnsCashAmount"></bk-view-line>
      <hr>
  
      <bk-view-line [start]="discountSumCaption" [end]="shiftUser.totals.salesDiscountSummary"></bk-view-line>
      <bk-view-line [start]="revenueCaption" [end]="shiftUser.totals.salesSummary"></bk-view-line>
      <bk-view-line [start]="cardPayment" [end]="shiftUser.totals.salesCardSummary"></bk-view-line>
      <bk-view-line [start]="cashPayment" [end]="shiftUser.totals.salesCashSummary" [bold]="true"></bk-view-line>
      <hr>
  
      <bk-view-line [start]="'+ ' + depositCaption" [end]="shiftUser.totals.cashDeposits"></bk-view-line>
      <bk-view-line [start]="'- ' + incasationCaption" [end]="shiftUser.totals.cashIncasations"></bk-view-line>
      <bk-view-line [start]="'- ' + financeExpensesCaption" [end]="shiftUser.totals.cashFinanceExpenses"></bk-view-line>
      <hr>
  
      <bk-view-line [start]="'- ' + refillExpenseCaption + ' (' + shiftUser.totals.refillsCount + ')'" [end]="shiftUser.totals.refillsPaidCashAmount | number: '1.2-2'"></bk-view-line>
      <bk-view-line [start]="'+ ' + wasteReturnReceivedCaption + ' (' + shiftUser.totals.wasteReturnsCount + ')'" [end]="shiftUser.totals.wasteReturnsReceivedCashAmount | number: '1.2-2'"></bk-view-line>
      <hr>
  
      <bk-view-line [start]="'= ' + cashRemainCaption" [end]="shiftUser.totals.cashRemain" [bold]="true"></bk-view-line>
      <hr>
  
      <bk-view-line [start]="wasteCaption" [end]="shiftUser.totals.wastesCount"></bk-view-line>
      <bk-view-line [start]="moveCaption" [end]="shiftUser.totals.movesOutCount"></bk-view-line>
      <hr>
    </section>
  </section>

  <bk-view-line [start]="moveInCaption" [end]="shift.totals.movesInCount"></bk-view-line>
  <bk-view-line [start]="moveStockInCaption" [end]="shift.totals.movesInFromStockCount"></bk-view-line>
  <hr>

  <section *ngIf="shift.shiftUsers.length > 1">
    <bk-view-line [start]="revenueCaption" [end]="shift.totals.salesSummary"></bk-view-line>
    <bk-view-line [start]="cardPayment" [end]="shift.totals.salesCardSummary"></bk-view-line>
    <bk-view-line [start]="cashPayment" [end]="shift.totals.salesCashSummary"></bk-view-line>
    <hr>
  
    <bk-view-line [start]="cashRemainCaption" [end]="shift.totals.cashRemain" [bold]="true"></bk-view-line>
    <hr>
  </section>

  <bk-view-line [center]="shift.printTime"></bk-view-line>

  <div class="page-break"></div>
</div>
