import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import round from 'lodash-es/round';
import QRCode from 'qrcode';

import {
  DATE_FORMAT,
  DATETIME_FORMAT,
} from '../../../../core/constants/date.const';
import { ShiftTotals } from '../../../../core/models/shift-totals.model';
import { Shift } from '../../../../core/models/shift.model';
import { CachedDataService } from '../../../../core/services/cached-data.service';
import { toCurrencyWords } from '../../../../core/services/numberToString';
import { UtilsService } from '../../../../core/services/utils.service';
import { PayFormCode } from '../../../../p-rro/fsco/enums/pay-form-code.enum';
import { VAT } from '../../../../p-rro/fsco/enums/tax.const';
import { PRroZReport } from '../../../../p-rro/fsco/models/p-rro-z-report.model';
import { Presale } from '../../../../presales/presale/presale.model';
import { Sale } from '../../../../sales/sale/sale.model';
import { Transaction } from '../../../../transactions/transaction/models/transaction.model';
import { TransactionType } from '../../../../transactions/transaction/transaction-type.enum';
import { Move } from '../../../../warehouse/invoices/move/move.model';
import { Refill } from '../../../../warehouse/invoices/refill/refill.model';
import { Waste } from '../../../../warehouse/invoices/waste/waste.model';
import { PrinterConnectionMethod } from '../../printer-connection-method.enum';
import { ReceiptPrinterData } from '../receipt-printer-data.model';
import { ROW_LENGTH_36 } from '../receipt-printer.const';
import {
  CHECK_FISCAL_NUMBER_CAPTION,
  EDRPOU_CAPTION,
  ESC_POS_ALIGN_CENTER,
  ESC_POS_ALIGN_LEFT,
  EXCISE_LABEL_CAPTION,
  FISCAL_CHECK_CAPTION,
  IPN_CAPTION,
  KOP,
  NON_BREAKING_SPACE,
  POS_OPERATION_RETURN,
  POS_OPERATION_SALE,
  PRRO_FISCAL_NUMBER_CAPTION,
  PRRO_ONLINE_STATUS,
  PRRO_ONLINE_STATUS_CAPTION,
  RETURNED_CHECK_FISCAL_NUMBER_CAPTION,
  RETURNED_CHECK_NUMBER_CAPTION,
  SERVICE_INPUT_CAPTION,
  SERVICE_OUTPUT_CAPTION,
  TAX_DATE_FORMAT,
  TAX_VOLUME_CAPTION,
  TEST_FISCAL_CHECK_CAPTION,
  UAH,
  UKTZED_CAPTION,
  WASTE_POSITION_COUNT_1_CAPTION,
  WASTE_POSITION_COUNT_2_CAPTION,
  WASTE_SELF_RNOKPP_CAPTION,
  WASTE_SUMMARY_SUM_CAPTION,
  WASTE_SUMMARY_VAT_CAPTION,
  WITHOUT_VAT,
  X_REPORT_CAPTION,
  Z_REPORT_CAPTION,
} from '../receipt.const';
import { Receipt } from '../receipt.enum';
import { PrintMove } from '../types/print-move.model';
import { PrintPRroServiceDoc } from '../types/print-p-rro-service-doc.model';
import { PrintPRroZReportPayment } from '../types/print-p-rro-z-report-payment.model';
import { PrintPRroZReportTax } from '../types/print-p-rro-z-report-tax.model';
import { PrintPRroZReport } from '../types/print-p-rro-z-report.model';
import { PrintPresale } from '../types/print-presale.model';
import { PrintProductItem } from '../types/print-product-item.model';
import { PrintRefillSalePrices } from '../types/print-refill-sale-prices.model';
import { PrintRefill } from '../types/print-refill.model';
import { PrintSaleTaxItem } from '../types/print-sale-tax-item.model';
import { PrintSale } from '../types/print-sale.model';
import { PrintShiftTotals } from '../types/print-shift-totals.model';
import { PrintShiftUser } from '../types/print-shift-user.model';
import { PrintShift } from '../types/print-shift.model';
import { PrintWaste } from '../types/print-waste.model';

import { ReceiptPrinterStorageService } from './receipt-printer-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ReceiptPrinterFormatService {
  constructor(
    private receiptPrinterStorageService: ReceiptPrinterStorageService,
    private cachedDataService: CachedDataService,
    private utilsService: UtilsService,
  ) {}

  //#region Presale
  async presale(presale: Presale): Promise<ReceiptPrinterData> {
    const settings = await this.receiptPrinterStorageService.getSettings();
    const shop = this.cachedDataService.getShop();

    const doc = new PrintPresale();

    doc.IP = shop.printerIP;
    doc.employee = '';

    doc.createdAt = formatDate(
      presale.createdAt ?? new Date(),
      DATETIME_FORMAT,
      'uk_UA',
    );

    doc.completeBy = formatDate(presale.completeBy, DATETIME_FORMAT, 'uk_UA');

    doc.comment = presale.comment ?? '';
    doc.clientName = presale.client?.name ?? '';
    doc.clientPhone = presale.client?.mobilePhone ?? '';
    doc.products = [];

    presale.presaleProducts.forEach((presaleProduct) => {
      const checkProduct = new PrintProductItem();

      checkProduct.uktzedRow =
        checkProduct.uktzed > ''
          ? `${UKTZED_CAPTION}: ${checkProduct.uktzed}`
          : '';

      checkProduct.exciseLabel =
        presaleProduct.exciseLabel?.replace(new RegExp(';', 'g'), ', ') ??
        '' ??
        '';
      checkProduct.exciseLabelRow =
        checkProduct.exciseLabel > ''
          ? `${EXCISE_LABEL_CAPTION}: ${checkProduct.exciseLabel}`
          : '';

      const weightCoefficient = Math.pow(
        10,
        Number(presaleProduct.product.weightProduct),
      );

      checkProduct.quantity = Math.abs(
        round(presaleProduct.quantity / weightCoefficient, 3),
      ).toString();

      checkProduct.name = presaleProduct.product.name;
      checkProduct.type = presaleProduct.product.amount;

      doc.products.push(checkProduct);
    });

    doc.rowLength = settings.rowLength;
    doc.isQZMode = settings.connectionMethod === PrinterConnectionMethod.QZTray;

    const data = new ReceiptPrinterData(Receipt.Presale, doc);

    data.settings = settings;

    return data;
  }

  //#region Cash Drawer
  async cashDrawer(): Promise<ReceiptPrinterData> {
    const settings = await this.receiptPrinterStorageService.getSettings();

    const data = new ReceiptPrinterData(Receipt.CashDrawer);

    data.settings = settings;

    return data;
  }

  //#region Sale
  async sale(sale: Sale): Promise<ReceiptPrinterData> {
    const settings = await this.receiptPrinterStorageService.getSettings();
    const shop = this.cachedDataService.getShop();
    const prro = this.cachedDataService.getPRRO();
    const user = this.cachedDataService.getUser();

    const doc = new PrintSale();

    doc.IP = shop.printerIP;
    doc.companyName =
      (prro?.companyName ?? '') > '' ? prro.companyName : shop.fop;

    doc.shopName = (prro?.shopName ?? '') > '' ? prro.shopName : shop.name;

    doc.shopAddress =
      (prro?.shopAddress ?? '') > '' ? prro.shopAddress : shop.streetAddress;

    doc.IPN = prro?.companyIPN ?? '';
    doc.IPNRow = doc.IPN > '' ? `${IPN_CAPTION} ${doc.IPN}` : '';

    doc.EDRPOU =
      (prro?.companyEDRPOU ?? '') > '' ? prro.companyEDRPOU : shop.ipn;

    doc.EDRPOURow = doc.EDRPOU > '' ? `${EDRPOU_CAPTION} ${doc.EDRPOU}` : '';

    doc.shopComment = shop.checkComment ?? '';

    doc.userName = sale.user?.name ?? user.name;
    doc.clientName = sale.client?.name ?? '';

    doc.comment = sale.comment ?? '';
    doc.date = formatDate(sale.createdAt, TAX_DATE_FORMAT, 'uk_UA');
    doc.id = sale.id;

    this.addFiscalData(doc, sale);
    this.addReturnData(doc, sale);
    this.addAmounts(doc, sale);
    this.addPaymentTerminalData(doc, sale);
    this.addTaxes(doc, sale);
    this.addProducts(doc, sale);

    doc.rowLength = settings.rowLength;
    doc.isQZMode = settings.connectionMethod === PrinterConnectionMethod.QZTray;

    if (doc.isQZMode) {
      doc.qrBase64 = await this.generateQRCodeToBase64(
        doc.qrData,
        doc.rowLength,
      );
    }

    const data = new ReceiptPrinterData(Receipt.Sale, doc);

    data.settings = settings;

    return data;
  }

  private addFiscalData(doc: PrintSale, sale: Sale): void {
    doc.prroOnlineStatus = PRRO_ONLINE_STATUS;
    doc.prroOnlineStatusRow = `${PRRO_ONLINE_STATUS_CAPTION}: ${doc.prroOnlineStatus}`;

    let isTestMode = false;

    if (sale.prro != null) {
      isTestMode = sale.prro?.testMode ?? false;

      doc.prroFiscalNumber = (sale.prro?.fiscalNumber ?? '').toString();
      doc.prroLocalNumber = (sale.prro?.localNumber ?? '').toString();
    } else {
      const prro = this.cachedDataService.getPRRO();

      isTestMode = prro?.testMode ?? false;

      doc.prroFiscalNumber = (prro?.fiscalNumber ?? '').toString();
      doc.prroLocalNumber = (prro?.localNumber ?? '').toString();
    }

    doc.prroFiscalNumberRow = `${PRRO_FISCAL_NUMBER_CAPTION}: ${doc.prroFiscalNumber}`;

    doc.checkFiscalNumber = sale.prroTaxNumber ?? '';
    doc.checkFiscalNumberRow = `${CHECK_FISCAL_NUMBER_CAPTION} ${doc.checkFiscalNumber}`;

    doc.checkLocalNumber = (sale.prroLocalNumber ?? '').toString();
    doc.checkType =
      sale.prroLocalNumber == null || (sale.prroTaxNumber ?? '') === ''
        ? ''
        : isTestMode
        ? TEST_FISCAL_CHECK_CAPTION
        : FISCAL_CHECK_CAPTION;

    doc.isFiscal = doc.checkFiscalNumber > '';

    doc.qrData =
      sale.prroLocalNumber == null || (sale.prroTaxNumber ?? '') === ''
        ? ''
        : this.utilsService.getQRText(
            sale.prroTaxNumber,
            sale.createdAt,
            sale.paymentSum,
            sale.prro?.fiscalNumber,
          );
  }

  private addReturnData(doc: PrintSale, sale: Sale): void {
    if (sale.returnSaleId > 0) {
      doc.returnSaleId = (sale.returnSaleId ?? '').toString();
      doc.returnedCheckFiscalNumber = (
        sale.returnSale?.prroTaxNumber ?? ''
      ).toString();
    } else {
      doc.returnSaleId = '';
      doc.returnedCheckFiscalNumber = '';
    }

    doc.returnSaleIdRow =
      doc.returnSaleId > ''
        ? `(${RETURNED_CHECK_NUMBER_CAPTION} ${doc.returnSaleId})`
        : '';

    doc.returnedCheckFiscalNumberRow =
      doc.returnedCheckFiscalNumber > ''
        ? `(${RETURNED_CHECK_FISCAL_NUMBER_CAPTION}${doc.returnedCheckFiscalNumber})`
        : '';
  }

  private addAmounts(doc: PrintSale, sale: Sale): void {
    doc.costSum = Math.abs(sale.costSum).toFixed(2);
    doc.discountSum =
      Math.abs(sale.discountSum) > 0
        ? Math.abs(sale.discountSum).toFixed(2)
        : '';

    doc.totalSum = Math.abs(sale.costSum - sale.discountSum).toFixed(2);
    doc.roundSum =
      Math.abs(sale.roundSum) > 0 ? Math.abs(sale.roundSum).toFixed(2) : '';

    doc.paymentSum = Math.abs(sale.paymentSum).toFixed(2);

    doc.debtSum =
      Math.abs(sale.debtSum) > 0 ? Math.abs(sale.debtSum).toFixed(2) : '';
    doc.cashSum =
      Math.abs(sale.cashSum) > 0 ? Math.abs(sale.cashSum).toFixed(2) : '';
    doc.cardSum =
      Math.abs(sale.cardSum) > 0 ? Math.abs(sale.cardSum).toFixed(2) : '';

    doc.debtSumRow = doc.debtSum > '' ? `${doc.debtSum} ${UAH}` : '';
    doc.cashSumRow = doc.cashSum > '' ? `${doc.cashSum} ${UAH}` : '';
    doc.cardSumRow = doc.cardSum > '' ? `${doc.cardSum} ${UAH}` : '';

    doc.providedSum =
      Math.abs(sale.changeSum) > 0 ? Math.abs(sale.clientCash).toFixed(2) : '';

    doc.remainsSum =
      Math.abs(sale.changeSum) > 0 ? Math.abs(sale.changeSum).toFixed(2) : '';

    // Deprecated
    doc.personalDiscount =
      Math.abs(sale.totalDiscount) > 0
        ? Math.abs(sale.totalDiscount).toFixed(2)
        : '';

    doc.bonusPayment =
      Math.abs(sale.totalBonus) > 0 ? Math.abs(sale.totalBonus).toFixed(2) : '';
  }

  private addPaymentTerminalData(doc: PrintSale, sale: Sale): void {
    const cardPayment = sale.salePayments.find(
      (sp) => sp.method === PayFormCode.Card,
    );

    doc.acquirerName = cardPayment?.acquirerName ?? '';
    doc.deviceId = cardPayment?.deviceId ?? '';
    doc.paymentSystem = cardPayment?.paymentSystem ?? '';
    doc.epzDetails = cardPayment?.epzDetails ?? '';
    doc.authCode = cardPayment?.authCode ?? '';
    doc.acquirerTransactionId = cardPayment?.acquirerTransactionId ?? '';
    doc.posTransactionNumber = cardPayment?.posTransactionNumber ?? '';

    doc.posTransactionDate =
      cardPayment?.posTransactionDate != null
        ? formatDate(cardPayment.posTransactionDate, TAX_DATE_FORMAT, 'uk_UA')
        : '';

    doc.transactionId = cardPayment?.transactionId ?? '';

    doc.posOperation =
      sale.returnSaleId > 0 ? POS_OPERATION_RETURN : POS_OPERATION_SALE;
  }

  private addTaxes(doc: PrintSale, sale: Sale): void {
    doc.taxes = [];

    sale.saleTaxes.forEach((tax) => {
      const taxItem = new PrintSaleTaxItem();

      taxItem.name = `${tax.name} ${tax.letter} ${tax.percent}%`;
      taxItem.amount = Math.abs(tax.taxAmount).toFixed(2);

      doc.taxes.push(taxItem);
    });

    if (
      doc.IPN === '' &&
      doc.isFiscal &&
      sale.saleTaxes?.find((saleTax) => saleTax.type === VAT.code) == null
    ) {
      const taxValue = new PrintSaleTaxItem();

      taxValue.name = WITHOUT_VAT;
      taxValue.amount = NON_BREAKING_SPACE;

      doc.taxes.push(taxValue);
    }

    doc.taxes.reverse();
  }

  private addProducts(doc: PrintSale, sale: Sale): void {
    let taxLettersMaxCount = 0;

    sale.saleProducts.forEach((saleProduct) => {
      taxLettersMaxCount = Math.max(
        taxLettersMaxCount,
        `${saleProduct.exciseLetter ?? ''}${saleProduct.vatLetter ?? ''}`
          .length,
      );
    });

    doc.products = [];

    sale.saleProducts.forEach((saleProduct) => {
      const checkProduct = new PrintProductItem();

      checkProduct.name = saleProduct.product.name;
      checkProduct.uktzed = saleProduct.UKTZED ?? '';
      checkProduct.uktzedRow =
        checkProduct.uktzed > ''
          ? `${UKTZED_CAPTION}: ${checkProduct.uktzed}`
          : '';

      checkProduct.exciseLabel = saleProduct.exciseLabelView ?? '';
      checkProduct.exciseLabelRow =
        checkProduct.exciseLabel > ''
          ? `${EXCISE_LABEL_CAPTION}: ${checkProduct.exciseLabel}`
          : '';

      const weightCoefficient = Math.pow(
        10,
        Number(saleProduct.product.weightProduct),
      );

      checkProduct.quantity = Math.abs(
        round(saleProduct.quantity / weightCoefficient, 3),
      ).toString();

      checkProduct.price = Math.abs(
        round(saleProduct.fullPrice * weightCoefficient, 2),
      ).toFixed(2);

      checkProduct.sum = Math.abs(saleProduct.fullCost).toFixed(2);

      checkProduct.quantityAndPrice =
        doc.isFiscal || checkProduct.quantity !== '1'
          ? `${checkProduct.quantity} x ${checkProduct.price}`
          : '';

      if (doc instanceof PrintSale && taxLettersMaxCount > 0) {
        const letters = `${saleProduct.exciseLetter ?? ''}${
          saleProduct.vatLetter ?? ''
        }`
          .padEnd(taxLettersMaxCount, NON_BREAKING_SPACE)
          .padStart(taxLettersMaxCount + 1, ' ');

        checkProduct.sum = `${Math.abs(saleProduct.fullCost).toFixed(
          2,
        )}${letters}`;
      }

      doc.products.push(checkProduct);
    });
  }

  private async generateQRCodeToBase64(
    text: string,
    rowLength: number,
  ): Promise<string> {
    try {
      const options: QRCode.QRCodeToDataURLOptions = {
        errorCorrectionLevel: 'L',
        type: 'image/png',
        margin: 1,
        scale: rowLength < ROW_LENGTH_36 ? 4 : 5,
      };

      const dataURL = await QRCode.toDataURL(text, options);

      return dataURL.split(',')[1];
    } catch (error) {
      return `${ESC_POS_ALIGN_CENTER}${text}${ESC_POS_ALIGN_LEFT}`;
    }
  }

  //#region Waste
  async waste(
    waste: Waste,
    options: { salePriceMode: boolean } = { salePriceMode: false },
  ): Promise<ReceiptPrinterData> {
    const settings = await this.receiptPrinterStorageService.getSettings();
    const shop = this.cachedDataService.getShop();
    const user = this.cachedDataService.getUser();

    const doc = new PrintWaste();

    doc.salePriceMode = options.salePriceMode;

    doc.IP = shop.printerIP;
    doc.fop = shop.fop ?? '';
    doc.ipn = shop.ipn ?? '';
    doc.rnokpp = doc.ipn > '' ? `${WASTE_SELF_RNOKPP_CAPTION}: ${doc.ipn}` : '';

    doc.employee = user.name;
    doc.provider = waste.provider.name;
    doc.positionCount = waste.wasteIngredients.length;
    doc.positionCountRow = `${WASTE_POSITION_COUNT_1_CAPTION} ${doc.positionCount} ${WASTE_POSITION_COUNT_2_CAPTION}`;

    doc.products = [];

    let totalSum = 0;
    let totalCostSum = 0;
    let totalSaleSum = 0;

    waste.wasteIngredients.forEach((wasteIngredient) => {
      const product = new PrintProductItem();

      product.name = wasteIngredient.ingredient.name;
      product.price = waste.backInSalePrice
        ? (wasteIngredient.salePrice ?? 0).toFixed(2)
        : (wasteIngredient.costPrice ?? 0).toFixed(2);

      product.costPrice = (wasteIngredient.costPrice ?? 0).toFixed(2);
      product.salePrice = (wasteIngredient.salePrice ?? 0).toFixed(2);

      product.quantity = wasteIngredient.quantity.toString();
      product.type = wasteIngredient.ingredient.measurement;
      product.sum = (
        wasteIngredient.quantity *
        (waste.backInSalePrice
          ? wasteIngredient.salePrice
          : wasteIngredient.costPrice)
      ).toFixed(2);

      product.costSum = (
        wasteIngredient.quantity * wasteIngredient.costPrice
      ).toFixed(2);

      product.saleSum = (
        wasteIngredient.quantity * wasteIngredient.salePrice
      ).toFixed(2);

      totalSum +=
        wasteIngredient.quantity *
        (waste.backInSalePrice
          ? wasteIngredient.salePrice
          : wasteIngredient.costPrice);

      totalCostSum += wasteIngredient.quantity * wasteIngredient.costPrice;
      totalSaleSum += wasteIngredient.quantity * wasteIngredient.salePrice;

      doc.products.push(product);
    });

    doc.sum = totalSum.toFixed(2);
    doc.costSum = totalCostSum.toFixed(2);
    doc.saleSum = totalSaleSum.toFixed(2);
    doc.money = doc.sum.split('.')[0];
    doc.cent = doc.sum.split('.')[1];
    doc.summarySumRow = `${WASTE_SUMMARY_SUM_CAPTION} ${doc.money} ${UAH} ${doc.cent} ${KOP}, ${WASTE_SUMMARY_VAT_CAPTION} ${UAH}`;

    const date = new Date(waste.wasteIngredients[0].wastedAt);

    doc.day = formatDate(date, 'dd', 'uk_UA');
    doc.month = formatDate(date, 'MM', 'uk_UA');
    doc.year = formatDate(date, 'yyyy', 'uk_UA');

    doc.rowLength = settings.rowLength;
    doc.isQZMode = settings.connectionMethod === PrinterConnectionMethod.QZTray;

    const data = new ReceiptPrinterData(Receipt.Waste, doc);

    data.settings = settings;

    return data;
  }

  //#region Move
  async move(move: Move): Promise<ReceiptPrinterData> {
    const settings = await this.receiptPrinterStorageService.getSettings();

    const doc = new PrintMove();

    doc.date = formatDate(move.createdAt, DATE_FORMAT, 'uk_UA');

    doc.senderName = move.shop != null ? move.shop.name : move.providerName;

    doc.senderName = doc.senderName ?? '';
    doc.recipientName = move.toShop.name ?? '';

    doc.products = [];

    let totalSum = 0;

    move.moveIngredients.forEach((moveIngredient) => {
      const product = new PrintProductItem();

      product.name = moveIngredient.ingredient.name;
      product.quantity = moveIngredient.quantity.toString();
      product.type = moveIngredient.ingredient.measurement;
      product.price = moveIngredient.costPrice.toFixed(2);
      product.sum = (
        moveIngredient.quantity * moveIngredient.costPrice
      ).toFixed(2);

      totalSum += moveIngredient.quantity * moveIngredient.costPrice;

      doc.products.push(product);
    });

    doc.sum = `${totalSum.toFixed(2)} ${UAH}`;

    doc.rowLength = settings.rowLength;
    doc.isQZMode = settings.connectionMethod === PrinterConnectionMethod.QZTray;

    const data = new ReceiptPrinterData(Receipt.Move, doc);

    data.settings = settings;

    return data;
  }

  //#region Refill
  async refill(refill: Refill): Promise<ReceiptPrinterData> {
    const settings = await this.receiptPrinterStorageService.getSettings();

    const doc = new PrintRefill();

    doc.name = `${refill.name.includes('Надходження') ? '' : 'Надходження № '}${
      refill.name
    } від`;

    doc.date = formatDate(refill.createdAt, DATE_FORMAT, 'uk_UA');

    doc.providerName = refill.providerName ?? '';
    doc.shopName = refill.shop.name ?? '';

    doc.products = [];

    let totalSum = 0;

    refill.refillIngredients.forEach((refillIngredient) => {
      const product = new PrintProductItem();

      product.name = refillIngredient.ingredient.name;
      product.quantity = refillIngredient.quantity.toString();
      product.type = refillIngredient.ingredient.measurement;
      product.price = (
        refillIngredient.costPrice / refillIngredient.quantity
      ).toFixed(2);
      product.sum = refillIngredient.costPrice.toFixed(2);

      totalSum += refillIngredient.costPrice;

      doc.products.push(product);
    });

    doc.amount = `${totalSum.toFixed(2)} ${UAH}`;
    doc.amountByWords = toCurrencyWords(totalSum, { capitalize: true });

    if (refill.paidAmount > 0) {
      doc.paidAmount = `${refill.paidAmount.toFixed(2)} ${UAH}`;
      doc.paidAmountByWords = toCurrencyWords(refill.paidAmount, {
        capitalize: true,
      });
    } else {
      doc.paidAmount = '';
      doc.paidAmountByWords = '';
    }

    doc.rowLength = settings.rowLength;
    doc.isQZMode = settings.connectionMethod === PrinterConnectionMethod.QZTray;

    const data = new ReceiptPrinterData(Receipt.Refill, doc);

    data.settings = settings;

    return data;
  }

  //#region Refill Sale Prices
  async refillSalePrices(refill: Refill): Promise<ReceiptPrinterData> {
    const settings = await this.receiptPrinterStorageService.getSettings();
    const shop = this.cachedDataService.getShop();
    const user = this.cachedDataService.getUser();

    const doc = new PrintRefillSalePrices();

    doc.refillDate = formatDate(refill.createdAt, DATE_FORMAT, 'uk_UA');

    doc.providerName = refill.providerName ?? '';

    doc.shopName = shop.name;
    doc.userName = user.name;

    doc.products = [];

    refill.refillIngredients.forEach((refillIngredient) => {
      const product = new PrintProductItem();

      product.name = refillIngredient.ingredient.name;
      product.type = refillIngredient.ingredient.measurement;

      product.productPrice = refillIngredient.productPrice
        ? refillIngredient.productPrice.toFixed(2)
        : '';

      product.salePrice = refillIngredient.salePrice
        ? refillIngredient.salePrice.toFixed(2)
        : '';

      product.priceVector = `${product.productPrice} => ${product.salePrice}`;

      doc.products.push(product);
    });

    doc.rowLength = settings.rowLength;
    doc.isQZMode = settings.connectionMethod === PrinterConnectionMethod.QZTray;

    const data = new ReceiptPrinterData(Receipt.RefillSalePrices, doc);

    data.settings = settings;

    return data;
  }

  //#region Tax Report
  async taxReport(report: PRroZReport): Promise<ReceiptPrinterData> {
    const settings = await this.receiptPrinterStorageService.getSettings();

    const doc = new PrintPRroZReport();

    doc.companyName = report.companyName;
    doc.shopName = report.shopName;
    doc.shopAddress = report.shopAddress;
    doc.IPN = report.IPN > '' ? `${IPN_CAPTION} ${report.IPN}` : '';
    doc.EDRPOU = report.EDRPOU ? `${EDRPOU_CAPTION} ${report.EDRPOU}` : '';

    doc.reportNumber = report.reportNumber?.toString() ?? '';
    doc.reportName =
      report.taxNumber > '' ? Z_REPORT_CAPTION : X_REPORT_CAPTION;

    if (report.taxNumber > '' && doc.reportNumber > '') {
      doc.reportName = `${doc.reportName} №${doc.reportNumber}`;
    }

    doc.prroFiscalNumber = `${PRRO_FISCAL_NUMBER_CAPTION}: ${report.prroFiscalNumber}`;
    doc.checkFiscalNumber =
      report.taxNumber > ''
        ? `${CHECK_FISCAL_NUMBER_CAPTION}: ${report.taxNumber}`
        : '';

    doc.date = formatDate(report.date, TAX_DATE_FORMAT, 'uk_UA');
    doc.taxNumber = report.taxNumber;
    doc.fiscalType = report.fiscalType;

    doc.startedAt =
      report.startedAt != null
        ? formatDate(report.startedAt, TAX_DATE_FORMAT, 'uk_UA')
        : '';

    doc.finishedAt =
      report.finishedAt != null
        ? formatDate(report.finishedAt, TAX_DATE_FORMAT, 'uk_UA')
        : '';

    doc.lastDocLocalNumber = report.lastDocLocalNumber?.toString() ?? '';
    doc.lastDocFiscalNumber = report.lastDocFiscalNumber ?? '';

    doc.discount = report.discount?.toFixed(2) ?? '';

    doc.realizCount = report.realizCount.toFixed();
    doc.realizCashSum = report.realizCashSum.toFixed(2);
    doc.realizCardSum = report.realizCardSum.toFixed(2);
    doc.realizOtherSum = report.realizOtherSum.toFixed(2);
    doc.realizNoRoundSum = report.realizNoRoundSum.toFixed(2);
    doc.realizRoundSum = report.realizRoundSum.toFixed(2);
    doc.realizSum = report.realizSum.toFixed(2);

    doc.returnCount = report.returnCount.toFixed();
    doc.returnCashSum = report.returnCashSum.toFixed(2);
    doc.returnCardSum = report.returnCardSum.toFixed(2);
    doc.returnOtherSum = report.returnOtherSum.toFixed(2);
    doc.returnNoRoundSum = report.returnNoRoundSum.toFixed(2);
    doc.returnRoundSum = report.returnRoundSum.toFixed(2);
    doc.returnSum = report.returnSum.toFixed(2);

    doc.serviceInput = report.serviceInput.toFixed(2);
    doc.serviceOutput = report.serviceOutput.toFixed(2);
    doc.cashRemain = report.cashRemain.toFixed(2);

    report.realizPayments.forEach((payment) => {
      const paymentItem = new PrintPRroZReportPayment();

      paymentItem.name = payment.name;
      paymentItem.sum = payment.sum.toFixed(2);

      doc.realizPayments.push(paymentItem);
    });

    report.returnPayments.forEach((payment) => {
      const paymentItem = new PrintPRroZReportPayment();

      paymentItem.name = payment.name;
      paymentItem.sum = payment.sum.toFixed(2);

      doc.returnPayments.push(paymentItem);
    });

    report.realizTaxes.forEach((tax) => {
      const taxItem = new PrintPRroZReportTax();

      taxItem.volume = `${TAX_VOLUME_CAPTION} ${tax.letter}`;
      taxItem.sourceSum = tax.sourceSum.toFixed(2);
      taxItem.name = `${tax.name} ${tax.letter} ${tax.prc}%`;
      taxItem.sum = tax.sum.toFixed(2);

      doc.realizTaxes.push(taxItem);
    });

    report.returnTaxes.forEach((tax) => {
      const taxItem = new PrintPRroZReportTax();

      taxItem.volume = `${TAX_VOLUME_CAPTION} ${tax.letter}`;
      taxItem.sourceSum = tax.sourceSum.toFixed(2);
      taxItem.name = `${tax.name} ${tax.letter} ${tax.prc}%`;
      taxItem.sum = tax.sum.toFixed(2);

      doc.returnTaxes.push(taxItem);
    });

    doc.rowLength = settings.rowLength;
    doc.isQZMode = settings.connectionMethod === PrinterConnectionMethod.QZTray;

    const data = new ReceiptPrinterData(Receipt.TaxReport, doc);

    data.settings = settings;

    return data;
  }

  //#region Shift Totals
  async shiftTotals(shift: Shift): Promise<ReceiptPrinterData> {
    const settings = await this.receiptPrinterStorageService.getSettings();
    const shop = this.cachedDataService.getShop();

    const doc = new PrintShift();

    doc.shopName = shop.name;
    doc.createdAt = formatDate(shift.createdAt, TAX_DATE_FORMAT, 'uk_UA');
    doc.closedAt =
      doc.closedAt == null
        ? ''
        : formatDate(shift.createdAt, TAX_DATE_FORMAT, 'uk_UA');

    shift.shiftUsers.forEach((shiftUser) => {
      const shiftUserItem = new PrintShiftUser();

      shiftUserItem.name = shiftUser.user?.name ?? '';
      shiftUserItem.createdAt =
        shift.shiftUsers.length > 1
          ? formatDate(shiftUser.createdAt, TAX_DATE_FORMAT, 'uk_UA')
          : '';

      if (shiftUser.totals != null) {
        const totals = this.getPrintShiftTotals(shiftUser.totals);

        shiftUserItem.totals = totals;
      }

      doc.shiftUsers.push(shiftUserItem);
    });

    doc.totals = this.getPrintShiftTotals(shift.totals);

    doc.rowLength = settings.rowLength;
    doc.isQZMode = settings.connectionMethod === PrinterConnectionMethod.QZTray;

    const data = new ReceiptPrinterData(Receipt.ShiftTotals, doc);

    data.settings = settings;

    return data;
  }

  private getPrintShiftTotals(totals: ShiftTotals): PrintShiftTotals {
    const printTotals = new PrintShiftTotals();

    printTotals.cashRemain = totals.cashRemain.toFixed(2);

    printTotals.salesSummary = totals.salesSummary.toFixed(2);
    printTotals.salesCashSummary = totals.salesCashSummary.toFixed(2);
    printTotals.salesCardSummary = totals.salesCardSummary.toFixed(2);
    printTotals.salesDiscountSummary = (
      totals.salesDiscount + totals.saleReturnsDiscount
    ).toFixed(2);

    printTotals.salesCount = totals.salesCount.toFixed();
    printTotals.salesCashAmount = totals.salesCashAmount.toFixed(2);
    printTotals.salesCardAmount = totals.salesCardAmount.toFixed(2);
    printTotals.salesDiscount = totals.salesDiscount.toFixed(2);
    printTotals.salesTotalAmount = (
      totals.salesCashAmount + totals.salesCardAmount
    ).toFixed(2);

    printTotals.saleReturnsCount = totals.saleReturnsCount.toFixed();
    printTotals.saleReturnsCashAmount = totals.saleReturnsCashAmount.toFixed(2);
    printTotals.saleReturnsCardAmount = totals.saleReturnsCardAmount.toFixed(2);
    printTotals.saleReturnsDiscount = totals.saleReturnsDiscount.toFixed(2);
    printTotals.saleReturnsTotalAmount = (
      totals.saleReturnsCashAmount + totals.saleReturnsCardAmount
    ).toFixed(2);

    printTotals.cashDeposits = totals.cashDeposits.toFixed(2);
    printTotals.cashIncasations = totals.cashIncasations.toFixed(2);
    printTotals.cardIncasations = totals.cardIncasations.toFixed(2);
    printTotals.cashFinanceExpenses = totals.cashFinanceExpenses.toFixed(2);

    printTotals.refillsCount = totals.refillsCount.toFixed();
    printTotals.refillsAmount = totals.refillsAmount.toFixed(2);
    printTotals.refillsPaidCashAmount = totals.refillsPaidCashAmount.toFixed(2);

    printTotals.wastesCount = totals.wastesCount.toFixed();
    printTotals.wastesAmount = totals.wastesAmount.toFixed(2);
    printTotals.wasteReturnsCount = totals.wasteReturnsCount.toFixed();
    printTotals.wasteReturnsAmount = totals.wasteReturnsAmount.toFixed(2);
    printTotals.wasteReturnsReceivedCashAmount =
      totals.wasteReturnsReceivedCashAmount.toFixed(2);

    printTotals.movesOutCount = totals.movesOutCount.toFixed();
    printTotals.movesInCount = totals.movesInCount.toFixed();
    printTotals.movesInFromStockCount = totals.movesInFromStockCount.toFixed();

    return printTotals;
  }

  //#region Tax Service Doc
  async taxServiceDoc(transaction: Transaction): Promise<ReceiptPrinterData> {
    const settings = await this.receiptPrinterStorageService.getSettings();
    const prro = this.cachedDataService.getPRRO();

    const doc = new PrintPRroServiceDoc();

    doc.companyName = prro.companyName ?? '';
    doc.shopName = prro.shopName ?? '';
    doc.shopAddress = prro.shopAddress ?? '';
    doc.IPN = prro.companyIPN > '' ? `${IPN_CAPTION} ${prro.companyIPN}` : '';
    doc.EDRPOU =
      prro.companyEDRPOU > '' ? `${EDRPOU_CAPTION} ${prro.companyEDRPOU}` : '';

    doc.prroFiscalNumber = prro.fiscalNumber.toString();

    doc.date = formatDate(transaction.createdAt, TAX_DATE_FORMAT, 'uk_UA');

    doc.name =
      transaction.type === TransactionType.Deposit
        ? SERVICE_INPUT_CAPTION
        : SERVICE_OUTPUT_CAPTION;

    doc.amount = transaction.amount.toFixed(2);

    doc.prroOnlineStatus = `${PRRO_ONLINE_STATUS_CAPTION}: ${doc.prroOnlineStatus}`;

    let isTestMode = false;

    if (transaction.prro != null) {
      isTestMode = transaction.prro?.testMode ?? false;

      doc.prroFiscalNumber = (transaction.prro?.fiscalNumber ?? '').toString();
      doc.prroLocalNumber = (transaction.prro?.localNumber ?? '').toString();
    } else {
      isTestMode = prro?.testMode ?? false;

      doc.prroFiscalNumber = (prro?.fiscalNumber ?? '').toString();
      doc.prroLocalNumber = (prro?.localNumber ?? '').toString();
    }

    doc.prroFiscalNumber = `${PRRO_FISCAL_NUMBER_CAPTION}: ${doc.prroFiscalNumber}`;
    doc.checkFiscalNumber = `${CHECK_FISCAL_NUMBER_CAPTION} ${transaction.prroTaxNumber}`;
    doc.checkLocalNumber = (transaction.prroLocalNumber ?? '').toString();
    doc.checkType =
      transaction.prroLocalNumber == null ||
      (transaction.prroTaxNumber ?? '') === ''
        ? ''
        : isTestMode
        ? TEST_FISCAL_CHECK_CAPTION
        : FISCAL_CHECK_CAPTION;

    doc.isFiscal = doc.checkFiscalNumber > '';

    doc.rowLength = settings.rowLength;
    doc.isQZMode = settings.connectionMethod === PrinterConnectionMethod.QZTray;

    const data = new ReceiptPrinterData(Receipt.TaxServiceDoc, doc);

    data.settings = settings;

    return data;
  }
}
