import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AUTH_URL } from '../constants/auth-url.const';
import { AuthService } from '../services/auth.service';
import { CachedDataService } from '../services/cached-data.service';
import { Events } from '../services/events.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private events: Events,
    private authService: AuthService,
    private cachedDataService: CachedDataService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.endsWith(AUTH_URL)) {
      return next.handle(req);
    }

    if (req.url.includes('settings/network-state') && this.authService.token) {
      req = req.clone({
        headers: new HttpHeaders({
          'Cache-Control':
            'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          Pragma: 'no-cache',
          Expires: '0',
          Authorization: `Bearer ${this.authService.token.accessToken}`,
        }),
      });
      return next.handle(req);
    }

    return this.authService.verifyToken().pipe(
      switchMap(() => {
        if (this.authService.token) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${this.authService.token.accessToken}`,
            },
          });
        }

        return next.handle(req);
      }),
      tap(
        () => {},
        (err) => {
          const appSettings = this.cachedDataService.getAppSettings();

          if (err.status === 401 && !err.url.includes(appSettings.dpsPRroUrl)) {
            this.events.publish('auth:error');

            setTimeout(() => {
              this.authService.removeToken();
            }, 500);
          }
        },
      ),
    );
  }
}
