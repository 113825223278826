<ion-list [inset]="true" lines="full">
  <ion-list-header>
    <ion-label>Сервіс QZ Tray</ion-label>
  </ion-list-header>

  <ion-item button detail="false" (click)="downloadQzTray()">
    <ion-icon slot="start" name="cloud-download-outline" color="secondary"></ion-icon>
    <ion-label>Завантажити застосунок</ion-label>
  </ion-item>

  <ion-item button detail="false" (click)="downloadQzCert()">
    <ion-icon slot="start" name="document-lock-outline" color="secondary"></ion-icon>
    <ion-label>Завантажити сертифікат</ion-label>
  </ion-item>

  <ion-item button detail="false" (click)="launchQzTray()">
    <ion-icon slot="start" name="play-circle-outline" color="secondary"></ion-icon>
    <ion-label>Запустити застосунок</ion-label>
  </ion-item>
</ion-list>
