import { Component, Input, OnInit } from '@angular/core';

import { DATETIME_FORMAT, TIME_FORMAT } from '../../core/constants/date.const';
import { UtilsService } from '../../core/services/utils.service';
import { PayFormCode } from '../../p-rro/fsco/enums/pay-form-code.enum';
import { Product } from '../../shop/products/product.model';

import { Sale } from './sale.model';

@Component({
  selector: 'bk-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss'],
})
export class SaleComponent implements OnInit {
  @Input() sale: Sale;
  @Input() fulldate = false;

  collapsed = false;
  payForm = PayFormCode;

  SALE_DATE_FORMAT = TIME_FORMAT;

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    if (this.fulldate) {
      this.SALE_DATE_FORMAT = DATETIME_FORMAT;
    }
  }

  toggleSale(event: MouseEvent): void {
    event.stopPropagation();

    this.collapsed = !this.collapsed;
  }

  name(product: Product): string {
    return this.utilsService.productNameView(product);
  }
}
