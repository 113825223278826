import { PrivatbankJsonMethod } from '../enums/method.enum';

import { PrivatbankJsonBaseRequest } from './base-request.model';

/**
 * Операція "Сервіс повернення"
 *
 * Операція є альтернативою операції "Повернення" (Refund) і призначена для скасування транзакції,
 * навіть якщо вона не міститься у поточному пакеті транзакцій, а була проведена на терміналі раніше
 *
 * Код сервісу та id мерчанта зашивається хардкодом і вказувати його не потрібно.
 */
export class PrivatbankJsonRefundServiceRequest extends PrivatbankJsonBaseRequest {
  /**
   * Масив додаткових параметрів для терміналу
   */
  params: {
    /**
     * Cума транзакції, що виконується
     *
     * У полі Amount допускається розділення суми (грн/коп) лише через знаки "." або ","
     */
    amount: string;

    /**
     * Унікальний номер транзакції, для якої виконується повернення
     */
    rrn: string;
  };

  constructor(amount: number, rrn: string) {
    super(PrivatbankJsonMethod.ServiceRefund);

    this.params = {
      rrn,
      amount: amount.toFixed(2),
    };
  }
}
