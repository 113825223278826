import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * This directive removes focus from the selectors after clicking on them
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'ion-button',
})
export class FocusRemoverDirective {
  constructor(private elRef: ElementRef) {}

  @HostListener('click')
  onClick(): void {
    this.elRef.nativeElement.blur();
  }
}
