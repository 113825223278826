import { DEFAULT_SETTINGS } from './receipt-printer.const';
import { Receipt } from './receipt.enum';
import { PrintDoc } from './types/print-doc.type';

export class ReceiptPrinterData {
  type = Receipt.Sale;
  settings = DEFAULT_SETTINGS;
  silentPrint = false;

  logo = '';

  doc: PrintDoc;

  constructor(type: Receipt, doc?: PrintDoc) {
    this.type = type;

    if (doc != null) {
      this.doc = doc;
    }
  }
}
