import { IntegrationSettings } from './integration-settings.interface';
import { Integration } from './integration.enum';

export const DEFAULT_ELEKS_PORT = 8095;
export const DEFAULT_ELEKS_INTERVAL = 5;
export const DEFAULT_ELEKS_SELECT_FIRST_BY_PRICE = false;
export const DEFAULT_ELEKS_AUTO_SALE = false;
export const DEFAULT_ELEKS_NO_DEFAULT = 'Немає';

export const DEFAULT_SETTINGS: IntegrationSettings = {
  name: Integration.None,
  eleksPort: DEFAULT_ELEKS_PORT,
  eleksInterval: DEFAULT_ELEKS_INTERVAL,
  eleksSelectFirstByPrice: DEFAULT_ELEKS_SELECT_FIRST_BY_PRICE,
  eleksAutoSale: DEFAULT_ELEKS_AUTO_SALE,
  eleksDefaultProductId: null,
};

export const TOAST_TITLE = 'Суміщення з інтеграцією';
