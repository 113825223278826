<div [ngClass]="{ 'border': collapsed && fulldate }">
  <div class="expanded-sale" [ngClass]="{ 'expanded-sale__active': collapsed }">
    <span class="expanded-sale__time">{{ sale.createdAt | date: SALE_DATE_FORMAT }}</span>
    <span class="expanded-sale__discount">{{ sale.discountSum | number: '1.2-2' }}</span>

    <span class="expanded-sale__sum">
      <ion-label>{{ (sale.paymentSum) | number: '1.2-2' }}</ion-label>
      <ion-icon [name]="sale.paymentIcon"></ion-icon>
    </span>

    <span class="expanded-sale__tax">
      <ion-icon *ngIf="sale.prroLocalNumber && sale.prroTaxNumber" name="qr-code-outline" color="medium"></ion-icon>
    </span>

    <span class="expanded-sale__details">
      <a (click)="toggleSale($event)">Деталі</a>

      <span class="expanded-sale__details__comment">
        <ion-label *ngIf="!sale.comment">&nbsp;</ion-label>
        <ion-icon *ngIf="sale.comment" name="chatbubble-ellipses-outline"></ion-icon>
      </span>
    </span>
  </div>

  <div class="collapsed" *ngIf="collapsed">
    <div class="collapsed-sale">
      <div class="collapsed-sale__header">
        <span class="collapsed-sale__header__name">Найменування</span>
        <span class="collapsed-sale__header__quantity">Кількість</span>
        <span class="collapsed-sale__header__sum">Сума, ₴</span>
      </div>

      <div class="odd-row" *ngFor="let saleProduct of sale.saleProducts">
        <div class="collapsed-sale__data">
          <span class="collapsed-sale__data__name">{{ name(saleProduct.product) }}</span>

          <span class="collapsed-sale__data__quantity">
            <bk-icon name="coupon" [customSize]="{ width: '18px', height: '18px' }" *ngIf="saleProduct.freeCup"></bk-icon>
            {{ saleProduct.quantity }}
          </span>

          <span class="collapsed-sale__data__sum">
            {{ saleProduct.cost | number: '1.2-2' }}
          </span>
        </div>
      </div>

      <div class="collapsed-sale__client" *ngIf="sale.client">
        <div class="collapsed-sale__client__name">
          <span class="collapsed-sale__client__name__caption">Клієнт:</span>
          <span class="collapsed-sale__client__name__data">{{ sale.client.name }}</span>
        </div>

        <div class="collapsed-sale__client__sum" *ngIf="sale.totalDiscount">
          <span class="collapsed-sale__client__sum__caption">Знижка:</span>
          <span class="collapsed-sale__client__sum__data">{{ sale.totalDiscount | currency:'UAH':'symbol-narrow' }}</span>
        </div>

        <div class="collapsed-sale__client__sum" *ngIf="sale.totalFreeCups">
          <span class="collapsed-sale__client__sum__caption">Знижка "Товар у подарунок":</span>
          <span class="collapsed-sale__client__sum__data">{{ sale.totalFreeCups | currency:'UAH':'symbol-narrow' }}</span>
        </div>

        <div class="collapsed-sale__client__sum" *ngIf="sale.totalBonus">
          <span class="collapsed-sale__client__sum__caption">Використано бонусів:</span>
          <span class="collapsed-sale__client__sum__data">{{ sale.totalBonus | currency:'UAH':'symbol-narrow' }}</span>
        </div>
      </div>

      <div class="collapsed-sale__comment" *ngIf="sale.comment">
        <span>{{ sale.comment }}</span>
      </div>
    </div>
  </div>
</div>
