import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Resource } from '../../../core/abstract/resource';
import { ObjectLiteral } from '../../../core/interfaces/object-literal';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { ShiftsService } from '../../../core/services/resources/shifts.service';

import { Move } from './move.model';

@Injectable({
  providedIn: 'root',
})
export class MovesService extends Resource<Move> {
  constructor(
    protected http: HttpClient,
    private platform: Platform,
    private cachedDataService: CachedDataService,
    private shiftsService: ShiftsService,
  ) {
    super(http, {
      path: '/moves',
    });
  }

  find(options: ObjectLiteral = {}): Observable<Move[]> {
    options = { ...options, shopId: this.cachedDataService.getShopId() };

    return super.find(options).pipe(
      map((moves) => moves.map((move) => this.transformFromBackend(move))),
      map((moves) => orderBy(moves, 'createdAt', 'desc')),
    );
  }

  findForApp(options: Partial<Move> | ObjectLiteral = {}): Observable<Move[]> {
    options = { ...options, shiftId: this.cachedDataService.getShift().id };

    return super.findForApp(options).pipe(
      map((moves) => moves.map((move) => this.transformFromBackend(move))),
      map((moves) => orderBy(moves, 'createdAt', 'desc')),
    );
  }

  get(id: number | string): Observable<Move> {
    return super.get(id).pipe(map((move) => this.transformFromBackend(move)));
  }

  create(body: Move): Observable<Move> {
    body.shopId = this.cachedDataService.getShopId();
    body.userId = this.cachedDataService.getUser().id;

    return super.create(this.transformToBackend(body));
  }

  private transformFromBackend(move: Move): Move {
    const isDesktop =
      !this.platform.is('cordova') &&
      !this.platform.is('android') &&
      !this.platform.is('ios');

    const shopId = this.cachedDataService.getShopId();
    const moveClone = cloneDeep(move);

    moveClone.createdAt = new Date(moveClone.createdAt);
    moveClone.providerName =
      moveClone.shopId === shopId
        ? moveClone.toShop.name
        : moveClone.stock != null
        ? `Склад компанії "${moveClone.stock.name}"`
        : moveClone.shop.name;

    moveClone.typeName =
      moveClone.shopId === shopId ? 'Відправлено' : 'Отримано';

    moveClone.canView = moveClone.shopId !== shopId;
    moveClone.canCopy = false;
    moveClone.canPrint = isDesktop;
    moveClone.canPrintLabel = false;
    moveClone.canUpdate = moveClone.shopId === shopId;
    moveClone.canDelete = moveClone.shopId === shopId;

    return moveClone;
  }

  private transformToBackend(move: Move): Move {
    const moveClone = cloneDeep(move);

    moveClone.shiftId = this.shiftsService.getCurrentShiftId();

    return moveClone;
  }
}
