import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';

import { ToastService } from '../../core/services/toast.service';
import { SaleProduct } from '../../sales/sale/sale-product.model';

@Component({
  selector: 'bk-excise-product-dialog',
  templateUrl: './excise-product.dialog.html',
  styleUrls: ['./excise-product.dialog.scss'],
})
export class ExciseProductDialog implements OnInit {
  @ViewChild(IonInput) input: IonInput;

  @Input() saleProduct: SaleProduct;
  @Input() decrement = false;

  exciseLabels: string[] = [];
  exciseLabel = '';

  constructor(
    private modalCtrl: ModalController,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    if (this.saleProduct.exciseLabel) {
      this.exciseLabels = this.saleProduct.exciseLabel.split(';');
    }
  }

  ionViewDidEnter(): void {
    this.setFocusOnInput();
  }

  selectAll(): void {
    this.input.getInputElement().then((data) => {
      data.select();
    });
  }

  deleteLabel(exciseLabel: string): void {
    this.exciseLabels = this.exciseLabels.filter((el) => el !== exciseLabel);

    this.setFocusOnInput();
  }

  convertValue(): void {
    let newLabel = '';

    for (let i = 0; i < this.exciseLabel.length; i += 1) {
      newLabel += [this.convertKey(this.exciseLabel.charAt(i))];
    }

    this.exciseLabel = newLabel;
  }

  finishInput(): void {
    setTimeout(() => {
      this.checkInput();

      if (this.exciseLabels.length >= this.saleProduct.quantity) {
        this.confirm();
      }
    }, 250);
  }

  private checkInput(): void {
    if (!this.exciseLabel) {
      return;
    }

    if (this.exciseLabels.includes(this.exciseLabel)) {
      this.toastService.presentWarning(
        'Акцизна марка',
        `Штрихкод "${this.exciseLabel}" був доданий раніше`,
      );

      this.exciseLabel = '';

      return;
    }

    this.exciseLabels.push(this.exciseLabel);

    this.exciseLabel = '';
  }

  async confirm(): Promise<void> {
    if (this.exciseLabel) {
      this.checkInput();
    }

    this.saleProduct.exciseLabel = this.exciseLabels.join(';');

    await this.modalCtrl.dismiss({ success: true });
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ success: false });
  }

  private setFocusOnInput(): void {
    setTimeout(() => {
      this.input.setFocus();
    }, 100);
  }

  private convertKey(key: string): string {
    const latin =
      "QWERTYUUIOP[]ASDFGHJKL;'ZXCVBNM,.qwertyuuiop[]asdfghjkl;'zxcvbnm,.";

    const cyrillic =
      'ЙЦУКЕНГҐШЩЗХЇФІВАПРОЛДЖЄЯЧСМИТЬБЮйцукенгґшщзхїфівапролджєячсмитьбю';

    if (cyrillic.indexOf(key) >= 0) {
      return latin[cyrillic.indexOf(key)];
    }

    return key;
  }
}
