import { PrivatbankJsonMethod } from '../enums/method.enum';

import { PrivatbankJsonBaseRequest } from './base-request.model';

/**
 * Операція "PingDevice"
 *
 * Команда не призначена для буквального "пінгування" обладнання на рівні TCP/IP
 * або для перевірки доступності COM порту.
 * Успішне виконання опереції свідчить про те, що термінал приймає JSON згідно з протоколом,
 * а також в даний момент вільний і готовий до виконання будь-якої операції.
 */
export class PrivatbankJsonPingDeviceRequest extends PrivatbankJsonBaseRequest {
  constructor() {
    super(PrivatbankJsonMethod.PingDevice);
  }
}
