import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import dataUriToBuffer from 'data-uri-to-buffer';
import * as gost89 from 'gost89';
import * as iconv from 'iconv-lite';
import * as jkurwa from 'jkurwa';
import { DateTime } from 'luxon';
import { timeout } from 'rxjs/operators';

import { CryptData } from './crypt-data.interface';
import { DocumentClassName } from './enums/document-class-name.enum';
import { DocumentRequestType } from './enums/document-request-type.enum';
import { PayFormCode } from './enums/pay-form-code.enum';
import { ShiftState } from './enums/shift-state.enum';
import { FSCOFormatService } from './fsco-format.service';
import {
  FISCAL_Z_REPORT_CAPTION,
  TEST_FISCAL_Z_REPORT_CAPTION,
} from './fsco.const';
import { CertSubject } from './interfaces/cert-subject.interface';
import { PRroCheckHeadData } from './interfaces/p-rro-check-head-data.interface';
import { RequestCommand } from './interfaces/requests/request-command.interface';
import { RequestDocInfoByLocalNum } from './interfaces/requests/request-doc-info-by-local-num.interface';
import { RequestDocInfoExt } from './interfaces/requests/request-doc-info-ext.interface';
import { RequestDocInfo } from './interfaces/requests/request-doc-info.interface';
import { RequestDocuments } from './interfaces/requests/request-documents.interface';
import { RequestLastShiftTotals } from './interfaces/requests/request-last-shift-totals.interface';
import { RequestShifts } from './interfaces/requests/request-shifts.interface';
import { RequestState } from './interfaces/requests/request-state.interface';
import { ShiftTotalsOrderType } from './interfaces/responses/items/shift-totals-order-type.interface';
import { ShiftTotalsTax } from './interfaces/responses/items/shift-totals-tax.interface';
import { ResponseCommon } from './interfaces/responses/response-common.interface';
import { ResponseDocInfoByLocalNum } from './interfaces/responses/response-doc-info-by-local-num.interface';
import { ResponseDocInfoExt } from './interfaces/responses/response-doc-info-ext.interface';
import { ResponseDocuments } from './interfaces/responses/response-documents.interface';
import { ResponseFSCO } from './interfaces/responses/response-fsco.interface';
import { ResponseLastShiftTotals } from './interfaces/responses/response-last-shift-totals.interface';
import { ResponseObjects } from './interfaces/responses/response-objects.interface';
import { ResponseOperators } from './interfaces/responses/response-operators.interface';
import { ResponsePRROState } from './interfaces/responses/response-prro-state.interface';
import { ResponseShifts } from './interfaces/responses/response-shifts.interface';
import { TSPErrorToast } from './interfaces/tsp-error-toast.type';
import { PRroCheck } from './models/p-rro-check.model';
import { PRroSaleTax } from './models/p-rro-sale-tax.model';
import { PRroTicket } from './models/p-rro-ticket.model';
import { PRroZReportPayForm } from './models/p-rro-z-report-pay-form.model';
import { PRroZReport } from './models/p-rro-z-report.model';
import { ResponseTicket } from './models/response-ticket.interface';

const DEFAULT_FSCO_URL = 'fs.tax.gov.ua';
const DEFAULT_CORS_PROXY_URL = 'https://cors.cashbox.in.ua';
const DEFAULT_FSCO_API_URL = `https://${DEFAULT_FSCO_URL}:8643/fs`;
const RESERVE_FSCO_API_URL = `http://${DEFAULT_FSCO_URL}:8609/fs`;

const FSCO_API_COMMAND_PATH = '/cmd';
const FSCO_API_DOCUMENT_PATH = '/doc';

const DEFAULT_TIMEOUT = 10 * 1000; // 10 seconds
const MIN_TIMEOUT = 5 * 1000; // 5 seconds
const STEP_TIMEOUT = 1 * 1000; // 1 second

const E_SIGN_ERROR = 'Помилка накладання УЕП';

@Injectable({
  providedIn: 'root',
})
export class FSCOService {
  private cryptData: CryptData | null = null;
  private subject: CertSubject | null = null;
  private box: jkurwa.Box | null = null;

  private fscoApiUrl = DEFAULT_FSCO_API_URL;
  private fscoReserveApiUrl = RESERVE_FSCO_API_URL;
  private corsProxyUrl = DEFAULT_CORS_PROXY_URL;

  private timeout_value = DEFAULT_TIMEOUT;
  private useTSP = true;

  private showTSPError: TSPErrorToast;

  constructor(
    protected http: HttpClient,
    private fscoFormatService: FSCOFormatService,
  ) {}

  isInit(): boolean {
    return (
      this.box != null && this.cryptData != null && this.cryptData.isCorrect()
    );
  }

  updateTSPStatus(value: boolean): void {
    this.useTSP = value;
  }

  //#region Init
  async init(
    cryptData: CryptData,
    urls: {
      corsProxyUrl?: string;
      fscoApiUrl?: string;
      fscoReserveApiUrl?: string;
    },
    options: {
      refresh: boolean;
      localMode?: boolean;
      useTSP?: boolean;
      showTSPError?: TSPErrorToast;
    },
  ): Promise<{ certSubject: CertSubject | null; report: string }> {
    const result: { certSubject: CertSubject | null; report: string } = {
      certSubject: null,
      report: '',
    };

    let box: jkurwa.Box | null = options.localMode ?? false ? null : this.box;

    this.corsProxyUrl = urls.corsProxyUrl ?? DEFAULT_CORS_PROXY_URL;
    this.fscoApiUrl = urls.fscoApiUrl ?? DEFAULT_FSCO_API_URL;
    this.fscoReserveApiUrl = urls.fscoReserveApiUrl ?? RESERVE_FSCO_API_URL;

    if (options.useTSP != null) {
      this.useTSP = options.useTSP;
    }

    if (options.showTSPError != null) {
      this.showTSPError = options.showTSPError;
    }

    try {
      if (
        (box == null || options.refresh) &&
        cryptData != null &&
        cryptData.isCorrect()
      ) {
        box = await this.getLocalBox(cryptData);
      }

      if (box != null) {
        if (!(options.localMode ?? false)) {
          this.box = box;
          this.cryptData = cryptData;
        }
      } else {
        result.report = 'Не вдалося розпакувати УЕП';

        return result;
      }

      const subject = this.getLocalSubject(box);

      if (!(options.localMode ?? false)) {
        this.subject = subject;
      }

      result.certSubject = subject;
    } catch (error) {
      result.report = `Не вдалося прочитати УЕП: ${this.convertJkurwaError(
        error,
      )}`;

      return result;
    }

    return result;
  }

  private async getLocalBox(cryptData: CryptData): Promise<jkurwa.Box> {
    const box = new jkurwa.Box({
      query: this.checkTSP,
      algo: gost89.compat.algos(),
    });

    const keyBuffer = dataUriToBuffer(cryptData.key.dataURL);

    box.load({
      keyBuffers: [keyBuffer],
      password: cryptData.password,
    });

    if (cryptData.cert.dataURL) {
      const certBuffer = dataUriToBuffer(cryptData.cert.dataURL);

      box.load({ certPem: certBuffer });
    }

    return box;
  }

  private checkTSP = (
    method: string,
    toUrl: string,
    headers: HttpHeaders,
    payload: Uint8Array,
    getTSPstampCb: (tspResponse: Buffer | null) => void,
  ) => {
    this.http
      .request(method, `${this.corsProxyUrl}/${toUrl}`, {
        headers,
        body: payload.buffer,
        responseType: 'arraybuffer',
      })
      .pipe(timeout(this.timeout_value))
      .toPromise()
      .then((response) => {
        this.timeout_value = Math.min(
          this.timeout_value + STEP_TIMEOUT,
          DEFAULT_TIMEOUT,
        );

        getTSPstampCb(Buffer.from(response));
      })
      .catch((problem) => {
        if (problem.status === 0 || problem.status === 404) {
          this.timeout_value = MIN_TIMEOUT;
        }

        if (problem.name === 'TimeoutError') {
          this.timeout_value = Math.max(
            this.timeout_value - STEP_TIMEOUT,
            MIN_TIMEOUT,
          );

          problem.status = 408;
          problem.statusText = 'Request Timeout';
        }

        getTSPstampCb(null);

        if (this.showTSPError != null) {
          const responseError = new HttpErrorResponse({
            error: problem,
            status: problem.status,
            statusText: problem.statusText,
            url: toUrl,
          });

          this.showTSPError(responseError, this.subject?.issuer);
        }
      });
  };

  private getLocalSubject(box: jkurwa.Box | null): CertSubject | null {
    let result: CertSubject | null = null;

    if (box == null) {
      return null;
    }

    let signKey = box?.keys.find(
      // Digital Signature, Non-Repudiation (c0)
      (key: any) =>
        key.priv &&
        (Buffer.from(key.cert.extension.keyUsage).toString('hex') ===
          '030206c0' || // КНЕДП АЦСК АТ КБ "ПРИВАТБАНК", КНЕДП - ІДД ДПС
          Buffer.from(key.cert.extension.keyUsage).toString('hex') ===
            '030200c0'), // КНЕДП ТОВ "Центр сертифікації ключів "Україна"
    );

    if (signKey == null) {
      signKey = box?.keys.find((key: any) => key.priv != null);
    }

    result = signKey.cert.subject;

    if (result != null && signKey.cert != null) {
      result.issuer = signKey.cert.issuer?.commonName;
      result.authorityInfoAccess = signKey.cert.extension?.authorityInfoAccess;
      result.subjectInfoAccess = signKey.cert.extension?.subjectInfoAccess;
      result.ipn = signKey.cert.extension?.ipn;

      result.keyId = signKey.keyid;

      result.subjectKeyId = Buffer.from(
        signKey.cert.extension.subjectKeyIdentifier,
      ).toString('hex');

      result.regNum =
        signKey.cert.subject?.organizationIdentifier?.split('-')?.[1];

      result.validTo = new Date(signKey.cert.valid.to);
    }

    return result;
  }

  private convertJkurwaError(error: any): string {
    const errorMessage = error?.toString();

    switch (errorMessage) {
      case 'Error: Cant load key from store, check password':
        return 'Не вдалося активувати ключ УЕП, перевірте пароль';

      case 'Error: Cant load key from store':
        return 'Не вдалося активувати ключ УЕП, перевірте обраний файл';

      case 'Error: No key-certificate pair found for given op sign and role stamp':
        return 'Не вдалося співставити ключ та сертифікат, спробуйте змінити роль ключа';

      default:
        return errorMessage;
    }
  }

  //#region Requests
  async getServerState(): Promise<ResponseFSCO<ResponseCommon>> {
    return this.sendCommand<ResponseCommon>(
      { Command: 'ServerState' },
      {
        sign: false,
      },
    );
  }

  async getObjects(): Promise<ResponseFSCO<ResponseObjects>> {
    return this.sendCommand<ResponseObjects>({ Command: 'Objects' });
  }

  async getOperators(): Promise<ResponseFSCO<ResponseOperators>> {
    return this.sendCommand<ResponseOperators>({ Command: 'Operators' });
  }

  async getPRROState(
    prroFiscalNumber: number,
    offlineSessionId: string = '',
    offlineSeed: string = '',
  ): Promise<ResponseFSCO<ResponsePRROState>> {
    const prroStateCommand: RequestState = {
      Command: 'TransactionsRegistrarState',
      NumFiscal: prroFiscalNumber,
      OfflineSeed: offlineSeed,
      OfflineSessionId: offlineSessionId,
      IncludeTaxObject: false,
    };

    return this.sendCommand<ResponsePRROState>(prroStateCommand);
  }

  async getShiftsFromTo(
    prroFiscalNumber: number,
    dateFrom: Date,
    dateTo: Date,
  ): Promise<ResponseFSCO<ResponseShifts>> {
    const shiftsCommand: RequestShifts = {
      Command: 'Shifts',
      NumFiscal: prroFiscalNumber,
      From: DateTime.fromJSDate(dateFrom).toISO(),
      To: DateTime.fromJSDate(dateTo).toISO(),
    };

    return this.sendCommand<ResponseShifts>(shiftsCommand);
  }

  async getLastShiftTotals(
    prroFiscalNumber: number,
  ): Promise<ResponseFSCO<ResponseLastShiftTotals>> {
    const lastShiftTotalsCommand: RequestLastShiftTotals = {
      Command: 'LastShiftTotals',
      NumFiscal: prroFiscalNumber,
    };

    return this.sendCommand<ResponseLastShiftTotals>(lastShiftTotalsCommand);
  }

  async getDocuments(
    prroFiscalNumber: number,
    shiftId?: number,
    openShiftFiscalNum?: string,
  ): Promise<ResponseFSCO<ResponseDocuments>> {
    let documentsCommand: RequestDocuments = {
      Command: 'Documents',
      NumFiscal: prroFiscalNumber,
    };

    if (shiftId != null) {
      documentsCommand = { ...documentsCommand, ShiftId: shiftId };
    } else if (openShiftFiscalNum != null) {
      documentsCommand = {
        ...documentsCommand,
        OpenShiftFiscalNum: openShiftFiscalNum,
      };
    }

    return this.sendCommand<ResponseDocuments>(documentsCommand);
  }

  async getDocumentInfoByLocalNum(
    prroFiscalNumber: number,
    docLocalNumber: number,
  ): Promise<ResponseFSCO<ResponseDocInfoByLocalNum>> {
    const documentCommand: RequestDocInfoByLocalNum = {
      Command: 'DocumentInfoByLocalNum',
      NumFiscal: prroFiscalNumber,
      NumLocal: docLocalNumber,
    };

    return this.sendCommand<ResponseDocInfoByLocalNum>(documentCommand);
  }

  async getDoc(
    prroFiscalNumber: number,
    docClass: string,
    docNumber: {
      fiscal?: string;
      local?: number;
    },
  ): Promise<ResponseFSCO<string>> {
    const docInfoCommand: RequestDocInfo = {
      Command: docClass,
      RegistrarNumFiscal: prroFiscalNumber,
      NumFiscal: docNumber.fiscal,
      NumLocal: docNumber.local,
    };

    return this.sendCommand<string>(docInfoCommand, {
      sign: docInfoCommand.Command.includes(DocumentClassName.ZRep),
      readSignedXML: true,
    });
  }

  async getDocExtXML(
    prroFiscalNumber: number,
    docClass: string,
    docNumber: {
      fiscal?: string;
      local?: number;
    },
    resultType: DocumentRequestType = DocumentRequestType.SignedBySenderAndServerXml,
  ): Promise<ResponseFSCO<ResponseDocInfoExt>> {
    const docInfoExtCommand: RequestDocInfoExt = {
      Command: `${docClass}Ext`,
      RegistrarNumFiscal: prroFiscalNumber,
      NumFiscal: docNumber.fiscal,
      NumLocal: docNumber.local,
      Type: resultType,
      SetTimestamp: true,
      AcquireCabinetUrl: true,
    };

    return this.sendCommand<ResponseDocInfoExt>(docInfoExtCommand, {
      sign: docInfoExtCommand.Command.includes(DocumentClassName.ZRep),
    });
  }

  //#region Z Report
  async getZReport(prro: PRroCheckHeadData): Promise<PRroZReport> {
    const lastShiftTotalsResponse = await this.getLastShiftTotals(
      prro.fiscalNumber,
    );

    const lastShiftTotals = lastShiftTotalsResponse.data;

    const zRepData = new PRroZReport();

    zRepData.opened = lastShiftTotals?.ShiftState === ShiftState.Opened;
    zRepData.companyName = prro.companyName ?? '';
    zRepData.EDRPOU = prro.companyEDRPOU ?? '';
    zRepData.IPN = prro.companyIPN ?? '';
    zRepData.shopName = prro.shopName ?? '';
    zRepData.shopAddress = prro.shopAddress ?? '';
    zRepData.prroFiscalNumber = prro.fiscalNumber;
    zRepData.fiscalType = prro.testMode
      ? TEST_FISCAL_Z_REPORT_CAPTION
      : FISCAL_Z_REPORT_CAPTION;

    if (lastShiftTotals?.Opened != null) {
      zRepData.startedAt = new Date(lastShiftTotals.Opened);
    }

    if (lastShiftTotals?.Closed != null) {
      zRepData.finishedAt = new Date(lastShiftTotals.Closed);
    }

    if (lastShiftTotals?.Totals != null) {
      if (lastShiftTotals.Totals?.Real != null) {
        this.calcRealTotals(lastShiftTotals.Totals?.Real, zRepData);
      }

      if (lastShiftTotals.Totals?.Ret != null) {
        this.calcRetTotals(lastShiftTotals.Totals?.Ret, zRepData);
      }

      zRepData.serviceInput = lastShiftTotals.Totals.ServiceInput;
      zRepData.serviceOutput = lastShiftTotals.Totals.ServiceOutput;
    }

    return zRepData;
  }

  private calcRealTotals(
    Real: ShiftTotalsOrderType,
    zRepData: PRroZReport,
  ): void {
    zRepData.realizCount = Real.OrdersCount;
    zRepData.realizNoRoundSum = Real.NoRndSum;
    zRepData.realizRoundSum = Real.RndSum;

    zRepData.realizCashSum += Real.PayForm?.filter(
      (payment) => payment.PayFormCode === PayFormCode.Cash,
    ).reduce((sum, payment) => (sum += payment.Sum), 0);

    zRepData.realizCardSum += Real.PayForm?.filter(
      (payment) => payment.PayFormCode === PayFormCode.Card,
    ).reduce((sum, payment) => (sum += payment.Sum), 0);

    zRepData.realizOtherSum += Real.PayForm?.filter(
      (payment) =>
        payment.PayFormCode !== PayFormCode.Cash &&
        payment.PayFormCode !== PayFormCode.Card,
    ).reduce((sum, payment) => (sum += payment.Sum), 0);

    Real.PayForm?.forEach((payment) => {
      const payForm = new PRroZReportPayForm();

      payForm.code = payment.PayFormCode;
      payForm.name = payment.PayFormName;
      payForm.sum = payment.Sum;

      zRepData.realizPayments.push(payForm);
    });

    Real.Tax?.forEach((tax) => {
      const taxItem = this.getTaxItem(tax);

      zRepData.realizTaxes.push(taxItem);
    });
  }

  private calcRetTotals(
    Ret: ShiftTotalsOrderType,
    zRepData: PRroZReport,
  ): void {
    zRepData.returnCount = Ret.OrdersCount;
    zRepData.returnNoRoundSum = Ret.NoRndSum;
    zRepData.returnRoundSum = Ret.RndSum;

    zRepData.returnCashSum += Ret.PayForm?.filter(
      (payment) => payment.PayFormCode === PayFormCode.Cash,
    ).reduce((sum, payment) => (sum += payment.Sum), 0);

    zRepData.returnCardSum += Ret.PayForm?.filter(
      (payment) => payment.PayFormCode === PayFormCode.Card,
    ).reduce((sum, payment) => (sum += payment.Sum), 0);

    zRepData.returnOtherSum += Ret.PayForm?.filter(
      (payment) =>
        payment.PayFormCode !== PayFormCode.Cash &&
        payment.PayFormCode !== PayFormCode.Card,
    ).reduce((sum, payment) => (sum += payment.Sum), 0);

    Ret.PayForm?.forEach((payment) => {
      const payForm = new PRroZReportPayForm();

      payForm.code = payment.PayFormCode;
      payForm.name = payment.PayFormName;
      payForm.sum = payment.Sum;

      zRepData.returnPayments.push(payForm);
    });

    Ret.Tax?.forEach((tax) => {
      const taxItem = this.getTaxItem(tax);

      zRepData.returnTaxes.push(taxItem);
    });
  }

  private getTaxItem(tax: ShiftTotalsTax): PRroSaleTax {
    const taxItem = new PRroSaleTax();

    taxItem.type = tax.Type;
    taxItem.name = tax.Name;
    taxItem.letter = tax.Letter;
    taxItem.prc = tax.Prc;
    taxItem.turnover = tax.Turnover;
    taxItem.turnoverDiscount = tax.TurnoverDiscount;
    taxItem.sourceSum = tax.SourceSum;
    taxItem.sum = tax.Sum;

    return taxItem;
  }

  //#region Send Command
  async sendCommand<T>(
    command:
      | RequestCommand
      | RequestState
      | RequestDocInfo
      | RequestDocInfoExt
      | RequestShifts
      | RequestDocuments
      | RequestLastShiftTotals
      | RequestDocInfoByLocalNum,
    options: { sign: boolean; readSignedXML?: boolean } = { sign: true },
  ): Promise<ResponseFSCO<T>> {
    const { payload: signedCommand, report } = options.sign
      ? await this.signText(JSON.stringify(command))
      : { payload: Buffer.from(JSON.stringify(command)), report: '' };

    if (signedCommand == null) {
      return {
        rawData: Buffer.from(''),
        data: null,
        statusCode: 0,
        statusMessage: `${E_SIGN_ERROR}: ${report}`,
      };
    }

    const response = await this.send<T>(FSCO_API_COMMAND_PATH, signedCommand);

    if (response.statusCode === 200) {
      if (options.readSignedXML ?? false) {
        response.data = this.readSignedXML(response.rawData) as unknown as T;
      } else {
        try {
          response.data = JSON.parse(
            Buffer.from(response.rawData).toString(),
          ) as T;
        } catch (error) {
          response.data = null;
        }
      }
    }

    return response;
  }

  //#region Send Document
  async sendDocument(
    docData: PRroCheck | PRroZReport,
    prro: PRroCheckHeadData,
  ): Promise<ResponseFSCO<PRroTicket>> {
    const docXML =
      docData instanceof PRroZReport
        ? this.fscoFormatService.getZReportXML(prro, docData)
        : this.fscoFormatService.getCheckXML(prro, docData);

    const { payload: signedDoc, report } = await this.signText(docXML, {
      useWin1251: true,
    });

    if (signedDoc == null) {
      return {
        rawData: Buffer.from(''),
        data: null,
        statusCode: 0,
        statusMessage: `${E_SIGN_ERROR}: ${report}`,
      };
    }

    const response = await this.send<PRroTicket>(
      FSCO_API_DOCUMENT_PATH,
      signedDoc,
    );

    if (response.statusCode === 200) {
      const ticket = new ResponseTicket(this.readSignedXML(response.rawData))
        .TICKET;

      response.data = ticket;
    }

    return response;
  }

  //#region Sign
  private async signText(
    data: string,
    options: {
      useWin1251: boolean;
    } = {
      useWin1251: false,
    },
  ): Promise<{ payload: Buffer | null; report: string }> {
    const result: { payload: Buffer | null; report: string } = {
      payload: null,
      report: '',
    };

    let content: Buffer;

    if (options.useWin1251) {
      content = iconv.encode(data, 'win1251');
    } else {
      content = Buffer.from(data, 'utf8');
    }

    if (this.box == null) {
      try {
        this.box = await this.getLocalBox(this.cryptData ?? new CryptData());
      } catch (error) {
        result.report = this.convertJkurwaError(error);

        return result;
      }
    }

    const headers = null;
    const tsp = options.useWin1251 ? 'signature' : 'none';

    const commands = {
      op: 'sign',
      tax: false,
      detached: false,
      role: this.cryptData?.role ?? '',
    };

    try {
      result.payload = await this.box?.pipe(
        content,
        [{ ...commands, tsp }],
        headers,
      );
    } catch (error) {
      let signError = error;

      if (!this.useTSP) {
        try {
          result.payload = await this.box?.pipe(content, [commands], headers);
        } catch (error) {
          signError = error;
        }
      }

      result.report = this.parseSignError(signError, options);
    }

    return result;
  }

  private parseSignError(
    signError: any,
    options: { useWin1251: boolean },
  ): string {
    const message = this.convertJkurwaError(
      signError ?? !this.useTSP
        ? `Виникла проблема з підписанням ${
            options.useWin1251 ? 'документа' : 'команди ПРРО'
          }`
        : `Не вдалося підписати ${
            options.useWin1251 ? 'документ' : 'команду ПРРО'
          }`,
    );

    if (this.showTSPError != null && options.useWin1251) {
      this.showTSPError(message);
    }

    return message;
  }

  readSignedXML(
    signedData: Buffer,
    options: { returnWin1251base64: boolean } = { returnWin1251base64: false },
  ): string {
    let result: string;

    try {
      const message = new jkurwa.models.Message(signedData);

      result = options.returnWin1251base64
        ? message.info.contentInfo.content.toString('base64')
        : iconv
            .encode(
              iconv.decode(message.info.contentInfo.content, 'win1251'),
              'utf8',
            )
            .toString();
    } catch (e) {
      throw new Error(`Error read message: ${e}`);
    }

    return result;
  }

  //#region Send
  private async send<T>(
    typePath: string,
    payload: Buffer,
  ): Promise<ResponseFSCO<T>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
    });

    return this.http
      .request('post', `${this.corsProxyUrl}/${this.fscoApiUrl}${typePath}`, {
        headers,
        body: payload.buffer,
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(timeout(this.timeout_value))
      .toPromise<HttpResponse<ArrayBuffer>>()
      .then((response) => {
        this.timeout_value = Math.min(
          this.timeout_value + STEP_TIMEOUT,
          DEFAULT_TIMEOUT,
        );

        return {
          rawData:
            response.body != null
              ? Buffer.from(response.body)
              : Buffer.from(''),
          data: null,
          statusCode: response.status,
          statusMessage: response.statusText,
        };
      })
      .catch((problem) => {
        if (problem.status === 0 || problem.status === 404) {
          this.timeout_value = MIN_TIMEOUT;
        }

        if (problem.status === 404) {
          this.fscoApiUrl = this.fscoReserveApiUrl;
        }

        if (
          typePath === FSCO_API_DOCUMENT_PATH &&
          problem.name === 'TimeoutError'
        ) {
          this.timeout_value = Math.max(
            this.timeout_value - STEP_TIMEOUT,
            MIN_TIMEOUT,
          );

          problem.status = 408;
          problem.statusText = 'Request Timeout';
        }

        if (problem.error instanceof Buffer) {
          problem.error = Buffer.from(problem.error).toString();
        } else if (problem.error instanceof ArrayBuffer) {
          problem.error = new TextDecoder('utf-8').decode(
            new Uint8Array(problem.error),
          );
        }

        return {
          rawData: Buffer.from(''),
          data: null,
          statusCode: problem.status,
          statusMessage: problem.statusText,
          error: problem,
        };
      });
  }
}
