import { DOCUMENT } from '@angular/common';
import { Directive, Inject } from '@angular/core';
import { config, IConfig, MaskDirective, MaskService } from 'ngx-mask';

@Directive({
  selector: 'ion-input[mask]', // tslint:disable-line:directive-selector
  providers: [MaskService],
})
export class IonicNgxMaskDirective extends MaskDirective {
  constructor(
    // @ts-ignore
    @Inject(DOCUMENT) private _ionDocument: any,
    // @ts-ignore
    private _ionMaskService: MaskService,
    @Inject(config) protected _config: IConfig,
  ) {
    super(_ionDocument, _ionMaskService, _config);
  }
}
