import { PRRO_NAME } from '../../../settings.const';
import { SERVICE_DOC_AMOUNT_CAPTION } from '../receipt.const';

import { PrintReceipt } from './print-receipt.model';

export class PrintPRroServiceDoc extends PrintReceipt {
  id: number;
  date: string;

  companyName: string;
  shopName: string;
  shopAddress: string;
  IPN: string;
  EDRPOU: string;

  name: string;
  amount: string;

  isFiscal: boolean;
  prroOnlineStatus: string;
  prroFiscalNumber: string;
  prroLocalNumber: string;
  checkFiscalNumber: string;
  checkLocalNumber: string;
  checkType: string;

  receipt(): string[] {
    const receipt: string[] = [];

    if (this.companyName > '') {
      receipt.push(...this.alignToCenter(`${this.companyName}`));
    }

    receipt.push(...this.alignToCenter(`${this.shopName}`, { isBold: true }));
    receipt.push(...this.alignToCenter(`${this.shopAddress}`));

    if (this.IPN > '') {
      receipt.push(...this.alignToCenter(this.IPN));
    } else if (this.EDRPOU > '') {
      receipt.push(...this.alignToCenter(this.EDRPOU));
    }

    receipt.push(...this.alignToCenter(this.name, { isBold: true }));

    this.addTwoColumnItemToReceipt(
      SERVICE_DOC_AMOUNT_CAPTION,
      this.amount,
      receipt,
    );

    receipt.push(this.rowDevider());

    if (this.isFiscal) {
      this.addTwoColumnItemToReceipt(
        this.checkFiscalNumber,
        this.date,
        receipt,
      );

      receipt.push(`${this.prefix()}${this.prroFiscalNumber}`);

      receipt.push(...this.alignToCenter(this.checkType));
    } else {
      receipt.push(...this.alignToCenter(this.date));
    }

    receipt.push(...this.alignToCenter(PRRO_NAME));

    return receipt;
  }
}
