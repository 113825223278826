<bk-categories *ngIf="!subcategoriesActive && !productsActive && !weightProductActive"
                [isLandscape]="true"
                (setCategory)="goToSubcategory($event)"
                [disabled]="!isShiftOpen"></bk-categories>

<bk-subcategories *ngIf="subcategoriesActive"
                   [isLandscape]="true"
                   [menuCategory]="selectedCategory"
                   [menuSubcategories]="subcategoryMenu"
                   (goToProducts)="goToProducts($event)"
                   (goBackToMenu)="refreshMenu()"></bk-subcategories>

<bk-products *ngIf="productsActive"
              [isLandscape]="true"
              [menuCategory]="selectedCategory"
              [menuSubcategory]="selectedSubcategory"
              [menuEmptyCategory]="emptyCategory"
              (goToWeightProduct)="goToWeightProduct($event)"
              (goBackToMenu)="refreshMenu()"
              (goBackToSubcategories)="goBackToSubcategories()"></bk-products>

<bk-weight-product *ngIf="weightProductActive"
                    [isLandscape]="true"
                    [menuProduct]="selectedProduct"
                    (goBackToMenu)="refreshMenu()"
                    (goBackToSubcategories)="goBackToSubcategories()"
                    (goBackToProducts)="goBackToProducts()"></bk-weight-product>