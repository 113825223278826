import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
} from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UnauthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    return this.isUnauthenticated();
  }

  canActivateChild(): boolean {
    return this.isUnauthenticated();
  }

  canLoad(): boolean {
    return this.isUnauthenticated();
  }

  isUnauthenticated(): boolean {
    const isAuthenticated = this.authService.isAuthenticated();

    if (isAuthenticated) {
      this.router.navigate(['/shop'], { replaceUrl: true });
    }

    return !isAuthenticated;
  }
}
