/**
 * Тип знижки/націнки (1 символ)
 */
export enum DiscountType {
  /**
   * `0` – Сумова
   */
  Sum = 0,

  /**
   * `1` – Відсоткова
   */
  Percent = 1,
}
