import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import round from 'lodash-es/round';

import {
  MAX_CURRENCY_VALUE,
  MAX_PERCENT_VALUE,
  MIN_CURRENCY_VALUE_NOT_ZERO,
  ONE_KOP,
} from '../../core/constants/form-validations.const';
import { CachedDataService } from '../../core/services/cached-data.service';
import { ToastService } from '../../core/services/toast.service';
import { SaleProduct } from '../../sales/sale/sale-product.model';

import { ProductPriceTab } from './product-price-tab.enum';

@Component({
  selector: 'bk-product-price-dialog',
  templateUrl: './product-price.dialog.html',
  styleUrls: ['./product-price.dialog.scss'],
})
export class ProductPriceDialog implements OnInit {
  @ViewChild(IonInput) input: IonInput;

  @Input() saleProduct: SaleProduct;

  value: number;

  price = 0;
  discount = 0;
  percent = 0;

  productPriceTab = ProductPriceTab;
  activeTab = ProductPriceTab.Percent;

  tabs = [
    {
      caption: 'Нова ціна, ₴',
      name: ProductPriceTab.Price,
    },
    {
      caption: 'Знижка, ₴',
      name: ProductPriceTab.Discount,
    },
    {
      caption: 'Знижка, %',
      name: ProductPriceTab.Percent,
    },
  ];

  MIN_CURRENCY_VALUE_NOT_ZERO = MIN_CURRENCY_VALUE_NOT_ZERO;

  private isPRroActive = false;

  constructor(
    private modalCtrl: ModalController,
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
  ) {
    this.isPRroActive = this.cachedDataService.isPRroActive();
  }

  ngOnInit(): void {
    this.defaultValues();
  }

  ionViewDidEnter(): void {
    this.setFocusOnInput();
  }

  selectAll(): void {
    this.input.getInputElement().then((data) => {
      data.select();
    });
  }

  calcData(): void {
    if (!Boolean(this.value)) {
      this.defaultValues();
      return;
    }

    switch (this.activeTab) {
      case ProductPriceTab.Price:
        this.price = round(this.value, 2);
        this.discount = round(this.saleProduct.product.price - this.price, 2);
        this.percent = round(
          (this.discount / this.price) * MAX_PERCENT_VALUE,
          2,
        );

        break;

      case ProductPriceTab.Discount:
        this.discount = round(this.value, 2);
        this.price = round(this.saleProduct.product.price - this.discount, 2);
        this.percent = round(
          (this.discount / this.price) * MAX_PERCENT_VALUE,
          2,
        );

        break;

      case ProductPriceTab.Percent:
        this.percent = round(this.value, 2);
        this.discount = round(
          (this.saleProduct.product.price * this.percent) / MAX_PERCENT_VALUE,
          2,
        );

        if (this.percent === MAX_PERCENT_VALUE && this.isPRroActive) {
          this.price = Math.max(10 * ONE_KOP, this.price);
        } else {
          this.price = Math.max(
            0,
            round(this.saleProduct.product.price - this.discount, 2),
          );
        }

        break;

      default:
        break;
    }
  }

  async confirm(): Promise<void> {
    if (this.value > this.maxValue()) {
      this.toastService.presentError(
        `Значення "${this.value}" перевищує допустиме "${this.maxValue()}"`,
      );

      return;
    }

    this.saleProduct.price = round(this.price, 2);
    this.saleProduct.cost = round(this.price * this.saleProduct.quantity, 2);

    if (this.activeTab === this.productPriceTab.Price) {
      this.saleProduct.fullPrice = round(this.price, 2);
      this.saleProduct.fullCost = round(
        this.price * this.saleProduct.quantity,
        2,
      );
    }

    await this.modalCtrl.dismiss({ success: true });
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ success: false });
  }

  changedTab(event: any): void {
    this.activeTab = event.detail.value;

    this.calcData();
    this.setFocusOnInput();
  }

  placeholder(): string {
    switch (this.activeTab) {
      case ProductPriceTab.Price:
        return 'Введіть нову ціну, ₴';
      case ProductPriceTab.Discount:
        return 'Введіть суму знижки, ₴';
      case ProductPriceTab.Percent:
        return 'Відсоткова знижка, %';
      default:
        return '';
    }
  }

  maxValue(): number {
    switch (this.activeTab) {
      case ProductPriceTab.Price:
        return MAX_CURRENCY_VALUE;
      case ProductPriceTab.Discount:
        return this.saleProduct.product.price - MIN_CURRENCY_VALUE_NOT_ZERO;
      case ProductPriceTab.Percent:
        return MAX_PERCENT_VALUE - MIN_CURRENCY_VALUE_NOT_ZERO;
      default:
        return 0;
    }
  }

  private setFocusOnInput(): void {
    setTimeout(() => {
      this.input.setFocus();
    }, 100);
  }

  private defaultValues(): void {
    this.price = this.saleProduct.product.price;
    this.discount = this.saleProduct.product.price - this.price;
    this.percent = round((this.discount / this.price) * MAX_PERCENT_VALUE, 2);
  }
}
