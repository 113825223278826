<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/shop"></ion-back-button>
    </ion-buttons>

    <ion-title>Відкладені чеки</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="presales" *ngIf="shift && presales; else loading">
    <ion-content>
      <ng-container *ngIf="presaleGroups?.length; else noData">
        <ion-segment color="dark" (ionChange)="changedTab($event)" [value]="activeTabName">
          <ion-segment-button [value]="TAB_CREATED">
            <ion-label>Створені</ion-label>
          </ion-segment-button>

          <ion-segment-button [value]="TAB_COMPLETED">
            <ion-label>Реалізовані</ion-label>
          </ion-segment-button>
        </ion-segment>

        <div *ngFor="let group of presaleGroups">
          <div class="presales__group" (click)="toggleGroupPresale(group)">
            <div class="presales__group__header">
              <span class="presales__group__header__date">{{ group.date }}</span>
              <ion-icon class="presales__group__header__toggle" [name]="group.collapsed ? 'chevron-forward-outline' : 'chevron-down-outline'"></ion-icon>
            </div>
          </div>

          <div class="table-header" *ngIf="!group.collapsed">
            <span class="column-time">Час</span>
            <span class="column-client">Клієнт</span>
            <span class="column-products"><span class="ion-hide-sm-down">Найменування</span></span>
            <span class="column-sum">Сума, ₴</span>
            <span class="column-details">&nbsp;</span>
          </div>

          <ion-list lines="none" class="table-data" *ngIf="!group.collapsed">
            <div class="even-row" *ngFor="let presale of getPresales(group); let i = index" >
              <ion-item-sliding #itemSliding *ngIf="i < group.visibleRows">
                <ion-item (click)="itemSliding.open('end')">
                  <bk-presale [presale]="presale"></bk-presale>
                </ion-item>
                
                <ion-item-options side="end" (click)="itemSliding.close()">
                  <ion-item-option color="danger" (click)="sale(presale, group)" *ngIf="presale.status === status.Created">
                    <ion-icon name="calculator" size="large"></ion-icon>
                  </ion-item-option>

                  <ion-item-option color="tertiary" (click)="print(presale)">
                    <ion-icon name="print-outline" size="large"></ion-icon>
                  </ion-item-option>

                  <ion-item-option color="warning" (click)="updateTime(presale, group)" *ngIf="presale.status === status.Created">
                    <ion-icon name="alarm-outline" size="large"></ion-icon>
                  </ion-item-option>

                  <ion-item-option color="danger" (click)="delete(presale, group)" *ngIf="presale.status === status.Created">
                    <ion-icon name="bag-remove-outline" size="large"></ion-icon>
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
            </div>

            <div *ngIf="group.visibleRows < getPresales(group).length" class="ion-text-center">
              <ion-button (click)="showMorePresales(group)">
                <ion-label>Показати ще</ion-label>
                <ion-icon slot="end" name="ellipsis-horizontal"></ion-icon>
              </ion-button>
            </div>
          </ion-list>
        </div>
      </ng-container>

      <ng-template #noData>
        <div class="no-data">
          <span>Немає замовлень</span>
        </div>
      </ng-template>
    </ion-content>
  </section>

  <ng-template #loading>
    <div class="no-data">
      <span>
        <ion-spinner *ngIf="!presales; else noAccess"></ion-spinner>

        <ng-template #noAccess>
          Обмежені права доступу!
        </ng-template>
      </span>
    </div>
  </ng-template>
</ion-content>

<ion-item [hidden]="true">
  <ion-label>Час замовлення:</ion-label>
  <ion-datetime #datetime
                name="date-picker"
                displayFormat="DD MMMM HH:mm"
                cancelText="Скасувати​"
                doneText="Підтвердити"
                placeholder="Час замовлення"
                hourValues="6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22"
                minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
                [monthNames]="UA_MONTHS"
                [min]="minDate"
                [max]="maxDate"
                [(ngModel)]="presaleDate" 
                (ionChange)="dateTimeChange()"
                (ionCancel)="dateTimeCancel()"></ion-datetime>
</ion-item>