import { Component, Input, OnInit } from '@angular/core';

import {
  REFILL_HEADER_CAPTION,
  REFILL_NUMBER_CAPTION,
  REFILL_PROVIDER_NAME_CAPTION,
  REFILL_SHOP_NAME_CAPTION,
  REFILL_USER_NAME_CAPTION,
} from '../../receipt/receipt.const';
import { PrintRefillSalePrices } from '../../receipt/types/print-refill-sale-prices.model';

@Component({
  selector: 'bk-view-refill-sale-prices',
  templateUrl: './view-refill-sale-prices.component.html',
  styleUrls: ['./view-refill-sale-prices.component.scss'],
})
export class ViewRefillSalePricesComponent implements OnInit {
  @Input() refill: PrintRefillSalePrices;

  numberCaption = REFILL_NUMBER_CAPTION;
  providerNameCaption = REFILL_PROVIDER_NAME_CAPTION;
  shopNameCaption = REFILL_SHOP_NAME_CAPTION;
  userNameCaption = REFILL_USER_NAME_CAPTION;
  productsCaption = REFILL_HEADER_CAPTION;

  constructor() {}

  ngOnInit(): void {
    //
  }
}
