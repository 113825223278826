import { Component, Input, OnInit } from '@angular/core';

import { PRRO_NAME } from '../../../settings.const';
import { SERVICE_DOC_AMOUNT_CAPTION } from '../../receipt/receipt.const';
import { PrintPRroServiceDoc } from '../../receipt/types/print-p-rro-service-doc.model';

@Component({
  selector: 'bk-view-tax-service-doc',
  templateUrl: './view-tax-service-doc.component.html',
  styleUrls: ['./view-tax-service-doc.component.scss'],
})
export class ViewTaxServiceDocComponent implements OnInit {
  @Input() serviceDoc: PrintPRroServiceDoc;

  serviceDocAmountCaption = SERVICE_DOC_AMOUNT_CAPTION;
  prroName = PRRO_NAME;

  constructor() {}

  ngOnInit(): void {
    //
  }
}
