<ion-header>
  <ion-toolbar>
    <ion-title>Товари зі штрихкодом "{{ barcode }}"</ion-title>
  </ion-toolbar>
</ion-header>
  
<ion-content>
  <section class="multibarcode">
    <h2 class="multibarcode__title">Виберіть товар</h2>

    <div class="multibarcode__data">
      <ion-list lines="none" class="table-data">
        <div class="even-row" *ngFor="let item of items">
          <ion-item [button]="true" (click)="select(item, $event)">
            <div class="table-data-row" *ngIf="isProduct; else ingredientTable">
              <span class="product-column-name">
                {{ item.name }}
                <ion-note>{{ item.categoryName + ' > ' + item.subcategoryName }}</ion-note>
              </span>

              <span class="product-column-icons">
                <bk-icon name="coupon" color="tertiary" *ngIf="item.freeCupActive"></bk-icon>
              </span>

              <span class="product-column-amount">{{ item.amount }}</span>
              <span class="product-column-price">{{ item.price | currency:'UAH':'symbol-narrow' }}</span>
            </div>

            <ng-template #ingredientTable>
              <div class="table-data-row">
                <span class="ingredient-column-name">
                  {{ item.name }}
                  <ion-note>{{ item.categoryName }}</ion-note>
                </span>
  
                <span class="ingredient-column-price">{{ (item.salePrice ? item.salePrice : item.costPrice) | currency:'UAH':'symbol-narrow' }}</span>
              </div>
            </ng-template>
          </ion-item>
        </div>
      </ion-list>
    </div>
  </section>
</ion-content>
  
<ion-footer>
  <ion-toolbar>
    <ion-button class="one-button" color="tertiary" (click)="cancel()">
      <ion-label>Скасувати</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>
