import { PrivatbankJsonMethod } from '../enums/method.enum';
import { PrivatbankJsonServiceMessageTypeRequest } from '../enums/service-message-type-request.enum';

import { PrivatbankJsonBaseRequest } from './base-request.model';

/**
 * Service Messages – особливий тип повідомлень, які обробляються терміналом асинхронно,
 * тобто незалежно від поточної операції чи стану,
 * в той час як інші методи перехоплюють процес і до завершення повертають ServiceMessage Busy
 *
 * Використовуються для передачі службових команд від клієнта серверу та навпаки,
 * для отримання клієнтом повідомлень про стан від сервера
 *
 * - identify - ідентифікація приналежності терміналу та версії його ПЗ АТ КБ "ПриватБанк",
 * а також інформація про виробника та модель терміналу
 *
 * - interrupt – вимога переривання ініційованої операції
 *
 * - getLastStatMsgCode - повідомляє поточний хід виконання операції терміналом.
 * Зазвичай результат змінюється паралельно зміні подій в інтерфейсі пристрою при взаємодії з касиром/користувачем.
 * Перелік можливих значень відповідає аналогічному property BPOS
 *
 * - getLastStatMsgDescription - повідомляє поточний хід виконання операції терміналом.
 * Зазвичай результат змінюється паралельно зміні подій в інтерфейсі пристрою, при взаємодії з касиром/користувачем.
 * Перелік можливих значень відповідає аналогічному property BPOS
 * і є текстовою розшифровкою значень параметра LastStatMsgCode
 */
export class PrivatbankJsonServiceMessageRequest extends PrivatbankJsonBaseRequest {
  /**
   * Масив додаткових параметрів для терміналу
   */
  params: {
    msgType: PrivatbankJsonServiceMessageTypeRequest;
  };

  constructor(msgType: PrivatbankJsonServiceMessageTypeRequest) {
    super(PrivatbankJsonMethod.ServiceMessage);

    this.params = { msgType };
  }
}
