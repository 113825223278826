import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ClientFreeCup } from '../../../clients/client-free-cup.model';
import { Resource } from '../../abstract/resource';
import { ShopClientScreenSlide } from '../../models/shop-client-screen-slide.model';
import { ShopClient } from '../../models/shop-client.model';
import { Shop } from '../../models/shop.model';
import { CachedDataService } from '../cached-data.service';

@Injectable({
  providedIn: 'root',
})
export class ShopsService extends Resource<Shop> {
  private shop: BehaviorSubject<Shop>;

  constructor(
    protected http: HttpClient,
    private cachedDataService: CachedDataService,
  ) {
    super(http, {
      path: '/shops',
    });
  }

  refresh(id: number): Observable<Shop> {
    return this.http
      .get<Shop>(`${this.path}/${id}`, { params: { forApp: 'true' } })
      .pipe(
        tap((shop) => {
          this.cachedDataService.setShop(shop);
          this.setShop(shop);
        }),
      );
  }

  getShop(): Observable<Shop> {
    if (this.shop != null) {
      return this.shop;
    }

    const shopId = this.cachedDataService.getShopId();

    return this.refresh(shopId);
  }

  getShopLocal(): Observable<Shop> {
    if (this.shop != null) {
      return this.shop;
    }

    const localShop = this.cachedDataService.getShop();

    this.setShop(localShop);

    return new Observable((observer) => {
      observer.next(localShop);
      observer.complete();
    });
  }

  setShop(shop: Shop): void {
    if (this.shop != null) {
      this.shop.next(shop);
    } else {
      this.shop = new BehaviorSubject<Shop>(shop);
    }
  }

  checkActiveClient(id: number): Observable<ShopClient> {
    return this.http.get<ShopClient>(`${this.path}/client/${id}`);
  }

  findClients(shopId: number, query: string): Observable<ShopClient[]> {
    let params = new HttpParams();

    params = params.append('shopId', String(shopId));
    params = params.append('query', query);

    return this.http.get<ShopClient[]>(`${this.path}/clients/search`, {
      params,
    });
  }

  findClientScreenSlides(): Observable<ShopClientScreenSlide[]> {
    const shopId = this.cachedDataService.getShopId();

    return this.http.get<ShopClientScreenSlide[]>(
      `${this.path}/${shopId}/client-screen-slide`,
    );
  }

  getClientFreeCups(
    shopId: number,
    clientId: number,
    productIds: number[],
  ): Observable<ClientFreeCup[]> {
    let params = new HttpParams();

    params = params.append('shopId', String(shopId));
    params = params.append('clientId', String(clientId));
    params = params.append('productIds', JSON.stringify(productIds));

    return this.http.get<ClientFreeCup[]>(`${this.path}/clients/free-cups`, {
      params,
    });
  }

  removeActiveClient(id: number): Observable<void> {
    return this.http.delete<void>(`${this.path}/removeClient/${id}`);
  }
}
