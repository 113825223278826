import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ActionSheetButton,
  ActionSheetController,
  AlertController,
  IonInput,
  ModalController,
} from '@ionic/angular';
import { DateTime } from 'luxon';
import { MaskPipe } from 'ngx-mask';

import {
  TERMINAL,
  TERMINAL_LOG,
  TERMINAL_SERVICE_MESSAGE,
} from '../../core/constants/events.const';
import { IStoragePaymentTerminalData } from '../../core/interfaces/storage-payment-terminal-data.interface';
import { IStoragePaymentTerminalSettings } from '../../core/interfaces/storage-payment-terminal-settings.interface';
import { Events } from '../../core/services/events.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { PayFormCode } from '../../p-rro/fsco/enums/pay-form-code.enum';
import { SalePayment } from '../../sales/sale/sale-payment.model';
import { LogsService } from '../../settings/logs/logs.service';
import { TerminalConnectionProtocol } from '../../settings/terminals/enums/terminal-connection-protocol.enum';
import { ITerminalServiceMessage } from '../../settings/terminals/interfaces/terminal-service-message.interface';
import { PrivatbankJsonMethod } from '../../settings/terminals/privatbank/json/enums/method.enum';
import { PrivatbankJsonTxnType } from '../../settings/terminals/privatbank/json/enums/txn-type.enum';
import { PrivatbankJsonTransactionResponse } from '../../settings/terminals/privatbank/json/responses/transaction-response.model';
import {
  PAYMENT_SYSTEM_MASTERCARD,
  PAYMENT_SYSTEM_VISA,
} from '../../settings/terminals/terminals.const';
import { TerminalsService } from '../../settings/terminals/terminals.service';

import { CashlessPaymentTab } from './cashless-payment-tab.enum';

@Component({
  selector: 'bk-cashless-payment-dialog',
  templateUrl: './cashless-payment.dialog.html',
  styleUrls: ['./cashless-payment.dialog.scss'],
})
export class CashlessPaymentDialog implements OnInit {
  @ViewChild('deviceId') private deviceIdInput: IonInput;
  @ViewChild('epzDetails') private epzDetailsInput: IonInput;
  @ViewChild('paymentSystem') private paymentSystemInput: IonInput;
  @ViewChild('acquirerTransactionId')
  private acquirerTransactionIdInput: IonInput;
  @ViewChild('authCode') private authCodeInput: IonInput;

  @Input() amount: number;
  @Input() refundTransactionId = '';

  salePayment = new SalePayment();

  epzDetailsPrefix = '';
  epzDetailsMask = '0000 XXXX XXXX 0000';

  terminalSettings: IStoragePaymentTerminalSettings;
  terminalData: IStoragePaymentTerminalData;
  terminalConnectionProtocol = TerminalConnectionProtocol;
  terminalStatus = '';
  terminalRetry = false;
  terminalSpinner = false;

  isDesktop = false;
  isAndroidApp = false;
  requestInProgress = false;
  interruptInProgress = false;

  readonly paymentSystems = [
    PAYMENT_SYSTEM_MASTERCARD,
    PAYMENT_SYSTEM_VISA,
    'PRIVAT',
    'PROSTIR',
  ];

  cashlessPaymentTab = CashlessPaymentTab;
  activeTab = CashlessPaymentTab.Data;

  tabs = [
    {
      caption: 'Дані еквайрингу',
      name: CashlessPaymentTab.Data,
    },
    {
      caption: 'Лог',
      name: CashlessPaymentTab.Log,
    },
  ];

  logData = ``;

  get isPaymentApp(): boolean {
    return (
      this.terminalData != null &&
      (this.terminalData.connectionProtocol ===
        TerminalConnectionProtocol.TapToMono ||
        this.terminalData.connectionProtocol ===
          TerminalConnectionProtocol.TapToPrivat)
    );
  }

  constructor(
    private events: Events,
    private maskPipe: MaskPipe,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private actionSheetController: ActionSheetController,
    private terminalsService: TerminalsService,
    private utilsService: UtilsService,
    private toastService: ToastService,
    private logsService: LogsService,
  ) {
    this.isDesktop = this.utilsService.isDesktop();
    this.isAndroidApp = this.utilsService.isAndroidApp();
  }

  async ngOnInit(): Promise<void> {
    this.salePayment.amount = this.amount;
    this.salePayment.method = PayFormCode.Card;

    this.salePayment.posTransactionDate = new Date();
    this.salePayment.operationType =
      this.salePayment.amount > 0
        ? PrivatbankJsonTxnType.Purchase
        : PrivatbankJsonTxnType.Refund;

    this.events.subscribe(
      TERMINAL_SERVICE_MESSAGE,
      (message: ITerminalServiceMessage) => {
        this.terminalStatus = message.description;

        if (message.error) {
          this.requestInProgress = false;
          this.interruptInProgress = false;
          this.terminalRetry = true;
          this.terminalSpinner = false;
        }
      },
    );

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonMethod.Purchase}`,
      (response: PrivatbankJsonTransactionResponse) => {
        if (response.method === PrivatbankJsonMethod.Purchase) {
          this.setPaymentData(response);
        }
      },
    );

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonMethod.Withdrawal}`,
      (response: PrivatbankJsonTransactionResponse) => {
        if (response.method === PrivatbankJsonMethod.Withdrawal) {
          this.setPaymentData(response);
        }
      },
    );

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonMethod.Refund}`,
      (response: PrivatbankJsonTransactionResponse) => {
        if (response.method === PrivatbankJsonMethod.Refund) {
          this.setPaymentData(response);
        }
      },
    );

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonMethod.ServiceRefund}`,
      (response: PrivatbankJsonTransactionResponse) => {
        if (response.method === PrivatbankJsonMethod.ServiceRefund) {
          this.setPaymentData(response);
        }
      },
    );

    this.events.subscribe(`${TERMINAL_LOG}`, (message: string) => {
      this.logData += `${message}\n\n`;
    });
  }

  async ionViewDidEnter(): Promise<void> {
    this.terminalData = await this.terminalsService.getTerminalData();
    this.terminalSettings = await this.terminalsService.getSettings();

    this.salePayment.acquirerName = this.terminalData.acquirerName;
    this.salePayment.deviceId = this.terminalData.deviceId;

    if (
      (this.isAndroidApp || this.isDesktop) &&
      this.terminalData?.connectionProtocol
    ) {
      this.sendMessage();
    }
  }

  ionViewWillLeave(): void {
    if (
      this.terminalData.connectionProtocol ===
        TerminalConnectionProtocol.PrivatbankJson ||
      this.terminalData.connectionProtocol ===
        TerminalConnectionProtocol.CashboxPrivatbank ||
      this.terminalData.connectionProtocol ===
        TerminalConnectionProtocol.CashboxBPOS
    ) {
      this.logsService.send(
        `payment`,
        `${JSON.stringify(
          {
            acquirerName: this.terminalData.acquirerName,
            deviceId: this.terminalData.deviceId,
            connectionProtocol: this.terminalData.connectionProtocol,
            merchantId: this.terminalSettings.merchantId,
            serviceRefund: this.terminalSettings.serviceRefund,
            connectionMethod: this.terminalSettings.connectionMethod,
            ip: this.terminalSettings.ip,
            usbPort: this.terminalSettings.usbPort,
          },
          null,
          2,
        )}\n\n${this.logData.replace(/\n\n/g, '\n')}`,
        { auto: true },
      );
    }
  }

  async selectpaymentSystem(): Promise<void> {
    const actionSheetButtons: ActionSheetButton[] = [
      {
        text: 'Скасувати',
        icon: 'close',
        role: 'cancel',
      },
    ];

    for (const paymentSystem of this.paymentSystems) {
      actionSheetButtons.push({
        text: paymentSystem,
        icon: 'card-outline',
        handler: () => {
          this.salePayment.paymentSystem = paymentSystem;
        },
      });
    }

    const actionSheet = await this.actionSheetController.create({
      header: 'Платіжні системи',
      buttons: actionSheetButtons,
    });

    await actionSheet.present();
  }

  changedTab(event: any): void {
    this.activeTab = event.detail.value;
  }

  setFocus(id: string): void {
    if (id === 'deviceId') {
      this.deviceIdInput?.setFocus();
    } else if (id === 'epzDetails') {
      this.epzDetailsInput?.setFocus();
    } else if (id === 'paymentSystem') {
      if ((this.salePayment.paymentSystem ?? '') > '') {
        this.acquirerTransactionIdInput?.setFocus();
      } else {
        this.paymentSystemInput?.setFocus();
      }
    } else if (id === 'acquirerTransactionId') {
      this.acquirerTransactionIdInput?.setFocus();
    } else if (id === 'authCode') {
      this.authCodeInput?.setFocus();
    }
  }

  parsePaymentSystemName(): void {
    if (this.salePayment.epzDetails?.[0] === '4') {
      this.salePayment.paymentSystem = PAYMENT_SYSTEM_VISA;
    } else if (this.salePayment.epzDetails?.[0] === '5') {
      this.salePayment.paymentSystem = PAYMENT_SYSTEM_MASTERCARD;
    }
  }

  updadeEPZMask(): void {
    if (this.salePayment.paymentSystem?.toUpperCase().includes('PRIVAT')) {
      this.epzDetailsPrefix = 'XXXX XXXX XXXX ';
      this.epzDetailsMask = '0000';

      if (this.salePayment.epzDetails) {
        this.salePayment.epzDetails = this.maskPipe.transform(
          this.salePayment.epzDetails,
          'XXXX XXXX XXXX 0000',
        );
      }

      return;
    }

    if (this.salePayment.paymentSystem?.toUpperCase().includes('PROSTIR')) {
      this.epzDetailsPrefix = '';
      this.epzDetailsMask = '0000 00XX XXXX 0000';

      if (this.salePayment.epzDetails) {
        this.salePayment.epzDetails = this.maskPipe.transform(
          this.salePayment.epzDetails,
          '0000 00XX XXXX 0000',
        );
      }

      return;
    }

    this.epzDetailsPrefix = '';
    this.epzDetailsMask = '0000 XXXX XXXX 0000';
  }

  sendMessage(): void {
    if (this.requestInProgress) {
      this.toastService.presentWarning(
        'Запит відправлено',
        'Зачекайте на відповідь терміналу',
      );

      return;
    }

    if (this.amount > 0) {
      this.startSending();
      this.terminalsService.purchase(
        this.amount,
        this.terminalData.connectionProtocol,
      );
    } else if (this.refundTransactionId > '') {
      this.startSending();

      if (
        (this.terminalData.connectionProtocol ===
          TerminalConnectionProtocol.PrivatbankJson ||
          this.terminalData.connectionProtocol ===
            TerminalConnectionProtocol.CashboxPrivatbank) &&
        this.terminalSettings.serviceRefund
      ) {
        this.terminalsService.refundService(
          Math.abs(this.amount),
          this.refundTransactionId,
        );
      } else {
        this.terminalsService.refund(
          Math.abs(this.amount),
          this.refundTransactionId,
          this.terminalData.connectionProtocol,
        );
      }
    } else {
      this.terminalRetry = true;
      this.terminalStatus = `Операцію скасовано: ${
        this.refundTransactionId
          ? 'Невідомий тип'
          : 'Немає RRN транзакції продажу'
      }`;

      this.toastService.presentError('Платіжний термінал', this.terminalStatus);
    }
  }

  private startSending(): void {
    this.terminalStatus = 'Надсилання даних...';
    this.terminalRetry = false;
    this.terminalSpinner = true;
    this.requestInProgress = true;
  }

  interrupt(message: string): void {
    this.log(message);

    if (this.interruptInProgress) {
      this.toastService.presentWarning(
        'Запит на скасування операції відправлено',
        'Зачекайте на відповідь терміналу',
      );

      return;
    }

    this.interruptInProgress = true;

    this.terminalsService.interrupt();
  }

  status(): void {
    this.terminalsService.transactionStatus(
      this.amount,
      this.refundTransactionId,
      this.terminalData.connectionProtocol,
    );
  }

  confirm(message: string): void {
    this.log(message);

    if (
      this.requestInProgress &&
      this.terminalData.connectionProtocol !== TerminalConnectionProtocol.None
    ) {
      this.alertCtrl
        .create({
          header: 'Термінал',
          message: `
          Триває зв'язок з терміналом!<br>
          Будь ласка, дочекайтеся завершення операції.<br>
          Якщо тривалість виконання операції більша очікуваної, перевірте чи проосить термінал втручання оператора.<br><br>
          <strong>Продовжити</strong> дострокове завершення операції (термінал отримає команду скасування) з ризиком втрати даних еквайрингу?`,
          buttons: [
            {
              text: 'Скасувати',
              role: 'cancel',
              cssClass: 'tertiary',
            },
            {
              text: 'Продовжити',
              role: 'confirm',
              cssClass: 'primary',
              handler: () => {
                this.interrupt('Interrupt automatically');
                this.finish();
              },
            },
          ],
          cssClass: 'pay-debt-dialog-alert',
          backdropDismiss: false,
        })
        .then((alert) => alert.present());
    } else {
      this.finish();
    }
  }

  async cancel(message: string): Promise<void> {
    this.log(message);

    if (this.requestInProgress) {
      this.interrupt('Interrupt automatically');
    }

    await this.modalCtrl.dismiss({ success: false, payment: undefined });
  }

  private setPaymentData(response: PrivatbankJsonTransactionResponse): void {
    this.requestInProgress = false;

    if (response.error) {
      return;
    }

    this.salePayment.acquirerName = response.params.bankAcquirer
      ? response.params.bankAcquirer
      : this.terminalData.acquirerName;

    this.salePayment.deviceId = response.params.terminalId
      ? response.params.terminalId
      : this.terminalData.deviceId;

    this.salePayment.acquirerTransactionId = response.params.rrn;
    this.salePayment.authCode = response.params.approvalCode;
    this.salePayment.epzDetails = response.params.pan;

    this.salePayment.paymentSystem = response.params.paymentSystem
      ? response.params.paymentSystem
      : response.params.issuerName;

    this.salePayment.signVerification = Number(response.params.signVerif);
    this.salePayment.operationType = Number(response.params.txnType);
    this.salePayment.transactionId = response.params.transactionId;
    this.salePayment.posTransactionNumber = response.params.invoiceNumber;
    this.salePayment.posTransactionDate = DateTime.fromFormat(
      `${response.params.date} ${response.params.time}`,
      'dd.MM.yyyy HH:mm:ss',
    ).toJSDate();

    setTimeout(() => {
      this.confirm('Confirmed automatically');
    }, 1500);
  }

  private finish(): void {
    this.salePayment.epzDetails = this.salePayment.epzDetails?.replace(
      new RegExp(' ', 'g'),
      '',
    );

    this.terminalData.acquirerName = this.salePayment.acquirerName ?? '';
    this.terminalData.deviceId = this.salePayment.deviceId ?? '';

    this.terminalsService.setDefaultPaymentData(this.terminalData);

    this.alertCtrl.getTop().then((currentCtrl) => {
      if (currentCtrl != null) {
        currentCtrl.dismiss();
      }
    });

    this.modalCtrl
      .getTop()
      .then((currentCtrl) => {
        if (currentCtrl != null) {
          currentCtrl.dismiss({ success: true, payment: this.salePayment });
        } else {
          this.toastService.presentWarning(
            'Вікно безготівкової оплати не знайдене',
            'Можливо вікно було закрите вручну. Дані еквайрингу можуть бути втрачені',
          );
        }
      })
      .catch((error) => {
        this.toastService.presentWarning(
          'Не вдалося закрити вікно безготівкової оплати',
          `Помилка: ${JSON.stringify(error)}`,
        );
      });
  }

  private log(message: string): void {
    if (
      this.terminalData.connectionProtocol ===
        TerminalConnectionProtocol.PrivatbankJson ||
      this.terminalData.connectionProtocol ===
        TerminalConnectionProtocol.CashboxPrivatbank ||
      this.terminalData.connectionProtocol ===
        TerminalConnectionProtocol.CashboxBPOS
    ) {
      this.terminalsService.log(`Dialog [#timestamp]: ${message}`);
    }
  }
}
