<ion-header>
  <ion-toolbar>
    <ion-title>Повернення</ion-title>

    <ion-buttons slot="end">
      <ion-label>{{ totalSum | currency:'UAH':'symbol-narrow' }}&nbsp;</ion-label>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form #returnForm="ngForm">
    <section class="return">
      <div class="table-data-row">
        <ion-note>Оберіть найменування, які повертають, та вкажіть їх кількість</ion-note>
      </div>

      <div class="table-header">
        <span class="column-selected">
          <ion-checkbox name="selectAllProducts"
                        [indeterminate]="selectAllIndeterminate"
                        [(ngModel)]="selectAllChecked"
                        (click)="selectAllChanged()"></ion-checkbox>
        </span>

        <span class="column-name">Найменування</span>
        <span class="column-quantity">Кількість</span>
        <span class="column-price-sum">Сума, ₴<br>Ціна, ₴</span>
      </div>

      <ion-content>
        <ion-list lines="none" class="table-data">
          <div class="even-row" *ngFor="let returnProduct of returnProducts; let index = index">
            <ion-item> 
              <div class="table-data-row">
                <div class="product">
                  <div class="column-selected">
                    <ion-checkbox [name]="'selected_' + returnProduct.productId"
                                  [disabled]="isInDebt || sale.saleInDebtId"
                                  [(ngModel)]="returnProduct.selected"
                                  (ionChange)="selectProduct(returnProduct, index)"></ion-checkbox>
                  </div>

                  <div class="column-name">
                    {{ returnProduct.product.name }}, {{ returnProduct.product.amount }}
                  </div>

                  <div class="column-quantity">
                    <ng-container *ngIf="!returnProduct.selected || isInDebt || sale.saleInDebtId else editMode">
                      {{ returnProduct.quantity }}
                    </ng-container>

                    <ng-template #editMode>
                      <ng-container *ngIf="!returnProduct.product.weightProduct; else weightProduct">
                        <ion-button color="primary" slot="icon-only" (click)="decrementQuantity(returnProduct)">
                          <ion-icon name="remove"></ion-icon>
                        </ion-button>

                        <ion-text color="primary" class="buttons-value" (click)="inputQuantity(returnProduct)">
                          {{ returnProduct.quantity }}
                        </ion-text>

                        <ion-button color="primary" slot="icon-only" (click)="incrementQuantity(returnProduct)">
                          <ion-icon name="add"></ion-icon>
                        </ion-button>
                      </ng-container>

                      <ng-template #weightProduct>
                        <ion-item>
                          <ion-input  #quantityInput
                                      type="tel"
                                      step="0.001"
                                      [id]="'quantity_' + index"
                                      [name]="'quantity_' + index"
                                      [(ngModel)]="returnProduct.quantity"
                                      [min]="MIN_QUANTITY_VALUE.toString()"
                                      [bkMin]="MIN_QUANTITY_VALUE"
                                      [max]="returnProduct.soldQuantity?.toString()"
                                      [bkMax]="returnProduct.soldQuantity"
                                      [required]="true"
                                      (ionFocus)="selectAllInputText(quantityInput)"
                                      (ionChange)="calcSaleProductCost(returnProduct)"></ion-input>
                        </ion-item>
                      </ng-template>
                    </ng-template>
                  </div>

                  <div class="column-price-sum">
                    <div class="sum">
                      {{ returnProduct.cost | number: '1.2-2' }}
                    </div>

                    <div class="price">
                      {{ returnProduct.price | number: '1.2-2' }}
                    </div>
                  </div>
                </div>

                <div class="excise">
                  <ion-list lines="none" class="table-data" *ngIf="returnProduct.exciseLabels.length">
                    <ion-list-header>
                      <ion-label>Акцизні марки</ion-label>

                      <ion-note *ngIf="returnProduct.selected">
                        Залишіть акцизні марки найменувань, які повертають, а зайві - видаліть
                      </ion-note>
                    </ion-list-header>

                    <div class="even-row" *ngFor="let exciseLabel of returnProduct.exciseLabels">
                      <ion-item>
                        <div class="table-data-row">
                          <div class="column-excise-icon">
                            <ion-icon name="barcode-outline"></ion-icon>
                          </div>

                          <div class="column-excise-label">
                            <ion-label>{{ exciseLabel }}</ion-label>
                          </div>

                          <div class="column-excise-button">
                            <ion-button (click)="deleteExciseLabel(returnProduct, exciseLabel)" *ngIf="returnProduct.selected">
                              <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                            </ion-button>
                          </div>
                        </div>
                      </ion-item>
                    </div>
                  </ion-list>
                </div>
              </div>
            </ion-item>
          </div>
        </ion-list>
      </ion-content>

      <div class="payments">
        <div class="column-note">
          <ion-label>Сума коштів для повернення клієнту</ion-label>
        </div>

        <div class="column-debt" *ngIf="isInDebt; else moneyMode">
          <ion-item>
            <ion-label  position="floating">Зменшення боргу, ₴</ion-label>
            <ion-input  #debtInput
                        type="tel"
                        name="totalDebt"
                        step="0.01"
                        [disabled]="isInDebt"
                        [(ngModel)]="totalDebt"
                        [required]="true"
                        [min]="0"
                        [bkMin]="0"
                        [max]="totalSum.toString()"
                        [bkMax]="totalSum"></ion-input>
          </ion-item>
        </div>

        <ng-template #moneyMode>
          <div class="column-cash">
            <ion-item>
              <ion-label  position="floating">Готівка, ₴</ion-label>
              <ion-input  #cashInput
                          type="tel"
                          name="totalCash"
                          step="0.1"
                          [disabled]="isInDebt"
                          [(ngModel)]="totalCash"
                          [required]="true"
                          [min]="0"
                          [bkMin]="0"
                          [max]="totalCashMax.toString()"
                          [bkMax]="totalCashMax"
                          (ionFocus)="cashPaymentFocus(cashInput)"
                          (ionInput)="cashPaymentChanged($event.target.value)"></ion-input>
            </ion-item>
          </div>
  
          <div class="column-space">&nbsp;</div>
  
          <div class="column-card">
            <ion-item>
              <ion-label  position="floating">Картка, ₴</ion-label>
              <ion-input  #cardInput
                          type="tel"
                          name="totalCard"
                          step="0.01"
                          [disabled]="isInDebt"
                          [(ngModel)]="totalCard"
                          [required]="true"
                          [min]="0"
                          [bkMin]="0"
                          [max]="totalSum.toString()"
                          [bkMax]="totalSum"
                          (ionFocus)="cardPaymentFocus(cardInput)"
                          (ionInput)="cardPaymentChanged($event.target.value)"></ion-input>
            </ion-item>
          </div>
        </ng-template>
      </div>
    </section>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button slot="start"
                color="tertiary"
                class="two-buttons"
                (click)="cancel()">
      <ion-label>Скасувати</ion-label>
    </ion-button>

    <ion-button slot="end"
                color="primary"
                class="two-buttons"
                [disabled]="!returnForm.valid || requestInProgress || (totalDebt + totalCash + totalCard) == 0"
                (click)="return()">
      <ion-label>Підтвердити</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>