<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/shop"></ion-back-button>
    </ion-buttons>

    <ion-title>Продажі</ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="openMenu()">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="sales" *ngIf="shift && sales && user?.sale; else loading">
    <ion-content [ngClass]="{ 'active-footer': user?.showCash }">
      <ng-container *ngIf="saleGroups?.length; else noData">
        <div *ngFor="let group of saleGroups">
          <div class="sales__group" (click)="toggleGroupSale(group)">
            <div class="sales__group__header">
              <span class="sales__group__header__date">{{ group.date }}</span>
              <ion-icon class="sales__group__header__toggle" [name]="group.collapsed ? 'chevron-forward-outline' : 'chevron-down-outline'"></ion-icon>
            </div>
            
            <div class="sales__group__sum" *ngIf="user?.showCash">
              <div class="sales__group__sum__data">
                <ion-icon name="card"></ion-icon>
                <span>{{ group.cardSum | currency:'UAH':'symbol-narrow' }}</span>
              </div>

              <div class="sales__group__sum__data">
                <ion-icon name="cash"></ion-icon>
                <span>{{ group.cashSum | currency:'UAH':'symbol-narrow' }}</span>
              </div>
            </div>
          </div>

          <div class="table-header" *ngIf="!group.collapsed">
            <span class="column-time">Час</span>
            <span class="column-discount">Знижка, ₴</span>
            <span class="column-sum">Сума, ₴</span>
            <span class="column-tax">&nbsp;</span>
            <span class="column-details">&nbsp;</span>
          </div>

          <ion-list lines="none" class="table-data" *ngIf="!group.collapsed">
            <div class="even-row" *ngFor="let sale of group.sales; let i = index" >
              <ion-item-sliding #itemSliding *ngIf="i < group.visibleRows">
                <ion-item (click)="itemSliding.open('end')">
                  <bk-sale [sale]="sale"></bk-sale>
                </ion-item>
                
                <ion-item-options side="end" (click)="itemSliding.close()">
                  <ion-item-option [color]="isPRroDoc(sale) ? 'secondary' : 'danger'" (click)="qr(sale)" *ngIf="(isPRroDoc(sale) || isPRroActive) && !sale.debtSum">
                    <ion-icon [name]="isPRroDoc(sale) ? 'share-social-outline' : 'qr-code-outline'" size="large"></ion-icon>
                  </ion-item-option>

                  <ion-item-option color="tertiary" (click)="printReceipt(sale)">
                    <ion-icon name="print-outline" size="large"></ion-icon>
                  </ion-item-option>

                  <ion-item-option color="tertiary" (click)="printLabels(sale)" class="border-left" *ngIf="isPrintLabelMode">
                    <ion-icon name="ticket-outline" size="large"></ion-icon>
                  </ion-item-option>

                  <ion-item-option color="warning" (click)="changePaymentMethodAlert(sale, group)" *ngIf="sale.cashless != null && !sale.returnSaleId && !sale.debtSum">
                    <ion-icon [name]="sale.cashless ? 'cash-outline' : 'card-outline'" size="large"></ion-icon>
                  </ion-item-option>

                  <ion-item-option color="danger" (click)="returnSaleAlert(sale, group)" *ngIf="!sale.returnSaleId">
                    <ion-icon name="bag-remove-outline" size="large"></ion-icon>
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
            </div>

            <div *ngIf="group.visibleRows < group.sales.length" class="ion-text-center">
              <ion-button (click)="showMoreSales(group)">
                <ion-label>Показати ще</ion-label>
                <ion-icon slot="end" name="ellipsis-horizontal"></ion-icon>
              </ion-button>
            </div>
          </ion-list>
        </div>
      </ng-container>

      <ng-template #noData>
        <div class="no-data">
          <span>Немає продажів</span>
        </div>
      </ng-template>
    </ion-content>
  </section>

  <ng-template #loading>
    <div class="no-data">
      <span>
        <ion-spinner *ngIf="!sales; else noAccess"></ion-spinner>

        <ng-template #noAccess>
          {{ !shift ? 'Немає даних поточної зміни' : !user?.sale ? 'Обмежені права доступу' : ''}}
        </ng-template>
      </span>
    </div>
  </ng-template>
</ion-content>

<ion-footer *ngIf="user?.showCash">
  <ion-toolbar color="light">
    <ion-text color="dark" *ngIf="shift && user?.showCash; else noSummary">
      <ion-grid *ngIf="!isShiftRefreshing">
        <ion-row>
          <ion-col size="2"></ion-col>

          <ion-col size="8" class="summary">
            <div>Виручка<span class="value">{{ shift.totals.salesSummary | currency:'UAH':'symbol-narrow' }}</span></div>
            <div>Готівка<span class="value">{{ shift.totals.salesCashSummary | currency:'UAH':'symbol-narrow' }}</span></div>
            <div>Безготівка<span class="value" (click)="showNonCashSaleCount()">{{ shift.totals.salesCardSummary | currency:'UAH':'symbol-narrow' }}</span></div>
            <div>В касі<span class="value" (click)="showCashboxCalculation()">{{ shift.totals.cashRemain | currency:'UAH':'symbol-narrow' }}</span></div>
          </ion-col>

          <ion-col size="2"></ion-col>
        </ion-row>
      </ion-grid>

      <ion-row *ngIf="isShiftRefreshing" class="ion-align-items-center ion-justify-content-center ion-margin-top">
        <ion-spinner></ion-spinner>
      </ion-row>
    </ion-text>

    <ng-template #noSummary>
      <div class="no-data">
        <span>{{ !shift ? 'Немає даних поточної зміни' : !user?.showCash ? 'Обмежені права доступу' : '' }}</span>
      </div>
    </ng-template>
  </ion-toolbar>
</ion-footer>
