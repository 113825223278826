/**
 * Тип знижки/націнки (1 символ)
 */
export enum ShiftState {
  /**
   * `0` – зміну не відкрито
   */
  Closed = 0,

  /**
   * `1` – зміну відкрито
   */
  Opened = 1,
}
