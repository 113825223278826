import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { ToastService } from '../../../core/services/toast.service';
import { UtilsService } from '../../../core/services/utils.service';
import { LogsService } from '../../../settings/logs/logs.service';

@Component({
  selector: 'bk-log-view',
  templateUrl: './log-view.component.html',
  styleUrls: ['./log-view.component.scss'],
})
export class LogViewComponent implements OnInit {
  @Input() name: string;
  @Input() data: string;
  @Input() isLogPage = false;

  constructor(
    private logsService: LogsService,
    private clipboard: Clipboard,
    private navCtrl: NavController,
    private toastService: ToastService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit(): void {
    //
  }

  private get emptyData(): boolean {
    const result = this.data == null || this.data === '';

    if (result) {
      this.toastService.presentWarning('Немає даних для надсилання');
    }

    return result;
  }

  copyToClipboard(): void {
    if (this.emptyData) {
      return;
    }

    this.clipboard.copy(this.data);
    this.toastService.present('Дані скопійовано у буфер обміну');
  }

  sendByEmail(): void {
    if (this.emptyData) {
      return;
    }

    this.logsService.send(this.name, this.data, {
      useEmail: true,
      isDesktop: this.utilsService.isDesktop(),
    });

    if (this.isLogPage) {
      this.navCtrl.back();
    }
  }

  sendByRequest(): void {
    if (this.emptyData) {
      return;
    }

    this.logsService.send(this.name, this.data);

    if (this.isLogPage) {
      this.navCtrl.back();
    }
  }
}
