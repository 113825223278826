import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { UtilsService } from '../../../core/services/utils.service';
import { Presale } from '../../../presales/presale/presale.model';
import { Sale } from '../../../sales/sale/sale.model';
import { Product } from '../../../shop/products/product.model';
import { Refill } from '../../../warehouse/invoices/refill/refill.model';
import { PrinterConnectionMethod } from '../printer-connection-method.enum';
import { VIEW_COMPONENT_ID } from '../printer.const';
import { QzTrayService } from '../qz-tray/qz-tray.service';
import { ViewDialog } from '../view/view.dialog';

import { LabelPrinterData } from './label-printer-data.model';
import { LabelPrinterSettings } from './label-printer-settings.interface';
import { PRINTER_CLASS, PRINTER_URL } from './label-printer.const';
import { LabelPrinterFormatService } from './services/label-printer-format.service';
import { LabelPrinterPrintService } from './services/label-printer-print.service';
import { LabelPrinterStorageService } from './services/label-printer-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LabelPrinterService {
  private isDesktop: boolean;

  private connectionMethod = PrinterConnectionMethod.None;

  constructor(
    private modalCtrl: ModalController,
    private utilsService: UtilsService,
    private labelPrinterStorageService: LabelPrinterStorageService,
    private labelPrinterFormatService: LabelPrinterFormatService,
    private labelPrinterPrintService: LabelPrinterPrintService,
    private qzTrayService: QzTrayService,
  ) {
    this.isDesktop = this.utilsService.isDesktop();
  }

  async status(): Promise<{
    isPrinterAvailable: boolean;
    isAutoPrintAfterSale: boolean;
  }> {
    const settings = await this.getSettings();

    this.connectionMethod = settings.connectionMethod;

    return {
      isPrinterAvailable:
        this.isDesktop &&
        (this.connectionMethod === PrinterConnectionMethod.QZTray ||
          this.connectionMethod === PrinterConnectionMethod.Browser),
      isAutoPrintAfterSale: settings.autoPrintAfterSale,
    };
  }

  async getConnectionMethod(): Promise<PrinterConnectionMethod> {
    return this.labelPrinterStorageService.getConnectionMethod();
  }

  async getSettings(): Promise<LabelPrinterSettings> {
    return this.labelPrinterStorageService.getSettings();
  }

  async setSettings(data: LabelPrinterSettings): Promise<void> {
    this.connectionMethod = data.connectionMethod;

    await this.labelPrinterStorageService.setSettings(data);
  }

  async init(): Promise<void> {
    this.connectionMethod =
      await this.labelPrinterStorageService.getConnectionMethod();

    switch (this.connectionMethod) {
      case PrinterConnectionMethod.QZTray:
        await this.connectQzTray();
        break;

      case PrinterConnectionMethod.Bluetooth:
      case PrinterConnectionMethod.USB:
      case PrinterConnectionMethod.WiFi:
        break;

      default:
        break;
    }
  }

  async connectQzTray(): Promise<void> {
    await this.qzTrayService.startConnection();

    const settings = await this.getSettings();

    await this.qzTrayService.printerExist(settings.printer.name, {
      url: PRINTER_URL,
      printerClass: PRINTER_CLASS,
    });
  }

  async disconnectQzTray(): Promise<void> {
    await this.qzTrayService.endConnection();
  }

  async printPresale(
    presale: Presale,
    options: { viewMode?: boolean } = {
      viewMode: false,
    },
  ): Promise<void> {
    const data = await this.labelPrinterFormatService.formatPresale(presale);

    if (data == null) {
      return;
    }

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.labelPrinterPrintService.print(data);
    }
  }

  async printSale(
    sale: Sale,
    options: { viewMode?: boolean; silentPrint?: boolean } = {
      viewMode: false,
      silentPrint: false,
    },
  ): Promise<void> {
    const data = await this.labelPrinterFormatService.formatSale(sale);

    if (data == null) {
      return;
    }

    if (options.silentPrint != null) {
      data.silentPrint = options.silentPrint;
    }

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.labelPrinterPrintService.print(data);
    }
  }

  async printRefill(refill: Refill): Promise<void> {
    const data = await this.labelPrinterFormatService.formatRefill(refill);

    if (data == null) {
      return;
    }

    if (this.connectionMethod === PrinterConnectionMethod.Browser) {
      await this.openDialog(data);
    } else {
      this.labelPrinterPrintService.print(data);
    }
  }

  async printProduct(product: Product): Promise<void> {
    const data = await this.labelPrinterFormatService.formatProduct(product);

    if (data == null) {
      return;
    }

    if (this.connectionMethod === PrinterConnectionMethod.Browser) {
      await this.openDialog(data);
    } else {
      this.labelPrinterPrintService.print(data);
    }
  }

  private async openDialog(data: LabelPrinterData): Promise<void> {
    const status = await this.status();

    const modal = await this.modalCtrl.create({
      component: ViewDialog,
      componentProps: {
        data,
        isPrinterAvailable: status.isPrinterAvailable,
      },
      backdropDismiss: false,
      id: `${VIEW_COMPONENT_ID}_${data.type.toString()}`,
    });

    await modal.present();
  }
}
