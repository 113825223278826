export class PRro {
  id: number;
  companyName: string;
  companyEDRPOU: string;
  companyIPN: string;
  shopId: number;
  shopName: string;
  shopAddress: string;
  fiscalNumber: number;
  localNumber: number;
  lastDocNumber: number;
  lastZReportNumber: number;
  caption: string;
  syncShiftOpening: boolean;
  autoServiceIssue: boolean;
  testMode: boolean;

  prroObject?: {
    name: string;
    address: string;
    companyEntity: {
      name: string;
      EDRPOU: string;
      IPN: string;
    };
  };

  // App fields
  lastDocLocalNumber?: number;
  lastDocFiscalNumber?: string;
  discount?: number;
}
