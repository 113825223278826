import { Injectable } from '@angular/core';

import { IStoragePaymentTerminalData } from '../../core/interfaces/storage-payment-terminal-data.interface';
import { IStoragePaymentTerminalSettings } from '../../core/interfaces/storage-payment-terminal-settings.interface';
import { StorageTable } from '../../core/models/storage-table.model';
import {
  TERMINAL_DEFAULT_AUTO_DEBUG,
  TERMINAL_DEFAULT_MERCHANT_ID,
  TERMINAL_DEFAULT_SERVICE_REFUND,
} from '../settings.const';

import { TerminalConnectionMethod } from './enums/terminal-connection-method.enum';
import { TerminalConnectionProtocol } from './enums/terminal-connection-protocol.enum';
import {
  DEFAULT_AGENT_IP,
  KEY_AUTO_DEBUG,
  KEY_CONNECTION_METHOD,
  KEY_IP,
  KEY_MERCHANT_ID,
  KEY_SERVICE_REFUND,
  KEY_TAPXPHONE_DEVICE_ID,
  KEY_USB_PORT,
} from './terminals.const';

const KEY_CONNECTION_PROTOCOL = 'connectionProtocol';
const KEY_AGENT_IP = 'agentIp';
const KEY_ACQUIRER_NAME = 'acquirerName';
const KEY_DEVICE_ID = 'deviceId';

@Injectable({
  providedIn: 'root',
})
export class TerminalStorageService {
  private terminal: StorageTable;

  constructor() {
    this.terminal = new StorageTable('terminal');
  }

  async clearData(): Promise<void> {
    await this.terminal.clear();
  }

  async getTerminalData(): Promise<IStoragePaymentTerminalData> {
    const acquirerName = await this.getData(KEY_ACQUIRER_NAME);
    const deviceId = await this.getData(KEY_DEVICE_ID);
    const connectionProtocol = await this.getData(KEY_CONNECTION_PROTOCOL);
    const agentIp = await this.getData(KEY_AGENT_IP);

    const paymentTerminalData = {
      acquirerName: '',
      deviceId: '',
      connectionProtocol: TerminalConnectionProtocol.None,
      agentIp: DEFAULT_AGENT_IP,
    };

    if (acquirerName && typeof acquirerName === 'string') {
      paymentTerminalData.acquirerName = acquirerName;
    }

    if (deviceId && typeof deviceId === 'string') {
      paymentTerminalData.deviceId = deviceId;
    }

    if (connectionProtocol && typeof connectionProtocol === 'string') {
      paymentTerminalData.connectionProtocol =
        connectionProtocol as TerminalConnectionProtocol;
    }

    if (agentIp && typeof agentIp === 'string') {
      paymentTerminalData.agentIp = agentIp;
    }

    return paymentTerminalData;
  }

  async getSettings(): Promise<IStoragePaymentTerminalSettings> {
    const merchantId = await this.getData(KEY_MERCHANT_ID);
    const serviceRefund = await this.getData(KEY_SERVICE_REFUND);
    const autoDebug = await this.getData(KEY_AUTO_DEBUG);
    const connectionMethod = await this.getData(KEY_CONNECTION_METHOD);
    const ip = await this.getData(KEY_IP);
    const usbPort = await this.getData(KEY_USB_PORT);
    const tapXphoneDeviceId = await this.getData(KEY_TAPXPHONE_DEVICE_ID);

    return {
      merchantId: (merchantId ?? TERMINAL_DEFAULT_MERCHANT_ID) as number,
      serviceRefund: (serviceRefund ??
        TERMINAL_DEFAULT_SERVICE_REFUND) as boolean,
      autoDebug: (autoDebug ?? TERMINAL_DEFAULT_AUTO_DEBUG) as boolean,
      connectionMethod: (connectionMethod ??
        TerminalConnectionMethod.USB) as TerminalConnectionMethod,
      ip: (ip ?? '') as string,
      usbPort: usbPort ? (usbPort as number) : undefined,
      tapXphoneDeviceId: (tapXphoneDeviceId ?? '') as string,
    };
  }

  async setDefaultPaymentData(
    data: IStoragePaymentTerminalData,
  ): Promise<void> {
    await this.saveData(KEY_CONNECTION_PROTOCOL, data.connectionProtocol);
    await this.saveData(KEY_AGENT_IP, data.agentIp);
    await this.saveData(KEY_ACQUIRER_NAME, data.acquirerName);
    await this.saveData(KEY_DEVICE_ID, data.deviceId);
  }

  async setSettings(settings: IStoragePaymentTerminalSettings): Promise<void> {
    await this.saveData(KEY_MERCHANT_ID, settings.merchantId);
    await this.saveData(KEY_SERVICE_REFUND, settings.serviceRefund);
    await this.saveData(KEY_AUTO_DEBUG, settings.autoDebug);
    await this.saveData(KEY_CONNECTION_METHOD, settings.connectionMethod);
    await this.saveData(KEY_IP, settings.ip);
    await this.saveData(KEY_USB_PORT, settings.usbPort);
    await this.saveData(KEY_TAPXPHONE_DEVICE_ID, settings.tapXphoneDeviceId);
  }

  private async saveData(
    key: string,
    value: string | number | boolean | undefined,
  ): Promise<void> {
    await this.terminal.set<string | number | boolean | undefined>(key, value);
  }

  private async getData(
    key: string,
  ): Promise<string | number | boolean | null> {
    return await this.terminal.get<string | number | boolean>(key);
  }
}
