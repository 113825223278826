import { Injectable } from '@angular/core';
import {
  PrinterToUse,
  PrintFormattedText,
  ThermalPrinterPlugin,
} from 'thermal-printer-cordova-plugin/src';

import { ToastService } from '../../../../core/services/toast.service';
import { PrinterConnectionMethod } from '../../printer-connection-method.enum';
import { Printer } from '../../printer.model';
import { ReceiptPrinterData } from '../receipt-printer-data.model';
import {
  PRINTER_MODEL_OTHER,
  PRINTER_MODEL_SUNMI_V2,
} from '../receipt-printer.const';

declare let ThermalPrinter: ThermalPrinterPlugin;

@Injectable({
  providedIn: 'root',
})
export class ReceiptPrinterThermalPrinterService {
  constructor(private toastService: ToastService) {}

  async openCashDrawer(data: ReceiptPrinterData): Promise<void> {
    const formattedText = this.formatData(
      data,
      String.fromCharCode(0x1b, 0x70, 0x00),
    );

    this.selectPrinterAndSend(data, formattedText);
  }

  async print(data: ReceiptPrinterData): Promise<void> {
    const formattedText = this.prepareReceipt(data);

    this.selectPrinterAndSend(data, formattedText);
  }

  private prepareReceipt(data: ReceiptPrinterData): PrintFormattedText {
    const receipt = data.doc.receipt();

    if (data.settings.model === PRINTER_MODEL_SUNMI_V2) {
      receipt.forEach((row, index) => {
        const modifiedRow = row
          .replace(new RegExp('і', 'g'), 'i')
          .replace(new RegExp('І', 'g'), 'I')
          .replace(new RegExp('ґ', 'g'), 'г')
          .replace(new RegExp('Ґ', 'g'), 'Г')
          .replace(new RegExp('’', 'g'), "'");

        receipt[index] = modifiedRow;
      });
    }

    for (let i = 0; i < data.settings.linesToCut; i += 1) {
      receipt.push(`[L]`);
    }

    return this.formatData(data, receipt.join('\n'));
  }

  private formatData(
    data: ReceiptPrinterData,
    text: string,
  ): PrintFormattedText {
    const formattedText: PrintFormattedText = {
      text,
      type: 'usb',
      id: '',
      printerWidthMM: data.settings.paperWidth,
      printerNbrCharactersPerLine: data.settings.rowLength,
      model:
        data.settings.model === PRINTER_MODEL_SUNMI_V2
          ? PRINTER_MODEL_SUNMI_V2
          : PRINTER_MODEL_OTHER,
      charsetEncoding: {
        charsetName: data.settings.charsetName,
        charsetId: data.settings.charsetId,
        turnOffChinese: data.settings.turnOffChinese,
      },
    };

    switch (data.settings.connectionMethod) {
      case PrinterConnectionMethod.Bluetooth:
        formattedText.type = 'bluetooth';
        formattedText.id = data.settings.mac;

        return formattedText;

      case PrinterConnectionMethod.WiFi:
        formattedText.type = 'tcp';
        formattedText.address = data.settings.ip;
        formattedText.port = 9100;
        formattedText.id = `${formattedText.address}:${formattedText.port}`;

        return formattedText;

      default:
        return formattedText;
    }
  }

  private selectPrinterAndSend(
    data: ReceiptPrinterData,
    formattedText: PrintFormattedText,
  ): void {
    switch (data.settings.connectionMethod) {
      case PrinterConnectionMethod.Bluetooth:
        ThermalPrinter.requestBTPermissions(
          {
            type: 'bluetooth',
            id: data.settings.mac,
          },
          () => {
            this.sendToPrinter(formattedText);
          },
          (error) => {
            this.toastService.presentError(
              'Bluetooth-принтер',
              `Немає доступу: ${JSON.stringify(error)}`,
            );
          },
        );
        break;
      case PrinterConnectionMethod.USB:
        const TOAST_TITLE = 'USB-принтер';
        ThermalPrinter.listPrinters(
          { type: 'usb' },
          (usbPrinters) => {
            if (usbPrinters.length > 0) {
              const currentPrinter = usbPrinters.find(
                (printer) =>
                  Printer.usbName(
                    printer.manufacturerName,
                    printer.productName,
                  ) === data.settings.printer.name,
              );
              const usbPrinter: PrinterToUse = {
                type: 'usb',
                id:
                  (currentPrinter != null
                    ? currentPrinter.deviceId
                    : usbPrinters[0].deviceId) ?? '',
              };
              ThermalPrinter.requestPermissions(
                usbPrinter,
                () => {
                  formattedText.type = usbPrinter.type;
                  formattedText.id = usbPrinter.id;
                  this.sendToPrinter(formattedText);
                },
                (error) => {
                  this.toastService.presentError(
                    TOAST_TITLE,
                    `Немає доступу: ${JSON.stringify(error)}`,
                  );
                },
              );
            } else {
              this.toastService.presentWarning(
                TOAST_TITLE,
                'Жодного принтера не знайдено',
              );
            }
          },
          (error) => {
            this.toastService.presentError(
              TOAST_TITLE,
              `Помилка отримання списку принтерів: ${JSON.stringify(error)}`,
            );
          },
        );
        break;
      case PrinterConnectionMethod.WiFi:
        this.sendToPrinter(formattedText);
        break;
      default:
        break;
    }
  }

  private sendToPrinter(formattedText: PrintFormattedText): void {
    ThermalPrinter.printFormattedTextAndCut(
      formattedText,
      () => {
        this.disconnectThermalPrinter(formattedText);
      },
      (error) => {
        this.toastService.presentError('Printing error', JSON.stringify(error));
      },
    );
  }

  private disconnectThermalPrinter(formattedText: PrintFormattedText): void {
    ThermalPrinter.disconnectPrinter(
      {
        type: formattedText.type,
        id: formattedText.id,
        address: formattedText.address,
        port: formattedText.port,
      },
      () => {
        //
      },
      (error) => {
        this.toastService.presentError(
          'Відключення принтера',
          JSON.stringify(error),
        );
      },
    );
  }
}
