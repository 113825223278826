import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Resource } from '../../core/abstract/resource';
import { ObjectLiteral } from '../../core/interfaces/object-literal';
import { CachedDataService } from '../../core/services/cached-data.service';

import { Supply } from './supply.model';

@Injectable({
  providedIn: 'root',
})
export class SuppliesService extends Resource<Supply> {
  constructor(
    protected http: HttpClient,
    private cachedDataService: CachedDataService,
  ) {
    super(http, {
      path: '/supplies',
    });
  }

  find(options: ObjectLiteral = {}): Observable<Supply[]> {
    options = { ...options, shopId: this.cachedDataService.getShopId() };

    return super
      .find(options)
      .pipe(
        map((supplies) =>
          orderBy(supplies, [
            'ingredient.category.position',
            'ingredient.position',
          ]),
        ),
      );
  }
}
