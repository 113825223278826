import { PayFormName } from '../../../../p-rro/fsco/enums/pay-form-name.enum';
import {
  CASH_REMAIN_CAPTION,
  DEPOSIT_CAPTION,
  DISCOUNT_SUM_CAPTION,
  FINANCE_EXPENSES_CAPTION,
  INCASATION_CAPTION,
  MOVE_CAPTION,
  MOVE_IN_CAPTION,
  MOVE_STOCK_IN_CAPTION,
  REALIZ_SUM_CAPTION,
  REFILL_EXPENSE_CAPTION,
  RETURN_SUM_CAPTION,
  REVENUE_CAPTION,
  SHIFT_FINISH_CAPTION,
  SHIFT_START_CAPTION,
  SHIFT_TOTALS_REPORT_CAPTION,
  WASTE_CAPTION,
  WASTE_RETURN_RECEIVED_CAPTION,
} from '../receipt.const';

import { PrintReceipt } from './print-receipt.model';
import { PrintShiftTotals } from './print-shift-totals.model';
import { PrintShiftUser } from './print-shift-user.model';

export class PrintShift extends PrintReceipt {
  shopName: string;
  createdAt: string;
  closedAt: string;

  shiftUsers: PrintShiftUser[] = [];
  totals: PrintShiftTotals;

  receipt(): string[] {
    const receipt: string[] = [];

    receipt.push(...this.alignToCenter(`${this.shopName}`, { isBold: true }));
    receipt.push(
      ...this.alignToCenter(`${SHIFT_TOTALS_REPORT_CAPTION}`, { isBold: true }),
    );

    this.addTwoColumnItemToReceipt(
      SHIFT_START_CAPTION,
      this.createdAt,
      receipt,
    );

    if (this.closedAt > '') {
      this.addTwoColumnItemToReceipt(
        SHIFT_FINISH_CAPTION,
        this.closedAt,
        receipt,
      );
    }

    receipt.push(this.rowDevider());

    for (const shiftUser of this.shiftUsers) {
      this.addTwoColumnItemToReceipt(
        shiftUser.name,
        shiftUser.createdAt,
        receipt,
        { isBold: true },
      );

      if (shiftUser.totals == null) {
        continue;
      }

      this.addTwoColumnItemToReceipt(
        `${REALIZ_SUM_CAPTION} (${shiftUser.totals.salesCount})`,
        shiftUser.totals.salesTotalAmount,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `> ${PayFormName.Card}`,
        shiftUser.totals.salesCardAmount,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `> ${PayFormName.Cash}`,
        shiftUser.totals.salesCashAmount,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `${RETURN_SUM_CAPTION} (${shiftUser.totals.saleReturnsCount})`,
        shiftUser.totals.saleReturnsTotalAmount,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `> ${PayFormName.Card}`,
        shiftUser.totals.saleReturnsCardAmount,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `> ${PayFormName.Cash}`,
        shiftUser.totals.saleReturnsCashAmount,
        receipt,
      );

      this.addShiftRevenueDataToReceipt(shiftUser.totals, receipt);

      this.addTwoColumnItemToReceipt(
        `+ ${DEPOSIT_CAPTION}`,
        shiftUser.totals.cashDeposits,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `- ${INCASATION_CAPTION}`,
        shiftUser.totals.cashIncasations,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `- ${FINANCE_EXPENSES_CAPTION}`,
        shiftUser.totals.cashFinanceExpenses,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `- ${REFILL_EXPENSE_CAPTION} (${shiftUser.totals.refillsCount})`,
        shiftUser.totals.refillsPaidCashAmount,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `+ ${WASTE_RETURN_RECEIVED_CAPTION} (${shiftUser.totals.wasteReturnsCount})`,
        shiftUser.totals.wasteReturnsReceivedCashAmount,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `= ${CASH_REMAIN_CAPTION}`,
        shiftUser.totals.cashRemain,
        receipt,
        { isBold: true },
      );

      this.addTwoColumnItemToReceipt(
        `${WASTE_CAPTION}`,
        shiftUser.totals.wastesCount,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `${MOVE_CAPTION}`,
        shiftUser.totals.movesOutCount,
        receipt,
      );

      receipt.push(this.rowDevider());
    }

    this.addTwoColumnItemToReceipt(
      `${MOVE_IN_CAPTION}`,
      this.totals.movesInCount,
      receipt,
    );

    this.addTwoColumnItemToReceipt(
      `${MOVE_STOCK_IN_CAPTION}`,
      this.totals.movesInFromStockCount,
      receipt,
    );

    if (this.shiftUsers.length > 1) {
      this.addShiftRevenueDataToReceipt(this.totals, receipt);

      this.addTwoColumnItemToReceipt(
        `${CASH_REMAIN_CAPTION}`,
        this.totals.cashRemain,
        receipt,
        { isBold: true },
      );
    }

    receipt.push(...this.alignToCenter(this.printTime));

    return receipt;
  }

  private addShiftRevenueDataToReceipt(
    totals: PrintShiftTotals,
    receipt: string[],
  ): void {
    this.addTwoColumnItemToReceipt(
      `${DISCOUNT_SUM_CAPTION}`,
      totals.salesDiscountSummary,
      receipt,
    );

    this.addTwoColumnItemToReceipt(
      `${REVENUE_CAPTION}`,
      totals.salesSummary,
      receipt,
    );

    this.addTwoColumnItemToReceipt(
      PayFormName.Card,
      totals.salesCardSummary,
      receipt,
    );

    this.addTwoColumnItemToReceipt(
      PayFormName.Cash,
      totals.salesCashSummary,
      receipt,
      { isBold: true },
    );
  }
}
