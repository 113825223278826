import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Resource } from '../../../core/abstract/resource';
import { RECEIPT_PRINTER_REFRESH } from '../../../core/constants/events.const';
import { ObjectLiteral } from '../../../core/interfaces/object-literal';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { Events } from '../../../core/services/events.service';
import { ShiftsService } from '../../../core/services/resources/shifts.service';
import { ReceiptPrinterService } from '../../../settings/printer/receipt/receipt-printer.service';

import { Refill } from './refill.model';

@Injectable({
  providedIn: 'root',
})
export class RefillsService extends Resource<Refill> {
  private isPrintReceiptMode = false;

  constructor(
    protected http: HttpClient,
    private events: Events,
    private platform: Platform,
    private cachedDataService: CachedDataService,
    private receiptPrinterService: ReceiptPrinterService,
    private shiftsService: ShiftsService,
  ) {
    super(http, {
      path: '/refills',
    });

    this.events.subscribe(
      RECEIPT_PRINTER_REFRESH,
      (status: { isAvailible: boolean; isAutoPrintMode: boolean }) => {
        this.isPrintReceiptMode = status.isAvailible;
      },
    );

    this.refreshReceiptPrintStatus();
  }

  find(options: ObjectLiteral = {}): Observable<Refill[]> {
    options = {
      ...options,
      shopId: this.cachedDataService.getShopId(),
      updatedView: true,
    };

    return super.find(options).pipe(
      map((refills) =>
        refills.map((refill) => this.transformFromBackend(refill)),
      ),
      map((refills) => orderBy(refills, 'createdAt', 'desc')),
    );
  }

  get(id: number | string): Observable<Refill> {
    return super
      .get(id)
      .pipe(map((refill) => this.transformFromBackend(refill)));
  }

  create(refill: Refill): Observable<Refill> {
    refill.shopId = this.cachedDataService.getShopId();
    refill.userId = this.cachedDataService.getUser().id;

    return super.create(this.transformToBackend(refill)).pipe(
      tap(async (createdRefill) => {
        await this.print(createdRefill);
      }),
    );
  }

  update(id: number | string, refill: Refill): Observable<Refill> {
    return super
      .update(id, this.transformToBackend(refill), {
        updatedDoc: true,
      })
      .pipe(
        tap(async (createdRefill) => {
          await this.print(createdRefill);
        }),
      );
  }

  private transformFromBackend(refill: Refill): Refill {
    const isDesktop =
      !this.platform.is('cordova') &&
      !this.platform.is('android') &&
      !this.platform.is('ios');

    const refillClone = cloneDeep(refill);

    refillClone.createdAt = new Date(refillClone.createdAt);
    refillClone.providerName = refillClone.provider.name;

    refillClone.typeName =
      refillClone.refillAmount > refillClone.paidAmount ? 'В борг' : 'Оплачена';

    const user = this.cachedDataService.getUser();

    refillClone.canView = refillClone.userId !== user.id;
    refillClone.canCopy = refillClone.userId === user.id;
    refillClone.canPrint = true;
    refillClone.canPrintLabel = isDesktop;
    refillClone.canUpdate = refillClone.userId === user.id;
    refillClone.canDelete = refillClone.userId === user.id;

    return refillClone;
  }

  private transformToBackend(refill: Refill): Refill {
    const refillClone = cloneDeep(refill);

    refillClone.shiftId = this.shiftsService.getCurrentShiftId();
    refillClone.shiftRefill = true;

    refillClone.refillIngredients.forEach((refillIngredient) => {
      if (refillIngredient.salePrice != null) {
        refillIngredient.productPrice = refillIngredient.salePrice;
      } else {
        Reflect.deleteProperty(refillIngredient, 'productId');
        Reflect.deleteProperty(refillIngredient, 'productPrice');
      }
    });

    return refillClone;
  }

  private refreshReceiptPrintStatus(): void {
    this.receiptPrinterService.status().then((status) => {
      this.isPrintReceiptMode = status.isPrinterAvailable;
    });
  }

  private async print(refill: Refill): Promise<void> {
    if (this.isPrintReceiptMode && Math.abs(refill.paidAmount ?? 0) > 0) {
      await this.receiptPrinterService.openCashDrawer();
    }
  }
}
