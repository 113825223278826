import { formatDate } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import JsBarcode from 'jsbarcode';
import 'svg2pdf.js';

import { DATE_FORMAT } from '../../../../core/constants/date.const';
import {
  EAN_13_LENGTH,
  PAPER_40_X_25,
  PAPER_60_X_40,
} from '../../../../settings/printer/label/label-printer.const';
import { LabelPrinterData } from '../../label/label-printer-data.model';
import { Label } from '../../label/label.enum';
import { PrintLabelPdfSettings } from '../../label/types/print-label-pdf-settings.model';

@Component({
  selector: 'bk-view-labels',
  templateUrl: './view-labels.component.html',
  styleUrls: ['./view-labels.component.scss'],
})
export class ViewLabelsComponent implements OnInit, AfterViewInit {
  @Input() data: LabelPrinterData;

  label = Label;

  labelConfig: PrintLabelPdfSettings;

  constructor() {}

  ngOnInit(): void {
    this.labelConfig = new PrintLabelPdfSettings(this.data.settings);
  }

  ngAfterViewInit(): void {
    if (this.data.type === Label.Product) {
      this.generateBarcodes();
    }
  }

  is40x25(): boolean {
    return this.labelConfig.isFormat(PAPER_40_X_25);
  }

  is60x40(): boolean {
    return this.labelConfig.isFormat(PAPER_60_X_40);
  }

  generateBarcodes(): void {
    this.data.products.forEach((product, index) => {
      const barcode = (product.barcode ?? '')
        .split(';')
        .filter((b) => b.length >= EAN_13_LENGTH)[0];

      JsBarcode(`#barcode_${index}`, barcode, {
        format: this.labelConfig.barcode.format,
        fontSize: this.labelConfig.barcode.fontSize,
        fontOptions: 'bold',
        width: this.labelConfig.barcode.width,
        height: this.labelConfig.barcode.height,
        margin: this.labelConfig.barcode.margin,
      });
    });
  }

  date(): string {
    return `${formatDate(new Date(), DATE_FORMAT, 'uk-UA')}`;
  }
}
