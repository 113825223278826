<div class="cd__container">
  <div class="cd__title">{{ title }}</div>
  <div class="cd__subtitle" *ngIf="subtitle">{{ subtitle }}</div>
  <div class="cd__message" *ngIf="message">{{ message }}</div>

  <div class="cd__print" *ngIf="printDialog">
    <ion-item lines="none">
      <bk-icon slot="start" name="print"></bk-icon>
      <ion-label>Друкувати</ion-label>
      <ion-checkbox slot="end" color="dark" [(ngModel)]="needPrint"></ion-checkbox>
    </ion-item>
  </div>

  <div class="cd__print" *ngIf="sendDialog">
    <ion-item lines="none">
      <ion-icon slot="start" name="send"></ion-icon>
      <ion-label>Надіслати дані у службу підтримки</ion-label>
      <ion-checkbox slot="end" color="dark" [(ngModel)]="needSendEmail"></ion-checkbox>
    </ion-item>
  </div>

  <div class="cd__action">
    <ion-button color="tertiary" (click)="cancelModal()">Скасувати</ion-button>
    <ion-button color="primary" (click)="confirmModal()">{{ btnConfirm }}</ion-button>
  </div>
</div>
