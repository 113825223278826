import { SalePayment } from '../../../sales/sale/sale-payment.model';
import { CheckDocumentSubType } from '../enums/check-document-sub-type.enum';
import { CheckDocumentType } from '../enums/check-document-type.enum';

import { PRroSaleItem } from './p-rro-sale-item.model';
import { PRroSaleTax } from './p-rro-sale-tax.model';
import { PRroServiceCheck } from './p-rro-service-check.model';

export class PRroSaleCheck extends PRroServiceCheck {
  /**
   * Загальна сума знижки (15.2 цифри)
   */
  discountSum: number;

  /**
   * Заокруглення (15.2 цифри)
   */
  roundSum: number;

  /**
   * Загальна сума після заокруглення (15.2 цифри)
   */
  paymentSum: number;

  /**
   * Сума внесених коштів (15.2 цифри)
   */
  providedSum: number;

  /**
   * Решта (15.2 цифри)
   *
   * (не зазначається, якщо решта відсутня)
   */
  remainsSum: number;

  payments: SalePayment[];
  items: PRroSaleItem[];
  taxes: PRroSaleTax[];

  constructor(date?: Date) {
    super(CheckDocumentType.SaleGoods, CheckDocumentSubType.CheckGoods, date);

    this.payments = [];
    this.items = [];
    this.taxes = [];
  }
}
