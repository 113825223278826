import { formatDate, formatNumber } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PRRO_STATE } from '../core/constants/events.const';
import { CachedDataService } from '../core/services/cached-data.service';
import { Events } from '../core/services/events.service';
import { LoadingService } from '../core/services/loading.service';
import { ShiftsService } from '../core/services/resources/shifts.service';
import { ToastService } from '../core/services/toast.service';
import { UtilsService } from '../core/services/utils.service';
import { Sale } from '../sales/sale/sale.model';
import { Incasation } from '../transactions/transaction/models/incasation.model';
import { Transaction } from '../transactions/transaction/models/transaction.model';
import { TransactionType } from '../transactions/transaction/transaction-type.enum';

import { SelectPRroDialog } from './components/select-p-rro/select-p-rro.dialog';
import { TaxQrDialog } from './components/tax-qr/tax-qr.dialog';
import { CheckDocumentSubTypeName } from './fsco/enums/check-document-sub-type-name.enum';
import { CheckDocumentSubType } from './fsco/enums/check-document-sub-type.enum';
import { CheckDocumentTypeName } from './fsco/enums/check-document-type-name.enum';
import { CheckDocumentType } from './fsco/enums/check-document-type.enum';
import { DocumentClassName } from './fsco/enums/document-class-name.enum';
import { DocumentClass } from './fsco/enums/document-class.enum';
import { DocumentRequestType } from './fsco/enums/document-request-type.enum';
import { ShiftState } from './fsco/enums/shift-state.enum';
import { FSCOService } from './fsco/fsco.service';
import { CertSubject } from './fsco/interfaces/cert-subject.interface';
import { DocumentItem } from './fsco/interfaces/responses/items/document-item.interface';
import { ResponseDocInfoByLocalNum } from './fsco/interfaces/responses/response-doc-info-by-local-num.interface';
import { ResponseDocuments } from './fsco/interfaces/responses/response-documents.interface';
import { ResponseLastShiftTotals } from './fsco/interfaces/responses/response-last-shift-totals.interface';
import { ResponsePRROState } from './fsco/interfaces/responses/response-prro-state.interface';
import { PRroCheck } from './fsco/models/p-rro-check.model';
import { PRroDoc } from './fsco/models/p-rro-doc.model';
import { PRroReturnCheck } from './fsco/models/p-rro-return-check.model';
import { PRroServiceCheck } from './fsco/models/p-rro-service-check.model';
import { PRroTicket } from './fsco/models/p-rro-ticket.model';
import { PRroZReport } from './fsco/models/p-rro-z-report.model';
import { PRro } from './p-rro.model';
import { PRroResult } from './types/p-rro-result.interface';
import { ShopCryptData } from './types/shop-crypt-data.model';

const LOADING_ID = 'prro.service';
const OPERATION_NOTE = ' з використанням ПРРО';

@Injectable({
  providedIn: 'root',
})
export class PRroService {
  // Returns a Promise that resolves after "ms" Milliseconds
  timer = (ms: number) => new Promise((res) => setTimeout(res, ms));

  constructor(
    protected http: HttpClient,
    private events: Events,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private cachedDataService: CachedDataService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private utilsService: UtilsService,
    private shiftsService: ShiftsService,
    private fscoService: FSCOService,
  ) {}

  //#region Init Crypt
  isCryptInit(): boolean {
    return this.fscoService.isInit();
  }

  updateTSPStatus(value: boolean): void {
    this.fscoService.updateTSPStatus(value);
  }

  async initCrypt(
    cryptData: ShopCryptData,
    options: { refresh: boolean; localMode?: boolean } = {
      refresh: false,
      localMode: false,
    },
  ): Promise<CertSubject | null> {
    const loadingId = `${LOADING_ID}:init-crypt`;
    const appSettings = this.cachedDataService.getAppSettings();
    const userSettings = this.cachedDataService.getUserSettings();

    await this.loadingService.presentCustomPreloader(
      loadingId,
      'Активація УЕП',
    );

    const { certSubject, report } = await this.fscoService.init(
      cryptData,
      {
        corsProxyUrl: appSettings.corsProxyUrl,
        fscoApiUrl: this.cachedDataService.getDefaultDpsApiUrl(),
        fscoReserveApiUrl: this.cachedDataService.getReserveDpsApiUrl(),
      },
      {
        ...options,
        useTSP: userSettings.useTSP,
        showTSPError: (
          responseError: HttpErrorResponse | string,
          additionalData?: string,
        ) => {
          if (typeof responseError === 'string') {
            this.toastService.presentWarning(responseError, additionalData);
          } else {
            const httpError = this.utilsService.getParsedError(responseError);

            this.utilsService.showErrorToast(httpError, {
              note: `Сервер ${additionalData ?? 'КНЕДП'} недоступний${
                httpError.statusCode ? '' : ' (немає мережі Інтернет)'
              }`,
            });
          }
        },
      },
    );

    await this.loadingService.dismiss(loadingId);

    if (certSubject == null) {
      if (report > '') {
        this.toastService.presentError('Помилка активації УЕП', report);
      }

      return null;
    }

    if (certSubject?.validTo != null) {
      const daysDifferent = DateTime.fromJSDate(certSubject.validTo).diffNow(
        'days',
      ).days;

      if (daysDifferent < 15) {
        this.toastService.presentWarning(
          'ПРРО',
          `Термін дії УЕП закінчується ${formatDate(
            certSubject.validTo,
            'dd.MM.yyyy',
            'uk_UA',
          )}`,
        );
      }
    }

    return certSubject;
  }

  //#region Fiscalize
  async fiscalize(
    doc: PRroCheck,
    options: { saveDoc: boolean; updatePRroFields?: Partial<PRroDoc> } = {
      saveDoc: false,
    },
  ): Promise<PRroResult | null> {
    let result: PRroResult | null = null;

    const ticket = await this.sendDocument(doc);

    const prro = this.cachedDataService.getPRRO();
    const prroShift = this.cachedDataService.getPRROShift();

    if (ticket != null) {
      result = {
        prroId: prro.id,
        prroShiftId: prroShift.ShiftId,
        prroLocalNumber: doc.localNumber,
        prroTaxNumber: ticket.ORDERTAXNUM ?? '',
        note: OPERATION_NOTE,
      };
    } else {
      await this.timer(1000);

      const dpsDoc = await this.getDocumentInfoByLocalNum(
        prro.fiscalNumber,
        doc.localNumber,
      );

      if (dpsDoc != null) {
        result = {
          prroId: prro.id,
          prroShiftId: prroShift.ShiftId,
          prroLocalNumber: doc.localNumber,
          prroTaxNumber: dpsDoc.NumFiscal,
          note: OPERATION_NOTE,
        };
      }
    }

    if (options.saveDoc && result != null) {
      this.savePRroDoc(doc, result.prroTaxNumber, {
        ...options.updatePRroFields,
      });
    }

    return result;
  }

  async fiscalizeServiceDoc(
    doc: Transaction,
    options: { saveDoc: boolean; updatePRroFields?: Partial<PRroDoc> } = {
      saveDoc: false,
    },
  ): Promise<PRroResult | null> {
    const serviceCheck = new PRroServiceCheck(
      CheckDocumentType.SaleGoods,
      doc.type === TransactionType.Deposit
        ? CheckDocumentSubType.ServiceDeposit
        : CheckDocumentSubType.ServiceIssue,
    );

    serviceCheck.totalSum = doc.amount;
    serviceCheck.uId = doc.uuid;

    if (options.updatePRroFields == null) {
      options.updatePRroFields = {};
    }

    options.updatePRroFields = {
      cashSum: doc.amount,
      ...options.updatePRroFields,
    };

    return this.fiscalize(serviceCheck, options);
  }

  private async savePRroDoc(
    docData: PRroCheck | PRroZReport,
    ticketOrderTaxNum: string,
    updateFields: Partial<PRroDoc> = {},
  ): Promise<void> {
    if (ticketOrderTaxNum === '') {
      return;
    }

    const docClass =
      docData instanceof PRroZReport ? DocumentClass.ZRep : DocumentClass.Check;

    const prro = this.cachedDataService.getPRRO();
    const doc = new PRroDoc();

    if (docData instanceof PRroZReport) {
      doc.docData = await this.getDocExtXML(
        prro.fiscalNumber,
        DocumentClassName.ZRep,
        { fiscal: ticketOrderTaxNum },
      );

      doc.jsonData = this.transformZReportToBackend(docData);
    } else if (docData instanceof PRroCheck) {
      doc.checkDocType = docData.checkType;

      if (
        docData instanceof PRroServiceCheck ||
        docData instanceof PRroReturnCheck
      ) {
        doc.checkDocSubType = docData.checkSubType;
      }
    }

    if (updateFields.saleId != null) {
      doc.saleId = updateFields.saleId;
    } else if (updateFields.incasationId != null) {
      doc.incasationId = updateFields.incasationId;
    } else if (updateFields.expenseId != null) {
      doc.expenseId = updateFields.expenseId;
    }

    if (updateFields.cashSum != null) {
      doc.cashSum = updateFields.cashSum;
    }

    if (updateFields.cardSum != null) {
      doc.cardSum = updateFields.cardSum;
    }

    const shift = this.cachedDataService.getShift();

    if (shift?.id != null) {
      doc.shiftId = shift?.id;
    }

    doc.shopId = this.cachedDataService.getShopId();
    doc.userId = this.cachedDataService.getUser().id;
    doc.prroId = prro.id;
    doc.prroShiftId = this.cachedDataService.getPRROShift().ShiftId;
    doc.docDateTime = docData.date;
    doc.localNumber = docData.localNumber;
    doc.taxNumber = ticketOrderTaxNum;
    doc.docClass = docClass;
    doc.revoked = false;
    doc.storned = false;

    if (doc.checkDocType === CheckDocumentType.OpenShift) {
      doc.testMode = this.cachedDataService.getPRROShift().Testing;
    }

    await this.http
      .post(`/p-rro/doc`, doc)
      .toPromise()
      .then(() => {
        //
      })
      .catch(() => {
        //
      });
  }

  private transformZReportToBackend(docData: PRroZReport): PRroZReport {
    const zReport = cloneDeep(docData);

    Reflect.deleteProperty(zReport, 'date');
    Reflect.deleteProperty(zReport, 'taxNumber');
    Reflect.deleteProperty(zReport, 'localNumber');
    Reflect.deleteProperty(zReport, 'uId');
    Reflect.deleteProperty(zReport, 'opened');
    Reflect.deleteProperty(zReport, 'fiscalType');

    return zReport;
  }

  //#region Send
  private async sendDocument(
    docData: PRroCheck | PRroZReport,
  ): Promise<PRroTicket | null> {
    const prro = this.cachedDataService.getPRRO();
    const response = await this.fscoService.sendDocument(docData, prro);

    if (response.statusCode === 200) {
      if (response.data != null) {
        this.cachedDataService.updateLastDocNumber(
          response.data.ORDERNUM,
          response.data.ORDERTAXNUM,
        );

        this.updateCurrentDocNumber(response.data.ORDERNUM);
      }
    } else {
      await this.getPRROState();
    }

    return response.data;
  }

  private updateCurrentDocNumber(newDocNumber: number): void {
    const id = this.cachedDataService.getPRRO().id;

    this.http
      .post<void>(`/p-rro/${id}/last-doc-number`, { newDocNumber })
      .toPromise()
      .then((_) => true)
      .catch((_) => false);
  }

  //#region Requests
  async getServerState(): Promise<{ state: boolean; message: string }> {
    const serverStateResponse = await this.fscoService.getServerState();

    if (serverStateResponse.data == null) {
      return { state: false, message: '' };
    }

    return {
      state: true,
      message: formatDate(
        new Date(serverStateResponse.data.Timestamp),
        'dd MMMM yyyy HH:mm:ss',
        'uk-UA',
      ),
    };
  }

  async getPRROState(
    prroFiscalNumber?: number,
    offlineSessionId: string = '',
    offlineSeed: string = '',
  ): Promise<ResponsePRROState | null> {
    if (prroFiscalNumber == null) {
      prroFiscalNumber = this.cachedDataService.getPRRO().fiscalNumber;
    }

    const prroStateResponse = await this.fscoService.getPRROState(
      prroFiscalNumber,
      offlineSessionId,
      offlineSeed,
    );

    if (prroStateResponse.data != null) {
      if (prroStateResponse.data.ShiftState === ShiftState.Opened) {
        const currentShift = this.cachedDataService.getPRROShift();

        if (currentShift == null || currentShift.OpenShiftDateTime == null) {
          await this.getOpenShiftDateTime(
            prroFiscalNumber,
            prroStateResponse.data,
          );
        } else {
          prroStateResponse.data.OpenShiftDateTime = new Date(
            currentShift.OpenShiftDateTime,
          );

          const shiftDuration = DateTime.fromJSDate(
            prroStateResponse.data.OpenShiftDateTime,
          ).diffNow(['hours']);

          if (Math.abs(shiftDuration.hours) > 22) {
            await this.getOpenShiftDateTime(
              prroFiscalNumber,
              prroStateResponse.data,
            );
          }
        }
      }

      this.cachedDataService.setPRROShift(prroStateResponse.data);
      this.cachedDataService.updateLastDocNumber(
        prroStateResponse.data.NextLocalNum - 1,
        prroStateResponse.data.LastFiscalNum ?? undefined,
      );
    }

    this.events.publish(PRRO_STATE, prroStateResponse.data);

    return prroStateResponse.data;
  }

  private async getOpenShiftDateTime(
    prroFiscalNumber: number,
    prroState: ResponsePRROState,
  ): Promise<void> {
    const shiftDocs = await this.getDocuments(
      prroFiscalNumber,
      prroState.ShiftId,
    );

    if (shiftDocs?.Documents != null) {
      const openShiftDoc = shiftDocs.Documents.find(
        (doc) => doc.NumFiscal === prroState.OpenShiftFiscalNum,
      );

      if (openShiftDoc != null) {
        prroState.OpenShiftDateTime = new Date(openShiftDoc.DocDateTime);
      }
    } else {
      this.toastService.presentWarning(
        'ПРРО',
        `Не вдалося отримати з ДПС інформацію про фіскальну зміну`,
      );
    }
  }

  async getLastShiftTotals(
    prroFiscalNumber: number,
  ): Promise<ResponseLastShiftTotals | null> {
    const lastShiftTotalsResponse = await this.fscoService.getLastShiftTotals(
      prroFiscalNumber,
    );

    return lastShiftTotalsResponse.data;
  }

  async getDocuments(
    prroFiscalNumber: number,
    shiftId?: number,
    openShiftFiscalNum?: string,
  ): Promise<ResponseDocuments | null> {
    const documentsResponse = await this.fscoService.getDocuments(
      prroFiscalNumber,
      shiftId,
      openShiftFiscalNum,
    );

    return documentsResponse.data;
  }

  async getDocumentInfoByLocalNum(
    prroFiscalNumber: number,
    docLocalNumber: number,
  ): Promise<ResponseDocInfoByLocalNum | null> {
    const documentResponse = await this.fscoService.getDocumentInfoByLocalNum(
      prroFiscalNumber,
      docLocalNumber,
    );

    return documentResponse.data;
  }

  async getDocExtXML(
    prroFiscalNumber: number,
    docClass: string,
    docNumber: {
      fiscal?: string;
      local?: number;
    },
    resultType: DocumentRequestType = DocumentRequestType.SignedBySenderAndServerXml,
  ): Promise<string> {
    const docInfoExt = await this.fscoService.getDocExtXML(
      prroFiscalNumber,
      docClass,
      docNumber,
      resultType,
    );

    if (docInfoExt.data != null) {
      return docInfoExt.data.Data ?? '';
    }

    return '';
  }

  //#region Z Report
  async getZReport(): Promise<PRroZReport> {
    const prro = this.cachedDataService.getPRRO();

    return this.fscoService.getZReport(prro);
  }

  //#region Common
  async selectPRro(): Promise<void> {
    const shop = this.cachedDataService.getShop();

    if (shop == null || shop.prros == null || shop.prros.length === 0) {
      return;
    }

    if (shop.prros.length === 1) {
      this.cachedDataService.setPRRO(shop.prros[0]);
      return;
    }

    const modal = await this.modalCtrl.create({
      component: SelectPRroDialog,
      componentProps: { shop },
      backdropDismiss: false,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    this.cachedDataService.setPRRO(data);
  }

  async showQR(doc: Sale | Transaction): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: TaxQrDialog,
      componentProps: {
        doc,
      },
      backdropDismiss: false,
    });

    await modal.present();
  }

  async fiscalRiskyOperationDialog(
    operationSum: number,
    zReport: PRroZReport,
  ): Promise<boolean> {
    let result = true;

    if (zReport.cashRemain - operationSum < 0) {
      const prroAlert = await this.alertCtrl.create({
        header: `Програмний РРО`,
        message: `
          Фіскальний залишок:<br><b>${formatNumber(
            zReport.cashRemain,
            'uk_UA',
            '1.2-2',
          )} ₴</b><br><br>
          Сума вилучення:<br><b>${formatNumber(
            operationSum,
            'uk_UA',
            '1.2-2',
          )} ₴</b><br><br>
          Розрахунковий залишок:<br><b>${formatNumber(
            zReport.cashRemain - operationSum,
            'uk_UA',
            '1.2-2',
          )} ₴</b><br><br>
          Операцію скасовано!`,
        buttons: [{ text: 'Закрити', role: 'close' }],
      });

      await prroAlert.present();

      result = false;
    }

    return result;
  }

  //#region Open Shift
  async openShift(): Promise<{
    prroReport: string;
    prroState: ResponsePRROState | null;
  }> {
    const loadingId = `${LOADING_ID}:open-shift`;

    let prroReport = '';
    let prroState: ResponsePRROState | null = null;

    if (!this.cachedDataService.isPRroActive()) {
      return { prroReport, prroState };
    }

    await this.loadingService.presentCustomPreloader(loadingId, 'Зміна ПРРО');

    prroState = await this.getPRROState();

    if (prroState != null && prroState.ShiftState === ShiftState.Closed) {
      const openShiftCheckData = new PRroCheck(CheckDocumentType.OpenShift);

      const ticket = await this.sendDocument(openShiftCheckData);

      if (ticket != null) {
        // To get opened shift ID
        prroState = await this.getPRROState();

        await this.savePRroDoc(openShiftCheckData, ticket.ORDERTAXNUM ?? '');

        prroReport = `
          <br><b>Фіскальну зміну відкрито</b><br>
          Фіскальний номер документа: ${ticket.ORDERTAXNUM}`;
      } else {
        prroReport = `<br><strong>Фіскальну зміну НЕ відкрито</strong>`;
      }
    } else {
      prroReport = `<br><strong>Фіскальна зміна була відкрита раніше</strong>`;

      prroState = await this.getPRROState();
    }

    this.loadingService.dismiss(loadingId);

    return { prroReport, prroState };
  }

  //#region Create Z-Report And Close Shift
  async createZReportAndCloseShift(): Promise<{
    prroState: ResponsePRROState | null;
    zReport: PRroZReport | null;
    operationReport: string;
    canLogout: boolean;
  }> {
    const loadingId = `${LOADING_ID}:create-z-report-and-close-shift`;

    const result: {
      prroState: ResponsePRROState | null;
      zReport: PRroZReport | null;
      operationReport: string;
      canLogout: boolean;
    } = {
      prroState: null,
      zReport: null,
      operationReport: '',
      canLogout: true,
    };

    const isPRroActive = this.cachedDataService.isPRroActive();

    if (!isPRroActive) {
      return result;
    }

    await this.loadingService.presentCustomPreloader(loadingId, 'Зміна ПРРО');

    result.prroState = await this.getPRROState();

    if (
      result.prroState == null ||
      result.prroState.ShiftState === ShiftState.Closed
    ) {
      await this.loadingService.dismiss(loadingId);
      return result;
    }

    let zRepData: PRroZReport | null = null;

    if (result.prroState.ZRepPresent) {
      result.operationReport = `<br><strong>Z-звіт було створено раніше</strong><br>`;
    } else {
      const { zReport, operationReport, canLogout } = await this.createZReport(
        result.prroState,
      );

      zRepData = zReport;

      result.operationReport = operationReport;
      result.canLogout = canLogout;
    }

    if (result.prroState.ZRepPresent) {
      const { operationReport, canLogout } = await this.closeShift(zRepData);

      result.operationReport += operationReport;

      if (result.canLogout) {
        result.canLogout = canLogout;
      }
    }

    if (zRepData != null) {
      await this.addOptionalData(zRepData);
      await this.savePRroDoc(zRepData, zRepData.taxNumber ?? '');

      this.updateLastZReportNumber(zRepData);

      result.zReport = zRepData;
    }

    result.prroState = await this.getPRROState();

    await this.loadingService.dismiss(loadingId);

    return result;
  }

  private async createZReport(prroState: ResponsePRROState): Promise<{
    zReport: PRroZReport | null;
    operationReport: string;
    canLogout: boolean;
  }> {
    const result: {
      zReport: PRroZReport | null;
      operationReport: string;
      canLogout: boolean;
    } = { zReport: null, operationReport: '', canLogout: true };

    const zReport = await this.getZReport();
    const prro = this.cachedDataService.getPRRO();

    if (zReport.cashRemain > 0 && prro.autoServiceIssue) {
      const cashRemain = zReport.cashRemain;
      const prroResult = await this.fiscalizeServiceDoc(
        new Incasation({ amount: cashRemain }),
        { saveDoc: true },
      );

      if (prroResult == null) {
        result.canLogout = false;
        result.operationReport = `
          <br><strong>Z-звіт НЕ створено</strong>
          Не вдалося виконати вилучення`;

        return result;
      }

      zReport.serviceOutput += cashRemain;
      prro.lastDocNumber += 1;
    }

    const ticket = await this.sendDocument(zReport);

    if (ticket != null) {
      prroState.ZRepPresent = true;

      zReport.taxNumber = ticket.ORDERTAXNUM ?? '';

      result.zReport = zReport;
      result.operationReport += `
        <br><strong>Z-звіт створено</strong><br>
        Фіскальний номер документа: ${ticket.ORDERTAXNUM}<br>`;

      return result;
    }

    result.canLogout = false;
    result.operationReport = `<br><strong>Z-звіт НЕ створено</strong>`;

    return result;
  }

  private async addOptionalData(zReport: PRroZReport): Promise<void> {
    const prro = this.cachedDataService.getPRRO();

    zReport.reportNumber =
      prro.lastZReportNumber != null ? prro.lastZReportNumber + 1 : undefined;

    if (prro.lastDocLocalNumber == null || prro.lastDocFiscalNumber == null) {
      const prroShift = this.cachedDataService.getPRROShift();
      const docs = await this.getDocuments(
        prro.fiscalNumber,
        prroShift.ShiftId,
      );

      const lastDoc = docs?.Documents.pop();

      zReport.lastDocLocalNumber = lastDoc?.NumLocal;
      zReport.lastDocFiscalNumber = lastDoc?.NumFiscal;
    } else {
      zReport.lastDocLocalNumber = prro.lastDocLocalNumber;
      zReport.lastDocFiscalNumber = prro.lastDocFiscalNumber;
    }

    if (prro.discount == null) {
      const shift = await this.shiftsService.getShiftReport();

      if (shift != null) {
        zReport.discount =
          shift.totals.salesDiscount - shift.totals.saleReturnsDiscount;
      }
    } else {
      zReport.discount = prro.discount ?? 0;
    }
  }

  private updateLastZReportNumber(zReport: PRroZReport): void {
    if (zReport.reportNumber == null) {
      return;
    }

    const prro = this.cachedDataService.getPRRO();

    prro.lastZReportNumber = zReport.reportNumber;

    this.cachedDataService.setPRRO(prro);
  }

  private async closeShift(zReport: PRroZReport | null): Promise<{
    operationReport: string;
    canLogout: boolean;
  }> {
    const result: {
      operationReport: string;
      canLogout: boolean;
    } = {
      operationReport: '',
      canLogout: true,
    };

    const closeShiftCheckData = new PRroCheck(CheckDocumentType.CloseShift);

    const ticket = await this.sendDocument(closeShiftCheckData);

    if (ticket != null) {
      this.savePRroDoc(closeShiftCheckData, ticket.ORDERTAXNUM ?? '');

      if (zReport != null) {
        zReport.finishedAt = DateTime.fromFormat(
          `${ticket.ORDERDATE}${ticket.ORDERTIME}`,
          'ddMMyyyyHHmmss',
        ).toJSDate();
      }

      result.operationReport += `
        <br><strong>Фіскальну зміну закрито</strong><br>
        Фіскальний номер документа: ${ticket.ORDERTAXNUM}`;
    } else {
      result.canLogout = false;
      result.operationReport += `<br><strong>Фіскальну зміну НЕ закрито</strong><br>`;
    }

    return result;
  }

  //#region Load Docs And Compare
  async loadDocsAndCompare(): Promise<void> {
    const prro = this.cachedDataService.getPRRO();
    const prroShift = this.cachedDataService.getPRROShift();

    let params = new HttpParams();

    params = params.append('prroShiftId', prroShift.ShiftId.toString());

    const cashboxDocs = await this.http
      .get<PRroDoc[]>(`/p-rro/${prro.id}/docs`, { params })
      .toPromise();

    const shiftDocsResponse = await this.getDocuments(
      prro.fiscalNumber,
      prroShift.ShiftId,
    );

    const syncDocs: PRroDoc[] = [];

    if (shiftDocsResponse != null && shiftDocsResponse.Documents != null) {
      this.compareDocs(
        prro,
        prroShift,
        shiftDocsResponse.Documents,
        cashboxDocs,
        syncDocs,
      );
    } else if (shiftDocsResponse == null) {
      this.toastService.presentWarning(
        `Синхронізація даних з ДПС`,
        `Не вдалося завантажити документи зміни`,
      );
    } else {
      this.syncToast(`Немає документів у зміні`);
    }

    for (const syncDoc of syncDocs) {
      if (syncDoc?.docData === '' && syncDoc.docClass === DocumentClass.ZRep) {
        this.syncToast(`Завантаження XML`);

        const xml = await this.getDocExtXML(
          prro.fiscalNumber,
          DocumentClassName.ZRep,
          { fiscal: syncDoc.taxNumber },
        );

        if (xml != null && xml !== '') {
          syncDoc.docData = `data:application/octet-stream;base64,${xml}`;
        } else {
          syncDoc.docData = ``;
        }
      }
    }

    await this.sendDocs(syncDocs);
  }

  private compareDocs(
    prro: PRro,
    prroShift: ResponsePRROState,
    taxDocs: DocumentItem[],
    cashboxDocs: PRroDoc[],
    syncDocs: PRroDoc[],
  ): void {
    taxDocs.forEach((taxDoc) => {
      const cashboxDoc = cashboxDocs.find(
        (cd) => cd.taxNumber === taxDoc.NumFiscal,
      );

      if (
        cashboxDoc == null ||
        (cashboxDoc != null &&
          this.isDifferenceBetweenDocs(cashboxDoc, taxDoc, prroShift))
      ) {
        const prroDoc = this.createPRroDoc(
          prro,
          prroShift,
          taxDoc,
          cashboxDoc?.docDataExist ?? false,
        );

        syncDocs.push(prroDoc);
      }
    });
  }

  private isDifferenceBetweenDocs(
    cashboxDoc: PRroDoc,
    taxDoc: DocumentItem,
    prroShift: ResponsePRROState,
  ): boolean {
    return (
      cashboxDoc.prroShiftId !== prroShift.ShiftId ||
      Boolean(cashboxDoc.revoked) !== taxDoc.Revoked ||
      Boolean(cashboxDoc.storned) !== taxDoc.Storned ||
      !cashboxDoc.docDataExist ||
      (cashboxDoc.checkDocType === CheckDocumentType.OpenShift &&
        Boolean(cashboxDoc.testMode) !== prroShift.Testing) ||
      (cashboxDoc.docClass === DocumentClass.Check &&
        cashboxDoc.checkDocType === CheckDocumentType.SaleGoods &&
        cashboxDoc.cardSum == null &&
        cashboxDoc.cashSum == null)
    );
  }

  private createPRroDoc(
    prro: PRro,
    prroShift: ResponsePRROState,
    doc: DocumentItem,
    docDataExist: boolean,
  ): PRroDoc {
    const shift = this.cachedDataService.getShift();

    const prroDoc = new PRroDoc();

    prroDoc.shopId = prro.shopId;
    prroDoc.prroId = prro.id;

    if (shift != null) {
      prroDoc.shiftId = shift.id;
    }

    prroDoc.prroShiftId = prroShift.ShiftId;
    prroDoc.docDateTime = new Date(doc.DocDateTime);
    prroDoc.localNumber = doc.NumLocal;
    prroDoc.taxNumber = doc.NumFiscal;
    prroDoc.revoked = doc.Revoked;
    prroDoc.storned = doc.Storned;

    switch (doc.DocClass) {
      case DocumentClassName.ZRep:
        prroDoc.docClass = DocumentClass.ZRep;
        break;

      case DocumentClassName.Check:
        prroDoc.docClass = DocumentClass.Check;
        break;
    }

    switch (doc.CheckDocType) {
      case CheckDocumentTypeName.SaleGoods:
        prroDoc.checkDocType = CheckDocumentType.SaleGoods;
        break;

      case CheckDocumentTypeName.TransferFunds:
        prroDoc.checkDocType = CheckDocumentType.TransferFunds;
        break;

      case CheckDocumentTypeName.CurrencyExchange:
        prroDoc.checkDocType = CheckDocumentType.CurrencyExchange;
        break;

      case CheckDocumentTypeName.CashWithdrawal:
        prroDoc.checkDocType = CheckDocumentType.CashWithdrawal;
        break;

      case CheckDocumentTypeName.OpenShift:
        prroDoc.checkDocType = CheckDocumentType.OpenShift;
        prroDoc.testMode = prroShift.Testing;
        break;

      case CheckDocumentTypeName.CloseShift:
        prroDoc.checkDocType = CheckDocumentType.CloseShift;
        break;

      case CheckDocumentTypeName.OfflineBegin:
        prroDoc.checkDocType = CheckDocumentType.OfflineBegin;
        break;

      case CheckDocumentTypeName.OfflineEnd:
        prroDoc.checkDocType = CheckDocumentType.OfflineEnd;
        break;
    }

    switch (doc.CheckDocSubType) {
      case CheckDocumentSubTypeName.CheckGoods:
        prroDoc.checkDocSubType = CheckDocumentSubType.CheckGoods;
        break;

      case CheckDocumentSubTypeName.CheckReturn:
        prroDoc.checkDocSubType = CheckDocumentSubType.CheckReturn;
        break;

      case CheckDocumentSubTypeName.ServiceDeposit:
        prroDoc.checkDocSubType = CheckDocumentSubType.ServiceDeposit;
        break;

      case CheckDocumentSubTypeName.AdditionalDeposit:
        prroDoc.checkDocSubType = CheckDocumentSubType.AdditionalDeposit;
        break;

      case CheckDocumentSubTypeName.ServiceIssue:
        prroDoc.checkDocSubType = CheckDocumentSubType.ServiceIssue;
        break;

      case CheckDocumentSubTypeName.CheckStorno:
        prroDoc.checkDocSubType = CheckDocumentSubType.CheckStorno;
        break;
    }

    if (!docDataExist) {
      prroDoc.docData = '';
    }

    return prroDoc;
  }

  private async sendDocs(taxShiftDocs: PRroDoc[]): Promise<void> {
    if (taxShiftDocs.length === 0) {
      this.syncToast(`Розбіжностей з даними ДПС не знайдено`);
      return;
    }

    this.syncToast(`Збереження документів: ${taxShiftDocs.length}`);

    for (const taxShiftDoc of taxShiftDocs) {
      await this.syncPRroDoc(taxShiftDoc);
    }

    this.syncToast(`Синхронізацію завершено`);
  }

  private syncToast(message: string): void {
    this.toastService.present(`Синхронізація даних з ДПС`, message);
  }

  private async syncPRroDoc(docs: PRroDoc): Promise<PRroDoc> {
    return await this.http.post<PRroDoc>(`/p-rro/sync`, docs).toPromise();
  }

  //#region Load Z-reports
  loadZReports(): Observable<PRroDoc[]> {
    const prro = this.cachedDataService.getPRRO();

    let params = new HttpParams();

    params = params.append('docClass', DocumentClass.ZRep.toString());
    params = params.append(
      'fromDate',
      DateTime.now().minus({ days: 7 }).toSQLDate(),
    );
    params = params.append('toDate', DateTime.now().toSQLDate());

    return this.http
      .get<PRroDoc[]>(`/p-rro/${prro.id}/docs`, { params })
      .pipe(map((docs) => orderBy(docs, 'docDateTime', 'desc')));
  }
}
