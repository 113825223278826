export const WRONG_CREDENTIALS = 'Неправильний логін або пароль';
export const NO_INTERNET_TITLE = "Немає інтернет-з'єднання";
export const NO_INTERNET_CONNECTION =
  'Будь ласка, перевірте стан вашого інтернет-підключення і спробуйте ще раз';
export const SYNC_TOAST_MESSAGE =
  '\nЩоб повторити спробу відправки даних, необхідно виконати синхронізацію. \nЩоб видалити дані - перейти у вікно "Налаштування"';
export const SHIFT_CLOSED = 'Будь ласка, відкрийте робочу зміну';
export const SHIFT_NOT_CONNECTED = 'Будь ласка, приєднайтесь до робочої зміни';
export const SHIFT_CONFLICT = 'Перевірка робочої зміни';
export const SHIFT_CONFLICT_WITH_ANOTHER_SHOP =
  'Робоча зміна відкрита на іншій торговій точці';
export const SHIFT_WAS_CLOSED = 'Поточна робоча зміна була закрита раніше';
export const NEED_PAYMENT =
  'Необхідна термінова оплата послуг, доступи заблоковані';
export const NO_SHOPS =
  'Торгових точок ще не створено або немає доступу до жодної з існуючих\nЗверніться до адміністратора';
export const SHOP_NOT_SELECTED = 'Торгова точка не була вибрана';
export const NEED_UPDATE_TITLE = 'Необхідне оновлення';
export const NEED_UPDATE =
  'Поточна версія додатку застаріла, будь ласка, оновіть додаток';
export const NEED_PWA_UPDATE =
  'Поточна версія PWA-додатку застаріла, будь ласка, натисніть кнопку праворуч щоб оновити додаток';
export const ERROR_OFFLINE_SALE =
  'Продажі будуть зберігатися на пристрої. Виконайте синхронізацію після відновлення мережі';
export const AUTH_ERROR = 'Помилка авторизації';
export const ROLE_ERROR = 'Використовуйте адмін-панель за адресою';
