import { CheckDocumentSubType } from '../enums/check-document-sub-type.enum';

import { PRroSaleCheck } from './p-rro-sale-check.model';

export class PRroReturnCheck extends PRroSaleCheck {
  /**
   * Фіскальний номер чека, для якого здійснюється повернення (128 символів)
   *
   * (зазначається тільки для чеків повернення)
   */
  returnTaxNumber: string;

  constructor(date?: Date) {
    super(date);

    this.checkSubType = CheckDocumentSubType.CheckReturn;
  }
}
