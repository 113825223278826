<ion-header>
  <ion-toolbar>
    <ion-title>Виберіть ПРРО</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div *ngFor="let prro of shop.prros">
    <ion-button color="tertiary"
                [disabled]="false"
                (click)="closeModal(prro)">
      <ion-label>ФН {{ prro.fiscalNumber }}, ВН {{ prro.localNumber }}</ion-label>
    </ion-button>

    <ion-label color="medium">
      {{ prro.caption }}
    </ion-label>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="one-button" [disabled]="true">Налаштування УЕП</ion-button>
  </ion-toolbar>
</ion-footer>
