export enum PrivatbankJsonMethod {
  PingDevice = 'PingDevice',
  ServiceMessage = 'ServiceMessage',
  Purchase = 'Purchase',
  Refund = 'Refund',
  Withdrawal = 'Withdrawal',
  Audit = 'Audit',
  Verify = 'Verify',
  ServiceRefund = 'ServiceRefund',
}
