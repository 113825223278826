const ASK_ACQUIRING_PROVIDER = 'Зверніться до постачальника послуг еквайрингу';

export function merchantDeviceStatus(code: number): {
  desctiption: string;
  recommendation: string;
} {
  switch (code) {
    case 1:
    case 2:
    case 7:
    case 8:
      return {
        desctiption: 'Потрібне налаштування платіжного додатку',
        recommendation:
          'У параметри наступного запуску плітіжного додатку потрібно включити токен оплати та токен підключення',
      };

    case 3:
      return {
        desctiption: 'Платіжний додаток активовано',
        recommendation:
          'Все готове для приймання платежів з використання токену оплати',
      };

    case 4:
      return {
        desctiption: 'Платіжний додаток відключено',
        recommendation: ASK_ACQUIRING_PROVIDER,
      };

    case 5:
      return {
        desctiption: 'Платіжний додаток заблоковано та відключено',
        recommendation: ASK_ACQUIRING_PROVIDER,
      };

    case 6:
      return {
        desctiption: 'Платіжний додаток заблоковано',
        recommendation: ASK_ACQUIRING_PROVIDER,
      };

    default:
      return {
        desctiption: 'Невідомий статус',
        recommendation: 'Повідомте про ситуацію операторів cashbox',
      };
  }
}
