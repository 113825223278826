import { Component } from '@angular/core';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { AlertController } from '@ionic/angular';

import { IStorageUserSettings } from '../core/interfaces/storage-user-setting.interface';
import { Shop } from '../core/models/shop.model';
import { CachedDataService } from '../core/services/cached-data.service';
import { ToastService } from '../core/services/toast.service';
import { UtilsService } from '../core/services/utils.service';

import { LogsService } from './logs/logs.service';
import {
  CONFIRM_DIALOG_ALERT_STYLE,
  FINANCE_EXPENSES_STORAGE_KEY,
  INCASATIONS_STORAGE_KEY,
  PRESALES_STORAGE_KEY,
  SALES_STORAGE_KEY,
} from './settings.const';
import { SettingService } from './settings.service';

@Component({
  selector: 'bk-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent {
  userSettings: IStorageUserSettings;
  shop: Shop;

  isAndroidApp: boolean;
  isDesktop: boolean;

  constructor(
    private market: Market,
    private alertCtrl: AlertController,
    private cachedDataService: CachedDataService,
    private utilsService: UtilsService,
    private settingService: SettingService,
    private toastService: ToastService,
    private logsService: LogsService,
  ) {
    this.shop = this.cachedDataService.getShop();
    this.userSettings = this.cachedDataService.getUserSettings();

    this.isAndroidApp = this.utilsService.isAndroidApp();
    this.isDesktop = this.utilsService.isDesktop();
  }

  toggleQuickSaleButtons(): void {
    this.userSettings.quickSaleButtonsMode =
      !this.userSettings.quickSaleButtonsMode;

    this.cachedDataService.setUserSettings(this.userSettings);
  }

  toggleFiscalByDefault(): void {
    this.userSettings.fiscalByDefaultMode =
      !this.userSettings.fiscalByDefaultMode;

    this.cachedDataService.setUserSettings(this.userSettings);
  }

  toggleProductsAsList(): void {
    this.userSettings.productsAsListMode =
      !this.userSettings.productsAsListMode;

    this.cachedDataService.setUserSettings(this.userSettings);
  }

  toggleRoundInvoiceTotalSum(): void {
    this.userSettings.roundInvoiceTotalSum =
      !this.userSettings.roundInvoiceTotalSum;

    this.cachedDataService.setUserSettings(this.userSettings);
  }

  toggleTabControl(): void {
    this.userSettings.tabControl = !this.userSettings.tabControl;

    this.cachedDataService.setUserSettings(this.userSettings);

    this.alertCtrl
      .create({
        header: 'Контроль вікон',
        message: `Оновіть сторінку, щоб активувати зміни`,
        buttons: [{ text: 'OK', role: 'close' }],
      })
      .then((alert) => alert.present());
  }

  toggleDarkTheme(): void {
    this.userSettings.darkThemeMode = !this.userSettings.darkThemeMode;

    this.cachedDataService.setUserSettings(this.userSettings);

    document.body.classList.toggle('dark', this.userSettings.darkThemeMode);
  }

  async clearSyncSales(): Promise<void> {
    const sales = localStorage.getItem(SALES_STORAGE_KEY);
    const presales = localStorage.getItem(PRESALES_STORAGE_KEY);
    const incasations = localStorage.getItem(INCASATIONS_STORAGE_KEY);
    const financeExpenses = localStorage.getItem(FINANCE_EXPENSES_STORAGE_KEY);

    const sendByEmail = 'sendByEmail';

    const alert = await this.alertCtrl.create({
      header: 'Дані синхронізації',
      message: `
        Видалення невідправлених на сервер даних!<br><br>
        - чеки: ${sales ? JSON.parse(sales)?.length : 0};<br>
        - відкладені чеки: ${presales ? JSON.parse(presales)?.length : 0};<br>
        - інкасації/внесення: ${
          incasations ? JSON.parse(incasations)?.length : 0
        };<br>
        - витрати: ${
          financeExpenses ? JSON.parse(financeExpenses)?.length : 0
        };<br><br>
        Інформацію буде втрачено!<br><br>
        Щоб спробувати відновити дані або їх частину,<br>
        необхідно відправити їх у службу підтримки для аналізу.<br><br>
        <strong>Видалити</strong> дані?`,
      inputs: [
        {
          name: sendByEmail,
          type: 'checkbox',
          label: 'Надіслати дані у службу підтримки',
          value: sendByEmail,
          checked: false,
        },
      ],
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: (options: string[]) => {
            if (options?.includes(sendByEmail)) {
              const body = `${sales ?? ''}${presales ?? ''}${
                incasations ?? ''
              }${financeExpenses ?? ''}`;

              this.logsService.send('Sync data', body, {
                useEmail: true,
                isDesktop: this.isDesktop,
              });
            }

            localStorage.setItem(SALES_STORAGE_KEY, JSON.stringify([]));
            localStorage.setItem(PRESALES_STORAGE_KEY, JSON.stringify([]));
            localStorage.setItem(INCASATIONS_STORAGE_KEY, JSON.stringify([]));
            localStorage.setItem(
              FINANCE_EXPENSES_STORAGE_KEY,
              JSON.stringify([]),
            );

            this.toastService.present('Дані для синхронізації видалено');
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  openMarket(): void {
    this.market.open(this.settingService.getPackageName());
  }
}
