import { IngredientCategory } from './ingredient-category.model';

export class Ingredient {
  id: number;
  name: string;
  measurement: string;
  barcode: string;
  position: number;
  categoryId: number;
  categoryName: string;
  categoryPosition: number;
  costPrice: number;

  category?: IngredientCategory;

  // For creating product from ingredient
  productCategoryId?: number;
  productSubcategoryId?: number;
  productSubcategoryMarkup?: number;
  productId?: number;
  productShopId?: number;
  productPrice?: number;
  productMarkup?: number;
  weightProduct?: boolean;

  currentMarkup?: number;
  salePrice?: number;
}
