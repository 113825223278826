import { SALE_GROUP_ROWS } from '../core/constants/visible-rows.const';

import { Sale } from './sale/sale.model';

export class SalesGroup {
  date: string;
  sales: Sale[];
  cashSum: number;
  cardSum: number;
  collapsed: boolean;
  visibleRows: number;

  constructor(date: string) {
    this.date = date;
    this.sales = [];
    this.cashSum = 0;
    this.cardSum = 0;
    this.collapsed = true;
    this.visibleRows = SALE_GROUP_ROWS;
  }
}
