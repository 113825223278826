import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import {
  ActionSheetButton,
  ActionSheetController,
  AlertController,
  IonInfiniteScroll,
  IonItemSliding,
  ModalController,
  Platform,
} from '@ionic/angular';
import chunk from 'lodash-es/chunk';
import remove from 'lodash-es/remove';

import {
  INVOICE_ADD_PRODUCT,
  INVOICE_ADD_WEIGHT_PRODUCT,
} from '../../core/constants/events.const';
import {
  MAX_CURRENCY_VALUE,
  MIN_CURRENCY_VALUE,
} from '../../core/constants/form-validations.const';
import { IStorageUserSettings } from '../../core/interfaces/storage-user-setting.interface';
import { User } from '../../core/models/user.model';
import { CachedDataService } from '../../core/services/cached-data.service';
import { Events } from '../../core/services/events.service';
import { HostListenerService } from '../../core/services/host-listener.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { LabelPrinterService } from '../../settings/printer/label/label-printer.service';
import { ScalesService } from '../../settings/scales/scales.service';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../../settings/settings.const';
import { Category } from '../categories/category.model';
import { Subcategory } from '../subcategories/subcategory.model';

import { BarcodeDialog } from './barcode-dialog/barcode.dialog';
import { Product } from './product.model';
import { ProductsService } from './products.service';

const PRODUCTS_PORTRAIT_ROWS = 20;
const PRODUCTS_LANDSCAPE_ROWS = 10;

@Component({
  selector: 'bk-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @Input() isLandscape = false;
  @Input() menuCategory: Category;
  @Input() menuSubcategory: Subcategory;
  @Input() menuEmptyCategory: boolean;
  @Output() goToWeightProduct: EventEmitter<Product> =
    new EventEmitter<Product>();
  @Output() goBackToMenu: EventEmitter<any> = new EventEmitter<any>();
  @Output() goBackToSubcategories: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(IonItemSliding) itemSliding: IonItemSliding;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  products: Product[];
  filteredProducts: Product[];
  category: Category;
  subcategory: Subcategory;
  emptyCategory: boolean;
  selectedProducts: Product[] = [];
  isBluetoothEnabled: boolean;
  userSettings: IStorageUserSettings;
  user: User;

  productGroups: Product[][];

  columnsCount = 1;
  visiblePortraitRows = PRODUCTS_PORTRAIT_ROWS;
  visibleLandscapeRows = PRODUCTS_LANDSCAPE_ROWS;

  private storageKey: string;
  private swipedProduct: Product;

  isPrintLabelMode = false;

  constructor(
    private router: Router,
    private events: Events,
    private platform: Platform,
    private diagnostic: Diagnostic,
    private hostListenerService: HostListenerService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
    private actionSheetController: ActionSheetController,
    private productsService: ProductsService,
    private labelPrinterService: LabelPrinterService,
    private scalesService: ScalesService,
    private utilsService: UtilsService,
  ) {
    this.user = this.cachedDataService.getUser();
    this.userSettings = this.cachedDataService.getUserSettings();
  }

  ngOnInit(): void {
    this.setFilters();

    this.productsService
      .findForApp({ subcategoryId: this.subcategory.id })
      .subscribe((products) => {
        if (products.length > 0) {
          this.storageKey = products[0].storageUrl
            ? products[0].storageUrl
            : '';

          Reflect.deleteProperty(products[0], 'storageUrl');
        }

        this.products = products;
        this.filteredProducts = this.products;

        if (this.isLandscape) {
          this.productGroups = chunk(this.products, this.columnsCount);
        }
      });

    if (this.platform.is('cordova')) {
      this.diagnostic
        .isBluetoothEnabled()
        .then((status) => (this.isBluetoothEnabled = status));
    } else {
      this.isBluetoothEnabled = false;
    }

    this.labelPrinterService.status().then((status) => {
      this.isPrintLabelMode = status.isPrinterAvailable;
    });
  }

  ionViewWillEnter(): void {
    this.hostListenerService.setEventType('product');
  }

  private setFilters(): void {
    if (this.isLandscape) {
      this.category = this.menuCategory;
      this.subcategory = this.menuSubcategory;
      this.emptyCategory = this.menuEmptyCategory;
      this.columnsCount = 4;
    } else {
      const navigation = this.router.getCurrentNavigation();

      if (navigation && navigation.extras && navigation.extras.state) {
        const routeState = navigation.extras.state;

        if (routeState != null) {
          this.category = JSON.parse(routeState.category) as Category;
          this.subcategory = JSON.parse(routeState.subcategory) as Subcategory;
          this.emptyCategory = routeState.emptyCategory;
        }
      }
    }
  }

  search(event: any): void {
    const searchQuery = event.target.value || '';

    this.visiblePortraitRows = PRODUCTS_PORTRAIT_ROWS;
    this.visibleLandscapeRows = PRODUCTS_LANDSCAPE_ROWS;

    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = false;
    }

    this.filteredProducts = this.products.filter((product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase().trim()),
    );
  }

  loadPortraitData(event: any): void {
    setTimeout(() => {
      this.visiblePortraitRows += PRODUCTS_PORTRAIT_ROWS;

      event.target.complete();

      if (this.products.length <= this.visiblePortraitRows) {
        event.target.disabled = true;
      }
    }, 300);
  }

  loadLandscapeData(event: any): void {
    setTimeout(() => {
      this.visibleLandscapeRows += PRODUCTS_LANDSCAPE_ROWS;

      event.target.complete();

      if (this.productGroups.length <= this.visibleLandscapeRows) {
        event.target.disabled = true;
      }
    }, 300);
  }

  async addProductAndGoBack(product: Product, event?: any): Promise<void> {
    if (event) {
      event.currentTarget.blur();
    }

    if (this.selectedProducts.length > 0) {
      this.toggleSelectedProduct(product);
    } else if (product.weightProduct) {
      if (this.isLandscape) {
        this.goToWeightProduct.emit(product);

        return;
      }

      if (this.scalesService.isScalesAvailable(product.amount)) {
        this.scalesService.getWeight();

        await this.scalesService.wait();

        const scalesData = this.scalesService.data();

        if (
          !scalesData.isError &&
          scalesData.isStable &&
          scalesData.weight > 0
        ) {
          this.scalesService.finish();

          const saleProduct = this.utilsService.getStartSaleProduct(product);

          this.utilsService.calcWeightProductData(
            saleProduct,
            scalesData.weight,
          );

          this.events.publish(INVOICE_ADD_WEIGHT_PRODUCT, saleProduct);
          this.router.navigate(['/shop'], { replaceUrl: true });

          return;
        }

        await this.scalesService.finish({ waiting: true });
      }

      this.router.navigateByUrl('/weight-product', {
        state: {
          product: JSON.stringify(product),
        },
      });
    } else {
      this.events.publish(INVOICE_ADD_PRODUCT, { product });
      this.router.navigate(['/shop'], { replaceUrl: true });
    }
  }

  addProductsAndGoBack(): void {
    this.selectedProducts.forEach((product) => {
      this.events.publish(INVOICE_ADD_PRODUCT, { product });
    });

    this.router.navigate(['/shop'], { replaceUrl: true });
  }

  toggleSelectedProduct(product: Product): void {
    if (this.isProductChecked(product)) {
      this.deselectProduct(product);
    } else {
      this.selectProduct(product);
    }
  }

  isProductChecked(product: Product): boolean {
    return this.selectedProducts.some(
      (selectedProduct) => selectedProduct.id === product.id,
    );
  }

  selectProduct(product: Product): void {
    if (this.isLandscape && this.userSettings.productsAsListMode) {
      return;
    }

    this.selectedProducts.push(product);
  }

  private deselectProduct(product: Product): void {
    remove(
      this.selectedProducts,
      (selectedProduct) => selectedProduct.id === product.id,
    );
  }

  private closeSlidedItem(): void {
    if (this.itemSliding != null) {
      this.itemSliding.close();
    }
  }

  async updateProduct(product: Product): Promise<void> {
    const currentBarcode = product.barcode;
    const setButtonText = currentBarcode ? `Змінити` : `Додати`;

    const actionSheetButtons: ActionSheetButton[] = [
      {
        text: 'Скасувати',
        icon: 'close',
        role: 'cancel',
      },
    ];

    if (this.user.changeProductPrice) {
      actionSheetButtons.push({
        text: 'Змінити ціну',
        icon: 'pricetag-outline',
        cssClass: 'change-price',
        handler: async () => {
          await this.updatePrice(product);
        },
      });
    }

    actionSheetButtons.push({
      text: `${setButtonText} штрихкод`,
      icon: 'barcode-outline',
      cssClass: 'add-barcode',
      handler: async () => {
        await this.updateBarcode(product);
      },
    });

    if (this.isPrintLabelMode) {
      actionSheetButtons.push({
        text: 'Надрукувати етикетки',
        icon: 'ticket-outline',
        cssClass: 'print-labels',
        handler: async () => {
          await this.printLabelDialog(product);
        },
      });
    }

    const actionSheet = await this.actionSheetController.create({
      header: 'Додаткові операції',
      cssClass: 'product-action-sheet-controller',
      buttons: actionSheetButtons,
    });

    await actionSheet.present();
  }

  async updateBarcode(product: Product): Promise<void> {
    if (!this.userSettings.keyboardMode && !this.isBluetoothEnabled) {
      this.router.navigateByUrl('/settings');

      return;
    }

    this.swipedProduct = product;

    const modal = await this.modalCtrl.create({
      component: BarcodeDialog,
      componentProps: {
        product,
        isBluetoothEnabled: this.isBluetoothEnabled,
      },
      backdropDismiss: false,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.success) {
      this.updateBarcodeRequest(data.barcode);
    }
  }

  private updateBarcodeRequest(barcode: string): void {
    const clone = { ...this.swipedProduct };

    clone.barcode = barcode;

    this.productsService
      .updateBarcode(this.swipedProduct.id, clone)
      .subscribe(() => {
        this.swipedProduct.barcode = barcode;

        localStorage.setItem(this.storageKey, JSON.stringify(this.products));

        this.toastService.present('Штрихкод оновлений');
      });

    this.closeSlidedItem();
  }

  async updatePrice(product: Product): Promise<void> {
    this.swipedProduct = product;

    const inputFieldId = 'inputField';

    const alert = await this.alertCtrl.create({
      header: `Ціна`,
      message: `Введіть нову ціну ${
        product.weightProduct ? `за ${product.amount} ` : ''
      }товару "${this.swipedProduct.name}"`,
      inputs: [
        {
          id: inputFieldId,
          name: 'productPrice',
          placeholder: 'Ціна, ₴',
          type: 'number',
          min: MIN_CURRENCY_VALUE,
          max: MAX_CURRENCY_VALUE,
          value: product.price,
        },
      ],
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
          handler: () => {
            this.closeSlidedItem();
          },
        },
        {
          text: 'Підтвердити',
          role: 'confirm',
          cssClass: 'primary',
          handler: (data: { productPrice: string }) => {
            this.updatePriceRequest(data.productPrice);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
      backdropDismiss: false,
    });

    alert.addEventListener('ionAlertDidPresent', (e) => {
      const inputField = document.getElementById(inputFieldId);

      if (inputField == null) {
        return;
      }

      this.setFocusAndSelect(inputField);

      inputField.onkeyup = async (event) => {
        if (event.key !== 'Enter') {
          return;
        }

        if (inputField instanceof HTMLInputElement) {
          this.updatePriceRequest(inputField.value);

          await alert.dismiss();
        }
      };
    });

    await alert.present();
  }

  private updatePriceRequest(value: string): void {
    const price = Math.max(0, Math.abs(Number(value)));

    if (price > 0) {
      const cloneProduct = { ...this.swipedProduct };

      cloneProduct.price = price;

      this.productsService
        .updatePrice(this.swipedProduct.id, cloneProduct)
        .subscribe(() => {
          this.swipedProduct.price = price;

          localStorage.setItem(this.storageKey, JSON.stringify(this.products));

          this.toastService.present('Ціна оновлена');
        });

      this.closeSlidedItem();
    }
  }

  async printLabelDialog(product: Product): Promise<void> {
    this.closeSlidedItem();

    this.swipedProduct = product;

    const inputFieldId = 'inputField';

    const alert = await this.alertCtrl.create({
      header: `Друк етикеток`,
      message: `Введіть кількість етикеток, яку потрібно надрукувати для товару "${this.swipedProduct.name}"`,
      inputs: [
        {
          id: inputFieldId,
          name: 'quantity',
          placeholder: 'Кількість',
          type: 'number',
          min: 1,
          max: 99,
          value: 1,
        },
      ],
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
          handler: () => {
            this.closeSlidedItem();
          },
        },
        {
          text: 'Підтвердити',
          role: 'confirm',
          cssClass: 'primary',
          handler: (data: { quantity: string }) => {
            this.printLabel(data.quantity);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
      backdropDismiss: false,
    });

    alert.addEventListener('ionAlertDidPresent', (e) => {
      const inputField = document.getElementById(inputFieldId);

      if (!inputField) {
        return;
      }

      this.setFocusAndSelect(inputField);

      inputField.onkeyup = async (event) => {
        if (event.key !== 'Enter') {
          return;
        }

        if (inputField instanceof HTMLInputElement) {
          this.printLabel(inputField.value);

          await alert.dismiss();
        }
      };
    });

    await alert.present();
  }

  private setFocusAndSelect(inputField: HTMLElement): void {
    setTimeout(() => {
      inputField.focus();

      if (inputField instanceof HTMLInputElement) {
        inputField.select();
      }
    }, 100);
  }

  private printLabel(value: string): void {
    const quantity = Math.max(0, Math.abs(Number(value)));

    if (quantity > 0) {
      this.swipedProduct.labelsCount = quantity;

      this.labelPrinterService.printProduct(this.swipedProduct);
    }
  }
}
