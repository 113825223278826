import { ShiftTotals } from './shift-totals.model';
import { User } from './user.model';

export class ShiftUser {
  id: number;
  shiftId: number;
  userId: number;
  userName?: string;
  user?: User;
  createdAt: Date;

  totals?: ShiftTotals;
}
