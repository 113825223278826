<ion-header>
  <ion-toolbar>
    <ion-title>QR-код фіскального чека</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="content">
    <div class="content__data">
      <div class="ion-text-center">
        <qrcode [qrdata]="qrData" 
                [margin]="1"
                [width]="256" 
                [errorCorrectionLevel]="'L'"
                [elementType]="'svg'"></qrcode>
      </div>
      
      <ion-text color="dark">
        <div class="summary">
          <div>ФН ПРРО<span class="value">{{ doc.prro?.fiscalNumber }}</span></div>
          <div>ФН чека<span class="value">{{ doc.prroTaxNumber }}</span></div>
          <div>Дата і час<span class="value">{{ doc.createdAt | date: TAX_DATE_FORMAT }}</span></div>
          <div>Сума<span class="value">{{ sum | currency:'UAH':'symbol-narrow' }}</span></div>

          <a [href]="qrData" target="_blank">ФСКО ЄВПЕЗ</a>
        </div>
      </ion-text>
    </div>

    <ion-row class="ion-text-center">
      <ion-col [size]="12 / activeShareButtons.length" class="ion-align-items-center" *ngFor="let shareButton of activeShareButtons">
        <ion-button [class]="shareButton.class" (click)="share(shareButton.package)">
          <bk-icon [name]="shareButton.icon" *ngIf="shareButton.package; else defaultIcon"></bk-icon>

          <ng-template #defaultIcon>
            <ion-icon [name]="shareButton.icon"></ion-icon>
          </ng-template>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="one-button" color="tertiary" (click)="close()">Закрити</ion-button>
  </ion-toolbar>
</ion-footer>