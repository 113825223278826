<div class="check" *ngIf="refill">
  <bk-view-line [line]="numberCaption + ' ' + refill.date"></bk-view-line>

  <section class="border-top border-bottom">
    <bk-view-line [line]="providerNameCaption + ': ' + refill.providerName"></bk-view-line>
    <bk-view-line [line]="shopNameCaption + ': ' + refill.shopName"></bk-view-line>
    <bk-view-line [line]="userNameCaption + ': ' + refill.userName"></bk-view-line>
  </section>

  <bk-view-line [line]="productsCaption + ': '"></bk-view-line>

  <section class="products">
    <div *ngFor="let product of refill.products" class="product">
      <bk-view-line [line]="product.name + ', ' + product.type"></bk-view-line>
      <bk-view-line [line]="product.priceVector"></bk-view-line>
    </div>

    <hr>
  </section>

  <section class="border-top">
    <bk-view-line [center]="refill.printTime"></bk-view-line>
  </section>

  <div class="page-break"></div>
</div>
