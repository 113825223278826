import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Resource } from '../../abstract/resource';
import { IngredientCategory } from '../../models/ingredient-category.model';

@Injectable()
export class IngredientCategoryService extends Resource<IngredientCategory> {
  constructor(protected http: HttpClient) {
    super(http, {
      path: '/ingredients/category',
    });
  }
}
