import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import Socket from '@vendus/sockets-for-cordova';
import { iif, of, throwError, BehaviorSubject, Observable } from 'rxjs';
import { concatMap, delay, retryWhen, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import {
  TERMINAL,
  TERMINAL_LOG,
  TERMINAL_SERVICE_MESSAGE,
} from '../../core/constants/events.const';
import { IStoragePaymentTerminalData } from '../../core/interfaces/storage-payment-terminal-data.interface';
import { IStoragePaymentTerminalSettings } from '../../core/interfaces/storage-payment-terminal-settings.interface';
import { CachedDataService } from '../../core/services/cached-data.service';
import { Events } from '../../core/services/events.service';
import { LoadingService } from '../../core/services/loading.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { LogsService } from '../logs/logs.service';

import { TerminalConnectionMethod } from './enums/terminal-connection-method.enum';
import { TerminalConnectionProtocol } from './enums/terminal-connection-protocol.enum';
import { PrivatbankJsonLastStatMsgCode } from './privatbank/json/enums/last-stat-msg-code.enum';
import { PrivatbankJsonMethod } from './privatbank/json/enums/method.enum';
import { PrivatbankJsonResponseCode } from './privatbank/json/enums/response-code.enum';
import { PrivatbankJsonServiceMessageTypeRequest } from './privatbank/json/enums/service-message-type-request.enum';
import { PrivatbankJsonServiceMessageTypeResponse } from './privatbank/json/enums/service-message-type-response.enum';
import { PrivatbankJsonMessage } from './privatbank/json/message.interface';
import { PrivatbankJsonAuditRequest } from './privatbank/json/requests/audit-request.model';
import { PrivatbankJsonBaseRequest } from './privatbank/json/requests/base-request.model';
import { PrivatbankJsonPingDeviceRequest } from './privatbank/json/requests/ping-device-request.model';
import { PrivatbankJsonPurchaseRequest } from './privatbank/json/requests/purchase-request.model';
import { PrivatbankJsonRefundRequest } from './privatbank/json/requests/refund-request.model';
import { PrivatbankJsonRefundServiceRequest } from './privatbank/json/requests/refund-service-request.model';
import { PrivatbankJsonServiceMessageRequest } from './privatbank/json/requests/service-message-request.model';
import { PrivatbankJsonVerifyRequest } from './privatbank/json/requests/verify-request.model';
import { PrivatbankJsonWithdrawalRequest } from './privatbank/json/requests/withdrawal-request.model';
import { PrivatbankJsonIdentifyResponse } from './privatbank/json/responses/identify-response.model';
import { PrivatbankJsonPingDeviceResponse } from './privatbank/json/responses/ping-device-response.model';
import { PrivatbankJsonReceiptResponse } from './privatbank/json/responses/receipt-message-response.model';
import { PrivatbankJsonServiceMessageResponse } from './privatbank/json/responses/service-message-response.model';
import { PrivatbankNfcposService } from './privatbank/nfcpos/privatbank-nfcpos.service';
import { TapXphoneService } from './tapxphone/tapxphone.service';
import { TerminalStorageService } from './terminal-storage.service';
import { TOAST_TITLE } from './terminals.const';

const CASHBOX_RECONNECT_INTERVAL = 4 * 1000; // 8 seconds
const PRIVATBANK_RECONNECT_INTERVAL = 16 * 1000; // 16 seconds
const LAST_STAT_MSG_TIMEOUT = 1000; // 1 second
const MAX_RECONNECT_ATTEMPTS = 5;

const TAPXPHONE_BATCH_CLOSED = [4, 6];

const LOG_KEY = 'terminal';

@Injectable({
  providedIn: 'root',
})
export class TerminalsService {
  private tcpSocket: Socket;
  private webSocket: WebSocketSubject<PrivatbankJsonMessage>;

  private readonly connectionStatus: BehaviorSubject<boolean>;

  private mainMethods = Object.values(PrivatbankJsonMethod)
    .filter((m) => m !== PrivatbankJsonMethod.ServiceMessage)
    .map((m) => m.toString());

  private waitResponseMethods = [
    PrivatbankJsonMethod.Purchase,
    PrivatbankJsonMethod.Refund,
    PrivatbankJsonMethod.ServiceRefund,
    PrivatbankJsonMethod.Withdrawal,
    PrivatbankJsonMethod.Audit,
    PrivatbankJsonMethod.Verify,
  ].map((m) => m.toString());

  private isDesktop = false;
  private isAndroidApp = false;
  private currentMethod = '';
  private previousResponse = '';
  private showDebugMessage = true;

  private retryAttempt = 0;

  constructor(
    private events: Events,
    private alertCtrl: AlertController,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private cachedDataService: CachedDataService,
    private terminalStorageService: TerminalStorageService,
    private utilsService: UtilsService,
    private tapXphoneService: TapXphoneService,
    private privatbankNfcposService: PrivatbankNfcposService,
    private logsService: LogsService,
  ) {
    this.isDesktop = this.utilsService.isDesktop();
    this.isAndroidApp = this.utilsService.isAndroidApp();

    this.connectionStatus = new BehaviorSubject<boolean>(false);

    if (this.isAndroidApp) {
      this.tcpSocket = new Socket();

      this.tcpSocket.onData = (data: Uint8Array) => {
        // Invoked after new batch of data is received (typed array of bytes Uint8Array)
        const response = Buffer.from(data).toString().slice(0, -1);

        this.log(`< Response [#timestamp]: ${response}`);

        const parsedResponse = this.getParsedResponse(response);

        if (parsedResponse != null) {
          this.handleResponse(parsedResponse);
        }
      };

      this.tcpSocket.onError = (error: string) => {
        // Invoked after error occurs during connection
        const errorMessage = `Помилка обробки відповіді: ${error}`;

        this.log(`> Error [#timestamp] ${TOAST_TITLE}: ${errorMessage}`);
        this.toastService.presentError(TOAST_TITLE, errorMessage);
      };

      this.tcpSocket.onClose = (hasError: boolean) => {
        // Invoked after connection close
        let message = `Закриття з'єднання`;

        if (hasError) {
          message += ' (з помилками)';

          this.toastService.presentWarning(TOAST_TITLE, `${message}`);
        }

        this.log(`> [#timestamp] ${TOAST_TITLE}: ${message}`);
      };
    }

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonMethod.PingDevice}`,
      (response: PrivatbankJsonPingDeviceResponse) => {
        this.showAlert(
          'Протокол',
          Number(response.params.responseCode) === 0
            ? 'ПриватБанк JSON based'
            : `${response.params.responseCode} ${response.errorDescription}`,
        );
      },
    );

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonServiceMessageTypeResponse.identify}`,
      (response: PrivatbankJsonIdentifyResponse) => {
        this.showAlert(
          'Термінал',
          `${response.params.vendor} ${response.params.model}`,
        );
      },
    );

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonServiceMessageTypeResponse.debugOn}`,
      (response: PrivatbankJsonIdentifyResponse) => {
        this.showDebugAlert(response);
      },
    );

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonServiceMessageTypeResponse.debugOff}`,
      (response: PrivatbankJsonIdentifyResponse) => {
        this.showDebugAlert(response);
      },
    );

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonServiceMessageTypeResponse.getMerchantList}`,
      (response: PrivatbankJsonIdentifyResponse) => {
        const entries = Object.entries(response.params).filter(
          (e) =>
            !e.includes(
              PrivatbankJsonServiceMessageTypeRequest.getMerchantList,
            ),
        );

        this.showAlert(
          'Мерчанти',
          entries.map((e) => `${e[0]}: ${e[1]}`).join('<br>'),
        );
      },
    );

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonMethod.Audit}`,
      (auditResponse: PrivatbankJsonReceiptResponse) => {
        this.showAlert(
          'X-звіт',
          auditResponse.params.receipt
            ? auditResponse.params.receipt
            : auditResponse.errorDescription,
        );
      },
    );

    this.events.subscribe(
      `${TERMINAL}:${PrivatbankJsonMethod.Verify}`,
      (verifyResponse: PrivatbankJsonReceiptResponse) => {
        this.showAlert(
          'Загальний звіт',
          verifyResponse.params.receipt
            ? verifyResponse.params.receipt
            : verifyResponse.errorDescription,
        );
      },
    );
  }

  init(): void {
    if (!(this.isDesktop || this.isAndroidApp)) {
      return;
    }

    this.getTerminalData().then((terminal) => {
      if (
        terminal.connectionProtocol === TerminalConnectionProtocol.None ||
        terminal.connectionProtocol === TerminalConnectionProtocol.TapToMono ||
        terminal.connectionProtocol === TerminalConnectionProtocol.TapToPrivat
      ) {
        return;
      }

      this.getSettings().then((settings) => {
        if (
          this.isAndroidApp &&
          terminal.connectionProtocol ===
            TerminalConnectionProtocol.PrivatbankJson &&
          this.validSettings(settings)
        ) {
          this.connectForAndroid(settings);
        } else if (this.isDesktop) {
          this.connectForDesktop(terminal);
        }
      });
    });
  }

  private validSettings(settings: IStoragePaymentTerminalSettings): boolean {
    if (
      settings.connectionMethod === TerminalConnectionMethod.Ethernet &&
      !settings.ip
    ) {
      this.toastService.presentError(
        TOAST_TITLE,
        'Не заповнена IP-адреса термінала',
      );

      return false;
    }

    if (
      settings.connectionMethod === TerminalConnectionMethod.USB &&
      !settings.usbPort
    ) {
      this.toastService.presentError(
        TOAST_TITLE,
        'Не заповнений USB-порт (COM-порт) підключення термінала',
      );

      return false;
    }

    return true;
  }

  async start(settings: IStoragePaymentTerminalSettings): Promise<void> {
    const terminal = await this.getTerminalData();

    if (this.isAndroidApp) {
      this.connectForAndroid(settings);
    } else if (this.isDesktop) {
      this.connectForDesktop(terminal);
    }
  }

  private connectForAndroid(settings: IStoragePaymentTerminalSettings): void {
    this.tcpSocket.open(
      settings.ip,
      2000,
      async () => {
        // Invoked after successful opening of socket
        await this.successfullyConnected();
      },
      (error: string) => {
        // Invoked after unsuccessful opening of socket
        this.connectionStatus.next(false);

        const errorMessage = `Помилка активації: ${error}`;

        this.toastService.presentError(TOAST_TITLE, errorMessage);
        this.log(`> Error [#timestamp] ${TOAST_TITLE}: ${errorMessage}`);
      },
    );
  }

  private getParsedResponse(response: string): PrivatbankJsonMessage | null {
    try {
      return JSON.parse(response) as PrivatbankJsonMessage;
    } catch (error) {
      this.log(
        `> Parsed error, level 1 [#timestamp]: ${JSON.stringify(error)}\n"${
          this.previousResponse
        }"\n"${response}"\n`,
      );

      if (this.previousResponse.length > 0) {
        return this.getParsedResponseLevel2(response);
      }

      this.previousResponse = response;
    }

    return null;
  }

  private getParsedResponseLevel2(
    response: string,
  ): PrivatbankJsonMessage | null {
    try {
      return JSON.parse(response) as PrivatbankJsonMessage;
    } catch (error) {
      this.log(
        `> Parsed error, level 2 [#timestamp]: ${JSON.stringify(error)}\n"${
          this.previousResponse
        }"\n"${response}"\n`,
      );

      return this.getParsedResponseLevel3(response);
    }
  }

  private getParsedResponseLevel3(
    response: string,
  ): PrivatbankJsonMessage | null {
    try {
      return JSON.parse(
        `${this.previousResponse}${response}`,
      ) as PrivatbankJsonMessage;
    } catch (error) {
      this.log(
        `> Parsed error, level 3 [#timestamp]: ${JSON.stringify(error)}\n"${
          this.previousResponse
        }"\n"${response}"\n`,
      );

      return this.getParsedResponseLevel4(response);
    }
  }

  private getParsedResponseLevel4(
    response: string,
  ): PrivatbankJsonMessage | null {
    let parsedResponse: PrivatbankJsonMessage | null = null;

    const joinedResponse = `${this.previousResponse}${response}`;
    const separatorMatch = joinedResponse.match(/\}.\{/);

    if (separatorMatch != null) {
      const separator = separatorMatch[0][1] ?? '�';

      for (const subresponse of joinedResponse.split(separator)) {
        try {
          const terminalMessage = JSON.parse(
            subresponse,
          ) as PrivatbankJsonMessage;

          if (terminalMessage.method !== PrivatbankJsonMethod.ServiceMessage) {
            parsedResponse = terminalMessage;
          }
        } catch (error) {
          this.log(
            `> Parsed error, level 4 [#timestamp]: ${JSON.stringify(
              error,
            )}\n"${subresponse}"\n`,
          );
        }
      }
    }

    return parsedResponse;
  }

  private connectForDesktop(terminalData: IStoragePaymentTerminalData): void {
    if (this.webSocket != null && this.webSocket.closed) {
      this.webSocket.complete();
    }

    this.retryAttempt = 0;

    this.createWebSocketConnection(terminalData);

    this.webSocket
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            concatMap((error) =>
              iif(
                () => this.retryAttempt >= MAX_RECONNECT_ATTEMPTS,
                throwError(
                  'Перевищено ліміт спроб повторного підключення до емулятора',
                ),
                of(error).pipe(
                  tap(async () => {
                    await this.reconnectToWebSocketServer(
                      this.retryAttempt,
                      terminalData,
                    );

                    this.retryAttempt += 1;
                  }),
                  delay(
                    terminalData.connectionProtocol ===
                      TerminalConnectionProtocol.PrivatbankJson
                      ? PRIVATBANK_RECONNECT_INTERVAL
                      : CASHBOX_RECONNECT_INTERVAL,
                  ),
                ),
              ),
            ),
          ),
        ),
      )
      .subscribe(
        (data) => {
          this.log(`< Response [#timestamp]: ${JSON.stringify(data)}`);
          this.handleResponse(data);
        },
        (error) => {
          const errorMessage = `Помилка обробки відповіді: ${this.parseConnectionError(
            error,
          )}`;

          this.log(`> Error [#timestamp] ${TOAST_TITLE}: ${errorMessage}`);
          this.toastService.presentError(TOAST_TITLE, errorMessage);
        },
        () => {
          const message = `З'єднання з емулятором термінала завершене`;

          this.log(`> [#timestamp] ${TOAST_TITLE}: ${message}`);
          this.toastService.presentWarning(TOAST_TITLE, message);
        },
      );
  }

  private createWebSocketConnection(
    terminalData: IStoragePaymentTerminalData,
  ): void {
    this.webSocket = webSocket({
      url: `ws://${
        terminalData.connectionProtocol ===
        TerminalConnectionProtocol.PrivatbankJson
          ? '127.0.0.1'
          : terminalData.agentIp
      }:3000/${
        terminalData.connectionProtocol ===
        TerminalConnectionProtocol.PrivatbankJson
          ? 'echo'
          : 'terminal'
      }`,
      openObserver: {
        next: async () => {
          await this.successfullyConnected();
        },
      },
      closeObserver: {
        next: () => {
          this.successfullyDisconnected();
        },
      },
    });
  }

  private async reconnectToWebSocketServer(
    retryAttempt: number,
    terminalData: IStoragePaymentTerminalData,
  ): Promise<void> {
    if (
      retryAttempt === 0 ||
      terminalData.connectionProtocol ===
        TerminalConnectionProtocol.PrivatbankJson
    ) {
      const settings = await this.getSettings();

      await this.startEmulator(settings, terminalData.connectionProtocol);
    }

    this.connectionStatus.next(false);

    if (retryAttempt > 0) {
      const message = `Відновлення з'єднання (спроба ${retryAttempt}/${MAX_RECONNECT_ATTEMPTS})...`;

      this.toastService.presentWarning(
        TOAST_TITLE,
        message,
        terminalData.connectionProtocol ===
          TerminalConnectionProtocol.PrivatbankJson
          ? PRIVATBANK_RECONNECT_INTERVAL
          : CASHBOX_RECONNECT_INTERVAL,
      );

      this.log(`> [#timestamp] ${TOAST_TITLE}: ${message}`);
    }
  }

  disconnect(): void {
    this.getTerminalData().then((terminal) => {
      if (
        terminal.connectionProtocol === TerminalConnectionProtocol.None ||
        terminal.connectionProtocol === TerminalConnectionProtocol.TapToMono ||
        terminal.connectionProtocol === TerminalConnectionProtocol.TapToPrivat
      ) {
        return;
      }

      if (this.isAndroidApp) {
        this.tcpSocket.close(
          () => {
            this.successfullyDisconnected();
          },
          (error: string) => {
            const errorMessage = `Помилка відключення: ${error}`;

            this.toastService.presentError(TOAST_TITLE, errorMessage);
            this.log(`> Error [#timestamp] ${TOAST_TITLE}: ${errorMessage}`);
          },
        );
      } else if (this.isDesktop) {
        this.webSocket?.complete();
      }
    });
  }

  private async successfullyConnected(): Promise<void> {
    this.connectionStatus.next(true);

    this.retryAttempt = 0;

    const message = 'Суміщення з терміналом активоване';

    this.log(`> [#timestamp] ${message}`);
    this.toastService.present(message);

    const settings = await this.getSettings();
    const data = await this.getTerminalData();

    this.log(
      `> [#timestamp] ${JSON.stringify(
        {
          acquirerName: data.acquirerName,
          deviceId: data.deviceId,
          connectionProtocol: data.connectionProtocol,
          merchantId: settings.merchantId,
          serviceRefund: settings.serviceRefund,
          autoDebug: settings.autoDebug,
          connectionMethod: settings.connectionMethod,
          ip: settings.ip,
          usbPort: settings.usbPort,
        },
        null,
        2,
      )}`,
    );

    if (
      data.connectionProtocol === TerminalConnectionProtocol.PrivatbankJson &&
      settings.autoDebug
    ) {
      setTimeout(async () => {
        await this.debugMode({ showDebugMessage: false });
      }, 1000);
    }
  }

  private successfullyDisconnected(): void {
    this.connectionStatus.next(false);

    const message = 'Суміщення з терміналом завершене';

    this.log(`> [#timestamp] ${message}`);
    this.toastService.present(message);
  }

  private parseConnectionError(error: any): string {
    return typeof error === 'string' ? error : JSON.stringify(error);
  }

  private handleResponse(data: PrivatbankJsonMessage): void {
    if (this.needStop(data)) {
      this.loadingService.dismiss(TERMINAL);

      return;
    }

    if (
      this.mainMethods.includes(data.method) ||
      data.params?.msgType ===
        PrivatbankJsonServiceMessageTypeResponse.debugOn ||
      data.params?.msgType ===
        PrivatbankJsonServiceMessageTypeResponse.debugOff ||
      data.params?.msgType ===
        PrivatbankJsonServiceMessageTypeResponse.identify ||
      data.params?.msgType ===
        PrivatbankJsonServiceMessageTypeResponse.getMerchantList
    ) {
      this.publish(data);

      this.currentMethod = '';
      this.previousResponse = '';

      return;
    }

    if (this.waitResponseMethods.includes(this.currentMethod)) {
      setTimeout(() => {
        this.getLastStatMsgCode();
      }, LAST_STAT_MSG_TIMEOUT);
    }
  }

  getConnectionStatus(): Observable<boolean> {
    return this.connectionStatus;
  }

  private async startEmulator(
    settings: IStoragePaymentTerminalSettings,
    connectionProtocol: TerminalConnectionProtocol,
  ): Promise<void> {
    const message = 'Запуск емулятора платіжного термінала';

    this.toastService.present(
      message,
      'Будь ласка, зачекайте повідомлення про активацію суміщення',
      connectionProtocol === TerminalConnectionProtocol.PrivatbankJson
        ? PRIVATBANK_RECONNECT_INTERVAL
        : CASHBOX_RECONNECT_INTERVAL,
    );

    const params =
      settings.connectionMethod === TerminalConnectionMethod.Ethernet
        ? `:${settings.ip}`
        : settings.usbPort
        ? `:${settings.usbPort}`
        : '';

    const command = `cashbox.${
      connectionProtocol === TerminalConnectionProtocol.PrivatbankJson
        ? 'privatbank'
        : 'terminal'
    }:start:${settings.connectionMethod}${params}`;

    this.log(`> [#timestamp] ${message}: ${command}`);

    window.location.assign(command);
  }

  log(message: string): void {
    const value = message.replace(
      '[#timestamp]',
      `[${formatDate(new Date(), 'HH:mm:ss.sss', 'uk-UA')}]`,
    );

    // tslint:disable-next-line:no-console
    console.log(value);

    this.events.publish(TERMINAL_LOG, value);
    this.logsService.add(LOG_KEY, value).then().catch();
  }

  private getLastStatMsgCode(): void {
    const serviceMessage = new PrivatbankJsonServiceMessageRequest(
      PrivatbankJsonServiceMessageTypeRequest.getLastStatMsgCode,
    );

    if (this.isAndroidApp) {
      this.tcpSocket.write(
        serviceMessage.toArray(),
        () => {
          //
        },
        (error: string) => {
          this.showWriteError(error);
        },
      );
    } else if (this.isDesktop) {
      this.webSocket.next(serviceMessage);
    }
  }

  private needStop(data: PrivatbankJsonMessage): boolean {
    if (data.error) {
      this.currentMethod = '';
      this.previousResponse = '';

      let errorMessage = data.errorDescription;

      if (
        data.params?.responseCode != null &&
        Number(data.params?.responseCode) >= 1000 &&
        PrivatbankJsonResponseCode[Number(data.params?.responseCode)]
      ) {
        errorMessage =
          PrivatbankJsonResponseCode[Number(data.params?.responseCode)];

        if (data.errorDescription) {
          errorMessage += `: ${data.errorDescription}`;
        }
      }

      this.events.publish(TERMINAL_SERVICE_MESSAGE, {
        description: errorMessage,
        error: true,
      });

      this.toastService.presentError(TOAST_TITLE, errorMessage);

      return true;
    }

    if (data.method === PrivatbankJsonMethod.ServiceMessage) {
      const serviceMessage = data as PrivatbankJsonServiceMessageResponse;

      if (
        serviceMessage.params.msgType ===
        PrivatbankJsonServiceMessageTypeResponse.deviceBusy
      ) {
        this.currentMethod = '';

        const errorMessage = 'Термінал зайнятий. Зачекайте й спробуйте пізніше';

        this.events.publish(TERMINAL_SERVICE_MESSAGE, {
          description: errorMessage,
          error: true,
        });

        this.toastService.presentWarning(TOAST_TITLE, errorMessage);

        return true;
      }

      if (
        serviceMessage.params.msgType ===
        PrivatbankJsonServiceMessageTypeResponse.interruptTransmitted
      ) {
        this.currentMethod = '';

        const errorMessage = 'Операцію скасовано';

        this.events.publish(TERMINAL_SERVICE_MESSAGE, {
          description: errorMessage,
          error: true,
        });

        this.toastService.presentWarning(TOAST_TITLE, errorMessage);

        return true;
      }

      if (
        serviceMessage.params.msgType ===
          PrivatbankJsonServiceMessageTypeResponse.getLastStatMsgCode &&
        Number(serviceMessage.params.LastStatMsgCode) > 0
      ) {
        this.events.publish(TERMINAL_SERVICE_MESSAGE, {
          description:
            PrivatbankJsonLastStatMsgCode[
              Number(serviceMessage.params.LastStatMsgCode)
            ],
          error: false,
        });
      }
    }

    return false;
  }

  private publish(data: PrivatbankJsonMessage): void {
    this.loadingService.dismiss(TERMINAL);
    this.events.publish(
      `${TERMINAL}:${
        data.params?.msgType ===
          PrivatbankJsonServiceMessageTypeResponse.debugOn ||
        data.params?.msgType ===
          PrivatbankJsonServiceMessageTypeResponse.debugOff ||
        data.params?.msgType ===
          PrivatbankJsonServiceMessageTypeResponse.identify ||
        data.params?.msgType ===
          PrivatbankJsonServiceMessageTypeResponse.getMerchantList
          ? data.params?.msgType
          : this.currentMethod
      }`,
      data,
    );
  }

  private sendMessage(
    message: PrivatbankJsonBaseRequest,
    options: { showLoader: boolean } = { showLoader: false },
  ): void {
    if (!this.connectionStatus.value) {
      return;
    }

    if (
      (this.isAndroidApp && this.tcpSocket.state !== Socket.State.OPENED) ||
      (this.isDesktop && (!this.webSocket || this.webSocket.closed))
    ) {
      this.toastService.presentWarning(
        TOAST_TITLE,
        'Суміщення не активне. Зачекайте кілька секунд або перезавантажте сторінку',
      );

      return;
    }

    if (options.showLoader) {
      this.loadingService.presentCustomPreloader(TERMINAL);
    }

    this.log(`> Request [#timestamp]: ${JSON.stringify(message)}`);

    this.currentMethod = message.method;
    this.previousResponse = '';

    if (this.isAndroidApp) {
      this.tcpSocket.write(
        message.toArray(),
        () => {
          //
        },
        (error: string) => {
          this.showWriteError(error);
        },
      );
    } else if (this.isDesktop) {
      this.webSocket.next(message);
    }

    if (this.waitResponseMethods.includes(this.currentMethod)) {
      setTimeout(() => {
        this.getLastStatMsgCode();
      }, 2 * LAST_STAT_MSG_TIMEOUT);
    }
  }

  private showWriteError(error: string): void {
    const errorMessage = `Помилка відправки повідомлення: ${error}`;

    this.log(`> Error [#timestamp] ${TOAST_TITLE}: ${errorMessage}`);
    this.toastService.presentError(TOAST_TITLE, errorMessage);
  }

  private showAlert(title: string, comment: string): void {
    this.alertCtrl
      .create({
        header: `${title} ${TOAST_TITLE.toLowerCase()}`,
        message: comment,
        buttons: [{ text: 'Закрити', role: 'close' }],
      })
      .then((alert) => alert.present());
  }

  private showDebugAlert(response: PrivatbankJsonIdentifyResponse): void {
    if (this.showDebugMessage) {
      this.showAlert('Debug', `${response.params.msgType}`);
    } else if (
      response.params.msgType ===
      PrivatbankJsonServiceMessageTypeResponse.debugOff
    ) {
      this.getSettings().then((settings) => {
        if (settings.autoDebug) {
          this.debugMode({ showDebugMessage: false });
        }
      });
    }
  }

  pingDevice(): void {
    this.sendMessage(new PrivatbankJsonPingDeviceRequest());
  }

  identify(): void {
    this.sendMessage(
      new PrivatbankJsonServiceMessageRequest(
        PrivatbankJsonServiceMessageTypeRequest.identify,
      ),
    );
  }

  getMerchantList(): void {
    this.sendMessage(
      new PrivatbankJsonServiceMessageRequest(
        PrivatbankJsonServiceMessageTypeRequest.getMerchantList,
      ),
    );
  }

  async interrupt(): Promise<void> {
    const terminal = await this.getTerminalData();

    if (terminal.connectionProtocol === TerminalConnectionProtocol.TapToMono) {
      this.tapXphoneService.cancelTransaction();
    } else if (
      terminal.connectionProtocol === TerminalConnectionProtocol.TapToPrivat
    ) {
      //
    } else {
      this.sendMessage(
        new PrivatbankJsonServiceMessageRequest(
          PrivatbankJsonServiceMessageTypeRequest.interrupt,
        ),
      );
    }
  }

  async audit(): Promise<void> {
    const terminal = await this.getTerminalData();
    const settings = await this.getSettings();
    const audit = new PrivatbankJsonAuditRequest(settings.merchantId);

    if (terminal.connectionProtocol === TerminalConnectionProtocol.TapToMono) {
      const response = await this.tapXphoneService.batch('status');

      this.events.publish(`${TERMINAL}:${PrivatbankJsonMethod.Audit}`, {
        method: PrivatbankJsonMethod.Audit,
        step: 0,
        params: {
          receipt: response?.description ?? 'Невідомий статус',
          responseCode: response?.code ?? '0',
        },
        error: false,
        errorDescription: '',
      });
    } else if (
      terminal.connectionProtocol === TerminalConnectionProtocol.TapToPrivat
    ) {
      //
    } else {
      this.sendMessage(audit, { showLoader: true });
    }
  }

  async verify(): Promise<void> {
    const terminal = await this.getTerminalData();
    const settings = await this.getSettings();
    const verify = new PrivatbankJsonVerifyRequest(settings.merchantId);

    if (terminal.connectionProtocol === TerminalConnectionProtocol.TapToMono) {
      const response = await this.tapXphoneService.batch('close');

      this.events.publish(`${TERMINAL}:${PrivatbankJsonMethod.Verify}`, {
        method: PrivatbankJsonMethod.Verify,
        step: 0,
        params: {
          receipt: response?.description ?? 'Невідомий статус',
          responseCode: response?.code ?? '0',
        },
        error: false,
        errorDescription: '',
      });
    } else if (
      terminal.connectionProtocol === TerminalConnectionProtocol.TapToPrivat
    ) {
      //
    } else {
      this.sendMessage(verify, { showLoader: true });
    }
  }

  async debugMode(
    data: { showDebugMessage: boolean } = { showDebugMessage: true },
  ): Promise<void> {
    this.showDebugMessage = data.showDebugMessage;

    this.sendMessage(
      new PrivatbankJsonServiceMessageRequest(
        PrivatbankJsonServiceMessageTypeRequest.debug,
      ),
    );
  }

  async closeBatch(): Promise<string> {
    const terminal = await this.getTerminalData();

    if (terminal.connectionProtocol === TerminalConnectionProtocol.TapToMono) {
      const statusResponse = await this.tapXphoneService.batch('status');

      if (TAPXPHONE_BATCH_CLOSED.includes(statusResponse?.code ?? 0)) {
        return 'Бізнес-день терміналу було закрито раніше<br>';
      }

      const closeResponse = await this.tapXphoneService.batch('close');

      if (closeResponse?.code === 204) {
        return 'Бізнес-день терміналу закрито<br>';
      }

      return closeResponse?.description ?? '';
    }

    return '';
  }

  async transactionStatus(
    amount: number,
    rrn: string,
    connectionProtocol: TerminalConnectionProtocol,
  ): Promise<void> {
    if (connectionProtocol === TerminalConnectionProtocol.TapToMono) {
      this.tapXphoneService.transactionStatus();
    } else if (connectionProtocol === TerminalConnectionProtocol.TapToPrivat) {
      this.privatbankNfcposService.getCheck(amount, rrn);
    }
  }

  async openApp(): Promise<void> {
    await this.privatbankNfcposService.openApp();
  }

  async tapXphoneAppStatus(): Promise<void> {
    await this.tapXphoneService.checkDeviceStatus();
  }

  async isTapXPhoneWarnings(): Promise<string[]> {
    const shop = this.cachedDataService.getShop();
    const user = this.cachedDataService.getUser();
    const settings = await this.getSettings();

    const result: string[] = [];

    if (user.tapxphoneConfirmedAt == null) {
      result.push(
        'Користувач не ознайомився з правилами використання tapXphone',
      );
    }

    if (settings.tapXphoneDeviceId === '') {
      result.push('Невідомий статус tapXphone на пристрої');
    }

    if (shop.tapxphoneDeviceId === '') {
      result.push('Додаток не активовано в адмін-панелі cashbox');
    } else if (settings.tapXphoneDeviceId !== shop.tapxphoneDeviceId) {
      result.push(
        'tapXphone ID на пристрої та в адмін-панелі cashbox відрізняються',
      );
    }

    return result;
  }

  async purchase(
    amount: number,
    connectionProtocol: TerminalConnectionProtocol,
  ): Promise<void> {
    const settings = await this.getSettings();

    if (connectionProtocol === TerminalConnectionProtocol.TapToMono) {
      await this.tapXphoneService.transaction(
        settings.tapXphoneDeviceId,
        amount,
      );
    } else if (connectionProtocol === TerminalConnectionProtocol.TapToPrivat) {
      await this.privatbankNfcposService.transaction(amount);
    } else {
      const purchase = new PrivatbankJsonPurchaseRequest(
        amount,
        settings.merchantId,
      );

      await this.checkReconnectForAndroid(settings);

      this.sendMessage(purchase);
    }
  }

  async withdrawal(invoiceNumber: string): Promise<void> {
    const settings = await this.getSettings();
    const withdrawal = new PrivatbankJsonWithdrawalRequest(invoiceNumber);

    await this.checkReconnectForAndroid(settings);

    this.sendMessage(withdrawal);
  }

  async refund(
    amount: number,
    rrn: string,
    connectionProtocol: TerminalConnectionProtocol,
  ): Promise<void> {
    const settings = await this.getSettings();

    if (connectionProtocol === TerminalConnectionProtocol.TapToMono) {
      await this.tapXphoneService.transaction(
        settings.tapXphoneDeviceId,
        amount,
        rrn,
      );
    } else if (connectionProtocol === TerminalConnectionProtocol.TapToPrivat) {
      await this.privatbankNfcposService.transaction(amount, rrn);
    } else {
      const refund = new PrivatbankJsonRefundRequest(
        amount,
        rrn,
        settings.merchantId,
      );

      await this.checkReconnectForAndroid(settings);

      this.sendMessage(refund);
    }
  }

  async refundService(amount: number, rrn: string): Promise<void> {
    const settings = await this.getSettings();
    const refundService = new PrivatbankJsonRefundServiceRequest(amount, rrn);

    await this.checkReconnectForAndroid(settings);

    this.sendMessage(refundService);
  }

  private async checkReconnectForAndroid(
    settings: IStoragePaymentTerminalSettings,
  ): Promise<void> {
    if (this.isAndroidApp && this.tcpSocket.state !== Socket.State.OPENED) {
      this.connectForAndroid(settings);

      await new Promise((f) => setTimeout(f, 1000));
    }
  }

  async clearData(): Promise<void> {
    await this.terminalStorageService.clearData();
  }

  async getTerminalData(): Promise<IStoragePaymentTerminalData> {
    return this.terminalStorageService.getTerminalData();
  }

  async getSettings(): Promise<IStoragePaymentTerminalSettings> {
    return this.terminalStorageService.getSettings();
  }

  async setDefaultPaymentData(
    data: IStoragePaymentTerminalData,
  ): Promise<void> {
    this.terminalStorageService.setDefaultPaymentData(data);
  }

  async setSettings(settings: IStoragePaymentTerminalSettings): Promise<void> {
    this.terminalStorageService.setSettings(settings);
  }
}
