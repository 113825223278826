import { AfterViewInit, Component } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { IPairedBluetoothDevice } from '../../core/interfaces/paired-bluetooth-device.interface';
import { IStorageUserSettings } from '../../core/interfaces/storage-user-setting.interface';
import { CachedDataService } from '../../core/services/cached-data.service';
import { LoadingService } from '../../core/services/loading.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { CONNECTION_ERROR } from '../settings.const';

import { BarcodeScannerService } from './barcode-scanner.service';

const LOADING_ID = 'barcode-scanner.component';

@Component({
  selector: 'bk-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss'],
})
export class BarcodeScannerComponent implements AfterViewInit {
  userSettings: IStorageUserSettings;

  isAndroidApp = false;
  isDesktop = false;
  isBluetoothEnabled = false;
  isConnected = false;

  pairedDevices: IPairedBluetoothDevice[] = [];
  currentDevice: IPairedBluetoothDevice | null = null;

  subscription: Subscription;

  constructor(
    private platform: Platform,
    private diagnostic: Diagnostic,
    private androidPermissions: AndroidPermissions,
    private bluetoothSerial: BluetoothSerial,
    private alertCtrl: AlertController,
    private barcodeScannerService: BarcodeScannerService,
    private loadingService: LoadingService,
    private cachedDataService: CachedDataService,
    private utilsService: UtilsService,
    private toastService: ToastService,
  ) {
    this.userSettings = this.cachedDataService.getUserSettings();

    this.isAndroidApp = this.utilsService.isAndroidApp();
    this.isDesktop = this.utilsService.isDesktop();
  }

  async ngAfterViewInit(): Promise<void> {
    await this.checkBluetoothStatus();
    await this.setBluetoothDevices();
  }

  toggleMode(): void {
    this.userSettings.keyboardMode = !this.userSettings.keyboardMode;

    this.cachedDataService.setUserSettings(this.userSettings);

    if (
      this.platform.is('cordova') &&
      this.userSettings.keyboardMode &&
      this.isBluetoothEnabled
    ) {
      this.barcodeScannerService
        .disconnectBluetoothDevice()
        .then(() => {
          this.toastService.present('Сканер штрихкодів', 'Вимкнено');
        })
        .catch((error) => {
          this.toastService.presentError(
            'Сканер штрихкодів',
            `Не вдалося вимкнути: ${JSON.stringify(error)}`,
          );
        });
    }
  }

  toggleBluetoothState(): void {
    if (!this.platform.is('cordova')) {
      return;
    }

    if (this.isBluetoothEnabled) {
      this.diagnostic
        .setBluetoothState(false)
        .then((res) => (this.isBluetoothEnabled = res !== 'OK'));
    } else {
      this.diagnostic
        .setBluetoothState(true)
        .then((res) => (this.isBluetoothEnabled = res === 'OK'));
    }
  }

  initCurrentDevice(): void {
    this.loadingService.presentCustomPreloader(`${LOADING_ID}:init-device`);

    this.subscription = this.barcodeScannerService
      .initBluetoothDevice()
      .subscribe((status) => {
        this.loadingService.dismiss(`${LOADING_ID}:init-device`);

        if (status === CONNECTION_ERROR || status == null) {
          this.subscription.unsubscribe();

          this.isConnected = false;

          return;
        }

        this.isConnected = true;
      });
  }

  removeCurrentDevice(device: IPairedBluetoothDevice): void {
    this.barcodeScannerService.disconnectBluetoothDevice().then(() => {
      this.isConnected = false;

      this.barcodeScannerService.setBluetoothDevice(null);
      this.barcodeScannerService.removeDevice();

      this.pairedDevices.forEach((pd) => {
        if (pd.id === device.id) {
          pd.isUse = false;
        }
      });

      this.currentDevice = null;

      this.subscription.unsubscribe();

      this.alertCtrl
        .create({
          header: 'Пристрій відключено',
          buttons: [{ text: 'Закрити', role: 'close' }],
        })
        .then((alert) => alert.present());
    });
  }

  findNewDevices(): void {
    this.diagnostic.switchToBluetoothSettings();
  }

  setDevice(device: IPairedBluetoothDevice): void {
    this.loadingService.presentCustomPreloader(`${LOADING_ID}:set-device`);

    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.barcodeScannerService
      .reconnectToBluetoothDevice(device)
      .subscribe((status) => {
        this.loadingService.dismiss(`${LOADING_ID}:set-device`);

        if (status === CONNECTION_ERROR) {
          this.subscription.unsubscribe();

          this.isConnected = false;

          return;
        }

        this.currentDevice = { ...device };

        this.pairedDevices.forEach((pd) => {
          if (pd.id === device.id) {
            pd.isUse = true;
          }
        });

        this.isConnected = true;
      });
  }

  private async checkBluetoothStatus(): Promise<void> {
    if (!this.platform.is('cordova')) {
      this.isBluetoothEnabled = false;
      return;
    }

    try {
      const permission = await this.androidPermissions.checkPermission(
        this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT,
      );

      if (permission.hasPermission) {
        // Do nothing and proceed permission exists already
        this.isBluetoothEnabled = await this.diagnostic.isBluetoothEnabled();
      } else {
        // Request for all the permissions in the array
        await this.androidPermissions.requestPermissions([
          this.androidPermissions.PERMISSION.BLUETOOTH,
          this.androidPermissions.PERMISSION.BLUETOOTH_ADMIN,
          this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT,
          this.androidPermissions.PERMISSION.BLUETOOTH_SCAN,
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
        ]);

        this.isBluetoothEnabled = await this.diagnostic.isBluetoothEnabled();
      }
    } catch (error) {
      await this.androidPermissions.requestPermissions([
        this.androidPermissions.PERMISSION.BLUETOOTH,
        this.androidPermissions.PERMISSION.BLUETOOTH_ADMIN,
        this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT,
        this.androidPermissions.PERMISSION.BLUETOOTH_SCAN,
        this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
      ]);

      this.isBluetoothEnabled = await this.diagnostic.isBluetoothEnabled();
    }
  }

  private async setBluetoothDevices(): Promise<void> {
    if (!this.isBluetoothEnabled) {
      return;
    }

    this.currentDevice = this.barcodeScannerService.getDevice();

    if (this.currentDevice != null) {
      this.initCurrentDevice();
    }

    this.bluetoothSerial.list().then((list: IPairedBluetoothDevice[]) => {
      this.pairedDevices = list ?? [];

      for (const item of this.pairedDevices) {
        switch (item.class) {
          case 524:
            item.icon = 'call';
            break;

          case 1028:
            item.icon = 'headset';
            break;

          case 1664:
            item.icon = 'print';
            break;

          default:
            item.icon = 'bluetooth';
            break;
        }

        if (this.currentDevice?.id === item.id) {
          item.isUse = true;
        }
      }
    });
  }
}
