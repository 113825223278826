<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/shop"></ion-back-button>
    </ion-buttons>

    <ion-title>Ревізія</ion-title>

    <ion-buttons slot="end">
      <ng-container>
        <ion-button (click)="showBarcodeSearchbar()">
          <ion-icon name="keypad"></ion-icon>
        </ion-button>

        <bk-bluetooth-barcode-scanner *ngIf="!userSettings.keyboardMode"
                                      [defaultEmitter]="true"
                                      (checkCode)="checkIngredientByCode($event)"></bk-bluetooth-barcode-scanner>
      </ng-container>

      <ion-button (click)="refresh()">
        <ion-icon name="sync-outline"></ion-icon>
      </ion-button>

      <ion-button (click)="openMenu()">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-searchbar *ngIf="searchBarcodeMode"
                    #barcodeSearchbar
                    id="barcodeSearchbar"
                    name="barcodeSearchbar"
                    type="number"
                    class="barcode-search"
                    placeholder="Введіть штрихкод і натисніть Enter"
                    [(ngModel)]="searchBarcode"
                    (ionBlur)="clearBarcodeSearchbar()"
                    (ionCancel)="clearBarcodeSearchbar()"
                    (keyup.enter)="checkBarcode()"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ingredients-filters" *ngIf="shiftIngredients">
    <ion-item>
      <ion-label>Категорія:</ion-label>
      <ion-select name="category"
                  interface="action-sheet"
                  cancelText="Скасувати"
                  [(ngModel)]="filterCategory"
                  (ngModelChange)="filter()">
        <ion-select-option *ngFor="let ingredientCategory of ingredientCategories"
                           [value]="ingredientCategory">
          {{ ingredientCategory.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-searchbar placeholder="Пошук" (ionInput)="search($event)"></ion-searchbar>
  </div>

  <section class="revision" *ngIf="shiftIngredients; else loading">
    <div class="table-header">
      <div [ngClass]="{ 'column-name': shop.dailyRevision, 'manually-column-name': !shop.dailyRevision }">Товар</div>
      <div class="column-quantity ion-text-center" *ngIf="shop.dailyRevision">Поч. зміни</div>
      <div class="column-quantity ion-text-center">{{ shop.dailyRevision ? 'Кін. зміни' : 'Залишок' }}</div>
      <div class="column-measurement">Од.</div>
    </div>

    <ion-content [ngClass]="{ 'manually-revision': !shop.dailyRevision && (!shiftIngredients || !shiftIngredients.length) }">
      <ng-container *ngIf="shiftIngredients.length; else noData">
        <div class="even-row" *ngFor="let shiftIngredient of shiftIngredients; let index = index">
          <div class="table-data-row" *ngIf="index < visibleRows">
            <div class="column-name" [ngClass]="{ 'manually-column-name': !shop.dailyRevision }">
              <span (press)="showCategory(shiftIngredient.ingredient)">
                {{ shiftIngredient.ingredient.name }} {{ shiftIngredient.salePrice ? '[' + (shiftIngredient.salePrice | currency:'UAH':'symbol-narrow') + ']' : '' }}
              </span>

              <ion-button *ngIf="!shop.dailyRevision"
                          class="column-name__add"
                          color="medium" 
                          fill="outline"
                          shape="round"
                          size="small"
                          slot="icon-only" 
                          (click)="addQuantity(shiftIngredient)">
                <ion-icon name="add"></ion-icon>
              </ion-button>
            </div>

            <div class="column-quantity" *ngIf="shop.dailyRevision">
              <ion-item>
                <ion-input  #startRealQuantityInput
                            type="number"
                            step="0.001"
                            [(ngModel)]="shiftIngredient.startRealQuantity"
                            [min]="MIN_QUANTITY_VALUE.toString()"
                            [bkMin]="MIN_QUANTITY_VALUE"
                            [max]="MAX_QUANTITY_VALUE.toString()"
                            [bkMax]="MAX_QUANTITY_VALUE"
                            (change)="realQuantityChanged(shiftIngredient)"
                            (ionFocus)="selectAll(startRealQuantityInput)"></ion-input>
              </ion-item>
            </div>

            <div class="column-quantity">
              <ion-item>
                <ion-input  #endRealQuantityInput
                            type="number"
                            step="0.001"
                            [(ngModel)]="shiftIngredient.endRealQuantity"
                            [min]="MIN_QUANTITY_VALUE.toString()"
                            [bkMin]="MIN_QUANTITY_VALUE"
                            [max]="MAX_QUANTITY_VALUE.toString()"
                            [bkMax]="MAX_QUANTITY_VALUE"
                            (change)="realQuantityChanged(shiftIngredient)"
                            (ionFocus)="selectAll(endRealQuantityInput)"></ion-input>
              </ion-item>
            </div>

            <div class="column-measurement">{{ shiftIngredient.ingredient.measurement }}</div>
          </div>
        </div>

        <ion-infinite-scroll threshold="100px" (ionInfinite)="showMore($event)">
          <ion-infinite-scroll-content loadingSpinner="circular" loadingText="Завантаження даних..."></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ng-container>

      <ng-template #noData>
        <div class="no-data">
          <span>Немає даних</span>
        </div>
      </ng-template>
    </ion-content>
  </section>

  <ng-template #loading>
    <div class="no-data">
      <span>
        <ion-spinner></ion-spinner>
      </span>
    </div>
  </ng-template>
</ion-content>

<ion-footer *ngIf="!shop.dailyRevision && (!shiftIngredients || !shiftIngredients.length)">
  <ion-toolbar>
    <ion-button class="one-button" color="primary" (click)="create()" [disabled]="requestInProgress">
      Створити ревізію
    </ion-button>
  </ion-toolbar>
</ion-footer>
