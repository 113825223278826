<ion-header>
  <ion-toolbar *ngIf="!(isLandscape)">
    <ion-buttons slot="start" *ngIf="!isModal">
      <ion-back-button defaultHref="/shop" (click)="back()"></ion-back-button>
    </ion-buttons>

    <ion-title>Ваговий товар</ion-title>
  </ion-toolbar>

  <ion-toolbar class="landscape-toolbar" *ngIf="isLandscape">
    <ion-buttons slot="start">
      <ion-button color="medium" fill="outline" (click)="goBackToMenu.emit()">
        <ion-label>Меню</ion-label>
      </ion-button>
  
      <ion-button color="medium" fill="outline" (click)="goBackToSubcategories.emit()">
        <ion-label>{{ saleProduct.product.categoryName }}</ion-label>
      </ion-button>
  
      <ion-button color="medium" fill="outline" (click)="goBackToProducts.emit()">
        <ion-label>{{ saleProduct.product.subcategoryName }}</ion-label>
      </ion-button>
    </ion-buttons>
  
    <ion-buttons slot="end">
      <ion-button color="medium" fill="outline" (click)="goBackToProducts.emit()">
        <ion-icon name="arrow-back-outline"></ion-icon>
        <ion-label>Назад</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{ 'dark-background': isLandscape }">
  <section class="weight">
    <h2 class="weight__title">{{ saleProduct.product.name }}</h2>
    <h3 class="weight__price">{{ saleProduct.product.price }} грн / 100 {{ amountType }}</h3>
    
    <div class="weight__data">
      <ion-grid>
        <ion-row *ngIf="isScalesAvailable && !scalesData?.isError && !scalesData?.isStable">
          <ion-col class="ion-text-center">
            <ion-text color="warning">
              <ion-label>Зачекайте... Вага не стабільна...</ion-label>
            </ion-text>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="ion-text-end">
            <ion-label>{{ amountName }}</ion-label>
          </ion-col>
  
          <ion-col size="3">
            <ion-item [ngClass]="{ 'dark-item': isLandscape }">
              <ion-input  type="tel"
                          name="weight"
                          pattern="[0-9]{1,5}"
                          [placeholder]="amountName"
                          step="1"
                          [(ngModel)]="productWeight"
                          [autofocus]="true"
                          [required]="true"
                          [maxlength]="5"
                          [min]="MIN_WEIGHT_VALUE.toString()"
                          [bkMin]="MIN_WEIGHT_VALUE"
                          [max]="MAX_WEIGHT_VALUE.toString()"
                          [bkMax]="MAX_WEIGHT_VALUE"
                          (ngModelChange)="recalculation()"
                          (ionFocus)="selectAll()"
                          (keyup.enter)="saveAndReturn()"></ion-input>
            </ion-item>
          </ion-col>
  
          <ion-col size="2">
            <ion-label>{{ amountType }}</ion-label>
          </ion-col>
        </ion-row>
  
        <ion-row>
          <ion-col class="ion-text-end">
            <ion-label>Вартість</ion-label>
          </ion-col>
  
          <ion-col size="3" class="ion-text-center weight__cost">
            <span>{{ cost }}</span>
          </ion-col>
  
          <ion-col size="2">
            <ion-label>грн</ion-label>
          </ion-col>
        </ion-row>    
      </ion-grid>
    </div>
  </section>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button slot="start" class="two-buttons" color="tertiary" (click)="cancel()" *ngIf="isModal">
      <ion-label>Скасувати</ion-label>
    </ion-button>

    <ion-button slot="end" class="two-buttons" color="primary" (click)="saveAndReturn()" [disabled]="!isValid()">
      <ion-label>Підтвердити</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>
