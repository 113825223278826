import { Product } from '../../shop/products/product.model';

export class PresaleProduct {
  id?: number;
  quantity: number;
  price: number;
  productId: number;
  product: Product;
  bonusPayment?: number;
  bonusAdd?: number;
  personalDiscount?: number;
  freeCup?: number;
  excisePercent?: number;
  exciseAmount?: number;
  exciseLabel?: string;
  exciseLetter?: string;
  UKTZED?: string;
  vatPercent?: number;
  vatAmount?: number;
  vatLetter?: string;
  productName?: string;

  // App fields
  cost: number;
  fullPrice: number;
  fullCost: number;
  discount?: number;
}
