import {
  UAH,
  WASTE_ACCOUNTANT_SIGN_CAPTION,
  WASTE_DIRECTOR_SIGN_CAPTION,
  WASTE_HEADER_NAME_CAPTION,
  WASTE_HEADER_NO_VAT_CAPTION,
  WASTE_HEADER_PRICE_CAPTION,
  WASTE_HEADER_QUANTITY_CAPTION,
  WASTE_HEADER_SUM_CAPTION,
  WASTE_NUMBER_CAPTION,
  WASTE_POA_CAPTION,
  WASTE_POSITION_COUNT_1_CAPTION,
  WASTE_POSITION_COUNT_2_CAPTION,
  WASTE_PROVIDER_NAME_CAPTION,
  WASTE_PROVIDER_SIGN_CAPTION,
  WASTE_SALE_PRICE_MODE_NOTE,
  WASTE_SELF_NAME_CAPTION,
  WASTE_SHOP_SIGN_CAPTION,
  WASTE_TOTAL_COST_SUM_CAPTION,
  WASTE_TOTAL_SALE_SUM_CAPTION,
  WASTE_TOTAL_SUM_CAPTION,
  WASTE_TOTAL_SUM_WITH_VAT_CAPTION,
  WASTE_TOTAL_VAT_CAPTION,
  WASTE_USER_NAME_CAPTION,
} from '../receipt.const';

import { PrintProductItem } from './print-product-item.model';
import { PrintReceipt } from './print-receipt.model';

export class PrintWaste extends PrintReceipt {
  fop: string;
  ipn: string;
  rnokpp: string;
  provider: string;
  employee: string;
  day: string;
  month: string;
  year: string;
  sum: string;
  costSum: string;
  saleSum: string;
  money: string;
  cent: string;
  summarySumRow: string;
  positionCount: number;
  positionCountRow: string;
  products: PrintProductItem[];

  salePriceMode: boolean;

  receipt(): string[] {
    const receipt: string[] = [];

    receipt.push(
      ...this.alignToLeft(
        `${WASTE_NUMBER_CAPTION} ${this.day}.${this.month}.${this.year}`,
      ),
    );

    this.addTwoColumnItemToReceipt(
      `${WASTE_SELF_NAME_CAPTION}: ${this.fop}`,
      this.rnokpp,
      receipt,
    );

    receipt.push(
      ...this.alignToLeft(`${WASTE_PROVIDER_NAME_CAPTION}: ${this.provider}`),
    );

    receipt.push(
      ...this.alignToLeft(`${WASTE_USER_NAME_CAPTION}: ${this.employee}`),
    );

    receipt.push(...this.alignToLeft(`${WASTE_POA_CAPTION} ${this.year}`));

    this.addWasteProductsToReceipt(receipt);

    receipt.push(this.rowDevider());

    if (this.salePriceMode) {
      receipt.push(`${this.prefix()}${WASTE_SALE_PRICE_MODE_NOTE}`);

      receipt.push(...this.alignToLeft(this.positionCountRow));

      this.addTwoColumnItemToReceipt(
        `${WASTE_TOTAL_COST_SUM_CAPTION}`,
        `${this.costSum} ${UAH}`,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `${WASTE_TOTAL_SALE_SUM_CAPTION}`,
        `${this.saleSum} ${UAH}`,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `${WASTE_TOTAL_VAT_CAPTION}`,
        `______.___ ${UAH}`,
        receipt,
      );

      this.addTwoColumnItemToReceipt(
        `${WASTE_TOTAL_SUM_WITH_VAT_CAPTION}`,
        `______.___ ${UAH}`,
        receipt,
      );
    } else {
      this.addTwoColumnItemToReceipt(
        `${WASTE_TOTAL_SUM_CAPTION}`,
        `${this.sum} ${UAH}`,
        receipt,
      );

      receipt.push(`${this.prefix()}`);
      receipt.push(`${this.prefix()}${WASTE_TOTAL_VAT_CAPTION}`);

      this.addTwoColumnItemToReceipt(
        `${WASTE_TOTAL_SUM_WITH_VAT_CAPTION}`,
        `______.___ ${UAH}`,
        receipt,
      );

      receipt.push(`${this.prefix()}`);
      receipt.push(
        ...this.alignToLeft(
          `${WASTE_POSITION_COUNT_1_CAPTION} ${this.positionCount} ${WASTE_POSITION_COUNT_2_CAPTION}`,
        ),
      );

      receipt.push(...this.alignToLeft(this.summarySumRow));
    }

    receipt.push(`${this.prefix()}`);

    this.addTwoColumnItemToReceipt(
      `${WASTE_DIRECTOR_SIGN_CAPTION} ${''.padEnd(
        (this.rowLength - 2) / 2 - WASTE_DIRECTOR_SIGN_CAPTION.length - 1,
        '_',
      )}`,
      `${WASTE_ACCOUNTANT_SIGN_CAPTION} ${''.padEnd(
        (this.rowLength - 2) / 2 - WASTE_ACCOUNTANT_SIGN_CAPTION.length - 1,
        '_',
      )}`,
      receipt,
    );

    receipt.push(`${this.prefix()}`);

    this.addTwoColumnItemToReceipt(
      `${WASTE_SHOP_SIGN_CAPTION} ${''.padEnd(
        (this.rowLength - 2) / 2 - WASTE_SHOP_SIGN_CAPTION.length - 1,
        '_',
      )}`,
      `${WASTE_PROVIDER_SIGN_CAPTION} ${''.padEnd(
        (this.rowLength - 2) / 2 - WASTE_PROVIDER_SIGN_CAPTION.length - 1,
        '_',
      )}`,
      receipt,
    );

    return receipt;
  }

  private addWasteProductsToReceipt(receipt: string[]): void {
    const indexLength = 3;
    const quantityLength = 6;
    const priceLength = 8;
    const sumLength = 8;

    const numbersLength = quantityLength + priceLength + sumLength;
    const nameLength = this.rowLength - numbersLength - indexLength;
    const headerIndexRow1 = '#'.padEnd(indexLength, ' ');
    const headerIndexRow2 = ''.padEnd(indexLength, ' ');
    const headerNameRow1 = WASTE_HEADER_NAME_CAPTION.padEnd(nameLength, ' ');
    const headerNameRow2 = ''.padEnd(nameLength, ' ');

    const headerQuantityRow1 = WASTE_HEADER_QUANTITY_CAPTION.padStart(
      quantityLength,
      ' ',
    );

    const headerQuantityRow2 = ''.padStart(quantityLength, ' ');

    const headerPriceRow1 = `${WASTE_HEADER_PRICE_CAPTION}${
      this.salePriceMode ? '*' : ''
    }`.padStart(priceLength, ' ');

    const headerPriceRow2 = WASTE_HEADER_NO_VAT_CAPTION.padStart(
      priceLength,
      ' ',
    );

    const headerSumRow1 = `${WASTE_HEADER_SUM_CAPTION}${
      this.salePriceMode ? '*' : ''
    }`.padStart(sumLength, ' ');

    const headerSumRow2 = WASTE_HEADER_NO_VAT_CAPTION.padStart(sumLength, ' ');

    receipt.push(this.rowDevider());

    receipt.push(
      `${this.prefix()}${headerIndexRow1}${headerNameRow1}${headerQuantityRow1}${headerPriceRow1}${headerSumRow1}`,
    );

    receipt.push(
      `${this.prefix()}${headerIndexRow2}${headerNameRow2}${headerQuantityRow2}${headerPriceRow2}${headerSumRow2}`,
    );

    receipt.push(this.rowDevider());

    this.products.forEach((product, index) => {
      const indexValue = `${(index + 1).toString()}`;
      const nameValue = `${product.name}, ${product.type}`;
      const numbersValue = this.salePriceMode
        ? `${product.quantity.padStart(quantityLength, ' ')}${(
            product.costPrice ?? ''
          ).padStart(priceLength, ' ')}${(product.costSum ?? '').padStart(
            sumLength,
            ' ',
          )}${''.padStart(quantityLength, ' ')}${(
            product.salePrice ?? ''
          ).padStart(priceLength, ' ')}${(product.saleSum ?? '').padStart(
            sumLength,
            ' ',
          )}`
        : `${product.quantity.padStart(
            quantityLength,
            ' ',
          )}${product.price.padStart(priceLength, ' ')}${product.sum.padStart(
            sumLength,
            ' ',
          )}`;

      this.formatReceiptTableRow(
        receipt,
        indexValue,
        indexLength,
        nameValue,
        nameLength,
        numbersValue,
        numbersLength,
      );
    });
  }
}
