import { v4 as uuid } from 'uuid';

import { PRro } from '../../../p-rro/p-rro.model';
import { TransactionType } from '../transaction-type.enum';

export class Transaction {
  // Service fields
  type: TransactionType;
  amount: number;

  // Common fields
  id: number;
  createdAt: Date;
  comment: string;
  shopId: number;
  shiftId: number;
  userId: number;
  prroId: number;
  prro?: PRro;
  prroShiftId: number;
  prroLocalNumber: number;
  prroTaxNumber: string;
  returnId: number;
  uuid: string;

  // Incasation
  cashless: boolean;
  financeExpenseReturnId?: number;

  get typeName(): string {
    return this.type === TransactionType.Incasation
      ? 'інкасацію'
      : this.type === TransactionType.Deposit
      ? 'внесення коштів'
      : this.type === TransactionType.FinanceExpense
      ? 'витрату коштів'
      : '<Невідомий документ>';
  }

  constructor(type: TransactionType, amount: number) {
    this.uuid = uuid();
    this.type = type;
    this.amount = amount;
    this.createdAt = new Date();
    this.cashless = false;
  }
}
