import { Component, Input, OnInit } from '@angular/core';

import { PayFormName } from '../../../../p-rro/fsco/enums/pay-form-name.enum';
import {
  CASH_REMAIN_CAPTION,
  DEPOSIT_CAPTION,
  DISCOUNT_SUM_CAPTION,
  FINANCE_EXPENSES_CAPTION,
  INCASATION_CAPTION,
  MOVE_CAPTION,
  MOVE_IN_CAPTION,
  MOVE_STOCK_IN_CAPTION,
  REALIZ_SUM_CAPTION,
  REFILL_EXPENSE_CAPTION,
  RETURN_SUM_CAPTION,
  REVENUE_CAPTION,
  SHIFT_FINISH_CAPTION,
  SHIFT_START_CAPTION,
  SHIFT_TOTALS_REPORT_CAPTION,
  WASTE_CAPTION,
  WASTE_RETURN_RECEIVED_CAPTION,
} from '../../receipt/receipt.const';
import { PrintShift } from '../../receipt/types/print-shift.model';

@Component({
  selector: 'bk-view-shift-totals',
  templateUrl: './view-shift-totals.component.html',
  styleUrls: ['./view-shift-totals.component.scss'],
})
export class ViewShiftTotalsComponent implements OnInit {
  @Input() shift: PrintShift;

  reportName = SHIFT_TOTALS_REPORT_CAPTION;
  shiftStartCaption = SHIFT_START_CAPTION;
  shiftFinishCaption = SHIFT_FINISH_CAPTION;
  realizSumCaption = REALIZ_SUM_CAPTION;
  returnSumCaption = RETURN_SUM_CAPTION;
  cashPayment = PayFormName.Cash;
  cardPayment = PayFormName.Card;
  revenueCaption = REVENUE_CAPTION;
  discountSumCaption = DISCOUNT_SUM_CAPTION;
  depositCaption = DEPOSIT_CAPTION;
  incasationCaption = INCASATION_CAPTION;
  financeExpensesCaption = FINANCE_EXPENSES_CAPTION;
  refillExpenseCaption = REFILL_EXPENSE_CAPTION;
  wasteReturnReceivedCaption = WASTE_RETURN_RECEIVED_CAPTION;
  cashRemainCaption = CASH_REMAIN_CAPTION;
  wasteCaption = WASTE_CAPTION;
  moveCaption = MOVE_CAPTION;
  moveInCaption = MOVE_IN_CAPTION;
  moveStockInCaption = MOVE_STOCK_IN_CAPTION;

  constructor() {}

  ngOnInit(): void {
    //
  }
}
