/**
 * Назва форми оплати
 */
export enum PayFormName {
  /**
   * Готівка = 0
   */
  Cash = 'Готівка',

  /**
   * Банківська картка = 1
   */
  Card = 'Картка',

  /**
   * Попередня оплата = 2
   */
  Avans = 'Попередня оплата',

  /**
   * Кредит = 3
   */
  Credit = 'Кредит',

  /**
   * Погашення кредиту = 4
   */
  CreditOff = 'Погашення кредиту',

  /**
   * Відстрочення платежу = 5
   */
  InDebt = 'Відстрочення платежу',
}
