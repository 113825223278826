import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { ShiftGuard } from './core/guards/shift.guard';
import { StatisticsGuard } from './core/guards/statistics.guard';
import { UnauthGuard } from './core/guards/unauth.guard';
import { ProductsComponent } from './shop/products/products.component';
import { ShopComponent } from './shop/shop.component';
import { SubcategoriesComponent } from './shop/subcategories/subcategories.component';
import { WeightProductComponent } from './shop/weight-product/weight-product';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'shop',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canLoad: [UnauthGuard],
  },
  {
    path: 'shop',
    component: ShopComponent,
    canLoad: [AuthGuard],
  },
  {
    path: 'subcategories',
    component: SubcategoriesComponent,
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'products',
    component: ProductsComponent,
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'weight-product',
    component: WeightProductComponent,
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'statistics',
    loadChildren: () =>
      import('./statistics/statistics.module').then((m) => m.StatisticsModule),
    canLoad: [AuthGuard, ShiftGuard, StatisticsGuard],
  },
  {
    path: 'transactions',
    loadChildren: () =>
      import('./transactions/transactions.module').then(
        (m) => m.TransactionsModule,
      ),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'sales',
    loadChildren: () =>
      import('./sales/sales.module').then((m) => m.SalesModule),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'presales',
    loadChildren: () =>
      import('./presales/presales.module').then((m) => m.PresalesModule),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./clients/clients.module').then((m) => m.ClientsModule),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'revision',
    loadChildren: () =>
      import('./revision/revision.module').then((m) => m.RevisionModule),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'supplies',
    loadChildren: () =>
      import('./warehouse/supplies/supplies.module').then(
        (m) => m.SuppliesModule,
      ),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'refills',
    loadChildren: () =>
      import('./warehouse/invoices/refill/refills.module').then(
        (m) => m.RefillsModule,
      ),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'returns',
    loadChildren: () =>
      import('./warehouse/invoices/waste/wastes.module').then(
        (m) => m.WastesModule,
      ),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'wastes',
    loadChildren: () =>
      import('./warehouse/invoices/waste/wastes.module').then(
        (m) => m.WastesModule,
      ),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'moves',
    loadChildren: () =>
      import('./warehouse/invoices/move/moves.module').then(
        (m) => m.MovesModule,
      ),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./warehouse/invoices/order/orders.module').then(
        (m) => m.OrdersModule,
      ),
    canLoad: [AuthGuard, ShiftGuard],
  },
  {
    path: 'p-rro',
    loadChildren: () =>
      import('./p-rro/p-rro.module').then((m) => m.PRroModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'client-screen',
    loadChildren: () =>
      import('./client-screen/client-screen.module').then(
        (m) => m.ClientScreenModule,
      ),
    canLoad: [AuthGuard],
  },
  {
    path: '**',
    component: ShopComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
