import { IWebIntentResultInfo } from '../../../../core/interfaces/web-intent-result-info.interface';

const TAPXPHONE_TRUSTED_DEVICES_CRITERIA = `Cтан пристрою не задовольняє вимоги безпеки (https://docs.tapxphone.com/v/en/app-guide/trusted-devices).`;
const CONTACT_CASHBOX_SUPPORT = `Зверніться до підтримки cashbox для уточнення`;
const INTERNAL_TAPXPHONE_APPLICATION_ERROR = `Внутрішня помилка платіжного додатку.<br>Повідомте підтримку cashbox про необхідність зв'язатися з постачальником послуг еквайрингу, який повинен звернутися до підтримки платіжного додатку`;

function settingsRecommendation(
  acquirerData: string,
  cashboxData: string,
): string {
  return `Детальну інформацію про ${acquirerData}, які підтримує платіжний додаток, надає постачальник послуг еквайрингу.<br>${CONTACT_CASHBOX_SUPPORT} списку ${cashboxData} обраного постачальника послуг еквайрингу`;
}

export function parseTapXphoneResult(
  code: string,
  serverError?: string,
): IWebIntentResultInfo {
  switch (code) {
    case '0':
      return {
        description: 'Трансакція успішна',
        recommendation: ``,
      };

    case '512':
      return {
        description: 'Платіжний додаток заблоковано',
        recommendation: `Платіжний додаток заблоковано з міркувань безпеки.<br>${TAPXPHONE_TRUSTED_DEVICES_CRITERIA}<br>Виправте невідповідності й зверніться до постачальника послуг еквайрингу з проханням про розблокування`,
      };

    case '900':
      return {
        description: 'Помилка виконання транзакції',
        recommendation: `Платіжна операція була перервана через помилку:<br><strong>${serverError}</strong>`,
      };

    case '903':
      return {
        description: 'Платіжний додаток має недійсний UID',
        recommendation: `Зверніться до підтримки cashbox для перевірки правильності UID`,
      };

    case '904':
      return {
        description:
          'Проблема комунікації між платіжним додатком та його сервером',
        recommendation: `Перевірте статус підключення до Інтернету`,
      };

    case '905':
      return {
        description: 'Недійсний тип платіжної транзакції в токені',
        recommendation: `${settingsRecommendation(
          'типи платіжних операцій',
          'типів платіжних операцій',
        )}`,
      };

    case '906':
      return {
        description: 'Платіжний додаток отримав неправильний мовний код',
        recommendation: `${settingsRecommendation('мови', 'доступних мов')}`,
      };

    case '907':
      return {
        description: 'Сума операції не відповідає токену сеансу',
        recommendation: `Повторіть спробу щоб оновити токен сеансу (сума не повинна дорівнювати 0)`,
      };

    case '908':
      return {
        description: 'Виявлено накладання на вікно платіжного додатку',
        recommendation: `${TAPXPHONE_TRUSTED_DEVICES_CRITERIA}<br>Виправте порушення й повторіть спробу`,
      };

    case '909':
      return {
        description:
          'Платіжний додаток успішно налаштований і може використовуватися для оплати',
        recommendation: ``,
      };

    case '910':
      return {
        description: 'Потрібне налаштування платіжного додатку',
        recommendation: `Перед початком платіжної транзакції потрібно налаштувати мобільний пристрій.<br>Перейдіть у розділ "Налаштування" - "Платіжний термінал" та натисніть кнопку "Активувати"`,
      };

    case '911':
      return {
        description: 'Виявлено режим розробника',
        recommendation: `${TAPXPHONE_TRUSTED_DEVICES_CRITERIA}<br>Вимкніть у налаштуваннях пристрою режим розробника й повторіть спробу`,
      };

    case '912':
      return {
        description: 'Виявлено розділений екран',
        recommendation: `${TAPXPHONE_TRUSTED_DEVICES_CRITERIA}<br>Вийдіть з режиму розділеного екрану й повторіть спробу`,
      };

    case '913':
      return {
        description: 'Помилка при формуванні запиту до сервера',
        recommendation: INTERNAL_TAPXPHONE_APPLICATION_ERROR,
      };

    case '914':
      return {
        description: 'Помилка шифрування',
        recommendation: INTERNAL_TAPXPHONE_APPLICATION_ERROR,
      };

    case '915':
      return {
        description: 'Помилка отримання відповіді від сервера',
        recommendation: INTERNAL_TAPXPHONE_APPLICATION_ERROR,
      };

    case '916':
      return {
        description: 'Помилка аналізу відповіді',
        recommendation: INTERNAL_TAPXPHONE_APPLICATION_ERROR,
      };

    case '917':
      return {
        description: 'Не вказана валюта операції',
        recommendation: `${settingsRecommendation(
          'валюти',
          'доступних валют',
        )}`,
      };

    case '918':
      return {
        description: 'Неправильні дані для операції',
        recommendation: `${CONTACT_CASHBOX_SUPPORT} наявності та значень параметрів, які включені у кореневий параметр "data", і які надсилаються в запиті на створення токену сеансу.<br>Також потрібно перевірити, чи токен містить цифри та латинські літери та має довжину від 32 до 64 символів`,
      };

    case '919':
      return {
        description: 'Помилка JNI',
        recommendation: INTERNAL_TAPXPHONE_APPLICATION_ERROR,
      };

    case '920':
      return {
        description: 'Відхилення транзакції під час обробки',
        recommendation: `Платіжну операцію не було завершено через помилку:<br><strong>${serverError}</strong>`,
      };

    case '921':
      return {
        description:
          'Транзакція скасовується після виконання протягом одного сеансу',
        recommendation: `Платіжну операцію не було завершено через відмову від введення підпису`,
      };

    case '922':
      return {
        description: 'Відсутність зовнішніх даних з токена',
        recommendation: `${CONTACT_CASHBOX_SUPPORT} значення параметра "data", який надсилається у запиті на створення токену сеансу`,
      };

    case '923':
      return {
        description: 'Необхідно скинути налаштування платіжного додатку',
        recommendation: `Перед початком платіжної транзакції потрібно налаштувати мобільний пристрій.<br>Перейдіть у розділ "Налаштування" - "Платіжний термінал" та натисніть кнопку "Активувати"`,
      };

    case '924':
      return {
        description: 'Неправильний стан пристрою',
        recommendation: INTERNAL_TAPXPHONE_APPLICATION_ERROR,
      };

    case '999':
      return {
        description: 'Статус платіжної операції невідомий',
        recommendation: `Платіжна операція перервана.<br>Буде виконана спроба скасування транзакції через API`,
      };

    default:
      return {
        description: `Невідомий статус: ${code}`,
        recommendation: `Повідомте про ситуацію адміністрацію cashbox`,
      };
  }
}
