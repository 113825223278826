export class SyncResult {
  success = false;
  warning = false;
  message = '';
  timeoutErrors = 0;
  syncedItems = 0;
  totalItems = 0;
  itemsName = '';

  constructor(itemsName?: string) {
    if (itemsName) {
      this.itemsName = itemsName;
    }
  }

  successSync(): void {
    this.success = true;
    this.syncedItems += 1;

    if (this.timeoutErrors > 0) {
      this.timeoutErrors -= 1;
    }
  }

  warningSync(): void {
    this.warning = true;
    this.timeoutErrors += 1;
  }
}
