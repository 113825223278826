import { Injectable } from '@angular/core';
import { ActionSheetButton, ActionSheetController } from '@ionic/angular';

import { TRANSACTIONS_Z_REPORT } from '../core/constants/events.const';
import { ShopPRroMode } from '../core/enum/shop-p-rro-mode.enum';
import { CachedDataService } from '../core/services/cached-data.service';
import { Events } from '../core/services/events.service';
import { LoadingService } from '../core/services/loading.service';
import { PRroZReport } from '../p-rro/fsco/models/p-rro-z-report.model';
import { PRroService } from '../p-rro/p-rro.service';
import { PRroResult } from '../p-rro/types/p-rro-result.interface';

import { FinanceExpensesService } from './finance-expanses.service';
import { IncasationsService } from './incasations.service';
import { FinanceExpense } from './transaction/models/finance-expense.model';
import { Incasation } from './transaction/models/incasation.model';
import { Transaction } from './transaction/models/transaction.model';
import { TransactionType } from './transaction/transaction-type.enum';

const LOADING_ID = 'transactions.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(
    private events: Events,
    private actionSheetCtrl: ActionSheetController,
    private cachedDataService: CachedDataService,
    private loadingService: LoadingService,
    private prroService: PRroService,
    private incasationsService: IncasationsService,
    private financeExpensesService: FinanceExpensesService,
  ) {}

  async create(
    doc: Transaction,
    options?: { needFiscalization: boolean },
  ): Promise<Transaction | null> {
    if (doc instanceof Incasation && doc.cashless) {
      return this.createDoc(doc, { needFiscalization: false });
    }

    const { zReport, needAsk } = await this.prroStatus();

    const needFiscalization =
      options?.needFiscalization != null
        ? options.needFiscalization
        : needAsk
        ? await this.needFiscalization(doc)
        : zReport != null;

    if (needFiscalization == null) {
      return null;
    }

    if (
      needFiscalization &&
      zReport != null &&
      doc.type !== TransactionType.Deposit
    ) {
      const confirmedFiscalRiskyOperation =
        await this.prroService.fiscalRiskyOperationDialog(doc.amount, zReport);

      if (!confirmedFiscalRiskyOperation) {
        return null;
      }
    }

    return this.createDoc(doc, { needFiscalization });
  }

  async delete(doc: Transaction): Promise<void> {
    await this.loadingService.presentCustomPreloader(LOADING_ID);

    if (this.isIncasation(doc)) {
      await this.incasationsService.delete(doc.id).toPromise();
    } else if (this.isFinanceExpense(doc)) {
      await this.financeExpensesService.delete(doc.id).toPromise();
    }

    await this.loadingService.dismiss(LOADING_ID);
  }

  async fiscalize(doc: Transaction): Promise<Transaction | null> {
    if (doc instanceof Incasation && doc.cashless) {
      return null;
    }

    const { zReport } = await this.prroStatus();

    if (zReport == null) {
      return null;
    }

    if (doc.type !== TransactionType.Deposit) {
      const confirmedFiscalRiskyOperation =
        await this.prroService.fiscalRiskyOperationDialog(doc.amount, zReport);

      if (!confirmedFiscalRiskyOperation) {
        return null;
      }
    }

    await this.loadingService.presentCustomPreloader(LOADING_ID);

    const prroResult = await this.fiscalizeDoc(doc);

    if (prroResult == null) {
      await this.loadingService.dismiss(LOADING_ID);
      return null;
    }

    if (this.isIncasation(doc)) {
      await this.incasationsService.updateOrSaveOffline(doc);

      await this.loadingService.dismiss(LOADING_ID);
      return doc;
    }

    if (this.isFinanceExpense(doc)) {
      await this.financeExpensesService.updateOrSaveOffline(doc);

      await this.loadingService.dismiss(LOADING_ID);
      return doc;
    }

    return null;
  }

  private async prroStatus(): Promise<{
    needAsk: boolean;
    zReport: PRroZReport | null;
  }> {
    const shop = this.cachedDataService.getShop();
    const isPRroActive = this.cachedDataService.isPRroActive();
    const zReport = isPRroActive ? await this.prroService.getZReport() : null;

    this.events.publish(TRANSACTIONS_Z_REPORT, zReport);

    return {
      zReport: zReport?.opened ? zReport : null,
      needAsk:
        (shop.prroMode === ShopPRroMode.UserMode || shop.prroManualControl) &&
        isPRroActive &&
        (zReport?.opened ?? false),
    };
  }

  private async needFiscalization(doc: Transaction): Promise<boolean | null> {
    const actionSheetButtons: ActionSheetButton[] = [
      {
        text: `Фіскалізувати ${doc.typeName}`,
        icon: 'qr-code-outline',
        cssClass: 'fiscal',
        handler: () => {
          actionSheet.dismiss(true);
        },
      },
      {
        text: 'Зберегти',
        icon: 'wallet-outline',
        cssClass: 'just-save',
        handler: () => {
          actionSheet.dismiss(false);
        },
      },
      {
        text: 'Скасувати',
        icon: 'close',
        role: 'cancel',
      },
    ];

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Варіанти збереження',
      cssClass: 'transactions-action-sheet-controller',
      buttons: actionSheetButtons,
    });

    await actionSheet.present();

    const { data: needFiscalization } = await actionSheet.onWillDismiss<
      boolean | undefined
    >();

    if (needFiscalization == null) {
      return null;
    }

    return needFiscalization;
  }

  private async createDoc(
    doc: Transaction,
    options: { needFiscalization: boolean } = { needFiscalization: true },
  ): Promise<Transaction | null> {
    await this.loadingService.presentCustomPreloader(LOADING_ID);

    if (options.needFiscalization) {
      await this.fiscalizeDoc(doc);
    }

    if (this.isIncasation(doc)) {
      const createdDoc = await this.incasationsService.createOrSaveOffline(doc);

      doc.id = createdDoc.id;

      await this.loadingService.dismiss(LOADING_ID);

      return doc;
    }

    if (this.isFinanceExpense(doc)) {
      const createdDoc = await this.financeExpensesService.createOrSaveOffline(
        doc,
      );

      doc.id = createdDoc.id;

      await this.loadingService.dismiss(LOADING_ID);
      return doc;
    }

    await this.loadingService.dismiss(LOADING_ID);
    return null;
  }

  private async fiscalizeDoc(doc: Transaction): Promise<PRroResult | null> {
    const prroResult = await this.prroService.fiscalizeServiceDoc(doc, {
      saveDoc: false,
    });

    if (prroResult != null) {
      doc.prroId = prroResult.prroId;
      doc.prroShiftId = prroResult.prroShiftId;
      doc.prroLocalNumber = prroResult.prroLocalNumber;
      doc.prroTaxNumber = prroResult.prroTaxNumber;
    }

    return prroResult;
  }

  private isIncasation(item: Transaction): item is Incasation {
    return item.type !== TransactionType.FinanceExpense;
  }

  private isFinanceExpense(item: Transaction): item is FinanceExpense {
    return item.type === TransactionType.FinanceExpense;
  }
}
