import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import * as serial from '@red-mobile/cordova-plugin-usb-serial/www/serial';
import round from 'lodash-es/round';
import {
  iif,
  of,
  throwError,
  BehaviorSubject,
  Observable,
  Subject,
} from 'rxjs';
import { concatMap, delay, retryWhen, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { WEIGHT_AMOUNT } from '../../core/constants/product.const';
import { Measurement } from '../../core/enum/measurement.enum';
import { WeightProductMeasurement } from '../../core/enum/weight-product-measurement.enum';
import { Shop } from '../../core/models/shop.model';
import { StorageTable } from '../../core/models/storage-table.model';
import { CachedDataService } from '../../core/services/cached-data.service';
import { DeprecatedScaleService } from '../../core/services/scale.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import { LogsService } from '../logs/logs.service';

import { ScalesRequest } from './request.interface';
import { ScalesResponse } from './response.interface';
import { ScalesConnectionMethod } from './scales-connection-method.enum';
import { ScalesModel } from './scales-model.enum';
import { ScalesSettings } from './scales-settings.interface';
import {
  DEFAULT_COM_PORT,
  DEFAULT_DATA,
  DEFAULT_DELAY,
  DEFAULT_WEBSOCKET_PORT,
  TOAST_ANDROID_TITLE,
  TOAST_DESKTOP_TITLE,
} from './scales.const';
import { Scales } from './scales.model';

const KEY_CONNECTION_METHOD = 'connectionMethod';
const KEY_MODEL_NAME = 'model';
const KEY_DELAY = 'delay';
const KEY_COM_PORT = 'comPort';
const KEY_WEBSOCKET_PORT = 'websocketPort';

const RECONNECT_INTERVAL = 5 * 1000; // 5 seconds
const MAX_RECONNECT_ATTEMPTS = 10;

const LOG_KEY = 'scales';

const METHOD_WEIGHT = 'Weight';
const METHOD_FINISH = 'Finish';

const NUL = 0;
const SOH = 1;
const STX = 2;
const ETX = 3;
const EOT = 4;
const ENQ = 5;
const ACK = 6;
const DC1 = 17;

const SCALES_STABLE = 'S';

const ANDROID_OPERATION_REQUEST_PERMISSION = 'requestPermission';
const ANDROID_OPERATION_OPEN = 'open';
const ANDROID_OPERATION_REGISTER_READ_CALLBACK = 'registerReadCallback';
const ANDROID_OPERATION_WRITE = 'write';
const ANDROID_OPERATION_CLOSE = 'close';

@Injectable({
  providedIn: 'root',
})
export class ScalesService {
  private scales: StorageTable;
  private webSocket: WebSocketSubject<ScalesRequest | ScalesResponse>;
  private isConnectedSubject: BehaviorSubject<boolean>;
  private scalesSubject: Subject<ScalesResponse> =
    new Subject<ScalesResponse>();

  private shop: Shop;
  private settings: ScalesSettings;

  private isScanWeight = false;
  private isDesktop = false;
  private isAndroidApp = false;

  private retryAttempt = 0;

  private response = DEFAULT_DATA;
  private casBuffer = '';

  constructor(
    private logsService: LogsService,
    private utilsService: UtilsService,
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
    private deprecatedScaleService: DeprecatedScaleService,
  ) {
    this.scales = new StorageTable('scales');
    this.isConnectedSubject = new BehaviorSubject<boolean>(false);

    this.shop = this.cachedDataService.getShop();
    this.isDesktop = this.utilsService.isDesktop();
    this.isAndroidApp = this.utilsService.isAndroidApp();

    this.getSettings().then((settings) => {
      this.settings = settings;
    });
  }

  async clearData(): Promise<void> {
    await this.scales.clear();
  }

  private async getValue<T>(key: string, defaultValue: T): Promise<T> {
    const value = await this.scales.get<T>(key);

    if (value == null) {
      await this.scales.set<T>(key, defaultValue);

      return defaultValue;
    }

    return value;
  }

  async getConnectionMethod(): Promise<ScalesConnectionMethod> {
    return this.getValue(KEY_CONNECTION_METHOD, ScalesConnectionMethod.None);
  }

  async getSettings(): Promise<ScalesSettings> {
    const connectionMethod = await this.getConnectionMethod();
    const modelName = await this.getValue(
      KEY_MODEL_NAME,
      this.isDesktop ? ScalesModel.CAS_DLL : ScalesModel.None,
    );

    const delayTime = await this.getValue(KEY_DELAY, DEFAULT_DELAY);
    const comPort = await this.getValue(KEY_COM_PORT, DEFAULT_COM_PORT);
    const websocketPort = await this.getValue(
      KEY_WEBSOCKET_PORT,
      DEFAULT_WEBSOCKET_PORT,
    );

    const scales = new Scales(modelName, '', {});

    return {
      connectionMethod,
      scales,
      websocketPort,
      comPort,
      delay: delayTime,
    };
  }

  async setSettings(data: ScalesSettings): Promise<void> {
    this.settings = data;

    await this.scales.set(KEY_CONNECTION_METHOD, data.connectionMethod);
    await this.scales.set(KEY_MODEL_NAME, data.scales.name);
    await this.scales.set(KEY_DELAY, data.delay);
    await this.scales.set(KEY_COM_PORT, data.comPort);
    await this.scales.set(KEY_WEBSOCKET_PORT, data.websocketPort);
  }

  init(log: boolean = false): void {
    if (!(this.isDesktop || this.isAndroidApp)) {
      return;
    }

    this.getSettings().then((settings) => {
      if (settings.connectionMethod === ScalesConnectionMethod.None) {
        return;
      }

      this.connect(settings);
    });
  }

  isScalesAvailable(amount: string): boolean {
    const isWeightProduct =
      amount === `${WEIGHT_AMOUNT} ${WeightProductMeasurement.Gram}` ||
      amount === `${Measurement.Kilogram}`;

    if (!isWeightProduct) {
      return false;
    }

    if (this.settings.connectionMethod === ScalesConnectionMethod.None) {
      return this.isDesktop && this.shop.scalePort > '';
    }

    return this.isDesktop || this.isAndroidApp;
  }

  convertWeight(weight: number): ScalesResponse {
    return { weight, isStable: true, isError: false, error: '' };
  }

  emptyData(): ScalesResponse {
    return DEFAULT_DATA;
  }

  liveData(): Observable<ScalesResponse> {
    return this.scalesSubject.asObservable();
  }

  data(): ScalesResponse {
    return this.response;
  }

  isConnected(): Observable<boolean> {
    return this.isConnectedSubject;
  }

  wait(ms?: number): Promise<void> {
    const time = ms ?? this.settings.delay ?? DEFAULT_DELAY;

    return new Promise((resolve) => setTimeout(resolve, time));
  }

  connect(settings: ScalesSettings): void {
    if (this.isDesktop) {
      this.disconnect();
      this.createWebSocketConnection(settings);

      this.retryAttempt = 0;

      this.webSocket
        .pipe(
          retryWhen((errors) =>
            errors.pipe(
              concatMap((error) =>
                iif(
                  () => this.retryAttempt >= MAX_RECONNECT_ATTEMPTS,
                  throwError(
                    'Перевищено ліміт спроб повторного підключення агента зважування',
                  ),
                  of(error).pipe(
                    tap(async () => {
                      await this.reconnectToWebSocketServer(this.retryAttempt);

                      this.retryAttempt += 1;
                    }),
                    delay(RECONNECT_INTERVAL),
                  ),
                ),
              ),
            ),
          ),
        )
        .subscribe(
          async (response: ScalesResponse) => {
            await this.handleDesktopResponse(response, settings);
          },
          (error) => {
            const errorMessage = `Помилка обробки відповіді: ${this.parseConnectionError(
              error,
            )}`;

            this.toastService.presentError(TOAST_DESKTOP_TITLE, errorMessage);
            this.log(
              `> Error [#timestamp] ${TOAST_DESKTOP_TITLE}: ${errorMessage}`,
            );
          },
          () => {
            const message = `З'єднання з агентом зважування завершене`;

            this.log(`> [#timestamp] ${message}`);
          },
        );
    } else if (this.isAndroidApp) {
      this.log(
        `> [#timestamp] isConnectedSubject: ${this.isConnectedSubject.getValue()}`,
      );

      serial.requestPermission(
        {},
        (result) => {
          this.log(`> [#timestamp] Запит прав доступу до USB вагів: ${result}`);
          this.connectOnAndroid(settings);
        },
        this.handleError({
          name: ANDROID_OPERATION_REQUEST_PERMISSION,
          caption: 'Запит прав доступу до USB вагів',
        }),
      );
    }
  }

  disconnect(): void {
    if (this.isDesktop) {
      if (this.webSocket != null && !this.webSocket.closed) {
        this.webSocket.complete();
      }
    } else if (this.isAndroidApp) {
      serial.close((result) => {
        this.isConnectedSubject.next(false);

        this.log(`> [#timestamp] Відключення від USB вагів: ${result}`);
      }, this.handleError({ name: ANDROID_OPERATION_CLOSE, caption: 'Відключення від USB вагів' }));
    }
  }

  startAgent(): void {
    this.toastService.present('Запуск агента зважування');

    this.getSettings().then((settings) => {
      window.location.assign(
        `cashbox.scales://launch?model=${settings.scales.name}&comport=${settings.comPort}&websocketport=${settings.websocketPort}`,
      );
    });
  }

  getWeight(): void {
    this.response = this.emptyData();

    if (this.isDesktop) {
      if (this.settings.connectionMethod !== ScalesConnectionMethod.None) {
        this.sendOnDesktop();

        this.isScanWeight = true;
      } else if (this.shop.scalePort > '') {
        this.loadWeight().then().catch();
      }
    } else if (this.isAndroidApp) {
      this.sendOnAndroid();

      this.isScanWeight = true;
    }
  }

  async finish(
    options: { waiting: boolean } = { waiting: false },
  ): Promise<void> {
    this.isScanWeight = false;

    if (this.settings.connectionMethod === ScalesConnectionMethod.None) {
      return;
    }

    if (this.isDesktop) {
      this.webSocket.next({ method: METHOD_FINISH });
    } else if (this.isAndroidApp) {
      //
    }

    if (options.waiting && this.isDesktop) {
      await this.wait(3 * DEFAULT_DELAY);
    }
  }

  //#region Desktop
  private createWebSocketConnection(settings: ScalesSettings): void {
    const url = `ws://127.0.0.1:${settings.websocketPort}/scales`;

    this.webSocket = webSocket({
      url,
      openObserver: {
        next: () => {
          this.successfullyConnected();
        },
      },
      closeObserver: {
        next: () => {
          this.successfullyDisconnected();
        },
      },
    });
  }

  private async reconnectToWebSocketServer(
    retryAttempt: number,
  ): Promise<void> {
    if (retryAttempt === 0) {
      this.startAgent();
    }

    this.isConnectedSubject.next(false);

    if (retryAttempt > 0) {
      const message = `Відновлення з'єднання (спроба ${retryAttempt}/${MAX_RECONNECT_ATTEMPTS})...`;

      this.toastService.presentWarning(TOAST_DESKTOP_TITLE, message);
      this.log(`> [#timestamp] ${TOAST_DESKTOP_TITLE}: ${message}`);
    }
  }

  private successfullyConnected(): void {
    this.isConnectedSubject.next(true);

    this.retryAttempt = 0;

    const message = 'Суміщення з агентом зважування активоване';

    this.toastService.present(message);
    this.log(`> [#timestamp] ${message}`);
  }

  private successfullyDisconnected(): void {
    this.isConnectedSubject.next(false);

    const message = 'Суміщення з агентом зважування завершене';

    this.toastService.present(message);
    this.log(`> [#timestamp] ${message}`);
  }

  private parseConnectionError(error: any): string {
    return typeof error === 'string' ? error : JSON.stringify(error);
  }

  private async handleDesktopResponse(
    data: ScalesResponse,
    settings: ScalesSettings,
  ): Promise<void> {
    if (data.isError) {
      this.response = DEFAULT_DATA;

      this.scalesSubject.next(DEFAULT_DATA);

      const message = `Помилка: ${data.error ?? ''}`;

      this.toastService.presentError(TOAST_DESKTOP_TITLE, message);
      this.log(`> [#timestamp] ${TOAST_DESKTOP_TITLE}: ${message}`);

      return;
    }

    this.refreshData(data);

    if (settings.connectionMethod === ScalesConnectionMethod.None) {
      return;
    }

    if (data.method === METHOD_FINISH) {
      this.isScanWeight = false;

      return;
    }

    if (data.method === METHOD_WEIGHT) {
      await this.wait(2 * DEFAULT_DELAY);

      if (this.isScanWeight) {
        this.sendOnDesktop();
      }
    }
  }

  private sendOnDesktop(): void {
    this.webSocket.next({ method: METHOD_WEIGHT });
  }

  private async loadWeight(): Promise<void> {
    this.isScanWeight = true;

    // Required to enter the loop
    this.response.isError = false;

    while (this.isScanWeight && !this.response.isError) {
      this.response = await this.deprecatedScaleService.getScaleData();

      this.scalesSubject.next(this.response);

      await this.wait(4 * DEFAULT_DELAY);
    }
  }

  //#region Android
  private connectOnAndroid(settings: ScalesSettings): void {
    serial.open(
      {
        baudRate:
          settings.scales.name === ScalesModel.PROM_PRYLAD_BTA_60_7
            ? 4800
            : 9600,
      },
      (result) => {
        this.log(`> [#timestamp] Підключення до USB вагів: ${result}`);

        serial.registerReadCallback(
          async (response: { registerReadCallback: boolean } | ArrayBuffer) => {
            if (response instanceof ArrayBuffer) {
              await this.handleAndroidResponse(
                new Uint8Array(response),
                settings,
              );

              return;
            }

            this.log(
              `> [#timestamp] Register Read Callback: ${JSON.stringify(
                response,
              )}`,
            );

            if (response.registerReadCallback) {
              this.isConnectedSubject.next(true);

              this.toastService.present(`USB ваги готові до роботи`);
            } else {
              this.toastService.presentWarning(
                TOAST_ANDROID_TITLE,
                `USB ваги підключено, але вони не відповідають.\nПерезапустіть програму`,
              );
            }
          },
          this.handleError({
            name: ANDROID_OPERATION_REGISTER_READ_CALLBACK,
            caption: 'Register Read Callback',
          }),
        );
      },
      this.handleError({
        name: ANDROID_OPERATION_OPEN,
        caption: 'Підключення від USB вагів',
      }),
    );
  }

  private async handleAndroidResponse(
    data: Uint8Array,
    settings: ScalesSettings,
  ): Promise<void> {
    if (data.length === 0) {
      this.scalesSubject.next(DEFAULT_DATA);

      return;
    }

    if (data[0] === ACK) {
      serial.write(
        String.fromCharCode(DC1),
        (e) => {},
        this.handleError({
          name: ANDROID_OPERATION_WRITE,
          caption: 'Write DC1',
        }),
      );

      return;
    }

    let scalesResponse = '';

    for (const item of data) {
      scalesResponse += String.fromCharCode(item);
    }

    this.log(`> [#timestamp] Scales Response: ${scalesResponse} `);

    if (settings.scales.name === ScalesModel.PROM_PRYLAD_BTA_60_7) {
      scalesResponse = this.promPryladParseResponse(scalesResponse);
    }

    this.casParseResponse(scalesResponse);

    if (settings.connectionMethod === ScalesConnectionMethod.None) {
      return;
    }

    await this.wait(2 * DEFAULT_DELAY);

    if (this.isScanWeight) {
      this.sendOnAndroid();
    }
  }

  private promPryladParseResponse(scaleResponse: string): string {
    let result = '';

    // У відповідь ваги передають:
    // М1, М2, М3, М4, М5, М6, Ц1, Ц2, Ц3, Ц4, Ц5, Ц6, С1, С2, С3, С4, С5, С6,
    // де М1 - М6 - маса, С1 - С6 та Ц1 - Ц6 - дорівнюють нулю (00Н).
    try {
      // Перетворення кожного символу в ASCII код
      for (let i = scaleResponse.length - 1; i >= 0; i -= 1) {
        result += scaleResponse.charCodeAt(i).toString();
      }

      // ..S 0.128kgz..
      const weight = parseInt(result.slice(-6), 10) || 0;

      // Форматування числа з роздільником '.'
      const formattedWeight = weight / 1000;

      result = `${SCALES_STABLE}${formattedWeight
        .toFixed(3)
        .padStart(7, ' ')}kg`;
    } catch (error) {
      result = '';
    }

    return result;
  }

  private casParseResponse(scalesResponse: string): void {
    if (scalesResponse[0] === String.fromCharCode(SOH)) {
      this.casBuffer = '';
    }

    this.casBuffer += scalesResponse;

    if (this.casBuffer.length < 15) {
      return;
    }

    if (
      this.casBuffer[0] === String.fromCharCode(SOH) &&
      this.casBuffer[1] === String.fromCharCode(STX) &&
      this.casBuffer[13] === String.fromCharCode(ETX) &&
      this.casBuffer[14] === String.fromCharCode(EOT)
    ) {
      const sign = this.casBuffer.substr(3, 1).trim();
      const weight = this.casBuffer.substr(4, 6).trim();

      this.refreshData({
        weight: round(Number(`${sign}${weight}`) * 1000),
        isStable: this.casBuffer[2] === SCALES_STABLE,
        isError: false,
        error: '',
      });
    }

    this.casBuffer = '';
  }

  private handleError(operation: {
    name: string;
    caption: string;
  }): (error: string) => void {
    'Write ENQ';
    return (error: string) => {
      if (
        [
          ANDROID_OPERATION_REGISTER_READ_CALLBACK,
          ANDROID_OPERATION_OPEN,
          ANDROID_OPERATION_REGISTER_READ_CALLBACK,
          ANDROID_OPERATION_CLOSE,
        ].includes(operation.name) ||
        error.includes('closed')
      ) {
        this.isConnectedSubject.next(false);
      }

      this.log(`> Error [#timestamp] ${operation.caption}: ${error}`);
      this.toastService.presentError(
        TOAST_ANDROID_TITLE,
        `${operation.caption}: ${error}`,
        20000,
      );
    };
  }

  private sendOnAndroid(): void {
    serial.write(
      this.settings.scales.name === ScalesModel.PROM_PRYLAD_BTA_60_7
        ? `${String.fromCharCode(NUL)}${String.fromCharCode(
            NUL,
          )}${String.fromCharCode(ETX)}`
        : this.settings.scales.name === ScalesModel.CAS_PB
        ? String.fromCharCode(DC1)
        : String.fromCharCode(ENQ),
      () => {},
      this.handleError({ name: ANDROID_OPERATION_WRITE, caption: 'Write ENQ' }),
    );
  }

  //#region Common
  private refreshData(data: ScalesResponse): void {
    this.response = data;

    this.scalesSubject.next(data);
  }

  private log(message: string): void {
    const value = message.replace(
      '[#timestamp]',
      `[${formatDate(new Date(), 'HH:mm:ss.sss', 'uk-UA')}]`,
    );

    // tslint:disable-next-line:no-console
    console.log(`Scales ${value}`);

    this.logsService.add(LOG_KEY, value).then().catch();
  }
}
