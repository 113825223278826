import { Transaction } from './transaction/models/transaction.model';

export class TransactionsGroup {
  date: string;
  transactions: Transaction[];
  sum: number;

  constructor(date: string) {
    this.date = date;
    this.transactions = [];
    this.sum = 0;
  }
}
