import { Component } from '@angular/core';

import { RECEIPT_PRINTER_REFRESH } from '../../../core/constants/events.const';
import { IStorageUserSettings } from '../../../core/interfaces/storage-user-setting.interface';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { Events } from '../../../core/services/events.service';
import { UtilsService } from '../../../core/services/utils.service';
import { PrinterConnectionMethod } from '../printer-connection-method.enum';
import { DEFAULT_PRINTER } from '../printer.const';

import {
  ANDROID_POS_TERMINALS,
  DEFAULT_SETTINGS,
  LINES_TO_CUT_2,
  LINES_TO_CUT_3,
  LINES_TO_CUT_4,
  LINES_TO_CUT_5,
  PAPER_WIDTH_58,
  PAPER_WIDTH_80,
  ROW_LENGTH_32,
  ROW_LENGTH_34,
  ROW_LENGTH_35,
  ROW_LENGTH_36,
  ROW_LENGTH_42,
  ROW_LENGTH_48,
} from './receipt-printer.const';
import { ReceiptPrinterService } from './receipt-printer.service';

@Component({
  selector: 'bk-receipt-printer',
  templateUrl: './receipt-printer.component.html',
  styleUrls: ['./receipt-printer.component.scss'],
})
export class ReceiptPrinterComponent {
  readonly printerConnectionMethod = PrinterConnectionMethod;

  userSettings: IStorageUserSettings;
  isAndroidApp: boolean;
  isDesktop: boolean;

  connectionMethods: PrinterConnectionMethod[] = [
    PrinterConnectionMethod.Browser,
  ];

  printers = [DEFAULT_PRINTER];
  paperWidths = [PAPER_WIDTH_58, PAPER_WIDTH_80];

  rowLengths = [
    ROW_LENGTH_32,
    ROW_LENGTH_34,
    ROW_LENGTH_35,
    ROW_LENGTH_36,
    ROW_LENGTH_42,
    ROW_LENGTH_48,
  ];

  linesToCutList = [
    LINES_TO_CUT_2,
    LINES_TO_CUT_3,
    LINES_TO_CUT_4,
    LINES_TO_CUT_5,
  ];

  androidPosTerminals = ANDROID_POS_TERMINALS;

  settings = DEFAULT_SETTINGS;
  androidPosTerminal = this.androidPosTerminals[0];

  additionOptions = false;

  constructor(
    private events: Events,
    private cachedDataService: CachedDataService,
    private utilsService: UtilsService,
    private receiptPrinterService: ReceiptPrinterService,
  ) {
    this.userSettings = this.cachedDataService.getUserSettings();
    this.isAndroidApp = this.utilsService.isAndroidApp();
    this.isDesktop = this.utilsService.isDesktop();

    if (this.isDesktop) {
      this.connectionMethods.push(...[PrinterConnectionMethod.QZTray]);
    } else if (this.isAndroidApp) {
      this.connectionMethods.push(
        ...[
          PrinterConnectionMethod.Bluetooth,
          PrinterConnectionMethod.USB,
          PrinterConnectionMethod.WiFi,
        ],
      );
    }
  }

  async ionViewWillEnter(): Promise<void> {
    this.settings = await this.receiptPrinterService.getSettings();

    if (this.settings.connectionMethod === PrinterConnectionMethod.None) {
      return;
    }

    this.androidPosTerminal =
      this.androidPosTerminals.find((p) => p.name === this.settings.model) ||
      this.androidPosTerminals[0];
  }

  async updateSettings(): Promise<void> {
    await this.setSettings();
    await this.updateSaleServiceOptions();
  }

  private async updateSaleServiceOptions(): Promise<void> {
    const status = await this.receiptPrinterService.status();

    this.events.publish(RECEIPT_PRINTER_REFRESH, {
      isAvailible: status.isPrinterAvailable,
      isAutoPrintMode: status.isAutoPrintAfterSale,
    });
  }

  async setSettings(): Promise<void> {
    await this.receiptPrinterService.setSettings(this.settings);
  }

  async setModel(): Promise<void> {
    this.settings.model = this.androidPosTerminal.name;
    this.settings.charsetName = this.androidPosTerminal.charsetName;
    this.settings.charsetId = this.androidPosTerminal.charsetId;
    this.settings.turnOffChinese = this.androidPosTerminal.turnOffChinese;

    await this.setSettings();
  }

  isPaperWidthAvailable(): boolean {
    return (
      this.settings.connectionMethod === PrinterConnectionMethod.Bluetooth ||
      this.settings.connectionMethod === PrinterConnectionMethod.USB ||
      this.settings.connectionMethod === PrinterConnectionMethod.WiFi
    );
  }

  isRowLengthAvailable(): boolean {
    return (
      this.settings.connectionMethod === PrinterConnectionMethod.QZTray ||
      this.settings.connectionMethod === PrinterConnectionMethod.Bluetooth ||
      this.settings.connectionMethod === PrinterConnectionMethod.USB ||
      this.settings.connectionMethod === PrinterConnectionMethod.WiFi
    );
  }

  isPrintShopLogoInReceiptAvailable(): boolean {
    return (
      this.settings.connectionMethod === PrinterConnectionMethod.QZTray ||
      (this.isDesktop &&
        this.settings.connectionMethod === PrinterConnectionMethod.Browser)
    );
  }

  isUseCashDrawerAvailable(): boolean {
    return this.isRowLengthAvailable();
  }
}
