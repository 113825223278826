import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';

import { Resource } from '../core/abstract/resource';
import { SYNC_TOAST_MESSAGE } from '../core/constants/error-messages.const';
import { RECEIPT_PRINTER_REFRESH } from '../core/constants/events.const';
import { ObjectLiteral } from '../core/interfaces/object-literal';
import { IToastOptions } from '../core/interfaces/toast-options.interface';
import { SyncResult } from '../core/models/sync-result.model';
import { CachedDataService } from '../core/services/cached-data.service';
import { Events } from '../core/services/events.service';
import { ToastService } from '../core/services/toast.service';
import { UtilsService } from '../core/services/utils.service';
import { ReceiptPrinterService } from '../settings/printer/receipt/receipt-printer.service';
import { INCASATIONS_STORAGE_KEY } from '../settings/settings.const';

import { Incasation } from './transaction/models/incasation.model';
import { TransactionType } from './transaction/transaction-type.enum';

@Injectable({
  providedIn: 'root',
})
export class IncasationsService extends Resource<Incasation> {
  private isPrintReceiptMode = false;
  private isPrintReceiptAutoPrintMode = false;

  constructor(
    protected http: HttpClient,
    private events: Events,
    private cachedDataService: CachedDataService,
    private utilsService: UtilsService,
    private toastService: ToastService,
    private receiptPrinterService: ReceiptPrinterService,
  ) {
    super(http, {
      path: '/incasations',
    });

    this.events.subscribe(
      RECEIPT_PRINTER_REFRESH,
      (status: { isAvailible: boolean; isAutoPrintMode: boolean }) => {
        this.isPrintReceiptMode = status.isAvailible;
        this.isPrintReceiptAutoPrintMode = status.isAutoPrintMode;
      },
    );

    this.refreshReceiptPrintStatus();
  }

  find(options: ObjectLiteral = {}): Observable<Incasation[]> {
    options = { ...options };

    return super.find(options).pipe(
      map((incasations) =>
        incasations.map((incasation) => this.transformFromBackend(incasation)),
      ),
      map((incasations) => orderBy(incasations, 'createdAt', 'desc')),
    );
  }

  async createOrSaveOffline(incasation: Incasation): Promise<Incasation> {
    incasation.shopId = this.cachedDataService.getShopId();
    incasation.shiftId = this.cachedDataService.getShift().id;
    incasation.userId = this.cachedDataService.getUser().id;

    const savedIncasation = this.transformToBackend(incasation);
    const isConnected = await this.utilsService.isOnline();

    if (!isConnected) {
      return this.saveOfflineIncasation(savedIncasation);
    }

    return super
      .create(savedIncasation)
      .pipe(
        tap(() => this.presentToast(savedIncasation)),
        catchError((error) =>
          this.saveOfflineIncasation(savedIncasation, error),
        ),
      )
      .toPromise()
      .finally(async () => {
        await this.print(incasation);
      });
  }

  async updateOrSaveOffline(incasation: Incasation): Promise<Incasation> {
    const savedIncasation = this.transformToBackend(incasation);
    const isConnected = await this.utilsService.isOnline();

    if (!isConnected) {
      return this.saveOfflineIncasation(savedIncasation);
    }

    return super
      .update(incasation.id, savedIncasation)
      .pipe(
        tap(() => this.presentToast(savedIncasation)),
        catchError((error) =>
          this.saveOfflineIncasation(savedIncasation, error),
        ),
      )
      .toPromise()
      .finally(async () => {
        await this.print(incasation);
      });
  }

  delete(id: number): Observable<Incasation> {
    return super.delete(id);
  }

  haveUnsyncedIncasations(): boolean {
    return this.getLocallySavedIncasations().length > 0;
  }

  async sync(): Promise<SyncResult> {
    const incasations = this.getLocallySavedIncasations();

    if (incasations == null || incasations.length === 0) {
      const emptyResult = new SyncResult();

      emptyResult.success = true;
      emptyResult.message = 'Немає даних для синхронізації каси';

      return emptyResult;
    }

    const syncResult = new SyncResult('внесень/інкасацій');
    const unSyncedItems: Incasation[] = [];
    const errors: string[] = [];

    syncResult.totalItems = incasations.length;

    for (const incasation of incasations) {
      if (syncResult.timeoutErrors < 5) {
        const request =
          incasation.id == null
            ? super.create(incasation)
            : super.update(incasation.id, incasation);

        await request
          .pipe(timeout(5000))
          .toPromise()
          .then((response) => {
            syncResult.successSync();
          })
          .catch((problem) => {
            syncResult.warningSync();
            unSyncedItems.push(incasation);

            this.utilsService.addSyncError(problem, errors);
          });
      } else {
        unSyncedItems.push(incasation);
      }
    }

    this.utilsService.setResultMessage(syncResult, errors);
    this.saveIncasationsLocally(unSyncedItems);

    return syncResult;
  }

  private async presentToast(
    incasation: Incasation,
    data: IToastOptions = {},
  ): Promise<void> {
    const prroReport =
      (incasation.prroTaxNumber ?? '') > '' ? ' з використанням ПРРО' : '';

    const type = incasation.deposit
      ? TransactionType.Deposit
      : TransactionType.Incasation;

    const toastHeader = this.getToastHeader(type, data, prroReport);
    const toastMessage = this.getToastMessage(type, data);

    if (data.isOffline || data.isError) {
      this.toastService.presentWarning(toastHeader, toastMessage, 7500);
    } else {
      this.toastService.present(toastHeader, toastMessage);
    }
  }

  private transformToBackend(incasation: Incasation): Incasation {
    const incasationClone = cloneDeep(incasation);

    if (incasationClone.money != null) {
      incasationClone.money = Number(incasationClone.money);
    }

    if (incasationClone.deposit != null) {
      incasationClone.deposit = Number(incasationClone.deposit);
    }

    return incasationClone;
  }

  private transformFromBackend(incasation: Incasation): Incasation {
    const incasationClone: Incasation = cloneDeep(incasation);

    incasationClone.createdAt = new Date(incasationClone.createdAt);

    return incasationClone;
  }

  private async saveOfflineIncasation(
    incasation: Incasation,
    error?: any,
  ): Promise<Incasation> {
    const parsedError = error ? this.utilsService.getParsedError(error) : null;

    this.saveLocally(incasation);

    this.presentToast(incasation, {
      isOffline: (parsedError?.statusCode ?? 0) === 0,
      isError: (parsedError?.statusCode ?? 0) >= 400,
    });

    return incasation;
  }

  private saveLocally(incasation: Incasation): void {
    const incasations = this.getLocallySavedIncasations() || [];

    incasations.push(incasation);

    this.saveIncasationsLocally(incasations);
  }

  private getLocallySavedIncasations(): Incasation[] {
    const incasations = localStorage.getItem(INCASATIONS_STORAGE_KEY);

    if (!incasations) {
      return [];
    }

    return JSON.parse(incasations);
  }

  private saveIncasationsLocally(incasations: Incasation[]): void {
    localStorage.setItem(INCASATIONS_STORAGE_KEY, JSON.stringify(incasations));
  }

  private getToastHeader(
    type: TransactionType,
    data: IToastOptions = {},
    prroReport: string,
  ): string {
    let offlineOperation = 'інкасація';
    let defaultOperation = 'Інкасовано';

    if (type === TransactionType.Deposit) {
      offlineOperation = 'внесення коштів';
      defaultOperation = 'Внесено кошти';
    }

    return data.isOffline
      ? `Оффлайн ${data.isUpdate ? 'оновлення' : offlineOperation}${prroReport}`
      : data.isError
      ? `Помилка ${
          data.isUpdate ? 'оновлення' : 'збереження'
        } даних на сервері${prroReport}`
      : `${data.isUpdate ? 'Оновлено' : defaultOperation}${prroReport}`;
  }

  private getToastMessage(
    type: TransactionType,
    data: IToastOptions = {},
  ): string | undefined {
    return data.isOffline
      ? 'Необхідна синхронізація'
      : data.isError
      ? `${
          type === TransactionType.Deposit ? 'Внесення' : 'Інкасацію'
        } збережено на пристрої! ${SYNC_TOAST_MESSAGE}`
      : undefined;
  }

  private refreshReceiptPrintStatus(): void {
    this.receiptPrinterService.status().then((status) => {
      this.isPrintReceiptMode = status.isPrinterAvailable;
      this.isPrintReceiptAutoPrintMode = status.isAutoPrintAfterSale;
    });
  }

  private async print(doc: Incasation): Promise<void> {
    if (this.isPrintReceiptMode) {
      if (!doc.cashless) {
        await this.receiptPrinterService.openCashDrawer();
      }

      if (this.isPrintReceiptAutoPrintMode && doc.prroTaxNumber != null) {
        // tslint:disable-next-line: no-commented-code
        // await this.receiptPrinterService.printTaxServiceDoc(doc);
      }
    }
  }
}
