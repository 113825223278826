import { Client } from '../../clients/client.model';
import { User } from '../../core/models/user.model';

import { PresaleProduct } from './presale-product.model';

export class Presale {
  id: number;
  shopId: number;
  userId: number;
  user?: User;
  createdAt: Date;
  completeBy: Date;
  status: number;
  cashless: boolean | null;
  clientId: number;
  client?: Client;
  cashSum: number;
  cardSum: number;
  providedCash: number;
  comment: string;
  uuid: string;

  presaleProducts: PresaleProduct[];

  // App fields
  costSum: number;
  discountSum: number;
  roundSum: number;
  paymentSum: number;
  changeSum: number;

  totalDiscount: number;
  totalFreeCups: number;
  totalBonus: number;

  productNames: string;

  constructor(isForBackend: boolean = false) {
    if (isForBackend) {
      return;
    }

    this.costSum = 0;
    this.discountSum = 0;
    this.roundSum = 0;
    this.paymentSum = 0;
    this.changeSum = 0;

    this.totalDiscount = 0;
    this.totalFreeCups = 0;
    this.totalBonus = 0;
  }
}
