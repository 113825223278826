import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { SaleReturnDialog } from './sale-return/sale-return.dialog';
import { SalesRoutingModule } from './sales-routing.module';
import { SalesComponent } from './sales.component';
import { SaleSearchDialog } from './search/sale-search.dialog';

@NgModule({
  imports: [SharedModule, SalesRoutingModule],
  declarations: [SalesComponent, SaleSearchDialog, SaleReturnDialog],
  providers: [DatePipe],
})
export class SalesModule {}
