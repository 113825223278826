import { PrivatbankJsonMethod } from '../enums/method.enum';

export class PrivatbankJsonBaseRequest {
  /**
   * Назва операції, що виконується
   */
  method: string;

  /**
   * Зарезервовано для багатопрохідних операцій
   * (в однопрохідних операціях завжди дорівнює "0")
   */
  step: number;

  /**
   * Масив додаткових параметрів для терміналу
   */
  params?: any;

  constructor(method: PrivatbankJsonMethod = PrivatbankJsonMethod.PingDevice) {
    this.method = method;
    this.step = 0;
  }

  private getNullTerminatedArray(): Buffer {
    // Для спрощення обміну даними байтові послідовності JSON
    // завершуються символами-термінаторами (делімітерами) 0x00
    return Buffer.concat([
      Buffer.from(this.toJSONString()),
      Buffer.from([0x00]),
    ]);
  }

  toJSONString(): string {
    return JSON.stringify(this);
  }

  toArray(): Buffer {
    if (this.method === PrivatbankJsonMethod.PingDevice) {
      return Buffer.concat([
        Buffer.from([0x00]),
        this.getNullTerminatedArray(),
      ]);
    }

    return this.getNullTerminatedArray();
  }
}
