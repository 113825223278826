import { TerminalConnectionProtocol } from './enums/terminal-connection-protocol.enum';

export class Terminal {
  name: string;
  protocol: TerminalConnectionProtocol;
  isAndroidSupport: boolean;
  isDesktopSupport: boolean;
  hasDownloadFiles: boolean;
  downloadPath: string;
  agentIpSettings: boolean;
  connectionMethodSettings: boolean;
  serviceRefundSettings: boolean;
  autoDebugSettings: boolean;
  deviceIdSettings: boolean;
  merchantIdSettings: boolean;
  emptySettingsLabel: string;
  pingOperation: boolean;
  identifyOperation: boolean;
  merchantListOperation: boolean;
  auditOperation: boolean;
  auditLabel: string;
  verifyOperation: boolean;
  verifyLabel: string;
  debugMode: boolean;
  emptyOperationsLabel: string;
  openAppLabel: string;
  activateButton: boolean;

  constructor(
    name: string,
    protocol: TerminalConnectionProtocol,
    settings: Partial<Terminal>,
  ) {
    this.name = name;
    this.protocol = protocol;
    this.isAndroidSupport = settings.isAndroidSupport ?? false;
    this.isDesktopSupport = settings.isDesktopSupport ?? false;
    this.hasDownloadFiles = settings.hasDownloadFiles ?? false;
    this.downloadPath = settings.downloadPath ?? '';
    this.agentIpSettings = settings.agentIpSettings ?? false;
    this.connectionMethodSettings = settings.connectionMethodSettings ?? false;
    this.serviceRefundSettings = settings.serviceRefundSettings ?? false;
    this.autoDebugSettings = settings.autoDebugSettings ?? false;
    this.deviceIdSettings = settings.deviceIdSettings ?? false;
    this.merchantIdSettings = settings.merchantIdSettings ?? false;
    this.emptySettingsLabel = settings.emptySettingsLabel ?? '';
    this.pingOperation = settings.pingOperation ?? false;
    this.identifyOperation = settings.identifyOperation ?? false;
    this.merchantListOperation = settings.merchantListOperation ?? false;
    this.auditOperation = settings.auditOperation ?? false;
    this.auditLabel = settings.auditLabel ?? 'X-звіт';
    this.verifyOperation = settings.verifyOperation ?? false;
    this.verifyLabel = settings.verifyLabel ?? 'Загальний звiт';
    this.debugMode = settings.debugMode ?? false;
    this.emptyOperationsLabel = settings.emptyOperationsLabel ?? '';
    this.openAppLabel = settings.openAppLabel ?? '';
    this.activateButton = settings.activateButton ?? false;
  }
}
