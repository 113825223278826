import {
  REFILL_HEADER_CAPTION,
  REFILL_NUMBER_CAPTION,
  REFILL_PROVIDER_NAME_CAPTION,
  REFILL_SHOP_NAME_CAPTION,
  REFILL_USER_NAME_CAPTION,
} from '../receipt.const';

import { PrintProductItem } from './print-product-item.model';
import { PrintReceipt } from './print-receipt.model';

export class PrintRefillSalePrices extends PrintReceipt {
  printTime: string;
  refillDate: string;
  providerName: string;
  shopName: string;
  userName: string;
  products: PrintProductItem[];

  receipt(): string[] {
    const receipt: string[] = [];

    receipt.push(
      ...this.alignToLeft(`${REFILL_NUMBER_CAPTION} ${this.refillDate}`),
    );

    receipt.push(this.rowDevider());

    receipt.push(
      ...this.alignToLeft(
        `${REFILL_PROVIDER_NAME_CAPTION}: ${this.providerName}`,
      ),
    );

    receipt.push(
      ...this.alignToLeft(`${REFILL_SHOP_NAME_CAPTION}: ${this.shopName}`),
    );

    receipt.push(
      ...this.alignToLeft(`${REFILL_USER_NAME_CAPTION}: ${this.userName}`),
    );

    receipt.push(this.rowDevider());

    receipt.push(...this.alignToLeft(`${REFILL_HEADER_CAPTION}:`));

    this.products.forEach((product, index) => {
      receipt.push(...this.alignToLeft(`${product.name}, ${product.type}`));

      receipt.push(`${this.prefix()}${product.priceVector}`);
    });

    receipt.push(this.rowDevider());

    receipt.push(...this.alignToCenter(this.printTime));

    return receipt;
  }
}
