import { CryptDataURLFile } from './crypt-data-url-file.interface';

export class CryptData {
  key: CryptDataURLFile;
  cert: CryptDataURLFile;
  isJks: boolean;
  role: string;
  password: string;

  constructor() {
    this.key = { name: '', dataURL: '' };
    this.cert = { name: '', dataURL: '' };
    this.isJks = true;
    this.role = '';
    this.password = '';
  }

  isCorrect(): boolean {
    return (
      (this.key.dataURL ?? '') > '' &&
      (this.password ?? '') > '' &&
      (this.isJks || (this.cert.dataURL ?? '') > '')
    );
  }
}
