import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import { WebSocketSubject } from 'rxjs/webSocket';

import {
  INVOICE_AUTO_SALE,
  SYNC_PRODUCTS_SUCCESS,
} from '../../../core/constants/events.const';
import { AutoSaleOptions } from '../../../core/interfaces/auto-sale-options.interface';
import { Events } from '../../../core/services/events.service';
import { UtilsService } from '../../../core/services/utils.service';
import { SaleProduct } from '../../../sales/sale/sale-product.model';
import { Product } from '../../../shop/products/product.model';
import { ProductsService } from '../../../shop/products/products.service';
import { IntegrationSettings } from '../integration-settings.interface';

import { EleksDocItem } from './doc-item.interface';
import { EleksDoc } from './doc.interface';
import { EleksPayForm } from './pay-form.enum';
import { EleksRequest } from './request.interface';
import { EleksResponse } from './response.interface';
import { EleksSelectDialog } from './select-dialog/eleks-select.dialog';

const METHOD_FIND = 'Find';
const METHOD_FINISH = 'Finish';

@Injectable({
  providedIn: 'root',
})
export class EleksService {
  private isDialogInProgress = false;

  private products: Product[];

  constructor(
    private events: Events,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private utilsService: UtilsService,
    private productsService: ProductsService,
  ) {
    this.events.subscribe(SYNC_PRODUCTS_SUCCESS, () => {
      this.loadProducts();
    });
  }

  loadProducts(): void {
    this.productsService
      .findForApp({}, { useIndexedDb: true })
      .subscribe((products) => (this.products = products));
  }

  checkNewDocs(webSocket: WebSocketSubject<EleksRequest>): void {
    if (this.isDialogInProgress) {
      return;
    }

    webSocket.next({ method: METHOD_FIND });
  }

  async handleResponse(
    response: EleksResponse,
    webSocket: WebSocketSubject<EleksRequest>,
    settings: IntegrationSettings,
  ): Promise<void> {
    if (response.method === METHOD_FIND) {
      this.isDialogInProgress = true;

      if (response.docs?.length === 1) {
        await this.handleDoc(response.docs[0], webSocket, settings);
      } else {
        const modal = await this.modalCtrl.create({
          component: EleksSelectDialog,
          componentProps: {
            docs: response.docs,
          },
          backdropDismiss: false,
        });

        await modal.present();

        const { data } = await modal.onWillDismiss();

        if (data.item != null) {
          await this.handleDoc(data.item, webSocket, settings);
        }
      }

      this.isDialogInProgress = false;
    }
  }

  private async handleDoc(
    doc: EleksDoc,
    webSocket: WebSocketSubject<EleksRequest>,
    settings: IntegrationSettings,
  ): Promise<void> {
    if (doc.isReturn) {
      await this.wrongDocAlert(doc);

      this.markProcessed(doc, webSocket);

      return;
    }

    const saleProducts: SaleProduct[] = [];
    const missed: string[] = [];

    for (const item of doc.items) {
      const products =
        this.products?.filter((p) => p.name.startsWith(item.name.trim())) ?? [];

      switch (products.length) {
        case 0:
          const defaultProduct = this.products.find(
            (p) => p.id === settings.eleksDefaultProductId,
          );

          if (
            settings.eleksDefaultProductId != null &&
            defaultProduct != null
          ) {
            const saleProduct = this.createSaleProduct(defaultProduct, item);

            saleProduct.product.name = item.name;
            saleProduct.productName = item.name;

            saleProducts.push(saleProduct);
          } else {
            missed.push(
              `${item.name}, ${item.quantity} x ${formatCurrency(
                item.price,
                'uk_UA',
                '₴',
              )}`,
            );
          }

          break;

        case 1:
          saleProducts.push(this.createSaleProduct(products[0], item));
          break;

        default:
          if (settings.eleksSelectFirstByPrice) {
            saleProducts.push(this.createSaleProduct(products[0], item));
            break;
          }

          await this.selectProductAndAddToSale(item, saleProducts);
          break;
      }
    }

    if (missed.length > 0) {
      await this.missedItemsAlert(missed);
    }

    this.events.publish(INVOICE_AUTO_SALE, {
      saleProducts,
      active: settings.eleksAutoSale && doc.payments.length === 1,
      isCard: doc.payments[0].method === EleksPayForm.Card,
    } as AutoSaleOptions);

    this.markProcessed(doc, webSocket);
  }

  private markProcessed(
    doc: EleksDoc,
    webSocket: WebSocketSubject<EleksRequest>,
  ): void {
    webSocket.next({ method: METHOD_FINISH, filename: doc.filename });
  }

  private async selectProductAndAddToSale(
    item: EleksDocItem,
    saleProducts: SaleProduct[],
  ): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: EleksSelectDialog,
      componentProps: {
        docItem: item,
      },
      backdropDismiss: false,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.item != null) {
      saleProducts.push(this.createSaleProduct(data.item, item));
    }
  }

  private createSaleProduct(product: Product, item: EleksDocItem): SaleProduct {
    const localProduct = cloneDeep(product);

    localProduct.price = item.price;

    return this.utilsService.getStartSaleProduct(localProduct, item.quantity);
  }

  private async wrongDocAlert(doc: EleksDoc): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Неправильний чек',
      message: `Чек "${doc.comment}, ${doc.cashier}" на суму ${formatCurrency(
        doc.amount,
        'uk_UA',
        '₴',
      )} є чеком повернення й не може бути обробленим`,
      buttons: [
        {
          text: 'Закрити',
          role: 'cancel',
          cssClass: 'tertiary',
        },
      ],
      cssClass: 'missed-data-alert',
      backdropDismiss: false,
    });

    await alert.present();
  }

  private async missedItemsAlert(missed: string[]): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Невідомі найменування',
      message: `${missed.join('<br>')}`,
      buttons: [
        {
          text: 'Закрити',
          role: 'cancel',
          cssClass: 'tertiary',
        },
      ],
      cssClass: 'missed-data-alert',
      backdropDismiss: false,
    });

    await alert.present();
  }
}
