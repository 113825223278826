import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { DateTime } from 'luxon';
import { timeout } from 'rxjs/operators';

import {
  TERMINAL,
  TERMINAL_SERVICE_MESSAGE,
} from '../../../../core/constants/events.const';
import { CachedDataService } from '../../../../core/services/cached-data.service';
import { Events } from '../../../../core/services/events.service';
import { ToastService } from '../../../../core/services/toast.service';
import { UtilsService } from '../../../../core/services/utils.service';
import { LogsService } from '../../../logs/logs.service';
import { PrivatbankJsonMethod } from '../../privatbank/json/enums/method.enum';
import {
  DEFAULT_REQUEST_CODE,
  DEFAULT_TIMEOUT,
  TOAST_TITLE,
  TRANSACTION_TOKEN_ERROR,
} from '../../terminals.const';
import { PrivatbankJsonTxnType } from '../json/enums/txn-type.enum';

import { IPrivatbankNfcposPayCheckResponse } from './interfaces/check/pay-response.interface';
import { IPrivatbankNfcposRefundCheckResponse } from './interfaces/check/refund-response.interface';
import { IPrivatbankNfcposCheckRequest } from './interfaces/check/request.interface';
import { IPrivatbankNfcposIntentExtraResult } from './interfaces/intent/extra-result.interface';
import { IPrivatbankNfcposIntentExtra } from './interfaces/intent/extra.interface';
import { IPrivatbankNfcposPayTokenRequest } from './interfaces/token/pay-request.interface';
import { IPrivatbankNfcposRefundTokenRequest } from './interfaces/token/refund-request.interface';
import { IPrivatbankNfcposTokenResponse } from './interfaces/token/response.interface';

const LOG_KEY = 'PrivatBank';
const URL = '/privatbank-nfcpos';
const DEEP_LINK_URL = 'nfcterminal://executor';
const PACKAGE_NAME = 'ua.privatbank.pterminal';
const ACTIVITY = `presentation.ui.MainActivity`;

@Injectable({
  providedIn: 'root',
})
export class PrivatbankNfcposService {
  private transactionToken = '';

  constructor(
    protected http: HttpClient,
    private events: Events,
    private utilsService: UtilsService,
    private logsService: LogsService,
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
    private webIntent: WebIntent,
  ) {}

  private async log(message: string): Promise<void> {
    await this.logsService.add(LOG_KEY, message, { addTimestamp: true });
  }

  async transaction(amount: number, rrn: string = ''): Promise<void> {
    const tokenResponse = await this.getToken(amount, rrn);

    if (tokenResponse.jwt === '') {
      await this.log(TRANSACTION_TOKEN_ERROR);

      this.events.publish(TERMINAL_SERVICE_MESSAGE, {
        description: TRANSACTION_TOKEN_ERROR,
        error: true,
      });

      return;
    }

    const packageName = await this.utilsService.getPaymentPackageName(
      PACKAGE_NAME,
      LOG_KEY,
    );

    if (packageName === '') {
      return;
    }

    const options = {
      url: `${DEEP_LINK_URL}?token=${tokenResponse.jwt}`,
      action: this.webIntent.ACTION_VIEW,
      requestCode: DEFAULT_REQUEST_CODE,
    };

    const webIntentResult = await this.utilsService.startActivityForResult(
      options,
      LOG_KEY,
    );

    await this.log(
      `${rrn > '' ? 'Refund' : 'Purchase'} Intent Result:\n${JSON.stringify(
        webIntentResult,
      )}`,
    );

    if (webIntentResult?.extras == null) {
      return;
    }

    const paymentAppData =
      webIntentResult.extras as IPrivatbankNfcposIntentExtra;

    if (paymentAppData.result == null) {
      this.events.publish(TERMINAL_SERVICE_MESSAGE, {
        description: `Невідомий результат виконання транзакції: ${JSON.stringify(
          paymentAppData,
        )}.\nЗакрийте вікно оплати й повторіть спробу`,
        error: true,
      });

      return;
    }

    const paymentResult = JSON.parse(
      paymentAppData.result,
    ) as IPrivatbankNfcposIntentExtraResult;

    if (paymentResult.result) {
      this.getCheck(amount, rrn, paymentResult);
    } else {
      // Message for Merchant that transaction wasn't processed successful
      this.events.publish(TERMINAL_SERVICE_MESSAGE, {
        description: `${paymentResult?.error ?? ''}`,
        error: true,
      });
    }
  }

  async getCheck(
    amount: number,
    rrn: string,
    paymentResult?: IPrivatbankNfcposIntentExtraResult,
  ): Promise<void> {
    if (this.transactionToken === '') {
      this.toastService.presentWarning(
        TOAST_TITLE,
        'Невідомий токен транзакції\nНеможливо отримати дані чека',
      );

      return;
    }

    const checkResponse = await this.sendRequest<
      IPrivatbankNfcposPayCheckResponse | IPrivatbankNfcposRefundCheckResponse
    >('check', {
      method: 'post',
      data: { jwt: paymentResult?.token ?? this.transactionToken },
    });

    await this.log(`Check Response:\n${JSON.stringify(checkResponse)}`);

    if (checkResponse == null) {
      this.events.publish(`${TERMINAL}:${PrivatbankJsonMethod.Purchase}`, {
        method: PrivatbankJsonMethod.Purchase,
        step: 0,
        params: {
          amount,
          rrn,
          approvalCode: '',
          date: (paymentResult == null
            ? DateTime.now()
            : DateTime.fromFormat(paymentResult.date, 'yyyy-MM-dd HH:mm:ss')
          ).toFormat('dd.MM.yyyy'),
          invoiceNumber: '',
          issuerName: '',
          merchant: '',
          pan: '',
          responseCode: '0000',
          terminalId: '',
          time: (paymentResult == null
            ? DateTime.now()
            : DateTime.fromFormat(paymentResult.date, 'yyyy-MM-dd HH:mm:ss')
          ).toFormat('HH:mm:ss'),
          signVerif: '0',
          txnType:
            rrn > ''
              ? PrivatbankJsonTxnType.Refund
              : PrivatbankJsonTxnType.Purchase,
          bankAcquirer: 'ПриватБанк',
          paymentSystem: '',
          transactionId: paymentResult?.operation_id,
        },
        error: true,
        errorDescription: 'Не вдалося отримати дані чека',
      });

      this.utilsService.showErrorAlert({
        message: `
          [Check request] Відповідь на запит чека:<br>
          ${JSON.stringify(checkResponse, null, 2)}
        `,
      });

      return;
    }

    const operationDateTime = this.isRefund(checkResponse)
      ? DateTime.fromISO(
          checkResponse.refunds[checkResponse.refunds.length - 1].date,
        )
      : DateTime.fromFormat(checkResponse.pay.date, 'yyyyMMdd HH:mm:ss ZZZ');

    this.events.publish(`${TERMINAL}:${PrivatbankJsonMethod.Purchase}`, {
      method: PrivatbankJsonMethod.Purchase,
      step: 0,
      params: {
        amount: checkResponse.pay.amount_full,
        approvalCode: checkResponse.pay.approval_code,
        date: operationDateTime.toFormat('dd.MM.yyyy'),
        invoiceNumber: checkResponse.pay.stan,
        issuerName: checkResponse.pay.payment_system,
        merchant: checkResponse.pay.merchant,
        pan: checkResponse.pay.masked_pan,
        responseCode: '0000',
        rrn: checkResponse.pay.rrn,
        terminalId: checkResponse.pay.merchant,
        time: operationDateTime.toFormat('HH:mm:ss'),
        signVerif: '0',
        txnType:
          rrn > ''
            ? PrivatbankJsonTxnType.Refund
            : PrivatbankJsonTxnType.Purchase,
        bankAcquirer: 'ПриватБанк',
        paymentSystem: checkResponse.pay.payment_system,
        transactionId: checkResponse.pay.transaction_id,
      },
      error: false,
      errorDescription: '',
    });
  }

  async openApp(): Promise<void> {
    const packageName = await this.utilsService.getPaymentPackageName(
      PACKAGE_NAME,
      LOG_KEY,
    );

    if (packageName === '') {
      return;
    }

    const options = {
      component: {
        package: packageName,
        class: `${packageName}.${ACTIVITY}`,
      },
      requestCode: DEFAULT_REQUEST_CODE,
    };

    const webIntentResult = await this.utilsService.startActivityForResult(
      options,
      LOG_KEY,
    );

    await this.log(`Open Intent Result:\n${JSON.stringify(webIntentResult)}`);
  }

  private async getToken(
    amount: number,
    rrn: string,
  ): Promise<IPrivatbankNfcposTokenResponse> {
    this.transactionToken = '';

    const response = await this.sendRequest<IPrivatbankNfcposTokenResponse>(
      'token',
      {
        method: 'post',
        data:
          rrn > ''
            ? {
                amount,
                operation: 'refund',
                transaction_id: rrn,
              }
            : {
                amount,
                operation: 'pay',
                purpose: 'Оплата за товари та послуги',
              },
      },
    );

    await this.log(
      `Token Response:\n${response == null ? 'failed' : 'success'}`,
    );

    this.transactionToken = response?.jwt ?? '';

    return response ?? { success: false, status: 404, rid: '', jwt: '' };
  }

  private async sendRequest<T>(
    path: string,
    options: {
      method: string;
      data?:
        | IPrivatbankNfcposPayTokenRequest
        | IPrivatbankNfcposRefundTokenRequest
        | IPrivatbankNfcposCheckRequest;
    },
  ): Promise<T | null> {
    const shop = this.cachedDataService.getShop();

    return await this.http
      .request<T>(options.method, `${URL}/${path}`, {
        params: { shopId: shop.id.toString() },
        body: options.data,
        observe: 'response',
      })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .toPromise<HttpResponse<T>>()
      .then((response) => {
        return response.body;
      })
      .catch(async (problem) => {
        await this.log(`Error (${path}):\n${JSON.stringify(problem)}`);

        const parsedError = this.utilsService.getParsedError(problem);

        this.utilsService.showErrorToast(parsedError);

        return null;
      });
  }

  private isRefund(
    check:
      | IPrivatbankNfcposPayCheckResponse
      | IPrivatbankNfcposRefundCheckResponse,
  ): check is IPrivatbankNfcposRefundCheckResponse {
    return (
      (check as IPrivatbankNfcposRefundCheckResponse).refunds !== undefined
    );
  }
}
