import { EXCISE, VAT } from '../../p-rro/fsco/enums/tax.const';

export class SaleTax {
  type: number;
  name: string;
  letter: string;
  percent: number;
  totalAmount: number;
  totalDiscount: number;
  sourceAmount: number;
  taxAmount: number;

  constructor(tax: typeof VAT | typeof EXCISE) {
    this.type = tax.code;
    this.name = tax.name;
    this.totalAmount = 0;
    this.totalDiscount = 0;
    this.sourceAmount = 0;
    this.taxAmount = 0;
  }
}
