<ion-header>
  <ion-toolbar>
    <ion-title>Пошук чека для повернення</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="search" *ngIf="!saleGroups?.length">
    <div class="search__data">
      <ion-list lines="inset">
        <ion-item>
          <ion-segment color="dark" (ionChange)="changedParam($event)" [value]="param">
            <ion-segment-button [value]="TAB_FN">
              <ion-label>Фіскальний номер</ion-label>
            </ion-segment-button>

            <ion-segment-button [value]="TAB_ID">
              <ion-label>ID</ion-label>
            </ion-segment-button>

            <ion-segment-button [value]="TAB_DAY">
              <ion-label>День</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-item>

        <ion-item>
          <ion-input  type="text"
                      name="number"
                      [placeholder]="param == TAB_FN ? 'Фіскальний номер чека' : 'ID (#номер) чека'"
                      debounce="250"
                      [(ngModel)]="number"
                      [autofocus]="true"
                      [required]="true"
                      (ionFocus)="selectAll()"
                      (keyup.enter)="search()"
                      *ngIf="param !== TAB_DAY; else dayInput"></ion-input>

          <ng-template #dayInput>
            <ion-datetime #datetime
                          name="date-picker"
                          displayFormat="DD MMMM YYYY"
                          cancelText="Скасувати​"
                          doneText="Підтвердити"
                          placeholder="Дата чека"
                          [monthNames]="UA_MONTHS"
                          [(ngModel)]="saleDate"
                          [min]="minDate"
                          [max]="maxDate"
                          [required]="true"
                          (ionChange)="dateTimeChange()"
                          (ionCancel)="dateTimeCancel()"></ion-datetime>
          </ng-template>
        </ion-item>

        <ion-item *ngIf="error">
          <ion-text color="danger" class="search__error">
            <ion-label>{{ error }}</ion-label>
          </ion-text>
        </ion-item>
      </ion-list>
    </div>
  </section>

  <section class="sales" *ngIf="saleGroups?.length">
    <div *ngFor="let group of saleGroups">
      <div class="sales__group" (click)="toggleGroupSale(group)">
        <div class="sales__group__header">
          <span class="sales__group__header__date">{{ group.date }}:00:00 - {{ group.date }}:59:59</span>
          <ion-icon class="sales__group__header__toggle" [name]="group.collapsed ? 'chevron-forward-outline' : 'chevron-down-outline'"></ion-icon>
        </div>
      </div>

      <div class="table-header" *ngIf="!group.collapsed">
        <span class="column-time">Час</span>
        <span class="column-discount">Знижка, ₴</span>
        <span class="column-sum">Сума, ₴</span>
        <span class="column-tax">&nbsp;</span>
        <span class="column-details">&nbsp;</span>
      </div>

      <ion-list lines="none" class="table-data" *ngIf="!group.collapsed">
        <div class="even-row" *ngFor="let sale of group.sales; let i = index" >
          <ion-item-sliding #itemSliding *ngIf="i < group.visibleRows">
            <ion-item (click)="itemSliding.open('end')">
              <bk-sale [sale]="sale"></bk-sale>
            </ion-item>
            
            <ion-item-options side="end" (click)="itemSliding.close()">
              <ion-item-option color="danger" (click)="returnSale(sale, group)" *ngIf="!sale.returnSaleId">
                <ion-icon name="bag-remove-outline" size="large"></ion-icon>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </div>
      </ion-list>
    </div>
  </section>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="two-buttons" slot="start" color="tertiary" (click)="cancel()">Скасувати</ion-button>
    <ion-button class="two-buttons" slot="end" color="primary" (click)="search()" *ngIf="!saleGroups?.length">Підтвердити</ion-button>
  </ion-toolbar>
</ion-footer>