<section class="settings">
  <ion-item>
    <ion-icon slot="start" name="git-branch-outline" [color]="isConnected ? 'success' : 'dark'"></ion-icon>
    <ion-label>Спосіб підключення</ion-label>
    <ion-select id="connectionMethod" 
                name="connectionMethod" 
                okText="Вибрати" 
                cancelText="Скасувати"
                [(ngModel)]="settings.connectionMethod"
                (ngModelChange)="setConnectionMethod()">
      <ion-select-option *ngFor="let method of connectionMethods" [value]="method">
        {{ connectionMethodName(method) }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  
  <ion-item *ngIf="settings.connectionMethod === printerConnectionMethod.Bluetooth">
    <ion-icon slot="start" name="bluetooth-outline" color="tertiary"></ion-icon>
    <ion-label>Статус Bluetooth</ion-label>
    <ion-toggle slot="end" color="tertiary" name="isBluetoothEnabled" [ngModel]="isBluetoothEnabled" (ngModelChange)="changeBluetoothState()"></ion-toggle>
  </ion-item>
  
  <ion-item *ngIf="isPrinterAvailable()">
    <ion-icon slot="start" name="print-outline" [color]="isConnected ? 'success' : 'dark'"></ion-icon>
    <ion-label>Принтер</ion-label>
    <ion-select id="printer"
                name="printer"
                okText="Вибрати"
                cancelText="Скасувати"
                [ngClass]="{ 'required' : settings.printer.name === '' }"
                [interfaceOptions]="{ cssClass: 'printer-select-option' }"
                [(ngModel)]="settings.printer"
                (ngModelChange)="setPrinter()"
                (click)="loadIcons()">
      <ion-select-option *ngFor="let printerOption of printers" [value]="printerOption">
        {{ printerOption.title }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  
  <ion-item *ngIf="isAddressAvailable()">
    <ion-icon slot="start" name="wifi-outline"></ion-icon>
    <ion-label  position="stacked">Мережева (IP) адреса принтера</ion-label>
    <ion-input  #ipModel="ngModel"
                type="tel"
                name="ip"
                mask="IP"
                placeholder="0.0.0.0"
                maxlength="15"
                pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                [(ngModel)]="settings.ip"
                (ngModelChange)="setSettings()"
                required></ion-input>
  
    <ion-note class="error-form-note" *ngIf="ipModel.errors?.required">
      IP-адреса обов'язкова для заповнення
    </ion-note>
  
    <ion-note class="error-form-note" *ngIf="ipModel.errors?.pattern">
      Неправильний формат IP-адреси (0.0.0.0)
    </ion-note>
  </ion-item>
</section>
