import { Component, Input, OnChanges, ViewChild } from '@angular/core';

import {
  INVOICE_ADD_WEIGHT_PRODUCT,
  SYNC_PRODUCTS_SUCCESS,
} from '../../core/constants/events.const';
import { Events } from '../../core/services/events.service';
import { UtilsService } from '../../core/services/utils.service';
import { ScalesService } from '../../settings/scales/scales.service';
import { CategoriesComponent } from '../categories/categories.component';
import { Category } from '../categories/category.model';
import { Product } from '../products/product.model';
import { ProductsComponent } from '../products/products.component';
import { SubcategoriesComponent } from '../subcategories/subcategories.component';
import { SubcategoriesService } from '../subcategories/subcategories.service';
import { Subcategory } from '../subcategories/subcategory.model';
import { WeightProductComponent } from '../weight-product/weight-product';

@Component({
  selector: 'bk-landscape-menu',
  templateUrl: './landscape-menu.component.html',
})
export class LandscapeMenuComponent implements OnChanges {
  @Input() isShiftOpen: boolean;

  @ViewChild(CategoriesComponent) categories: CategoriesComponent;
  @ViewChild(SubcategoriesComponent) subcategories: SubcategoriesComponent;
  @ViewChild(ProductsComponent) products: ProductsComponent;
  @ViewChild(WeightProductComponent) weightProducts: WeightProductComponent;

  selectedCategory: Category;
  selectedSubcategory: Subcategory;
  selectedProduct: Product;

  subcategoryMenu: Subcategory[];
  subcategoriesActive: boolean;

  emptyCategory: boolean;
  productsActive: boolean;
  weightProductActive: boolean;

  constructor(
    private events: Events,
    private subcategoriesService: SubcategoriesService,
    private scalesService: ScalesService,
    private utilsService: UtilsService,
  ) {
    this.events.subscribe(SYNC_PRODUCTS_SUCCESS, () => {
      this.refreshMenu();
    });
  }

  ngOnChanges(): void {
    this.refreshMenu();
  }

  goToSubcategory(category: Category): void {
    this.subcategoriesService
      .findForApp({ categoryId: category.id })
      .subscribe((subcategories) => {
        if (subcategories.length === 1) {
          this.selectedCategory = category;
          this.selectedSubcategory = subcategories[0];

          this.subcategoriesActive = false;
          this.emptyCategory = true;
          this.productsActive = true;
          this.weightProductActive = false;
        } else {
          this.selectedCategory = category;
          this.subcategoryMenu = subcategories;

          this.subcategoriesActive = true;
          this.emptyCategory = false;
          this.productsActive = false;
          this.weightProductActive = false;
        }
      });
  }

  goToProducts(subcategory: Subcategory): void {
    this.selectedSubcategory = subcategory;

    this.subcategoriesActive = false;
    this.productsActive = true;
    this.weightProductActive = false;
  }

  async goToWeightProduct(product: Product): Promise<void> {
    this.selectedProduct = product;

    if (this.scalesService.isScalesAvailable(product.amount)) {
      this.scalesService.getWeight();

      await this.scalesService.wait();

      const scalesData = this.scalesService.data();

      if (!scalesData.isError && scalesData.isStable && scalesData.weight > 0) {
        this.scalesService.finish();

        const saleProduct = this.utilsService.getStartSaleProduct(product);

        this.utilsService.calcWeightProductData(saleProduct, scalesData.weight);
        this.events.publish(INVOICE_ADD_WEIGHT_PRODUCT, saleProduct);

        return;
      }

      await this.scalesService.finish({ waiting: true });
    }

    this.subcategoriesActive = false;
    this.productsActive = false;
    this.weightProductActive = true;
  }

  refreshMenu(): void {
    this.subcategoriesActive = false;
    this.emptyCategory = false;
    this.productsActive = false;
    this.weightProductActive = false;
  }

  goBackToSubcategories(): void {
    if (this.emptyCategory) {
      this.refreshMenu();
    } else {
      this.subcategoriesActive = true;
      this.productsActive = false;
      this.weightProductActive = false;
    }
  }

  goBackToProducts(): void {
    this.subcategoriesActive = false;
    this.productsActive = true;
    this.weightProductActive = false;
  }
}
