import { Drivers, Storage } from '@ionic/storage';

export class StorageTable {
  table: Storage | null = null;

  constructor(name: string) {
    this.table = new Storage({
      name: 'cashbox',
      storeName: name,
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    });

    this.table?.create().then().catch();
  }

  async clear(): Promise<void> {
    await this.table?.clear();
  }

  async remove(key: string): Promise<void> {
    await this.table?.remove(key);
  }

  async set<T>(key: string, value: T): Promise<void> {
    await this.table?.set(key, value);
  }

  async get<T>(key: string): Promise<T | null> {
    const value = await this.table?.get(key);

    return value != null ? value : null;
  }

  async keys(prefix?: string): Promise<string[]> {
    const keys = await this.table?.keys();

    if (keys != null && prefix != null) {
      return keys.filter((key) => key.startsWith(prefix));
    }

    return keys ?? [];
  }
}
