import { ScalesModel } from './scales-model.enum';

export class Scales {
  name: ScalesModel;
  title: string;
  isAndroidSupport: boolean;
  isDesktopSupport: boolean;

  constructor(name: ScalesModel, title: string, settings: Partial<Scales>) {
    this.name = name;
    this.title = title;
    this.isAndroidSupport = settings.isAndroidSupport ?? false;
    this.isDesktopSupport = settings.isDesktopSupport ?? false;
  }
}
