<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/shop"></ion-back-button>
    </ion-buttons>

    <ion-title>Залишки товарів</ion-title>

    <ion-buttons slot="end" *ngIf="user.createProduct">
      <ion-button (click)="addProduct()">
        <ion-icon name="add"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ingredients-filters" *ngIf="supplies && user?.ingredients">
    <ion-item>
      <ion-label>Категорія:</ion-label>
      <ion-select name="category"
                  interface="action-sheet"
                  cancelText="Скасувати"
                  [(ngModel)]="filterCategory"
                  (ngModelChange)="filter()">
        <ion-select-option *ngFor="let ingredientCategory of ingredientCategories"
                           [value]="ingredientCategory">
          {{ ingredientCategory.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-searchbar placeholder="Пошук" (ionInput)="search($event)"></ion-searchbar>
  </div>

  <section class="supplies" *ngIf="supplies && user?.ingredients; else loading">
    <div class="table-header">
      <div class="column-name">Товар</div>
      <div class="column-quantity">Залишок</div>
      <div class="column-measurement">Од.</div>
    </div>

    <ion-content>
      <ng-container *ngIf="supplies.length; else noData">
        <div class="even-row" *ngFor="let supply of supplies; let index = index">
          <div class="table-data-row" *ngIf="index < visibleRows">
            <div class="column-name">{{ supply.ingredient.name }}</div>
            <div class="column-quantity">{{ supply.quantity | number }}</div>
            <div class="column-measurement">{{ supply.ingredient.measurement }}</div>
          </div>
        </div>

        <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
          <ion-infinite-scroll-content loadingSpinner="circular" loadingText="Завантаження даних..."></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ng-container>

      <ng-template #noData>
        <div class="no-data">
          <span>Немає даних</span>
        </div>
      </ng-template>
    </ion-content>
  </section>

  <ng-template #loading>
    <div class="no-data">
      <span>
        <ion-spinner *ngIf="!supplies; else noAccess"></ion-spinner>

        <ng-template #noAccess>
          Обмежені права доступу!
        </ng-template>
      </span>
    </div>
  </ng-template>
</ion-content>
