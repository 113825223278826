import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';

import { IStorageUserSettings } from '../../../core/interfaces/storage-user-setting.interface';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { LoadingService } from '../../../core/services/loading.service';
import { Product } from '../product.model';

@Component({
  selector: 'bk-barcode-dialog',
  templateUrl: './barcode.dialog.html',
  styleUrls: ['./barcode.dialog.scss'],
})
export class BarcodeDialog implements OnInit {
  @ViewChildren('barcodeInput') barcodeInputs: QueryList<IonInput>;

  @Input() product: Product;
  @Input() isBluetoothEnabled = false;

  barcodes: string[] = [];

  userSettings: IStorageUserSettings;

  private canStartScan: boolean;

  constructor(
    private modalCtrl: ModalController,
    private loadingService: LoadingService,
    private cachedDataService: CachedDataService,
  ) {
    this.userSettings = this.cachedDataService.getUserSettings();
  }

  ngOnInit(): void {
    this.barcodes = this.product.barcode.split(';').filter((b) => b.length > 0);
  }

  handleScannerCode(barcode: string): void {
    if (this.canStartScan) {
      this.barcodes.push(barcode);
    }

    this.canStartScan = false;
  }

  selectAll(barcodeInput: IonInput): void {
    barcodeInput.getInputElement().then((data) => {
      data.select();
    });
  }

  add(): void {
    if (this.userSettings.keyboardMode) {
      this.barcodes.push('');

      setTimeout(() => {
        const barcodeInputsArray = this.barcodeInputs.toArray();

        barcodeInputsArray[this.barcodes.length - 1].setFocus();
      }, 250);
    } else {
      this.loadingService.presentScannerPreloader(
        'products.component:setBarcode',
      );

      this.canStartScan = true;
    }
  }

  update(event: any, index: number): void {
    this.barcodes[index] = event.target.value || '';
  }

  delete(index: number): void {
    this.barcodes.splice(index, 1);
  }

  async save(): Promise<void> {
    const barcode = [
      ...new Set(
        this.barcodes.map((b) => b.trim()).filter((b) => b.length > 0),
      ),
    ].join(';');

    this.product.barcode = `;${barcode};`;

    await this.modalCtrl.dismiss({ barcode, success: true });
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ success: false });
  }
}
