export enum Receipt {
  CashDrawer = -1,
  Sale = 0,
  Presale = 1,
  Waste = 2,
  Move = 3,
  RefillSalePrices = 4,
  TaxReport = 5,
  ShiftTotals = 6,
  TaxServiceDoc = 7,
  Refill = 8,
}
