import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ActionSheetButton,
  ActionSheetController,
  AlertController,
  NavController,
} from '@ionic/angular';

import { environment } from '../../../environments/environment';
import { IStoragePaymentTerminalData } from '../../core/interfaces/storage-payment-terminal-data.interface';
import { IStoragePaymentTerminalSettings } from '../../core/interfaces/storage-payment-terminal-settings.interface';
import { CachedDataService } from '../../core/services/cached-data.service';
import { UserService } from '../../core/services/resources/user.service';
import { ToastService } from '../../core/services/toast.service';
import { UtilsService } from '../../core/services/utils.service';
import {
  CONFIRM_DIALOG_ALERT_STYLE,
  TERMINAL_DEFAULT_AUTO_DEBUG,
  TERMINAL_DEFAULT_MERCHANT_ID,
  TERMINAL_DEFAULT_SERVICE_REFUND,
} from '../settings.const';

import { ShopsService } from './../../core/services/resources/shops.service';
import { TerminalConnectionMethod } from './enums/terminal-connection-method.enum';
import { TerminalConnectionProtocol } from './enums/terminal-connection-protocol.enum';
import { ITerminalConnectionMethod } from './interfaces/terminal-connection-method.interface';
import { TapXphoneService } from './tapxphone/tapxphone.service';
import { Terminal } from './terminal.model';
import {
  DEFAULT_AGENT_IP,
  KEY_AUTO_DEBUG,
  KEY_CONNECTION_METHOD,
  KEY_IP,
  KEY_MERCHANT_ID,
  KEY_SERVICE_REFUND,
  KEY_USB_PORT,
  TOAST_TITLE,
} from './terminals.const';
import { TerminalsService } from './terminals.service';

const TAPXPHONE_REQUIRES_DEVICE_SETUP_MODES = [1, 2, 7, 8];

@Component({
  selector: 'bk-terminals',
  templateUrl: './terminals.component.html',
  styleUrls: ['./terminals.component.scss'],
})
export class TerminalsComponent implements OnInit {
  readonly supportedTerminals: Terminal[] = [
    new Terminal('Немає', TerminalConnectionProtocol.None, {
      isAndroidSupport: true,
      isDesktopSupport: true,
    }),
    new Terminal('Приватбанк JSON', TerminalConnectionProtocol.PrivatbankJson, {
      isAndroidSupport: true,
      isDesktopSupport: true,
      hasDownloadFiles: true,
      downloadPath: 'PrivatBank',
      connectionMethodSettings: true,
      serviceRefundSettings: true,
      autoDebugSettings: true,
      merchantIdSettings: true,
      pingOperation: true,
      identifyOperation: true,
      merchantListOperation: true,
      auditOperation: true,
      verifyOperation: true,
      debugMode: true,
      activateButton: true,
    }),
    new Terminal(
      '[тест] cashbox | ПриватБанк',
      TerminalConnectionProtocol.CashboxPrivatbank,
      {
        isDesktopSupport: true,
        hasDownloadFiles: true,
        downloadPath: 'Terminal',
        agentIpSettings: true,
        serviceRefundSettings: true,
        merchantIdSettings: true,
        pingOperation: true,
        identifyOperation: true,
        merchantListOperation: true,
        auditOperation: true,
        verifyOperation: true,
        activateButton: true,
      },
    ),
    new Terminal(
      '[тест] cashbox | B-POS1',
      TerminalConnectionProtocol.CashboxBPOS,
      {
        isDesktopSupport: true,
        hasDownloadFiles: true,
        downloadPath: 'Terminal',
        agentIpSettings: true,
        merchantIdSettings: true,
        auditOperation: true,
        verifyOperation: true,
        activateButton: true,
      },
    ),
    new Terminal('tapXphone | monobank', TerminalConnectionProtocol.TapToMono, {
      isAndroidSupport: true,
      deviceIdSettings: true,
      auditOperation: true,
      auditLabel: 'Статус бізнес-дня',
      verifyOperation: true,
      verifyLabel: 'Закрити бізнес-день',
      activateButton: true,
    }),
    new Terminal(
      'tapXphone | ПриватБанк',
      TerminalConnectionProtocol.TapToPrivat,
      {
        isDesktopSupport: false,
        isAndroidSupport: true,
        emptySettingsLabel: 'Немає доступних налаштувань',
        openAppLabel: 'Запустити додаток Термінал',
      },
    ),
  ];

  readonly connectionMethods: ITerminalConnectionMethod[] = [
    {
      name: 'Мережа (LAN, Wi-Fi)',
      value: TerminalConnectionMethod.Ethernet,
    },
    { name: 'Кабель USB', value: TerminalConnectionMethod.USB },
  ];

  KEY_CONNECTION_METHOD = KEY_CONNECTION_METHOD;
  KEY_SERVICE_REFUND = KEY_SERVICE_REFUND;
  KEY_AUTO_DEBUG = KEY_AUTO_DEBUG;
  KEY_MERCHANT_ID = KEY_MERCHANT_ID;
  KEY_IP = KEY_IP;
  KEY_USB_PORT = KEY_USB_PORT;

  terminalConnectionProtocol = TerminalConnectionProtocol;
  terminalConnectionMethod = TerminalConnectionMethod;

  validationMessages = {
    ip: [
      { type: 'required', message: "ІР-адреса обов'язкова для підключення" },
      {
        type: 'pattern',
        message: 'Не правильна ІР-адреса, введіть у форматі: 0.0.0.0',
      },
    ],
    usbPort: [
      {
        type: 'required',
        message: "Номер порту USB обов'язковий для підключення",
      },
      { type: 'min', message: 'Номер порту USB не може бути меншим 1' },
      { type: 'max', message: 'Номер порту USB не може бути більшим 99' },
    ],
    merchantId: [
      {
        type: 'required',
        message:
          "Номер мерчанту обов'язковий для виконання операцій з терміналом",
      },
      { type: 'min', message: 'Номер мерчанту не може бути меншим 0' },
      { type: 'max', message: 'Номер мерчанту не може бути більшим 9' },
    ],
  };

  status = '';
  connected: boolean;
  isAndroidApp: boolean;
  isDesktop: boolean;

  terminalForm: FormGroup;
  settings: IStoragePaymentTerminalSettings;
  data: IStoragePaymentTerminalData = {
    acquirerName: '',
    deviceId: '',
    connectionProtocol: TerminalConnectionProtocol.None,
    agentIp: DEFAULT_AGENT_IP,
  };

  availableTerminals: Terminal[] = [];
  tapXphoneWarnings: string[] = [];

  terminal = this.supportedTerminals[0];

  constructor(
    private formBuilder: FormBuilder,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private toastService: ToastService,
    private utilsService: UtilsService,
    private shopService: ShopsService,
    private userService: UserService,
    private terminalsService: TerminalsService,
    private tapXphoneService: TapXphoneService,
    private cachedDataService: CachedDataService,
  ) {
    this.isDesktop = this.utilsService.isDesktop();
    this.isAndroidApp = this.utilsService.isAndroidApp();

    if (this.isAndroidApp) {
      this.connectionMethods = this.connectionMethods.filter(
        (m) => m.value !== TerminalConnectionMethod.USB,
      );

      this.availableTerminals = this.supportedTerminals.filter(
        (s) => s.isAndroidSupport,
      );
    } else if (this.isDesktop) {
      this.availableTerminals = this.supportedTerminals.filter(
        (s) => s.isDesktopSupport,
      );
    }

    this.terminalForm = this.formBuilder.group({
      connectionProtocol: [TerminalConnectionProtocol.None],
      connectionMethod: [TerminalConnectionMethod.USB],
      ip: [
        {
          disabled: true,
          value: '',
        },
        Validators.compose([
          Validators.pattern(
            '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
          ),
        ]),
      ],
      usbPort: [
        '',
        Validators.compose([Validators.min(1), Validators.max(99)]),
      ],
      merchantId: [
        TERMINAL_DEFAULT_MERCHANT_ID,
        Validators.compose([
          Validators.required, // tslint:disable-line: no-unbound-method
          Validators.min(0),
          Validators.max(99),
        ]),
      ],
      serviceRefund: [TERMINAL_DEFAULT_SERVICE_REFUND],
      autoDebug: [TERMINAL_DEFAULT_AUTO_DEBUG],
    });

    this.terminalForm
      .get(KEY_CONNECTION_METHOD)
      ?.valueChanges.subscribe((method: TerminalConnectionMethod) => {
        this.refreshFormValidators();

        const ipControl = this.terminalForm.get(KEY_IP);

        if (ipControl) {
          method === TerminalConnectionMethod.Ethernet
            ? ipControl.enable()
            : ipControl.disable();
        }
      });
  }

  ngOnInit(): void {
    this.connected = false;
  }

  async ionViewWillEnter(): Promise<void> {
    this.data = await this.terminalsService.getTerminalData();
    this.settings = await this.terminalsService.getSettings();

    this.terminal =
      this.supportedTerminals.find(
        (st) => st.protocol === this.data.connectionProtocol,
      ) ?? this.supportedTerminals[0];

    this.terminalForm.patchValue({
      merchantId: this.settings.merchantId,
      serviceRefund: this.settings.serviceRefund,
      autoDebug: this.settings.autoDebug,
      connectionMethod: this.settings.connectionMethod,
      ip: this.settings.ip,
      usbPort: this.settings.usbPort,
    });

    if (this.data.connectionProtocol !== TerminalConnectionProtocol.None) {
      this.refreshFormValidators();
    }

    this.terminalsService.getConnectionStatus().subscribe((active) => {
      this.connected = active;

      if (active) {
        this.terminalForm.get(KEY_CONNECTION_METHOD)?.disable();
        this.terminalForm.get(KEY_IP)?.disable();
        this.terminalForm.get(KEY_USB_PORT)?.disable();
        this.terminalForm.get(KEY_MERCHANT_ID)?.disable();
        this.terminalForm.get(KEY_SERVICE_REFUND)?.disable();
        this.terminalForm.get(KEY_AUTO_DEBUG)?.disable();
      } else {
        this.terminalForm.get(KEY_CONNECTION_METHOD)?.enable();
        this.terminalForm.get(KEY_IP)?.enable();
        this.terminalForm.get(KEY_USB_PORT)?.enable();
        this.terminalForm.get(KEY_MERCHANT_ID)?.enable();
        this.terminalForm.get(KEY_AUTO_DEBUG)?.enable();
      }
    });
  }

  async ionViewDidEnter(): Promise<void> {
    if (this.data.connectionProtocol === TerminalConnectionProtocol.TapToMono) {
      const shop = this.cachedDataService.getShop();

      this.shopService.refresh(shop.id).subscribe((updatedShop) => {
        this.terminalsService
          .isTapXPhoneWarnings()
          .then((tapXphoneWarnings) => {
            this.tapXphoneWarnings = tapXphoneWarnings;
          })
          .catch();
      });
    }
  }

  async savePaymentTerminalData(): Promise<void> {
    this.data.connectionProtocol = this.terminal.protocol;

    this.refreshFormValidators();

    await this.terminalsService.setDefaultPaymentData(this.data);
  }

  downloadFiles(): void {
    window.open(
      `${environment.apiUrl}/settings/${
        this.data.connectionProtocol ===
        TerminalConnectionProtocol.PrivatbankJson
          ? 'privatbank'
          : 'terminal'
      }`,
      '_blank',
    );
  }

  async connect(): Promise<void> {
    this.data = await this.terminalsService.getTerminalData();
    this.settings = await this.terminalsService.getSettings();

    if (this.data.connectionProtocol === TerminalConnectionProtocol.TapToMono) {
      await this.linkTapXphoneApp();
    } else {
      this.settings.merchantId = this.terminalForm.get(KEY_MERCHANT_ID)?.value;

      this.settings.serviceRefund =
        this.terminalForm.get(KEY_SERVICE_REFUND)?.value;

      this.settings.autoDebug = this.terminalForm.get(KEY_AUTO_DEBUG)?.value;

      this.settings.connectionMethod = this.terminalForm.get(
        KEY_CONNECTION_METHOD,
      )?.value;

      this.settings.ip = this.terminalForm.get(KEY_IP)?.value;
      this.settings.usbPort = this.terminalForm.get(KEY_USB_PORT)?.value;

      await this.terminalsService.setSettings(this.settings);

      this.terminalsService.start(this.settings);
    }
  }

  disconnect(): void {
    this.terminalsService.disconnect();
  }

  pingDevice(): void {
    this.terminalsService.pingDevice();
  }

  identify(): void {
    this.terminalsService.identify();
  }

  getMerchantList(): void {
    this.terminalsService.getMerchantList();
  }

  audit(): void {
    this.terminalsService.audit();
  }

  verify(): void {
    this.terminalsService.verify();
  }

  debugMode(): void {
    this.terminalsService.debugMode();
  }

  tapXphoneStatus(): void {
    this.terminalsService.tapXphoneAppStatus();
  }

  openApp(): void {
    this.terminalsService.openApp();
  }

  async showServiceRefundHelp(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: `Налаштування повернення`,
      message: `
        Термінал може використовувати один з двох методів повернення: повернення по мерчанту й сервіс повернення.<br><br>
        Якщо поточний метод не працює або з'являється помилка, то потрібно змінити положення перемикача й спробувати знову.<br><br>
        Щоб дізнатися більше про налаштування термінала або змінити їх зверніться, будь ласка, до служби підтримки банку.`,
      buttons: [{ text: 'Закрити', role: 'close' }],
    });

    await alert.present();
  }

  private refreshFormValidators(): void {
    if (
      this.data.connectionProtocol === TerminalConnectionProtocol.PrivatbankJson
    ) {
      if (
        this.terminalForm.get(KEY_CONNECTION_METHOD)?.value ===
        this.terminalConnectionMethod.Ethernet
      ) {
        this.terminalForm.controls[KEY_IP].setValidators([
          Validators.required, // tslint:disable-line: no-unbound-method
          Validators.pattern(
            '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
          ),
        ]);

        this.terminalForm.controls[KEY_USB_PORT].clearValidators();
      } else if (
        this.terminalForm.get(KEY_CONNECTION_METHOD)?.value ===
        this.terminalConnectionMethod.USB
      ) {
        this.terminalForm.controls[KEY_USB_PORT].setValidators([
          Validators.required, // tslint:disable-line: no-unbound-method
          Validators.min(1),
          Validators.max(99),
        ]);

        this.terminalForm.controls[KEY_IP].clearValidators();
      }

      this.terminalForm.controls[KEY_IP].updateValueAndValidity();
      this.terminalForm.controls[KEY_USB_PORT].updateValueAndValidity();
    } else if (
      this.data.connectionProtocol === TerminalConnectionProtocol.TapToMono
    ) {
      this.terminalsService
        .isTapXPhoneWarnings()
        .then((tapXphoneWarnings) => {
          this.tapXphoneWarnings = tapXphoneWarnings;
        })
        .catch();
    } else {
      this.terminalForm.controls[KEY_IP].clearValidators();
      this.terminalForm.controls[KEY_IP].updateValueAndValidity();

      this.terminalForm.controls[KEY_USB_PORT].clearValidators();
      this.terminalForm.controls[KEY_USB_PORT].updateValueAndValidity();
    }
  }

  async openMenu(): Promise<void> {
    const actionSheetButtons: ActionSheetButton[] = [
      {
        text: 'Скасувати',
        icon: 'close',
        role: 'cancel',
      },
    ];

    actionSheetButtons.push({
      text: 'Очистити дані',
      icon: 'trash-outline',
      cssClass: 'clear',
      handler: () => {
        this.clearData();
      },
    });

    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Додаткові операції',
      cssClass: 'terminals-action-sheet-controller',
      buttons: actionSheetButtons,
    });

    await actionSheet.present();
  }

  private async clearData(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: `Платіжний термінал`,
      message: `<strong>Видалити</strong> всі налаштування та інформацію про термінал?`,
      buttons: [
        { text: 'Скасувати', role: 'cancel', cssClass: 'tertiary' },
        {
          text: 'Видалити',
          role: 'confirm',
          cssClass: 'primary',
          handler: () => {
            this.terminalsService.clearData().then(() => {
              this.navCtrl.back();
            });
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  private async linkTapXphoneApp(): Promise<void> {
    const shop = this.cachedDataService.getShop();
    const user = this.cachedDataService.getUser();

    if (user.tapxphoneConfirmedAt == null) {
      const alert = await this.alertCtrl.create({
        header: `Правила використання tapXphone`,
        message: `
          ● Якщо Ви запідозрите, що стався несанкціонований доступ до мобільного пристрою або мобільного додатку, Ви повинні повідомити про інцидент <strong>команду cashbox</strong> та Ваше <strong>керівництво</strong>.<br><br>
          ● У разі втрати або викрадення мобільного пристрою необхідно, щоб Ви негайно повідомили про інцидент <strong>команду cashbox</strong> та Ваше <strong>керівництво</strong>.
        `,
        buttons: [
          {
            text: 'Закрити',
            role: 'cancel',
            cssClass: 'tertiary',
          },
          {
            text: 'Погоджуюсь',
            role: 'confirm',
            cssClass: 'primary',
            handler: () => {
              this.tapXphoneService.confirmLicenseAgreement().subscribe(() => {
                this.toastService.present(
                  TOAST_TITLE,
                  'Згоду з Правилами використання tapXphone збережено',
                );

                this.userService.refresh(user.id).subscribe((updatedUser) => {
                  user.tapxphoneConfirmedAt = updatedUser.tapxphoneConfirmedAt;

                  this.terminalsService
                    .isTapXPhoneWarnings()
                    .then((tapXphoneWarnings) => {
                      this.tapXphoneWarnings = tapXphoneWarnings;

                      this.connect().then().catch();
                    });
                });
              });
            },
          },
        ],
        cssClass: CONFIRM_DIALOG_ALERT_STYLE,
      });

      await alert.present();

      return;
    }

    const deviceStatus = await this.tapXphoneService.getDeviceStatus();

    if (deviceStatus?.device_id != null) {
      this.settings.tapXphoneDeviceId = deviceStatus.device_id;

      this.tapXphoneWarnings =
        await this.terminalsService.isTapXPhoneWarnings();

      if (
        TAPXPHONE_REQUIRES_DEVICE_SETUP_MODES.includes(
          deviceStatus?.merchant_device_status_code ?? 0,
        )
      ) {
        const initResult = await this.tapXphoneService.initDevice(
          this.settings.tapXphoneDeviceId,
        );

        if (initResult) {
          this.shopService.refresh(shop.id).subscribe((updatedShop) => {
            shop.tapxphoneDeviceId = updatedShop.tapxphoneDeviceId;

            this.terminalsService
              .isTapXPhoneWarnings()
              .then((tapXphoneWarnings) => {
                this.tapXphoneWarnings = tapXphoneWarnings;
              });
          });
        }
      } else {
        await this.tapXphoneService.showDeviceStatus(deviceStatus);
      }
    }
  }
}
