import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  CRYPT_DATA_STORAGE_KEY,
  INDEXEDDB_TABLE_DATA,
  USERS_SETTINGS_STORAGE_KEY,
} from '../../settings/settings.const';
import { AUTH_URL } from '../constants/auth-url.const';
import { StorageTable } from '../models/storage-table.model';

interface Token {
  accessToken: string;
}

interface TokenData {
  iat: number; // Date of creation
  exp: number; // Date of expiration

  userId: number;
  role: string;
  companyId: number;
}

const TOKEN_STORAGE_KEY = 'jwtToken';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token?: Token | null;

  private tokenData?: TokenData | null;
  private tokenExpiration?: number | null;
  // private tokenRefresh$?: Observable<any> | null;

  constructor(private http: HttpClient) {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);

    if (token) {
      this.setToken(JSON.parse(token));
    }
  }

  getUserId(): number {
    return this.tokenData ? this.tokenData.userId : 0;
  }

  isAuthenticated(): boolean {
    return Boolean(this.token);
  }

  getCompanyId(): number {
    return this.tokenData ? this.tokenData.companyId : -1;
  }

  getUserRole(): string {
    return this.tokenData ? this.tokenData.role : '';
  }

  authenticate(username: string, password: string): Observable<Token> {
    return this.http
      .post<Token>(AUTH_URL, {
        username,
        password,
        strategy: 'local',
      })
      .pipe(
        tap((token) => {
          this.storeToken(token);
          this.setToken(token);
        }),
      );
  }

  /*
uncomment when use refreshToken on backend side

  refreshToken(): Observable<Token> {
    return this.http
      .post<Token>(
        AUTH_URL,
        {
          strategy: 'jwt',
        },
        {
          headers: {
            Authorization: `Bearer ${this.token!.accessToken}`,
          },
        },
      )
      .pipe(
        tap(token => {
          this.storeToken(token);
          this.setToken(token);
        }),
      );
  }
*/

  removeToken(): void {
    this.token = null;
    this.tokenData = null;
    this.tokenExpiration = null;

    const cryptData = localStorage.getItem(CRYPT_DATA_STORAGE_KEY);
    const usersSettings = localStorage.getItem(USERS_SETTINGS_STORAGE_KEY);

    localStorage.clear();

    if (cryptData != null) {
      localStorage.setItem(CRYPT_DATA_STORAGE_KEY, cryptData);
    }

    if (usersSettings != null) {
      localStorage.setItem(USERS_SETTINGS_STORAGE_KEY, usersSettings);
    }

    const data = new StorageTable(INDEXEDDB_TABLE_DATA);

    data.clear().then().catch();

    window.location.reload();
  }

  verifyToken(): Observable<any> {
    // Refresh token if it's expired
    if (this.token && this.isTokenExpired()) {
      this.removeToken();

      // Delay all requests until token is refreshed
      // if (!this.tokenRefresh$) {
      //   this.tokenRefresh$ = this.refreshToken().pipe(share());
      // }
      //
      // return this.tokenRefresh$;
    }

    // Clear refresh observable after token is refreshed
    // if (this.tokenRefresh$) {
    //   this.tokenRefresh$ = null;
    // }

    return of(null);
  }

  private isTokenExpired(): boolean {
    return Date.now() >= this.tokenExpiration!;
  }

  private storeToken(token: Token): void {
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(token));
  }

  private setToken(token: Token): void {
    this.token = token;
    this.tokenData = jwt_decode<TokenData>(token.accessToken);

    // TODO: Change token expiration to real expiration date, use refresh tokens
    // Now token expiration date is set two times lower that the real date
    this.tokenExpiration =
      (this.tokenData.exp - (this.tokenData.exp - this.tokenData.iat) / 2) *
      1000;
  }
}
