/**
 * Код форми оплати
 */
export enum PayFormCode {
  /**
   * Комбінована оплата
   */
  Mixed = -1,

  /**
   * Готівка
   */
  Cash = 0,

  /**
   * Банківська картка
   */
  Card = 1,

  /**
   * Попередня оплата
   */
  Avans = 2,

  /**
   * Кредит
   */
  Credit = 3,

  /**
   * Погашення кредиту
   */
  CreditOff = 4,

  /**
   * Відстрочення платежу
   */
  InDebt = 5,
}
