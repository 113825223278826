export class PrintPRroZReportPayment {
  /**
   * Найменування форми оплати (128 символів)
   */
  name: string;

  /**
   * Сума оплати (15.2 цифри)
   */
  sum: string;
}
