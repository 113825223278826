import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { Category } from '../../../shop/categories/category.model';
import { Product } from '../../../shop/products/product.model';
import { Subcategory } from '../../../shop/subcategories/subcategory.model';

type ItemWithCover = Category | Subcategory | Product;

@Component({
  selector: 'bk-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss'],
})
export class CoverComponent implements OnInit {
  @Input() item: ItemWithCover;
  @Input() color: string;
  @Input() disabled = false;
  @Output() goToSubcategories: EventEmitter<Category> =
    new EventEmitter<Category>();
  @Output() goToProducts: EventEmitter<Subcategory> =
    new EventEmitter<Subcategory>();
  @Output() useProduct: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() updateProduct: EventEmitter<Product> = new EventEmitter<Product>();

  needUnits = false;
  coverPath: string;

  constructor() {}

  ngOnInit(): void {
    let section = '';

    if (this.isCategory(this.item)) {
      section = 'categories';
      this.needUnits = false;
    } else if (this.isSubcategory(this.item)) {
      section = 'subcategories';
      this.needUnits = false;
    } else if (this.isProduct(this.item)) {
      section = 'products';
      this.needUnits = true;
    }

    if (this.item.coverPath) {
      this.coverPath = `${environment.apiUrl}/${section}/${this.item.id}/cover/${this.item.coverPath}`;
    } else {
      this.coverPath = '';
    }
  }

  go(item: ItemWithCover): void {
    if (this.disabled) {
      return;
    }

    if (this.isCategory(item)) {
      this.goToSubcategories.emit(item);
    } else if (this.isSubcategory(item)) {
      this.goToProducts.emit(item);
    } else if (this.isProduct(item)) {
      this.useProduct.emit(item);
    }
  }

  popup(item: ItemWithCover): void {
    if (this.disabled) {
      return;
    }

    if (this.isProduct(item)) {
      this.updateProduct.emit(item);
    }
  }

  private isCategory(item: ItemWithCover): item is Category {
    return (
      (item as Subcategory).categoryId === undefined &&
      (item as Product).subcategoryId === undefined
    );
  }

  private isSubcategory(item: ItemWithCover): item is Subcategory {
    return (
      (item as Subcategory).categoryId !== undefined &&
      (item as Product).subcategoryId === undefined
    );
  }

  private isProduct(item: ItemWithCover): item is Product {
    return (
      (item as Product).categoryId !== undefined &&
      (item as Product).subcategoryId !== undefined
    );
  }
}
