import { ScalesResponse } from './response.interface';
import { ScalesConnectionMethod } from './scales-connection-method.enum';
import { ScalesModel } from './scales-model.enum';
import { ScalesSettings } from './scales-settings.interface';
import { Scales } from './scales.model';

export const DEFAULT_WEBSOCKET_PORT = 8090;
export const DEFAULT_COM_PORT = 2;
export const DEFAULT_DELAY = 450;
export const MIN_DELAY = 100;

export const TOAST_DESKTOP_TITLE = 'Суміщення з агентом зважування';
export const TOAST_ANDROID_TITLE = 'Суміщення з вагами';

export const DEFAULT_SCALES = new Scales(ScalesModel.None, 'Не вказано', {
  isAndroidSupport: true,
  isDesktopSupport: true,
});

export const DEFAULT_SETTINGS: ScalesSettings = {
  connectionMethod: ScalesConnectionMethod.None,
  scales: DEFAULT_SCALES,
  delay: DEFAULT_DELAY,
  comPort: DEFAULT_COM_PORT,
  websocketPort: DEFAULT_WEBSOCKET_PORT,
};

export const DEFAULT_DATA: ScalesResponse = {
  weight: 0,
  isStable: false,
  isError: true,
  error: '',
};
