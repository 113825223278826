export const PrivatbankJsonResponseCode: Record<number, string> = {
  51: 'Недостатньо коштів для проведення транзакції',
  1000: 'Загальна помилка',
  1001: 'Користувач скасував транзакцію',
  1002: 'EMV Decline',
  1003: 'Журнал транзакцій заповнений. Потрібно закрити зміну',
  1004: "Немає зв'язку з хостом",
  1005: 'У принтері немає паперу',
  1006: 'Помилка крипто-ключів',
  1007: 'Зчитувач карт не підключений',
  1008: 'Транзакція вже завершена',
};
