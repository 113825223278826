<div class="check" *ngIf="move">
  <bk-view-line [line]="numberCaption + ' ' + move.date"></bk-view-line>
  <bk-view-line [line]="senderNameCaption + ': ' + move.senderName"></bk-view-line>
  <bk-view-line [line]="recipientNameCaption + ': ' + move.recipientName"></bk-view-line>

  <table class="products">
    <tr class="header">
      <th class="index">№</th>
      <th class="name">{{ headerNameCaption }}</th>
      <th class="quantity">{{ headerQuantityCaption }}</th>
      <th class="sum">{{ headerSumCaption }}</th>
    </tr>

    <tr class="product" *ngFor="let product of move.products; let i = index">
      <td class="index">{{ i + 1 }}</td>
      <td class="name">{{ product.name }}, {{ product.type }}</td>
      <td class="quantity">{{ product.quantity }}</td>
      <td class="sum">{{ product.sum }}</td>
    </tr>
  </table>

  <bk-view-line [start]="totalSumCaption" [end]="move.sum"></bk-view-line>

  <bk-view-line start="&nbsp;" end="&nbsp;"></bk-view-line>

  <bk-view-line [start]="senderSignCaption + ' ____________'" [end]="recipientSignCaption + ' ____________'"></bk-view-line>

  <div class="page-break"></div>
</div>
