import { Component, Input, OnInit } from '@angular/core';

import {
  MOVE_HEADER_NAME_CAPTION,
  MOVE_HEADER_QUANTITY_CAPTION,
  MOVE_HEADER_SUM_CAPTION,
  MOVE_NUMBER_CAPTION,
  MOVE_RECIPIENT_NAME_CAPTION,
  MOVE_RECIPIENT_SIGN_CAPTION,
  MOVE_SENDER_NAME_CAPTION,
  MOVE_SENDER_SIGN_CAPTION,
  MOVE_TOTAL_SUM_CAPTION,
} from '../../receipt/receipt.const';
import { PrintMove } from '../../receipt/types/print-move.model';

@Component({
  selector: 'bk-view-move',
  templateUrl: './view-move.component.html',
  styleUrls: ['./view-move.component.scss'],
})
export class ViewMoveComponent implements OnInit {
  @Input() move: PrintMove;

  numberCaption = MOVE_NUMBER_CAPTION;
  senderNameCaption = MOVE_SENDER_NAME_CAPTION;
  recipientNameCaption = MOVE_RECIPIENT_NAME_CAPTION;
  headerNameCaption = MOVE_HEADER_NAME_CAPTION;
  headerQuantityCaption = MOVE_HEADER_QUANTITY_CAPTION;
  headerSumCaption = MOVE_HEADER_SUM_CAPTION;
  totalSumCaption = MOVE_TOTAL_SUM_CAPTION;
  senderSignCaption = MOVE_SENDER_SIGN_CAPTION;
  recipientSignCaption = MOVE_RECIPIENT_SIGN_CAPTION;

  constructor() {}

  ngOnInit(): void {
    //
  }
}
