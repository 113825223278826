/**
 * Клас документа
 */
export enum DocumentClassName {
  /**
   * Чек
   */
  Check = 'Check',

  /**
   * Z-звіт
   */
  ZRep = 'ZRep',
}
