import { NgModule } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

import { SharedModule } from '../shared/shared.module';

import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component';
import { BarcodeScannerService } from './barcode-scanner/barcode-scanner.service';
import { EleksSelectDialog } from './integrations/eleks/select-dialog/eleks-select.dialog';
import { IntegrationsComponent } from './integrations/integrations.component';
import { LogPageComponent } from './logs/log/log.page';
import { LogsPageComponent } from './logs/logs.page';
import { LabelPrinterComponent } from './printer/label/label-printer.component';
import { PrinterComponent } from './printer/printer.component';
import { ReceiptPrinterComponent } from './printer/receipt/receipt-printer.component';
import { ScalesComponent } from './scales/scales.component';
import { SecondScreenComponent } from './second-screen/second-screen.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { TerminalsComponent } from './terminals/terminals.component';

@NgModule({
  imports: [SharedModule, SettingsRoutingModule],
  declarations: [
    SettingsComponent,
    BarcodeScannerComponent,
    ReceiptPrinterComponent,
    LabelPrinterComponent,
    TerminalsComponent,
    ScalesComponent,
    IntegrationsComponent,
    SecondScreenComponent,
    LogsPageComponent,
    LogPageComponent,
    EleksSelectDialog,
    PrinterComponent,
  ],
  providers: [BarcodeScannerService, AndroidPermissions],
})
export class SettingsModule {}
