import { Injectable } from '@angular/core';
import remove from 'lodash-es/remove';

import { environment } from '../../../environments/environment';
import { ResponsePRROState } from '../../p-rro/fsco/interfaces/responses/response-prro-state.interface';
import { PRro } from '../../p-rro/p-rro.model';
import { ShopCryptData } from '../../p-rro/types/shop-crypt-data.model';
import {
  APP_SETTINGS_STORAGE_KEY,
  CRYPT_DATA_STORAGE_KEY,
  DARK_THEME_MODE_DEFAULT_VALUE,
  DEFAULT_CASHBOX_URL,
  DEFAULT_CORS_PROXY_URL,
  DEFAULT_DPS_PRRO_API_HTTP_URL,
  DEFAULT_DPS_PRRO_API_HTTPS_URL,
  DEFAULT_DPS_PRRO_CHECK_URL,
  DEFAULT_DPS_PRRO_URL,
  DEFAULT_MAX_SALE_AMOUNT,
  DEFAULT_SUPPORT_EMAIL,
  FISCAL_MODE_BY_DEFAULT_DEFAULT_VALUE,
  KEYBOARD_MODE_DEFAULT_VALUE,
  PRODUCTS_AS_LIST_MODE_DEFAULT_VALUE,
  QUICK_SALE_BUTTONS_MODE_DEFAULT_VALUE,
  ROUND_INVOICE_TOTAL_SUM_DEFAULT_VALUE,
  SHOW_COMMENT_IN_PAYMENT_DEFAULT_VALUE,
  TAB_CONTROL_DEFAULT_VALUE,
  USE_TSP_DEFAULT_VALUE,
  USERS_SETTINGS_STORAGE_KEY,
} from '../../settings/settings.const';
import { ShopPRroMode } from '../enum/shop-p-rro-mode.enum';
import { IWorkingIntervals } from '../interfaces/i-working-intervals';
import { IStorageAppSettings } from '../interfaces/storage-app-setting.interface';
import { IStorageUserSettings } from '../interfaces/storage-user-setting.interface';
import { ShiftUser } from '../models/shift-user.model';
import { Shift } from '../models/shift.model';
import { Shop } from '../models/shop.model';
import { User } from '../models/user.model';

import { AuthService } from './auth.service';
import { Events } from './events.service';

const CURRENT_SESSION_ID_STORAGE_KEY = 'currentSessionId';
const CURRENT_SHIFT_STORAGE_KEY = 'currentShift';
const CURRENT_SHOP_STORAGE_KEY = 'currentShop';
const CURRENT_SHOP_ID_STORAGE_KEY = 'currentShopId';
const CURRENT_USER_STORAGE_KEY = 'currentUser';
const CURRENT_WORKING_INTERVAL_STORAGE_KEY = 'currentWorkingInterval';
const CURRENT_PRRO_STORAGE_KEY = 'currentPRRO';
const CURRENT_PRRO_SHIFT_STORAGE_KEY = 'currentPRROShift';
const CURRENT_DPS_PRRO_API_URL = 'currentDPSPRROAPIURL';
const FAKE_PRRO_MAX_FISCAL_NUMBER = 10;

@Injectable({
  providedIn: 'root',
})
export class CachedDataService {
  constructor(private authService: AuthService, private events: Events) {}

  getDefaultDpsApiUrl(): string {
    const url = localStorage.getItem(CURRENT_DPS_PRRO_API_URL);

    return url != null
      ? String(JSON.parse(url))
      : this.getAppSettings().dpsPRroApiHttpsUrl ??
          this.getAppSettings().dpsPRroApiHttpUrl ??
          DEFAULT_DPS_PRRO_API_HTTPS_URL;
  }

  getReserveDpsApiUrl(): string {
    return (
      this.getAppSettings().dpsPRroApiHttpUrl ?? DEFAULT_DPS_PRRO_API_HTTP_URL
    );
  }

  setReserveDpsPRroApiUrl(): void {
    const url = this.getReserveDpsApiUrl();

    this.setDpsPRroApiUrl(url);
  }

  setMainDpsPRroApiUrl(): void {
    const url =
      this.getAppSettings().dpsPRroApiHttpsUrl ??
      DEFAULT_DPS_PRRO_API_HTTPS_URL;

    this.setDpsPRroApiUrl(url);
  }

  getSessionId(): number {
    const sessionId = localStorage.getItem(CURRENT_SESSION_ID_STORAGE_KEY);

    return sessionId != null ? Number(JSON.parse(sessionId)) : 0;
  }

  setSessionId(sessionId: number): void {
    localStorage.setItem(
      CURRENT_SESSION_ID_STORAGE_KEY,
      JSON.stringify(sessionId),
    );
  }

  getShift(): Shift {
    const shift = localStorage.getItem(CURRENT_SHIFT_STORAGE_KEY);

    return shift != null ? (JSON.parse(shift) as Shift) : null!;
  }

  setShift(shift: Shift | null): void {
    let localShift: Shift | null = null;

    if (shift != null) {
      localShift = new Shift();

      localShift.id = shift.id;
      localShift.shopId = shift.shopId;
      localShift.userId = shift.userId;
      localShift.createdAt = shift.createdAt;
      localShift.closed = shift.closed;
      localShift.shiftUsers = [];

      shift.shiftUsers.forEach((shiftUser) => {
        const tempShiftUser = new ShiftUser();

        tempShiftUser.userId = shiftUser.userId;

        localShift?.shiftUsers.push(tempShiftUser);
      });
    }

    localStorage.setItem(CURRENT_SHIFT_STORAGE_KEY, JSON.stringify(localShift));
  }

  getShopId(): number {
    const id = localStorage.getItem(CURRENT_SHOP_ID_STORAGE_KEY);

    return id != null ? Number(JSON.parse(id)) : null!;
  }

  getShop(): Shop {
    const shop = localStorage.getItem(CURRENT_SHOP_STORAGE_KEY);

    return shop != null ? (JSON.parse(shop) as Shop) : null!;
  }

  isPRroActive(): boolean {
    const cryptData = this.getCryptData();

    return this.isFiscalMode() && cryptData != null && cryptData.isCorrect();
  }

  isFiscalMode(): boolean {
    const shop = this.getShop();
    const prro = this.getPRRO();

    if (
      prro != null &&
      prro.companyName &&
      prro.companyEDRPOU &&
      prro.shopName &&
      prro.shopAddress &&
      prro.fiscalNumber > FAKE_PRRO_MAX_FISCAL_NUMBER &&
      shop.prros?.length > 0 &&
      shop.prroMode !== ShopPRroMode.Disabled
    ) {
      return true;
    }

    return false;
  }

  getLastDocNumber(): number {
    return Number(this.getPRRO().lastDocNumber);
  }

  updateLastDocNumber(localNumber: number, fiscalNumber?: string): void {
    const prro = this.getPRRO();

    prro.lastDocNumber = localNumber;
    prro.lastDocLocalNumber = localNumber;
    prro.lastDocFiscalNumber = fiscalNumber;

    localStorage.setItem(CURRENT_PRRO_STORAGE_KEY, JSON.stringify(prro));
  }

  setShop(shop: Shop): void {
    if (shop.checkLogoPath) {
      shop.checkLogoPath = `${environment.apiUrl}/shops/${shop.id}/check-logo/${shop.checkLogoPath}`;
    }

    localStorage.setItem(CURRENT_SHOP_ID_STORAGE_KEY, JSON.stringify(shop.id));
    localStorage.setItem(CURRENT_SHOP_STORAGE_KEY, JSON.stringify(shop));
  }

  getUser(): User {
    const user = localStorage.getItem(CURRENT_USER_STORAGE_KEY);

    return user ? (JSON.parse(user) as User) : null!;
  }

  setUser(user: User | null): void {
    localStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(user));
  }

  getWorkingIntervals(): IWorkingIntervals {
    const workingIntervals = localStorage.getItem(
      CURRENT_WORKING_INTERVAL_STORAGE_KEY,
    );

    return workingIntervals
      ? (JSON.parse(workingIntervals) as IWorkingIntervals)
      : null!;
  }

  setWorkingIntervals(workingIntervals: IWorkingIntervals | null): void {
    localStorage.setItem(
      CURRENT_WORKING_INTERVAL_STORAGE_KEY,
      JSON.stringify(workingIntervals),
    );
  }

  //#region Crypt Data
  private getCryptDataArray(): ShopCryptData[] | null {
    const cryptDataString = localStorage.getItem(CRYPT_DATA_STORAGE_KEY);

    return cryptDataString
      ? (JSON.parse(cryptDataString) as ShopCryptData[])
      : null!;
  }

  private saveCryptDataArray(savedCryptData: ShopCryptData[]): void {
    localStorage.setItem(
      CRYPT_DATA_STORAGE_KEY,
      JSON.stringify(savedCryptData),
    );
  }

  getCryptData(): ShopCryptData | null {
    const cryptDataArray = this.getCryptDataArray();

    try {
      const shopId = this.getShopId();
      const userId = this.getUser().id;

      if (cryptDataArray != null) {
        const currentCryptData = cryptDataArray.find(
          (cd) => cd.shopId === shopId && cd.userId === userId,
        );

        if (currentCryptData != null) {
          const cryptData = new ShopCryptData(
            currentCryptData.shopId,
            currentCryptData.userId,
          );

          cryptData.key = currentCryptData.key;
          cryptData.cert = currentCryptData.cert;
          cryptData.isJks = currentCryptData.isJks;
          cryptData.role = currentCryptData.role;
          cryptData.password = currentCryptData.password;

          return cryptData;
        }
      }

      return new ShopCryptData(shopId, userId);
    } catch {
      return null;
    }
  }

  setCryptData(cryptData: ShopCryptData): void {
    let savedCryptData = this.getCryptDataArray();

    if (savedCryptData != null) {
      const currentCryptData = savedCryptData.find(
        (cd) =>
          cd.shopId === cryptData.shopId && cd.userId === cryptData.userId,
      );

      if (currentCryptData != null) {
        currentCryptData.key = cryptData.key;
        currentCryptData.cert = cryptData.cert;
        currentCryptData.isJks = cryptData.isJks;
        currentCryptData.role = cryptData.role;
        currentCryptData.password = cryptData.password;
      } else {
        savedCryptData.push(cryptData);
      }
    } else {
      savedCryptData = [cryptData];
    }

    this.saveCryptDataArray(savedCryptData);
  }

  deleteCryptData(): boolean {
    const savedCryptData = this.getCryptDataArray();

    try {
      const shopId = this.getShopId();
      const userId = this.getUser().id;

      if (savedCryptData != null) {
        const currentCryptData = savedCryptData.find(
          (cd) => cd.shopId === shopId && cd.userId === userId,
        );

        if (currentCryptData != null) {
          remove(
            savedCryptData,
            (cd) => cd.userId === userId && cd.shopId === shopId,
          );

          this.saveCryptDataArray(savedCryptData);

          return true;
        }
      }
    } catch {
      return false;
    }

    return false;
  }

  getPRRO(): PRro {
    const prro = localStorage.getItem(CURRENT_PRRO_STORAGE_KEY);

    return prro ? (JSON.parse(prro) as PRro) : null!;
  }

  setPRRO(prro: PRro | null): void {
    localStorage.setItem(CURRENT_PRRO_STORAGE_KEY, JSON.stringify(prro));

    this.events.publish('fiscal:status', this.isFiscalMode());
  }

  getPRROShift(): ResponsePRROState {
    const prroShift = localStorage.getItem(CURRENT_PRRO_SHIFT_STORAGE_KEY);

    return prroShift ? (JSON.parse(prroShift) as ResponsePRROState) : null!;
  }

  setPRROShift(prro: ResponsePRROState | null): void {
    localStorage.setItem(CURRENT_PRRO_SHIFT_STORAGE_KEY, JSON.stringify(prro));
  }

  getUsersSettingsArray(): IStorageUserSettings[] | null {
    const usersSettingsString = localStorage.getItem(
      USERS_SETTINGS_STORAGE_KEY,
    );

    return usersSettingsString
      ? (JSON.parse(usersSettingsString) as IStorageUserSettings[])
      : null!;
  }

  getUserSettings(): IStorageUserSettings {
    const usersSettings = this.getUsersSettingsArray();

    let userId = 0;

    try {
      userId = this.getUser().id;
    } catch {
      userId = this.authService.getUserId();
    }

    if (usersSettings != null) {
      const currentUserSettings = usersSettings.find(
        (us) => us.userId === userId,
      );

      if (currentUserSettings != null) {
        return currentUserSettings;
      }
    }

    return {
      userId,
      keyboardMode: KEYBOARD_MODE_DEFAULT_VALUE,
      darkThemeMode: DARK_THEME_MODE_DEFAULT_VALUE,
      quickSaleButtonsMode: QUICK_SALE_BUTTONS_MODE_DEFAULT_VALUE,
      fiscalByDefaultMode: FISCAL_MODE_BY_DEFAULT_DEFAULT_VALUE,
      productsAsListMode: PRODUCTS_AS_LIST_MODE_DEFAULT_VALUE,
      roundInvoiceTotalSum: ROUND_INVOICE_TOTAL_SUM_DEFAULT_VALUE,
      showCommentInPayment: SHOW_COMMENT_IN_PAYMENT_DEFAULT_VALUE,
      tabControl: TAB_CONTROL_DEFAULT_VALUE,
      useTSP: USE_TSP_DEFAULT_VALUE,
    };
  }

  setUserSettings(userSettings: IStorageUserSettings): void {
    let savedUsersSettings = this.getUsersSettingsArray();

    if (savedUsersSettings != null) {
      const currentUserSettings = savedUsersSettings.find(
        (us) => us.userId === userSettings.userId,
      );

      if (currentUserSettings) {
        currentUserSettings.keyboardMode = userSettings.keyboardMode;
        currentUserSettings.darkThemeMode = userSettings.darkThemeMode;
        currentUserSettings.quickSaleButtonsMode =
          userSettings.quickSaleButtonsMode;
        currentUserSettings.fiscalByDefaultMode =
          userSettings.fiscalByDefaultMode;
        currentUserSettings.productsAsListMode =
          userSettings.productsAsListMode;
        currentUserSettings.roundInvoiceTotalSum =
          userSettings.roundInvoiceTotalSum;
        currentUserSettings.showCommentInPayment =
          userSettings.showCommentInPayment;
        currentUserSettings.tabControl = userSettings.tabControl;
        currentUserSettings.useTSP = userSettings.useTSP;
      } else {
        savedUsersSettings.push(userSettings);
      }
    } else {
      savedUsersSettings = [userSettings];
    }

    localStorage.setItem(
      USERS_SETTINGS_STORAGE_KEY,
      JSON.stringify(savedUsersSettings),
    );
  }

  getAppSettings(): IStorageAppSettings {
    const appSettings = localStorage.getItem(APP_SETTINGS_STORAGE_KEY);

    return appSettings != null
      ? (JSON.parse(appSettings) as IStorageAppSettings)
      : {
          supportEmail: DEFAULT_SUPPORT_EMAIL,
          url: DEFAULT_CASHBOX_URL,
          corsProxyUrl: DEFAULT_CORS_PROXY_URL,
          dpsPRroUrl: DEFAULT_DPS_PRRO_URL,
          dpsPRroApiHttpsUrl: DEFAULT_DPS_PRRO_API_HTTPS_URL,
          dpsPRroApiHttpUrl: DEFAULT_DPS_PRRO_API_HTTP_URL,
          dpsPRroCheckUrl: DEFAULT_DPS_PRRO_CHECK_URL,
          maxSaleAmount: DEFAULT_MAX_SALE_AMOUNT.toString(),
        };
  }

  setAppSettings(appSettings: IStorageAppSettings | null): void {
    localStorage.setItem(APP_SETTINGS_STORAGE_KEY, JSON.stringify(appSettings));
  }

  private setDpsPRroApiUrl(url: string): void {
    localStorage.setItem(CURRENT_DPS_PRRO_API_URL, JSON.stringify(url));
  }
}
