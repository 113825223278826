import { TransactionType } from '../transaction-type.enum';

import { Transaction } from './transaction.model';

export class FinanceExpense extends Transaction {
  // Unique fields
  price: number;
  subCategoryId: number;

  // App fields
  categoryName?: string;
  subcategoryName?: string;
  salaryEmployeeId?: number;
  salaryDate?: string;

  constructor(data: { amount: number; comment?: string } = { amount: 0 }) {
    super(TransactionType.FinanceExpense, data.amount);

    this.price = data.amount;

    if (data.comment != null) {
      this.comment = data.comment;
    }
  }
}
