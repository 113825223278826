import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { hextorstr, stob64, KEYUTIL, KJUR } from 'jsrsasign';
import * as qz from 'qz-tray';

import { DATE_FORMAT } from '../../../core/constants/date.const';
import { StorageTable } from '../../../core/models/storage-table.model';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { ToastService } from '../../../core/services/toast.service';
import { UtilsService } from '../../../core/services/utils.service';
import { LabelPrinterData } from '../label/label-printer-data.model';
import { PdfService } from '../label/services/pdf.service';
import { ReceiptPrinterData } from '../receipt/receipt-printer-data.model';
import {
  ESC_POS_ALIGN_CENTER,
  ESC_POS_ALIGN_LEFT,
  ESC_POS_CUT_PAPER_OLD,
  ESC_POS_INIT,
  ESC_POS_NEW_LINE,
  ESC_POS_OPEN_CASH_DRAWER_COMMAND_1,
} from '../receipt/receipt.const';
import { Receipt } from '../receipt/receipt.enum';

import { IQzTrayConnectionConfig } from './qz-tray-connection-config.interface';

const KEY_CERT = 'cert';
const KEY_KEY = 'key';
const KEY_LOGO = 'logo';

const QZ_TRAY_CONFIG_CAPTION = `Налаштування друку QZ Tray`;
const QZ_TRAY_SERVICE_CAPTION = `Сервіс друку QZ Tray`;
const WEBSOCKET_CONNECTION_CONFIG: IQzTrayConnectionConfig = {
  retries: 3,
  delay: 1,
};

@Injectable({
  providedIn: 'root',
})
export class QzTrayService {
  private qz: StorageTable;
  private isDesktop: boolean;
  private requestInProgress = false;

  constructor(
    protected http: HttpClient,
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
    private utilsService: UtilsService,
    private pdfService: PdfService,
  ) {
    this.qz = new StorageTable('qz');

    this.isDesktop = this.utilsService.isDesktop();
  }

  async clearData(): Promise<void> {
    await this.qz.clear();
  }

  async getValue(key: string): Promise<string> {
    const value = await this.qz.get<string>(key);

    if (value != null && typeof value === 'string') {
      return value;
    }

    return '';
  }

  async loadData(): Promise<void> {
    if (!this.isDesktop) {
      return;
    }

    await this.http
      .get(`/settings/qz-print-cert`, {
        responseType: 'text',
      })
      .toPromise()
      .then(async (cert) => {
        await this.qz.set(KEY_CERT, cert);
      })
      .catch((error) => {
        //
      });

    await this.http
      .get(`/settings/qz-print-key`, {
        responseType: 'text',
      })
      .toPromise()
      .then(async (key) => {
        await this.qz.set(KEY_KEY, key);
      })
      .catch((error) => {
        //
      });

    const shop = this.cachedDataService.getShop();

    if (shop.checkLogoPath > '') {
      await this.http
        .get(shop.checkLogoPath, {
          responseType: 'arraybuffer',
        })
        .toPromise()
        .then(async (logo) => {
          await this.qz.set(KEY_LOGO, Buffer.from(logo).toString('base64'));
        })
        .catch((error) => {
          //
        });
    } else {
      await this.qz.set(KEY_LOGO, '');
    }
  }

  async startConnection(config?: IQzTrayConnectionConfig): Promise<void> {
    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    await this.setSignData();

    if (!qz.websocket.isActive()) {
      try {
        await qz.websocket.connect(config ?? WEBSOCKET_CONNECTION_CONFIG);

        this.toastService.present(`Сервіс друку QZ Tray активовано`);
      } catch (error) {
        this.handleConnectionError(error);
      } finally {
        this.requestInProgress = false;
      }
    } else {
      this.requestInProgress = false;
    }
  }

  // Disconnect QZ Tray websocket
  async endConnection(): Promise<void> {
    if (qz.websocket.isActive()) {
      try {
        await qz.websocket.disconnect();

        this.toastService.present(`Сервіс друку QZ Tray зупинено`);
      } catch (error) {
        this.handleConnectionError(error);
      }
    } else {
      this.toastService.presentWarning(
        QZ_TRAY_CONFIG_CAPTION,
        `Немає активних з'єднань QZ Tray`,
      );
    }
  }

  async isActive(): Promise<boolean> {
    return qz.websocket.isActive();
  }

  async printerExist(
    name: string,
    warningOptions: { url: string; printerClass: string },
  ): Promise<boolean> {
    const printer = await this.getPrinter(name);

    if (printer === '') {
      this.toastService.presentWarning(
        QZ_TRAY_CONFIG_CAPTION,
        `Друк не можливий: не обраний ${warningOptions.printerClass}\nВиберіть принтер у налаштуваннях`,
        5000,
        `settings/${warningOptions.url}`,
      );
    }

    return printer > '';
  }

  // Get the list of printers connected
  async getPrinters(): Promise<string[]> {
    if (!qz.websocket.isActive()) {
      await this.startConnection();
    }

    let printers: string[] = [];

    try {
      printers = await qz.printers.find();
    } catch (error) {
      this.toastService.presentWarning(
        QZ_TRAY_SERVICE_CAPTION,
        `Не вдалося отримати перелік принтерів: ${JSON.stringify(error)}`,
      );
    }

    return printers;
  }

  async openCashDrawer(data: ReceiptPrinterData): Promise<void> {
    if (!this.isDesktop) {
      return;
    }

    if (!qz.websocket.isActive()) {
      await this.startConnection();
    }

    const config = qz.configs.create(data.settings.printer.name, {
      encoding: 'Windows-1251', // 'CP1251'
    });

    await qz.print(config, [ESC_POS_OPEN_CASH_DRAWER_COMMAND_1]);
  }

  // Print raw data to chosen printer
  async printReceipt(data: ReceiptPrinterData): Promise<void> {
    if (!this.isDesktop) {
      return;
    }

    if (data.doc == null) {
      this.toastService.presentWarning(
        QZ_TRAY_SERVICE_CAPTION,
        `Документ не знайдено`,
      );

      return;
    }

    const receipt: any[] = [ESC_POS_INIT];

    if (data.type === Receipt.Sale) {
      const logo = await this.getValue(KEY_LOGO);

      if (data.settings.printShopLogoInReceipt && logo > '') {
        receipt.push(
          ...[
            ESC_POS_ALIGN_CENTER,
            {
              type: 'raw',
              format: 'image',
              flavor: 'base64',
              data: logo,
              options: {
                language: 'ESCPOS',
                dotDensity: 'double',
              },
            },
            ESC_POS_ALIGN_LEFT,
          ],
        );
      }

      data.doc.receipt().forEach((row) => {
        if (typeof row === 'string') {
          receipt.push(`${row}${ESC_POS_NEW_LINE}`);
        } else {
          receipt.push(...[ESC_POS_ALIGN_CENTER, row, ESC_POS_ALIGN_LEFT]);
        }
      });
    } else {
      receipt.push(
        ...data.doc.receipt().map((row) => `${row}${ESC_POS_NEW_LINE}`),
      );
    }

    receipt.push(
      `${''.padEnd(
        data.settings.linesToCut,
        ESC_POS_NEW_LINE,
      )}${ESC_POS_CUT_PAPER_OLD}${
        data.settings.useCashDrawer ? ESC_POS_OPEN_CASH_DRAWER_COMMAND_1 : ''
      }`,
    );

    if (!qz.websocket.isActive()) {
      await this.startConnection();
    }

    const config = qz.configs.create(data.settings.printer.name, {
      encoding: 'Windows-1251', // 'CP1251'
    });

    await qz.print(config, receipt);
  }

  // Print pixel data to chosen printer
  async printLabels(data: LabelPrinterData): Promise<void> {
    if (!this.isDesktop) {
      return;
    }

    const name = `Етикетки від ${formatDate(new Date(), DATE_FORMAT, 'uk_UA')}`;
    const fileName = `[${data.shop.name}] ${name}.pdf`;

    data.logo = await this.getValue(KEY_LOGO);

    const doc = await this.pdfService.generateLabels(data);

    if (data.settings.pdfInsteadPrint) {
      doc.save(fileName, { returnPromise: true }).then(() => {
        //
      });

      return;
    }

    const config = qz.configs.create(data.settings.printer.name, {
      jobName: fileName,
      orientation: 'portrait',
      size: {
        width: data.settings.paperFormat.width,
        height: data.settings.paperFormat.height,
      },
      units: 'mm',
      margins: data.settings.margins,
      colorType: 'grayscale',
      interpolation: 'nearest-neighbor',
    });

    if (!qz.websocket.isActive()) {
      await this.startConnection();
    }

    await qz.print(config, [
      {
        type: 'pixel',
        format: 'pdf',
        flavor: 'base64',
        data: doc.output('datauristring').split('base64,')[1],
      },
    ]);
  }

  private async setSignData(): Promise<void> {
    let cert = await this.getValue(KEY_CERT);
    let key = await this.getValue(KEY_KEY);

    if (!cert || !key) {
      await this.loadData()
        .then(async () => {
          cert = await this.getValue(KEY_CERT);
          key = await this.getValue(KEY_KEY);
        })
        .catch((reason) => {
          this.toastService.presentWarning(
            QZ_TRAY_CONFIG_CAPTION,
            `Немає даних для забезпечення безпеки з'єднання`,
          );
        });
    }

    if (!cert || !key) {
      return;
    }

    qz.security.setCertificatePromise(
      (resolve: (arg0: any) => void, reject: any) => {
        // Alternate method 2 - direct
        resolve(cert);
      },
    );

    qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1

    // DANGER:  Don't leak qz issued private keys!
    qz.security.setSignaturePromise((toSign: any) => {
      return (resolve: (arg0: any) => void, reject: (arg0: any) => void) => {
        try {
          const pk = KEYUTIL.getKey(key);
          const sig = new KJUR.crypto.Signature({ alg: 'SHA512withRSA' });

          sig.init(pk);
          sig.updateString(toSign);

          const hex = sig.sign();

          resolve(stob64(hextorstr(hex)));
        } catch (error) {
          this.toastService.presentWarning(
            QZ_TRAY_CONFIG_CAPTION,
            `Не вдалося активувати засоби безпеки з'єднання`,
          );
          reject(error);
        }
      };
    });
  }

  private handleConnectionError(error: any): void {
    if (error.target !== undefined) {
      // if CLOSING or CLOSED
      if (error.target.readyState >= 2) {
        this.toastService.presentError(
          QZ_TRAY_SERVICE_CAPTION,
          `Підключення до QZ Tray було завершене раніше`,
        );
      } else {
        this.toastService.presentError(
          QZ_TRAY_SERVICE_CAPTION,
          `Помилка підключення: ${JSON.stringify(error)}`,
        );
      }
    } else {
      this.toastService.presentError(
        QZ_TRAY_SERVICE_CAPTION,
        `Помилка: ${JSON.stringify(error)}`,
      );
    }
  }

  // Get the SPECIFIC connected printer
  private async getPrinter(name: string): Promise<string> {
    if (!qz.websocket.isActive()) {
      await this.startConnection();
    }

    let printer = '';

    try {
      printer = await qz.printers.find(name);
    } catch (error) {
      this.toastService.presentWarning(
        QZ_TRAY_SERVICE_CAPTION,
        `Не вдалося знайти принтер "${name}": ${JSON.stringify(error)}`,
      );
    }

    return printer;
  }
}
