import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { RevisionRoutingModule } from './revision-routing.module';
import { RevisionComponent } from './revision.component';

@NgModule({
  imports: [SharedModule, RevisionRoutingModule],
  declarations: [RevisionComponent],
})
export class RevisionModule {}
