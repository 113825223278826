import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { CryptSettingsDialog } from './components/crypt-settings/crypt-settings.dialog';
import { SelectPRroDialog } from './components/select-p-rro/select-p-rro.dialog';
import { TaxQrDialog } from './components/tax-qr/tax-qr.dialog';
import { PRroRoutingModule } from './p-rro-routing.module';
import { PRroComponent } from './p-rro.component';

@NgModule({
  imports: [SharedModule, PRroRoutingModule],
  declarations: [
    PRroComponent,
    CryptSettingsDialog,
    TaxQrDialog,
    SelectPRroDialog,
  ],
})
export class PRroModule {}
