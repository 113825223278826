<ion-header>
  <ion-toolbar>
    <ion-title>Виберіть торгову точку</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div *ngFor="let shop of shops">
    <ion-button [disabled]="getShopStatus(shop).disabled"
                (click)="closeModal(shop)">
      <ion-label>{{ shop.name }}</ion-label>
    </ion-button>

    <ion-label color="medium">
      {{ getShopStatus(shop).shiftCaption }}
    </ion-label>

    <ion-label color="medium" *ngIf="getShopStatus(shop).slotsCaption">
      {{ getShopStatus(shop).slotsCaption }}
    </ion-label>

    <ion-label color="medium" *ngIf="getShopStatus(shop).usersCaption">
      {{ getShopStatus(shop).usersCaption }}
    </ion-label>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="one-button" [disabled]="true" >Графік роботи</ion-button>
  </ion-toolbar>
</ion-footer>