import round from 'lodash-es/round';

import { SaleProduct } from '../../../sales/sale/sale-product.model';
import { DiscountType } from '../enums/discount-type.enum';

export class PRroSaleItem {
  /**
   * Внутрішній код товару (64 символи)
   */
  code: number;

  /**
   * Код товару згідно з УКТЗЕД (15 цифр)
   */
  UKTZED: string;

  /**
   * Найменування товару, послуги або операції (текст)
   */
  name: string;

  /**
   * Найменування одиниці виміру (64 символи)
   */
  unit: string;

  /**
   * Кількість/об’єм товару (15.3 цифри)
   */
  quantity: number;

  /**
   * Ціна за одиницю товару (15.2 цифри)
   */
  price: number;

  /**
   * Сума операції (15.2 цифри)
   */
  cost: number;

  /**
   * Тип знижки/націнки (1 символ):
   *
   * 0–Сумова,
   *
   * 1–Відсоткова
   */
  discountType: number;

  /**
   * Загальна сума знижки (15.2 цифри)
   */
  discountSum: number;

  /**
   * Літерні позначення видів і ставок податків/зборів (15 символів)
   */
  letters: string;

  /**
   * Серія та номер марки акцизного податку
   */
  exciseLabel: string;

  constructor(saleProduct?: SaleProduct) {
    if (saleProduct == null) {
      return;
    }

    this.code = saleProduct.productId;
    this.name = saleProduct.product.name;
    this.unit = saleProduct.product.amount;

    const weightCoefficient = Math.pow(
      10,
      Number(saleProduct.product.weightProduct),
    );

    this.quantity = round(
      Math.abs(saleProduct.quantity) / weightCoefficient,
      3,
    );

    this.price = round(Math.abs(saleProduct.fullPrice) * weightCoefficient, 2);
    this.cost = round(Math.abs(saleProduct.fullCost), 2);
    this.discountSum = round(Math.abs(saleProduct.discount ?? 0), 2);

    if (this.discountSum > 0) {
      this.discountType = DiscountType.Sum;
    }

    if (saleProduct.UKTZED != null && saleProduct.UKTZED > '') {
      this.UKTZED = saleProduct.UKTZED;
    }

    if (saleProduct.exciseLetter != null || saleProduct.vatLetter != null) {
      this.letters =
        (saleProduct.exciseLetter ?? '') + (saleProduct.vatLetter ?? '');
    }

    if (saleProduct.exciseLabel != null) {
      this.exciseLabel = saleProduct.exciseLabel;
    }
  }

  static exciseAmount(
    value: number,
    percent: number,
    precision: number = 2,
  ): number {
    return (
      Math.round(
        Math.fround(
          value * (1 - 100 / (100 + percent)) * Math.pow(10, precision),
        ),
      ) / Math.pow(10, precision)
    );
  }

  static vatAmount(
    value: number,
    percent: number,
    exciseAmount: number,
    precision: number = 2,
  ): number {
    return (
      Math.round(
        Math.fround(
          (value - exciseAmount) *
            (1 - 100 / (100 + percent)) *
            Math.pow(10, precision),
        ),
      ) / Math.pow(10, precision)
    );
  }
}
