import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';

import { CachedResource } from '../../core/abstract/cached-resource';
import { ObjectLiteral } from '../../core/interfaces/object-literal';
import { CachedDataService } from '../../core/services/cached-data.service';

import { Subcategory } from './subcategory.model';

@Injectable({
  providedIn: 'root',
})
export class SubcategoriesService extends CachedResource<Subcategory> {
  constructor(
    protected http: HttpClient,
    protected platform: Platform,
    protected network: Network,
    private cachedDataService: CachedDataService,
  ) {
    super(http, platform, network, {
      path: '/subcategories',
      cache: true,
      preferCache: true,
    });
  }

  find(
    options: Partial<Subcategory> | ObjectLiteral = {},
  ): Observable<Subcategory[]> {
    options = {
      ...options,
      shopId: this.cachedDataService.getShopId(),
    };

    return super.find(options);
  }

  findForApp(
    options: Partial<Subcategory> | ObjectLiteral = {},
    customOptions?: { forceRefresh?: boolean },
  ): Observable<Subcategory[]> {
    if (options.categoryId == null) {
      options = {
        ...options,
        shopId: this.cachedDataService.getShopId(),
      };
    }

    return super.findForApp(options, customOptions);
  }
}
