/**
 * Розширений тип документа "Чек"
 */
export enum CheckDocumentSubType {
  /**
   * Касовий чек (реалізація)
   */
  CheckGoods = 0,

  /**
   * Видатковий чек (повернення)
   */
  CheckReturn = 1,

  /**
   * Чек операції "службове внесення" / "отримання авансу"
   */
  ServiceDeposit = 2,

  /**
   * Чек операції "отримання підкріплення"
   */
  AdditionalDeposit = 3,

  /**
   * Чек операції "службова видача" / "інкасація"
   */
  ServiceIssue = 4,

  /**
   * Чек сторнування попереднього чека
   */
  CheckStorno = 5,
}
