import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Platform } from '@ionic/angular';

import {
  BARCODE_CHECK,
  SETTINGS_BLUETOOTH_BARCODE_SCANNER,
} from '../../../core/constants/events.const';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { Events } from '../../../core/services/events.service';
import { ToastService } from '../../../core/services/toast.service';
import { BarcodeScannerService } from '../../../settings/barcode-scanner/barcode-scanner.service';
import { CONNECTION_ERROR } from '../../../settings/settings.const';

@Component({
  selector: 'bk-bluetooth-barcode-scanner',
  templateUrl: './bluetooth-barcode-scanner.component.html',
  styleUrls: ['./bluetooth-barcode-scanner.component.scss'],
})
export class BluetoothBarcodeScannerComponent implements OnInit {
  @Input() defaultEmitter = false;
  @Output() checkCode: EventEmitter<string> = new EventEmitter<string>();

  canScan: boolean;

  private isBluetoothEnabled: boolean;
  private isConnected: boolean;

  constructor(
    private router: Router,
    private platform: Platform,
    private diagnostic: Diagnostic,
    private events: Events,
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
    private barcodeScannerService: BarcodeScannerService,
  ) {
    this.barcodeScannerService.getBluetoothDevice().subscribe((device) => {
      if (device !== null) {
        this.isConnected = true;

        device.subscribeRawData().subscribe((data) => {
          if (!this.canScan) {
            return;
          }

          const bytes = new Uint8Array(data);
          const code = String.fromCharCode.apply(null, bytes);

          this.checkBarcode(code.trim());
        });
      } else {
        this.canScan = false;
      }
    });

    this.events.subscribe(
      SETTINGS_BLUETOOTH_BARCODE_SCANNER,
      (flag: boolean) => {
        this.defaultEmitter = !flag;
      },
    );
  }

  ngOnInit(): void {
    if (this.platform.is('cordova')) {
      const usersSettings = this.cachedDataService.getUserSettings();

      if (usersSettings.keyboardMode) {
        this.barcodeScannerService.disconnectBluetoothDevice().then(() => {
          this.canScan = false;
          this.isConnected = false;
        });
      }

      this.canScan = this.barcodeScannerService.isBluetoothDeviceActive();
      this.isConnected = this.barcodeScannerService.isBluetoothDeviceActive();

      this.diagnostic
        .isBluetoothEnabled()
        .then((status) => (this.isBluetoothEnabled = status));
    } else {
      this.canScan = false;
      this.isConnected = false;
      this.isBluetoothEnabled = false;
    }
  }

  checkDevice(): void {
    if (!this.isBluetoothEnabled) {
      this.router.navigateByUrl('/settings/barcode-scanner');

      return;
    }

    if (!this.isConnected) {
      this.barcodeScannerService.initBluetoothDevice().subscribe((status) => {
        if (status === CONNECTION_ERROR || status == null) {
          this.isConnected = false;
          this.canScan = false;

          return;
        }

        this.isConnected = true;
        this.canScan = true;
      });
    } else {
      this.canScan = true;
    }
  }

  private checkBarcode(barcode: string): void {
    if (barcode > '') {
      if (this.defaultEmitter) {
        this.checkCode.emit(barcode);
      } else {
        this.events.publish(BARCODE_CHECK, barcode);
      }
    } else {
      this.toastService.present(
        'Невірний штрихкод',
        'Виникла помилка при зчитуванні штрихкоду',
      );
    }
  }
}
