<div class="check" *ngIf="refill">
  <bk-view-line [line]="refill.name + ' ' + refill.date"></bk-view-line>
  <bk-view-line [line]="providerNameCaption + ': ' + refill.providerName"></bk-view-line>
  <bk-view-line [line]="shopNameCaption + ': ' + refill.shopName"></bk-view-line>

  <table class="products">
    <tr class="header">
      <th class="index">№</th>
      <th class="name">{{ headerNameCaption }}</th>
      <th class="quantity">{{ headerQuantityCaption }}</th>
      <th class="sum">{{ headerSumCaption }}</th>
    </tr>

    <tr class="product" *ngFor="let product of refill.products; let i = index">
      <td class="index">{{ i + 1 }}</td>
      <td class="name">{{ product.name }}, {{ product.type }}</td>
      <td class="quantity">{{ product.quantity }}</td>
      <td class="sum">{{ product.sum }}</td>
    </tr>
  </table>

  <bk-view-line [start]="totalSumCaption" [end]="refill.amount"></bk-view-line>
  <bk-view-line [line]="refill.amountByWords"></bk-view-line>

  <section *ngIf="refill.paidAmount">
    <bk-view-line [start]="totalPaidSumCaption" [end]="refill.paidAmount"></bk-view-line>
    <bk-view-line [line]="refill.paidAmountByWords"></bk-view-line>
  </section>

  <bk-view-line start="&nbsp;" end="&nbsp;"></bk-view-line>

  <bk-view-line [start]="senderSignCaption + ' ____________'" [end]="recipientSignCaption + ' ____________'"></bk-view-line>

  <div class="page-break"></div>
</div>
