import { PRroDocHead } from './p-rro-doc-head.model';
import { PRroSaleTax } from './p-rro-sale-tax.model';
import { PRroZReportPayForm } from './p-rro-z-report-pay-form.model';

export class PRroZReport extends PRroDocHead {
  companyName: string;
  shopName: string;
  shopAddress: string;
  EDRPOU: string;
  IPN: string;
  prroFiscalNumber: number;
  fiscalType: string;

  startedAt: Date;
  finishedAt: Date;

  reportNumber?: number;
  lastDocLocalNumber?: number;
  lastDocFiscalNumber?: string;

  discount: number;

  realizCount: number;
  realizCashSum: number;
  realizCardSum: number;
  realizOtherSum: number;
  realizNoRoundSum: number;
  realizRoundSum: number;

  returnCount: number;
  returnCashSum: number;
  returnCardSum: number;
  returnOtherSum: number;
  returnNoRoundSum: number;
  returnRoundSum: number;

  serviceInput: number;
  serviceOutput: number;

  realizPayments: PRroZReportPayForm[];
  returnPayments: PRroZReportPayForm[];

  realizTaxes: PRroSaleTax[];
  returnTaxes: PRroSaleTax[];

  opened: boolean;

  constructor(date?: Date) {
    super(date);

    this.opened = false;

    this.companyName = '';
    this.shopName = '';
    this.shopAddress = '';
    this.EDRPOU = '';
    this.IPN = '';
    this.fiscalType = '';
    this.taxNumber = '';

    this.realizCount = 0;
    this.realizCashSum = 0;
    this.realizCardSum = 0;
    this.realizOtherSum = 0;
    this.realizNoRoundSum = 0;
    this.realizRoundSum = 0;

    this.returnCount = 0;
    this.returnCashSum = 0;
    this.returnCardSum = 0;
    this.returnOtherSum = 0;
    this.returnNoRoundSum = 0;
    this.returnRoundSum = 0;

    this.serviceInput = 0;
    this.serviceOutput = 0;

    this.cashier = '';

    this.realizPayments = [];
    this.returnPayments = [];

    this.realizTaxes = [];
    this.returnTaxes = [];
  }

  get realizSum(): number {
    return this.realizCashSum + this.realizCardSum + this.realizOtherSum;
  }

  get returnSum(): number {
    return this.returnCashSum + this.returnCardSum + this.returnOtherSum;
  }

  get cashRemain(): number {
    return (
      this.serviceInput +
      this.realizCashSum -
      this.returnCashSum -
      this.serviceOutput
    );
  }
}
