<ion-header>
  <ion-toolbar>
    <ion-title>Чек</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="ion-padding">
    <div id="check" class="content">
      <bk-view-sale [sale]="data.doc" [printShopLogoInReceipt]="data.settings.printShopLogoInReceipt" *ngIf="isSale"></bk-view-sale>
      <bk-view-presale [presale]="data.doc" *ngIf="isPresale"></bk-view-presale>
      <bk-view-waste [waste]="data.doc" *ngIf="isWaste"></bk-view-waste>
      <bk-view-tax-report [report]="data.doc" *ngIf="isReport"></bk-view-tax-report>
      <bk-view-tax-service-doc [serviceDoc]="data.doc" *ngIf="isServiceDoc"></bk-view-tax-service-doc>
      <bk-view-shift-totals [shift]="data.doc" *ngIf="isShift"></bk-view-shift-totals>
      <bk-view-move [move]="data.doc" *ngIf="isMove"></bk-view-move>
      <bk-view-refill [refill]="data.doc" *ngIf="isRefill"></bk-view-refill>
      <bk-view-refill-sale-prices [refill]="data.doc" *ngIf="isRefillSalePrices"></bk-view-refill-sale-prices>
      <bk-view-labels [data]="data" *ngIf="isLabels"></bk-view-labels>
    </div>
  </section>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="two-buttons" slot="start" color="tertiary" (click)="close()">Закрити</ion-button>
    <ion-button class="two-buttons" slot="end" color="primary" (click)="print()">Друк</ion-button>
  </ion-toolbar>
</ion-footer>