import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { ScalesResponse } from '../../settings/scales/response.interface';

import { CachedDataService } from './cached-data.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class DeprecatedScaleService {
  private webSocket: WebSocketSubject<ScalesResponse>;

  constructor(
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
  ) {}

  async getScaleData(autoComplete: boolean = true): Promise<ScalesResponse> {
    this.webSocket = webSocket(
      `ws://127.0.0.1:${this.cachedDataService.getShop().scalePort}`,
    );

    return new Promise<ScalesResponse>((resolve, reject) => {
      this.webSocket.subscribe(
        (message: ScalesResponse) => {
          if (autoComplete) {
            this.webSocket.complete();
          }

          resolve(message);
        },
        (error) => {
          this.toastService.presentWarning(
            'Ваги',
            `Не вдалося отримати масу.\nПеревірте чи запущена й працює програма-посередник`,
            5000,
          );

          resolve(this.getEmtyData());
        },
      );
    });
  }

  complete(): void {
    this.webSocket.complete();
  }

  getEmtyData(): ScalesResponse {
    return { weight: 0, isStable: false, isError: true, error: '' };
  }

  getData(weight: number): ScalesResponse {
    return { weight, isError: false, isStable: true, error: '' };
  }
}
