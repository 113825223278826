<ion-header>
  <ion-toolbar>
    <ion-title>Акцизний товар</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="excise">
    <h2 class="excise__title">{{ saleProduct.product.name }}</h2>
    <h3 class="excise__price">{{ saleProduct.product.price }} грн</h3>

    <div class="excise__data">
      <ion-note>
        {{ decrement ? 'Видаліть або замініть' : 'Відскануйте або введіть'}} 
        штрихкод акцизної марки
        ({{ exciseLabels.length }} / {{ saleProduct.quantity }})
      </ion-note>

      <ion-item>
        <ion-input  type="text"
                    name="label"
                    placeholder="Штрихкод акцизної марки"
                    debounce="250"
                    [(ngModel)]="exciseLabel"
                    [autofocus]="true"
                    [required]="true"
                    (ionFocus)="selectAll()"
                    (ionChange)="convertValue()"
                    (keyup.enter)="finishInput()"></ion-input>
      </ion-item>

      <ion-list lines="none" class="table-data" *ngIf="exciseLabels.length">
        <div class="even-row" *ngFor="let el of exciseLabels">
          <ion-item>
            <ion-icon slot="start" name="barcode-outline"></ion-icon>

            <ion-label>{{ el }}</ion-label>

            <ion-button slot="end" (click)="deleteLabel(el)">
              <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
            </ion-button>
          </ion-item>
        </div>
      </ion-list>
    </div>
  </section>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button slot="start" class="two-buttons" color="tertiary" (click)="cancel()">
      <ion-label>Скасувати</ion-label>
    </ion-button>

    <ion-button slot="end" class="two-buttons" color="primary" (click)="confirm()">
      <ion-label>Підтвердити</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>
