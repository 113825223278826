import { formatDate } from '@angular/common';

import { DEFAULT_ROW_LENGTH } from '../receipt-printer.const';
import {
  ESC_POS_BOLD_OFF,
  ESC_POS_BOLD_ON,
  ESC_POS_NEW_LINE,
  TAX_DATE_FORMAT,
} from '../receipt.const';

export class PrintReceipt {
  rowLength = DEFAULT_ROW_LENGTH;
  isQZMode = true;

  printTime = formatDate(new Date(), TAX_DATE_FORMAT, 'uk_UA');

  // Deprecated
  IP: string;

  addTwoColumnItemToReceipt(
    leftValue: string,
    rightValue: string,
    receipt: string[],
    options: { isBold?: boolean; checkExist?: boolean } = {
      isBold: false,
      checkExist: false,
    },
  ): void {
    if (options.checkExist && rightValue === '') {
      return;
    }

    const rightValueLength = rightValue.length + 1;
    const leftValueLength = this.rowLength - rightValueLength;

    let lastRow = '';

    if (leftValue.length > leftValueLength) {
      const leftValueByRows = this.getTextByRows(leftValue, leftValueLength);
      const leftValueRows = leftValueByRows.split(ESC_POS_NEW_LINE);
      const leftValueLastRow = leftValueRows[leftValueRows.length - 1];
      const formatedWord = leftValueLastRow.padEnd(leftValueLength, ' ');
      const fullLeftValue = leftValueByRows.replace(
        leftValueLastRow,
        formatedWord,
      );

      lastRow += fullLeftValue;
    } else {
      lastRow += leftValue.padEnd(leftValueLength, ' ');
    }

    `${lastRow} ${rightValue}`.split(ESC_POS_NEW_LINE).forEach((row) => {
      receipt.push(...this.alignToLeft(row, options));
    });
  }

  prefix(): string {
    return this.isQZMode ? `` : `[L]`;
  }

  alignToLeft(text: string, options?: { isBold?: boolean }): string[] {
    const textLength = text.trim().length;

    if (textLength === 0) {
      return this.isQZMode ? [``] : ['[L]'];
    }

    if (textLength <= this.rowLength) {
      return [this.alignToLeftText(text, options)];
    }

    const textByRows = this.getTextByRows(text, this.rowLength);
    const textRows = textByRows.split(ESC_POS_NEW_LINE);
    const result: string[] = [];

    textRows.forEach((textRow) => {
      result.push(this.alignToLeftText(textRow, options));
    });

    return result;
  }

  alignToLeftText(text: string, options?: { isBold?: boolean }): string {
    if (this.isQZMode) {
      return options?.isBold
        ? `${ESC_POS_BOLD_ON}${text}${ESC_POS_BOLD_OFF}`
        : `${text}`;
    }

    return options?.isBold ? `[L]<b>${text}</b>` : `[L]${text}`;
  }

  alignToCenter(text: string, options?: { isBold?: boolean }): string[] {
    const textLength = text.trim().length;

    if (textLength === 0) {
      return this.isQZMode ? [``] : ['[C]'];
    }

    if (textLength <= this.rowLength) {
      return [this.alignToCenterText(this.pad(text), options)];
    }

    const textByRows = this.getTextByRows(text, this.rowLength);
    const textRows = textByRows.split(ESC_POS_NEW_LINE);
    const result: string[] = [];

    textRows.forEach((textRow) => {
      result.push(this.alignToCenterText(this.pad(textRow), options));
    });

    return result;
  }

  alignToCenterText(text: string, options?: { isBold?: boolean }): string {
    if (this.isQZMode) {
      return options?.isBold
        ? `${ESC_POS_BOLD_ON}${text}${ESC_POS_BOLD_OFF}`
        : `${text}`;
    }

    return options?.isBold ? `[C]<b>${text}</b>` : `[C]${text}`;
  }

  getTextByRows(text: string, fieldLength: number): string {
    const textWords = text.split(' ');

    let tempRow = '';
    let tempWord = '';

    textWords.forEach((textWord, index) => {
      if (tempWord.length + textWord.length > fieldLength) {
        tempWord = `${tempWord.trim()}${ESC_POS_NEW_LINE}`;
        tempRow += tempWord;
        tempWord = `${textWord} `;

        if (index === textWords.length - 1) {
          tempRow += textWord;
        }
      } else if (index === textWords.length - 1) {
        tempWord += textWord;
        tempRow += tempWord;
      } else {
        tempWord += `${textWord} `;
      }
    });

    return tempRow;
  }

  rowDevider(): string {
    return `${this.prefix()}${Array(this.rowLength).fill('-').join('')}`;
  }

  pad(text: string): string {
    const textLength = text.trim().length;

    return text
      .trim()
      .padStart(Math.floor((this.rowLength - textLength) / 2) + textLength, ' ')
      .padEnd(this.rowLength, ' ');
  }

  formatReceiptTableRow(
    receipt: string[],
    indexValue: string,
    indexLength: number,
    nameValue: string,
    nameLength: number,
    numbersValue: string,
    numbersLength: number,
  ): void {
    const indexValueByRows = this.getTextByRows(indexValue, indexLength);
    const indexValueRows = indexValueByRows.split(ESC_POS_NEW_LINE);

    const nameValueByRows = this.getTextByRows(nameValue, nameLength);
    const nameValueRows = nameValueByRows.split(ESC_POS_NEW_LINE);

    const numbersValueByRows = this.getTextByRows(numbersValue, numbersLength);
    const numbersValueRows = numbersValueByRows.split(ESC_POS_NEW_LINE);

    for (
      let i = 0;
      i < Math.max(1, nameValueRows.length, numbersValueRows.length);
      i += 1
    ) {
      receipt.push(
        `${this.prefix()}${(indexValueRows[i] ?? '').padEnd(
          indexLength,
          ' ',
        )}${(nameValueRows[i] ?? '').padEnd(nameLength, ' ')}${(
          numbersValueRows[i] ?? ''
        ).padStart(numbersLength, ' ')}`,
      );
    }
  }
}
