import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  selector: '[bkMax]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxValidator, multi: true },
  ],
})
export class MaxValidator implements Validator {
  @Input() bkMax: number;

  validate(control: AbstractControl): ValidationErrors | null {
    if ((control.value || control.value === 0) && control.value > this.bkMax) {
      return { max: true };
    }

    return null;
  }
}
