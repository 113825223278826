<ion-header>
  <ion-toolbar *ngIf="!isLandscape">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/shop"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ category?.name }}</ion-title>
  </ion-toolbar>

  <ion-toolbar class="landscape-toolbar" *ngIf="isLandscape">
    <ion-buttons slot="start">
      <ion-button color="medium" fill="outline" (click)="goBackToMenu.emit()">
        <ion-label>Меню</ion-label>
      </ion-button>

      <ion-button color="medium" fill="outline">
        <ion-label>{{ menuCategory.name }}</ion-label>
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button color="medium" fill="outline" (click)="goBackToMenu.emit()">
        <ion-icon name="arrow-back-outline"></ion-icon>
        <ion-label>Назад</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{ 'dark-background': isLandscape }">
  <section class="subategories-grid" *ngIf="isLandscape; else portraitMode">
    <ion-grid *ngIf="subcategoryGroups?.length; else loadingLandscape">
      <ion-row *ngFor="let subcategoryGroup of subcategoryGroups">
        <ion-col *ngFor="let subcategory of subcategoryGroup; let i = index" [size]="(12 / columnsCount).toString()">
          <bk-cover color="secondary" [item]="subcategory" (goToProducts)="go($event)"></bk-cover>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ng-template #loadingLandscape>
      <div class="no-data">
        <span>
          <ion-spinner color="light" *ngIf="!subcategoryGroups; else noDataLandscape"></ion-spinner>
          
          <ng-template #noDataLandscape>
            <ion-text color="light">
              Немає підкатегорій. Зверніться до менеджера чи адміністратора
            </ion-text>
          </ng-template>
        </span>
      </div>
    </ng-template>
  </section>

  <ng-template #portraitMode>
    <ion-list lines="none" *ngIf="subcategories?.length; else loadingPortrait">
      <ion-item *ngFor="let subcategory of subcategories" (click)="go(subcategory)">
        <ion-label>{{ subcategory.name }}</ion-label>
      </ion-item>
    </ion-list>

    <ng-template #loadingPortrait>
      <div class="no-data">
        <span>
          <ion-spinner *ngIf="!subcategories; else noDataPortrait"></ion-spinner>
          
          <ng-template #noDataPortrait>
              Немає підкатегорій. Зверніться до менеджера чи адміністратора
          </ng-template>
        </span>
      </div>
    </ng-template>
  </ng-template>
</ion-content>
