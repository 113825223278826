import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { DateTime } from 'luxon';

import { UA_MONTHS_GENITIVE } from '../../core/constants/date.const';
import { Sale } from '../sale/sale.model';
import { SalesGroup } from '../sales-group.model';
import { SalesService } from '../sales.service';

@Component({
  selector: 'bk-sale-search-dialog',
  templateUrl: './sale-search.dialog.html',
  styleUrls: ['./sale-search.dialog.scss'],
})
export class SaleSearchDialog implements OnInit {
  @ViewChild(IonInput) input: IonInput;

  TAB_FN = 'fn';
  TAB_ID = 'id';
  TAB_DAY = 'day';

  param = this.TAB_FN;
  number = '';
  error = '';

  minDate: string;
  maxDate: string;
  saleDate: Date;

  UA_MONTHS = UA_MONTHS_GENITIVE.map((month) => month.toLowerCase());

  sale: Sale;
  saleGroups: SalesGroup[] = [];

  constructor(
    private modalCtrl: ModalController,
    private salesService: SalesService,
  ) {}

  ngOnInit(): void {
    this.minDate = DateTime.now().minus({ days: 14 }).toISO();
    this.maxDate = DateTime.now().toISO();
  }

  ionViewDidEnter(): void {
    this.setFocusOnInput();
  }

  selectAll(): void {
    this.input.getInputElement().then((data) => {
      data.select();
    });
  }

  changedParam(event: any): void {
    this.param = event.detail.value;

    this.setFocusOnInput();
  }

  async dateTimeChange(): Promise<void> {
    //
  }

  dateTimeCancel(): void {
    //
  }

  toggleGroupSale(group: SalesGroup): void {
    group.collapsed = !group.collapsed;
  }

  async returnSale(sale: Sale, group: SalesGroup): Promise<void> {
    this.sale = sale;

    this.confirm();
  }

  async search(): Promise<void> {
    this.error = '';
    this.saleGroups = [];

    const sales = await this.salesService.findForReturn(
      this.param === this.TAB_FN
        ? { fn: this.number }
        : this.param === this.TAB_ID
        ? { id: this.number }
        : { day: formatDate(this.saleDate, 'yyyy-MM-dd', 'uk_UA') },
    );

    if (sales.length === 1) {
      this.sale = sales[0];

      this.confirm();
    } else if (sales.length > 1) {
      if (this.param === this.TAB_DAY) {
        this.regroupSales(sales);
      } else {
        this.error = 'Знайдено більше 1 чека. Уточніть дані';
      }
    } else {
      this.error = 'Чек не знайдено';
    }
  }

  async confirm(): Promise<void> {
    await this.modalCtrl.dismiss({ sale: this.sale });
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ sale: null });
  }

  private setFocusOnInput(): void {
    setTimeout(() => {
      if (this.input) {
        this.input.setFocus();
      }
    }, 100);
  }

  private regroupSales(sales: Sale[]): void {
    sales.forEach((sale) => {
      if (
        this.saleGroups.find(
          (group) => group.date === formatDate(sale.createdAt, 'HH', 'uk_UA'),
        ) == null
      ) {
        this.saleGroups.push(
          new SalesGroup(formatDate(sale.createdAt, 'HH', 'uk_UA')),
        );
      }
    });

    this.saleGroups.forEach((saleGroup, index) => {
      if (index === 0) {
        this.saleGroups[0].collapsed = false;
      }

      sales.forEach((sale) => {
        if (formatDate(sale.createdAt, 'HH', 'uk_UA') === saleGroup.date) {
          saleGroup.sales.push(sale);
        }
      });

      saleGroup.visibleRows = saleGroup.sales.length;
    });
  }
}
