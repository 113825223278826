import { Injectable } from '@angular/core';

import { StorageTable } from '../../../../core/models/storage-table.model';
import { PrinterConnectionMethod } from '../../printer-connection-method.enum';
import { Printer } from '../../printer.model';
import { LabelPrinterSettings } from '../label-printer-settings.interface';
import {
  DEFAULT_AUTO_PRINT_AFTER_SALE,
  DEFAULT_BARCODE_FORMAT,
  DEFAULT_MARGINS,
  DEFAULT_PAPER_FORMAT,
  DEFAULT_PDF_INSTEAD_PRINT,
} from '../label-printer.const';

const KEY_CONNECTION_METHOD = 'connectionMethod';
const KEY_NAME = 'name';
const KEY_IP = 'ip';
const KEY_MAC = 'mac';
const KEY_PAPER_FORMAT = 'paperFormat';
const KEY_BARCODE_FORMAT = 'barcodeFormat';
const KEY_PDF_INSTEAD_PRINT = 'pdfInsteadPrint';
const KEY_MARGINS = 'margins';
const KEY_AUTO_PRINT_AFTER_SALE = 'autoPrintAfterSale';

@Injectable({
  providedIn: 'root',
})
export class LabelPrinterStorageService {
  private printer: StorageTable;

  constructor() {
    this.printer = new StorageTable('label-printer');
  }

  async clearData(): Promise<void> {
    await this.printer.clear();
  }

  private async getValue<T>(key: string, defaultValue: T): Promise<T> {
    let value = await this.printer.get<T>(key);

    if (value == null) {
      await this.printer.set<T>(key, defaultValue);

      return defaultValue;
    }

    // TODO: Delete after updater up 5.9.92
    if (
      (key === KEY_PAPER_FORMAT ||
        key === KEY_BARCODE_FORMAT ||
        key === KEY_MARGINS) &&
      typeof value === 'string'
    ) {
      value = JSON.parse(value) as T;

      await this.printer.set<T>(key, value);
    }

    return value;
  }

  async getConnectionMethod(): Promise<PrinterConnectionMethod> {
    return this.getValue(KEY_CONNECTION_METHOD, PrinterConnectionMethod.None);
  }

  async getSettings(): Promise<LabelPrinterSettings> {
    const connectionMethod = await this.getConnectionMethod();
    const printerName = await this.getValue(KEY_NAME, '');
    const ip = await this.getValue(KEY_IP, '');
    const mac = await this.getValue(KEY_MAC, '');

    const paperFormat = await this.getValue(
      KEY_PAPER_FORMAT,
      DEFAULT_PAPER_FORMAT,
    );

    const barcodeFormat = await this.getValue(
      KEY_BARCODE_FORMAT,
      DEFAULT_BARCODE_FORMAT,
    );

    const margins = await this.getValue(KEY_MARGINS, DEFAULT_MARGINS);

    const pdfInsteadPrint = await this.getValue(
      KEY_PDF_INSTEAD_PRINT,
      DEFAULT_PDF_INSTEAD_PRINT,
    );

    const autoPrintAfterSale = await this.getValue(
      KEY_AUTO_PRINT_AFTER_SALE,
      DEFAULT_AUTO_PRINT_AFTER_SALE,
    );

    const printer = new Printer(printerName, '');

    return {
      connectionMethod,
      printer,
      ip,
      mac,
      paperFormat,
      barcodeFormat,
      pdfInsteadPrint,
      margins,
      autoPrintAfterSale,
    };
  }

  async setSettings(data: LabelPrinterSettings): Promise<void> {
    await this.printer.set(KEY_CONNECTION_METHOD, data.connectionMethod);
    await this.printer.set(KEY_NAME, data.printer.name);
    await this.printer.set(KEY_IP, data.ip);
    await this.printer.set(KEY_MAC, data.mac);
    await this.printer.set(KEY_PAPER_FORMAT, data.paperFormat);
    await this.printer.set(KEY_BARCODE_FORMAT, data.barcodeFormat);
    await this.printer.set(KEY_PDF_INSTEAD_PRINT, data.pdfInsteadPrint);
    await this.printer.set(KEY_MARGINS, data.margins);
    await this.printer.set(KEY_AUTO_PRINT_AFTER_SALE, data.autoPrintAfterSale);
  }
}
