import { Ingredient } from './ingredient.model';

export class ShiftIngredient {
  id: number;
  shiftId: number;
  ingredientId: number;
  ingredient: Ingredient;
  startRealQuantity?: number | string | null;
  endRealQuantity?: number | string | null;
}
