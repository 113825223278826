import { create } from 'xmlbuilder2';

import { PRroTicket } from './p-rro-ticket.model';

export class ResponseTicket {
  TICKET: PRroTicket;

  constructor(response: string) {
    const ticket = create({ parser: { attribute: () => undefined } }, response)
      .first()
      .toObject() as unknown as ResponseTicket;

    this.TICKET = new PRroTicket();

    this.TICKET.UID = ticket.TICKET.UID;
    this.TICKET.ORDERDATE = ticket.TICKET.ORDERDATE;
    this.TICKET.ORDERTIME = ticket.TICKET.ORDERTIME;
    this.TICKET.ORDERNUM = Number(ticket.TICKET.ORDERNUM);

    if (ticket.TICKET.ORDERTAXNUM) {
      this.TICKET.ORDERTAXNUM = ticket.TICKET.ORDERTAXNUM;
    }

    if (ticket.TICKET.OFFLINESESSIONID) {
      this.TICKET.OFFLINESESSIONID = Number(ticket.TICKET.OFFLINESESSIONID);
    }

    if (ticket.TICKET.OFFLINESEED) {
      this.TICKET.OFFLINESEED = Number(ticket.TICKET.OFFLINESEED);
    }

    if (ticket.TICKET.ERRORCODE) {
      this.TICKET.ERRORCODE = Number(ticket.TICKET.ERRORCODE);
    }

    if (ticket.TICKET.ERRORTEXT) {
      this.TICKET.ERRORTEXT = ticket.TICKET.ERRORTEXT;
    }

    if (ticket.TICKET.VER) {
      this.TICKET.VER = Number(ticket.TICKET.VER);
    }
  }
}
