import { Injectable } from '@angular/core';

import { Measurement } from '../../../../core/enum/measurement.enum';
import { CachedDataService } from '../../../../core/services/cached-data.service';
import { ToastService } from '../../../../core/services/toast.service';
import { Presale } from '../../../../presales/presale/presale.model';
import { Sale } from '../../../../sales/sale/sale.model';
import { Product } from '../../../../shop/products/product.model';
import { ProductsService } from '../../../../shop/products/products.service';
import { Refill } from '../../../../warehouse/invoices/refill/refill.model';
import { LabelPrinterData } from '../label-printer-data.model';
import { Label } from '../label.enum';

import { LabelPrinterStorageService } from './label-printer-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LabelPrinterFormatService {
  constructor(
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
    private productsService: ProductsService,
    private labelPrinterStorageService: LabelPrinterStorageService,
  ) {}

  async formatPresale(presale: Presale): Promise<LabelPrinterData | null> {
    const localProducts = await this.productsService.localProducts();
    const products: Product[] = [];

    presale.presaleProducts.forEach((presaleProduct) => {
      this.setMissingData(
        presaleProduct.product,
        presaleProduct.productId,
        localProducts,
      );

      presaleProduct.product.labelsCount = presaleProduct.product.weightProduct
        ? 1
        : presaleProduct.quantity;

      if (presaleProduct.product.printLabel) {
        products.push(presaleProduct.product);
      }
    });

    if (this.noData(products)) {
      return null;
    }

    return this.formatLabels(Label.Sale, products);
  }

  async formatSale(sale: Sale): Promise<LabelPrinterData | null> {
    const localProducts = await this.productsService.localProducts();
    const products: Product[] = [];

    sale.saleProducts.forEach((saleProduct) => {
      if (saleProduct.product.price == null) {
        saleProduct.product.price = saleProduct.fullPrice;
      }

      this.setMissingData(
        saleProduct.product,
        saleProduct.productId,
        localProducts,
      );

      saleProduct.product.labelsCount = saleProduct.product.weightProduct
        ? 1
        : saleProduct.quantity;

      if (saleProduct.product.printLabel) {
        products.push(saleProduct.product);
      }
    });

    if (this.noData(products)) {
      return null;
    }

    return this.formatLabels(Label.Sale, products);
  }

  async formatRefill(refill: Refill): Promise<LabelPrinterData | null> {
    const localProducts = await this.productsService.localProducts();
    const products: Product[] = [];

    refill.refillIngredients.forEach((refillIngredient) => {
      const product = new Product();

      product.name = refillIngredient.ingredient.name;
      product.amount = refillIngredient.ingredient.measurement;
      product.barcode = refillIngredient.ingredient.barcode;
      product.price = refillIngredient.salePrice ?? 0;

      this.setMissingData(
        product,
        refillIngredient.productId ?? 0,
        localProducts,
      );

      product.labelsCount =
        refillIngredient.ingredient.measurement !== Measurement.Unit
          ? 1
          : refillIngredient.quantity;

      if (product.printLabel) {
        products.push(product);
      }
    });

    if (this.noData(products)) {
      return null;
    }

    return this.formatLabels(Label.Product, products);
  }

  async formatProduct(product: Product): Promise<LabelPrinterData | null> {
    const products: Product[] = [product];

    if (this.noData(products)) {
      return null;
    }

    return this.formatLabels(Label.Product, products);
  }

  private setMissingData(
    product: Product,
    productId: number,
    localProducts: Product[],
  ): void {
    if (
      product.barcode == null ||
      product.price == null ||
      product.printLabel == null
    ) {
      const localProduct = localProducts.find((lp) => lp.id === productId);

      if (localProduct != null) {
        product.barcode = localProduct.barcode;
        product.printLabel = localProduct.printLabel;

        if (product.price == null) {
          product.price = localProduct.price;
        }
      }
    }
  }

  private async formatLabels(
    model: Label,
    products: Product[],
  ): Promise<LabelPrinterData> {
    const productLabels: Product[] = [];

    for (const product of products) {
      for (let i = 0; i < (product.labelsCount ?? 0); i += 1) {
        productLabels.push(product);
      }
    }

    const data = new LabelPrinterData(model, productLabels);

    data.shop = this.cachedDataService.getShop();
    data.settings = await this.labelPrinterStorageService.getSettings();

    return data;
  }

  private noData(products: Product[]): boolean {
    if (products.length === 0) {
      this.toastService.presentWarning(
        'Друк етикеток',
        'Немає даних для друку етикеток',
        750,
      );

      return true;
    }

    return false;
  }
}
