import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Shift } from '../../../core/models/shift.model';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { UtilsService } from '../../../core/services/utils.service';
import { PRroZReport } from '../../../p-rro/fsco/models/p-rro-z-report.model';
import { Presale } from '../../../presales/presale/presale.model';
import { Sale } from '../../../sales/sale/sale.model';
import { Transaction } from '../../../transactions/transaction/models/transaction.model';
import { Move } from '../../../warehouse/invoices/move/move.model';
import { Refill } from '../../../warehouse/invoices/refill/refill.model';
import { Waste } from '../../../warehouse/invoices/waste/waste.model';
import { PrinterConnectionMethod } from '../printer-connection-method.enum';
import { VIEW_COMPONENT_ID } from '../printer.const';
import { QzTrayService } from '../qz-tray/qz-tray.service';
import { ViewDialog } from '../view/view.dialog';

import { ReceiptPrinterData } from './receipt-printer-data.model';
import { ReceiptPrinterSettings } from './receipt-printer-settings.interface';
import { PRINTER_CLASS, PRINTER_URL } from './receipt-printer.const';
import { ReceiptPrinterFormatService } from './services/receipt-printer-format.service';
import { ReceiptPrinterPrintService } from './services/receipt-printer-print.service';
import { ReceiptPrinterStorageService } from './services/receipt-printer-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ReceiptPrinterService {
  private isAndroidApp: boolean;
  private isDesktop: boolean;

  private connectionMethod = PrinterConnectionMethod.Browser;

  constructor(
    private modalCtrl: ModalController,
    private utilsService: UtilsService,
    private receiptPrinterStorageService: ReceiptPrinterStorageService,
    private receiptPrinterFormatService: ReceiptPrinterFormatService,
    private receiptPrinterPrintService: ReceiptPrinterPrintService,
    private qzTrayService: QzTrayService,
    private cachedDataService: CachedDataService,
  ) {
    this.isAndroidApp = this.utilsService.isAndroidApp();
    this.isDesktop = this.utilsService.isDesktop();
  }

  async status(): Promise<{
    isPrinterAvailable: boolean;
    isAutoPrintAfterSale: boolean;
  }> {
    const shop = this.cachedDataService.getShop();
    const settings = await this.getSettings();

    this.connectionMethod = settings.connectionMethod;

    return {
      isPrinterAvailable:
        (this.isDesktop &&
          (this.connectionMethod === PrinterConnectionMethod.QZTray ||
            this.connectionMethod === PrinterConnectionMethod.Browser)) ||
        (this.isAndroidApp &&
          (this.connectionMethod === PrinterConnectionMethod.Bluetooth ||
            this.connectionMethod === PrinterConnectionMethod.USB ||
            this.connectionMethod === PrinterConnectionMethod.WiFi)),
      isAutoPrintAfterSale:
        settings.autoPrintAfterSale || Boolean(shop?.printCheck),
    };
  }

  async getConnectionMethod(): Promise<PrinterConnectionMethod> {
    return this.receiptPrinterStorageService.getConnectionMethod();
  }

  async getSettings(): Promise<ReceiptPrinterSettings> {
    return this.receiptPrinterStorageService.getSettings();
  }

  async setSettings(data: ReceiptPrinterSettings): Promise<void> {
    this.connectionMethod = data.connectionMethod;

    await this.receiptPrinterStorageService.setSettings(data);
  }

  async init(): Promise<void> {
    const settings = await this.getSettings();

    this.connectionMethod = settings.connectionMethod;

    switch (this.connectionMethod) {
      case PrinterConnectionMethod.QZTray:
        await this.connectQzTray();
        break;

      case PrinterConnectionMethod.Bluetooth:
      case PrinterConnectionMethod.USB:
      case PrinterConnectionMethod.WiFi:
        break;

      default:
        break;
    }
  }

  async connectQzTray(): Promise<void> {
    await this.qzTrayService.startConnection();

    const settings = await this.getSettings();

    await this.qzTrayService.printerExist(settings.printer.name, {
      url: PRINTER_URL,
      printerClass: PRINTER_CLASS,
    });
  }

  async disconnectQzTray(): Promise<void> {
    await this.qzTrayService.endConnection();
  }

  updateShopIP(ip: string): void {
    const shop = this.cachedDataService.getShop();

    shop.printerIP = ip;

    this.cachedDataService.setShop(shop);
  }

  async openCashDrawer(): Promise<void> {
    const data = await this.receiptPrinterFormatService.cashDrawer();

    if (!data.settings.useCashDrawer) {
      return;
    }

    this.receiptPrinterPrintService.openCashDrawer(data);
  }

  async printPresale(
    presale: Presale,
    options: { viewMode?: boolean; silentPrint?: boolean } = {
      viewMode: false,
    },
  ): Promise<void> {
    const data = await this.receiptPrinterFormatService.presale(presale);

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.receiptPrinterPrintService.print(data);
    }
  }

  async printSale(
    sale: Sale,
    options: { viewMode?: boolean; silentPrint?: boolean } = {
      viewMode: false,
      silentPrint: false,
    },
  ): Promise<void> {
    const data = await this.receiptPrinterFormatService.sale(sale);

    if (options.silentPrint != null) {
      data.silentPrint = options.silentPrint;
    }

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.receiptPrinterPrintService.print(data);
    }
  }

  async printWaste(
    waste: Waste,
    options: { viewMode?: boolean; salePriceMode: boolean } = {
      viewMode: false,
      salePriceMode: false,
    },
  ): Promise<void> {
    const data = await this.receiptPrinterFormatService.waste(waste, options);

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.receiptPrinterPrintService.print(data);
    }
  }

  async printMove(
    move: Move,
    options: { viewMode?: boolean } = {
      viewMode: false,
    },
  ): Promise<void> {
    const data = await this.receiptPrinterFormatService.move(move);

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.receiptPrinterPrintService.print(data);
    }
  }

  async printRefill(
    refill: Refill,
    options: { viewMode?: boolean } = {
      viewMode: false,
    },
  ): Promise<void> {
    const data = await this.receiptPrinterFormatService.refill(refill);

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.receiptPrinterPrintService.print(data);
    }
  }

  async printRefillSalePrices(
    refill: Refill,
    options: { viewMode?: boolean } = {
      viewMode: false,
    },
  ): Promise<void> {
    const data = await this.receiptPrinterFormatService.refillSalePrices(
      refill,
    );

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.receiptPrinterPrintService.print(data);
    }
  }

  async printTaxReport(
    report: PRroZReport,
    options: { viewMode?: boolean } = {
      viewMode: false,
    },
  ): Promise<void> {
    const data = await this.receiptPrinterFormatService.taxReport(report);

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.receiptPrinterPrintService.print(data);
    }
  }

  async printShiftTotals(
    shift: Shift,
    options: { viewMode?: boolean } = {
      viewMode: false,
    },
  ): Promise<void> {
    const data = await this.receiptPrinterFormatService.shiftTotals(shift);

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.receiptPrinterPrintService.print(data);
    }
  }

  async printTaxServiceDoc(
    transaction: Transaction,
    options: { viewMode?: boolean } = {
      viewMode: false,
    },
  ): Promise<void> {
    const data = await this.receiptPrinterFormatService.taxServiceDoc(
      transaction,
    );

    if (
      this.connectionMethod === PrinterConnectionMethod.Browser ||
      Boolean(options.viewMode)
    ) {
      await this.openDialog(data);
    } else {
      this.receiptPrinterPrintService.print(data);
    }
  }

  private async openDialog(data: ReceiptPrinterData): Promise<void> {
    const status = await this.status();

    const modal = await this.modalCtrl.create({
      component: ViewDialog,
      componentProps: {
        data,
        isPrinterAvailable: status.isPrinterAvailable,
      },
      backdropDismiss: false,
      id: `${VIEW_COMPONENT_ID}_${data.type.toString()}`,
    });

    await modal.present();
  }
}
