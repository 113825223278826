<div class="log">
  <ion-content>
    <ion-textarea [autoGrow]="true" [value]="data" [readonly]="true"></ion-textarea>
  </ion-content>

  <ion-toolbar>
    <ion-button slot="start" icon-only fill="outline" color="medium" (click)="copyToClipboard()">
      <ion-icon name="copy-outline"></ion-icon>
    </ion-button>

    <ion-button slot="end" icon-only fill="outline" color="medium" (click)="sendByEmail()">
      <ion-icon name="mail-outline"></ion-icon>
    </ion-button>

    <ion-button slot="end" icon-only fill="outline" color="medium" (click)="sendByRequest()">
      <ion-icon name="send-outline"></ion-icon>
    </ion-button>
  </ion-toolbar>
</div>
