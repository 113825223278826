import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'bk-delivery-dialog',
  templateUrl: './delivery.dialog.html',
  styleUrls: ['./delivery.dialog.scss'],
})
export class DeliveryDialog implements OnInit {
  phone = '';
  locality = '';
  street = '';
  house = '';
  apartment = '';
  floor = '';
  entrance = '';
  lock = '';
  comment = '';

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    //
  }

  changedLock(event: any): void {
    this.lock = event.detail.value;
  }

  async save(): Promise<void> {
    let comment = '';

    if (this.phone) {
      comment += `${this.phone}`;
    }

    if (this.locality) {
      comment += `${this.delimiter(comment)}${this.locality}`;
    }

    if (this.street) {
      comment += `${this.delimiter(comment)}вул. ${this.street}`;
    }

    if (this.house) {
      comment += `${this.delimiter(comment)}буд. ${this.house}`;
    }

    if (this.apartment) {
      comment += `${this.delimiter(comment)}кв. ${this.apartment}`;
    }

    let options = '';

    if (this.floor) {
      options += `${this.delimiter(options)}пов. ${this.floor}`;
    }

    if (this.entrance) {
      options += `${this.delimiter(options)}під. ${this.entrance}`;
    }

    if (this.lock) {
      options += `${this.delimiter(options)}${this.lock.toLowerCase()}`;
    }

    if (options) {
      comment += `${(this.delimiter(comment), ' ')}(${options})`;
    }

    if (this.comment) {
      comment += `${this.delimiter(comment, '.')}${this.comment}`;
    }

    await this.modalCtrl.dismiss({ comment });
  }

  private delimiter(text: string, symbol: string = ','): string {
    return text ? `${symbol} ` : '';
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ comment: '' });
  }
}
