/**
 * Тип документа "Чек"
 */
export enum CheckDocumentType {
  /**
   * Чек реалізації товарів / послуг
   */
  SaleGoods = 0,

  /**
   * Чек переказу коштів
   */
  TransferFunds = 1,

  /**
   * Чек операції обміну валюти
   */
  CurrencyExchange = 2,

  /**
   * Чек видачі готівки
   */
  CashWithdrawal = 3,

  /**
   * Відкриття зміни
   */
  OpenShift = 100,

  /**
   * Закриття зміни
   */
  CloseShift = 101,

  /**
   * Початок офлайн сесії
   */
  OfflineBegin = 102,

  /**
   * Завершення офлайн сесії
   */
  OfflineEnd = 103,
}
