import { ClipboardModule } from '@angular/cdk/clipboard';
import { registerLocaleData } from '@angular/common';
import localeUk from '@angular/common/locales/uk';
import { LOCALE_ID, NgModule } from '@angular/core';
import {
  BrowserModule,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgxPrinterModule } from 'ngx-printer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ClientScreenModule } from './client-screen/client-screen.module';
import { CustomHammerConfig } from './core/abstract/custom-hammer-config';
import { CoreModule } from './core/core.module';
import { ToastService } from './core/services/toast.service';
import { PRroModule } from './p-rro/p-rro.module';
import { PresalesModule } from './presales/presales.module';
import { RevisionModule } from './revision/revision.module';
import { SalesModule } from './sales/sales.module';
import { SettingsModule } from './settings/settings.module';
import { SettingService } from './settings/settings.service';
import { SharedModule } from './shared/shared.module';
import { ShopModule } from './shop/shop.module';
import { StatisticsModule } from './statistics/statistics.module';
import { TransactionsModule } from './transactions/transactions.module';
import { SuppliesModule } from './warehouse/supplies/supplies.module';

registerLocaleData(localeUk);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,

    BrowserModule,
    BrowserAnimationsModule,

    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'cashbox',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),

    HammerModule,
    ClipboardModule,

    NgxPrinterModule.forRoot({
      printOpenWindow: true,
      renderClass: 'ngx-printer',
    }),

    CoreModule,
    SharedModule,

    AuthModule,
    ShopModule,
    RevisionModule,
    SalesModule,
    PresalesModule,
    TransactionsModule,
    SuppliesModule,
    SettingsModule,
    StatisticsModule,
    ClientScreenModule,
    PRroModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'uk_UA' },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },

    File,
    Market,
    WebView,
    SettingService,
    BluetoothSerial,
    ScreenOrientation,
    ToastService,
    AppAvailability,
    WebIntent,
    SocialSharing,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
