/**
 * Клас документа
 */
export enum DocumentClass {
  /**
   * Чек
   */
  Check = 0,

  /**
   * Z-звіт
   */
  ZRep = 1,
}
