import { PrivatbankJsonMethod } from '../enums/method.enum';

import { PrivatbankJsonBaseRequest } from './base-request.model';

/**
 * Операція "Скасування"
 *
 * Призначена для анулювання транзакції в рамках поточного пакету транзакцій (операційного дня).
 * Доступ до транзакції, що анулюється, здійснюється за номером чека.
 */
export class PrivatbankJsonWithdrawalRequest extends PrivatbankJsonBaseRequest {
  /**
   * Масив додаткових параметрів для терміналу
   */
  params: {
    /**
     * Номер чека
     */
    invoiceNumber: string;
  };

  constructor(invoiceNumber: string) {
    super(PrivatbankJsonMethod.Withdrawal);

    this.params = {
      invoiceNumber,
    };
  }
}
