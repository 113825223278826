export class PrintProductItem {
  name: string;
  uktzed: string;
  exciseLabel: string;
  price: string;
  quantity: string;
  sum: string;
  type: string;
  productPrice?: string;
  costPrice?: string;
  salePrice?: string;
  costSum?: string;
  saleSum?: string;

  // Sale
  quantityAndPrice?: string;
  uktzedRow?: string;
  exciseLabelRow?: string;

  // RefillSalePrices
  priceVector?: string;
}
