import {
  MOVE_HEADER_NAME_CAPTION,
  MOVE_HEADER_QUANTITY_CAPTION,
  MOVE_HEADER_SUM_CAPTION,
  MOVE_NUMBER_CAPTION,
  MOVE_RECIPIENT_NAME_CAPTION,
  MOVE_RECIPIENT_SIGN_CAPTION,
  MOVE_SENDER_NAME_CAPTION,
  MOVE_SENDER_SIGN_CAPTION,
  MOVE_TOTAL_SUM_CAPTION,
} from '../receipt.const';

import { PrintProductItem } from './print-product-item.model';
import { PrintReceipt } from './print-receipt.model';

export class PrintMove extends PrintReceipt {
  date: string;
  senderName: string;
  recipientName: string;
  sum: string;
  products: PrintProductItem[];

  receipt(): string[] {
    const receipt: string[] = [];

    receipt.push(...this.alignToLeft(`${MOVE_NUMBER_CAPTION} ${this.date}`));
    receipt.push(this.rowDevider());

    receipt.push(
      ...this.alignToLeft(`${MOVE_SENDER_NAME_CAPTION}: ${this.senderName}`),
    );

    receipt.push(
      ...this.alignToLeft(
        `${MOVE_RECIPIENT_NAME_CAPTION}: ${this.recipientName}`,
      ),
    );

    const indexLength = 3;
    const quantityLength = 6;
    const sumLength = 8;

    const numbersLength = quantityLength + sumLength;
    const nameLength = this.rowLength - numbersLength - indexLength;

    const headerIndexRow1 = '#'.padEnd(indexLength, ' ');
    const headerNameRow1 = MOVE_HEADER_NAME_CAPTION.padEnd(nameLength, ' ');

    const headerQuantityRow1 = MOVE_HEADER_QUANTITY_CAPTION.padStart(
      quantityLength,
      ' ',
    );

    const headerSumRow1 = MOVE_HEADER_SUM_CAPTION.padStart(sumLength, ' ');

    receipt.push(this.rowDevider());

    receipt.push(
      `${this.prefix()}${headerIndexRow1}${headerNameRow1}${headerQuantityRow1}${headerSumRow1}`,
    );

    receipt.push(this.rowDevider());

    this.products.forEach((product, index) => {
      const indexValue = `${(index + 1).toString().padEnd(indexLength, ' ')}`;
      const nameValue = `${product.name}, ${product.type}`;
      const numbersValue = `${product.quantity.padStart(
        quantityLength,
        ' ',
      )}${product.sum.padStart(sumLength, ' ')}`;

      this.formatReceiptTableRow(
        receipt,
        indexValue,
        indexLength,
        nameValue,
        nameLength,
        numbersValue,
        numbersLength,
      );
    });

    receipt.push(this.rowDevider());

    this.addTwoColumnItemToReceipt(
      `${MOVE_TOTAL_SUM_CAPTION}`,
      this.sum,
      receipt,
    );

    receipt.push(`${this.prefix()}`);

    this.addTwoColumnItemToReceipt(
      `${MOVE_SENDER_SIGN_CAPTION} ${''.padEnd(
        (this.rowLength - 2) / 2 - MOVE_SENDER_SIGN_CAPTION.length - 1,
        '_',
      )}`,
      `${MOVE_RECIPIENT_SIGN_CAPTION} ${''.padEnd(
        (this.rowLength - 2) / 2 - MOVE_RECIPIENT_SIGN_CAPTION.length - 1,
        '_',
      )}`,
      receipt,
    );

    return receipt;
  }
}
