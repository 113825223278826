import { Move } from './move/move.model';
import { Order } from './order/order.model';
import { Refill } from './refill/refill.model';
import { Waste } from './waste/waste.model';

export class InvoicesGroup {
  date: string;
  invoices: Refill[] | Waste[] | Move[] | Order[];

  constructor(date: string) {
    this.date = date;
    this.invoices = [];
  }
}
