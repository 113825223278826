import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { UtilsService } from '../../../core/services/utils.service';
import { CryptDataURLFile } from '../../fsco/crypt-data-url-file.interface';
import { ShopCryptData } from '../../types/shop-crypt-data.model';

import { JksRole } from './jks-role.interface';

@Component({
  selector: 'bk-crypt-settings-dialog',
  templateUrl: './crypt-settings.dialog.html',
  styleUrls: ['./crypt-settings.dialog.scss'],
})
export class CryptSettingsDialog implements OnInit {
  @Input() cryptData: ShopCryptData;

  roles: JksRole[] = [
    { name: 'Не вказано', value: '' },
    { name: 'Печатка', value: 'stamp' },
    { name: 'Підпис', value: 'personal' },
  ];

  KEY_JKS = 'jks';
  KEY_DAT = 'dat';

  key: CryptDataURLFile;
  cert: CryptDataURLFile;

  isJks = true;
  isJksRole = false;

  acsk = '';
  role = '';
  password = '';

  hidePassword = true;
  isAndroidApp = false;

  get acceptedKeysFiles(): string {
    return this.isAndroidApp ? '' : this.isJks ? '.jks' : '.dat, .ZS2';
  }

  get acceptedCertsFiles(): string {
    return this.isAndroidApp ? '' : '.cer, .crt';
  }

  constructor(
    private modalCtrl: ModalController,
    private utilsService: UtilsService,
  ) {
    this.isAndroidApp = this.utilsService.isAndroidApp();
  }

  ngOnInit(): void {
    this.key = this.cryptData.key;
    this.cert = this.cryptData.cert;
    this.isJks = this.cryptData.isJks;
    this.role = this.cryptData.role;
    this.password = this.cryptData.password;
    this.acsk = this.isJks ? this.KEY_JKS : this.KEY_DAT;
    this.isJksRole = this.role !== '';
  }

  loadKey(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (!file) {
      return;
    }

    // todo: add .ext check

    reader.onload = (e: any) => {
      this.key = {
        name: file.name,
        dataURL: e.target.result,
      };
    };

    reader.readAsDataURL(file);
  }

  loadCert(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (!file) {
      return;
    }

    // todo: add .ext check

    reader.onload = (e: any) => {
      this.cert = {
        name: file.name,
        dataURL: e.target.result,
      };
    };

    reader.readAsDataURL(file);
  }

  changedACSK(event: any): void {
    this.acsk = event.detail.value;
    this.isJks = this.acsk === this.KEY_JKS;
  }

  togglePassword(event: MouseEvent): void {
    event.stopPropagation();

    this.hidePassword = !this.hidePassword;
  }

  async save(): Promise<void> {
    if (this.cryptData) {
      this.cryptData.key = this.key;

      if (this.isJks) {
        this.cryptData.cert = { name: '', dataURL: '' };
        this.cryptData.role = this.isJksRole ? this.role : '';
      } else {
        this.cryptData.cert = this.cert;
        this.cryptData.role = '';
      }

      this.cryptData.isJks = this.isJks;
      this.cryptData.password = this.password;
    }

    await this.modalCtrl.dismiss({ isSuccess: true });
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ isSuccess: false });
  }
}
