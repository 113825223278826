<div class="start" [ngClass]="{ 'bold': bold }" *ngIf="line;">
  {{ line }}
</div>

<div class="center" [ngClass]="{ 'bold': bold }" *ngIf="center">
  {{ center }}
</div>

<div [ngClass]="{ 'start': !dashed, 'start-dashed': dashed, 'bold': bold }" *ngIf="start && end">
  {{ start }}

  <span class="end">
    {{ end }}
  </span>
</div>
