<ion-grid *ngIf="categoryGroups?.length; else loading">
  <ion-row *ngFor="let group of categoryGroups">
    <ion-col *ngFor="let category of group; let i = index" [size]="(12 / columnsCount).toString()">
      <ion-button *ngIf="!isLandscape; else card"
                  color="tertiary"
                  expand="block"
                  (click)="go(category)"
                  [disabled]="disabled">
        {{ category.name }}
      </ion-button>

      <ng-template #card>
        <bk-cover color="tertiary"
                  [disabled]="disabled"
                  [item]="category"
                  (goToSubcategories)="go($event)"></bk-cover>
      </ng-template>
    </ion-col>
  </ion-row>
</ion-grid>

<ng-template #loading>
  <div class="no-data">
    <span>
      <ion-spinner color="light" *ngIf="!categoryGroups; else noData"></ion-spinner>
      
      <ng-template #noData>
        <ion-text color="light">
          Немає категорій. Зверніться до менеджера чи адміністратора
        </ion-text>
      </ng-template>
    </span>
  </div>
</ng-template>
