<div id="labels" class="content" *ngIf="data.type === label.Sale">
  <div *ngFor="let label of data.products; let i = index" [id]="'label_' + i"
        class="label"
        [ngClass]="{ 
          'w40-h25': is40x25(),
          'w60-h40': is60x40(),
          'page-break': i < data.products.length - 1
        }">

    <div class="shop-logo" *ngIf="data.shop.checkLogoPath">
      <img [src]="data.shop.checkLogoPath">
    </div>

    <div class="name">
      <span>{{ label.name }}</span>
    </div>

    <div class="last-row">
      <span class="date">{{ date() }}</span>
    </div>
  </div>
</div>

<div id="labels" class="content" *ngIf="data.type === label.Product">
  <div *ngFor="let label of data.products; let i = index" [id]="'label_' + i"
        class="label"
        [ngClass]="{ 
          'w40-h25': is40x25(),
          'w60-h40': is60x40(),
          'page-break': i < data.products.length - 1
        }">

    <div class="shop-name" *ngIf="is60x40()">
      <span>{{ data.shop.name }}</span>
    </div>

    <div class="name">
      <span>{{ label.name }}</span>
    </div>

    <div class="barcode">
      <svg [id]="'barcode_' + i"></svg>
    </div>

    <div class="last-row">
      <span class="price">{{ label.price | number: '1.2-2' }} грн</span>
    </div>
  </div>
</div>
