import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { CashlessPaymentDialog } from './cashless-payment/cashless-payment.dialog';
import { CategoriesComponent } from './categories/categories.component';
import { ExciseProductDialog } from './excise-product/excise-product.dialog';
import { LandscapeMenuComponent } from './landscape-menu/landscape-menu.component';
import { ProductPriceDialog } from './product-price/product-price.dialog';
import { BarcodeDialog } from './products/barcode-dialog/barcode.dialog';
import { ProductsComponent } from './products/products.component';
import { DeliveryDialog } from './sale-calculation/delivery/delivery.dialog';
import { SaleCalculationDialog } from './sale-calculation/sale-calculation.dialog';
import { ShopComponent } from './shop.component';
import { SubcategoriesComponent } from './subcategories/subcategories.component';
import { WeightProductComponent } from './weight-product/weight-product';

@NgModule({
  imports: [SharedModule],
  declarations: [
    ShopComponent,
    CategoriesComponent,
    ProductsComponent,
    SaleCalculationDialog,
    SubcategoriesComponent,
    WeightProductComponent,
    ExciseProductDialog,
    LandscapeMenuComponent,
    DeliveryDialog,
    CashlessPaymentDialog,
    BarcodeDialog,
    ProductPriceDialog,
  ],
})
export class ShopModule {}
