import round from 'lodash-es/round';

import { SaleTax } from '../../../sales/sale/sale-tax.model';

export class PRroSaleTax {
  /**
   * Код виду податку/збору
   */
  type: number;

  /**
   * Найменування виду податку/збору
   */
  name: string;

  /**
   * Літерне позначення виду і ставки податку/збору
   */
  letter: string;

  /**
   * Відсоток податку/збору
   */
  prc: number;

  /**
   * Ознака податку/збору, не включеного у вартість
   */
  sign: boolean;

  /**
   * Сума обсягів для розрахування податку/збору
   */
  turnover: number;

  /**
   * Сума обсягів для розрахування податку/збору з урахуванням знижки
   */
  turnoverDiscount: number;

  /**
   * Вихідна сума для розрахування податку/збору
   */
  sourceSum: number;

  /**
   * Сума податку/збору
   */
  sum: number;

  constructor(saleTax?: SaleTax) {
    if (saleTax == null) {
      return;
    }

    this.type = saleTax.type;
    this.name = saleTax.name;
    this.letter = saleTax.letter;
    this.prc = saleTax.percent;
    this.turnover = round(Math.abs(saleTax.totalAmount), 2);
    this.turnoverDiscount = round(
      Math.abs(saleTax.totalAmount - saleTax.totalDiscount),
      2,
    );

    this.sourceSum = round(Math.abs(saleTax.sourceAmount), 2);
    this.sum = round(Math.abs(saleTax.taxAmount), 2);
  }
}
