import { PrivatbankJsonMethod } from '../enums/method.enum';

import { PrivatbankJsonBaseRequest } from './base-request.model';

/**
 * Операція "Оплата"
 *
 * Основна операція терміналу, що призначена для оплати товару
 */
export class PrivatbankJsonPurchaseRequest extends PrivatbankJsonBaseRequest {
  /**
   * Масив додаткових параметрів для терміналу
   */
  params: {
    /**
     * Cума транзакції, що виконується
     *
     * У полі Amount допускається розділення суми (грн/коп) лише через знаки "." або ","
     */
    amount: string;

    /**
     * Додаткова сума (знижка)
     */
    discount: string;

    /**
     * Індекс мерчанта, який використовується для транзакції
     */
    merchantId: string;

    /**
     * Параметр, що запускає з каси оплату через FacePay24
     * (для Android терміналів з підтримкою даного сервісу)
     */
    facepay: string;
  };

  constructor(amount: number, merchantId: number = 1) {
    super(PrivatbankJsonMethod.Purchase);

    this.params = {
      amount: amount.toFixed(2),
      discount: '',
      merchantId: merchantId.toString(),
      facepay: 'false',
    };
  }
}
