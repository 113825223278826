import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { CalendarModal, CalendarResult } from 'ion2-calendar';

import { Shift } from '../core/models/shift.model';
import { ShiftsService } from '../core/services/resources/shifts.service';

import { IStatisticsProduct } from './statistics-product.interface';
import { IStatisticsReport } from './statistics-report.interface';
import { StatisticsTab } from './statistics-tab.enum';
import { StatisticsService } from './statistics.service';

const STATISTIC_ROWS = 20;

@Component({
  selector: 'bk-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  totals: IStatisticsReport[];
  saleProducts: IStatisticsProduct[];
  shift: Shift;
  displayedFrom: Date;
  displayedTo: Date;

  visibleRows = STATISTIC_ROWS;

  private fromDate: string;
  private toDate: string;

  statisticsTab = StatisticsTab;
  activeTab = StatisticsTab.Report;

  tabs = [
    {
      caption: 'Звіт',
      name: StatisticsTab.Report,
    },
    {
      caption: 'Товари та послуги',
      name: StatisticsTab.Products,
    },
    {
      caption: 'Сировина',
      name: StatisticsTab.Ingredients,
    },
  ];

  constructor(
    private modalCtrl: ModalController,
    private statisticService: StatisticsService,
    private shiftsService: ShiftsService,
  ) {
    this.shift = this.shiftsService.getCurrentShift();
    this.displayedFrom = this.shift.createdAt;
    this.displayedTo = new Date();
    this.activeTab = StatisticsTab.Report;
  }

  ionViewWillEnter(): void {
    this.fromDate = formatDate(this.shift.createdAt, 'yyyy-MM-dd', 'uk-UA');
    this.toDate = formatDate(new Date(), 'yyyy-MM-dd', 'uk-UA');

    this.getStatistic();
  }

  changedTab(event: any): void {
    this.activeTab = event.detail.value;

    this.getStatistic();
  }

  loadData(event: any): void {
    setTimeout(() => {
      this.visibleRows += STATISTIC_ROWS;

      event.target.complete();

      if (this.saleProducts.length <= this.visibleRows) {
        event.target.disabled = true;
      }
    }, 300);
  }

  async openCalendar(): Promise<void> {
    const options = {
      pickMode: 'range',
      title: 'Виберіть період',
      closeIcon: true,
      doneIcon: true,
      cssClass: 'statistic-calendar',
      weekStart: 0,
      weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'],
      monthFormat: 'MMMM YYYY',
      from: this.shift.createdAt.getTime(),
      to: new Date().getTime(),
      defaultDateRange: {
        from: new Date(this.fromDate),
        to: new Date(this.toDate),
      },
    };

    const filterCalendar = await this.modalCtrl.create({
      component: CalendarModal,
      componentProps: { options },
      backdropDismiss: false,
    });

    filterCalendar.present();

    const event: any = await filterCalendar.onDidDismiss();

    if (event.role === 'done') {
      const dateRange = event.data;
      const dateFrom: CalendarResult = dateRange.from;
      const dateTo: CalendarResult = dateRange.to;

      this.fromDate = `${dateFrom.years}-${dateFrom.months}-${dateFrom.date}`;
      this.toDate = `${dateTo.years}-${dateTo.months}-${dateTo.date}`;

      this.getStatistic();

      this.displayedFrom = new Date(dateRange.from.time);
      this.displayedTo = new Date(dateRange.to.time);
    }
  }

  private getStatistic(): void {
    this.visibleRows = STATISTIC_ROWS;

    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = false;
    }

    switch (this.activeTab) {
      case StatisticsTab.Report:
        this.statisticService
          .getStatisticsReport(this.fromDate, this.toDate)
          .subscribe((data: IStatisticsReport[]) => {
            this.totals = data;
          });

        break;

      case StatisticsTab.Products:
        this.statisticService
          .getProductsStatistics(this.fromDate, this.toDate)
          .subscribe((data: IStatisticsProduct[]) => {
            this.saleProducts = data;
          });

        break;

      case StatisticsTab.Ingredients:
        this.statisticService
          .getIngredientsStatistics(this.fromDate, this.toDate)
          .subscribe((data: IStatisticsProduct[]) => {
            this.saleProducts = data;
          });

        break;

      default:
        break;
    }
  }
}
