export enum EleksPayForm {
  /**
   * Готівка
   */
  Cash = 0,

  /**
   * Кредит
   */
  Credit = 1,

  /**
   * Чек
   */
  Check = 2,

  /**
   * Банківська картка
   */
  Card = 3,
}
