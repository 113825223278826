<ion-content>
  <div class="ion-padding ion-text-center login-logo-container">
    <img class="login-logo" src="assets/images/logo.png">
  </div>

  <form #loginForm="ngForm"
        class="login-form">
    <ion-list>
      <ion-item>
        <ion-label position="floating">Логін</ion-label>
        <ion-input type="text"
                   name="username"
                   autocomplete="off"
                   [required]="true"
                   [(ngModel)]="username"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Пароль</ion-label>

        <ion-input [type]="pass ? 'password' : 'text'"
                   name="password"
                   autocomplete="off"
                   [required]="true"
                   [(ngModel)]="password"
                   (keyup.enter)="login()"></ion-input>

        <ion-icon slot="end" 
                  class="toggle-password"
                  [name]="pass ? 'eye-off-outline' : 'eye-outline'" 
                  (click)="togglePassword($event)"></ion-icon>
      </ion-item>
    </ion-list>

    <div class="ion-padding">
      <ion-button expand="block"
                  [disabled]="!!loginForm.invalid"
                  (click)="login()">
        Увійти
      </ion-button>
    </div>
  </form>
</ion-content>
