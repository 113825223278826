import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../core/services/auth.service';
import { CachedDataService } from '../core/services/cached-data.service';

import { IStatisticsIngredient } from './statistics-ingredient.interface';
import { IStatisticsProduct } from './statistics-product.interface';
import { IStatisticsReport } from './statistics-report.interface';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(
    private http: HttpClient,
    private cachedDataService: CachedDataService,
    private authService: AuthService,
  ) {}

  getStatisticsReport(
    fromDate: string,
    toDate: string,
  ): Observable<IStatisticsReport[]> {
    let params = new HttpParams();

    params = params.append(
      'shopId',
      this.cachedDataService.getShopId().toString(),
    );

    params = params.append('fromDate', fromDate);
    params = params.append('toDate', toDate);

    return this.http.get<IStatisticsReport[]>('/statistics/app/report', {
      params,
    });
  }

  getProductsStatistics(
    fromDate: string,
    toDate: string,
  ): Observable<IStatisticsProduct[]> {
    const params = this.getParams(fromDate, toDate);

    return this.http.get<IStatisticsProduct[]>('/statistics/products', {
      params,
    });
  }

  getIngredientsStatistics(
    fromDate: string,
    toDate: string,
  ): Observable<IStatisticsProduct[]> {
    const params = this.getParams(fromDate, toDate);

    return this.http
      .get<IStatisticsIngredient[]>('/statistics/ingredients/sale', { params })
      .pipe(map((ingredients) => this.transformFromBackend(ingredients)));
  }

  private getParams(fromDate: string, toDate: string): HttpParams {
    let params = new HttpParams();

    params = params.append(
      'shopId',
      this.cachedDataService.getShopId().toString(),
    );

    params = params.append('userId', this.authService.getUserId().toString());
    params = params.append('fromDate', fromDate);
    params = params.append('toDate', toDate);
    params = params.append('isMobile', 'true');

    return params;
  }

  private transformFromBackend(
    ingredients: IStatisticsIngredient[],
  ): IStatisticsProduct[] {
    const ingredientsStatistic: IStatisticsProduct[] = [];

    ingredients.forEach((ingredient) => {
      ingredientsStatistic.push({
        revenue: ingredient.sum,
        salesProductsQuantity: ingredient.quantity,
        product: ingredient.name,
        productAmount: ingredient.measurement,
      });
    });

    return ingredientsStatistic;
  }
}
