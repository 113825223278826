<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="settings/logs"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ key }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="data">
    <bk-log-view [name]="key" [data]="value" [isLogPage]="true"></bk-log-view>
  </div>
</ion-content>
