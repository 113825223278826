import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Shop } from '../../../core/models/shop.model';
import { PRro } from '../../p-rro.model';

@Component({
  selector: 'bk-select-p-rro-dialog',
  templateUrl: './select-p-rro.dialog.html',
  styleUrls: ['./select-p-rro.dialog.scss'],
})
export class SelectPRroDialog implements OnInit {
  @Input() shop: Shop;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    //
  }

  async closeModal(prro: PRro): Promise<void> {
    await this.modalCtrl.dismiss(prro);
  }
}
