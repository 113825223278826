<div class="check" *ngIf="presale">
  <bk-view-line [line]="createdAtCaption + ' ' + presale.createdAt"></bk-view-line>
  <bk-view-line [start]="completeByCaption" [end]="presale.completeBy" [bold]="true"></bk-view-line>

  <table class="products">
    <tr class="header">
      <th class="index">№</th>
      <th class="name">{{ nameCaption }}</th>
      <th class="quantity">{{ quantityCaption }}</th>
    </tr>

    <ng-container *ngFor="let product of presale.products; let i = index">
      <tr class="product">
        <td class="index">{{ i + 1 }}</td>
        <td class="name">{{ product.name }}</td>
        <td class="quantity">{{ product.quantity }}</td>
      </tr>

      <tr class="product" *ngIf="product.exciseLabelRow">
        <td class="index"></td>
        <td class="name">{{ product.exciseLabelRow }}</td>
        <td class="quantity"></td>
      </tr>
    </ng-container>
  </table>

  <section *ngIf="presale.comment">
    <hr>
    <bk-view-line [line]="commentCaption + ':'" [bold]="true"></bk-view-line>
    <bk-view-line [line]="presale.comment"></bk-view-line>
  </section>

  <section *ngIf="presale.clientName || presale.clientPhone">
    <hr>
    <bk-view-line [line]="clientCaption + ':'" [bold]="true"></bk-view-line>
    <bk-view-line [start]="presale.clientName" [end]="presale.clientPhone"></bk-view-line>
  </section>

  <div class="page-break"></div>
</div>
