<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="settings"></ion-back-button>
    </ion-buttons>

    <ion-title>Друк етикеток</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form #printerForm="ngForm">
    <ion-list [inset]="true" lines="full" class="settings">
      <bk-printer [settings]="settings"
                  [connectionMethods]="connectionMethods"
                  (updated)="updateSettings()"></bk-printer>

      <ion-item *ngIf="settings.connectionMethod !== printerConnectionMethod.None">
        <ion-icon slot="start" name="resize-outline" color="dark"></ion-icon>
        <ion-label>Розміри етикетки</ion-label>
        <ion-select id="paperFormat"
                    name="paperFormat"
                    okText="Вибрати" 
                    cancelText="Скасувати"
                    [(ngModel)]="settings.paperFormat"
                    (ngModelChange)="setSettings()">
          <ion-select-option *ngFor="let paperFormatOption of paperFormats" [value]="paperFormatOption">
            {{ paperFormatOption.width }} мм х {{ paperFormatOption.height }} мм
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item *ngIf="settings.connectionMethod !== printerConnectionMethod.None">
        <ion-icon slot="start" name="barcode-outline" color="dark"></ion-icon>
        <ion-label>Формат штрихкоду</ion-label>
        <ion-select id="barcodeFormat"
                    name="barcodeFormat"
                    okText="Вибрати" 
                    cancelText="Скасувати"
                    [(ngModel)]="settings.barcodeFormat"
                    (ngModelChange)="setSettings()">
          <ion-select-option *ngFor="let barcodeFormatOption of barcodeFormats" [value]="barcodeFormatOption">
            {{ barcodeFormatOption.title }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item *ngIf="settings.connectionMethod === printerConnectionMethod.QZTray">
        <ion-icon slot="start" name="options-outline" color="dark"></ion-icon>
        <ion-label>Додаткові налаштування</ion-label>
        <ion-toggle slot="end" color="dark" name="additionOptions" [(ngModel)]="additionOptions"></ion-toggle>
      </ion-item>
    </ion-list>

    <ion-list [inset]="true" lines="full" class="settings" *ngIf="additionOptions && settings.connectionMethod === printerConnectionMethod.QZTray">
      <ion-list-header>
        <ion-label>Додаткові налаштування</ion-label>
      </ion-list-header>

      <ion-item>
        <ion-icon slot="start" name="ticket-outline" color="dark"></ion-icon>
        <ion-label>Автоматичний друк після продажу</ion-label>
        <ion-toggle slot="end" color="dark" name="autoPrintAfterSale"
                    [(ngModel)]="settings.autoPrintAfterSale"
                    (ngModelChange)="updateSettings()"></ion-toggle>
      </ion-item>

      <ion-item>
        <ion-icon slot="start" name="document-outline" color="dark"></ion-icon>
        <ion-label>Друк у PDF файл</ion-label>
        <ion-toggle slot="end" color="dark" name="pdfInsteadPrint"
                    [(ngModel)]="settings.pdfInsteadPrint"
                    (ngModelChange)="setSettings()"></ion-toggle>
      </ion-item>

      <ion-item-group>
        <ion-item-divider>
          <ion-label>Межі друку</ion-label>
        </ion-item-divider>

        <ion-item>
          <ion-icon slot="start" name="expand-outline" color="dark"></ion-icon>

          <div class="margins">
            <ion-item class="margins__item">
              <ion-label  position="floating">Верхня, мм</ion-label>
              <ion-input  type="number"
                          name="marginTop"
                          maxlength="2"
                          [min]="-MAX_MARGIN"
                          [bkMin]="-MAX_MARGIN"
                          [max]="MAX_MARGIN"
                          [bkMax]="MAX_MARGIN"
                          [(ngModel)]="settings.margins.top"
                          (ngModelChange)="setSettings()"
                          required></ion-input>
            </ion-item>

            <ion-item class="margins__item">
              <ion-label  position="floating">Права, мм</ion-label>
              <ion-input  type="number"
                          name="marginRight"
                          maxlength="2"
                          [min]="-MAX_MARGIN"
                          [bkMin]="-MAX_MARGIN"
                          [max]="MAX_MARGIN"
                          [bkMax]="MAX_MARGIN"
                          [(ngModel)]="settings.margins.right"
                          (ngModelChange)="setSettings()"
                          required></ion-input>
            </ion-item>

            <ion-item class="margins__item">
              <ion-label  position="floating">Нижня, мм</ion-label>
              <ion-input  type="number"
                          name="marginBottom"
                          maxlength="2"
                          [min]="-MAX_MARGIN"
                          [bkMin]="-MAX_MARGIN"
                          [max]="MAX_MARGIN"
                          [bkMax]="MAX_MARGIN"
                          [(ngModel)]="settings.margins.bottom"
                          (ngModelChange)="setSettings()"
                          required></ion-input>
            </ion-item>

            <ion-item class="margins__item">
              <ion-label  position="floating">Ліва, мм</ion-label>
              <ion-input  type="number"
                          name="marginLeft"
                          maxlength="2"
                          [min]="-MAX_MARGIN"
                          [bkMin]="-MAX_MARGIN"
                          [max]="MAX_MARGIN"
                          [bkMax]="MAX_MARGIN"
                          [(ngModel)]="settings.margins.left"
                          (ngModelChange)="setSettings()"
                          required></ion-input>
            </ion-item>

            <ion-item></ion-item>
          </div>
        </ion-item>
      </ion-item-group>
    </ion-list>

    <bk-qz-tray *ngIf="settings.connectionMethod === printerConnectionMethod.QZTray"></bk-qz-tray>
  </form>
</ion-content>
