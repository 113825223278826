/**
 * Тип даних запита документа
 */
export enum DocumentRequestType {
  /**
   * Перевірка наявності документа
   */
  Availability = 0,

  /**
   * Оригінальний XML
   */
  OriginalXml = 1,

  /**
   * XML засвідчений КЕП Фіскального Сервера
   */
  SignedByServerXml = 2,

  /**
   * Документ в текстовому форматі для відображення (UTF-8)
   */
  Visualization = 3,

  /**
   * XML засвідчений КЕП відправника
   */
  SignedBySenderXml = 4,

  /**
   * XML засвідчений КЕП відправника і КЕП Фіскального Сервера
   */
  SignedBySenderAndServerXml = 5,
}
