import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Resource } from '../../abstract/resource';
import { Provider } from '../../models/provider.model';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProvidersService extends Resource<Provider> {
  private providers: BehaviorSubject<Provider[]>;

  constructor(protected http: HttpClient, private authService: AuthService) {
    super(http, {
      path: '/provider',
    });
  }

  getProviders(): Observable<Provider[]> {
    if (this.providers != null) {
      return this.providers;
    }

    return this.find({ companyId: this.authService.getCompanyId() }).pipe(
      tap((providers) => (this.providers = new BehaviorSubject(providers))),
    );
  }
}
