import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import round from 'lodash-es/round';
import { DateTime } from 'luxon';

import { DATETIME_FORMAT, UA_MONTHS } from '../../core/constants/date.const';
import {
  TRANSACTIONS_REFRESH,
  TRANSACTIONS_SHIFT,
  TRANSACTIONS_Z_REPORT,
} from '../../core/constants/events.const';
import {
  MAX_CURRENCY_SUM_VALUE,
  MAX_STRING_LENGTH,
  MIN_CURRENCY_VALUE_NOT_ZERO,
  MIN_STRING_LENGTH,
  ONE_KOP,
} from '../../core/constants/form-validations.const';
import { Shift } from '../../core/models/shift.model';
import { User } from '../../core/models/user.model';
import { CachedDataService } from '../../core/services/cached-data.service';
import { Events } from '../../core/services/events.service';
import { LoadingService } from '../../core/services/loading.service';
import { UserService } from '../../core/services/resources/user.service';
import { ToastService } from '../../core/services/toast.service';
import { PRroZReport } from '../../p-rro/fsco/models/p-rro-z-report.model';
import { FinanceExpensesService } from '../finance-expanses.service';
import { TransactionsService } from '../transactions.service';

import { IFinanceCategory } from './interfaces/finance-category.interface';
import { IFinanceSubcategory } from './interfaces/finance-subcategory.interface';
import { Deposit } from './models/deposit.model';
import { FinanceExpense } from './models/finance-expense.model';
import { Incasation } from './models/incasation.model';
import { Transaction } from './models/transaction.model';
import { TransactionType } from './transaction-type.enum';

const LOADING_ID = 'transaction.component';

@Component({
  selector: 'bk-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
})
export class TransactionComponent implements OnInit {
  @ViewChild(IonInput) input: IonInput;

  @Input() type: TransactionType;

  user: User;
  shift: Shift;
  zReport: PRroZReport;

  isPRroActive = false;
  transaction = TransactionType;

  incasation = new Incasation();
  deposit = new Deposit();
  financeExpense = new FinanceExpense();

  employees: User[] = [];

  financeCategories: IFinanceCategory[] = [];
  financeSubcategory: IFinanceSubcategory | null = null;

  financeExpenseEmployee: User | null = null;
  financeExpenseSalaryDate: string;
  financeExpenseSalaryPreviousMonth: string;
  financeExpenseSalaryMaxDate: string;

  isSalary = false;

  MIN_CURRENCY_SUM_VALUE = MIN_CURRENCY_VALUE_NOT_ZERO;
  MAX_CURRENCY_SUM_VALUE = MAX_CURRENCY_SUM_VALUE;
  MIN_STRING_LENGTH = MIN_STRING_LENGTH;
  MAX_STRING_LENGTH = MAX_STRING_LENGTH;
  MONTHS = UA_MONTHS;

  private requestInProgress = false;

  constructor(
    private events: Events,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private cachedDataService: CachedDataService,
    private userService: UserService,
    private transactionsService: TransactionsService,
    private financeExpensesService: FinanceExpensesService,
  ) {
    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });

    this.financeExpenseSalaryMaxDate = DateTime.now().toISO();
    this.financeExpenseSalaryDate = DateTime.now().toISO();
    this.financeExpenseSalaryPreviousMonth = DateTime.now()
      .minus({ month: 1 })
      .toISO();
  }

  ngOnInit(): void {
    this.isPRroActive = this.cachedDataService.isPRroActive();

    this.events.subscribe(TRANSACTIONS_SHIFT, (shift) => {
      this.shift = shift;

      this.updateIncasationSum();
    });

    this.events.subscribe(TRANSACTIONS_Z_REPORT, (prroZReport) => {
      this.zReport = prroZReport;
    });

    this.userService.findAllEmployee().subscribe((employees) => {
      this.employees = employees;
    });

    this.financeExpensesService
      .findCategories()
      .subscribe((financeExpensesCategories) => {
        this.financeCategories = financeExpensesCategories;
      });
  }

  private updateIncasationSum(): void {
    if (!this.user?.showCash) {
      return;
    }

    this.incasation.money = this.incasation.cashless
      ? this.shift.totals.salesCardSummary - this.shift.totals.cardIncasations
      : this.shift.totals.cashRemain;
  }

  selectAll(): void {
    this.input.getInputElement().then((data) => {
      data.select();
    });
  }

  toggleIncasationType(): void {
    this.updateIncasationSum();
  }

  financeExpenseSubcategoryChange(): void {
    const financeExpenseCategory = this.financeCategories.find(
      (fc) => fc.id === this.financeSubcategory?.categoryId,
    );

    this.isSalary =
      financeExpenseCategory != null && financeExpenseCategory.isSalary;

    this.financeExpense.comment = '';
  }

  setFinanceExpenseSalaryComment(): void {
    this.financeExpense.comment = `Виплата заробітної плати працівнику ${
      this.financeExpenseEmployee?.name ?? '__________'
    } за ${DateTime.fromISO(this.financeExpenseSalaryDate).toFormat(
      'MM.yyyy',
    )}, дата: ${DateTime.now().toFormat(DATETIME_FORMAT)}`;
  }

  async save(): Promise<void> {
    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    let doc: Transaction | null = null;

    switch (this.type) {
      case TransactionType.Incasation:
        this.incasation.money = Number(this.incasation.money);
        this.incasation.amount = this.incasation.money;

        if (this.wrongIncasationData()) {
          return;
        }

        doc = await this.transactionsService.create(this.incasation);

        break;

      case TransactionType.Deposit:
        this.deposit.deposit = Number(this.deposit.deposit);
        this.deposit.amount = this.deposit.deposit;

        doc = await this.transactionsService.create(this.deposit);

        break;

      case TransactionType.FinanceExpense:
        this.financeExpense.price = Number(this.financeExpense.price);
        this.financeExpense.amount = this.financeExpense.price;

        if (this.wrongFinanceExpenseData()) {
          return;
        }

        if (this.financeSubcategory != null) {
          this.financeExpense.subCategoryId = this.financeSubcategory.id;
        }

        if (this.isSalary && this.financeExpenseEmployee != null) {
          this.financeExpense.salaryEmployeeId = this.financeExpenseEmployee.id;
          this.financeExpense.salaryDate = DateTime.fromISO(
            this.financeExpenseSalaryDate,
          ).toSQLDate();
        }

        doc = await this.transactionsService.create(this.financeExpense);

        break;

      default:
        break;
    }

    if (doc != null) {
      this.incasation = new Incasation();
      this.deposit = new Deposit();
      this.financeExpense = new FinanceExpense();

      if (this.type === TransactionType.FinanceExpense) {
        this.financeExpenseEmployee = null;
      }

      this.events.publish(TRANSACTIONS_REFRESH);
    }

    this.loadingService.dismiss(LOADING_ID);

    this.requestInProgress = false;
  }

  private wrongIncasationData(): boolean {
    if (
      this.incasation.cashless &&
      round(this.incasation.amount - this.shift.totals.salesCardSummary, 2) >=
        ONE_KOP
    ) {
      this.showDataWarningAlert('Недостатньо коштів оплачених карткою');

      return true;
    }

    if (
      !this.incasation.cashless &&
      round(this.incasation.amount - this.shift.totals.cashRemain, 2) >= ONE_KOP
    ) {
      this.showDataWarningAlert('Недостатньо коштів у касі');

      return true;
    }

    return false;
  }

  private wrongFinanceExpenseData(): boolean {
    if (this.financeSubcategory?.id == null) {
      this.showDataWarningAlert('Не вказана категорія витрат');

      return true;
    }

    if (this.isSalary && this.financeExpenseEmployee?.id == null) {
      this.showDataWarningAlert('Не вибраний працівник');

      return true;
    }

    if (
      round(this.financeExpense.amount - this.shift.totals.cashRemain, 2) >=
      ONE_KOP
    ) {
      this.showDataWarningAlert('Недостатньо коштів у касі');

      return true;
    }

    return false;
  }

  private showDataWarningAlert(message: string): void {
    this.toastService.presentWarning('Неправильно заповнені дані', message);

    this.requestInProgress = false;
  }
}
