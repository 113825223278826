<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/shop"></ion-back-button>
    </ion-buttons>

    <ion-title>Налаштування</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list [inset]="true" lines="full">
    <ion-list-header>
      <ion-label>Обладнання</ion-label>
    </ion-list-header>

    <ion-item button detail routerLink="barcode-scanner">
      <ion-icon slot="start" name="barcode-outline" color="tertiary"></ion-icon>
      <ion-label>Сканер штрихкодів</ion-label>
    </ion-item>

    <ion-item button detail routerLink="receipt-printer" *ngIf="isDesktop || isAndroidApp">
      <ion-icon slot="start" name="print-outline" color="dark"></ion-icon>
      <ion-label>Друк чеків</ion-label>
    </ion-item>

    <ion-item button detail routerLink="label-printer" *ngIf="isDesktop">
      <ion-icon slot="start" name="ticket-outline" color="dark"></ion-icon>
      <ion-label>Друк етикеток</ion-label>
    </ion-item>

    <ion-item button detail routerLink="terminals">
      <ion-icon slot="start" name="card-outline" color="secondary"></ion-icon>
      <ion-label>Платіжний термінал</ion-label>
    </ion-item>

    <ion-item button detail routerLink="scales" *ngIf="isDesktop || isAndroidApp">
      <ion-icon slot="start" name="scale-outline" color="dark"></ion-icon>
      <ion-label>Ваги</ion-label>
    </ion-item>

    <ion-item button detail routerLink="second-screen" *ngIf="isDesktop && shop.clientScreen">
      <ion-icon slot="start" name="desktop-outline" color="dark"></ion-icon>
      <ion-label>Другий екран</ion-label>
    </ion-item>

    <ion-item button detail routerLink="integrations" *ngIf="isDesktop">
      <ion-icon slot="start" name="git-compare-outline" color="dark"></ion-icon>
      <ion-label>Інтеграції</ion-label>
    </ion-item>
  </ion-list>

  <ion-list [inset]="true" lines="full">
    <ion-list-header>
      <ion-label>Інтерфейс та операції</ion-label>
    </ion-list-header>

    <ion-item>
      <bk-icon slot="start" name="speedometer" color="primary"></bk-icon>
      <ion-label>"Швидкі" кнопки продажу</ion-label>
      <ion-toggle slot="end" color="primary" [ngModel]="userSettings.quickSaleButtonsMode" (ngModelChange)="toggleQuickSaleButtons()"></ion-toggle>
    </ion-item>

    <ion-item *ngIf="shop.prroManualControl">
      <ion-icon slot="start" name="qr-code-outline" color="primary"></ion-icon>
      <ion-label>Фіскалізація "швидкими кнопками"</ion-label>
      <ion-toggle slot="end" color="primary" [ngModel]="userSettings.fiscalByDefaultMode" (ngModelChange)="toggleFiscalByDefault()"></ion-toggle>
    </ion-item>

    <ion-item>
      <bk-icon slot="start" name="list" color="tertiary"></bk-icon>
      <ion-label>Перелік товарів списком</ion-label>
      <ion-toggle slot="end" color="tertiary" [ngModel]="userSettings.productsAsListMode" (ngModelChange)="toggleProductsAsList()"></ion-toggle>
    </ion-item>

    <ion-item>
      <ion-icon slot="start" name="swap-vertical-outline" color="tertiary"></ion-icon>
      <ion-label>Округлювати суму "робочого столу"</ion-label>
      <ion-toggle slot="end" color="tertiary" [ngModel]="userSettings.roundInvoiceTotalSum" (ngModelChange)="toggleRoundInvoiceTotalSum()"></ion-toggle>
    </ion-item>

    <ion-item>
      <ion-icon slot="start" name="moon-outline" color="dark"></ion-icon>
      <ion-label>Темна тема</ion-label>
      <ion-toggle slot="end" color="dark" [ngModel]="userSettings.darkThemeMode" (ngModelChange)="toggleDarkTheme()"></ion-toggle>
    </ion-item>

    <ion-item *ngIf="isDesktop">
      <ion-icon slot="start" name="albums-outline" color="dark"></ion-icon>
      <ion-label>Контроль вікон</ion-label>
      <ion-toggle slot="end" color="dark" [ngModel]="userSettings.tabControl" (ngModelChange)="toggleTabControl()"></ion-toggle>
    </ion-item>
  </ion-list>

  <ion-list [inset]="true" lines="full">
    <ion-list-header>
      <ion-label>Сервісні операції</ion-label>
    </ion-list-header>

    <ion-item button detail="false" (click)="openMarket()" *ngIf="!isDesktop">
      <ion-icon slot="start" color="medium" [name]="isIos ? 'logo-apple-appstore' : 'logo-google-playstore'"></ion-icon>
      <ion-label>Знайти у {{ isIos ? 'App Store' : 'Play Маркет' }}</ion-label>
    </ion-item>

    <ion-item button detail="false" (click)="clearSyncSales()">
      <ion-icon slot="start" name="trash-outline" color="medium"></ion-icon>
      <ion-label>Видалити дані для синхронізації</ion-label>
    </ion-item>

    <ion-item button detail routerLink="logs">
      <ion-icon slot="start" name="terminal-outline" color="medium"></ion-icon>
      <ion-label>Логи</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
