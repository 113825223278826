import { Clipboard } from '@angular/cdk/clipboard';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Platform } from '@ionic/angular';

import { FULL_DATETIME_FORMAT } from '../../core/constants/date.const';
import { StorageTable } from '../../core/models/storage-table.model';
import { CachedDataService } from '../../core/services/cached-data.service';
import { ToastService } from '../../core/services/toast.service';
import { SettingService } from '../settings.service';

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  private log: StorageTable;

  constructor(
    protected http: HttpClient,
    private clipboard: Clipboard,
    private platform: Platform,
    private device: Device,
    private cachedDataService: CachedDataService,
    private settingService: SettingService,
    private toastService: ToastService,
  ) {
    this.log = new StorageTable('log');
  }

  async clear(): Promise<void> {
    await this.log.clear();
  }

  async remove(key: string): Promise<void> {
    await this.log.remove(key);
  }

  async add(
    key: string,
    value: string,
    options: { addTimestamp: boolean } = { addTimestamp: false },
  ): Promise<void> {
    const fullKey = `${key}_${formatDate(new Date(), 'yyyy.MM.dd', 'uk-UA')}`;
    const data = await this.log.get<string[]>(fullKey);

    const message = options.addTimestamp
      ? `${formatDate(new Date(), 'HH:mm:ss', 'uk-UA')} ${value}`
      : value;

    if (data == null) {
      await this.log.set<string[]>(fullKey, [message]);
    } else {
      data.push(message);

      await this.log.set<string[]>(fullKey, data);
    }
  }

  async keys(key?: string): Promise<string[]> {
    return this.log.keys(key);
  }

  async data(key: string): Promise<string[]> {
    const keys = await this.log.keys(key);
    const values = [];

    for (const k of keys) {
      const value = await this.log.get<string[] | null>(k);

      if (value != null) {
        values.push(...value);
      }
    }

    return values;
  }

  async clearOutdated(): Promise<void> {
    const keys = await this.log.keys();

    for (const key of keys) {
      const date = Date.parse(key.split('_')[1]);

      if (Date.now() - date > 7 * 24 * 60 * 60 * 1000) {
        await this.log.remove(key);
      }
    }
  }

  send(
    name: string,
    data: string,
    options: { auto?: boolean; useEmail?: boolean; isDesktop?: boolean } = {
      auto: false,
      useEmail: false,
      isDesktop: false,
    },
  ): void {
    const appSettings = this.cachedDataService.getAppSettings();
    const shop = this.cachedDataService.getShop();
    const user = this.cachedDataService.getUser();
    const shift = this.cachedDataService.getShift();
    const prro = this.cachedDataService.getPRRO();

    const platformInfo = `${this.platform.width()}x${this.platform.height()}${
      this.platform.isPortrait() ? ' portrait' : ''
    }, (${this.platform.platforms().join(', ')})`;

    let osInfo = 'WebOS';
    let deviceInfo = `${platformInfo}`;

    if (this.platform.is('cordova')) {
      osInfo = `${this.device.platform} ${this.device.version}`;
      deviceInfo = `${this.device.manufacturer} ${this.device.model}, ${platformInfo}`;
    }

    const info = {
      date: new Date(),
      shopId: shop?.id,
      shopName: shop?.name,
      userId: user?.id,
      userName: user?.name,
      shiftId: shift?.id,
      prroId: prro?.id,
      sessionId: this.cachedDataService.getSessionId(),
      version: this.settingService.getVersionInfo(),
      os: osInfo,
      device: deviceInfo,
      browser: this.getBrowserName(),
      url: this.platform.url(),
      title: name,
    };

    if (options.useEmail ?? false) {
      const time = formatDate(new Date(), FULL_DATETIME_FORMAT, 'uk-UA');
      const subject = `${shop.name}: ${time} ${info.sessionId} ${
        shop?.id ?? ''
      } ${name}`;

      const body = `${JSON.stringify(info, null, 2)}\n\n${data}`.replace(
        /<br>/g,
        '\n',
      );

      let link = `mailto:${
        appSettings.supportEmail
      }?subject=${subject}&body=${encodeURIComponent(body)}`;

      if (link.length > 1995) {
        this.clipboard.copy(body);
        this.toastService.presentError(
          'Логи',
          `Текст листа занадто довгий (${link.length}), тому буде скопійований у буфер обміну`,
          10000,
        );

        const hint = options.isDesktop
          ? ` - натисніть по черзі комбінації клавіш (Ctrl + англійська літера): Ctrl+A і Ctrl-V\n
              або\n
              - виділіть цей текст мишою,
              - натисніть праву кнопку й оберіть "Вставити"`
          : ` - натисність й затримайте на 2-3 секунди палець на вільному місці екрану
              - виберіть "Вставити" у меню, яке з'явиться`;

        link = `mailto:${
          appSettings.supportEmail
        }?subject=${subject}&body=${encodeURIComponent(
          `Текст листа був занадто довгим, тому одразу не підставився і був скопійований у буфер обміну\n
          ** Будь ласка, вставте текст з буфера обміну **\n
          ${hint}`,
        )}`;
      }

      window.location.href = link;
    } else {
      this.http
        .put(
          '/settings/log',
          {
            ...info,
            text: data.replace(/<br>/g, '\n'),
          },
          {
            params: { auto: (options.auto ?? false).toString() },
          },
        )
        .toPromise()
        .then(() => {
          if (!options.auto) {
            this.toastService.present('Дані відправлено');
          }
        })
        .catch(() => {
          //
        });
    }
  }

  private getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();

    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Microsoft Edge';

      case agent.indexOf('edg') > -1:
        return 'Chromium-based Edge';

      case agent.indexOf('opr') > -1:
        return 'Opera';

      case agent.indexOf('chrome') > -1:
        return 'Chrome';

      case agent.indexOf('trident') > -1:
        return 'Internet Explorer';

      case agent.indexOf('firefox') > -1:
        return 'Firefox';

      case agent.indexOf('safari') > -1:
        return 'Safari';

      default:
        return 'Other';
    }
  }
}
