import { PrivatbankJsonMethod } from '../enums/method.enum';

import { PrivatbankJsonBaseRequest } from './base-request.model';

/**
 * Операція "Загальний звiт"
 *
 * Операція призначена для ініціювання та відправлення підсумкових сум на хост
 * для звірки з даними, що є на хості.
 *
 * Термінал може виконати звіряння підсумків по черзі за всіма мерчантами,
 * або по кожному окремо – залежно від наявності поля Merchant ID.
 */
export class PrivatbankJsonVerifyRequest extends PrivatbankJsonBaseRequest {
  /**
   * Масив додаткових параметрів для терміналу
   */
  params: {
    /**
     * Індекс мерчанта, який використовується для транзакції
     */
    merchantId: string;
  };

  constructor(merchantId: number = 1) {
    super(PrivatbankJsonMethod.Verify);

    this.params = {
      merchantId: merchantId.toString(),
    };
  }
}
