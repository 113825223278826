<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="settings"></ion-back-button>
    </ion-buttons>

    <ion-title>Сканер штрихкодів</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list [inset]="true" lines="full" class="settings">
    <ion-item>
      <ion-icon slot="start" name="git-branch-outline" color="dark"></ion-icon>
      <ion-label>USB-сканер</ion-label>
      <ion-toggle slot="end" color="dark" [ngModel]="userSettings.keyboardMode" (ngModelChange)="toggleMode()"></ion-toggle>
    </ion-item>

    <section *ngIf="!userSettings.keyboardMode && isAndroidApp">
      <ion-item>
        <ion-icon slot="start" name="bluetooth-outline" color="tertiary"></ion-icon>
        <ion-label>Статус Bluetooth</ion-label>
        <ion-toggle slot="end" color="tertiary" [ngModel]="isBluetoothEnabled" (ngModelChange)="toggleBluetoothState()"></ion-toggle>
      </ion-item>

      <section *ngIf="isBluetoothEnabled">
        <ion-list-header>
          <ion-label>{{ currentDevice ? 'Обраний пристрій' : 'Оберіть пристрій зі списку доступних' }}</ion-label>
        </ion-list-header>

        <ion-item *ngIf="currentDevice">
          <ion-icon slot="start" name="bluetooth-outline" color="tertiary"></ion-icon>
          <ion-label>{{ currentDevice?.name }}</ion-label>

          <ion-icon slot="end" color="success" name="swap" *ngIf="isConnected; else noConnect"></ion-icon>

          <ng-template #noConnect>
            <ion-icon slot="end" color="primary" name="sync" (click)="initCurrentDevice()"></ion-icon>
          </ng-template>

          <ion-icon slot="end" color="primary" name="close" (click)="removeCurrentDevice(currentDevice)"></ion-icon>
        </ion-item>

        <ion-item *ngFor="let device of pairedDevices" [disabled]="currentDevice != null">
          <ion-icon slot="start" [name]="device.icon + '-outline'" color="dark"></ion-icon>
          <ion-label (click)="setDevice(device)">{{ device.name }}</ion-label>
          <ion-icon slot="end" name="done-all" color="success" *ngIf="device.isUse"></ion-icon>
        </ion-item>

        <ion-item *ngIf="!pairedDevices?.length">
          Немає жодного спареного пристрою<br>
          Натисність кнопку "Пошук нових пристроїв"
        </ion-item>
      </section>
    </section>
  </ion-list>
</ion-content>

<ion-footer *ngIf="!userSettings.keyboardMode && isBluetoothEnabled">
  <ion-toolbar>
    <ion-button class="one-button" color="primary" (click)="findNewDevices()">
      Пошук нових пристроїв
    </ion-button>
  </ion-toolbar>
</ion-footer>
