import { Component, Input, OnInit } from '@angular/core';

import { UtilsService } from '../../core/services/utils.service';
import { PayFormCode } from '../../p-rro/fsco/enums/pay-form-code.enum';
import { Product } from '../../shop/products/product.model';

import { Presale } from './presale.model';

@Component({
  selector: 'bk-presale',
  templateUrl: './presale.component.html',
  styleUrls: ['./presale.component.scss'],
})
export class PresaleComponent implements OnInit {
  @Input() presale: Presale;

  collapsed: boolean;
  payForm = PayFormCode;

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.collapsed = false;
  }

  togglePresale(event: MouseEvent): void {
    event.stopPropagation();

    this.collapsed = !this.collapsed;
  }

  name(product: Product): string {
    return this.utilsService.productNameView(product);
  }
}
