import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import round from 'lodash-es/round';

import { ToastService } from '../core/services/toast.service';
import { UtilsService } from '../core/services/utils.service';
import { CheckDocumentSubType } from '../p-rro/fsco/enums/check-document-sub-type.enum';
import { PayFormCode } from '../p-rro/fsco/enums/pay-form-code.enum';

import { SalePayment } from './sale/sale-payment.model';
import { SaleProduct } from './sale/sale-product.model';
import { Sale } from './sale/sale.model';
import { SalesService } from './sales.service';

@Injectable({
  providedIn: 'root',
})
export class ReturnService {
  constructor(
    private salesService: SalesService,
    private utilsService: UtilsService,
    private toastService: ToastService,
  ) {}

  async create(
    sale: Sale,
    terminalData?: SalePayment,
    paymentsAmount?: {
      cash: number;
      card: number;
      debt: number;
    },
    returnProducts?: SaleProduct[],
  ): Promise<Sale | null> {
    if (paymentsAmount == null) {
      paymentsAmount = {
        cash:
          sale.salePayments.find((sp) => sp.method === PayFormCode.Cash)
            ?.amount ?? 0,
        card:
          sale.salePayments.find((sp) => sp.method === PayFormCode.Card)
            ?.amount ?? 0,
        debt:
          sale.salePayments.find((sp) => sp.method === PayFormCode.InDebt)
            ?.amount ?? 0,
      };
    }

    const returnSale = new Sale();

    returnSale.saleProducts = [];

    (returnProducts ?? sale.saleProducts).forEach((returnProduct) => {
      const saleProduct = cloneDeep(returnProduct);

      if (returnProducts != null) {
        this.calcSaleProduct(saleProduct);
      }

      returnSale.totalDiscount += saleProduct.discount ?? 0;
      returnSale.totalFreeCups += saleProduct.freeCup ?? 0;
      returnSale.totalBonus += saleProduct.bonusPayment ?? 0;

      returnSale.saleProducts.push(saleProduct);
    });

    if (sale.prro != null) {
      returnSale.prro = sale.prro;
    }

    if (sale.client != null) {
      returnSale.clientId = sale.client.id;
      returnSale.client = sale.client;
    }

    if (sale.saleInDebtId != null) {
      returnSale.saleInDebtId = sale.saleInDebtId;
    }

    const totalSum = returnSale.saleProducts.reduce(
      (result, returnProduct) =>
        result +
        round(
          round(returnProduct.price, 2) * round(returnProduct.quantity, 3),
          2,
        ),
      0,
    );

    const debtPayment = sale.salePayments.find(
      (salePayment) => salePayment.method === PayFormCode.InDebt,
    );

    if (debtPayment == null) {
      returnSale.roundSum = this.utilsService.getRoundSum(
        totalSum - paymentsAmount.card,
      );
    }

    returnSale.paymentSum =
      paymentsAmount.cash + paymentsAmount.card + paymentsAmount.debt;
    returnSale.cashSum = paymentsAmount.cash;
    returnSale.cardSum = paymentsAmount.card;
    returnSale.debtSum = paymentsAmount.debt;
    returnSale.discountSum = round(
      returnSale.totalDiscount +
        returnSale.totalFreeCups +
        returnSale.totalBonus,
      2,
    );

    returnSale.costSum = round(totalSum + returnSale.discountSum, 2);

    this.addPayments(returnSale, paymentsAmount, terminalData);

    returnSale.calcTotalTaxes();

    if ((sale.prroTaxNumber ?? '') > '') {
      returnSale.prroTaxNumber = sale.prroTaxNumber;

      returnSale.returnSale = new Sale({ skipConstructor: true });

      returnSale.returnSale.createdAt = sale.createdAt;
      returnSale.returnSale.prroTaxNumber = sale.prroTaxNumber;
      returnSale.returnSale.prroLocalNumber = sale.prroLocalNumber;
    }

    returnSale.returnSaleId = sale.id;

    return this.salesService
      .createSale(returnSale, CheckDocumentSubType.CheckReturn, {
        needFiscalization: (sale.prroTaxNumber ?? '') > '',
      })
      .then(async (returnedSale) => {
        sale.returnSaleId = -returnedSale.id;

        this.salesService.setPaymentIcon(returnSale);

        return returnSale;
      })
      .catch((reason) => {
        this.toastService.presentError(
          `Повернення`,
          `Не вдалося створити чек повернення: ${reason}`,
        );

        return null;
      });
  }

  private calcSaleProduct(saleProduct: SaleProduct): void {
    const weightCoefficient = Math.pow(
      10,
      Number(saleProduct.product.weightProduct),
    );

    if (saleProduct.product.weightProduct) {
      saleProduct.quantity = Math.abs(
        round(saleProduct.quantity * weightCoefficient, 3),
      );

      saleProduct.price = Math.abs(
        round(saleProduct.price / weightCoefficient, 2),
      );

      saleProduct.fullPrice = Math.abs(
        round(saleProduct.fullPrice / weightCoefficient, 2),
      );
    }

    saleProduct.exciseLabel = saleProduct.exciseLabels?.join(';');

    saleProduct.refreshExciseLabelView();

    saleProduct.discount = round(
      (saleProduct.personalDiscount ?? 0) +
        (saleProduct.freeCup ?? 0) +
        (saleProduct.bonusPayment ?? 0),
      2,
    );

    if (saleProduct.soldQuantity != null && saleProduct.soldQuantity > 0) {
      if (saleProduct.personalDiscount) {
        saleProduct.personalDiscount = round(
          (saleProduct.quantity * saleProduct.personalDiscount) /
            saleProduct.soldQuantity /
            weightCoefficient,
          2,
        );
      }

      if (saleProduct.freeCup != null && saleProduct.freeCup > 0) {
        saleProduct.freeCup = round(
          (saleProduct.quantity * saleProduct.freeCup) /
            saleProduct.soldQuantity /
            weightCoefficient,
          2,
        );
      }

      if (saleProduct.bonusPayment != null && saleProduct.bonusPayment > 0) {
        saleProduct.bonusPayment = round(
          (saleProduct.quantity * saleProduct.bonusPayment) /
            saleProduct.soldQuantity /
            weightCoefficient,
          2,
        );
      }

      if (saleProduct.bonusAdd != null && saleProduct.bonusAdd > 0) {
        saleProduct.bonusAdd = round(
          (saleProduct.quantity * saleProduct.bonusAdd) /
            saleProduct.soldQuantity /
            weightCoefficient,
          2,
        );
      }
    }
  }

  addPayments(
    returnSale: Sale,
    payments: {
      cash: number;
      card: number;
      debt: number;
    },
    cashlessPayment?: SalePayment,
  ): void {
    returnSale.cashless =
      payments.card > 0 && payments.cash > 0 ? null : payments.card > 0;

    returnSale.salePayments = [];

    if (payments.debt > 0) {
      const salePayment = new SalePayment();

      salePayment.method = PayFormCode.InDebt;
      salePayment.amount = payments.debt;

      returnSale.salePayments.push(salePayment);
    }

    if (payments.cash > 0) {
      const salePayment = new SalePayment();

      salePayment.method = PayFormCode.Cash;
      salePayment.amount = payments.cash;

      returnSale.salePayments.push(salePayment);
    }

    if (payments.card > 0) {
      const salePayment = new SalePayment();

      salePayment.method = PayFormCode.Card;
      salePayment.amount = payments.card;

      if (cashlessPayment != null) {
        salePayment.acquirerName = cashlessPayment.acquirerName;
        salePayment.acquirerTransactionId =
          cashlessPayment.acquirerTransactionId;

        salePayment.authCode = cashlessPayment.authCode;
        salePayment.deviceId = cashlessPayment.deviceId;
        salePayment.epzDetails = cashlessPayment.epzDetails;
        salePayment.paymentSystem = cashlessPayment.paymentSystem;
        salePayment.posTransactionDate = cashlessPayment.posTransactionDate;
        salePayment.posTransactionNumber = cashlessPayment.posTransactionNumber;
        salePayment.transactionId = cashlessPayment.transactionId;
        salePayment.signVerification = cashlessPayment.signVerification;
        salePayment.operationType = cashlessPayment.operationType;
      }

      returnSale.salePayments.push(salePayment);
    }
  }
}
