import { Component, Input, OnInit } from '@angular/core';

import { PRRO_NAME } from '../../../settings.const';
import {
  CASH_REMAIN_CAPTION,
  DISCOUNT_SUM_CAPTION,
  LAST_DOC_FISCL_NUMBER_CAPTION,
  LAST_DOC_LOCAL_NUMBER_CAPTION,
  REALIZ_COUNT_CAPTION,
  REALIZ_SUM_CAPTION,
  RETURN_COUNT_CAPTION,
  RETURN_SUM_CAPTION,
  ROUND_SUM_CAPTION,
  SERVICE_INPUT_CAPTION,
  SERVICE_OUTPUT_CAPTION,
  SHIFT_FINISH_CAPTION,
  SHIFT_START_CAPTION,
} from '../../receipt/receipt.const';
import { PrintPRroZReport } from '../../receipt/types/print-p-rro-z-report.model';

@Component({
  selector: 'bk-view-tax-report',
  templateUrl: './view-tax-report.component.html',
  styleUrls: ['./view-tax-report.component.scss'],
})
export class ViewTaxReportComponent implements OnInit {
  @Input() report: PrintPRroZReport;

  isFiscal = false;

  shiftStartCaption = SHIFT_START_CAPTION;
  shiftFinishCaption = SHIFT_FINISH_CAPTION;
  roundSumCaption = ROUND_SUM_CAPTION;
  realizSumCaption = REALIZ_SUM_CAPTION;
  realizCountCaption = REALIZ_COUNT_CAPTION;
  returnSumCaption = RETURN_SUM_CAPTION;
  returnCountCaption = RETURN_COUNT_CAPTION;
  serviceInputCaption = SERVICE_INPUT_CAPTION;
  serviceOutputCaption = SERVICE_OUTPUT_CAPTION;
  cashRemainCaption = CASH_REMAIN_CAPTION;
  discountSumCaption = DISCOUNT_SUM_CAPTION;
  lastDocLocalNumberCaption = LAST_DOC_LOCAL_NUMBER_CAPTION;
  lastDocFisclNumberCaption = LAST_DOC_FISCL_NUMBER_CAPTION;
  prroName = PRRO_NAME;

  constructor() {}

  ngOnInit(): void {
    this.isFiscal = this.report.taxNumber > '';
  }
}
