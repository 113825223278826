import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Platform } from '@ionic/angular';
import { ThermalPrinterPlugin } from 'thermal-printer-cordova-plugin/src';

import { IPairedBluetoothDevice } from '../../core/interfaces/paired-bluetooth-device.interface';
import { CachedDataService } from '../../core/services/cached-data.service';
import { ToastService } from '../../core/services/toast.service';

import { LabelPrinterSettings } from './label/label-printer-settings.interface';
import { PrinterConnectionMethod } from './printer-connection-method.enum';
import { DEFAULT_PRINTER, NOT_FOUND_DEVICE } from './printer.const';
import { Printer } from './printer.model';
import { QzTrayService } from './qz-tray/qz-tray.service';
import { ReceiptPrinterSettings } from './receipt/receipt-printer-settings.interface';
import { ReceiptPrinterService } from './receipt/receipt-printer.service';

declare let ThermalPrinter: ThermalPrinterPlugin;

@Component({
  selector: 'bk-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.scss'],
})
export class PrinterComponent implements AfterViewInit, OnChanges {
  readonly printerConnectionMethod = PrinterConnectionMethod;

  @Input() settings: ReceiptPrinterSettings | LabelPrinterSettings;
  @Input() connectionMethods: PrinterConnectionMethod[] = [];
  @Output() updated: EventEmitter<void> = new EventEmitter<void>();

  printers = [DEFAULT_PRINTER];

  isBluetoothEnabled = false;
  isConnected = false;

  constructor(
    private platform: Platform,
    private diagnostic: Diagnostic,
    private bluetoothSerial: BluetoothSerial,
    private androidPermissions: AndroidPermissions,
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
    private qzTrayService: QzTrayService,
    private receiptPrinterService: ReceiptPrinterService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    if (this.settings.connectionMethod === PrinterConnectionMethod.Browser) {
      return;
    }

    switch (this.settings.connectionMethod) {
      case PrinterConnectionMethod.QZTray:
        await this.setQzTrayPrinters();
        break;

      case PrinterConnectionMethod.Bluetooth:
        await this.checkBluetoothStatus();
        await this.setBluetoothPrinters();
        break;

      case PrinterConnectionMethod.USB:
        await this.setUSBPrinters({ select: true });
        break;

      case PrinterConnectionMethod.WiFi:
        break;

      default:
        break;
    }

    if (this.settings.connectionMethod === PrinterConnectionMethod.USB) {
      return;
    }

    this.setCurrentPrinter();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.settings.firstChange) {
      return;
    }

    this.settings = changes.settings.currentValue;

    await this.ngAfterViewInit();
  }

  setSettings(): void {
    this.updated.emit();
  }

  connectionMethodName(method: PrinterConnectionMethod): string {
    switch (method) {
      case PrinterConnectionMethod.Browser:
        return 'Браузер';

      case PrinterConnectionMethod.QZTray:
        return 'Сервіс QZ Tray';

      case PrinterConnectionMethod.Bluetooth:
        return 'Bluetooth';

      case PrinterConnectionMethod.USB:
        return 'Кабель USB';

      case PrinterConnectionMethod.WiFi:
        return 'Мережа Wi-Fi';

      default:
        return 'Немає';
    }
  }

  loadIcons(): void {
    if (this.settings.connectionMethod !== PrinterConnectionMethod.Bluetooth) {
      return;
    }

    setTimeout(() => {
      const radios = document.getElementsByClassName('alert-radio-label');

      for (let index = 0; index < radios.length; index += 1) {
        if (
          this.printers[index].name === '' ||
          this.printers[index].name === NOT_FOUND_DEVICE.name
        ) {
          continue;
        }

        let icon = 'bluetooth';

        switch (this.printers[index].class) {
          case 524:
            icon = 'call';
            break;

          case 1028:
            icon = 'headset';
            break;

          case 1664:
            icon = 'print';
            break;
        }

        const element = radios[index];

        element.innerHTML = `<ion-icon name="${icon}-outline"></ion-icon> ${element.innerHTML}`;
      }
    }, 250);
  }

  isPrinterAvailable(): boolean {
    return (
      this.settings.connectionMethod === PrinterConnectionMethod.QZTray ||
      this.settings.connectionMethod === PrinterConnectionMethod.Bluetooth ||
      this.settings.connectionMethod === PrinterConnectionMethod.USB
    );
  }

  isAddressAvailable(): boolean {
    return this.settings.connectionMethod === PrinterConnectionMethod.WiFi;
  }

  async setConnectionMethod(): Promise<void> {
    switch (this.settings.connectionMethod) {
      case PrinterConnectionMethod.QZTray:
        await this.receiptPrinterService.connectQzTray();

        const connected = await this.qzTrayService.isActive();

        if (connected) {
          await this.setQzTrayPrinters();
        } else {
          this.printers = [DEFAULT_PRINTER];
        }

        break;

      case PrinterConnectionMethod.Bluetooth:
        await this.checkBluetoothStatus();
        await this.setBluetoothPrinters();
        break;

      case PrinterConnectionMethod.USB:
        await this.setUSBPrinters();
        break;

      case PrinterConnectionMethod.WiFi:
        await this.resetIp();
        break;

      default:
        break;
    }

    if (this.settings.connectionMethod === PrinterConnectionMethod.USB) {
      return;
    }

    this.setCurrentPrinter();
    this.setPrinter();
  }

  private async setQzTrayPrinters(): Promise<void> {
    const qzPrinters = await this.qzTrayService.getPrinters();

    this.printers = [
      ...[DEFAULT_PRINTER],
      ...qzPrinters.map((name) => new Printer(name, name)),
    ];
  }

  private async checkBluetoothStatus(): Promise<void> {
    if (!this.platform.is('cordova')) {
      this.isBluetoothEnabled = false;
      return;
    }

    try {
      const permission = await this.androidPermissions.checkPermission(
        this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT,
      );

      if (permission.hasPermission) {
        // Do nothing and proceed permission exists already
        this.isBluetoothEnabled = await this.diagnostic.isBluetoothEnabled();
      } else {
        // Request for all the permissions in the array
        await this.androidPermissions.requestPermissions([
          this.androidPermissions.PERMISSION.BLUETOOTH,
          this.androidPermissions.PERMISSION.BLUETOOTH_ADMIN,
          this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT,
          this.androidPermissions.PERMISSION.BLUETOOTH_SCAN,
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
        ]);

        this.isBluetoothEnabled = await this.diagnostic.isBluetoothEnabled();
      }
    } catch (error) {
      await this.androidPermissions.requestPermissions([
        this.androidPermissions.PERMISSION.BLUETOOTH,
        this.androidPermissions.PERMISSION.BLUETOOTH_ADMIN,
        this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT,
        this.androidPermissions.PERMISSION.BLUETOOTH_SCAN,
        this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
      ]);

      this.isBluetoothEnabled = await this.diagnostic.isBluetoothEnabled();
    }
  }

  private async setBluetoothPrinters(): Promise<void> {
    if (!this.platform.is('cordova')) {
      this.isBluetoothEnabled = false;
      return;
    }

    const pairedBtDevices: IPairedBluetoothDevice[] =
      await this.bluetoothSerial.list();

    this.printers = [
      ...[DEFAULT_PRINTER],
      ...pairedBtDevices.map((btDevice) => {
        const device = new Printer(btDevice.name, btDevice.name);

        device.id = btDevice.address;
        device.class = btDevice.class;
        device.address = btDevice.address;

        return device;
      }),
      ...[NOT_FOUND_DEVICE],
    ];
  }

  private async setUSBPrinters(
    options: { select: boolean } = { select: false },
  ): Promise<void> {
    if (!this.platform.is('cordova')) {
      return;
    }

    ThermalPrinter.listPrinters(
      { type: 'usb' },
      (usbPrinters) => {
        this.printers = [
          ...[DEFAULT_PRINTER],
          ...usbPrinters.map((usbDevice) => {
            const device = new Printer(
              Printer.usbName(
                usbDevice.manufacturerName,
                usbDevice.productName,
              ),
              Printer.usbTitle(
                usbDevice.manufacturerName,
                usbDevice.productName,
              ),
            );

            device.id = `${usbDevice.deviceId ?? 0}`;
            device.class = usbDevice.vendorId ?? 0;

            device.productName = usbDevice.productName;
            device.manufacturerName = usbDevice.manufacturerName;
            device.deviceId = usbDevice.deviceId;
            device.vendorId = usbDevice.vendorId;
            device.serialNumber = usbDevice.serialNumber;

            return device;
          }),
          ...[NOT_FOUND_DEVICE],
        ];

        if (options.select) {
          this.setCurrentPrinter();
        } else if (usbPrinters.length === 1) {
          this.settings.printer =
            this.printers.find(
              (p) =>
                p.name ===
                Printer.usbName(
                  usbPrinters[0].manufacturerName,
                  usbPrinters[0].productName,
                ),
            ) || DEFAULT_PRINTER;

          this.setPrinter();
        }
      },
      (error) => {
        this.toastService.presentError(
          'USB-принтер',
          `Помилка отримання списку принтерів: ${JSON.stringify(error)}`,
        );
      },
    );
  }

  private setCurrentPrinter(): void {
    this.settings.printer =
      this.printers.find((p) => p.name === this.settings.printer.name) ||
      DEFAULT_PRINTER;
  }

  async setPrinter(): Promise<void> {
    if (this.settings.connectionMethod === PrinterConnectionMethod.Bluetooth) {
      if (this.settings.printer.name === NOT_FOUND_DEVICE.name) {
        this.settings.printer = DEFAULT_PRINTER;

        this.diagnostic.switchToBluetoothSettings();
      } else {
        this.settings.mac = this.settings.printer.address;
      }
    } else if (
      this.settings.connectionMethod === PrinterConnectionMethod.USB &&
      this.settings.printer.name === NOT_FOUND_DEVICE.name
    ) {
      this.settings.printer = DEFAULT_PRINTER;
    }

    this.setSettings();
  }

  changeBluetoothState(): void {
    if (!this.platform.is('cordova')) {
      return;
    }

    if (this.isBluetoothEnabled) {
      this.diagnostic
        .setBluetoothState(false)
        .then((res) => (this.isBluetoothEnabled = res !== 'OK'));
    } else {
      this.diagnostic
        .setBluetoothState(true)
        .then((res) => (this.isBluetoothEnabled = res === 'OK'));
    }
  }

  private async resetIp(): Promise<void> {
    const shop = this.cachedDataService.getShop();

    this.settings.ip = (shop.printerIP ?? '') > '' ? shop.printerIP : '';
  }
}
