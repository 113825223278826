import { Injectable } from '@angular/core';

import { PrinterConnectionMethod } from '../../printer-connection-method.enum';
import { QzTrayService } from '../../qz-tray/qz-tray.service';
import { LabelPrinterData } from '../label-printer-data.model';
import { PRINTER_CLASS, PRINTER_URL } from '../label-printer.const';

@Injectable({
  providedIn: 'root',
})
export class LabelPrinterPrintService {
  constructor(private qzTrayService: QzTrayService) {}

  async print(data: LabelPrinterData): Promise<void> {
    switch (data.settings.connectionMethod) {
      case PrinterConnectionMethod.QZTray:
        const printerExist = await this.qzTrayService.printerExist(
          data.settings.printer.name,
          { url: PRINTER_URL, printerClass: PRINTER_CLASS },
        );

        if (!printerExist) {
          return;
        }

        await this.qzTrayService.printLabels(data);
        break;

      case PrinterConnectionMethod.Bluetooth:
      case PrinterConnectionMethod.USB:
      case PrinterConnectionMethod.WiFi:
        break;

      default:
        break;
    }
  }
}
