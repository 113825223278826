<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/shop"></ion-back-button>
    </ion-buttons>

    <ion-title>Статистика</ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="openCalendar()">
        <ion-icon name="calendar"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-toolbar class="period-container">
    <ion-label>{{ displayedFrom | date: 'dd.MM.yyyy' }} - {{ displayedTo | date: 'dd.MM.yyyy' }}</ion-label>
  </ion-toolbar>

  <ion-segment color="dark" (ionChange)="changedTab($event)" [value]="activeTab">
    <ion-segment-button *ngFor="let tab of tabs; let index = index" [value]="tab.name">
      <ion-label>{{ tab.caption }}</ion-label>
    </ion-segment-button>
  </ion-segment>

  <section class="statistics" *ngIf="totals || saleProducts; else loading">
    <ng-container *ngIf="activeTab === statisticsTab.Report; else productsStatistics">
      <ion-content>
        <section class="ion-padding">
          <div class="statistics__totals">
            <div class="statistics__totals__data">
              <bk-view-line *ngFor="let total of totals" [start]="total.caption" [end]="total.value" [dashed]="true"></bk-view-line>
            </div>
          </div>
        </section>
      </ion-content>
    </ng-container>

    <ng-template #productsStatistics>
      <section class="statistics__products">
        <div class="table-header">
          <div class="column-number">№</div>
          <div class="column-name">Назва</div>
          <div class="column-quantity">К-ть</div>
          <div class="column-sum" *ngIf="activeTab === statisticsTab.Products">В-ть, ₴</div>
        </div>
      
        <ion-content>
          <ng-container *ngIf="saleProducts?.length; else noData">
            <div class="even-row" *ngFor="let saleProduct of saleProducts; let index = index">
              <div class="table-data-row" *ngIf="index < visibleRows">
                <div class="column-number">{{ index + 1 }}</div>
    
                <div class="column-name" *ngIf="activeTab === statisticsTab.Products; else ingredientName">
                  <div>{{ saleProduct.productName }}, {{ saleProduct.productAmount }}</div>
    
                  <div class="column-name__addition">
                    {{ saleProduct.categoryName }} > 
                    {{ saleProduct.subcategoryName }}
                  </div>
                </div>
    
                <ng-template #ingredientName>
                  <div class="column-name">{{ saleProduct.product }}</div>
                </ng-template>
    
                <div class="column-quantity">{{ saleProduct.salesProductsQuantity | number }}</div>
    
                <div class="column-sum" *ngIf="activeTab === statisticsTab.Products; else ingredientMeasurement">
                  {{ saleProduct.revenue ? (saleProduct.revenue | number: '1.2-2') : '-' }}
                </div>
    
                <ng-template #ingredientMeasurement>
                  <div class="column-measurement">{{ saleProduct.productAmount }}</div>
                </ng-template>
              </div>
            </div>
        
            <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
              <ion-infinite-scroll-content loadingSpinner="circular" loadingText="Завантаження даних..."></ion-infinite-scroll-content>
            </ion-infinite-scroll>          
          </ng-container>  
        </ion-content>
      </section>
    </ng-template>
  </section>

  <ng-template #noData>
    <div class="no-data">
      <span>Немає продажів</span>
    </div>
  </ng-template>

  <ng-template #loading>
    <div class="no-data">
      <span>
        <ion-spinner></ion-spinner>
      </span>
    </div>
  </ng-template>
</ion-content>
