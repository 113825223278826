import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';

import { IngredientCategory } from '../../core/models/ingredient-category.model';
import { User } from '../../core/models/user.model';
import { UserService } from '../../core/services/resources/user.service';

import { NewProductDialog } from './new-product/new-product.dialog';
import { SuppliesService } from './supplies.service';
import { Supply } from './supply.model';

const SUPPLIES_ROWS = 25;

@Component({
  selector: 'bk-supplies',
  templateUrl: './supplies.component.html',
  styleUrls: ['./supplies.component.scss'],
})
export class SuppliesComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  supplies: Supply[];
  originalSupplies: Supply[];
  ingredientCategories: IngredientCategory[] = [];

  user: User;

  allCategories: IngredientCategory = { id: 0, name: 'Всі' };
  filterCategory: IngredientCategory;
  filterSearch = '';

  visibleRows = SUPPLIES_ROWS;

  constructor(
    private modalCtrl: ModalController,
    private userService: UserService,
    private suppliesService: SuppliesService,
  ) {}

  ngOnInit(): void {
    this.userService.getUser().subscribe((user: User) => (this.user = user));
  }

  ionViewWillEnter(): void {
    this.filterCategory = this.allCategories;
    this.ingredientCategories = [];

    this.suppliesService.find().subscribe((supplies) => {
      this.supplies = supplies;
      this.originalSupplies = cloneDeep(supplies);

      this.ingredientCategories.push(this.allCategories);

      supplies.forEach((supply) => {
        const category = this.ingredientCategories.find(
          (item) => item.id === supply.ingredient.categoryId,
        );

        if (!category && supply.ingredient.category) {
          this.ingredientCategories.push(supply.ingredient.category);
        }
      });
    });
  }

  search(event: any): void {
    this.filterSearch = event.target.value || '';

    this.filter();
  }

  filter(): void {
    this.visibleRows = SUPPLIES_ROWS;
    this.supplies = cloneDeep(this.originalSupplies);

    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = false;
    }

    if (this.filterSearch) {
      const searchedWords = this.filterSearch.trim().toLowerCase().split(' ');

      this.supplies = this.supplies.filter((supply) => {
        const name = supply.ingredient.name.trim().toLowerCase();

        return searchedWords.every((word) => name.includes(word));
      });
    }

    if (this.filterCategory.id !== this.allCategories.id) {
      this.supplies = this.supplies.filter(
        (supply) => supply.ingredient.categoryId === this.filterCategory.id,
      );
    }
  }

  loadData(event: any): void {
    setTimeout(() => {
      this.visibleRows += SUPPLIES_ROWS;

      event.target.complete();

      if (this.supplies.length <= this.visibleRows) {
        event.target.disabled = true;
      }
    }, 300);
  }

  async addProduct(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: NewProductDialog,
      componentProps: {},
      backdropDismiss: false,
    });

    await modal.present();
  }
}
