import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bk-view-line',
  templateUrl: './view-line.component.html',
  styleUrls: ['./view-line.component.scss'],
})
export class ViewLineComponent implements OnInit {
  @Input() line = '';
  @Input() start = '';
  @Input() center = '';
  @Input() end = '';
  @Input() bold = false;
  @Input() dashed = false;

  constructor() {}

  ngOnInit(): void {}
}
