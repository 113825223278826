<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/shop"></ion-back-button>
    </ion-buttons>

    <ion-title>Каса</ion-title>

    <ion-buttons slot="end">
      <ion-label *ngIf="user?.showCash">{{ shift?.totals.cashRemain | currency:'UAH':'symbol-narrow' }}&nbsp;</ion-label>

      <ion-button (click)="refresh()">
        <ion-icon name="sync-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section>
    <ion-segment color="dark" (ionChange)="changeTab($event)" [value]="type">
      <ion-segment-button [value]="transactions.Incasation">
        <ion-label>Інкасація</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="transactions.Deposit">
        <ion-label>Внесення коштів</ion-label>
      </ion-segment-button>

      <ion-segment-button [value]="transactions.FinanceExpense">
        <ion-label>Витрати</ion-label>
      </ion-segment-button>
    </ion-segment>

    <bk-transaction [type]="type"></bk-transaction>
  </section>

  <section class="transactions">
    <ng-container *ngIf="shiftTransactions?.length; else loading">
      <div *ngFor="let group of groupTransactions">
        <ion-toolbar class="period-container">
          <ion-label>{{ group.date }}</ion-label>
        </ion-toolbar>

        <div class="table-header">
          <span class="column-time">Час</span>
          <span class="column-tax">&nbsp;</span>
          <span class="column-comment">Коментар</span>
          <span class="column-sum">Сума, ₴</span>
        </div>

        <ion-list lines="none" class="table-data">
          <div class="even-row" *ngFor="let transaction of group.transactions; let i = index" >
            <ion-item-sliding #itemSliding>
              <ion-item (click)="itemSliding.open('end')">
                <div class="table-data-row">
                  <span class="column-time">{{ transaction.createdAt | date: 'HH:mm' }}</span>

                  <span class="column-tax">
                    <ion-icon *ngIf="transaction.prroLocalNumber && transaction.prroTaxNumber" name="qr-code-outline" color="medium"></ion-icon>
                  </span>

                  <span class="column-comment">
                    <ion-icon [name]="transaction.cashless ? 'card' : 'cash'" color="medium" *ngIf="(transaction.type === transactions.Incasation)"></ion-icon>
                    <ion-note *ngIf="transaction.returnId">[Анульовано]&nbsp;</ion-note>
                    {{ transaction.comment }}
                  </span>

                  <span class="column-sum">
                    {{ transaction.amount | number: '1.2-2' }}
                  </span>
                </div>
              </ion-item>

              <ion-item-options side="end" (click)="itemSliding.close()">
                <ion-item-option [color]="isFiscalDoc(transaction) ? 'secondary' : 'danger'" (click)="qr(transaction)" *ngIf="showQRButton(transaction)">
                  <ion-icon [name]="isFiscalDoc(transaction) ? 'share-social-outline' : 'qr-code-outline'" size="large"></ion-icon>
                </ion-item-option>

                <ion-item-option color="tertiary" (click)="print(transaction)" *ngIf="isFiscalDoc(transaction)">
                  <ion-icon name="print-outline" size="large"></ion-icon>
                </ion-item-option>

                <ion-item-option color="danger" (click)="delete(transaction)" *ngIf="!transaction.returnId">
                  <ion-icon name="trash-outline" size="large"></ion-icon>
                </ion-item-option>
              </ion-item-options>
            </ion-item-sliding>
          </div>
        </ion-list>
      </div>
    </ng-container>

    <ng-template #loading>
      <div class="no-data">
        <span>
          <ion-spinner *ngIf="!shiftTransactions; else noData"></ion-spinner>

          <ng-template #noData>
            Немає транзакцій обраного типу
          </ng-template>
        </span>
      </div>
    </ng-template>
  </section>
</ion-content>
