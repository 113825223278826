import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Resource } from '../../../core/abstract/resource';
import { RECEIPT_PRINTER_REFRESH } from '../../../core/constants/events.const';
import { ObjectLiteral } from '../../../core/interfaces/object-literal';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { Events } from '../../../core/services/events.service';
import { ShiftsService } from '../../../core/services/resources/shifts.service';
import { ReceiptPrinterService } from '../../../settings/printer/receipt/receipt-printer.service';

import { Waste } from './waste.model';

@Injectable({
  providedIn: 'root',
})
export class WastesService extends Resource<Waste> {
  private isPrintReceiptMode = false;

  constructor(
    protected http: HttpClient,
    private events: Events,
    private cachedDataService: CachedDataService,
    private receiptPrinterService: ReceiptPrinterService,
    private shiftsService: ShiftsService,
  ) {
    super(http, {
      path: '/wastes',
    });

    this.events.subscribe(
      RECEIPT_PRINTER_REFRESH,
      (status: { isAvailible: boolean; isAutoPrintMode: boolean }) => {
        this.isPrintReceiptMode = status.isAvailible;
      },
    );

    this.refreshReceiptPrintStatus();
  }

  find(options: ObjectLiteral = {}): Observable<Waste[]> {
    options = {
      ...options,
      shopId: this.cachedDataService.getShopId(),
      updatedView: true,
    };

    return super.find(options).pipe(
      map((wastes) => wastes.map((waste) => this.transformFromBackend(waste))),
      map((wastes) => orderBy(wastes, 'createdAt', 'desc')),
    );
  }

  get(id: number | string): Observable<Waste> {
    return super.get(id).pipe(map((waste) => this.transformFromBackend(waste)));
  }

  create(body: Waste): Observable<Waste> {
    body.shopId = this.cachedDataService.getShopId();
    body.userId = this.cachedDataService.getUser().id;

    return super.create(this.transformToBackend(body)).pipe(
      tap(async (createdWaste) => {
        await this.print(createdWaste);
      }),
    );
  }

  update(id: number | string, body: Partial<Waste>): Observable<Waste> {
    return super
      .update(id, body, {
        updatedDoc: true,
      })
      .pipe(
        tap(async (createdWaste) => {
          await this.print(createdWaste);
        }),
      );
  }

  private transformFromBackend(waste: Waste): Waste {
    const wasteClone = cloneDeep(waste);

    wasteClone.createdAt = new Date(wasteClone.createdAt);
    wasteClone.providerName = wasteClone.back
      ? wasteClone.provider.name
      : wasteClone.name;

    wasteClone.typeName = !wasteClone.back
      ? 'Списання'
      : wasteClone.wasteAmount > wasteClone.receivedAmount
      ? 'В борг'
      : 'Оплачена';

    wasteClone.canView = false;
    wasteClone.canCopy = true;
    wasteClone.canPrint = true;
    wasteClone.canPrintLabel = false;
    wasteClone.canUpdate = true;
    wasteClone.canDelete = true;

    return wasteClone;
  }

  private transformToBackend(waste: Waste): Waste {
    const wasteClone = cloneDeep(waste);

    wasteClone.shiftId = this.shiftsService.getCurrentShiftId();
    wasteClone.shiftWaste = true;

    return wasteClone;
  }

  private refreshReceiptPrintStatus(): void {
    this.receiptPrinterService.status().then((status) => {
      this.isPrintReceiptMode = status.isPrinterAvailable;
    });
  }

  private async print(waste: Waste): Promise<void> {
    if (
      this.isPrintReceiptMode &&
      waste.back &&
      Math.abs(waste.receivedAmount ?? 0) > 0
    ) {
      await this.receiptPrinterService.openCashDrawer();
    }
  }
}
