import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { TransactionComponent } from './transaction/transaction.component';
import { TransactionsRoutingModule } from './transactions-routing.module';
import { TransactionsComponent } from './transactions.component';

@NgModule({
  imports: [SharedModule, TransactionsRoutingModule],
  declarations: [TransactionsComponent, TransactionComponent],
  providers: [TransactionComponent, DatePipe],
})
export class TransactionsModule {}
