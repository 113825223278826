import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ToastService } from '../../core/services/toast.service';

@Component({
  selector: 'bk-second-screen',
  templateUrl: './second-screen.component.html',
  styleUrls: ['./second-screen.component.scss'],
})
export class SecondScreenComponent implements OnInit {
  constructor(
    private clipboard: Clipboard,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    //
  }

  installPlugin(): void {
    window.open(
      `https://chromewebstore.google.com/search/multiwindow-positioner`,
      '_blank',
    );
  }

  openSettings(): void {
    window.open(
      `chrome-extension://hmgehpjpfhobbnhhelhlggjfcaollidl/options.html`,
      '_blank',
    );
  }

  copyToClipboard(): void {
    this.clipboard.copy(
      `${environment.apiUrl}/settings/chrome-multiwindow-positioner-templates`,
    );
    this.toastService.present(
      'Посилання на шаблон налаштувань скопійовано у буфер обміну',
    );
  }
}
