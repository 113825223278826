import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';

import { Resource } from '../core/abstract/resource';
import { SYNC_TOAST_MESSAGE } from '../core/constants/error-messages.const';
import { RECEIPT_PRINTER_REFRESH } from '../core/constants/events.const';
import { ObjectLiteral } from '../core/interfaces/object-literal';
import { IToastOptions } from '../core/interfaces/toast-options.interface';
import { SyncResult } from '../core/models/sync-result.model';
import { CachedDataService } from '../core/services/cached-data.service';
import { Events } from '../core/services/events.service';
import { ToastService } from '../core/services/toast.service';
import { UtilsService } from '../core/services/utils.service';
import { ReceiptPrinterService } from '../settings/printer/receipt/receipt-printer.service';
import { FINANCE_EXPENSES_STORAGE_KEY } from '../settings/settings.const';

import { IFinanceCategory } from './transaction/interfaces/finance-category.interface';
import { FinanceExpense } from './transaction/models/finance-expense.model';
import { Transaction } from './transaction/models/transaction.model';
import { TransactionType } from './transaction/transaction-type.enum';

@Injectable({
  providedIn: 'root',
})
export class FinanceExpensesService extends Resource<FinanceExpense> {
  private isPrintReceiptMode = false;
  private isPrintReceiptAutoPrintMode = false;

  constructor(
    protected http: HttpClient,
    private events: Events,
    private cachedDataService: CachedDataService,
    private utilsService: UtilsService,
    private toastService: ToastService,
    private receiptPrinterService: ReceiptPrinterService,
  ) {
    super(http, {
      path: '/finance/expenses',
    });

    this.events.subscribe(
      RECEIPT_PRINTER_REFRESH,
      (status: { isAvailible: boolean; isAutoPrintMode: boolean }) => {
        this.isPrintReceiptMode = status.isAvailible;
        this.isPrintReceiptAutoPrintMode = status.isAutoPrintMode;
      },
    );

    this.refreshReceiptPrintStatus();
  }

  findCategories(): Observable<IFinanceCategory[]> {
    const shop = this.cachedDataService.getShop();
    const params = new HttpParams().set('companyId', shop.companyId.toString());

    return this.http.get<IFinanceCategory[]>('/finance/category', { params });
  }

  find(options: ObjectLiteral = {}): Observable<FinanceExpense[]> {
    options = { ...options, shopId: this.cachedDataService.getShopId() };

    return super.find(options).pipe(
      map((financeExpenses) =>
        financeExpenses.map((financeExpense) =>
          this.transformFromBackend(financeExpense),
        ),
      ),
      map((financeExpenses) => orderBy(financeExpenses, 'createdAt', 'desc')),
    );
  }

  async createOrSaveOffline(
    financeExpense: FinanceExpense,
  ): Promise<FinanceExpense> {
    financeExpense.shopId = this.cachedDataService.getShopId();
    financeExpense.shiftId = this.cachedDataService.getShift().id;
    financeExpense.userId = this.cachedDataService.getUser().id;

    const savedFinanceExpense = this.transformToBackend(financeExpense);
    const isConnected = await this.utilsService.isOnline();

    if (!isConnected) {
      return this.saveOfflineFinanceExpense(savedFinanceExpense, false);
    }

    if (savedFinanceExpense.salaryEmployeeId) {
      return this.http
        .post<FinanceExpense>('/working-hours/paid-salary', financeExpense)
        .pipe(
          tap(() => this.presentToast(savedFinanceExpense)),
          catchError((error) =>
            this.saveOfflineFinanceExpense(savedFinanceExpense, error),
          ),
        )
        .toPromise();
    }

    return super
      .create(savedFinanceExpense)
      .pipe(
        tap(() => this.presentToast(savedFinanceExpense)),
        catchError((error) =>
          this.saveOfflineFinanceExpense(savedFinanceExpense, error),
        ),
      )
      .toPromise()
      .finally(async () => {
        await this.print(financeExpense);
      });
  }

  async updateOrSaveOffline(
    financeExpense: FinanceExpense,
  ): Promise<FinanceExpense> {
    const savedFinanceExpense = this.transformToBackend(financeExpense);
    const isConnected = await this.utilsService.isOnline();

    if (!isConnected) {
      return this.saveOfflineFinanceExpense(savedFinanceExpense);
    }

    return super
      .update(financeExpense.id, savedFinanceExpense)
      .pipe(
        tap(() => this.presentToast(savedFinanceExpense)),
        catchError((error) =>
          this.saveOfflineFinanceExpense(savedFinanceExpense, error),
        ),
      )
      .toPromise()
      .finally(async () => {
        await this.print(financeExpense);
      });
  }

  delete(id: number): Observable<FinanceExpense> {
    return super.delete(id);
  }

  convertToTransaction(financeExpense: FinanceExpense): Transaction {
    const transaction = new Transaction(
      TransactionType.FinanceExpense,
      financeExpense.price,
    );

    transaction.id = financeExpense.id;
    transaction.createdAt = financeExpense.createdAt;
    transaction.shopId = financeExpense.shopId;
    transaction.prroId = financeExpense.prroId;
    transaction.prro = financeExpense.prro;
    transaction.prroShiftId = financeExpense.prroShiftId;
    transaction.prroLocalNumber = financeExpense.prroLocalNumber;
    transaction.prroTaxNumber = financeExpense.prroTaxNumber;
    transaction.returnId = financeExpense.returnId;
    transaction.comment =
      !financeExpense.comment || financeExpense.comment === ''
        ? `${financeExpense.categoryName} > ${financeExpense.subcategoryName}`
        : financeExpense.comment;

    return transaction;
  }

  haveUnsyncedFinanceExpenses(): boolean {
    return this.getLocallySavedFinanceExpenses().length > 0;
  }

  async sync(): Promise<SyncResult> {
    const financeExpenses = this.getLocallySavedFinanceExpenses();

    if (financeExpenses == null || financeExpenses.length === 0) {
      const emptyResult = new SyncResult();

      emptyResult.success = true;
      emptyResult.message = 'Немає даних для синхронізації витрат';

      return emptyResult;
    }

    const syncRresult = new SyncResult('витрат');
    const unSyncedItems: FinanceExpense[] = [];
    const errors: string[] = [];

    syncRresult.totalItems = financeExpenses.length;

    for (const financeExpense of financeExpenses) {
      if (syncRresult.timeoutErrors < 5) {
        const request =
          financeExpense.salaryEmployeeId != null
            ? this.http.post<FinanceExpense>(
                '/working-hours/paid-salary',
                financeExpense,
              )
            : financeExpense.id == null
            ? super.create(financeExpense)
            : super.update(financeExpense.id, financeExpense);

        await request
          .pipe(timeout(5000))
          .toPromise()
          .then((response) => {
            syncRresult.successSync();
          })
          .catch((problem) => {
            this.syncError(
              syncRresult,
              financeExpense,
              unSyncedItems,
              problem,
              errors,
            );
          });
      } else {
        unSyncedItems.push(financeExpense);
      }
    }

    this.utilsService.setResultMessage(syncRresult, errors);
    this.saveFinanceExpensesLocally(unSyncedItems);

    return syncRresult;
  }

  private async presentToast(
    financeExpense: FinanceExpense,
    data: IToastOptions = {},
  ): Promise<void> {
    const prroReport =
      (financeExpense.prroTaxNumber ?? '') > '' ? ' з використанням ПРРО' : '';

    const isSalary = financeExpense.salaryEmployeeId != null;
    const toastHeader = this.getToastHeader(isSalary, data, prroReport);
    const toastMessage = this.getToastMessage(isSalary, data);

    if (data.isOffline || data.isError) {
      this.toastService.presentWarning(toastHeader, toastMessage, 7500);
    } else {
      this.toastService.present(toastHeader, toastMessage);
    }
  }

  private transformToBackend(financeExpense: FinanceExpense): FinanceExpense {
    const financeExpenseClone = cloneDeep(financeExpense);

    if (financeExpenseClone.price != null) {
      financeExpenseClone.price = Number(financeExpenseClone.price);
    }

    return financeExpenseClone;
  }

  private transformFromBackend(financeExpense: FinanceExpense): FinanceExpense {
    const financeExpenseClone = cloneDeep(financeExpense);

    financeExpenseClone.createdAt = new Date(financeExpenseClone.createdAt);

    return financeExpenseClone;
  }

  private syncError(
    syncResult: SyncResult,
    item: FinanceExpense,
    unSyncedItems: FinanceExpense[],
    problem: any,
    errors: string[],
  ): void {
    syncResult.warningSync();
    unSyncedItems.push(item);

    this.utilsService.addSyncError(problem, errors);
  }

  private async saveOfflineFinanceExpense(
    financeExpense: FinanceExpense,
    error?: any,
  ): Promise<FinanceExpense> {
    const parsedError = error ? this.utilsService.getParsedError(error) : null;

    this.saveLocally(financeExpense);

    this.presentToast(financeExpense, {
      isOffline: (parsedError?.statusCode ?? 0) === 0,
      isError: (parsedError?.statusCode ?? 0) >= 400,
    });

    return financeExpense;
  }

  private saveLocally(financeExpense: FinanceExpense): void {
    const financeExpenses = this.getLocallySavedFinanceExpenses() || [];

    financeExpenses.push(financeExpense);

    this.saveFinanceExpensesLocally(financeExpenses);
  }

  private getLocallySavedFinanceExpenses(): FinanceExpense[] {
    const financeExpenses = localStorage.getItem(FINANCE_EXPENSES_STORAGE_KEY);

    if (!financeExpenses) {
      return [];
    }

    return JSON.parse(financeExpenses);
  }

  private saveFinanceExpensesLocally(financeExpenses: FinanceExpense[]): void {
    localStorage.setItem(
      FINANCE_EXPENSES_STORAGE_KEY,
      JSON.stringify(financeExpenses),
    );
  }

  private getToastHeader(
    isSalary: boolean,
    data: IToastOptions = {},
    prroReport: string,
  ): string {
    const salaryNote = isSalary ? ' на заробітну плату' : '';

    return data.isOffline
      ? `Оффлайн ${
          data.isUpdate ? 'оновлення витрати' : 'витрата'
        }${salaryNote}${prroReport}`
      : data.isError
      ? `Помилка ${
          data.isUpdate ? 'оновлення' : 'збереження'
        } даних на сервері${prroReport}`
      : `${
          data.isUpdate ? 'Оновлено' : 'Створено витрату'
        }${salaryNote}${prroReport}`;
  }

  private getToastMessage(
    isSalary: boolean,
    data: IToastOptions = {},
  ): string | undefined {
    const salaryNote = isSalary ? ' на заробітну плату' : '';

    return data.isOffline
      ? 'Необхідна синхронізація'
      : data.isError
      ? `Витрату ${salaryNote} збережено на пристрої! ${SYNC_TOAST_MESSAGE}`
      : undefined;
  }

  private refreshReceiptPrintStatus(): void {
    this.receiptPrinterService.status().then((status) => {
      this.isPrintReceiptMode = status.isPrinterAvailable;
      this.isPrintReceiptAutoPrintMode = status.isAutoPrintAfterSale;
    });
  }

  private async print(financeExpense: FinanceExpense): Promise<void> {
    if (this.isPrintReceiptMode) {
      await this.receiptPrinterService.openCashDrawer();

      if (
        this.isPrintReceiptAutoPrintMode &&
        financeExpense.prroTaxNumber != null
      ) {
        // tslint:disable-next-line: no-commented-code
        // await this.receiptPrinterService.printTaxServiceDoc(
        //   this.convertToTransaction(financeExpense),
        // );
      }
    }
  }
}
