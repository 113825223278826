<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/shop"></ion-back-button>
    </ion-buttons>

    <ion-title>Програмний РРО</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-toolbar class="period-container">
    <ion-label >ФН {{ prro?.fiscalNumber }}, ВН {{ prro?.localNumber }} {{ prro?.caption }}</ion-label>

    <ion-buttons slot="end">
      <ion-button icon-only size="small" (click)="selectPRro()" *ngIf="shop?.prros?.length > 1">
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-segment color="dark" (ionChange)="changedTab($event)" [value]="activeTab">
    <ion-segment-button [value]="TAB_OPERATIONS">
      <ion-label>Операції</ion-label>
    </ion-segment-button>

    <ion-segment-button [value]="TAB_Z_REPORTS">
      <ion-label>Z-звіти</ion-label>
    </ion-segment-button>

    <ion-segment-button [value]="TAB_SETTINGS">
      <ion-label>Налаштування</ion-label>
    </ion-segment-button>
  </ion-segment>

  <ion-list lines="none" *ngIf="activeTab === TAB_OPERATIONS">
    <ion-item-divider class="top">
      <ion-label>Основні операції</ion-label>
    </ion-item-divider>

    <ion-item>
      <div class="operation-button">
        <ion-button size="large" (click)="openShift()" [disabled]="!subject || !(subject && prroState && !prroState.ShiftState)">
          <ion-icon slot="start" name="lock-open-outline"></ion-icon>
          <ion-label>Відкрити зміну</ion-label>
        </ion-button>
      </div>
    </ion-item>

    <ion-item>
      <div class="operation-button">
        <ion-button size="large" (click)="createZReportAndCloseFiscalShift()" [disabled]="!subject || !(subject && prroState && prroState.ShiftState)">
          <ion-icon slot="start" name="lock-closed-outline"></ion-icon>
          <ion-label>Z-звіт/закрити зміну</ion-label>
        </ion-button>
      </div>
    </ion-item>

    <ion-item-divider>
      <ion-label>Службові операції</ion-label>
    </ion-item-divider>

    <ion-item>
      <div class="operation-button">
        <ion-button size="large" color="tertiary" (click)="getServerState()">
          <ion-label>Стан сервера</ion-label>
        </ion-button>
      </div>
    </ion-item>

    <ion-item>
      <div class="operation-button">
        <ion-button size="large" color="tertiary" (click)="getRROState()" [disabled]="!subject">
          <ion-label>Стан ПРРО</ion-label>
        </ion-button>
      </div>
    </ion-item>

    <ion-item>
      <div class="operation-button">
        <ion-button size="large" color="tertiary" (click)="getLastShiftTotals()" [disabled]="!subject">
          <ion-label>X-звіт/Підсумки зміни</ion-label>
        </ion-button>
      </div>
    </ion-item>

    <ion-item>
      <div class="operation-button">
        <ion-button size="large" color="tertiary" (click)="compareDocs()" [disabled]="!subject">
          <ion-label>Порівняти з ДПС</ion-label>
        </ion-button>
      </div>
    </ion-item>
  </ion-list>

  <ion-list lines="none" *ngIf="activeTab === TAB_Z_REPORTS">
    <div class="even-row" *ngFor="let zReport of zReports">
      <ion-item [button]="true" (click)="preview(zReport)">{{ zReportCaption(zReport) }}</ion-item>
    </div>
  </ion-list>

  <ion-list lines="none" *ngIf="activeTab === TAB_SETTINGS">
    <ion-item-divider>
      <ion-label>Налаштування УЕП (удосконалений електронний підпис)</ion-label>
    </ion-item-divider>

    <ion-item>
      <ion-icon slot="start" name="key-outline"></ion-icon>

      <div class="key-info">
        <ion-label>{{ subject ? subject.commonName : 'Не вибрано' }}</ion-label>

        <div class="key-info__id" *ngIf="subject?.subjectKeyId">
          <ion-button icon-only fill="clear" size="small" color="medium" (click)="copyToClipboard()">
            <ion-icon name="copy-outline"></ion-icon>
          </ion-button>

          <ion-note>{{ subject.subjectKeyId }}</ion-note>
        </div>

        <ion-note>{{ subject?.validTo ? 'УЕП дійсний до ' + (subject.validTo | date: 'dd.MM.yyyy') : '' }}</ion-note>
        <ion-note>{{ subject?.issuer ? subject?.issuer : '' }}</ion-note>
      </div>

      <ion-button class="no-margin width-80" slot="end" color="tertiary" (click)="setCryptData()">
        {{ subject ? 'Змінити' : 'Вибрати' }}
      </ion-button>

      <ion-button class="no-margin width-38" slot="end" color="primary" (click)="deleteCryptData()">
        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
      </ion-button>
    </ion-item>
  
    <ion-item-divider>
      <ion-label>Останній номер документа</ion-label>
    </ion-item-divider>

    <ion-item>
      <ion-icon slot="start" name="information-circle-outline"></ion-icon>
      <ion-label>{{ lastDocNumber }}</ion-label>
      <ion-button class="no-margin width-120" slot="end" color="tertiary" (click)="setLastDocNumber()">Змінити</ion-button>
    </ion-item>

    <ion-item>
      <div class="link">
        <a class="link-style" (click)="advancedOptions = !advancedOptions">Розширені налаштування</a>
      </div>
    </ion-item>

    <ion-item *ngIf="advancedOptions">
      <ion-icon slot="start" name="stopwatch-outline" color="dark"></ion-icon>
      <ion-label>Накладати позначку часу</ion-label>
      <ion-toggle slot="end" color="dark" [ngModel]="userSettings.useTSP" (ngModelChange)="toggleUseTSP()"></ion-toggle>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <div *ngIf="prroState; else noState">
      <div class="summary" *ngIf="!isShiftRefreshing">
        <div>
          Зміна:&nbsp;

          <strong>
            {{ prroState.ShiftState ? 'Відкрита' : 'Закрита' }}
            {{ prroState.OpenShiftDateTime ? (prroState.OpenShiftDateTime | date: 'dd.MM.yy HH:mm') : '' }}
          </strong>

          <span class="value">
            <ion-label>Z-звіт:&nbsp;</ion-label>
            <ion-label><strong>{{ prroState.ZRepPresent ? 'Є' : 'Немає' }}</strong></ion-label>
          </span>
        </div>

        <div>
          <ion-label>Наступний номер документа:&nbsp;</ion-label>
          <ion-label><strong>{{ prroState.NextLocalNum }}</strong></ion-label>

          <span class="value">
            <ion-label><strong>{{ prroState.Testing ? 'Тест' : '' }}</strong></ion-label>
          </span>
        </div>
      </div>
    </div>

    <ng-template #noState>
      <div class="no-data">
        <span *ngIf="subject">Немає даних! Виконайте запит стану ПРРО!</span>
        <span *ngIf="!subject">
          Не задані налаштування УЕП!<br>
          Перейдіть на вкладку "Налаштування"!
        </span>
      </div>
    </ng-template>
  </ion-toolbar>
</ion-footer>
