import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DATETIME_FORMAT } from '../../core/constants/date.const';
import { ShopShift } from '../../core/models/shop-shift.model';
import { Shop } from '../../core/models/shop.model';

import { ShopStatus } from './shop-status.model';

@Component({
  selector: 'bk-select-shop-dialog',
  templateUrl: './select-shop.dialog.html',
  styleUrls: ['./select-shop.dialog.scss'],
})
export class SelectShopDialog implements OnInit {
  @Input() shops: Shop[];
  @Input() userId: number;
  @Input() isUserHaveShift: boolean;

  private shopsStatus: ShopStatus[] = [];

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.shops.forEach((shop) => {
      if (shop.shift == null) {
        this.setStatusWithoutShift(shop);
      } else {
        const prroCount = shop.prros?.length ?? 0;
        const isCurrentUser =
          shop.shift.userId === this.userId ||
          Boolean(shop.shift.users.find((user) => user.userId === this.userId));

        const shiftDate = formatDate(
          shop.shift.createdAt,
          DATETIME_FORMAT,
          'uk_UA',
        );

        const shiftUser =
          shop.shift.userId === this.userId
            ? 'поточним користувачем'
            : shop.shift.userName;

        const shiftCaption = `Зміна відкрита ${shiftDate} ${shiftUser}`;

        this.setStatusWithShift(
          shop.id,
          prroCount,
          shop.shift,
          shiftCaption,
          isCurrentUser,
        );
      }
    });
  }

  private setStatusWithShift(
    shopId: number,
    prroCount: number,
    shift: ShopShift,
    shiftCaption: string,
    isCurrentUser: boolean,
  ): void {
    let isFreeSlots = false;
    let slotsCaption = '';
    let usersCaption = '';

    if (prroCount > 1) {
      isFreeSlots = prroCount > shift.users.length;
      slotsCaption = `Вільних слотів: ${prroCount - shift.users.length}`;

      let users = '';

      shift.users.forEach((shiftUser) => {
        users += (users ? ', ' : '') + shiftUser.userName ?? '';
      });

      if (users > '') {
        usersCaption += `Користувачі: ${users}`;
      }
    }

    this.shopsStatus.push({
      shiftCaption,
      slotsCaption,
      usersCaption,
      id: shopId,
      disabled: !(isCurrentUser || isFreeSlots),
    });
  }

  private setStatusWithoutShift(shop: Shop): void {
    this.shopsStatus.push({
      id: shop.id,
      disabled: this.isUserHaveShift,
      shiftCaption: `Немає відкритої зміни${
        this.isUserHaveShift
          ? ', але у вас є відкрита зміна на іншій торговій точці'
          : ''
      }`,
    });
  }

  getShopStatus(shop: Shop): ShopStatus | undefined {
    return this.shopsStatus.find((shopStatus) => shopStatus.id === shop.id);
  }

  async closeModal(shop: Shop): Promise<void> {
    await this.modalCtrl.dismiss(shop);
  }
}
