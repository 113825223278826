<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="settings"></ion-back-button>
    </ion-buttons>

    <ion-title>Логи</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="keys.length; else noData">
    <ion-list [inset]="true" lines="full" class="table-data">
      <div class="even-row" *ngFor="let key of keys">
        <ion-item-sliding #itemSliding>
          <ion-item button detail [routerLink]="['/settings/logs', key]">
            <ion-label>{{ key }}</ion-label>
          </ion-item>

          <ion-item-options side="end" (click)="itemSliding.close()">
            <ion-item-option color="danger" (click)="remove(key)">
              <ion-icon name="trash-outline" size="large"></ion-icon>
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </div>
    </ion-list>
  </ng-container>

  <ng-template #noData>
    <div class="no-data">
      <span>Немає даних</span>
    </div>
  </ng-template>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="one-button" (click)="clear()">Очистити</ion-button>
  </ion-toolbar>
</ion-footer>
