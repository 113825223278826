import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor(public loadingCtrl: LoadingController) {}

  async preloader(
    loadingId: string,
    loadingCaption?: string,
  ): Promise<HTMLIonLoadingElement> {
    let spinnerPadding = '';
    let caption = '';

    if ((loadingCaption ?? '') > '') {
      spinnerPadding = 'ion-padding-top';
      caption = `<div class="ion-padding">${loadingCaption}</div>`;
    }

    return this.loadingCtrl.create({
      id: loadingId,
      spinner: null,
      duration: 2 * 60 * 1000, // 2 minutes
      message: `<div class="ion-text-center ${spinnerPadding}"><img src="assets/spinner.gif"></div>${caption}`,
      cssClass: 'loading-service',
    });
  }

  async presentCustomPreloader(
    loadingId: string,
    loadingCaption: string = '',
  ): Promise<void> {
    const loading = await this.preloader(loadingId, loadingCaption);

    await loading.present();
  }

  async presentScannerPreloader(loadingId: string): Promise<void> {
    const loading = await this.loadingCtrl.create({
      id: loadingId,
      spinner: null,
      message: `<img src="assets/spinner.gif">`,
    });

    await loading.present();
  }

  async dismiss(loadingId: string): Promise<void> {
    const popover = await this.loadingCtrl.getTop();

    if (popover != null) {
      await this.loadingCtrl.dismiss(null, undefined, loadingId);
    }
  }
}
