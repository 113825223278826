import round from 'lodash-es/round';

type Language = 'ua';
type Currency = 'uah' | 'noCurrency';

interface ConvertingOptions {
  capitalize: boolean;
  language: Language;
  currency: Currency;
}

interface CurrencyDetails {
  integerNameCases: string[];
  integerValueCase: number;
  integerShortName?: string;
  fractionalNameCases: string[];
  fractionalValueCase: number;
  fractionalShortName?: string;
}

interface CurrencyOptions {
  uah: CurrencyDetails;
  noCurrency: CurrencyDetails;
}

interface LanguageText {
  minus: string;
  numbers: string[][];
  tens: string[];
  units: string[][];
  currency: CurrencyOptions;
}

interface TextValues {
  ua: LanguageText;
}

interface ToWords {
  integer: number;
  integerText: string;
  integerCurrency: string;
  integerShortCurrency: string;
  fractional: number;
  fractionalText: string;
  fractionalCurrency: string;
  fractionalShortCurrency: string;
  fractionalString: string;
}

const PRECISION = 2;
const ZERO = '0';
const INTEGER_CLASS_LENGTH = 3;

const TEXT_VALUES: TextValues = {
  ua: {
    minus: 'мінус',
    numbers: [
      [
        '',
        'одна',
        'дві',
        'три',
        'чотири',
        "п'ять",
        'шість',
        'сім',
        'вісім',
        "дев'ять",
      ],
      [
        'нуль',
        'один',
        'два',
        'три',
        'чотири',
        "п'ять",
        'шість',
        'сім',
        'вісім',
        "дев'ять",
      ],
      [
        '',
        '',
        'двадцять',
        'тридцять',
        'сорок',
        "п'ятдесят",
        'шістдесят',
        'сімдесят',
        'вісімдесят',
        "дев'яносто",
      ],
      [
        '',
        'сто',
        'двісті',
        'триста',
        'чотириста',
        "п'ятсот",
        'шістсот',
        'сімсот',
        'вісімсот',
        "дев'ятсот",
      ],
    ],
    tens: [
      'десять',
      'одинадцять',
      'дванадцять',
      'тринадцять',
      'чотирнадцять',
      "п'ятнадцять",
      'шістнадцять',
      'сімнадцять',
      'вісімнадцять',
      "дев'ятнадцять",
    ],
    units: [
      [],
      [],
      ['тисяча', 'тисячі', 'тисяч'],
      ['мільйон', 'мільйони', 'мільйонів'],
      ['мільярд', 'мільярди', 'мільярдів'],
      ['трильйон', 'трильйони', 'трильйонів'],
    ],
    currency: {
      uah: {
        integerNameCases: ['гривня', 'гривні', 'гривень'],
        fractionalNameCases: ['копійка', 'копійки', 'копійок'],
        integerValueCase: 1,
        fractionalValueCase: 0,
        integerShortName: 'грн',
        fractionalShortName: 'коп.',
      },
      noCurrency: {
        integerNameCases: ['ціла', 'цілих', 'цілих'],
        fractionalNameCases: ['сота', 'сотих', 'сотих'],
        integerValueCase: 0,
        fractionalValueCase: 0,
      },
    },
  },
};

const enum TextCase {
  none = -1,
  forOne = 0,
  fromTwoToFour = 1,
  fromFiveToNine = 2,
}

//#region subfunctions
function getConvertingConfigs(options?: {
  capitalize?: boolean;
  noCurrency?: boolean;
}): {
  convertingOptions: ConvertingOptions;
  currencyOptions: CurrencyDetails;
} {
  const defaultOptions: ConvertingOptions = {
    capitalize: true,
    currency: 'uah',
    language: 'ua',
  };

  if (options != null) {
    if (typeof options.capitalize === 'boolean') {
      defaultOptions.capitalize = options.capitalize;
    }

    if (typeof options.noCurrency === 'boolean' && options.noCurrency) {
      defaultOptions.currency = 'noCurrency';
    }
  }

  const currentCurrency =
    TEXT_VALUES[defaultOptions.language].currency[defaultOptions.currency];

  return {
    convertingOptions: defaultOptions,
    currencyOptions: currentCurrency,
  };
}

function parseInputValue(value: number): {
  isNegative: boolean;
  integerValue: number;
  fractionalValue: string;
} {
  const isNegative = value < 0;

  // Integer part of the number
  const integerValue = Math.trunc(value);

  // Fractional part of the number
  const fractionalValue = Math.floor(
    Math.abs(round(value % 1, PRECISION)) * Math.pow(10, PRECISION),
  )
    .toFixed(0)
    .padStart(PRECISION, ZERO);

  return {
    isNegative,
    integerValue,
    fractionalValue,
  };
}

//#region Integer Part
function getIntegerTextCase(
  currentClassValue: string,
  i: number,
): {
  currentDigit: number;
  textCase: TextCase;
} {
  const currentDigit = parseInt(currentClassValue.substr(0, 1), 10);

  let textCase = TextCase.none;

  if (i === 1 && (currentClassValue.length <= 1 || currentDigit !== 1)) {
    textCase = getFractionalTextCase(currentDigit);

    return { currentDigit, textCase };
  }

  if (currentDigit === 1) {
    textCase = TextCase.fromFiveToNine;

    if (currentClassValue.length < 2) {
      textCase = TextCase.forOne;
    }
  } else if (currentDigit > 1 && currentDigit < 5) {
    textCase = TextCase.fromTwoToFour;
  } else if (currentDigit > 4 && currentDigit < 10) {
    textCase = TextCase.fromFiveToNine;
  } else if (currentDigit === 0) {
    textCase = TextCase.fromFiveToNine;
  }

  return { currentDigit, textCase };
}

function processIntegerClass(
  i: number,
  currentClassValue: string,
  parsedValue: string,
  convertingOptions: ConvertingOptions,
  currencyOptions: CurrencyDetails,
): {
  textCase: TextCase;
  words: string[];
  currentClassValue: string;
  parsedValue: string;
} {
  const words: string[] = [];

  const { currentDigit, textCase: currentTextCase } = getIntegerTextCase(
    currentClassValue,
    i,
  );

  let textCase = currentTextCase;

  // For digits in range 10-19
  if (currentClassValue.length === 2 && currentDigit === 1) {
    words.push(
      TEXT_VALUES[convertingOptions.language].tens[
        parseInt(currentClassValue.substr(1, 1), 10)
      ],
    );

    parsedValue = parsedValue.substr(1);

    // Move to the next class
    currentClassValue = '';
    textCase = TextCase.fromFiveToNine;

    return {
      textCase,
      words,
      currentClassValue,
      parsedValue,
    };
  }

  if (currentDigit === 0) {
    return {
      textCase,
      words,
      currentClassValue,
      parsedValue,
    };
  }

  // If thousands
  if (i === 2 && currentClassValue.length === 1) {
    words.push(
      TEXT_VALUES[convertingOptions.language].numbers[0][currentDigit],
    );

    return {
      textCase,
      words,
      currentClassValue,
      parsedValue,
    };
  }

  if (currentClassValue.length > 1) {
    words.push(
      TEXT_VALUES[convertingOptions.language].numbers[currentClassValue.length][
        currentDigit
      ],
    );

    return {
      textCase,
      words,
      currentClassValue,
      parsedValue,
    };
  }

  if (i === 1) {
    if (
      (currentDigit === 1 || currentDigit === 2) &&
      convertingOptions.language === 'ua'
    ) {
      words.push(
        TEXT_VALUES[convertingOptions.language].numbers[0][currentDigit],
      );
    } else {
      words.push(
        TEXT_VALUES[convertingOptions.language].numbers[
          currencyOptions.integerValueCase
        ][currentDigit],
      );
    }

    return {
      textCase,
      words,
      currentClassValue,
      parsedValue,
    };
  }

  words.push(TEXT_VALUES[convertingOptions.language].numbers[1][currentDigit]);

  return {
    textCase,
    words,
    currentClassValue,
    parsedValue,
  };
}

function processIntegerPart(
  value: number,
  isNegative: boolean,
  convertingOptions: ConvertingOptions,
  currencyOptions: CurrencyDetails,
): {
  words: string[];
  textCase: number;
} {
  const words = [];

  let textCase = TextCase.fromFiveToNine;

  if (isNegative) {
    words.push(TEXT_VALUES[convertingOptions.language].minus);
  }

  let parsedValue = Math.abs(value).toFixed(0);

  if (value === 0) {
    words.push(TEXT_VALUES[convertingOptions.language].numbers[1][0]);

    return {
      words,
      textCase,
    };
  }

  const classesCount = Math.ceil(parsedValue.length / INTEGER_CLASS_LENGTH);
  const firstClassLength =
    parsedValue.length % INTEGER_CLASS_LENGTH || INTEGER_CLASS_LENGTH;

  let currentClassValue = parsedValue.substr(0, firstClassLength);

  for (let i = classesCount; i > 0; i -= 1) {
    textCase = TextCase.none;

    while (currentClassValue.length > 0) {
      if (currentClassValue === ''.padStart(INTEGER_CLASS_LENGTH, ZERO)) {
        parsedValue = parsedValue.substr(INTEGER_CLASS_LENGTH);
        currentClassValue = '';

        continue;
      }

      const {
        textCase: classTextCase,
        words: classWords,
        parsedValue: classParsedValue,
        currentClassValue: classCurrentValue,
      } = processIntegerClass(
        i,
        currentClassValue,
        parsedValue,
        convertingOptions,
        currencyOptions,
      );

      // Remove first digit from unit class
      currentClassValue = classCurrentValue.substr(1);
      parsedValue = classParsedValue.substr(1);

      words.push(...classWords);

      textCase = classTextCase;
    }

    // If we have class name
    if (textCase !== TextCase.none && i > 1) {
      words.push(TEXT_VALUES[convertingOptions.language].units[i][textCase]);
    }

    // Get the number of the next class
    currentClassValue = parsedValue.substr(0, INTEGER_CLASS_LENGTH);
  }

  return {
    textCase,
    words: words.filter((r) => r.trim() !== ''),
  };
}

//#region Fractional Part
function getTextForTens(value: string, language: Language): string {
  return TEXT_VALUES[language].tens[parseInt(value.substr(1, 1), 10)];
}

function getTextForDigit(
  digit: number,
  index: number,
  language: Language,
): string {
  const numberIndex = index === 0 ? 2 : 0;

  return TEXT_VALUES[language].numbers[numberIndex][digit];
}

function getFractionalTextCase(digit: number): TextCase {
  if (digit === 1) {
    return TextCase.forOne;
  }

  if (digit > 1 && digit < 5) {
    return TextCase.fromTwoToFour;
  }

  return TextCase.fromFiveToNine;
}

function processFractionalPart(
  value: string,
  convertingOptions: ConvertingOptions,
): {
  words: string[];
  textCase: number;
} {
  const words: string[] = [];

  let textCase = TextCase.fromFiveToNine;

  const intValue = parseInt(value, 10);

  if (intValue === 0) {
    words.push(TEXT_VALUES[convertingOptions.language].numbers[1][0]);

    return { textCase, words };
  }

  if (intValue > 9 && intValue < 20) {
    words.push(getTextForTens(value, convertingOptions.language));
  } else {
    for (let index = 0; index < value.length; index += 1) {
      const digit = parseInt(value[index], 10);

      if (digit !== 0) {
        words.push(getTextForDigit(digit, index, convertingOptions.language));

        if (index === 1) {
          textCase = getFractionalTextCase(digit);
        }
      }
    }
  }

  return { textCase, words };
}

//#region numberToWords
/**
 * Convert number to string
 * @param {Number} value
 * @param {Object} options
 */
function numberToWords(
  value: number,
  options?: {
    capitalize?: boolean;
    noCurrency?: boolean;
  },
): ToWords {
  const { convertingOptions, currencyOptions } = getConvertingConfigs(options);
  const { isNegative, integerValue, fractionalValue } = parseInputValue(value);

  if (
    fractionalValue === ''.padStart(PRECISION, ZERO) &&
    convertingOptions.currency === 'noCurrency'
  ) {
    currencyOptions.integerValueCase = 1;
  }

  const { textCase: integerTextCase, words: integerWords } = processIntegerPart(
    integerValue,
    isNegative,
    convertingOptions,
    currencyOptions,
  );

  const { textCase: fractionalTextCase, words: fractionalWords } =
    processFractionalPart(fractionalValue, convertingOptions);

  const result: ToWords = {
    integer: integerValue,
    integerText: integerWords.join(' '),
    integerCurrency: currencyOptions.integerNameCases[integerTextCase],
    integerShortCurrency: currencyOptions.integerShortName ?? '',
    fractional: parseInt(fractionalValue, 10),
    fractionalText: fractionalWords.join(' '),
    fractionalCurrency: currencyOptions.fractionalNameCases[fractionalTextCase],
    fractionalShortCurrency: currencyOptions.fractionalShortName ?? '',
    fractionalString: fractionalValue,
  };

  if (convertingOptions.capitalize) {
    result.integerText =
      result.integerText[0].toLocaleUpperCase() + result.integerText.substr(1);
  }

  return result;
}

//#region toCurrencyWords
export function toCurrencyWords(
  value: number,
  options?: {
    capitalize?: boolean;
  },
): string {
  const result = numberToWords(value, options);

  return `${result.integerText} ${result.integerShortCurrency} ${result.fractionalString} ${result.fractionalShortCurrency}`;
}

//#region toWords
export function toWords(
  value: number,
  options?: {
    capitalize?: boolean;
  },
): string {
  const result = numberToWords(value, { ...options, noCurrency: true });

  return `${result.integerText} ${result.integerCurrency} ${result.fractionalText} ${result.fractionalCurrency}`;
}
