export const KEY_CONNECTION_METHOD = 'connectionMethod';
export const KEY_SERVICE_REFUND = 'serviceRefund';
export const KEY_AUTO_DEBUG = 'autoDebug';
export const KEY_MERCHANT_ID = 'merchantId';
export const KEY_IP = 'ip';
export const KEY_USB_PORT = 'usbPort';
export const KEY_TAPXPHONE_DEVICE_ID = 'tapXphoneDeviceId';
export const DEFAULT_AGENT_IP = '127.0.0.1';

export const DEFAULT_TIMEOUT = 15 * 1000; // 15 seconds
export const DEFAULT_REQUEST_CODE = 2;

export const PAYMENT_SYSTEM_MASTERCARD = 'Mastercard';
export const PAYMENT_SYSTEM_VISA = 'Visa';

export const TOAST_TITLE = 'Суміщення з платіжним терміналом';
export const TRANSACTION_TOKEN_ERROR = `Не вдалося отримати токен транзакції`;
