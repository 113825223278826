import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Resource } from '../../abstract/resource';
import { ObjectLiteral } from '../../interfaces/object-literal';
import { Ingredient } from '../../models/ingredient.model';
import { CachedDataService } from '../cached-data.service';

@Injectable({
  providedIn: 'root',
})
export class IngredientsService extends Resource<Ingredient> {
  constructor(
    protected http: HttpClient,
    private cachedDataService: CachedDataService,
  ) {
    super(http, {
      path: '/ingredients',
    });
  }

  findForApp(
    options: Partial<Ingredient> | ObjectLiteral = {},
  ): Observable<Ingredient[]> {
    options = {
      shopId: this.cachedDataService.getShopId(),
      ...options,
    };

    return super.findForApp(options);
  }
}
