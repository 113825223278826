<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/supplies"></ion-back-button>
    </ion-buttons>

    <ion-title>Накладні {{ invoiceTitle }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <section class="invoice" *ngIf="invoicesGroups?.length; else loading">
    <ng-container *ngFor="let group of invoicesGroups; let i = index">
      <ion-toolbar class="period-container">
        <ion-label>{{ group.date }}</ion-label>
      </ion-toolbar>

      <div class="table-header">
        <span class="column-time">Час</span>

        <span [ngClass]="{ 
          'column-refill-return-provider': invoiceType === invoiceEnum.refill || invoiceType === invoiceEnum.return,
          'column-waste-provider': invoiceType === invoiceEnum.waste,
          'column-move-provider': invoiceType === invoiceEnum.move,
          'column-order-provider': invoiceType === invoiceEnum.order
        }">
          {{ providerHeader() }}
        </span>

        <span class="column-amount" *ngIf="invoiceType === invoiceEnum.refill || invoiceType === invoiceEnum.return">
          Сума, ₴
        </span>

        <span class="column-type" *ngIf="invoiceType !== invoiceEnum.waste">
          Тип
        </span>
      </div>

      <ion-list lines="none" class="table-data">
        <div class="even-row" *ngFor="let invoice of group.invoices; let j = index">
          <ion-item-sliding #itemSliding>
            <ion-item (click)="itemSliding.open('end')">
              <div class="table-data-row">
                <span class="column-time">{{ invoice.createdAt | date : 'HH:mm' }}</span>

                <span [ngClass]="{ 
                  'column-refill-return-provider': invoiceType === invoiceEnum.refill || invoiceType === invoiceEnum.return,
                  'column-waste-provider': invoiceType === invoiceEnum.waste,
                  'column-move-provider': invoiceType === invoiceEnum.move,
                  'column-order-provider': invoiceType === invoiceEnum.order
                }">
                  {{ invoice.providerName }} {{ docName(invoice) }}
                </span>

                <span class="column-amount" *ngIf="invoiceType === invoiceEnum.refill || invoiceType === invoiceEnum.return">
                  {{ (invoiceType === invoiceEnum.refill ? invoice.refillAmount : invoice.wasteAmount) | number: '1.2-2' }}
                </span>

                <span class="column-type" *ngIf="invoiceType !== invoiceEnum.waste">
                  {{ invoice.typeName }}
                </span>
              </div>
            </ion-item>

            <ion-item-options side="end" (click)="itemSliding.close()">
              <ion-item-option color="secondary" (click)="view(invoice)" *ngIf="invoice.canView">
                <ion-icon name="eye-outline" size="large"></ion-icon>
              </ion-item-option>

              <ion-item-option color="secondary" (click)="copy(invoice)" *ngIf="invoice.canCopy">
                <ion-icon name="copy-outline" size="large"></ion-icon>
              </ion-item-option>

              <ion-item-option color="warning" (click)="update(invoice)" *ngIf="invoice.canUpdate">
                <ion-icon name="create-outline" size="large"></ion-icon>
              </ion-item-option>

              <ion-item-option color="tertiary"(click)="print(invoice)" *ngIf="invoice.canPrint && !isIOS">
                <ion-icon name="print-outline" size="large"></ion-icon>
              </ion-item-option>

              <ion-item-option color="tertiary"(click)="printLabel(invoice)" *ngIf="invoice.canPrintLabel && isPrintLabelMode">
                <ion-icon name="ticket-outline" size="large"></ion-icon>
              </ion-item-option>

              <ion-item-option color="danger" (click)="delete(invoice, group)" *ngIf="invoice.canDelete">
                <ion-icon name="trash-outline" size="large"></ion-icon>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </div>
      </ion-list>
    </ng-container>
  </section>

  <ng-template #loading>
    <div class="no-data">
      <span>
        <ion-spinner *ngIf="!isNoData; else noData"></ion-spinner>

        <ng-template #noData>
          Немає накладних
        </ng-template>
      </span>
    </div>
  </ng-template>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="one-button" color="primary" (click)="create()">
      <ion-icon slot="start" name="add"></ion-icon>
      <ion-label>Нова накладна {{ invoiceTitle }}</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>
