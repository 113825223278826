/**
 * Тип документа "Чек"
 */
export enum CheckDocumentTypeName {
  /**
   * Чек реалізації товарів / послуг
   */
  SaleGoods = 'SaleGoods',

  /**
   * Чек переказу коштів
   */
  TransferFunds = 'TransferFunds',

  /**
   * Чек операції обміну валюти
   */
  CurrencyExchange = 'CurrencyExchange',

  /**
   * Чек видачі готівки
   */
  CashWithdrawal = 'CashWithdrawal',

  /**
   * Відкриття зміни
   */
  OpenShift = 'OpenShift',

  /**
   * Закриття зміни
   */
  CloseShift = 'CloseShift',

  /**
   * Початок офлайн сесії
   */
  OfflineBegin = 'OfflineBegin',

  /**
   * Завершення офлайн сесії
   */
  OfflineEnd = 'OfflineEnd',
}
