import { PayFormCode } from '../enums/pay-form-code.enum';

export class PRroZReportPayForm {
  /**
   * Код форми оплати (числовий):
   *
   * 0 – Готівка
   *
   * 1 – Банківська картка
   *
   * 2 - Попередня оплата
   *
   * 3 - Кредит
   */
  code: PayFormCode;

  /**
   * Найменування форми оплати (128 символів)
   */
  name: string;

  /**
   * Сума оплати (15.2 цифри)
   */
  sum: number;
}
