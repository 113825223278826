import omitBy from 'lodash-es/omitBy';
import { Observable } from 'rxjs';

import { IHttp } from '../interfaces/ihttp';
import { ObjectLiteral } from '../interfaces/object-literal';

export abstract class Resource<T> {
  path: string;

  constructor(protected http: IHttp, protected config: { path: string }) {
    this.path = config.path;
  }

  find(params: Partial<T> | ObjectLiteral = {}): Observable<T[]> {
    params = omitBy(params, (value) => value == null);

    return this.http.get<T[]>(this.buildUrl(), { params });
  }

  findForApp(params: Partial<T> | ObjectLiteral = {}): Observable<T[]> {
    params = omitBy(params, (value) => value == null);

    return this.http.get<T[]>(`${this.buildUrl()}/app`, { params });
  }

  get(id: number | string): Observable<T> {
    return this.http.get<T>(this.buildUrl(id));
  }

  create(body: T, params: ObjectLiteral = {}): Observable<T> {
    return this.http.post<T>(this.buildUrl(), body, { params });
  }

  update(
    id: number | string,
    body: Partial<T>,
    params: ObjectLiteral = {},
  ): Observable<T> {
    return this.http.patch<T>(this.buildUrl(id), body, { params });
  }

  delete(id: number | string): Observable<T> {
    return this.http.delete<T>(this.buildUrl(id));
  }

  protected buildUrl(id?: number | string): string {
    return id ? `${this.path}/${id}` : this.path;
  }
}
