import { inject, InjectionToken, NgZone } from '@angular/core';

import { BroadcastService } from '../services/broadcast.service';

export const BROADCAST_SERVICE = new InjectionToken<BroadcastService>(
  'broadcastService',
  {
    providedIn: 'root',
    factory: () => new BroadcastService('client-screen', inject(NgZone)),
  },
);
