import round from 'lodash-es/round';
import { v4 as uuid } from 'uuid';

import { Client } from '../../clients/client.model';
import { User } from '../../core/models/user.model';
import { EXCISE, VAT } from '../../p-rro/fsco/enums/tax.const';
import { PRro } from '../../p-rro/p-rro.model';

import { SalePayment } from './sale-payment.model';
import { SaleProduct } from './sale-product.model';
import { SaleTax } from './sale-tax.model';

export class Sale {
  id: number;
  shopId: number;
  shiftId: number;
  userId: number;
  user: User;
  createdAt: Date;
  cashless: boolean | null;
  clientId: number;
  client: Client;
  isOffline: boolean;
  userSessionId: number;
  returnSaleId: number;
  returnSale?: Sale;
  presaleId: number;
  saleInDebtId: number;
  prroId: number;
  prro?: PRro;
  prroShiftId: number;
  prroLocalNumber: number;
  prroTaxNumber: string;
  comment: string;
  uuid: string;

  saleProducts: SaleProduct[];
  salePayments: SalePayment[];
  saleTaxes: SaleTax[];

  // App fields
  costSum: number;
  discountSum: number;
  roundSum: number;
  paymentSum: number;
  debtSum: number;
  cashSum: number;
  cardSum: number;
  clientCash: number;
  changeSum: number;

  totalDiscount: number;
  totalFreeCups: number;
  totalBonus: number;

  paymentIcon: string;
  paymentTooltip: string;
  paymentTerminalData?: SalePayment;

  constructor(
    options: { skipConstructor: boolean } = { skipConstructor: false },
  ) {
    if (options.skipConstructor) {
      return;
    }

    this.uuid = uuid();

    this.costSum = 0;
    this.discountSum = 0;
    this.roundSum = 0;
    this.paymentSum = 0;
    this.debtSum = 0;
    this.cashSum = 0;
    this.cardSum = 0;
    this.clientCash = 0;
    this.changeSum = 0;

    this.totalDiscount = 0;
    this.totalFreeCups = 0;
    this.totalBonus = 0;
  }

  calcTotalTaxes(): void {
    this.saleTaxes = [];

    this.saleProducts.forEach((saleProduct) => {
      if (
        saleProduct.exciseLetter != null &&
        saleProduct.exciseAmount != null &&
        saleProduct.excisePercent != null &&
        saleProduct.excisePercent >= 0
      ) {
        const currentExciseGroup = this.saleTaxes.find((saleExcise) =>
          saleProduct.exciseLetter?.includes(saleExcise.letter),
        );

        if (currentExciseGroup != null) {
          currentExciseGroup.taxAmount += saleProduct.exciseAmount;
          currentExciseGroup.totalAmount += saleProduct.fullCost;
          currentExciseGroup.totalDiscount += saleProduct.discount ?? 0;
          currentExciseGroup.sourceAmount += saleProduct.cost;
        } else {
          const saleExcise = new SaleTax(EXCISE);

          saleExcise.letter = saleProduct.exciseLetter;
          saleExcise.percent = saleProduct.excisePercent;
          saleExcise.taxAmount = saleProduct.exciseAmount;
          saleExcise.totalAmount = saleProduct.fullCost;
          saleExcise.totalDiscount = saleProduct.discount ?? 0;
          saleExcise.sourceAmount = saleProduct.cost;

          this.saleTaxes.push(saleExcise);
        }
      }

      if (
        saleProduct.vatLetter != null &&
        saleProduct.vatAmount != null &&
        saleProduct.vatPercent != null &&
        saleProduct.vatPercent >= 0
      ) {
        const currentVATGroup = this.saleTaxes.find((saleTax) =>
          saleProduct.vatLetter?.includes(saleTax.letter),
        );

        if (currentVATGroup != null) {
          currentVATGroup.taxAmount += saleProduct.vatAmount;
          currentVATGroup.totalAmount += saleProduct.fullCost;
          currentVATGroup.totalDiscount += saleProduct.discount ?? 0;
          currentVATGroup.sourceAmount +=
            saleProduct.cost - (saleProduct.exciseAmount ?? 0);
        } else {
          const saleVAT = new SaleTax(VAT);

          saleVAT.letter = saleProduct.vatLetter;
          saleVAT.percent = saleProduct.vatPercent;
          saleVAT.taxAmount = saleProduct.vatAmount;
          saleVAT.totalAmount = saleProduct.fullCost;
          saleVAT.totalDiscount = saleProduct.discount ?? 0;
          saleVAT.sourceAmount =
            saleProduct.cost - (saleProduct.exciseAmount ?? 0);

          this.saleTaxes.push(saleVAT);
        }
      }
    });

    this.saleTaxes.forEach((saleTax) => {
      saleTax.taxAmount = round(saleTax.taxAmount, 2);
      saleTax.totalAmount = round(saleTax.totalAmount, 2);
      saleTax.totalDiscount = round(saleTax.totalDiscount, 2);
      saleTax.sourceAmount = round(saleTax.sourceAmount, 2);
    });
  }
}
