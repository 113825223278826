import { CryptData } from '../fsco/crypt-data.interface';

export class ShopCryptData extends CryptData {
  userId: number;
  shopId: number;

  constructor(shopId?: number, userId?: number) {
    super();

    if (shopId != null) {
      this.shopId = shopId;
    }

    if (userId != null) {
      this.userId = userId;
    }
  }
}
