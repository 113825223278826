export class PRroTicket {
  /**
   * Унікальний ідентифікатор документа (GUID, обов'язковий)
   */
  UID: string;

  /**
   * Дата операції (ddmmyyyy, обов'язковий)
   */
  ORDERDATE: string;

  /**
   * Час операції (hhmmss, обов'язковий)
   */
  ORDERTIME: string;

  /**
   * Локальний номер документа (128 символів, обов'язковий)
   */
  ORDERNUM: number;

  /**
   * Фіскальний номер документа (128 символів, необов'язковий)
   */
  ORDERTAXNUM?: string;

  /**
   * Ідентифікатор офлайн сесії (128 символів, необов'язковий)
   */
  OFFLINESESSIONID?: number;

  /**
   * "Секретне число" для обчислення фіскального номера офлайн документа (128 символів, необов'язковий)
   */
  OFFLINESEED?: number;

  /**
   * Код помилки (Ціле число >= 0, необов'язковий)
   */
  ERRORCODE?: number;

  //
  /**
   * Текст помилки (текст, необов'язковий)
   */
  ERRORTEXT?: string;

  /**
   * Версія формату документа (числовий, обов'язковий)
   */
  VER: number;
}
