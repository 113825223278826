import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { NewProductDialog } from './new-product/new-product.dialog';
import { SuppliesRoutingModule } from './supplies-routing.module';
import { SuppliesComponent } from './supplies.component';

@NgModule({
  imports: [SharedModule, SuppliesRoutingModule],
  declarations: [SuppliesComponent, NewProductDialog],
})
export class SuppliesModule {}
