import { PrivatbankJsonMethod } from '../enums/method.enum';

import { PrivatbankJsonBaseRequest } from './base-request.model';

/**
 * Операція "X-звіт"
 */
export class PrivatbankJsonAuditRequest extends PrivatbankJsonBaseRequest {
  /**
   * Масив додаткових параметрів для терміналу
   */
  params: {
    /**
     * Індекс мерчанта, який використовується для транзакції
     */
    merchantId: string;
  };

  constructor(merchantId: number = 1) {
    super(PrivatbankJsonMethod.Audit);

    this.params = {
      merchantId: merchantId.toString(),
    };
  }
}
