import { Clipboard } from '@angular/cdk/clipboard';
import { formatDate, formatNumber } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { ModalController } from '@ionic/angular';

import { DATETIME_FORMAT } from '../../../core/constants/date.const';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { ToastService } from '../../../core/services/toast.service';
import { UtilsService } from '../../../core/services/utils.service';
import { Sale } from '../../../sales/sale/sale.model';
import { TAX_DATE_FORMAT } from '../../../settings/printer/receipt/receipt.const';
import { Transaction } from '../../../transactions/transaction/models/transaction.model';
import { TransactionType } from '../../../transactions/transaction/transaction-type.enum';

const PACKAGE_NAME_TELEGRAM = 'org.telegram.messenger';
const PACKAGE_NAME_VIBER = 'com.viber.voip';
const PACKAGE_NAME_INSTAGRAM = 'com.instagram.android';

interface IShareButton {
  package: string;
  class: string;
  icon: string;
  enabled: boolean;
}

@Component({
  selector: 'bk-tax-qr-dialog',
  templateUrl: './tax-qr.dialog.html',
  styleUrls: ['./tax-qr.dialog.scss'],
})
export class TaxQrDialog implements OnInit {
  @Input() doc: Sale | Transaction;

  sum = 0;
  qrData = '';
  message = '';
  subject = '';

  isAndroidApp = false;

  TAX_DATE_FORMAT = TAX_DATE_FORMAT;

  shareButtons: IShareButton[] = [
    { package: '', class: 'default', icon: 'link-outline', enabled: true },
    {
      package: PACKAGE_NAME_TELEGRAM,
      class: 'telegram',
      icon: 'logo-telegram',
      enabled: true,
    },
    {
      package: PACKAGE_NAME_VIBER,
      class: 'viber',
      icon: 'logo-viber',
      enabled: true,
    },
    {
      package: PACKAGE_NAME_INSTAGRAM,
      class: 'instagram',
      icon: 'logo-instagram',
      enabled: true,
    },
  ];

  get activeShareButtons(): IShareButton[] {
    return this.shareButtons.filter((sb) => sb.enabled);
  }

  constructor(
    private modalCtrl: ModalController,
    private utilsService: UtilsService,
    private toastService: ToastService,
    private cachedDataService: CachedDataService,
    private socialSharing: SocialSharing,
    private clipboard: Clipboard,
  ) {
    this.isAndroidApp = this.utilsService.isAndroidApp();
  }

  ngOnInit(): void {
    this.sum = this.isSale(this.doc)
      ? Math.abs(this.doc.paymentSum)
      : this.doc.amount;

    this.qrData = this.utilsService.getQRText(
      this.doc.prroTaxNumber,
      this.doc.createdAt,
      this.sum,
      this.doc.prro?.fiscalNumber,
    );

    this.message = `${this.shareTitle()}\n${this.shareMessage()}:\n`;
    this.subject = this.shareTitle();

    if (this.isAndroidApp) {
      for (const shareButton of this.shareButtons) {
        if (shareButton.package === '') {
          shareButton.icon = 'share-social-outline';
        } else {
          this.socialSharing
            .canShareVia(
              shareButton.package,
              this.message,
              this.subject,
              undefined,
              this.qrData,
            )
            .then((canShare) => {
              shareButton.enabled = Boolean(canShare);
            })
            .catch(() => {
              shareButton.enabled = false;
            });
        }
      }
    }
  }

  async close(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  private isSale(doc: Sale | Transaction): doc is Sale {
    return (doc as Sale).saleProducts !== undefined;
  }

  private shareTitle(): string {
    const prro = this.cachedDataService.getPRRO();

    return `${
      this.isSale(this.doc)
        ? 'Фіскальний чек'
        : this.doc.type === TransactionType.Deposit
        ? 'Службове внесення'
        : 'Службове вилучення'
    } від ${prro?.companyName ?? ''} (${prro?.shopName ?? ''})`;
  }

  private shareMessage(): string {
    return `Фіскальний №${this.doc.prroTaxNumber} від ${formatDate(
      this.doc.createdAt,
      DATETIME_FORMAT,
      'uk_UA',
    )} на суму ${formatNumber(this.sum, 'uk_UA', '1.2-2')} грн)`;
  }

  share(packageName: string): void {
    switch (packageName) {
      case PACKAGE_NAME_TELEGRAM:
        this.shareViaTelegram();
        break;

      case PACKAGE_NAME_VIBER:
        this.shareViaViber();
        break;

      case PACKAGE_NAME_INSTAGRAM:
        this.shareViaInstagram();
        break;

      default:
        this.shareDefault();
    }
  }

  shareDefault(): void {
    if (this.isAndroidApp) {
      this.socialSharing.shareWithOptions({
        message: `${this.shareTitle()}\n${this.shareMessage()}:\n`,
        subject: this.shareTitle(),
        url: this.qrData,
        chooserTitle: 'Відправити посилання на фіскальний чек',
      });
    } else {
      this.clipboard.copy(this.qrData);
      this.toastService.present('Посилання на чек скопійовано у буфер обміну');
    }
  }

  private shareVia(appName: string): void {
    this.socialSharing
      .canShareVia(appName, this.message, this.subject, undefined, this.qrData)
      .then((canShare) => {
        // "OK"
        this.socialSharing.shareVia(
          appName,
          this.message,
          this.subject,
          undefined,
          this.qrData,
        );
      })
      .catch((error) => {
        this.toastService.presentError(
          'Share check error',
          Array.isArray(error) ? error.join('\n') : JSON.stringify(error),
          10000,
        );

        this.copyInfoToClipboard();
      });
  }

  private encodeURI(
    options: {
      useMarkdown?: boolean;
      addColon?: boolean;
      hideMessage?: boolean;
    } = {
      useMarkdown: false,
      addColon: false,
      hideMessage: false,
    },
  ): string {
    return encodeURIComponent(
      `${options.useMarkdown ? '**' : ''}${this.shareTitle()}${
        options.useMarkdown ? '**' : ''
      }\n${options.hideMessage ? '' : this.shareMessage()}${
        options.addColon ? ':\n' : ''
      }`,
    );
  }

  private copyInfoToClipboard(): void {
    this.clipboard.copy(
      `${this.shareTitle()}\n${this.shareMessage()}:\n${this.qrData}`,
    );

    this.toastService.present('Інформацію про чек скопійовано у буфер обміну');
  }

  shareViaTelegram(): void {
    if (this.isAndroidApp) {
      this.shareVia(PACKAGE_NAME_TELEGRAM);
    } else {
      window.open(
        `https://t.me/share/url?url=${encodeURIComponent(
          this.qrData,
        )}&text=${this.encodeURI({
          useMarkdown: true,
        })}`,
        '_blank',
      );
    }
  }

  shareViaViber(): void {
    if (this.isAndroidApp) {
      this.shareVia(PACKAGE_NAME_VIBER);
    } else {
      window.open(
        `viber://forward?text=${encodeURIComponent(
          this.qrData,
        )}${encodeURIComponent('\n')}${this.encodeURI({
          useMarkdown: true,
          addColon: false,
          hideMessage: true,
        })}`,
        '_blank',
      );
    }
  }

  shareViaInstagram(): void {
    if (this.isAndroidApp) {
      this.shareVia(PACKAGE_NAME_INSTAGRAM);
    } else {
      this.copyInfoToClipboard();
    }
  }
}
