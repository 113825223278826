import { CheckDocumentType } from '../enums/check-document-type.enum';

import { PRroDocHead } from './p-rro-doc-head.model';

export class PRroCheck extends PRroDocHead {
  /**
   * Тип документа (числовий):
   *
   * 0 - Чек реалізації товарів/послуг
   *
   * 1 - Чек переказу коштів
   *
   * 2 – Чек операції обміну валюти
   *
   * 3 - Чек видачі готівки
   *
   * 100 - Відкриття зміни
   *
   * 101 - Закриття зміни
   *
   * 102 - Початок офлайн сесії
   *
   * 103 - Завершення офлайн сесії
   */
  checkType: CheckDocumentType;

  constructor(checkType: CheckDocumentType, date?: Date) {
    super(date);

    this.checkType = checkType;
  }
}
