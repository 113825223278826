import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Resource } from '../../abstract/resource';
import { UserSession } from '../../models/user-session.model';
import { User } from '../../models/user.model';
import { AuthService } from '../auth.service';
import { CachedDataService } from '../cached-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends Resource<User> {
  private user = new ReplaySubject<User>();

  constructor(
    protected http: HttpClient,
    private authService: AuthService,
    private cachedDataService: CachedDataService,
  ) {
    super(http, { path: '/users' });
  }

  getUser(): ReplaySubject<User> {
    return this.user;
  }

  findAllEmployee(): Observable<User[]> {
    return this.http.get<User[]>(`${this.path}/employee/all`);
  }

  saveSession(body: UserSession): Observable<number> {
    return this.http.post<number>(`${this.path}/session`, body);
  }

  refresh(id: number): Observable<User> {
    return this.get(id).pipe(
      tap((user) => {
        this.cachedDataService.setUser(user);
        this.user.next(user);
      }),
    );
  }

  init(): void {
    const id = this.authService.getUserId();

    this.get(id).subscribe(
      (user) => {
        this.cachedDataService.setUser(user);

        if (user) {
          this.user.next(user);
        }
      },
      (error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.cachedDataService.setUser(null);
          this.authService.removeToken();
          window.location.reload();
        }
      },
    );
  }

  initLocal(): void {
    const localUser = this.cachedDataService.getUser();

    this.user.next(localUser);
  }
}
