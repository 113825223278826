import { Component, Input, OnInit } from '@angular/core';

import {
  REFILL_HEADER_NAME_CAPTION,
  REFILL_HEADER_QUANTITY_CAPTION,
  REFILL_HEADER_SUM_CAPTION,
  REFILL_NUMBER_CAPTION,
  REFILL_PAID_SUM_CAPTION,
  REFILL_PROVIDER_NAME_CAPTION,
  REFILL_RECIPIENT_SIGN_CAPTION,
  REFILL_SENDER_SIGN_CAPTION,
  REFILL_SHOP_NAME_CAPTION,
  REFILL_TOTAL_SUM_CAPTION,
} from '../../receipt/receipt.const';
import { PrintRefill } from '../../receipt/types/print-refill.model';

@Component({
  selector: 'bk-view-refill',
  templateUrl: './view-refill.component.html',
  styleUrls: ['./view-refill.component.scss'],
})
export class ViewRefillComponent implements OnInit {
  @Input() refill: PrintRefill;

  numberCaption = REFILL_NUMBER_CAPTION;
  providerNameCaption = REFILL_PROVIDER_NAME_CAPTION;
  shopNameCaption = REFILL_SHOP_NAME_CAPTION;
  headerNameCaption = REFILL_HEADER_NAME_CAPTION;
  headerQuantityCaption = REFILL_HEADER_QUANTITY_CAPTION;
  headerSumCaption = REFILL_HEADER_SUM_CAPTION;
  totalSumCaption = REFILL_TOTAL_SUM_CAPTION;
  totalPaidSumCaption = REFILL_PAID_SUM_CAPTION;
  senderSignCaption = REFILL_SENDER_SIGN_CAPTION;
  recipientSignCaption = REFILL_RECIPIENT_SIGN_CAPTION;

  constructor() {}

  ngOnInit(): void {
    //
  }
}
