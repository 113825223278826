import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { CalendarModule } from 'ion2-calendar';
import { MaskPipe } from 'ngx-mask';

import { SharedModule } from '../shared/shared.module';
import { CategoriesService } from '../shop/categories/categories.service';
import { ProductsService } from '../shop/products/products.service';
import { SubcategoriesService } from '../shop/subcategories/subcategories.service';

import { GlobalErrorHandler } from './global-error-handler';
import { ApiUrlInterceptor } from './interceptors/api-url.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthService } from './services/auth.service';
import { CachedDataService } from './services/cached-data.service';
import { CachedImageService } from './services/cached-image.service';
import { HostListenerService } from './services/host-listener.service';
import { LoadingService } from './services/loading.service';
import { PreloaderService } from './services/preloader.service';
import { IngredientCategoryService } from './services/resources/ingredient-category.service';
import { IngredientsService } from './services/resources/ingredients.service';
import { ProvidersService } from './services/resources/providers.service';
import { ShiftsService } from './services/resources/shifts.service';
import { ShopsService } from './services/resources/shops.service';
import { TariffService } from './services/resources/tariff.service';
import { UserService } from './services/resources/user.service';
import { WorkingHoursService } from './services/resources/working-hours.service';
import { UtilsService } from './services/utils.service';

@NgModule({
  imports: [HttpClientModule, CalendarModule, SharedModule],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },

    AppVersion,
    Device,
    Diagnostic,
    Network,
    StatusBar,

    AuthService,
    CachedDataService,
    CachedImageService,
    PreloaderService,
    UtilsService,
    LoadingService,
    HostListenerService,
    MaskPipe,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },

    IngredientCategoryService,
    IngredientsService,
    CategoriesService,
    SubcategoriesService,
    ProductsService,
    ProvidersService,
    TariffService,
    ShopsService,
    ShiftsService,
    UserService,
    WorkingHoursService,
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
  ) {
    if (parentModule) {
      throw new Error(
        `CoreModule has already been loaded. Import Core modules in the AppModule only.`,
      );
    }
  }
}
